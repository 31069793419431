import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Card, Row, Col, Button, Space, Table, Modal, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { Employee } from '../../../../../Model/Gym/Employee';
import { Gym } from '../../../../../Model/Gym/Gym';
import { PageResult } from '../../../../../Model/PageResult';
import { EmployeeCreateModal } from './EmployeeCreateModal/EmployeeCreateModal';
import { EmployeeEditModal } from './EmployeeEditModal';

export type EmployeeTabProps = {
  gym: Gym;
};

export const EmployeeTab: React.FC<EmployeeTabProps> = observer(({ gym }: EmployeeTabProps) => {
  const [showEmployeeCreate, setShowEmployeeCreate] = useState(false);
  const [employee, setEmployee] = useState<Employee | undefined>(undefined);
  const [result, setResult] = useState(new PageResult<Employee>());
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    PageResult.execute(
      Employee.find(gym.id, { page: currentPage, size: 20, sort: 'athlete.firstname,ASC' }),
      Employee.count(gym.id),
      currentPage,
      20,
    ).then((res) => setResult(res));
  }, [gym, currentPage]);

  const handlePageChange = useCallback((page: number, pageSize?: number) => {
    setCurrentPage(page - 1);
  }, []);

  const handleRemove = useCallback(
    async (employee: Employee) => {
      await employee.remove();
      PageResult.execute(
        Employee.find(gym.id, { page: currentPage, size: 20, sort: 'athlete.firstname,ASC' }),
        Employee.count(gym.id),
        currentPage,
        20,
      ).then((res) => setResult(res));
    },
    [gym, currentPage],
  );

  const handleConfirmDelete = useCallback(
    (employee: Employee) => {
      Modal.confirm({
        title: 'Confirm Deletion',
        content: `Do you really want to delete ${employee.athlete.fullName}?`,
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: async () => {
          await handleRemove(employee);
        },
      });
    },
    [handleRemove],
  );

  const handleCreateEmployee = useCallback(() => {
    setShowEmployeeCreate(true);
  }, []);

  const handleEmployeeCreateClose = useCallback(() => {
    setShowEmployeeCreate(false);
    PageResult.execute(
      Employee.find(gym.id, { page: currentPage, size: 20, sort: 'athlete.firstname,ASC' }),
      Employee.count(gym.id),
      currentPage,
      20,
    ).then((res) => setResult(res));
  }, [gym, currentPage]);

  const handleSelect = useCallback((employee: Employee) => {
    setEmployee(employee);
  }, []);

  const handleCloseEdit = useCallback(() => {
    setEmployee(undefined);
  }, []);

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (_: any, record: Employee) => record.athlete.fullName,
    },
    {
      title: 'User',
      key: 'user',
      render: (_: any, record: Employee) => (
        <Link to={`/athlete/${record.athlete.id}`}>{record.athlete.user.email}</Link>
      ),
    },
    {
      title: 'Roles',
      key: 'roles',
      render: (_: any, record: Employee) => record.roles?.map((role: string) => <Tag key={role}>{role}</Tag>) || null,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: Employee) => (
        <Space>
          <Button onClick={() => handleSelect(record)}>Edit</Button>
          <Button danger onClick={() => handleConfirmDelete(record)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card className="mb-3">
        <Row justify="end" align="middle">
          <Col>
            <Button type="primary" onClick={handleCreateEmployee} disabled={gym._isNew}>
              Create New Employee
            </Button>
          </Col>
        </Row>
      </Card>
      <Card>
        <Table
          dataSource={result.content}
          columns={columns}
          rowKey="id"
          pagination={{
            current: currentPage + 1,
            pageSize: 20,
            total: result.totalElements,
            onChange: handlePageChange,
          }}
        />
      </Card>
      {showEmployeeCreate && (
        <EmployeeCreateModal onClose={handleEmployeeCreateClose} isOpen={showEmployeeCreate} gym={gym} />
      )}
      {employee && <EmployeeEditModal employee={employee} isOpen={true} onClose={handleCloseEdit} />}
    </>
  );
});
