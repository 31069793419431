/**
 * Created by andreaskarantzas on 08.02.19
 */
import * as React from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { FormGroup, Label } from 'reactstrap';
import Select, { ActionMeta } from 'react-select';
import { TrackingKeysList } from '../../../../../Model/ProgramPortfolio/TrackingKeys';

export type TrackingKeysSelectionViewProps = {
  container: string[];
  viewContainer?: string[];
  label?: string;
  disabled?: boolean;
};

export const TrackingKeysSelectionView: React.FC<TrackingKeysSelectionViewProps> = observer(
  ({ container, viewContainer, label, disabled }) => {
    const remainingKeys = TrackingKeysList.filter(
      (availableKey) => container.findIndex((addedKey) => addedKey === availableKey) === -1,
    ).map((value) => ({ value, label: value }));

    const value = (viewContainer ?? container).map((value) => ({ value, label: value }));

    const handleChange = (keys: { value: string; label: string }[], action: ActionMeta<any>) => {
      const newValues = keys || [];
      runInAction(() => {
        container.splice(0, container.length);
        if (newValues.length > 0) {
          newValues.forEach(({ value }) => container.push(value));
        } else {
          viewContainer?.forEach((key) => container.push(key));
        }
      });
    };

    const optionLabel = (option) => option.label;

    const optionValue = (option) => option.value;

    return (
      <FormGroup>
        {label ? <Label>{label || 'Tracking Keys'}</Label> : null}
        <Select
          isMulti={true}
          value={value}
          getOptionLabel={optionLabel as any}
          getOptionValue={optionValue as any}
          options={remainingKeys}
          onChange={handleChange as any}
          isDisabled={disabled}
        />
      </FormGroup>
    );
  },
);
