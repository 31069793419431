/**
 *
 * Created by neo on 13.03.17.
 */
import { toJS, action, observable, onBecomeObserved, computed, runInAction } from 'mobx';
import { v4 as UUID } from 'uuid';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Media } from '../Media/Media';
import { BodyPartSize } from './BodyPartSize';
import { BodyPartType } from './BodyPartType';

const bodyPartSizes = ['huge', 'large', 'medium', 'small', 'tiny'];

export class BodyPartRegion {
  @observable
  id = UUID();
  @observable
  identifier: string = '';
  @observable
  name: string = '';
  @observable
  description: string = '';
  @observable
  latinName: string = '';
  @observable
  size: BodyPartSize = 'medium';
  @observable
  type: BodyPartType = 'MUSCLE';
  @observable
  medias: Array<Media> = [];
  @observable
  childrenIds: Array<string> = [];
  @observable
  children: BodyPartRegion[] = [];

  constructor(json?: any) {
    if (json) {
      this.id = json.id || UUID();
      this.identifier = json.identifier || '';
      this.name = json.name || '';
      this.latinName = json.latinName || '';
      this.size = json.size || 'medium';
      this.type = json.type || 'MUSCLE';
      this.medias = (json.medias || []).map((m) => new Media(m));
      this.childrenIds = json.childrenIds || [];
    }
    onBecomeObserved(this, 'children', () => this.fetchChildren());
  }

  toJS(): any {
    return {
      id: this.id,
      identifier: this.identifier,
      name: this.name,
      description: this.description,
      latinName: this.latinName,
      type: this.type,
      medias: this.medias.map((m) => m.toJS()),
      size: this.size,
    };
  }

  fetchChildren(): Promise<BodyPartRegion[]> {
    return BodyPartRegion.getAll(this.childrenIds).then((result) => {
      runInAction(() => (this.children = result));
      return this.children;
    });
  }

  @action
  remove() {
    return HttpBackend.delete(`/activity/exercise/bodypart/${this.id}`);
  }

  save(): Promise<BodyPartRegion> {
    return HttpBackend.post('/activity/exercise/bodypart', toJS(this)).then(() => this);
  }

  async flatten(): Promise<Array<BodyPartRegion>> {
    let result: Array<BodyPartRegion> = [this];
    const children = await this.fetchChildren();
    result = result.concat(children);
    for (const child of children) {
      result = result.concat(await child.flatten());
    }
    return result.filter((item, pos) => result.indexOf(item) === pos);
  }

  @computed
  get sortIndex(): number {
    return bodyPartSizes.reverse().indexOf(this.size ?? 'medium');
  }

  @computed
  get sortIndexDesc(): number {
    return bodyPartSizes.indexOf(this.size ?? 'medium');
  }

  static async getAll(ids: Array<string>): Promise<Array<BodyPartRegion>> {
    if (ids.length > 0) {
      const data = await Promise.all(ids.map((id) => BodyPartRegion.get(id)));
      return data.filter((b) => !!b) as Array<BodyPartRegion>;
    }
    return [];
  }

  static async get(id: string): Promise<BodyPartRegion | undefined> {
    const res = await HttpBackend.get(`/activity/exercise/bodypart/${id}`);
    if (res) {
      return new BodyPartRegion(res);
    }
    return undefined;
  }

  static async find(params: any = { page: 0, sort: 'name,ASC' }): Promise<BodyPartRegion[]> {
    return HttpBackend.get(`/activity/exercise/bodypart`, params).then((res) => (res ?? []).map((r) => new BodyPartRegion(r)));
  }

  static async list(): Promise<Array<BodyPartRegion>> {
    const res = await HttpBackend.get(`/activity/exercise/bodypart/list`);
    return (res || []).map((b) => new BodyPartRegion(b));
  }

  static async flatten(id: string): Promise<Array<string>> {
    return HttpBackend.get(`/activity/exercise/bodypart/${id}/flatten`);
  }
}
