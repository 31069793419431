/**
 * Created by neo on 18.06.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'reactstrap';
import { SuperMacro } from '../../../Model/Coach/SuperMacro/SuperMacro';
import { SuperMacroTableRow } from './SuperMacroTableRow';

export type SuperMacroTableProps = {
  superMacros: SuperMacro[];
  onCopy?: (superMacro: SuperMacro) => any;
  onRemove?: (superMacro: SuperMacro) => any;
};

export const SuperMacroTable: React.FC<SuperMacroTableProps> = observer(
  ({ superMacros, onRemove, onCopy }: SuperMacroTableProps) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Tags</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {superMacros.map((superMacro) => (
            <SuperMacroTableRow key={superMacro.id} superMacro={superMacro} onRemove={onRemove} onCopy={onCopy} />
          ))}
        </tbody>
      </Table>
    );
  },
);
