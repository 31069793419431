/**
 * Created by neo on 18.06.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Pipeline } from '../../../../Model/Coach/Pipeline';
import { Exercise } from '../../../../Model/Exercise/Exercise';
import { PipelineContext } from '../../../../Model/Coach/PipelineContext';
import { runInAction, toJS } from 'mobx';
import { Modal } from 'antd';
import { Col, Container, Row } from 'reactstrap';
import { SingleColRow } from '../../../../Components/SingleColRow';
import createEngine, { DiagramModel } from '@projectstorm/react-diagrams';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { FlowEditor } from './Flow/FlowEditor';
import { StageEditorModal } from './Flow/StageEditorModal';
import { ResultViewer } from './ResultViewer';
import { DefaultStageNodeFactory } from './Flow/Model/Default/DefaultStageNodeFactory';
import { notUndefined } from '../../../../Utils/notUndefined';
import { ScriptContextForm } from './ScriptContextForm';
import { RandomNodeModelFactory } from './Flow/Model/Random/RandomNodeModelFactory';
import { StageModelBuilder } from './Flow/Model/StageModelBuilder';
import { BodyPartClusterSortFactory } from './Flow/Model/BodyPartClusterSort/BodyPartClusterSortFactory';
import { ConcatFactory } from './Flow/Model/Concat/ConcatFactory';
import { DistinctNodeFactory } from './Flow/Model/Distinct/DistinctNodeFactory';
import { ExerciseBlockNodeFactory } from './Flow/Model/ExerciseBlock/ExerciseBlockNodeFactory';
import { FetchNodeFactory } from './Flow/Model/Fetch/FetchNodeFactory';
import { FlattenNodeFactory } from './Flow/Model/Flatten/FlattenNodeFactory';
import { GraphSortNodeModelFactory } from './Flow/Model/GraphSort/GraphSortNodeModelFactory';
import { GroupNodeFactory } from './Flow/Model/Group/GroupNodeFactory';
import { LimitNodeFactory } from './Flow/Model/Limit/LimitNodeFactory';
import { MapNodeFactory } from './Flow/Model/Map/MapNodeFactory';
import { PhaseNodeFactory } from './Flow/Model/Phase/PhaseNodeFactory';
import { PipelineNodeFactory } from './Flow/Model/Pipeline/PipelineNodeFactory';
import { ReverseNodeModelFactory } from './Flow/Model/Reverse/ReverseNodeModelFactory';
import { SortNodeModelFactory } from './Flow/Model/Sort/SortNodeModelFactory';
import { WorkoutNodeModelFactory } from './Flow/Model/Workout/WorkoutNodeModelFactory';
import { SwitchNodeFactory } from './Flow/Model/Switch/SwitchNodeFactory';
import { AllNodeModelFactory } from './Flow/Model/Logical/All/AllNodeModelFactory';
import { AndNodeModelFactory } from './Flow/Model/Logical/And/AndNodeModelFactory';
import { EqualsNodeModelFactory } from './Flow/Model/Logical/Equals/EqualsNodeModelFactory';
import { GreaterThanEqualsNodeModelFactory } from './Flow/Model/Logical/GreaterThanEquals/GreaterThanEqualsNodeModelFactory';
import { GreaterThanNodeModelFactory } from './Flow/Model/Logical/GreaterThan/GreaterThanNodeModelFactory';
import { InNodeModelFactory } from './Flow/Model/Logical/In/InNodeModelFactory';
import { LessThanEqualsNodeModelFactory } from './Flow/Model/Logical/LessThanEquals/LessThanEqualsNodeModelFactory';
import { LessThanNodeModelFactory } from './Flow/Model/Logical/LessThan/LessThanNodeModelFactory';
import { NotEqualsNodeModelFactory } from './Flow/Model/Logical/NotEquals/NotEqualsNodeModelFactory';
import { NotInNodeModelFactory } from './Flow/Model/Logical/NotIn/NotInNodeModelFactory';
import { OrNodeModelFactory } from './Flow/Model/Logical/Or/OrNodeModelFactory';
import { DefaultStageNodeModel } from './Flow/Model/Default/DefaultStageNodeModel';
import { NoopNodeModelFactory } from './Flow/Model/Noop/NoopNodeModelFactory';
import { InputSortNodeModelFactory } from './Flow/Model/InputSort/InputSortNodeModelFactory';
import { LimitFieldNodeFactory } from './Flow/Model/LimitField/LimitFieldNodeFactory';
import { SinkNodeModelFactory } from './Flow/Model/Sink/SinkNodeModelFactory';
import { CollectNodeModelFactory } from './Flow/Model/Collect/CollectNodeModelFactory';
import { PipelineParamEditor } from './PipelineParamEditor/PipelineParamEditor';
import { PipelineStage } from '../../../../Model/Coach/Stages/PipelineStage';
import { DuplicateNodeFactory } from './Flow/Model/Duplicate/DuplicateNodeFactory';
import { PipelineViewScreenTopButtons } from './PipelineViewScreenTopButtons';

export type PipelineViewScreenProps = {};

export const PipelineViewScreen: React.FC<PipelineViewScreenProps> = observer((props: PipelineViewScreenProps) => {
  const { pipelineId } = useParams<any>();
  const history = useNavigate();
  const [engine, setEngine] = useState<DiagramEngine | undefined>(undefined);
  const [pipeline, setPipeline] = useState<Pipeline>(new Pipeline());
  const [result, setResult] = useState<any>(undefined);
  const [selectedNode, setSelectedNode] = useState<DefaultStageNodeModel | undefined>(undefined);

  const store = useLocalStore(() => ({
    exercises: new Array<Array<Exercise>>(),
    context: new PipelineContext({
      equipmentTypes: ['BARBELL', 'DUMBBELL', 'FLAT_BENCH', 'BENCH_INCLINED'],
      tags: ['level:intermediate'],
    }),
  }));

  const prepareEngine = () => {
    const engine = createEngine();
    engine.getNodeFactories().registerFactory(new DefaultStageNodeFactory());
    engine.getNodeFactories().registerFactory(new BodyPartClusterSortFactory());
    engine.getNodeFactories().registerFactory(new CollectNodeModelFactory());
    engine.getNodeFactories().registerFactory(new ConcatFactory());
    engine.getNodeFactories().registerFactory(new DistinctNodeFactory());
    engine.getNodeFactories().registerFactory(new DuplicateNodeFactory());
    engine.getNodeFactories().registerFactory(new ExerciseBlockNodeFactory());
    engine.getNodeFactories().registerFactory(new FetchNodeFactory());
    engine.getNodeFactories().registerFactory(new FlattenNodeFactory());
    engine.getNodeFactories().registerFactory(new GraphSortNodeModelFactory());
    engine.getNodeFactories().registerFactory(new GroupNodeFactory());
    engine.getNodeFactories().registerFactory(new InputSortNodeModelFactory());
    engine.getNodeFactories().registerFactory(new LimitNodeFactory());
    engine.getNodeFactories().registerFactory(new LimitFieldNodeFactory());
    engine.getNodeFactories().registerFactory(new MapNodeFactory());
    engine.getNodeFactories().registerFactory(new NoopNodeModelFactory());
    engine.getNodeFactories().registerFactory(new PhaseNodeFactory());
    engine.getNodeFactories().registerFactory(new PipelineNodeFactory());
    engine.getNodeFactories().registerFactory(new ReverseNodeModelFactory());
    engine.getNodeFactories().registerFactory(new SinkNodeModelFactory());
    engine.getNodeFactories().registerFactory(new SortNodeModelFactory());
    engine.getNodeFactories().registerFactory(new WorkoutNodeModelFactory());
    engine.getNodeFactories().registerFactory(new SwitchNodeFactory());
    engine.getNodeFactories().registerFactory(new AllNodeModelFactory());
    engine.getNodeFactories().registerFactory(new AndNodeModelFactory());
    engine.getNodeFactories().registerFactory(new EqualsNodeModelFactory());
    engine.getNodeFactories().registerFactory(new GreaterThanEqualsNodeModelFactory());
    engine.getNodeFactories().registerFactory(new GreaterThanNodeModelFactory());
    engine.getNodeFactories().registerFactory(new InNodeModelFactory());
    engine.getNodeFactories().registerFactory(new LessThanEqualsNodeModelFactory());
    engine.getNodeFactories().registerFactory(new LessThanNodeModelFactory());
    engine.getNodeFactories().registerFactory(new NotEqualsNodeModelFactory());
    engine.getNodeFactories().registerFactory(new NotInNodeModelFactory());
    engine.getNodeFactories().registerFactory(new OrNodeModelFactory());
    engine.getNodeFactories().registerFactory(new RandomNodeModelFactory());
    return engine;
  };

  const nodesListener = (pipeline: Pipeline) => ({
    eventDidFire: ((event) => console.log('nodeEvent', event)) as any,
    positionChanged: (({ entity }) => {
      entity.stage.x = entity.position.x;
      entity.stage.y = entity.position.y;
    }) as any,
    entityRemoved: (({ entity }) => {
      console.log('entityRemoved', entity);
      pipeline.removeStage(entity.stage);
    }) as any,
    editClicked: (({ entity }) => {
      console.log('editClicked', entity);
      setSelectedNode(entity);
      entity.setLocked(true);
    }) as any,
    executeClicked: (({ entity: { stage } }) => {
      console.log('executeClicked', stage);
      executeUntil(pipeline, stage.id);
    }) as any,
    copyParamsClicked: (({ entity: { stage } }) => {
      console.log('copyParamsClicked', stage);
      if (stage instanceof PipelineStage) {
        runInAction(() => {
          stage.innerPipeline?.params.forEach((param) => {
            if (pipeline.params.findIndex((p) => p.name === param.name) === -1) {
              pipeline.params.push(param.copy());
            }
            if (!stage.config.params) {
              stage.config.params = {};
            }
            if (stage.config.params && !stage.config.params[param.name]) {
              stage.config.params[param.name] = `$${param.name}`;
            }
          });
        });
      }
    }) as any,
  });

  const modelListener = (pipeline: Pipeline) => ({
    eventDidFire: ((event) => console.log('modelEvent', event)) as any,
    nodesUpdated: (({ node, isCreated }) => {
      if (isCreated) {
        node.registerListener(nodesListener(pipeline));
      }
    }) as any,
  });

  const connect = (pipeline: Pipeline, model: DiagramModel) => {
    const models = pipeline.pipeline.map((stage, index) => {
      const node = StageModelBuilder.build(stage); //new DefaultStageNodeModel(pipeline, stage);
      model.addNode(node);
      node.setPosition(stage.x || index * 120, stage.y || 100);
      node.registerListener(nodesListener(pipeline));
      return node;
    });
    const links = pipeline.edges
      .map((edge) => {
        const from = models.find((m) => m.stage.id === edge.from);
        const to = models.find((m) => m.stage.id === edge.to);
        const outPort = from?.getOutPorts().find((port) => port.getName() === (edge.type ?? 'default')); // from?.addOutPort(edge.type ?? 'default');
        const inPort = to?.getInPorts().find((port) => port.getName() === (edge.inType ?? 'in'));
        if (inPort && outPort) {
          return model.addLink(inPort?.link(outPort));
        }
        return undefined;
      })
      .filter(notUndefined);
  };

  const loadPipeline = (pipelineId?: string) => {
    if (pipelineId && pipelineId !== 'create' && pipelineId !== 'new') {
      return Pipeline.get(pipelineId).then((p) => p ?? new Pipeline());
    }
    return Promise.resolve(new Pipeline());
  };

  useEffect(() => {
    const engine = prepareEngine();
    const model = new DiagramModel();
    loadPipeline(pipelineId).then((pipeline: Pipeline) => {
      connect(pipeline, model);
      console.log('model', pipeline, model);
      model.registerListener(modelListener(pipeline));
      engine.setModel(model);
      setEngine(engine);
      setPipeline(pipeline);
    });
  }, [pipelineId]);

  const handleExecuted = React.useCallback((result) => setResult(result), []);
  const handleBeforeExecute = React.useCallback(() => setResult([]), []);

  const executeUntil = (pipeline: Pipeline, stageId: string) => {
    pipeline
      ?.execute(store.context.copy({ executeUntilStageId: stageId }))
      .then((result) => console.log('result', toJS(result)));
    // .then((result) => {
    //   console.log('result', toJS(result));
    //   setResult(result);
    // });
  };

  const handleCloseResultModal = React.useCallback(() => {
    setResult(undefined);
  }, []);

  const handleCloseStageEditModal = React.useCallback(() => {
    setSelectedNode((n) => {
      n?.setLocked(false);
      return undefined;
    });
  }, []);

  return (
    <React.Fragment>
      <Container style={{ paddingBottom: 0 }}>
        <Row>
          <Col>
            <h1>Pipeline</h1>
          </Col>
          <Col md="auto">
            <PipelineViewScreenTopButtons
              pipeline={pipeline}
              context={store.context}
              onExecuted={handleExecuted}
              onBeforeExecute={handleBeforeExecute}
            />
          </Col>
        </Row>
        <SingleColRow>
          <h2>Context</h2>
          <ScriptContextForm context={store.context} />
        </SingleColRow>
        <PipelineParamEditor pipeline={pipeline} />
      </Container>
      <SingleColRow style={{ marginTop: 16 }}>
        <FlowEditor pipeline={pipeline} engine={engine} />
      </SingleColRow>
      {selectedNode ? <StageEditorModal stage={selectedNode.stage} onClose={handleCloseStageEditModal} /> : null}
      {result ? (
        <Modal
          width={1920}
          style={{ top: 20 }}
          open={true}
          title={`Pipeline (Debug) Result`}
          onOk={handleCloseResultModal}
          onCancel={handleCloseResultModal}
        >
          <ResultViewer result={result} />
        </Modal>
      ) : null}
    </React.Fragment>
  );
});
