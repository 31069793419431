/**
 *
 * Created by neo on 18.01.17.
 */

import { action, computed, observable, toJS } from 'mobx';
import { ExerciseBlockSet } from './ExerciseBlockSet';
import { Phase } from './Phase';
import { Exercise } from '../Exercise/Exercise';
import { v4 as UUID } from 'uuid';
import { HttpBackend } from '../../Services/Http/HttpBackend';

export class ExerciseBlock {
  @observable
  exerciseBlockId: string = UUID();
  @observable
  description?: string = undefined;
  @observable
  exercise: Exercise = new Exercise();
  @observable
  sets: Array<ExerciseBlockSet> = [];
  @observable
  trackingKeys: Array<string> = [];
  @observable
  alternativeExerciseBlockId?: string = undefined;
  @observable
  phase: Phase;

  constructor(phase?: Phase, json?: any) {
    this.phase = phase || new Phase();
    if (json) {
      this.setData(json);
    }
  }

  toJS(newId?: boolean): any {
    return {
      exerciseBlockId: newId ? UUID() : this.exerciseBlockId,
      description: this.description,
      exercise: this.exercise.toJS(),
      sets: this.sets.map((s) => s.toJS()),
      trackingKeys: toJS(this.trackingKeys),
    };
  }

  copy(): ExerciseBlock {
    const json = this.toJS();
    delete json.exerciseBlockId;
    return new ExerciseBlock(this.phase, json);
  }

  @action
  setBasicData(json: any) {
    this.exerciseBlockId = json.exerciseBlockId || UUID();
    this.description = json.description;
    this.exercise = json.exercise instanceof Exercise ? json.exercise : new Exercise(json.exercise);
    this.trackingKeys = json.trackingKeys || [];
  }

  @action
  updateData(json: any) {
    this.setBasicData(json);
    const diff = this.sets.length - json.sets.length;
    if (diff > 0) {
      console.log(`Removing ${diff} sets start from ${json.sets.length}`);
      this.sets.splice(json.sets.length, diff);
    }
    const length = this.sets.length;
    json.sets.forEach((s: any, i: number) => {
      if (i < length) {
        console.log(`Updating set ${i}`);
        this.sets[i].updateData(s);
      } else {
        console.log(`Pushing set ${i}`);
        this.sets.push(new ExerciseBlockSet(this, s));
      }
    });
  }

  @action
  setData(json: any) {
    this.setBasicData(json);
    this.sets = (json.sets || []).map((s: any) => new ExerciseBlockSet(this, s));
  }

  @action
  createSet(setValues?: any): ExerciseBlockSet {
    const values = setValues || {
      MIN_REPETITIONS: 0,
      MAX_REPETITIONS: 0,
      MIN_WEIGHT: 0,
      MAX_WEIGHT: 0,
      MIN_BREAK: 60,
      MAX_BREAK: 90,
      MIN_DURATION: 60,
      MAX_DURATION: 90,
      WEIGHT: 0,
      REPETITIONS: 0,
    };
    const set = new ExerciseBlockSet(this, { values });
    this.sets.push(set);
    return set;
  }

  calculateCalories(bmr24: number): number {
    return this.sets.reduce((total: number, set: ExerciseBlockSet) => total + set.calculateCalories(bmr24), 0);
  }

  @action
  removeSet(index: number) {
    this.sets.splice(index, 1);
  }

  /**
   * @param userId not needed unless it is used in admin-web and the response should be "personalised"
   */
  async replace(userId?: string) {
    const result = await HttpBackend.post('/coach-workout-creator/replaceExerciseBlock', {
      workoutId: this.phase.workout?.id,
      exerciseBlockId: this.exerciseBlockId,
      variationIds: this.exercise.id,
    });
    if (result) {
      this.setData(result);
    }
  }

  @computed
  get valid(): boolean {
    return true;
  }

  @computed
  get duration(): number {
    return this.sets.reduce((total: number, set: ExerciseBlockSet) => total + set.duration, 0);
  }

  @computed
  get totalTimeExercising(): number {
    return this.sets.reduce((total: number, set: ExerciseBlockSet) => total + set.plannedDurationMs, 0);
  }

  @computed
  get tons(): number {
    return this.sets.reduce((total: number, set: ExerciseBlockSet) => total + set.tons, 0);
  }

  @computed
  get lastSet(): ExerciseBlockSet | undefined {
    const { length } = this.sets;
    if (length > 0) {
      return this.sets[length - 1];
    }
    return undefined;
  }

  @computed
  get firstSet(): ExerciseBlockSet | undefined {
    const { sets } = this;
    if (sets.length > 0) {
      return sets[0];
    }
    return undefined;
  }

  @computed
  get prevSet(): ExerciseBlockSet | undefined {
    if (!this.lastSet) {
      return this.prevBlock && this.prevBlock.prevSet;
    }
    return this.lastSet;
  }

  @computed
  get followingSet(): ExerciseBlockSet | undefined {
    return this.firstSet || (this.nextBlock ? this.nextBlock.followingSet : undefined);
  }

  @computed
  get exerciseBlockIndex(): number {
    return this.phase ? this.phase.exerciseBlocks.findIndex((b) => b.exerciseBlockId === this.exerciseBlockId) : -1;
  }

  @computed
  get isLastBlock(): boolean {
    if (this.phase) {
      const { exerciseBlockIndex } = this;
      return exerciseBlockIndex + 1 === this.phase.exerciseBlocks.length;
    }
    return true;
  }

  @computed
  get prevBlock(): ExerciseBlock | undefined {
    if (this.phase) {
      const { exerciseBlockIndex } = this;
      if (0 === exerciseBlockIndex) {
        return this.phase.prevPhase?.prevBlock;
      }
      return this.phase.exerciseBlocks[exerciseBlockIndex - 1];
    }
    return undefined;
  }

  @computed
  get nextBlock(): ExerciseBlock | undefined {
    if (this.phase) {
      const { exerciseBlockIndex, isLastBlock } = this;
      if (isLastBlock) {
        return this.phase.nextPhase?.nextBlock;
      }
      const nextIndex = exerciseBlockIndex + 1;
      return nextIndex < this.phase.exerciseBlocks.length ? this.phase.exerciseBlocks[nextIndex] : undefined;
    }
    return undefined;
  }

  @computed
  get minDuration(): number {
    let totalTime = 0;
    const sets = (this.sets || []).slice();
    for (const set of sets) {
      totalTime += set.minDuration;
    }
    return totalTime;
  }

  @computed
  get maxDuration(): number {
    let totalTime = 0;
    const sets = (this.sets || []).slice();
    for (const set of sets) {
      totalTime += set.maxDuration;
    }
    return totalTime;
  }
}
