/**
 * Created by neo on 18.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { SingleColRow } from '../../../Components/SingleColRow';
import { WorkoutConversion } from './WorkoutConversion';
import { Gym } from '../../../Model/Gym/Gym';
import { YogaConversion } from './YogaConversion';
import { MeditationConversion } from './MeditationConversion';
import { BreathingConversion } from './BreathingConversion';
import { WorkoutConversionWeekly } from './WorkoutConversionWeekly';
import { PercentageCompleted } from './PercentageCompleted';

export type ActivityConversionTabProps = {
  gym?: Gym;
};

export const ActivityConversionTab: React.FC<ActivityConversionTabProps> = observer(({ gym }) => {
  return (
    <Form layout="vertical">
      <SingleColRow>
        <PercentageCompleted gym={gym} />
      </SingleColRow>
      <SingleColRow>
        <WorkoutConversion gym={gym} />
      </SingleColRow>
      <SingleColRow>
        <WorkoutConversionWeekly gym={gym} />
      </SingleColRow>
      <SingleColRow>
        <WorkoutConversion gym={gym} workoutType="gym_strength" title="Gym Strength Workouts Conversions Daily" />
      </SingleColRow>
      <SingleColRow>
        <WorkoutConversion gym={gym} workoutType="workplace" title="Workplace Workouts Conversions Daily" />
      </SingleColRow>
      <SingleColRow>
        <YogaConversion gym={gym} />
      </SingleColRow>
      <SingleColRow>
        <MeditationConversion gym={gym} />
      </SingleColRow>
      <SingleColRow>
        <BreathingConversion gym={gym} />
      </SingleColRow>
    </Form>
  );
});
