/**
 * Created by neo on 05.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { PipelineStage } from '../../../../../Model/Coach/Stages/PipelineStage';
import { PipelineParam } from '../../../../../Model/Coach/PipelineParam';
import { Input } from 'antd';

export type PipelineStageEditorParamInputProps = {
  stage: PipelineStage;
  param: PipelineParam;
};

export const PipelineStageEditorParamInput: React.FC<PipelineStageEditorParamInputProps> = observer(
  (props: PipelineStageEditorParamInputProps) => {
    const { param, stage } = props;

    const store = useLocalStore(
      (source) => ({
        get value(): any {
          return (stage.config.params ?? {})[source.param.name];
        },
      }),
      props,
    );

    const handleChangeNumber = React.useCallback(
      (value?: number) => {
        stage.setParam(param.name, value ?? 0);
      },
      [stage, param],
    );

    const handleChangeString = React.useCallback(
      ({ target: { value } }) => {
        stage.setParam(param.name, value ?? '');
      },
      [stage, param],
    );

    switch (param.type) {
      // case 'number':
      //   return <InputNumber type="number" onChange={handleChangeNumber} value={store.value ?? 0} />;
      default:
        return <Input onChange={handleChangeString} value={store.value ?? ''} addonAfter={param.type} />;
    }
  },
);
