/**
 * Created by neo on 15.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';

export type UserIdColumnProps = {
  row: AnalyticsRow;
  onUserSelect?: (userId?: string) => void;
};

export const UserIdColumn: React.FC<UserIdColumnProps> = observer(({ row, onUserSelect }) => {
  const engagementTime = row.avg_engagement_seconds ? Number(row.avg_engagement_seconds) : 0;

  const handleUserClick = React.useCallback(
    (e) => {
      e.preventDefault();
      onUserSelect && onUserSelect(row.user_id);
    },
    [onUserSelect, row],
  );

  return (
    <td style={engagementTime < 10 ? { backgroundColor: '#dca1a1' } : undefined}>
      <a href="" onClick={handleUserClick}>
        {row.user_id?.substr(0, 8)}
        {/*{row.user_id}*/}
      </a>
    </td>
  );
});
