/**
 * Created by neo on 29.04.22.
 */
import { TimeOfDay } from '../TimeOfDay';
import { observable } from 'mobx';
import { TimesOfDayJson } from './TimesOfDay';
import { TimeRange, TimeRangeJson } from '../Gym/TimeRange';

export type ScheduleTimeRangeJson = TimeRangeJson & {
  timeOfDay: TimeOfDay;
};

export class ScheduleTimeRange extends TimeRange {
  @observable
  timeOfDay: TimeOfDay = 'any';

  constructor(json?: Partial<ScheduleTimeRangeJson>) {
    super(json);
    if (json) {
      this.timeOfDay = json.timeOfDay ?? 'any';
    }
  }

  toJS(): ScheduleTimeRangeJson {
    return Object.assign(super.toJS(), {
      timeOfDay: this.timeOfDay,
    });
  }
}
