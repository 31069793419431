/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import { Stage } from '../../../../../Model/Coach/Stages/Stage';
import { StageEditorContent } from '../StageEditorContent';
import { PipelineContext } from '../../../../../Model/Coach/PipelineContext';

export type StageEditorModalProps = {
  stage: Stage<any>;
  context?: PipelineContext;
  onExecute?: (stageId: string) => any;
  onClose?: () => any;
};

export const StageEditorModal: React.FC<StageEditorModalProps> = observer(
  ({ stage, context, onClose, onExecute }: StageEditorModalProps) => {
    return (
      <Modal width={1024} open={true} title={stage.type} onOk={onClose} onCancel={onClose}>
        <StageEditorContent stage={stage} context={context} onExecute={onExecute} />
      </Modal>
    );
  },
);
