import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Input, Modal, Table, message, List, Typography } from 'antd';
import { Gym } from '../../../../../../Model/Gym/Gym';
import { Athlete } from '../../../../../../Model/Athlete/Athlete';
import { HttpBackend } from '../../../../../../Services/Http/HttpBackend';
import { v4 as UUID } from 'uuid';
import { useDebouncedEffect } from '../../../../../../Utils/useDebouncedEffect';

export type CustomerCreateModalProps = {
  gym: Gym;
  isOpen?: boolean;
  onClose?: () => void;
};

export const CustomerCreateModal: React.FC<CustomerCreateModalProps> = observer(({ gym, isOpen = true, onClose }) => {
  const [selectedAthletes, setSelectedAthletes] = useState<Athlete[]>([]);
  const [athleteResult, setAthleteResult] = useState<Athlete[]>([]);
  const [query, setQuery] = useState('');

  useDebouncedEffect(() => {
    Athlete.find({
      query,
      size: 20,
    }).then((res) => setAthleteResult(res));
  }, [query]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const toggleAthlete = (athlete: Athlete) => {
    const exists = selectedAthletes.find((a) => a.id === athlete.id);
    if (exists) {
      setSelectedAthletes((prev) => prev.filter((a) => a.id !== athlete.id));
    } else {
      setSelectedAthletes((prev) => [...prev, athlete]);
    }
  };

  const createCustomers = async () => {
    if (!selectedAthletes.length) return;
    try {
      await Promise.all(
        selectedAthletes.map((athlete) =>
          HttpBackend.post('/gym/customer/admin/create', {
            customerId: UUID(),
            athleteId: athlete.id,
            gymId: gym.id,
          }),
        ),
      );
      message.success('Customer(s) created');
      onClose && onClose();
    } catch (err) {
      message.error('Error creating customer(s)');
    }
  };

  const dismiss = () => {
    onClose && onClose();
  };

  const rowSelection = {
    selectedRowKeys: selectedAthletes.map((athlete) => athlete.id),
    onChange: (_: React.Key[], selectedRows: Athlete[]) => {
      setSelectedAthletes(selectedRows);
    },
    preserveSelectedRowKeys: true,
  };

  const removeSelectedAthlete = (athleteId: string) => {
    setSelectedAthletes((prev) => prev.filter((athlete) => athlete.id !== athleteId));
  };

  return (
    <Modal
      open={isOpen}
      onCancel={dismiss}
      title="Create Customer"
      width={800}
      footer={[
        <Button key="create" type="primary" onClick={createCustomers} disabled={selectedAthletes.length === 0}>
          Create Customer ({selectedAthletes.length})
        </Button>,
        <Button key="cancel" type="dashed" onClick={dismiss}>
          Cancel
        </Button>,
      ]}
    >
      <div style={{ display: 'flex', gap: 16 }}>
        {/* Left Column: Search and Table */}
        <div style={{ flex: 1 }}>
          <Input
            allowClear
            placeholder="Search athletes"
            onChange={handleChange}
            value={query}
            style={{ marginBottom: 16 }}
          />
          <Table
            dataSource={athleteResult}
            rowKey="id"
            pagination={false}
            size="small"
            rowSelection={rowSelection}
            onRow={(record: Athlete) => ({
              onClick: () => toggleAthlete(record),
            })}
            columns={[
              {
                title: 'Name',
                dataIndex: 'fullName',
                key: 'name',
              },
              {
                title: 'Email',
                key: 'email',
                render: (_: any, record: Athlete) => record.user?.email,
              },
            ]}
          />
        </div>
        {/* Right Column: Selected Athletes */}
        <div style={{ width: 300 }}>
          <Typography.Title level={5}>Selected Athletes</Typography.Title>
          {selectedAthletes.length > 0 ? (
            <List
              bordered
              dataSource={selectedAthletes}
              renderItem={(athlete) => (
                <List.Item
                  actions={[
                    <Button key="delete" type="link" onClick={() => removeSelectedAthlete(athlete.id)}>
                      Remove
                    </Button>,
                  ]}
                >
                  <div>
                    <Typography.Text>{athlete.fullName}</Typography.Text>
                    <br />
                    <Typography.Text type="secondary">{athlete.user?.email}</Typography.Text>
                  </div>
                </List.Item>
              )}
            />
          ) : (
            <Typography.Text>No athletes selected</Typography.Text>
          )}
        </div>
      </div>
    </Modal>
  );
});
