/**
 * Created by neo on 13.09.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Gym } from '../Model/Gym/Gym';
import AsyncSelect from 'react-select/async';

export type GymSelectorProps = {
  gymId?: string;
  onChange?: (gym?: Gym | undefined) => void;
};

export const GymSelector: React.FC<GymSelectorProps> = observer(({ gymId, onChange }) => {
  const [selectedGym, setSelectedGym] = useState<Gym | undefined>();

  useEffect(() => {
    if (gymId) {
      Gym.get(gymId).then((res) => setSelectedGym(res));
    } else {
      setSelectedGym(undefined);
    }
  }, [gymId]);

  const handleRemove = React.useCallback(
    (ingredient: Gym) => {
      onChange && onChange();
    },
    [onChange],
  );

  const fetchObject = React.useCallback((query: string) => Gym.find({ query }), []);

  const optionLabel = React.useCallback((obj) => obj.name, []);

  const optionValue = React.useCallback((obj) => obj, []);

  const handleChangeObject = React.useCallback(
    (value?: Gym) => {
      onChange && onChange(value);
    },
    [onChange],
  );

  return (
    <AsyncSelect
      cacheOptions
      value={selectedGym}
      isClearable={true}
      loadOptions={fetchObject as any}
      getOptionLabel={optionLabel}
      getOptionValue={optionValue as any}
      onChange={handleChangeObject as any}
    />
  );
});
