/**
 *
 * Created by andreaskarantzas on 24.03.21.
 */
import { observable } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Audited, AuditedJson } from '../Audited';

export type NotificationTokenJson = AuditedJson & {
  athleteId: string;
  system: string;
  type: string;
  token: string;
};

export class NotificationToken extends Audited {
  @observable
  type: string = 'mobile';
  @observable
  system: string = '';
  @observable
  token = '';
  @observable
  athleteId?: string;

  constructor(json?: Partial<NotificationTokenJson>) {
    super(json);
    if (json) {
      this.athleteId = json.athleteId;
      this.system = json.system ?? '';
      this.type = json.type ?? 'mobile';
      this.token = json.token ?? '';
    }
  }

  static async list(athleteId: string): Promise<NotificationToken[]> {
    return HttpBackend.get('/messaging/notification/admin/token', {
      athleteId,
      sort: 'updatedAt,DESC',
    });
  }
}
