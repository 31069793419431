// File: application/src/App/View/MetaData/Translations/TranslationTable.tsx
import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { MergedTranslation } from '../../../Model/Translation/MergedTranslation';
import { TranslationTableRow } from './TranslationTableRow';

export type TranslationTableProps = {
  mergedTranslations: MergedTranslation[];
  namespaces: string[];
};

export const TranslationTable: React.FC<TranslationTableProps> = observer(({ mergedTranslations, namespaces }) => {
  // Only one column for the translation row.
  const columns = [
    {
      title: 'Key & Value',
      key: 'content',
      render: (_: any, record: MergedTranslation, index: number) => (
        <TranslationTableRow mergedTranslation={record} index={index} namespaces={namespaces} />
      ),
    },
  ];

  return <Table rowKey="id" dataSource={mergedTranslations} columns={columns} pagination={false} />;
});
