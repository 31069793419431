/**
 * Created by neo on 16.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import {
  CartesianGrid,
  Legend,
  Line,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,

} from 'recharts';
import { useState } from 'react';

export type UserRetentionChartProps = {
  data: AnalyticsRow[];
  enabledFields?: { [key: string]: boolean };
  onPressLegend?: (data: any) => void;
};

export const UserRetentionChart: React.FC<UserRetentionChartProps> = observer(({ data, onPressLegend }) => {
  const [enabledFields, setEnabledFields] = useState<{ [key: string]: boolean }>({});

  const handleLegendClick = React.useCallback(
    (data) => {
      const dataKey = typeof data.dataKey === 'string' ? data.dataKey : 'avg_activities_per_user';
      setEnabledFields((existing) => Object.assign({}, existing, { [dataKey]: !existing[dataKey] }));
      onPressLegend && onPressLegend(data);
    },
    [onPressLegend],
  );

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart height={250} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="weekIndex" />
        <YAxis yAxisId="left" label={{ value: '# Users', position: 'insideTopLeft', dy: -25 }} />
        <YAxis
          yAxisId="right"
          orientation="right"
          tickLine={false}
          axisLine={false}
          label={{ value: 'Active Users %', position: 'insideTopRight', dy: -25 }}
        />
        <Tooltip />
        <Legend onClick={handleLegendClick} />
        <Bar
          yAxisId="left"
          dataKey="open_once_per_user"
          stackId="a"
          fill="#0723D9"
          name="Once opened per user"
          hide={enabledFields.open_once_per_user}
        />
        <Bar
          yAxisId="left"
          dataKey="total_active_users"
          stackId="b"
          fill="#D2A45F"
          name="Active Users (min. x activities)"
          hide={enabledFields.total_active_users}
        />

        {/*<Line type="monotone" dataKey="possible_total_users" stroke="#ff0000" />*/}
        {/*<Line*/}
        {/*  yAxisId="left"*/}
        {/*  type="monotone"*/}
        {/*  dataKey="total_active_users"*/}
        {/*  stroke="#ff0000"*/}
        {/*  strokeWidth={3}*/}
        {/*  name="Active Users (min. x activities)"*/}
        {/*  dot={false}*/}
        {/*  hide={enabledFields.total_activities_count === true}*/}
        {/*/>*/}
        {/*<Line*/}
        {/*  yAxisId="left"*/}
        {/*  type="monotone"*/}
        {/*  dataKey="total_users"*/}
        {/*  stroke="#000000"*/}
        {/*  strokeWidth={3}*/}
        {/*  name="Total Users"*/}
        {/*  dot={false}*/}
        {/*  hide={enabledFields.total_activities_count === true}*/}
        {/*/>*/}
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="user_percentage_active"
          stroke="#C06D99"
          strokeWidth={3}
          name="Active Users % (min. x activities)"
          dot={false}
          hide={enabledFields.user_percentage_active}
        />

        <Line
          yAxisId="right"
          type="monotone"
          dataKey="user_percentage"
          stroke="#ff0000"
          strokeWidth={3}
          name="Total Active Users % (from total potential)"
          dot={false}
          hide={enabledFields.user_percentage}
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="open_percentage"
          stroke="#125F7A"
          strokeWidth={3}
          name="Users opened app %"
          dot={false}
          hide={enabledFields.open_percentage}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
});
