/**
 * Created by neo on 09.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { EarnModelTemplateRuleSimple } from '../../../../../Model/Engagement/EarnModelTemplateRuleSimple';
import { EarnModelRuleActivationLogicOrBlock } from '../../../../../Model/Engagement/EarnModelRuleActivationLogicOrBlock';
import { EarnModelRuleActivationLogicEntry } from '../../../../../Model/Engagement/EarnModelRuleActivationLogicEntry';
import { Button, Card, Form, InputNumber, Popconfirm, Select } from 'antd';
import { runInAction } from 'mobx';
import { Col, Row } from 'reactstrap';
import { DurationInput } from '../../../../../Components/DurationInput';
import { ActivationLogicValueCriteriaEditor } from './ActivationLogicValueCriteriaEditor';

export type RuleLogicBlockEntryEditorProps = {
  rule: EarnModelTemplateRuleSimple;
  logicBlock: EarnModelRuleActivationLogicOrBlock;
  index: number;
  entry: EarnModelRuleActivationLogicEntry;
};

export const RuleLogicBlockEntryEditor: React.FC<RuleLogicBlockEntryEditorProps> = observer(
  ({ rule, logicBlock, index, entry }) => {
    const handleRemove = React.useCallback(
      () => runInAction(() => logicBlock.entries.splice(index, 1)),
      [logicBlock, index],
    );

    const handleChangeEvent = React.useCallback((newValue) => runInAction(() => (entry.event = newValue)), [entry]);

    const handleChangeExpires = React.useCallback((newValue) => runInAction(() => (entry.expires = newValue)), [entry]);

    const handleChangeCount = React.useCallback(
      (newValue) => runInAction(() => (entry.count = Number(newValue) ?? 1)),
      [entry],
    );

    return (
      <Card
        size="small"
        title={`${index + 1}. Entry`}
        extra={
          <Popconfirm title="Remove Entry?" onConfirm={handleRemove}>
            <Button type="link" size="small">
              Remove
            </Button>
          </Popconfirm>
        }
      >
        <Row>
          <Col xs={6}>
            <Select value={entry.event} onChange={handleChangeEvent}>
              <Select.Option value="signup">Signup</Select.Option>
              <Select.Option value="activity_logged">Activity Logged</Select.Option>
              <Select.Option value="questionnaire_answered ">Questionnaire Answered</Select.Option>
              <Select.Option value="fitness_program_started">Fitness Program Started</Select.Option>
              <Select.Option value="fitness_program_completed">Fitness Program Completed</Select.Option>
              <Select.Option value="mindfulness_program_started">Mindfulness Program Started</Select.Option>
              <Select.Option value="mindfulness_program_completed">Mindfulness Program Completed</Select.Option>
              <Select.Option value="explore_content_consumed">Explore Content Consumed</Select.Option>
              <Select.Option value="explore_content_scheduled">Explore Content Scheduled</Select.Option>
              <Select.Option value="steps_walked">Steps Walked</Select.Option>
              <Select.Option value="challenge_joined">Challenge Joined</Select.Option>
              <Select.Option value="challenge_completed">Challenge Completed</Select.Option>
              <Select.Option value="tracker_connected">Tracker Connected</Select.Option>
              <Select.Option value="health_data_changed">Health Data Changed</Select.Option>
              <Select.Option value="article_read">Article read</Select.Option>
              <Select.Option value="recipe_read">Recipe read</Select.Option>
              <Select.Option value="webinar_watched">Webinar Watched</Select.Option>
            </Select>
          </Col>
          <Col xs={6}>
            <Form.Item label="Count" extra="How often does the event need to occur">
              <InputNumber min={1} max={100} value={entry.count} onChange={handleChangeCount} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Value Criteria" extra="Optional value criteria (expert mode)">
              <ActivationLogicValueCriteriaEditor rule={rule} entry={entry} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label="Expires"
              extra="Optional only when multiple events are defined a the following event needs to happen within a given timeframe"
            >
              <DurationInput onChange={handleChangeExpires} value={entry.expires} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    );
  },
);
