/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { runInAction } from 'mobx';
import { MindfulnessProgramTemplate } from '../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplate';
import { MindfulnessProgramTemplatePhaseEditor } from './PhaseEditor/MindfulnessProgramTemplatePhaseEditor';

export type MindfulnessProgramTemplatePhasesEditorProps = {
  template: MindfulnessProgramTemplate;
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const MindfulnessProgramTemplatePhasesEditor: React.FC<MindfulnessProgramTemplatePhasesEditorProps> = observer(({ template }) => {
  const handleRemove = React.useCallback(
    (phase) => {
      runInAction(() => (template.phases = template.phases.filter((m) => m.id !== phase.id)));
    },
    [template],
  );

  return (
    <Container>
      {template.phases.map((phase, index) => (
        <MindfulnessProgramTemplatePhaseEditor
          key={phase.id}
          template={template}
          phase={phase}
          onRemove={handleRemove}
          index={index}
          headerColor="#FFAD87"
        />
      ))}
    </Container>
  );
});
