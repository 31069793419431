import React, { useEffect, useState } from 'react';
import { Observer, observer } from 'mobx-react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Gym } from '../../../../../Model/Gym/Gym';
import { CustomerActivityLog } from '../../../../../Model/Statistics/CustomerActivityLog';
import { PageResult } from '../../../../../Model/PageResult';
import { SingleColRow } from '../../../../../Components/SingleColRow';

dayjs.extend(duration);

const SIZE = 50;

// Map local sort fields to remote sort keys.
const mapSortField = (field: string): string => {
  if (field === 'startDayjs') return 'startDateTime';
  if (field === 'endDayjs') return 'endDateTime';
  return field;
};

export type GymActivityLogTabProps = {
  gym: Gym;
};

export const GymActivityLogTab: React.FC<GymActivityLogTabProps> = observer(({ gym }) => {
  const [result, setResult] = useState(new PageResult<CustomerActivityLog>());
  const [page, setPage] = useState(0);

  // Loads logs with remote sort.
  const loadLogs = (options: { page: number; sort?: string } = { page: 0 }) => {
    const sort = options.sort || 'startDateTime,DESC';
    PageResult.execute(
      CustomerActivityLog.findAll({
        gymId: gym.id,
        page: options.page,
        size: SIZE,
        sort,
      }),
      CustomerActivityLog.count({
        gymId: gym.id,
      }),
      options.page,
      SIZE,
    ).then(setResult);
  };

  useEffect(() => {
    loadLogs({ page: 0 });
  }, [gym]);

  const handleTableChange = (pagination: any, _filters: any, sorter: any) => {
    const newPage = (pagination.current || 1) - 1;
    const localSortField = sorter.field || 'startDayjs';
    const remoteSortField = mapSortField(localSortField);
    const sortOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC';
    loadLogs({ page: newPage, sort: `${remoteSortField},${sortOrder}` });
    setPage(newPage);
  };

  const columns = [
    {
      title: 'Athlete',
      dataIndex: 'athlete',
      key: 'athlete',
      render: (_: any, a: CustomerActivityLog) => (
        <Observer>{() => <Link to={`/athlete/${a.athleteId}`}>{a.athlete?.fullName || a.athleteId}</Link>}</Observer>
      ),
    },
    {
      title: 'Activity',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
    },
    {
      title: 'Start Time',
      key: 'startDayjs',
      render: (_: any, record: CustomerActivityLog) =>
        record.startDayjs ? record.startDayjs.format('DD.MM.YYYY HH:mm') : '-',
      sorter: true,
    },
    {
      title: 'End Time',
      key: 'endDayjs',
      render: (_: any, record: CustomerActivityLog) =>
        record.endDayjs ? record.endDayjs.format('DD.MM.YYYY HH:mm') : '-',
      sorter: true,
    },
    {
      title: 'Duration',
      key: 'duration',
      render: (_: any, record: CustomerActivityLog) =>
        record.durationMs ? dayjs.duration(record.durationMs).format('HH:mm') : 'running',
      sorter: (a: CustomerActivityLog, b: CustomerActivityLog) => (a.durationMs || 0) - (b.durationMs || 0),
    },
    {
      title: `Source`,
      dataIndex: `source`,
      key: `source`,
      render: (_: any, record: CustomerActivityLog) => {
        if (!record.source) return `-`;
        if (Array.isArray(record.source)) {
          return record.source.join(`, `);
        }
        if (typeof record.source === `object`) {
          const { source, sourceType, sourceId } = record.source;
          return (
            <>
              <div>{source ?? `-`}</div>
              <div>{sourceType ?? `-`}</div>
              <div>{sourceId ?? `-`}</div>
            </>
          );
        }
        return `${record.source}`;
      },
    },
  ];

  return (
    <>
      <SingleColRow>
        <Table
          rowKey="id"
          dataSource={result.content.slice()}
          columns={columns}
          pagination={{
            current: page + 1,
            pageSize: SIZE,
            total: result.totalElements,
            showSizeChanger: false,
          }}
          onChange={handleTableChange}
          size="small"
        />
      </SingleColRow>
    </>
  );
});
