/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Table } from 'reactstrap';
import { observer } from 'mobx-react';
import { AthleteDeviceInfo } from '../../../../Model/Athlete/AthleteDeviceInfo';
import { DeviceInfoTableRow } from './DeviceInfoTableRow';

export type DeviceInfoTableProps = {
  deviceInfos: AthleteDeviceInfo[];
};

export const DeviceInfoTable: React.FC<DeviceInfoTableProps> = observer(({ deviceInfos }) => {
  return (
    <Table striped size="sm">
      <thead>
        <tr>
          <th>App</th>
          <th>Manuf</th>
          <th>Device</th>
          <th>OS</th>
          <th>App Version</th>
          <th>Bundle Id</th>
          <th>Time</th>
        </tr>
      </thead>
      {deviceInfos.map((device) => (
        <DeviceInfoTableRow key={device.id} deviceInfo={device} />
      ))}
    </Table>
  );
});
