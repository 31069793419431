/**
 *
 * Created by neo on 03.01.17.
 */

import { computed, observable, onBecomeObserved, runInAction } from 'mobx';
import { User, UserJson } from '../User';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Media, MediaJson } from '../Media/Media';
import { PageResult } from '../PageResult';
import { Token } from '../../Services/Security/Token';
import { v4 as UUID } from 'uuid';
import { Gender } from '../Person/Gender';
import { EMPTY_ARRAY } from '../../Utils/Constants';
import { Audited, AuditedJson } from '../Audited';
import { AthleteConfiguration } from './AthleteConfiguration';
import { Gym } from '../Gym/Gym';
import { ActivityLog } from '../Activity/ActivityLog';

export type AthleteJson = AuditedJson & {
  user?: UserJson;
  nickname: string;
  firstname?: string;
  lastname?: string;
  birthYear?: number;
  gender?: Gender;
  profilePicture?: MediaJson;
  tags: string[];
  apps: string[];
};

export class Athlete extends Audited {
  @observable
  nickname: string = '';
  @observable
  firstname?: string;
  @observable
  lastname?: string;
  @observable
  birthYear?: number;
  @observable
  gender: Gender = 'UNKNOWN';
  @observable
  profilePicture?: Media;
  @observable
  user: User = new User();
  @observable
  tags: string[] = [];
  @observable
  apps: string[] = [];
  @observable
  gyms?: Gym[];
  @observable
  lastActivity?: AthleteConfiguration = undefined;
  @observable
  loggedActivities?: number;

  constructor(json?: AthleteJson) {
    super(json);
    if (json) {
      this.id = json.id || UUID();
      this.nickname = json.nickname || '';
      this.firstname = json.firstname;
      this.lastname = json.lastname;
      this.birthYear = json.birthYear;
      this.gender = json.gender || 'UNKNOWN';
      this.profilePicture = json.profilePicture ? new Media(json.profilePicture) : undefined;
      this.user = new User(json.user);
      this.tags = json.tags ?? [];
      this.apps = json.apps ?? [];
    }

    onBecomeObserved(this, 'lastActivity', this.fetchLastDeviceLog);
    onBecomeObserved(this, 'gyms', this.fetchGyms);
    onBecomeObserved(this, 'loggedActivities', this.fetchLoggedActivities);
  }

  fetchLoggedActivities = async () => {
    if (!this.loggedActivities) {
      this.loggedActivities = await ActivityLog.count({ athleteId: this.id });
    }
    return this.loggedActivities;
  };

  fetchGyms = async () => {
    if (!this.gyms) {
      this.gyms = await Gym.listByAthleteId(this.id);
    }
    return this.gyms;
  };

  toJS(): any {
    return Object.assign(super.toJS(), {
      id: this.id,
      nickname: this.nickname,
      firstname: this.firstname,
      lastname: this.lastname,
      birthYear: this.birthYear,
      gender: this.gender,
      profilePicture: this.profilePicture?.toJS(),
      user: this.user.toJS(),
      tags: this.tags,
    });
  }

  fetchLastDeviceLog = async () => {
    if (!this.lastActivity) {
      AthleteConfiguration.get(this.id).then((res) => runInAction(() => (this.lastActivity = res)));
    }
  };

  remove() {
    return HttpBackend.delete(`/athlete/admin/${this.id}`);
  }

  save(): Promise<Athlete> {
    return HttpBackend.post('/athlete/admin', this.toJS()).then(() => this);
  }

  @computed
  get initials(): string {
    return (this.firstname?.trim().split(' ') ?? EMPTY_ARRAY)
      .map((str) => str.charAt(0))
      .join('')
      .toUpperCase();
  }

  @computed
  get fullName(): string {
    return this.firstname?.trim() ?? '';
  }

  static async get(athleteId: string): Promise<Athlete | undefined> {
    const data = await HttpBackend.get(`/athlete/admin/${athleteId}`);
    if (data) {
      return new Athlete(data);
    }
    return undefined;
  }

  static find(params: any = { page: 0, sort: 'firstname,ASC' }): Promise<Athlete[]> {
    return HttpBackend.get(`/athlete/admin`, params).then((data) => data.map((a) => new Athlete(a)));
  }

  static count(params: any = { page: 0, sort: 'firstname,ASC' }): Promise<number> {
    return HttpBackend.get(`/athlete/admin/count`, params);
  }

  static async findByName(
    name?: string,
    pageable: any = { page: 0, sort: 'firstname,ASC' },
  ): Promise<PageResult<Athlete>> {
    const data = await HttpBackend.get(`/athlete/admin`, { name, ...pageable });
    if (data) {
      data.content = data.content.map((a) => new Athlete(a));
      return new PageResult(data);
    }
    return new PageResult();
  }

  static async findByNickname(nickname: string): Promise<Athlete | undefined> {
    const data = await HttpBackend.get(`/athlete/admin/findByNickname`, { nickname });
    if (data) {
      return new Athlete(data);
    }
    return undefined;
  }

  static me(): Promise<Athlete> {
    return HttpBackend.get('/athlete/me').then((res) => new Athlete(res));
  }
}
