/**
 * @flow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { ExerciseVariationFilterMultipleSelection, ValueEntry } from './ExerciseVariationFilterMultipleSelection';

export type Props = {
  filter: ExerciseFilter;
};

const Values = [
  { value: 'BASIC', label: 'Basic' },
  { value: 'AUXILIARY', label: 'Auxiliary' },
];

@observer
export class ExerciseVariationFilterUtility extends React.Component<Props> {
  handleChange = (values: ValueEntry[]) => {
    this.props.filter.utilities = (values ?? []).map((e) => e.value);
  };

  render() {
    const {
      props: { filter },
    } = this;
    return (
      <ExerciseVariationFilterMultipleSelection
        label="Utility"
        container={filter.utilities || []}
        values={Values}
        onChange={this.handleChange}
      />
    );
  }
}

// const ExerciseVariationsTab = withRouter(ExerciseVariationsTabComponent);
// export default ExerciseVariationsTab;
