/**
 * Created by neo on 23.04.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Gym } from '../../../Model/Gym/Gym';
import dayjs from 'dayjs';
import { AnalyticsData, AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { usersActive } from './Queries/queryUsersActive';
import { Col, Row } from 'reactstrap';
import { DatePicker, Form, InputNumber, Space, Typography } from 'antd';
import { UserActivityPercentageChart } from './UserActivityPercentageChart';

export type UserActivityPercentageProps = {
  gym?: Gym;
  title: string;
  resolution: 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER';
};

export const UserActivityPercentage: React.FC<UserActivityPercentageProps> = observer(({ gym, title, resolution }) => {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(dayjs());
  const [minActive, setMinActive] = useState(1);
  const [data, setData] = useState<AnalyticsRow[]>([]);

  const totalUsers = data?.reduce((max, curr) => Math.max(max, Number(curr.total_users_cumulative)), 0) ?? 0;

  useEffect(() => {
    setData([]);
    Gym.find({ size: 200 })
      .then((result) => result.filter((g) => !!g.configuration.configuration.get('excludeInAnalytics')).map(g => g.id)).then((excludedGymIds) =>
      AnalyticsData.query({
        query: usersActive(gym, resolution, excludedGymIds),
        parameters: {
          gym_created_date: {
            value: gym?.createdAt ? dayjs(gym.createdAt).format('YYYYMMDD') : '',
          },
          gym_created_date_iso: {
            value: gym?.createdAt ? dayjs(gym.createdAt).format('YYYY-MM-DD') : '',
          },
          end_date: {
            value: endDate.format('YYYYMMDD'),
          },
          end_date_iso: {
            value: endDate.format('YYYY-MM-DD'),
          },
          gym_id: {
            value: gym?.id ?? '',
          },
          min_active: {
            value: minActive.toString(),
            type: 'number',
          },
        },
      }).then((data) =>
        setData(
          data.map((d) =>
            Array.from(Object.entries(d))
              .map(([key, value]) => [key, key === 'minWeek' ? value : Number(value)])
              .reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {}),
          ),
        ),
      ),
    );
  }, [gym, startDate, endDate, resolution, minActive]);

  const handleChangeValidFrom = React.useCallback(
    (date) => setStartDate(date ?? dayjs().subtract(1, 'month')),
    [],
  );

  const handleChangeValidUntil = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

  const handleChangeMinActive = React.useCallback((e) => setMinActive(Number(e) ?? 1), []);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h5>{title}</h5>
        </Col>
        <Col xs="auto">
          <h6>{`Total Users: ${totalUsers}`}</h6>
        </Col>
        <Col xs={12}>
          <Space>
            <Form.Item label="End Date">
              <DatePicker value={endDate} onChange={handleChangeValidUntil}/>
            </Form.Item>
            <Form.Item label="Min. Activities">
              <InputNumber min={0} max={30} step={1} value={minActive} onChange={handleChangeMinActive}/>
            </Form.Item>
          </Space>
        </Col>
        <Col xs={12} style={{ paddingBottom: 16 }}>
          <Typography.Text type="secondary">
            The percentage of active users (compared to all possible users for that time) within a day/week/month/quarter.
            <br />This chart is useful to determine if certain measures had an impact on the usage of the app. It answers
            the simple question of &quot;How many users were active during that day/week/month/quarter&quot;.
            <br />(&apos;New Users&apos; are accumulated and the total of unique active users for that
            day/week/month/quarter is then divided by the accumulated sum of new users.)
          </Typography.Text>
        </Col>
        <Col xs={12}><UserActivityPercentageChart data={data}/></Col>
      </Row>
    </React.Fragment>
  );
});
