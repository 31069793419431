/**
 * Created by neo on 01.02.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { runInAction } from 'mobx';
import { BreathingEntry } from '../../../../../Model/Explore/BreathingEntry';
import { BreathingTechniqueEntry } from './BreathingTechniqueEntry';
import { BreathingTechniqueStep } from '../../../../../Model/Explore/BreathingTechniqueStep';
import { Col, Row } from 'reactstrap';
import { MultiRowMap } from '../../../../Security/OAuth/View/MultiRowMap';

export type BreathingEntryContentProps = {
  entry: BreathingEntry;
};

export const BreathingEntryContent: React.FC<BreathingEntryContentProps> = observer(({ entry }) => {
  const handleAddTechniqueStep = React.useCallback(
    () => runInAction(() => entry.technique.push(new BreathingTechniqueStep())),
    [entry],
  );

  return (
    <React.Fragment>
      <Row>
        <Col>
          <MultiRowMap label="Data" container={entry.data} />
        </Col>
        <Col>
          <h5>Technique steps</h5>
          {entry.technique.map((step, index) => (
            <div key={index.toString()} style={{ marginBottom: 16 }}>
              <BreathingTechniqueEntry entry={entry} techniqueStep={step} index={index} />
            </div>
          ))}
          <Button block onClick={handleAddTechniqueStep}>
            Add technique step
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
});
