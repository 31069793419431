import * as React from 'react';
import { observer } from 'mobx-react';
import { useState, useEffect, useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { Form, Input, Select, Pagination } from 'antd';
import { Container, Row, Col } from 'reactstrap';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { ExploreEntryListEntryCard } from './ExploreEntryListEntryCard';
import { Gym } from '../../../../Model/Gym/Gym';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { ExploreEntryBuilder } from '../../../../Model/Explore/ExploreEntryBuilder';
import { PageResult } from '../../../../Model/PageResult';
import { useDebouncedEffect } from '../../../../Utils/useDebouncedEffect';
import { PublishState } from '../../../../Model/Explore/ExploreEntryQueryRequest';
import { ExploreContentEditModal } from '../ExploreContentEditModal';

const DEFAULT_SIZE = 52;

const EntryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const EntryWrapper = styled.div`
  flex: 1 0 calc(33.33% - 16px);
  min-width: 250px;
`;

export const ExploreEntriesListScreen: React.FC = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const gymId = searchParams.get('gymId') || undefined;
  const type = searchParams.get('type') || undefined;
  const publishState = searchParams.get('publishState') || 'all';
  const [contents, setContents] = useState<PageResult<ExploreEntry>>(new PageResult());
  const [selectedGym, setSelectedGym] = useState<Gym | undefined>();
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [language, setLanguage] = useState('en');
  const [pageSize, setPageSize] = useState(DEFAULT_SIZE);

  const loadContent = React.useRef(
    (
      gymId?: string,
      query?: string,
      type?: string,
      publishState?: string,
      page: number = 0,
      size: number = DEFAULT_SIZE,
    ) =>
      PageResult.execute(
        ExploreEntryBuilder.find({
          gymId,
          page,
          query,
          ignoreGymId: !gymId,
          matchGymId: !!gymId,
          type: type ? [type] : [],
          publishState: publishState && publishState !== 'all' ? (publishState as PublishState) : undefined,
          size,
          sort: 'createdAt,DESC',
        }),
        ExploreEntryBuilder.count({
          gymId,
          query,
          type: type ? [type] : [],
          publishState: publishState && publishState !== 'all' ? (publishState as PublishState) : undefined,
          ignoreGymId: !gymId,
          matchGymId: !!gymId,
        }),
        page,
        size,
      ),
  ).current;

  useEffect(() => {
    setSelectedGym(undefined);
    if (gymId) {
      Gym.get(gymId).then((result) => setSelectedGym(result));
    }
  }, [gymId]);

  useDebouncedEffect(() => {
    setContents(new PageResult());
    loadContent(gymId, query, type, publishState, page, pageSize).then((res: PageResult<ExploreEntry>) =>
      setContents(res),
    );
  }, [page, query, type, gymId, publishState, pageSize]);

  const fetchGym = useCallback((q: string) => Gym.find({ query: q, size: 100 }), []);

  const handleGymChange = useCallback(
    (gym?: any | null) => {
      setSearchParams((prev) => {
        const params = { ...Object.fromEntries(prev), gymId: gym?.id || '' };
        return new URLSearchParams(params);
      });
    },
    [setSearchParams],
  );

  const handleChangeQuery = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    setPage(0);
  }, []);

  const handleChangeType = useCallback(
    (value: string) => {
      setPage(0);
      setSearchParams(
        (prev) => new URLSearchParams({ ...Object.fromEntries(prev), type: value === 'all' ? '' : value }),
      );
    },
    [setSearchParams],
  );

  const handleChangePublishState = useCallback(
    (value: string) => {
      setPage(0);
      setSearchParams(
        (prev) => new URLSearchParams({ ...Object.fromEntries(prev), publishState: value === 'all' ? '' : value }),
      );
    },
    [setSearchParams],
  );

  const handlePageChange = useCallback(
    (newPage: number, newPageSize?: number) => {
      setPage(newPage - 1);
      if (newPageSize && newPageSize !== pageSize) {
        setPageSize(newPageSize);
        setPage(0);
      }
    },
    [pageSize],
  );

  return (
    <>
      <Form layout="vertical" style={{ marginBottom: 24 }}>
        <Container>
          <Row gutter={[16, 16]}>
            <Col xs={12} md={6}>
              <Form.Item label="Search">
                <Input allowClear value={query} onChange={handleChangeQuery} placeholder="Search..." />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item label="Company">
                <AsyncSelect
                  cacheOptions
                  value={selectedGym}
                  isClearable={true}
                  loadOptions={fetchGym as any}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.id}
                  onChange={handleGymChange as any}
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item label="Type">
                <Select value={type || 'all'} onChange={handleChangeType}>
                  <Select.Option value="all">All</Select.Option>
                  <Select.Option value="article">Article</Select.Option>
                  <Select.Option value="workplace">Workplace</Select.Option>
                  <Select.Option value="workoutTemplate">Workout Template</Select.Option>
                  <Select.Option value="activityWorkout">Activity Workout</Select.Option>
                  <Select.Option value="meditation">Meditation</Select.Option>
                  <Select.Option value="breathing">Breathing</Select.Option>
                  <Select.Option value="yoga">Yoga</Select.Option>
                  <Select.Option value="video">Video</Select.Option>
                  <Select.Option value="webinar">Webinar</Select.Option>
                  <Select.Option value="link">Link</Select.Option>
                  <Select.Option value="onlineMeeting">Online Meeting</Select.Option>
                  <Select.Option value="offlineMeeting">Offline Meeting</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item label="Display Language">
                <Select defaultValue="en" value={language} onChange={setLanguage}>
                  <Select.Option value="en">EN</Select.Option>
                  <Select.Option value="de">DE</Select.Option>
                  <Select.Option value="fr">FR</Select.Option>
                  <Select.Option value="it">IT</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={12} md={6}>
              <Form.Item label="Publish State">
                <Select value={publishState} onChange={handleChangePublishState}>
                  <Select.Option value="all">All</Select.Option>
                  <Select.Option value="draft">Draft</Select.Option>
                  <Select.Option value="published">Published</Select.Option>
                  <Select.Option value="unpublished">Unpublished</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Container>
      </Form>
      <Container>
        <Pagination
          current={page + 1}
          pageSize={pageSize}
          total={contents.totalElements}
          onChange={handlePageChange}
          onShowSizeChange={handlePageChange}
          showTotal={(total) => `Total: ${total}`}
          style={{ marginBottom: 16, textAlign: 'center' }}
        />
        <EntryContainer>
          {contents.content &&
            contents.content.map((entry: ExploreEntry) => (
              <EntryWrapper key={entry.id}>
                <ExploreEntryListEntryCard entry={entry} gymId={entry.gymId} language={language} />
              </EntryWrapper>
            ))}
        </EntryContainer>
        <Pagination
          current={page + 1}
          pageSize={pageSize}
          total={contents.totalElements}
          onChange={handlePageChange}
          onShowSizeChange={handlePageChange}
          showTotal={(total) => `Total: ${total}`}
          style={{ marginTop: 16, textAlign: 'center' }}
        />
      </Container>
      <Routes>
        <Route path=":contentId" element={<ExploreContentEditModal />} />
      </Routes>
    </>
  );
});
