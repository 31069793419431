/**
 *
 * Created by neo on 25.02.17.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Label, Card, CardBody, Row, Col, FormGroup } from 'reactstrap';

import { BodyPartCard } from '../BodyPartCard';
import { ExerciseVariationAdmin } from '../../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { EquipmentTypeInput } from '../../../../../Components/EquipmentTypeInput';
import { ViewMedia, ViewMediaProps } from '../../../../../Components/ViewMedia/ViewMedia';
import { TranslationInputArray } from '../../../../../Components/Translation/TranslationInputArray';
import { TrackingKeysSelectionView } from '../TrackingKeysSelectionView';
import { ExerciseVariationTags } from './ExerciseVariationTags';
import { Form, InputNumber } from 'antd';
import { ExerciseTypeSelect } from './ExerciseTypeSelect';
import { ExerciseForceSelect } from './ExerciseForceSelect';
import { ExerciseMechanicsSelect } from './ExerciseMechanicsSelect';
import { ExerciseUtilitySelect } from './ExerciseUtilitySelect';
import { ExerciseExecutionTypeSelect } from './ExerciseExecutionTypeSelect';
import { ExercisePositionSelect } from './ExercisePositionSelect';
import { ExerciseHipFlexingSelect } from './ExerciseHipFlexingSelect';
import { ExerciseSpineFlexingSelect } from './ExerciseSpineFlexingSelect';
import { ExerciseSpineForceTypeSelect } from './ExerciseSpineForceTypeSelect';
import { BodyPartJointCard } from '../BodyPartJointCard';
import { TagConditionsInput } from '../../../../Coach/SuperMacro/View/TagConditionsInput';
import { ExerciseEasierAlternativeInput } from './ExerciseEasierAlternativeInput';
import { ExerciseAlternativeInput } from './ExerciseAlternativeInput';
import { ExerciseHarderAlternativeInput } from './ExerciseHarderAlternativeInput';

export type ExerciseEditCardProps = {
  exercise: ExerciseVariationAdmin;
  onSaveMedia?: ViewMediaProps['onSave'];
};

export const ExerciseEditCard: React.FC<ExerciseEditCardProps> = observer(({ exercise, onSaveMedia }) => {
  return (
    <Card style={{ paddingTop: 4, paddingBottom: 4 }}>
      <CardBody>
        <Form layout="vertical">
          <Row>
            <Col md={6}>
              <TranslationInputArray entity={exercise} field="name" label="Name" />
            </Col>
            <Col md={6}>
              <TranslationInputArray entity={exercise} field="description" label="Description" type="textarea" />
            </Col>
            <Col md={6}>
              <Row>
                <Col>
                  <ExerciseTypeSelect exercise={exercise} />
                </Col>
                <Col md="auto">
                  <Form.Item label="MET">
                    <InputNumber
                      min={1}
                      max={30}
                      step={0.1}
                      value={exercise.met}
                      onChange={(e) => (exercise.met = Number(e) ?? 0.0)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <ExerciseExecutionTypeSelect exercise={exercise} />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <ExercisePositionSelect exercise={exercise} />
            </Col>
            <Col md={6}>
              <Row>
                <Col>
                  <ExerciseForceSelect exercise={exercise} />
                </Col>
                <Col>
                  <ExerciseMechanicsSelect exercise={exercise} />
                </Col>
                <Col>
                  <ExerciseUtilitySelect exercise={exercise} />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col>
                  <ExerciseHipFlexingSelect exercise={exercise} />
                </Col>
                <Col>
                  <ExerciseSpineFlexingSelect exercise={exercise} />
                </Col>
                <Col>
                  <ExerciseSpineForceTypeSelect exercise={exercise} />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <EquipmentTypeInput container={exercise.equipmentTypes.map((e) => e.id)} label="Equipment Types" />
            </Col>
            <Col md={6}>
              <EquipmentTypeInput
                container={exercise.secondaryEquipmentTypes.map((e) => e.id)}
                label="Secondary Equipment Types"
              />
            </Col>
            <Col md={6}>
              <BodyPartCard bodyParts={exercise.bodyParts} allBodyParts={exercise.allBodyParts} header="Body Parts" />
            </Col>
            <Col md={6}>
              <BodyPartCard bodyParts={exercise.synergists} allBodyParts={exercise.allSynergists} header="Synergists" />
            </Col>
            <Col md={6}>
              <BodyPartCard
                bodyParts={exercise.stabilizers}
                allBodyParts={exercise.allStabilizers}
                header="Stabilizers"
              />
            </Col>
            <Col md={6}>
              <BodyPartJointCard joints={exercise.joints} header="Joints" />
            </Col>
            <Col md={6}>
              <TrackingKeysSelectionView
                container={exercise.trackingKeys}
                viewContainer={exercise.trackingParameters}
                label="Tracking Keys"
              />
            </Col>
            <Col md={6}>
              <ExerciseVariationTags container={exercise.tags} />
            </Col>
            <Col xs={12}>
              <Row>
                <Col>
                  <Form.Item label="Easier Alternatives" extra="read only - Updated auto. via harder alternatives">
                    <ExerciseEasierAlternativeInput exercise={exercise} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="Alternatives">
                    <ExerciseAlternativeInput exercise={exercise} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="Harder Alternatives">
                    <ExerciseHarderAlternativeInput exercise={exercise} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label>{'Tag Conditions'}</Label>
                <TagConditionsInput tagConditions={exercise.tagConditions} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <h6>Main Medias</h6>
              <ViewMedia medias={exercise.medias} onSave={onSaveMedia} />
            </Col>
            <Col md={6}>
              <h6>Preview Medias</h6>
              <ViewMedia medias={exercise.previewMedias} onSave={onSaveMedia} />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
});
