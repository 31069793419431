/**
 * Created by neo on 15.12.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, DatePicker, Form, InputNumber, Modal, Select } from 'antd';
import { GymCoachSubscription } from '../../../../../Model/Subscription/GymCoachSubscription';
import { useNavigate } from 'react-router';
import { Col, Row } from 'reactstrap';
import dayjs from 'dayjs';
import { runInAction } from 'mobx';
import { SubscriptionPlan } from '../../../../../Model/Subscription/SubscriptionPlan';

export type GymCoachSubscriptionModalProps = {};

export const GymCoachSubscriptionModal: React.FC<GymCoachSubscriptionModalProps> = observer((props) => {
  const { subscriptionId, gymId } = useParams<{ subscriptionId: string; gymId: string }>();
  const [subscription, setSubscription] = useState(new GymCoachSubscription());
  const [saving, setSaving] = useState(false);
  const history = useNavigate();
  const isNew = subscriptionId === 'new';

  useEffect(() => {
    if (subscriptionId && subscriptionId !== 'new') {
      GymCoachSubscription.get(subscriptionId).then((result) => setSubscription(result));
    } else {
      setSubscription(new GymCoachSubscription({ gymId }));
    }
  }, [subscriptionId, gymId]);

  const handleClose = React.useCallback(() => {
    history(-1);
  }, [history]);

  const handleSave = React.useCallback(() => {
    setSaving(true);
    subscription
      .save()
      .finally(() => setSaving(false))
      .then(() => history(-1));
  }, [subscription, history]);

  const handleChangePlan = React.useCallback(
    (value: SubscriptionPlan) => runInAction(() => (subscription.plan = value)),
    [subscription],
  );

  const handleChangeValidFrom = React.useCallback(
    (date) => runInAction(() => (subscription.validFrom = date?.toDate() ?? new Date())),
    [subscription],
  );

  const handleChangeValidUntil = React.useCallback(
    (date) => runInAction(() => (subscription.validUntil = date?.toDate())),
    [subscription],
  );

  const handleChangeLicenseCount = React.useCallback(
    (count?: number | string | null) => {
      const licences = typeof count === 'string' ? Number(count) : count ?? 0;
      runInAction(() => (subscription.licenseCount = licences > 0 ? licences : undefined));
    },
    [subscription],
  );

  const toggleModule = (module: string) => (e) => {
    runInAction(() => {
      if (e.target.checked) {
        subscription.enabledModules.push(module);
      } else {
        subscription.enabledModules = subscription.enabledModules.filter((m) => m !== module);
      }
    });
  };

  return (
    <Modal
      title={isNew ? 'New Subscription' : subscription.id}
      open={true}
      onOk={handleSave}
      onCancel={handleClose}
      confirmLoading={saving}
    >
      <Form layout="vertical">
        <Row>
          <Col>
            <Form.Item label="Plan">
              <Select value={subscription.plan} onChange={handleChangePlan}>
                <Select.Option value="academy">Academy</Select.Option>
                <Select.Option value="move">Move</Select.Option>
                <Select.Option value="standard">Standard</Select.Option>
                <Select.Option value="engagement">Engagement</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Licences">
              <InputNumber value={subscription.licenseCount} onChange={handleChangeLicenseCount} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item label="Valid From">
              <DatePicker value={dayjs(subscription.validFrom)} onChange={handleChangeValidFrom} disabled={!isNew} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Valid Until">
              <DatePicker
                value={subscription.validUntil ? dayjs(subscription.validUntil) : undefined}
                onChange={handleChangeValidUntil}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h5>Enabled Modules</h5>
          </Col>
          <Col xs={6}>
            <Checkbox
              checked={subscription.enabledModules.includes('notifications')}
              onChange={toggleModule('notifications')}
            >
              Notifications
            </Checkbox>
          </Col>
          <Col xs={6}>
            <Checkbox
              checked={subscription.enabledModules.includes('content_management')}
              onChange={toggleModule('content_management')}
            >
              Content Management
            </Checkbox>
          </Col>
          <Col xs={6}>
            <Checkbox
              checked={subscription.enabledModules.includes('custom_challenges')}
              onChange={toggleModule('custom_challenges')}
            >
              Custom Challenges
            </Checkbox>
          </Col>
          <Col xs={6}>
            <Checkbox
              checked={subscription.enabledModules.includes('physical_workshops')}
              onChange={toggleModule('physical_workshops')}
            >
              Physical Workshops
            </Checkbox>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
