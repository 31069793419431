import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Observer, observer } from 'mobx-react';
import { Table, Pagination, Input } from 'antd';
import { Container } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { Customer } from '../../../Model/Customer/Customer';
import { PageResult } from '../../../Model/PageResult';
import { SingleColRow } from '../../../Components/SingleColRow';

const { Search } = Input;

export const CustomersListScreen: React.FC = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const urlQuery = params.get('query') ?? '';
  const urlPage = Number(params.get('page') ?? '0');
  const debounce = useRef<any>();
  const count = useRef(0);
  const [result, setResult] = useState(new PageResult<Customer>());
  const [query, setQuery] = useState(urlQuery);
  const [currentPage, setCurrentPage] = useState(urlPage);
  const [pageSize, setPageSize] = useState(10);
  const [sortField, setSortField] = useState('athlete.fullName');
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>('ASC');

  // Map table column keys to actual sort field paths
  const sorterFieldMap: Record<string, string> = {
    id: 'id',
    name: 'athlete.fullName',
    nickname: 'athlete.nickname',
    email: 'athlete.user.email',
    createdAt: 'createdAt',
    // gym sorting is not provided
  };

  // Update URL search parameters with a debounce
  const updateSearchParams = useCallback(
    (q: string, page: number = 0) => {
      if (debounce.current) clearTimeout(debounce.current);
      debounce.current = setTimeout(() => {
        const params = new URLSearchParams(location.search);
        params.set('query', q);
        params.set('page', page.toString());
        navigate(
          {
            pathname: location.pathname,
            search: params.toString(),
          },
          { replace: true },
        );
      }, 300);
    },
    [location, navigate],
  );

  // Load customers with sorting support
  const load = useCallback(
    (q: string, page: number = 0, sortFieldParam: string = sortField, sortOrderParam: 'ASC' | 'DESC' = sortOrder) => {
      PageResult.execute(
        Customer.find({ query: q, page, size: pageSize, sort: `${sortFieldParam},${sortOrderParam}` }),
        page === 0 ? Customer.count({ query: q }).then((res) => (count.current = res)) : Promise.resolve(count.current),
        page,
        pageSize,
      ).then((res) => setResult(res));
    },
    [pageSize, sortField, sortOrder],
  );

  useEffect(() => {
    load(query, currentPage);
  }, [query, currentPage, load]);

  // Trigger filter search
  const onSearch = (value: string) => {
    setQuery(value);
    setCurrentPage(0);
    updateSearchParams(value, 0);
    load(value, 0, sortField, sortOrder);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page - 1);
    updateSearchParams(query, page - 1);
    load(query, page - 1, sortField, sortOrder);
  };

  const handlePageSizeChange = (_current: number, size: number) => {
    setPageSize(size);
    setCurrentPage(0);
    load(query, 0, sortField, sortOrder);
  };

  // Map the sorter.field based on sorterFieldMap
  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    if (sorter.order) {
      const order = sorter.order === 'ascend' ? 'ASC' : 'DESC';
      const mappedField = sorterFieldMap[sorter.field] || sorter.field;
      setSortField(mappedField);
      setSortOrder(order);
      load(query, currentPage, mappedField, order);
    }
  };

  // Table columns with Observer wrapping for observed properties
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: (_: any, record: Customer) => (
        <Observer>
          {() => (
            <a onClick={() => navigate(`/infrastructure/customers/${record.id}`)} style={{ cursor: 'pointer' }}>
              {record.id}
            </a>
          )}
        </Observer>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (_: any, record: Customer) => (
        <Observer>
          {() => (
            <a
              onClick={() => navigate(`/infrastructure/customers/${record.id}`)}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              {record.athlete.fullName}
            </a>
          )}
        </Observer>
      ),
    },
    {
      title: 'Nickname',
      dataIndex: 'nickname',
      key: 'nickname',
      sorter: true,
      render: (_: any, record: Customer) => <Observer>{() => <span>{record.athlete.nickname}</span>}</Observer>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      render: (_: any, record: Customer) => <Observer>{() => <span>{record.athlete?.user?.email}</span>}</Observer>,
    },
    {
      title: 'Company',
      dataIndex: 'gym',
      key: 'gym',
      render: (_: any, record: Customer) => (
        <Observer>
          {() =>
            record.gym ? (
              <a
                onClick={() => navigate(`/infrastructure/gym/${record.gym?.id}`)}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                {record.gym.name}
              </a>
            ) : (
              <span>-</span>
            )
          }
        </Observer>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (_: any, record: Customer) => (
        <Observer>{() => <span>{dayjs(record.createdAt).format('LLLL')}</span>}</Observer>
      ),
    },
  ];

  return (
    <Container className="mt-3">
      <SingleColRow>
        <h1>Customers</h1>
      </SingleColRow>
      <SingleColRow>
        <Search placeholder="Filter customers..." defaultValue={query} onSearch={onSearch} enterButton />
      </SingleColRow>
      <SingleColRow>
        <Table<Customer>
          rowKey="id"
          columns={columns}
          dataSource={result.content.slice()}
          pagination={false}
          bordered
          size="middle"
          onChange={handleTableChange}
        />
      </SingleColRow>
      <SingleColRow>
        <Pagination
          current={currentPage + 1}
          total={result.totalElements || count.current}
          pageSize={pageSize}
          onChange={handlePageChange}
          showSizeChanger
          pageSizeOptions={['10', '20', '50', '100']}
          onShowSizeChange={handlePageSizeChange}
        />
      </SingleColRow>
    </Container>
  );
});
