/**
 * Created by neo on 02.08.22.
 */
import { computed, observable } from 'mobx';
import { EarnModelRuleTimeConstraint, EarnModelRuleTimeConstraintJson } from './EarnModelRuleTimeConstraint';
import { EarnModelRuleActivationLogic, EarnModelRuleActivationLogicJson } from './EarnModelRuleActivationLogic';
import { EarnModelTemplateRuleAbstract, EarnModelTemplateRuleAbstractJson } from './EarnModelTemplateRuleAbstract';
import {
  EarnModelTemplateRuleSteppedValueStep,
  EarnModelTemplateRuleSteppedValueStepJson,
} from './EarnModelTemplateRuleSteppedValueStep';

export type EarnModelRuleSteppedTimeFrame = 'day' | 'week' | 'month' | 'year';

export type EarnModelTemplateRuleSteppedJson = EarnModelTemplateRuleAbstractJson & {
  timeConstraint: EarnModelRuleTimeConstraintJson;
  activationLogic: EarnModelRuleActivationLogicJson;
  timeFrame: EarnModelRuleSteppedTimeFrame;
  valueKey: string;
  pointsSteps: EarnModelTemplateRuleSteppedValueStepJson[];
  singleResults: boolean;
};

export class EarnModelTemplateRuleStepped extends EarnModelTemplateRuleAbstract {
  @observable
  timeConstraint = new EarnModelRuleTimeConstraint();
  @observable
  activationLogic = new EarnModelRuleActivationLogic();
  @observable
  timeFrame: EarnModelRuleSteppedTimeFrame = 'day';
  @observable
  valueKey: string = '';
  @observable
  pointsSteps: EarnModelTemplateRuleSteppedValueStep[] = [];
  @observable
  singleResults = false;

  constructor(json?: Partial<EarnModelTemplateRuleSteppedJson>) {
    super(Object.assign({}, json ?? {}, { type: 'stepped' }));
    if (json) {
      this.timeConstraint = new EarnModelRuleTimeConstraint(json.timeConstraint);
      this.activationLogic = new EarnModelRuleActivationLogic(json.activationLogic);
      this.timeFrame = json.timeFrame ?? 'day';
      this.valueKey = json.valueKey ?? '';
      this.pointsSteps = json.pointsSteps?.map((p) => new EarnModelTemplateRuleSteppedValueStep(p)) ?? [];
      this.singleResults = json.singleResults ?? false;
    }
  }

  toJS(newId: boolean = false): EarnModelTemplateRuleSteppedJson {
    return Object.assign(super.toJS(newId), {
      timeConstraint: this.timeConstraint?.toJS(),
      activationLogic: this.activationLogic.toJS(),
      timeFrame: this.timeFrame,
      valueKey: this.valueKey,
      pointsSteps: this.pointsSteps.map((p) => p.toJS()),
      singleResults: this.singleResults,
    });
  }

  copy(): EarnModelTemplateRuleStepped {
    return new EarnModelTemplateRuleStepped(this.toJS(true));
  }

  @computed
  get maxPointsPerActivation(): number {
    return this.pointsSteps.reduce((sum, curr) => curr.points + sum, 0);
  }
}
