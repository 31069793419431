/**
 *
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { Container } from 'reactstrap';
import { ExerciseListScreenContent } from './ExerciseListScreenContent';
import { SingleColRow } from '../../../../Components/SingleColRow';

export type ExerciseListScreenProps = {};

export const ExerciseListScreen: React.FC<ExerciseListScreenProps> = observer((_: ExerciseListScreenProps) => {
  return (
    <Container>
      <SingleColRow>
        <h1>Exercises</h1>
      </SingleColRow>
      <ExerciseListScreenContent />
    </Container>
  );
});
