/**
 * Created by neo on 21.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Media } from '../../../../../Model/Media/Media';
import { MediaVisibleAreaTab } from '../MediaEditVideoContent/MediaVisibleAreaTab/MediaVisibleAreaTab';

export type MediaImageContentProps = {
  media: Media;
};

export const MediaImageContent: React.FC<MediaImageContentProps> = observer(({ media }) => {
  return <MediaVisibleAreaTab media={media} />;
});
