import { action, observable, runInAction } from 'mobx';
import OpenAI from 'openai';
import { Config } from '../Config/Config';

export type GptMessage = {
  content: string;
  role: string;
};

export class GptResponseService {
  private messages: GptMessage[];
  private finalMessage = '';
  @observable
  generating = false;

  constructor(initialMessages: GptMessage[] = []) {
    this.messages = initialMessages.map((p) => ({ ...p }));
  }

  @action
  generate(progressCallback?: (message: string) => void): Promise<string> {
    if (!this.generating) {
      this.generating = true;
      return new Promise((resolve, reject) => {
        this._generate(
          this.messages.length,
          (res) => {
            runInAction(() => (this.generating = false));
            resolve(res);
          },
          (err) => {
            runInAction(() => (this.generating = false));
            reject(err);
          },
          progressCallback,
        );
      });
    }
    return Promise.reject('Already generating');
  }

  @action
  private async _generate(
    attempt: number = 0,
    completeCallback?: (response: string) => void,
    errorCallback?: (err?: Error) => void,
    progressCallback?: (message: string) => void,
  ) {
    const openai = new OpenAI({ apiKey: Config.openai.gptApiKey, dangerouslyAllowBrowser: true });
    const stream: any = await openai.chat.completions.create({
      model: 'gpt-4.5-preview',
      max_completion_tokens: 16000,
      stream: true,
      messages: this.messages.map((m) => ({ role: m.role, content: m.content })) as any[],
    });

    for await (const event of stream) {
      this.finalMessage = `${this.finalMessage}${event.choices[0]?.delta?.content ?? ''}`;
      progressCallback && progressCallback(this.finalMessage);
      console.log('event', event);
    }

    completeCallback && completeCallback(this.finalMessage);
  }

  get completeResponse(): string {
    return this.finalMessage;
  }
}
