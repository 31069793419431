import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Table, Button, Space } from 'antd';
import { Row, Col, Container } from 'reactstrap';
import { HighlightContentEntry } from '../../../../Model/Content/HighlightContentEntry';
import { PageResult } from '../../../../Model/PageResult';
import { HighlightContentEntryModal } from './HighlightContentEntryModal';
import dayjs from 'dayjs';

const GymNameRenderer = observer(({ record }: { record: HighlightContentEntry }) => <span>{record.gym?.name}</span>);

export const HighlightListIndexScreen = observer(() => {
  const [entries, setEntries] = useState(new PageResult<HighlightContentEntry>());
  const [loading, setLoading] = useState(false);
  const [editingEntry, setEditingEntry] = useState<HighlightContentEntry | undefined>();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [sorter, setSorter] = useState<any>({
    field: 'publishDate',
    order: 'desc',
  });
  const [filters, setFilters] = useState<any>({});

  // Fetch entries on component mount
  useEffect(() => {
    fetchEntries(currentPage, pageSize);
  }, []);

  const fetchEntries = async (page = 0, size = 50, sorter: any = {}, filters: any = {}) => {
    setLoading(true);
    try {
      const sortOrder = sorter.order === 'ascend' ? 'ASC' : sorter.order === 'descend' ? 'DESC' : undefined;
      const params = {
        page,
        size,
        sort: sorter && sorter.field && sortOrder ? `${sorter.field},${sortOrder}` : undefined,
        category: filters.category && filters.category.length > 0 ? filters.category : undefined,
      };
      const data = await PageResult.execute(
        HighlightContentEntry.find(params),
        HighlightContentEntry.count(),
        page,
        size,
      );
      setEntries(data);
      setCurrentPage(page);
      setPageSize(size);
    } catch (error) {
      console.error('Failed to fetch entries:', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteEntry = async (entry: HighlightContentEntry) => {
    console.log('Delete function called for entry:', entry);
    setLoading(true);
    try {
      const result = await entry.delete();
      console.log('Delete result:', result);
      await fetchEntries(currentPage, pageSize);
    } catch (error) {
      console.error('Failed to delete entry:', error);
    } finally {
      setLoading(false);
    }
  };

  const openEditModal = (entry?: HighlightContentEntry) => {
    if (entry) {
      setEditingEntry(entry);
    } else {
      setEditingEntry(new HighlightContentEntry());
    }
  };

  const closeModal = () => {
    setEditingEntry(undefined);
  };

  const saveEntry = async (entry: HighlightContentEntry) => {
    setLoading(true);
    try {
      await entry.save();
      fetchEntries(currentPage, pageSize);
      closeModal();
    } catch (error) {
      console.error('Failed to save entry:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination, tableFilters, tableSorter) => {
    const page = pagination.current - 1; // Adjust for 0-based indexing
    const size = pagination.pageSize;
    setCurrentPage(page);
    setPageSize(size);
    setSorter({
      field: tableSorter.field,
      order: tableSorter.order,
    });
    setFilters(tableFilters);
    fetchEntries(page, size, tableSorter, tableFilters);
  };

  const columns = [
    {
      title: 'Internal Name',
      dataIndex: 'internalName',
      key: 'internalName',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filters: [
        { text: 'Home', value: 'home' },
        { text: 'Explore', value: 'explore' },
      ],
      filteredValue: filters.category || null,
    },
    {
      title: 'Gym',
      dataIndex: 'gym',
      key: 'gym',
      render: (text: any, record: HighlightContentEntry) => <GymNameRenderer record={record} />,
    },
    {
      title: 'Publish Date',
      dataIndex: 'publishDate',
      key: 'publishDate',
      render: (text: any) => dayjs(text).format('YYYY-MM-DD'),
      sorter: true,
      sortOrder: sorter.field === 'publishDate' && sorter.order,
    },
    {
      title: 'Unpublish Date',
      dataIndex: 'unpublishDate',
      key: 'unpublishDate',
      render: (text: any) => dayjs(text).format('YYYY-MM-DD'),
      sorter: true,
      sortOrder: sorter.field === 'unpublishDate' && sorter.order,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: any, record: HighlightContentEntry) => (
        <Space>
          <Button type="link" onClick={() => openEditModal(record)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => deleteEntry(record)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Container>
      <Row>
        <Col>
          <h1>Highlights</h1>
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={() => openEditModal()} style={{ marginBottom: 16 }}>
            Add New Entry
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={entries.content}
        rowKey="id"
        loading={loading}
        onChange={handleTableChange}
        pagination={{
          current: currentPage + 1,
          pageSize: entries.size,
          total: entries.totalElements,
          showSizeChanger: true,
        }}
      />
      {editingEntry && (
        <HighlightContentEntryModal entry={editingEntry} onEntrySaved={saveEntry} onCancel={closeModal} />
      )}
    </Container>
  );
});
