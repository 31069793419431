/**
 * Created by neo on 13.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Gym } from '../../../Model/Gym/Gym';
import { Col, Row } from 'reactstrap';
import { UserDailyEngagementTime } from './UserDailyEngagementTime';
import { AgeDistributionHistory } from './AgeDistributionHistory';
import { Form } from 'antd';
import { TopicDistribution } from './TopicDistribution';
import { UserPropertiesStats } from './UserPropertiesStats';
import { UserPropertiesStatsAge } from './UserPropertiesStatsAge';
import { UserPropertiesStatsAgeGender } from './UserPropertiesStatsAgeGender';

export type UsersOverviewTabProps = {
  gym?: Gym;
};

export const UsersOverviewTab: React.FC<UsersOverviewTabProps> = observer(({ gym }) => {
  return (
    <Form layout="vertical">
      <Row>
        {/*<Col>*/}
        {/*  <AgeDistributionStats gym={gym} />*/}
        {/*</Col>*/}
        {/*<Col>*/}
        {/*  <LanguageDistribution gym={gym} />*/}
        {/*</Col>*/}
        <Col xs={12}>
          <TopicDistribution gym={gym} />
        </Col>
        <Col xs={12}>
          <UserPropertiesStats gym={gym} />
        </Col>
        <Col xs={12}>
          <UserPropertiesStatsAge gym={gym} />
        </Col>
        <Col xs={12}>
          <UserPropertiesStatsAgeGender gym={gym} />
        </Col>
        <Col xs={12}>
          <AgeDistributionHistory gym={gym} />
        </Col>
        <Col xs={12}>
          <UserDailyEngagementTime gym={gym} />
        </Col>
      </Row>
    </Form>
  );
});
