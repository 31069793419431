/**
 * Created by neo on 09.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExploreCategory } from '../../../Model/Explore/ExploreCategory';
import { runInAction } from 'mobx';
import { ExploreCategoryLink } from '../../../Model/Explore/ExploreCategoryLink';
import { Button } from 'antd';
import { ExploreCategoryModalLinkListEditorEntry } from './ExploreCategoryModalLinkListEditorEntry';

export type ExploreCategoryModalLinkListEditorProps = {
  category: ExploreCategory;
};

export const ExploreCategoryModalLinkListEditor: React.FC<ExploreCategoryModalLinkListEditorProps> = observer(
  ({ category }) => {
    const handleAddLink = React.useCallback(() => {
      runInAction(() => category.links.push(new ExploreCategoryLink()));
    }, [category]);

    return (
      <React.Fragment>
        {category.links.map((link, index) => (
          <ExploreCategoryModalLinkListEditorEntry
            key={index.toString()}
            category={category}
            link={link}
            index={index}
          />
        ))}
        <Button block type="primary" onClick={handleAddLink}>
          Add Link
        </Button>
      </React.Fragment>
    );
  },
);
