// application/src/App/View/Infrastructure/Gym/GymViewScreen/GymCustomersTab/GymCustomersTab.tsx
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Observer, observer } from 'mobx-react';
import { Input, Button, message, Modal, Popconfirm, Space, Table, Pagination } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import saveAs from 'file-saver';
import dayjs from 'dayjs';
import { CustomerCreateModal } from './CustomerCreateModal/CustomerCreateModal';
import { OffboardingModal } from './OffboardingModal'; // Adjust the relative path if needed.
import { useRootStore } from '../../../../../Store/useRootStore';
import { Gym } from '../../../../../Model/Gym/Gym';
import { Customer } from '../../../../../Model/Customer/Customer';
import { PageResult } from '../../../../../Model/PageResult';
import { HttpBackend } from '../../../../../Services/Http/HttpBackend';
import { SingleColRow } from '../../../../../Components/SingleColRow';

export type GymCustomersTabProps = {
  gym: Gym;
};

export const GymCustomersTab: React.FC<GymCustomersTabProps> = observer(({ gym }) => {
  const { authentication } = useRootStore();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [exporting, setExporting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [result, setResult] = useState(new PageResult<Customer>());
  const [showCustomerCreate, setShowCustomerCreate] = useState(false);
  const [showOffboardingModal, setShowOffboardingModal] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const debounceQuery = useRef<ReturnType<typeof setTimeout>>();

  const searchPage = Number(searchParams.get('page') ?? '0');
  const searchQuery = searchParams.get('query') ?? '';
  const sortFieldParam = searchParams.get('sortField') ?? 'athlete.createdAt';
  const sortOrderParam = searchParams.get('sortOrder') ?? 'DESC';

  const [query, setQuery] = useState(searchQuery);

  const loadCustomers = useCallback(
    (
      gymId: string,
      newQuery?: string,
      newPage: number = 0,
      sortField: string = 'athlete.createdAt',
      sortOrder: string = 'DESC',
      size: number = pageSize,
    ) => {
      PageResult.execute(
        Customer.find({
          gymId,
          query: newQuery,
          page: newPage,
          sort: `${sortField},${sortOrder}`,
          size,
        }),
        Customer.count({
          gymId,
          query: newQuery,
          sort: `${sortField},${sortOrder}`,
        }),
        newPage,
        size,
      ).then((res) => setResult(res));
      const params = new URLSearchParams();
      params.set('page', newPage.toString());
      if (newQuery) params.set('query', newQuery);
      params.set('sortField', sortField);
      params.set('sortOrder', sortOrder);
      params.set('size', size.toString());

      setSearchParams(params);
    },
    [setSearchParams, pageSize],
  );

  // Page change handling using the new page size immediately.
  const handlePageChange = (newPage: number, newPageSize?: number) => {
    const effectiveSize = newPageSize && newPageSize !== pageSize ? newPageSize : pageSize;
    if (newPageSize && newPageSize !== pageSize) {
      setPageSize(newPageSize);
    }
    loadCustomers(gym.id, query, newPage - 1, sortFieldParam, sortOrderParam, effectiveSize);
  };

  // Helper to render Pagination with total count.
  const renderPagination = (current: number, total: number) => (
    <SingleColRow
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '16px 0',
      }}
    >
      <Pagination
        current={current}
        total={total}
        pageSize={pageSize}
        showSizeChanger
        pageSizeOptions={['10', '20', '50', '100']}
        showTotal={(total) => `Total customers: ${total}`}
        onChange={handlePageChange}
        onShowSizeChange={(current, size) => handlePageChange(current, size)}
      />
    </SingleColRow>
  );

  const handleChangeQuery = useCallback(
    (value: string) => {
      setQuery(value);
      if (debounceQuery.current) {
        clearTimeout(debounceQuery.current);
      }
      debounceQuery.current = setTimeout(() => {
        loadCustomers(gym.id, value, 0);
      }, 300);
    },
    [gym.id, loadCustomers],
  );

  const handleClear = useCallback(() => {
    setQuery('');
    loadCustomers(gym.id, '', 0);
  }, [gym.id, loadCustomers]);

  const handleRemove = useCallback(
    async (customer: Customer) => {
      customer
        .remove()
        .then(() => {
          message.success('Customer deleted');
          loadCustomers(gym.id, searchQuery, searchPage);
        })
        .catch(() => message.error('Could not delete customer'));
    },
    [gym.id, loadCustomers, searchQuery, searchPage],
  );

  const handleCreateCustomer = useCallback(() => {
    setShowCustomerCreate(true);
  }, []);

  const handleCustomerCreateClose = useCallback(() => {
    setShowCustomerCreate(false);
    loadCustomers(gym.id, searchQuery, searchPage);
  }, [gym.id, loadCustomers, searchQuery, searchPage]);

  const handleOpenOffboardingModal = useCallback(() => {
    setShowOffboardingModal(true);
  }, []);

  const handleOffboardingModalClose = useCallback(() => {
    setShowOffboardingModal(false);
  }, []);

  const handleExportCsv = useCallback(() => {
    setExporting(true);
    HttpBackend.get(`/gym/customer/admin/exportCsv`, { gymId: gym.id })
      .then((res) => {
        const blob = new Blob([res], { type: 'text/plain;charset=iso-8859-1' });
        saveAs(blob, `${gym.name}-customers-${dayjs().format('YYYYMMDDHHmm')}.csv`);
      })
      .finally(() => setExporting(false));
  }, [gym]);

  const handleDeleteAll = useCallback(() => {
    setDeleting(true);
    HttpBackend.delete(`/gym/customer/admin/deleteAllAccounts`, { gymId: gym.id })
      .then(() => {
        message.success('All accounts deleted');
      })
      .then(() => setResult(new PageResult<Customer>()))
      .finally(() => setDeleting(false));
  }, [gym]);

  const columns = [
    {
      title: 'Profile',
      key: 'profilePicture',
      render: (_: any, record: Customer) =>
        record.athlete.profilePicture ? (
          <img
            src={record.athlete.profilePicture?.url}
            width={40}
            height={40}
            style={{ borderRadius: '50%' }}
            alt="profile"
          />
        ) : null,
    },
    {
      title: 'Name',
      dataIndex: ['athlete', 'fullName'],
      key: 'name',
      sorter: true,
      sorterField: 'athlete.fullName',
      render: (_: any, record: Customer) => (record.athlete ? record.athlete.fullName : ''),
    },
    {
      title: 'Email',
      dataIndex: ['athlete', 'user', 'email'],
      key: 'email',
      sorter: true,
      sorterField: 'athlete.user.email',
      render: (_: any, record: Customer) =>
        record.athlete && record.athlete.user ? (
          <a href={`/athlete/${record.athlete.id}`}>{record.athlete.user.email}</a>
        ) : (
          ''
        ),
    },
    {
      title: 'Activities',
      key: 'activities',
      render: (_: any, record: Customer) => (
        <Observer>{() => <span>{record.athlete.loggedActivities || 0}</span>}</Observer>
      ),
    },
    {
      title: 'Points',
      key: 'points',
      render: (_: any, record: Customer) => (
        <Observer>{() => <span>{record.pointsLedger?.totalCollectedPoints?.toLocaleString() || 0}</span>}</Observer>
      ),
    },
    {
      title: 'Last Seen',
      key: 'lastSeen',
      render: (_: any, record: Customer) => (
        <Observer>
          {() => (
            <span>
              {record.athlete.lastActivity
                ? dayjs(record.athlete.lastActivity.updatedAt).format('YYYY-MM-DD HH:mm')
                : ''}
            </span>
          )}
        </Observer>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'created',
      sorter: true,
      sorterField: 'createdAt',
      render: (_: any, record: Customer) =>
        record.athlete && record.athlete.createdAt ? dayjs(record.athlete.createdAt).format('YYYY-MM-DD HH:mm') : '',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: Customer) => (
        <Button
          type="primary"
          danger
          size="small"
          onClick={() =>
            Modal.confirm({
              title: 'Confirm Deletion',
              content: `Are you sure to delete ${record.athlete.fullName}?`,
              okText: 'Yes',
              okType: 'danger',
              cancelText: 'No',
              onOk: () => handleRemove(record),
            })
          }
        >
          Delete
        </Button>
      ),
    },
  ];

  const handleTableChange = (pagination: any, _filters: any, sorter: any) => {
    const newPage = (pagination.current || 1) - 1;
    const sortField =
      sorter.sorterField ||
      (Array.isArray(sorter.field) ? sorter.field.join('.') : sorter.field) ||
      'athlete.createdAt';
    const sortOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC';
    loadCustomers(gym.id, query, newPage, sortField, sortOrder, pageSize);
  };

  // Initial loadCustomers call
  useEffect(() => {
    loadCustomers(
      gym.id,
      searchQuery,
      Number(searchParams.get('page') ?? '0'),
      sortFieldParam,
      sortOrderParam,
      pageSize,
    );
  }, [gym, loadCustomers, searchQuery, searchParams, sortFieldParam, sortOrderParam, pageSize]);

  return (
    <>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <div style={{ flex: 1, marginRight: 8 }}>
          <Input
            allowClear
            value={query}
            onChange={(e) => handleChangeQuery(e.target.value)}
            placeholder="Search"
            style={{ width: '100%' }}
          />
        </div>
        <Space>
          <Button type="primary" onClick={handleCreateCustomer}>
            Create New Customer
          </Button>
          <Button onClick={handleExportCsv} disabled={exporting}>
            Export Customers CSV
          </Button>
          <Button onClick={handleOpenOffboardingModal}>Offboard Customer</Button>
          {authentication.isOneAboveAll && (
            <Popconfirm title="ARE YOU SURE??? This will delete all accounts" onConfirm={handleDeleteAll}>
              <Button type="primary" danger disabled={deleting}>
                Delete All Accounts
              </Button>
            </Popconfirm>
          )}
        </Space>
      </div>
      {renderPagination(searchPage + 1, result.totalElements)}
      <Table
        rowKey="id"
        columns={columns}
        dataSource={result.content}
        pagination={false}
        onChange={handleTableChange}
      />
      {/* Bottom Pagination */}
      {renderPagination(searchPage + 1, result.totalElements)}
      {showCustomerCreate && (
        <SingleColRow>
          <CustomerCreateModal onClose={handleCustomerCreateClose} gym={gym} />
        </SingleColRow>
      )}
      {showOffboardingModal && (
        <OffboardingModal visible={showOffboardingModal} gymId={gym.id} onClose={handleOffboardingModalClose} />
      )}
    </>
  );
});
