/**
 * Created by andreaskarantzas on 05.05.20.
 */
import { action, computed, observable } from 'mobx';
import { v4 as UUID } from 'uuid';
import NativeFeedComponentProps, { NativeFeedComponentPropsJson } from './NativeFeedComponentProps';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { languagePriority } from '../LocalizedArrayEntity';
import { Media, MediaJson } from '../Media/Media';

export type NativeFeedComponentType =
  | 'View'
  | 'Image'
  | 'Video'
  | 'Heading'
  | 'ItalicHeading'
  | 'Paragraph'
  | 'ItalicParagraph'
  | 'Caption'
  | 'Summary'
  | 'InnerBoldText'
  | 'InnerLinkText'
  | 'BulletPoint'
  | 'NumberPoint'
  | 'Button'
  | 'SecondaryButton';

export type NativeFeedComponentJson = {
  key?: string;
  type?: NativeFeedComponentType;
  props?: NativeFeedComponentPropsJson;
  children?: NativeFeedComponentJson[];
  text?: LocalizedValueJson[];
  media?: MediaJson;
};

export default class NativeFeedComponent {
  @observable key: string = UUID();
  @observable type: NativeFeedComponentType = 'View';
  @observable props: NativeFeedComponentProps = new NativeFeedComponentProps({});
  @observable children: NativeFeedComponent[] = [];
  @observable text: Array<LocalizedValue> = [];
  @observable media?: Media;

  constructor(json: Partial<NativeFeedComponentJson>) {
    if (json) {
      this.key = json.key || UUID();
      this.type = json.type || 'View';
      this.props = new NativeFeedComponentProps(json.props || {});
      this.children = (json.children || []).map((c: any) => new NativeFeedComponent(c));
      this.text = (json.text || []).map((c: any) => new LocalizedValue(c));
      this.media = json.media ? new Media(json.media) : undefined;
    }
  }

  @action
  removeChild(componentKey: string) {
    const index = this.children.findIndex((c) => c.key === componentKey);
    if (index !== -1) {
      this.children.splice(index, 1);
    }
  }

  @action
  changeText(lang: string, value: string) {
    const existing = this.text.find((n) => n.lang === lang.toLowerCase());
    if (existing) {
      existing.value = value;
    } else {
      this.text.push(
        new LocalizedValue({
          lang: lang.toLowerCase(),
          value,
        }),
      );
    }
  }

  getText(lang: string) {
    return this.text.find((n) => n.lang === lang.toLowerCase())?.value ?? this.defaultText;
  }

  @computed
  get defaultText(): string {
    for (const lang of languagePriority) {
      const entry = this.text.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.text[0];
    return first?.value ?? '';
  }

  toJS(): NativeFeedComponentJson {
    return {
      key: this.key,
      type: this.type,
      props: this.props.toJS(),
      children: this.children.map((c) => c.toJS()),
      text: this.text.map((c) => c.toJS()),
      media: this.media?.toJS(),
    };
  }
}
