/**
 * Created by neo on 18.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Recipe } from '../../../../Model/Diet/Recipe/Recipe';
import { RecipeEditModalInstructionEntry } from './RecipeEditModalInstructionEntry';
import { Button, Form } from 'antd';
import { Col, Row } from 'reactstrap';
import { runInAction } from 'mobx';
import { RecipeInstruction } from '../../../../Model/Diet/Recipe/RecipeInstruction';
import { RecipeInstructionStep, RecipeInstructionStepJson } from '../../../../Model/Diet/Recipe/RecipeInstructionStep';
import { LocalizedValueJson } from '../../../../Model/LocalizedValue';
import { GptResponseService } from '../../../../Services/GptResponseService';

function parseTranslatedArray(array: any): LocalizedValueJson[] {
  console.log('array', Array.isArray(array), array);
  if (Array.isArray(array)) {
    if (array.length > 1) {
      return array;
      // return array.flatMap((item) =>
      //   Array.from(Object.entries(item)).map(([lang, value]) => ({ lang, value: value as string })),
      // );
    }
    return Array.from(Object.entries(array[0] ?? {}))?.map(([lang, value]) => ({ lang, value: value as string })) ?? [];
  } else if (typeof array === 'string') {
    return [{ lang: 'de', value: array }];
  }
  return Array.from(Object.entries(array ?? {}))?.map(([lang, value]) => ({ lang, value: value as string })) ?? [];
}

function createStepFromJson(step: any, number: number): RecipeInstructionStepJson {
  return {
    number,
    step: parseTranslatedArray(step.description),
    ingredients: [],
    equipment: [],
  };
}

export type RecipeEditModalInstructionsTabProps = {
  recipe: Recipe;
};

export const RecipeEditModalInstructionsTab: React.FC<RecipeEditModalInstructionsTabProps> = observer(({ recipe }) => {
  const [processing, setProcessing] = useState(false);

  const handleAddStep = React.useCallback(() => {
    runInAction(() => {
      const first = recipe.instructions[0];
      if (first) {
        first.steps.push(new RecipeInstructionStep({ number: first.steps.length + 1 }));
      }
    });
  }, [recipe]);

  const handleImprove = React.useCallback(() => {
    const stepsJson = JSON.stringify(recipe.instructions.flatMap((inst) => inst.steps));
    if (stepsJson) {
      setProcessing(true);
      new GptResponseService([
        {
          role: 'system',
          content: `Take the role of a recipe database.
      The recipes should not be translated from American recipes and just transferred to metric scale but they need to be authentic so that Swiss people feel spoken for.
      Use informal language.
      Rinderstreifen should be Rindsstreifen
      Rinderfilet should be Rindsfilet
      Lasagneplatten should be Lasagneplätter
      When you refer to the vegetable paprika, please use the word peperoni for Swiss audience
      Instead of kirschtomaten use cherry-tomaten
      Randen instead of Rote Beeten
      Paniermehl instead of semmelbröt
      Hacktäschen instead of Frikadellen
      Never use "ẞ" , replace it always with "ss"
      
      The ingredients used in the recipes are: ${recipe.ingredients
        .map(
          (i) =>
            `${i.getName('en') ?? i.getName('de')} ${
              (i.measures.metric?.amount ?? 0) > 0
                ? `(${i.measures.metric?.amount} ${i.measures.metric?.unitShort})`
                : ''
            }`,
        )
        .join(', ')}

      The response has to be a JSON array containing only the required steps/tasks describing how someone can prepare and cook the recipe. Make each step easy to understand and detailed. 
      Use 2 to 3 sentences per step whenever it makes sense. For each object in the array, the following properties are required
      - description: an array of objects containing the description of the step in different languages
      - description.lang: two letter lowercase language code de | en | fr | it
      - description.value: the current step required to prepare or cook the recipe which are easy to understand and and should be around 2 to 3 sentences long.  Omit wordings like "step 1" or "1., 2." etc.`,
        },
        {
          role: 'user',
          content: recipe.getName('en'),
        },
      ])
        .generate()
        .then((res) => JSON.parse(res))
        .then((steps) => {
          console.log('steps', steps);
          runInAction(
            () =>
              (recipe.instructions = [
                new RecipeInstruction({
                  steps: steps
                    .map((s, index) => createStepFromJson(s, index + 1))
                    .filter((s) => s.step.find((l) => l.lang === 'en')?.value !== recipe.getName('en')),
                }),
              ]),
          );
        })
        .finally(() => setProcessing(false));
    }
  }, [recipe]);

  return (
    <Row>
      <Col xs={12}>
        <Form layout="vertical">
          {recipe.instructions.slice(0, 1).map((instruction, index) => (
            <RecipeEditModalInstructionEntry
              key={index.toString()}
              index={index}
              recipe={recipe}
              instruction={instruction}
            />
          ))}
        </Form>
      </Col>
      <Col xs={12}>
        <Button type="primary" size="large" onClick={handleAddStep} block disabled={processing}>
          Add Step
        </Button>
      </Col>
      <Col xs={12}>
        <Button type="dashed" size="large" onClick={handleImprove} block disabled={processing}>
          Improve Using GPT
        </Button>
      </Col>
    </Row>
  );
});
