/**
 * Created by neo on 13.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Button } from 'antd';
import { SingleColRow } from '../../../Components/SingleColRow';
import { CoachProgram } from '../../../Model/Coach/Program/CoachProgram';
import { useNavigate } from 'react-router-dom';
import { CoachProgramTable } from './CoachProgramTable';
import { PageResult } from '../../../Model/PageResult';
import { Pager } from '../../../Components/Pager';

export type AthleteSuperMacroListScreenProps = {};

export const CoachProgramListScreen: React.FC<AthleteSuperMacroListScreenProps> = observer((_) => {
  const history = useNavigate();
  const page = React.useRef(0);
  const [superMacros, setSuperMacros] = useState(new PageResult<CoachProgram>());
  const [loading, setLoading] = useState(false);

  const load = React.useCallback((page: number) => {
    setLoading(true);
    PageResult.execute(
      CoachProgram.findAdmin({ page, size: 10, sort: 'createdAt,DESC' }),
      CoachProgram.countAdmin(),
      page,
      10,
    )
      .then((result) => {
        setSuperMacros(result);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    load(page.current);
  }, [load]);

  const handleCreate = React.useCallback(() => {
    history('/coach/fitness-program/create');
  }, [history]);

  const handlePage = React.useCallback(
    (newPage: number) => {
      if (newPage !== page.current) {
        page.current = newPage;
        load(page.current);
      }
    },
    [load],
  );

  const handleRemove = React.useCallback((macro: CoachProgram) => {
    macro.delete();
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <h1>Coach Programs</h1>
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={handleCreate}>
            Create Athlete Super Macro
          </Button>
        </Col>
      </Row>
      <SingleColRow>
        <Pager page={superMacros} onPage={handlePage} />
      </SingleColRow>
      <SingleColRow>
        <CoachProgramTable programs={superMacros.content.slice()} onRemove={handleRemove} />
      </SingleColRow>
      <SingleColRow>
        <Pager page={superMacros} onPage={handlePage} />
      </SingleColRow>
    </Container>
  );
});
