import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Modal, Form, Input, DatePicker, Select, Button } from 'antd';
import { Row, Col } from 'reactstrap';
import { runInAction, reaction } from 'mobx';
import { Gym } from '../../../../Model/Gym/Gym';
import { HighlightContentEntry } from '../../../../Model/Content/HighlightContentEntry';
import AsyncSelect from 'react-select/async';
import dayjs from 'dayjs';
import { ExploreEntryModalMediaField } from '../../Explore/ExploreEntryModalMediaField';
import { Media } from '../../../../Model/Media/Media';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';

export type HighlightContentEntryModalProps = {
  entry: HighlightContentEntry;
  onEntrySaved: (entry: HighlightContentEntry) => void;
  onCancel: () => void;
};

export const HighlightContentEntryModal: React.FC<HighlightContentEntryModalProps> = observer(
  ({ entry, onEntrySaved, onCancel }) => {
    const [gym, setGym] = useState<Gym | undefined>();
    const [fetchingGyms, setFetchingGyms] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState<Media | undefined>();

    // Fetch gym when gymId changes
    useEffect(
      () =>
        reaction(
          () => entry.gymId,
          (gymId) => {
            setGym(undefined);
            if (gymId) {
              Gym.get(gymId).then((res) => setGym(res));
            }
          },
          { fireImmediately: true },
        ),
      [entry],
    );

    const handleSave = () => {
      onEntrySaved(entry);
    };

    const handleChangeInternalName = (e: React.ChangeEvent<HTMLInputElement>) => {
      runInAction(() => {
        entry.internalName = e.target.value;
      });
    };

    const handleChangeCategory = (value: string) => {
      runInAction(() => {
        entry.category = value;
      });
    };

    const handleChangePublishDate = React.useCallback(
      (date) => runInAction(() => (entry.publishDate = date?.toDate())),
      [entry],
    );

    const handleChangeUnpublishDate = React.useCallback(
      (date) => runInAction(() => (entry.unpublishDate = date?.toDate())),
      [entry],
    );

    const fetchGyms = (inputValue: string) => {
      setFetchingGyms(true);
      return Gym.find({ query: inputValue, size: 10 })
        .then((data) => data)
        .finally(() => setFetchingGyms(false));
    };

    const getOptionLabel = (gym: Gym) => gym.name;
    const getOptionValue = (gym: Gym) => gym.id;

    const handleChangeGym = (selectedGym: Gym | null) => {
      runInAction(() => {
        entry.gymId = selectedGym ? selectedGym.id : undefined;
      });
      setGym(selectedGym || undefined);
    };

    const handleRemoveVideo = React.useCallback(
      (media: Media) => {
        runInAction(() => (entry.medias = entry.medias.filter((v) => v.id !== media.id)));
      },
      [entry],
    );

    const handleVideoUploaded = React.useCallback(
      (media: Media[]) => {
        runInAction(() => (entry.medias = entry.medias.concat(media)));
      },
      [entry],
    );

    const handleClickMedia = React.useCallback((media: Media) => {
      setSelectedMedia(media);
    }, []);

    return (
      <Modal
        title={entry.id ? 'Edit Entry' : 'Add New Entry'}
        visible={true}
        onCancel={onCancel}
        footer={null}
        width="80%" // Make the modal wider
      >
        <Form layout="vertical" onFinish={handleSave}>
          <Row>
            <Col md={6}>
              <Form.Item
                label="Internal Name"
                required
                validateStatus={!entry.internalName ? 'error' : ''}
                help={!entry.internalName ? 'Please input the internal name!' : ''}
              >
                <Input value={entry.internalName} onChange={handleChangeInternalName} />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="Category"
                required
                validateStatus={!entry.category ? 'error' : ''}
                help={!entry.category ? 'Please select the category!' : ''}
              >
                <Select value={entry.category} onChange={handleChangeCategory}>
                  <Select.Option value="home">home</Select.Option>
                  <Select.Option value="explore">explore</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item label="Gym">
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  isClearable
                  loadOptions={fetchGyms}
                  value={gym || null}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={getOptionValue}
                  onChange={handleChangeGym as any}
                  isLoading={fetchingGyms}
                  placeholder="Select a gym"
                />
              </Form.Item>
            </Col>
            <Col md={3}>
              <Form.Item
                label="Publish Date"
                required
                validateStatus={!entry.publishDate ? 'error' : ''}
                help={!entry.publishDate ? 'Please select the publish date!' : ''}
              >
                <DatePicker
                  value={entry.publishDate ? dayjs(entry.publishDate) : null}
                  onChange={handleChangePublishDate}
                />
              </Form.Item>
            </Col>
            <Col md={3}>
              <Form.Item
                label="Unpublish Date"
                required
                validateStatus={!entry.unpublishDate ? 'error' : ''}
                help={!entry.unpublishDate ? 'Please select the unpublish date!' : ''}
              >
                <DatePicker
                  value={entry.unpublishDate ? dayjs(entry.unpublishDate) : null}
                  onChange={handleChangeUnpublishDate}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label="App Link"
                extra="An optional link to where this entry should lead to (recommended though)"
              >
                <Input value={entry.appLink} onChange={({ target: { value } }) => (entry.appLink = value)} />
              </Form.Item>
            </Col>
            <Col md={6}>
              <TranslationInputArray entity={entry} field="name" label="Name" />
            </Col>
            <Col md={6}>
              <TranslationInputArray
                entity={entry}
                type="textarea"
                field="description"
                label="Description"
                markdown={true}
              />
            </Col>
            <Col md={12}>
              <Form.Item label="Medias">
                <div style={{ display: 'flex', overflowX: 'scroll' }}>
                  {([undefined] as any).concat(entry.medias).map((media) => (
                    <div key={media?.id} style={{ marginLeft: 8 }}>
                      <ExploreEntryModalMediaField
                        onRemove={handleRemoveVideo}
                        onUploaded={handleVideoUploaded}
                        onClick={handleClickMedia}
                        media={media}
                      />
                    </div>
                  ))}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Row>
              <Col>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Col>
              <Col>
                <Button onClick={onCancel} style={{ marginLeft: 8 }}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    );
  },
);
