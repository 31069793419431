/**
 *
 * Created by neo on 11.02.17.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { Button } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

export type AuthScreenProps = {};

export const AuthScreen: React.FC<AuthScreenProps> = observer(() => {
  const { loginWithRedirect, isAuthenticated, isLoading, logout } = useAuth0();

  const handleSubmit = React.useCallback(
    async (e) => {
      await loginWithRedirect({
        authorizationParams: {
          audience: 'https://api.kinastic.com', // the audience is needed to get a proper access_token
          scope: 'offline_access openid profile email', // offline_access is needed for refresh token
          redirect_uri: window.location.origin + '/auth',
        },
      });
    },
    [loginWithRedirect],
  );

  const handleLogout = React.useCallback(() => {
    logout();
  }, [logout]);

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect({
        authorizationParams: {
          audience: 'https://api.kinastic.com', // the audience is needed to get a proper access_token
          scope: 'offline_access openid profile email', // offline_access is needed for refresh token
          redirect_uri: window.location.origin + '/auth',
        },
      });
    }
  }, [loginWithRedirect, isAuthenticated]);

  return (
    <Row>
      <Col sm={{ size: 8, offset: 2 }} lg={{ size: 4, offset: 4 }}>
        <div style={{ marginTop: '100px' }}>
          <Card>
            <CardBody>
              <CardTitle style={{ textAlign: 'center' }}>
                <h3>{'KINASTIC Admin'}</h3>
              </CardTitle>
              <div style={{ textAlign: 'center' }}>
                <Button type="primary" size="large" disabled={isLoading} onClick={handleSubmit}>
                  {isAuthenticated ? 'Continue to Admin' : 'Authenticate'}
                </Button>
              </div>
              {isAuthenticated && (
                <div style={{ textAlign: 'center' }}>
                  <Button type="link" size="large" disabled={isLoading} onClick={handleLogout}>
                    Logout
                  </Button>
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </Col>
    </Row>
  );
});
