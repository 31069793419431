import React, { useEffect, useCallback } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { runInAction } from 'mobx';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Card, Row, Col, Space, InputNumber, message } from 'antd';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';
import { ViewMedia } from '../../../../Components/ViewMedia/ViewMedia';
import { TrackingKeysSelectionView } from '../../Exercise/View/TrackingKeysSelectionView';
import { Activity } from '../../../../Model/Activity/Activity';
import { Container } from 'reactstrap';

export type ActivityViewScreenProps = {};

export const ActivityViewScreen: React.FC<ActivityViewScreenProps> = observer(() => {
  const { activityId } = useParams<any>();
  const history = useNavigate();
  const [form] = Form.useForm();

  const store = useLocalStore(() => ({
    activity: new Activity(),
    processing: false,
    originalIdentifier: '',
    load(activityId?: string) {
      if (activityId && activityId !== 'new') {
        store.processing = true;
        Activity.get(activityId)
          .then((activity) => {
            runInAction(() => {
              store.activity = activity ?? new Activity();
              store.originalIdentifier = activity?.identifier || '';
            });
          })
          .finally(() => (store.processing = false));
      }
    },
  }));

  useEffect(() => {
    store.load(activityId);
  }, [store, activityId]);

  // This effect updates the form when the activity is loaded/changed.
  useEffect(() => {
    form.setFieldsValue({
      identifier: store.activity.identifier,
      met: store.activity.met,
    });
  }, [store.activity, form]);

  const validateIdentifier = async (_: any, value: string) => {
    if (!value) {
      return Promise.reject(new Error('Identifier is required'));
    }
    const pattern = /^[a-z][a-z0-9_-]*$/;
    if (!pattern.test(value)) {
      return Promise.reject(
        new Error('Identifier must start with a lowercase letter and contain only letters, digits, "_" or "-"'),
      );
    }
    if (value !== store.originalIdentifier) {
      try {
        const existingActivity = await Activity.get(value);
        if (existingActivity) {
          return Promise.reject(new Error('Identifier must be unique'));
        }
      } catch (e) {}
    }
    return Promise.resolve();
  };

  const updateIdentifierStatus = () => {
    const errors = form.getFieldError('identifier');
    const value = form.getFieldValue('identifier');
    return errors.length === 0 && value && value.trim().length > 0 ? 'success' : undefined;
  };

  const handleFinish = useCallback(
    (_values: any) => {
      runInAction(() => {
        if (activityId === 'new') {
          store.activity.identifier = form.getFieldValue('identifier');
        }
        store.activity.met = Number(form.getFieldValue('met')) || 0.0;
      });
      store.processing = true;
      store.activity
        .save()
        .then(() => {
          if (activityId === 'new') {
            history(`/metadata/activity/${store.activity.id}`, { replace: true });
          }
          message.info('Activity successfully saved');
        })
        .catch(() => message.error('Failed to save activity'))
        .finally(() => (store.processing = false));
    },
    [store, history, activityId, form],
  );

  return (
    <Container>
      <Card style={{ margin: 20 }}>
        <Form
          form={form}
          id="activityForm"
          layout="vertical"
          onFinish={handleFinish}
          onFieldsChange={() => {
            updateIdentifierStatus();
          }}
          initialValues={{
            identifier: store.activity.identifier,
            met: store.activity.met,
          }}
        >
          <Form.Item
            label="Identifier"
            name="identifier"
            rules={activityId === 'new' ? [{ validator: validateIdentifier }] : []}
            validateStatus={updateIdentifierStatus()}
            hasFeedback
          >
            <Input disabled={activityId !== 'new'} />
          </Form.Item>
          <Row gutter={16}>
            <Col md={12}>
              <TranslationInputArray entity={store.activity} field="name" label="Name" />
            </Col>
            <Col md={12}>
              <TranslationInputArray entity={store.activity} field="description" label="Description" type="textarea" />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label="MET" name="met">
                <InputNumber min={1} max={30} step={0.1} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Tracking Keys">
                <TrackingKeysSelectionView container={store.activity.trackingKeys} />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item label="Media">
                <ViewMedia medias={store.activity.medias} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" disabled={store.processing}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Container>
  );
});
