/**
 * Created by katarinababic on 13.12.21..
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { AnalyticsData, AnalyticsRow } from '../../../../Model/Analytics/AnalyticsData';
import { Gym } from '../../../../Model/Gym/Gym';
import { entryTypes } from './ExploreEntryAnalyticsAggregated';
import { Recipe } from '../../../../Model/Diet/Recipe/Recipe';
import { ArticleEntry } from '../../../../Model/Explore/ArticleEntry';
import { WorkplaceWorkoutEntry } from '../../../../Model/Explore/WorkplaceWorkoutEntry';
import { WorkoutTemplateEntry } from '../../../../Model/Explore/WorkoutTemplateEntry';
import { ActivityWorkoutEntry } from '../../../../Model/Explore/ActivityWorkoutEntry';
import { MeditationEntry } from '../../../../Model/Explore/MeditationEntry';
import { BreathingEntry } from '../../../../Model/Explore/BreathingEntry';
import { YogaEntry } from '../../../../Model/Explore/YogaEntry';
import { ZoomMeetingEntry } from '../../../../Model/Explore/ZoomMeetingEntry';
import {
  allTopFiveClickedEntriesWeekly,
  gymTopFiveClickedEntriesWeekly,
} from './Queries/queryMostClickedEntriesWeekly';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { Col, Container, Row } from 'reactstrap';
import { DatePicker, Form, Select } from 'antd';
import { ExploreEntryAnalyticsChart } from './ExploreEntryAnalyticsChart';

export type ExploreEntryAnalyticsWeeklyProps = {
  gym?: Gym;
};

export const ExploreEntryAnalyticsWeekly: React.FC<ExploreEntryAnalyticsWeeklyProps> = observer(({ gym }) => {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(dayjs());
  const [data, setData] = useState<AnalyticsRow[] | undefined>();
  const [entryType, setEntryType] = useState<string>('recipe');
  const [categories, setCategories] = useState<string[]>([]);

  const getPromiseFromEntryType = React.useCallback((row: AnalyticsRow) => {
    switch (row.entry_type) {
      case 'recipe':
        return Recipe.get(row.entry_id);
      case 'article':
        return ArticleEntry.findOne(row.entry_id);
      case 'workplace':
        return WorkplaceWorkoutEntry.findOne(row.entry_id);
      case 'workoutTemplate':
        return WorkoutTemplateEntry.findOne(row.entry_id);
      case 'activityWorkout':
        return ActivityWorkoutEntry.findOne(row.entry_id);
      case 'meditation':
        return MeditationEntry.findOne(row.entry_id);
      case 'breathing':
        return BreathingEntry.findOne(row.entry_id);
      case 'yoga':
        return YogaEntry.findOne(row.entry_id);
      case 'zoomMeeting':
        return ZoomMeetingEntry.findOne(row.entry_id);
      case 'video':
        return ZoomMeetingEntry.findOne(row.entry_id);
      default:
        return undefined;
    }
  }, []);

  const transformEntry = React.useCallback(
    (row: AnalyticsRow) => {
      const promise = getPromiseFromEntryType(row);
      if (promise) {
        return promise
          .then((entry) => {
            row.entry_name = entry.defaultName !== '' ? entry.defaultName : row.entry_id;
            return row;
          })
          .catch(async () => {
            row.entry_name = row.entry_id;
            return row;
          });
      }
      return row;
    },
    [getPromiseFromEntryType],
  );

  const fetchAndTransformData = React.useCallback(async () => {
    setData(undefined);

    console.log('entry type is now', entryType);

    const fetchedData = await AnalyticsData.query({
      query: gym ? gymTopFiveClickedEntriesWeekly : allTopFiveClickedEntriesWeekly,
      parameters: {
        start_date: {
          value: startDate.format('YYYYMMDD'),
        },
        end_date: {
          value: endDate.format('YYYYMMDD'),
        },
        gym_id: {
          value: gym?.id ?? '',
        },
        entry_type: {
          value: entryType,
        },
      },
    });

    const transformedFetchedData = await Promise.all(fetchedData.map((row) => transformEntry(row))).then(
      (transformedEntries) => transformedEntries,
    );

    const cats = new Array<string>();
    setData(
      transformedFetchedData
        .reduce((agg, row) => {
          const existing = agg.find((r) => r.event_date === row.event_date);
          cats.push(row.entry_name);
          if (existing) {
            existing[row.entry_name] = row.user_count;
            existing[`${row.entry_name}_select`] = row.select_count;
          } else {
            agg.push({
              event_date: row.event_date,
              [row.entry_name]: row.user_count,
              [`${row.entry_name}_select`]: row.select_count,
            });
          }
          return agg;
        }, new Array<AnalyticsRow>())
        .map((d) =>
          Array.from(Object.entries(d))
            .map(([key, value]) => [key, key === 'event_date' ? value : Number(value)])
            .reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {}),
        ),
    );
    setCategories([...new Set<string>(cats)]);
  }, [startDate, endDate, entryType, gym]);

  useEffect(() => {
    fetchAndTransformData();
  }, [startDate, endDate, entryType, gym]);

  const handleChangeValidFrom = React.useCallback((date) => setStartDate(date ?? dayjs().subtract(1, 'month')), []);

  const handleChangeValidUntil = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

  const handleChangeEntryType = React.useCallback((value) => {
    setEntryType(value);
  }, []);

  return (
    <Container>
      <SingleColRow>
        <h5>Top five most clicked entries weekly</h5>
      </SingleColRow>
      <Row style={styles.filter}>
        <Col xs={2}>
          <Form.Item label="Entry type">
            <Select value={entryType} onChange={handleChangeEntryType}>
              {entryTypes.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Item label="Start Date">
            <DatePicker value={startDate} onChange={handleChangeValidFrom} />
          </Form.Item>
        </Col>
        <Col xs={6}>
          <Form.Item label="End Date">
            <DatePicker value={endDate} onChange={handleChangeValidUntil} />
          </Form.Item>
        </Col>
        <Col xs={12}>{data && <ExploreEntryAnalyticsChart data={data} categories={categories} />}</Col>
      </Row>
    </Container>
  );
});

const styles = {
  filter: {
    marginTop: 16,
  },
};
