/**
 * Created by neo on 19.08.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Input, TimePicker } from 'antd';
import { Col, Row } from 'reactstrap';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';
import { FitnessProgramTemplate } from '../../../../Model/Coach/Program/Template/FitnessProgramTemplate';
import { CoachProgramTagsInput } from './CoachProgramTagsInput';
import { CoachProgramExcludedTagsInput } from './CoachProgramExcludedTagsInput';
import { ProgramParamsEditor } from './ProgramParamsEditor';
import { runInAction } from 'mobx';
import { Media } from '../../../../Model/Media/Media';
import { ViewMedia } from '../../../../Components/ViewMedia/ViewMedia';
import { ConditionalMedia } from '../../../../Model/Coach/SuperMacro/ConditionalMedia';
import { ProgramTemplateProgramParams } from '../../../../Model/Coach/Program/Template/ProgramTemplateProgramParams';
import { ProgramTemplateResult } from '../../../../Model/Coach/ProgramTemplateResult';
import { GymSelector } from '../../../../Components/GymSelector';
import { Gym } from '../../../../Model/Gym/Gym';
import dayjs from 'dayjs';
import { ScheduleTimeRange } from '../../../../Model/Coach/ScheduleTimeRange';

export type CoachProgramMainAttributesEditorProps = {
  template: FitnessProgramTemplate;
};

export const CoachProgramMainAttributesEditor: React.FC<CoachProgramMainAttributesEditorProps> = observer(
  ({ template }) => {
    const handleTemplateNameChange = React.useCallback(
      ({ target: { value } }) => {
        template.templateName = value;
      },
      [template],
    );

    const handleSetParams = React.useCallback(
      () => runInAction(() => (template.params = new ProgramTemplateProgramParams())),
      [template],
    );

    const handleRemoveParams = React.useCallback(() => runInAction(() => (template.params = undefined)), [template]);

    const handleImageUploaded = React.useCallback(
      (media: Media) => runInAction(() => (template.image = media)),
      [template],
    );

    const handleImageRemove = React.useCallback(() => runInAction(() => (template.image = undefined)), [template]);

    const handleSaveMedia = React.useCallback((media: Media) => new ConditionalMedia(media.toJS()), []);

    const handleAddResult = React.useCallback(
      () => runInAction(() => template.expectedResults.push(new ProgramTemplateResult())),
      [template],
    );

    const handleChangeGym = React.useCallback(
      (gym?: Gym) => {
        runInAction(() => (template.gymId = gym?.id));
      },
      [template],
    );

    const handleChangeDefaultTime = React.useCallback(
      (date, dateString) => {
        if (dateString) {
          runInAction(
            () => (template.defaultTimesOfDay.any = new ScheduleTimeRange({ timeOfDay: 'any', from: date?.format('HH:mm') })),
          );
        } else {
          runInAction(() => delete template.defaultTimesOfDay.any);
        }
      },
      [template],
    );

    return (
      <React.Fragment>
        <Row>
          <Col>
            <Form.Item label="Template Name">
              <Input value={template.templateName} onChange={handleTemplateNameChange} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Min. Duration (per Workout)">
              <Input value={template.minWorkoutDurationInMinutes} onChange={handleTemplateNameChange} disabled={true} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Max. Duration (per Workout)">
              <Input value={template.maxWorkoutDurationInMinutes} onChange={handleTemplateNameChange} disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Item label="Included Tags" extra="Used for search / filtering">
              <CoachProgramTagsInput template={template} />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item label="Excluded Tags" extra="Used for search / filtering">
              <CoachProgramExcludedTagsInput template={template} />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item label="Optional Gym" extra="Use it when the program should be exclusive for a company">
              <GymSelector gymId={template.gymId} onChange={handleChangeGym} />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item label="Default Time" extra="Specify a default time (optional)">
              <TimePicker
                onChange={handleChangeDefaultTime}
                format="HH:mm"
                minuteStep={15}
                allowClear={true}
                value={
                  template.defaultTimesOfDay.any?.fromFixed
                    ? dayjs(template.defaultTimesOfDay.any?.from, 'HH:mm:ss')
                    : undefined
                }
              />
            </Form.Item>
          </Col>
          <Col xs={12} style={{ paddingBottom: 16 }}>
            {template.params ? (
              <React.Fragment>
                <ProgramParamsEditor params={template.params} />
                <Button block danger onClick={handleRemoveParams}>
                  Remove Params
                </Button>
              </React.Fragment>
            ) : (
              <Button block type="primary" onClick={handleSetParams}>
                Set Params
              </Button>
            )}
          </Col>
          <Col xs={6}>
            <TranslationInputArray type="textarea" entity={template} field="name"  label="Name" />
          </Col>
          <Col xs={6}>
            <TranslationInputArray
              type="textarea"
              entity={template}
              field="description"
              label="Description"
            />
          </Col>
          <Col xs={6}>
            <Form.Item label="Expected Results">
              {template.expectedResults.map((result, index) => (
                <Row key={index.toString()}>
                  <Col xs="auto">{`${index + 1}.`}</Col>
                  <Col>
                    <TranslationInputArray key={index.toString()} entity={result} field="name" />
                  </Col>
                  <Col xs="auto">
                    <Button
                      size="small"
                      danger
                      onClick={() => runInAction(() => template.expectedResults.splice(index, 1))}
                    >
                      Remove
                    </Button>
                  </Col>
                </Row>
              ))}
            </Form.Item>
            <Button onClick={handleAddResult} block={true}>
              Add Expected Result
            </Button>
          </Col>
          <Col xs={12}>
            <Form.Item label="Medias">
              <ViewMedia medias={template.medias} disableCamera={true} onTransform={handleSaveMedia} />
            </Form.Item>
            {/*<Form.Item label="Image">*/}
            {/*  <CoachProgramTemplateMediaField*/}
            {/*    media={template.image}*/}
            {/*    onUploaded={handleImageUploaded}*/}
            {/*    onRemove={handleImageRemove}*/}
            {/*  />*/}
            {/*</Form.Item>*/}
          </Col>
        </Row>
      </React.Fragment>
    );
  },
);
