/**
 *
 * Created by neo on 25.02.17.
 */
import { observer, useLocalStore } from 'mobx-react';
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { BodyPartRegionAdmin, BodyPartSize, BodyPartType } from '../../../../Model/BodyPart/BodyPartRegionAdmin';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';
import { Form, Input, Select, Checkbox } from 'antd';
import { SingleColRow } from '../../../../Components/SingleColRow';
import {
  BodyPartPosition,
  BodyPartPositionSuperiorInferior,
  BodyPartPositionAnteroposterior,
  BodyPartPositionMediolateral,
  BodyPartPositionCranialCaudal,
  BodyPartPositionProximalDistal,
  BodyPartPositionSuperficialDeep,
} from '../../../../Model/BodyPart/BodyPartPosition';
import { BodyPartRegion } from '../../../../Model/BodyPart/BodyPartRegion';
import { runInAction } from 'mobx';
import { useEffect } from 'react';
import { BodyPartParentInput } from './BodyPartParentInput';
import { BodyPartAntagonistsInput } from './BodyPartAntagonistsInput';

export type BodyPartBasicInfoTabProps = {
  bodyPart: BodyPartRegionAdmin;
};

export const BodyPartBasicInfoTab: React.FC<BodyPartBasicInfoTabProps> = observer(
  ({ bodyPart }: BodyPartBasicInfoTabProps) => {
    const debounce = React.useRef<any>(undefined);
    const store = useLocalStore(() => ({
      identifierUnique: true,
      checkIdentifier(identifier: string, bodyPart: BodyPartRegionAdmin) {
        BodyPartRegion.get(identifier).then((result) =>
          runInAction(() => {
            console.log('result', result?.id, bodyPart.id);
            store.identifierUnique = !result || result.id === bodyPart.id;
          }),
        );
      },
    }));

    useEffect(() => {
      store.checkIdentifier(bodyPart.identifier, bodyPart);
    }, [bodyPart, store]);

    const handleChangeIdentifier = React.useCallback(
      ({ target: { value } }: any) => {
        bodyPart.identifier = value.trim().toLowerCase().replace(/ /g, '_');
        debounce.current && clearTimeout(debounce.current);
        debounce.current = setTimeout(() => {
          store.checkIdentifier(value, bodyPart);
        }, 300);
      },
      [bodyPart, store],
    );

    const handleChangeSuperiorInferior = React.useCallback(
      (value: string) => {
        if (!bodyPart.position) {
          bodyPart.position = new BodyPartPosition();
        }
        bodyPart.position.superiorInferior = value ? (value as BodyPartPositionSuperiorInferior) : undefined;
      },
      [bodyPart],
    );

    const handleChangeAnteriorPosterior = React.useCallback(
      (value: string) => {
        if (!bodyPart.position) {
          bodyPart.position = new BodyPartPosition();
        }
        bodyPart.position.anteriorPosterior = value ? (value as BodyPartPositionAnteroposterior) : undefined;
      },
      [bodyPart],
    );

    const handleChangeMedialLateral = React.useCallback(
      (value: string) => {
        if (!bodyPart.position) {
          bodyPart.position = new BodyPartPosition();
        }
        bodyPart.position.medialLateral = value ? (value as BodyPartPositionMediolateral) : undefined;
      },
      [bodyPart],
    );

    const handleChangeCranialCaudal = React.useCallback(
      (value: string) => {
        if (!bodyPart.position) {
          bodyPart.position = new BodyPartPosition();
        }
        bodyPart.position.cranialCaudal = value ? (value as BodyPartPositionCranialCaudal) : undefined;
      },
      [bodyPart],
    );

    const handleChangeProximalDistal = React.useCallback(
      (value: string) => {
        if (!bodyPart.position) {
          bodyPart.position = new BodyPartPosition();
        }
        bodyPart.position.proximalDistal = value ? (value as BodyPartPositionProximalDistal) : undefined;
      },
      [bodyPart],
    );

    const handleChangeSuperficialDeep = React.useCallback(
      (value: string) => {
        if (!bodyPart.position) {
          bodyPart.position = new BodyPartPosition();
        }
        bodyPart.position.superficialDeep = value ? (value as BodyPartPositionSuperficialDeep) : undefined;
      },
      [bodyPart],
    );

    const handleChangeHide = React.useCallback(
      ({ target: { checked } }: any) => {
        bodyPart.hide = checked;
      },
      [bodyPart],
    );

    return (
      <SingleColRow>
        <Form layout="vertical">
          <Row>
            <Col md={6}>
              <Form.Item
                label="Identifier"
                validateStatus={store.identifierUnique ? 'success' : 'error'}
                help="Has to be unique"
              >
                <Input type="text" value={bodyPart.identifier} onChange={handleChangeIdentifier} />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item label="Hide" help="Won't appear in the app">
                <Checkbox onChange={handleChangeHide} checked={bodyPart.hide}>
                  Hide
                </Checkbox>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item label="Type">
                <Select value={bodyPart.type} onChange={(e) => (bodyPart.type = e as BodyPartType)}>
                  <Select.Option value="MUSCLE">{'Muscle'}</Select.Option>
                  <Select.Option value="SINEW">{'Sinew'}</Select.Option>
                  <Select.Option value="BONE">{'Bone'}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item label="Size">
                <Select value={bodyPart.size} onChange={(e) => (bodyPart.size = e as BodyPartSize)}>
                  <Select.Option value="tiny">{'Tiny'}</Select.Option>
                  <Select.Option value="small">{'Small'}</Select.Option>
                  <Select.Option value="medium">{'Medium'}</Select.Option>
                  <Select.Option value="large">{'Large'}</Select.Option>
                  <Select.Option value="huge">{'Huge'}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {/*<Col md={6}>*/}
            {/*  <Form.Item*/}
            {/*    label="Superior / Inferior"*/}
            {/*    help="https://en.wikipedia.org/wiki/Anatomical_terms_of_location#/media/File:Blausen_0019_AnatomicalDirectionalReferences.png"*/}
            {/*  >*/}
            {/*    <Select*/}
            {/*      value={bodyPart.position?.superiorInferior}*/}
            {/*      onChange={handleChangeSuperiorInferior}*/}
            {/*      disabled={!!bodyPart.parents?.position?.superiorInferior}*/}
            {/*    >*/}
            {/*      <Select.Option value="">{''}</Select.Option>*/}
            {/*      <Select.Option value="superior">{'Superior / Upper Body'}</Select.Option>*/}
            {/*      <Select.Option value="inferior">{'Inferior / Lower Body'}</Select.Option>*/}
            {/*    </Select>*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}
            {/*<Col md={6}>*/}
            {/*  <Form.Item label="Anterior Posterior">*/}
            {/*    <Select*/}
            {/*      value={bodyPart.position?.anteriorPosterior}*/}
            {/*      onChange={handleChangeAnteriorPosterior}*/}
            {/*      disabled={!!bodyPart.parents?.position?.anteriorPosterior}*/}
            {/*    >*/}
            {/*      <Select.Option value="">{''}</Select.Option>*/}
            {/*      <Select.Option value="anterior">{'Anterior / Front'}</Select.Option>*/}
            {/*      <Select.Option value="posterior">{'Posterior / Back'}</Select.Option>*/}
            {/*    </Select>*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}
            {/*<Col md={6}>*/}
            {/*  <Form.Item label="Medial / Lateral">*/}
            {/*    <Select*/}
            {/*      value={bodyPart.position?.medialLateral}*/}
            {/*      onChange={handleChangeMedialLateral}*/}
            {/*      disabled={!!bodyPart.parents?.position?.medialLateral}*/}
            {/*    >*/}
            {/*      <Select.Option value="">{''}</Select.Option>*/}
            {/*      <Select.Option value="medial">{'Medial / Center'}</Select.Option>*/}
            {/*      <Select.Option value="lateral">{'Lateral / Side'}</Select.Option>*/}
            {/*    </Select>*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}
            {/*<Col md={6}>*/}
            {/*  <Form.Item label="Cranial / Caudal" help="From hip to head">*/}
            {/*    <Select*/}
            {/*      value={bodyPart.position?.cranialCaudal}*/}
            {/*      onChange={handleChangeCranialCaudal}*/}
            {/*      disabled={!!bodyPart.parents?.position?.cranialCaudal}*/}
            {/*    >*/}
            {/*      <Select.Option value="">{''}</Select.Option>*/}
            {/*      <Select.Option value="cranial">{'Cranial / More to the head'}</Select.Option>*/}
            {/*      <Select.Option value="caudal">{'Caudal / More to the hip'}</Select.Option>*/}
            {/*    </Select>*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}
            {/*<Col md={6}>*/}
            {/*  <Form.Item label="Proximal / Distal" help="More for arm and leg related muscles">*/}
            {/*    <Select*/}
            {/*      value={bodyPart.position?.proximalDistal}*/}
            {/*      onChange={handleChangeProximalDistal}*/}
            {/*      disabled={!!bodyPart.parents?.position?.proximalDistal}*/}
            {/*    >*/}
            {/*      <Select.Option value="">{''}</Select.Option>*/}
            {/*      <Select.Option value="proximal">{'Proximal / Closer to Torso'}</Select.Option>*/}
            {/*      <Select.Option value="distal">{'Distal / Further away from Torso'}</Select.Option>*/}
            {/*    </Select>*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}
            {/*<Col md={6}>*/}
            {/*  <Form.Item label="Superficial / Deep" help="More to the surface / more inside the body">*/}
            {/*    <Select*/}
            {/*      value={bodyPart.position?.superficialDeep}*/}
            {/*      onChange={handleChangeSuperficialDeep}*/}
            {/*      disabled={!!bodyPart.parents?.position?.superficialDeep}*/}
            {/*    >*/}
            {/*      <Select.Option value="">{''}</Select.Option>*/}
            {/*      <Select.Option value="superficial">{'Superficial / More to the surface'}</Select.Option>*/}
            {/*      <Select.Option value="deep">{'Deep / Further away from surface'}</Select.Option>*/}
            {/*    </Select>*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}
            <Col md={6}>
              <BodyPartParentInput bodyPart={bodyPart} />
            </Col>
            <Col md={6}>
              <BodyPartAntagonistsInput bodyPart={bodyPart} />
            </Col>
            <Col md={6}>
              <TranslationInputArray entity={bodyPart} field="name" label="Name" />
            </Col>
            <Col md={6}>
              <Form.Item label="Latin Name">
                <Input value={bodyPart.latinName} onChange={(e) => (bodyPart.latinName = e.target.value)} />
              </Form.Item>
            </Col>
            <Col md="12">
              <TranslationInputArray entity={bodyPart} field="description" label="Description" type="textarea" />
            </Col>
          </Row>
        </Form>
      </SingleColRow>
    );
  },
);
