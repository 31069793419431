/**
 * Created by neo on 22.11.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { PageResult } from '../../../../../Model/PageResult';
import { RouteChallengeTeam } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeTeam';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { Pager } from '../../../../../Components/Pager';
import { Table } from 'reactstrap';
import { Input } from 'antd';
import { RouteChallengeTeamsTableRow } from './RouteChallengeTeamsTableRow';

export type RouteChallengeTeamsTabProps = {
  challenge: RouteChallenge;
};

export const RouteChallengeTeamsTab: React.FC<RouteChallengeTeamsTabProps> = observer(({ challenge }) => {
  const [query, setQuery] = useState('');
  const [teams, setTeams] = useState(new PageResult<RouteChallengeTeam>());
  const [page, setPage] = useState(0);

  useEffect(() => {
    PageResult.execute(
      RouteChallengeTeam.find({
        query,
        challengeId: challenge.id,
        page: page,
        size: 50,
        sort: 'companyRank,ASC',
      }),
      RouteChallengeTeam.count({ query, challengeId: challenge.id }),
      page,
      50,
    ).then((res) => setTeams(res));
  }, [query, page, challenge]);

  return (
    <React.Fragment>
      <h3>Teams</h3>
      <SingleColRow>
        <Input value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Search" />
      </SingleColRow>
      <SingleColRow>
        <Pager page={teams} onPage={(newPage) => setPage(newPage)} />
      </SingleColRow>
      <SingleColRow>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Company</th>
              <th>Name</th>
              <th>Private</th>
              <th>Collected Points</th>
              <th>Members</th>
              <th>Last Location Reached At</th>
            </tr>
          </thead>
          {teams.content.map((team) => (
            <RouteChallengeTeamsTableRow key={team.id} challenge={challenge} team={team} />
          ))}
        </Table>
      </SingleColRow>
      <SingleColRow>
        <Pager page={teams} onPage={(newPage) => setPage(newPage)} />
      </SingleColRow>
    </React.Fragment>
  );
});
