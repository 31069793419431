/**
 * Created by katarinababic on 15.11.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Row, Col, Container, Table, Spinner, Alert } from 'reactstrap';
import { FolderContent } from '../../../Model/Media/FileStorage/FolderContent';
import { Button } from 'antd';
import { FolderTableRow } from './FolderTableRow';
import { CreateFolderDialog } from './CreateFolderDialog';
import { EditFolderDialog } from './EditFolderDialog';
import { DeleteFolderDialog } from './DeleteFolderDialog';
import { FileStorageResult } from '../../../Model/Media/FileStorage/FileStorageResult';
import saveAs from 'file-saver';
import { InfiniteScroll } from '../../../Components/InfiniteScroll';
import { debounce } from 'lodash';

export type FileStorageScreenProps = {};

export const FileStorageScreen: React.FC<FileStorageScreenProps> = observer((props) => {
  const hasMoreResults = React.useRef(false);
  const page = React.useRef(0);
  const loadingMore = React.useRef(false);

  const [folders, setFolders] = React.useState<FolderContent[]>([]);
  const [folderToEdit, setFolderToEdit] = React.useState<FolderContent | null>(null);
  const [processing, setProcessing] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [nextPageToken, setNextPageToken] = React.useState<string | undefined>(undefined);

  const [showEditDialog, setShowEditDialog] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [showUploadDialog, setShowUploadDialog] = React.useState(false);

  const loadData = async () => {
    setProcessing(true);
    try {
      const res = await FileStorageResult.getFolderContent('/', { size: 20, pageToken: nextPageToken });
      const resFolders = res.content.filter((f) => f.isDirectory).map((folder) => new FolderContent(folder));
      setFolders((previous) => (page.current === 0 ? resFolders : previous.concat(resFolders)));
      setNextPageToken(res.nextPageToken);
      hasMoreResults.current = !!res.nextPageToken;
    } catch (e) {
      console.log('FileStorageResult.getFolderContent error', e);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadMore = debounce(() => {
    if (!processing && hasMoreResults.current && !loadingMore.current) {
      loadingMore.current = true;
      page.current += 1;
      loadData().then(() => {
        loadingMore.current = false;
      });
    }
  }, 300);

  const reloadData = debounce(() => {
    page.current = 0;
    setNextPageToken(undefined);
    loadData();
  }, 300);

  const handleShowEditFolderDialog = React.useCallback((folder: FolderContent) => {
    setShowEditDialog(true);
    setFolderToEdit(folder);
  }, []);

  const handleCancelEditFolderDialog = React.useCallback(() => {
    setShowEditDialog(false);
    setFolderToEdit(null);
    reloadData();
  }, [reloadData]);

  const handleShowDeleteFolderDialog = React.useCallback((folder: FolderContent) => {
    setShowDeleteDialog(true);
    setFolderToEdit(folder);
  }, []);

  const handleDeleteFolder = React.useCallback(() => {
    setShowDeleteDialog(false);
    setFolderToEdit(null);
    reloadData();
  }, [reloadData]);

  const handleCancelDelete = React.useCallback(() => {
    setShowDeleteDialog(false);
    setFolderToEdit(null);
  }, []);

  const handleCreateFolder = React.useCallback(() => {
    setShowCreateDialog(true);
  }, []);

  const handleCancelCreateDialog = React.useCallback(() => {
    setShowCreateDialog(false);
    reloadData();
  }, [reloadData]);

  const handleShowUploadDialog = React.useCallback(() => {
    setShowUploadDialog(true);
  }, []);

  const handleCancelUploadDialog = React.useCallback(() => {
    setShowUploadDialog(false);
  }, []);

  const handleDownloadFolder = React.useCallback((folder: FolderContent) => {
    setProcessing(true);
    FolderContent.download([folder.name])
      .then((res) => {
        saveAs(res.data, `${folder.displayName}.zip`);
      })
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setProcessing(false));
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <h1>{'File Storage'}</h1>
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={handleCreateFolder}>
            Create New Folder
          </Button>
        </Col>

        {/*uploading an entire folder doesn't work yet*/}
        {/*<Col xs="auto">*/}
        {/*  <Button type="primary" onClick={handleShowUploadDialog}>*/}
        {/*    Upload New Folder*/}
        {/*  </Button>*/}
        {/*</Col>*/}
      </Row>
      {processing ? (
        <Row>
          <Col xs={12} style={{ paddingTop: 40, display: 'flex', justifyContent: 'center' }}>
            <Spinner />
          </Col>
        </Row>
      ) : (
        <Table striped hover>
          {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          <thead>
            <tr>
              <th />
              <th>{'Name'}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {folders.map((folder, index) => (
              <FolderTableRow
                key={`${folder.name}-${index}`}
                folder={folder}
                onEditFolder={handleShowEditFolderDialog}
                onDeleteFolder={handleShowDeleteFolderDialog}
                onDownloadFolder={handleDownloadFolder}
              />
            ))}
          </tbody>
        </Table>
      )}
      {hasMoreResults.current ? <InfiniteScroll loading={processing} onLoad={loadMore} /> : null}
      <CreateFolderDialog isOpen={showCreateDialog} onClose={handleCancelCreateDialog} />

      {/*uploading an entire folder doesn't work yet*/}
      {/*{showUploadDialog && <UploadFolderDialog isOpen={showUploadDialog} onClose={handleCancelUploadDialog} />}*/}

      {folderToEdit && (
        <EditFolderDialog isOpen={showEditDialog} onClose={handleCancelEditFolderDialog} folder={folderToEdit} />
      )}
      {folderToEdit && (
        <DeleteFolderDialog
          folder={folderToEdit}
          isOpen={showDeleteDialog}
          onClose={handleCancelDelete}
          onDeleteFolder={handleDeleteFolder}
        />
      )}
    </Container>
  );
});
