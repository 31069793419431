import { observable, toJS, computed, ObservableMap } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Retry } from '../../Utils/Retry';
import { ExerciseId } from './ExerciseId';
import { AbstractExercise } from './AbstractExercise';
import { BaseTrackingKey } from '../ProgramPortfolio/TrackingKeys';
import { ExerciseFilterJson } from './ExerciseFilter';
import { Pageable } from '../Pageable';
import { BodyPartRegion } from '../BodyPart/BodyPartRegion';
import { BodyPartJoint } from '../BodyPart/BodyPartJoint';

export type ExerciseQueryRequest = ExerciseFilterJson &
  Pageable & { query?: string; sourceId: string; sourceType: string };

export class Exercise extends AbstractExercise {
  @observable
  name: string = '';
  @observable
  names: ObservableMap<string, string> = observable.map({});
  @observable
  description: string = '';
  @observable
  equipmentTypes: string[] = [];
  @observable
  secondaryEquipmentTypes: string[] = [];
  @observable
  synergists: BodyPartRegion[] = [];
  @observable
  bodyParts: BodyPartRegion[] = [];
  @observable
  stabilizers: BodyPartRegion[] = [];
  @observable
  joints: BodyPartJoint[] = [];
  @observable
  allBodyParts: BodyPartRegion[] = [];
  @observable
  allSynergists: BodyPartRegion[] = [];
  @observable
  allStabilizers: BodyPartRegion[] = [];
  @observable
  allBodyPartIds: Array<string> = []; // from backend
  @observable
  allSynergistIds: Array<string> = []; // from backend
  @observable
  allStabilizerIds: Array<string> = []; // from backend

  constructor(json?: any) {
    super(json);
    if (json) {
      this.name = json.name;
      this.names = observable.map(json.names || {});
      this.description = json.description;
      this.synergists = (json.synergists || []).map((b) => new BodyPartRegion(b));
      this.bodyParts = (json.bodyParts || []).map((b) => new BodyPartRegion(b));
      this.stabilizers = (json.stabilizers || []).map((b) => new BodyPartRegion(b));
      this.equipmentTypes = json.equipmentTypes || [];
      this.secondaryEquipmentTypes = json.secondaryEquipmentTypes || [];
      this.allBodyPartIds = json.allBodyPartIds || [];
      this.allSynergistIds = json.allSynergistIds || [];
      this.allStabilizerIds = json.allStabilizerIds || [];
    }
  }

  toJS(): any {
    return Object.assign(super.toJS(), {
      name: this.name,
      names: toJS(this.names),
      description: this.description,
    });
  }

  remove() {
    return HttpBackend.delete(`/activity/exercise/v3/admin/variation/${this.id}`, {
      sourceType: this.sourceType,
      sourceId: this.sourceId,
    });
  }

  accept(tags: string[]) {
    if (tags.length > 0) {
      const globalTags = tags.filter((t) => t.startsWith('focus:') || t.startsWith('group:'));
      if (globalTags.length === 0 || globalTags.every((t) => this.tags.indexOf(t) !== -1)) {
        return this.tagConditions.length === 0 || this.tagConditions.findIndex((c) => c.matches(tags)) !== -1;
      }
      return false;
    }
    return true;
  }

  @computed
  get exerciseIdentifier(): ExerciseId {
    return new ExerciseId(this.id, this.sourceType, this.sourceId);
  }

  @computed
  get defaultTrackingKeys(): BaseTrackingKey[] {
    switch (this.type.toLowerCase()) {
      case 'strength':
        if (this.equipmentTypes.indexOf('FREE') !== -1) {
          return ['REPETITIONS', 'DURATION', 'BREAK'];
        }
        return ['REPETITIONS', 'WEIGHT', 'DURATION', 'BREAK'];
      case 'endurance':
      case 'flexibility':
      case 'balance':
      case 'relax':
      default:
        return ['DURATION', 'BREAK'];
    }
  }

  @computed
  get trackingParameters(): BaseTrackingKey[] {
    if (this.trackingKeys.length > 0) {
      const hasBreak = this.trackingKeys.indexOf('BREAK') !== -1;
      const hasDuration = this.trackingKeys.indexOf('DURATION') !== -1;
      if (!hasBreak) {
        if (!hasDuration) {
          return this.trackingKeys.concat(['DURATION', 'BREAK']);
        }
        return this.trackingKeys.concat(['BREAK']);
      } else if (!hasDuration) {
        if (!hasBreak) {
          return this.trackingKeys.concat(['DURATION', 'BREAK']);
        }
        return this.trackingKeys.concat(['DURATION']);
      }
      return this.trackingKeys;
    }
    return this.defaultTrackingKeys;
  }

  @computed
  get tracksDuration(): boolean {
    return this.trackingParameters.indexOf('DURATION') !== -1;
  }

  @computed
  get tracksWeightOrReps(): boolean {
    return this.trackingParameters.indexOf('WEIGHT') !== -1 || this.trackingParameters.indexOf('REPETITIONS') !== -1;
  }

  static find(params: Partial<ExerciseQueryRequest> = {}): Promise<Exercise[]> {
    return HttpBackend.get('/activity/exercise/v3', params).then((data) => data.map((e) => new Exercise(e)));
  }

  static autocomplete(params: Partial<ExerciseQueryRequest> = {}): Promise<Exercise[]> {
    if (params.query) {
      return HttpBackend.get('/activity/exercise/v3/autocomplete', params).then((data) => data.map((e) => new Exercise(e)));
    }
    return Exercise.find(params);
  }

  static get(variationId: string, sourceType?: string, sourceId?: string): Promise<Exercise | undefined> {
    return HttpBackend.get(`/activity/exercise/v3/${variationId}`, { sourceType, sourceId }).then((res) =>
      res ? new Exercise(res) : undefined,
    );
  }

  static importTranslation(id: string, field: string, value: string, lang: string) {
    return Retry.tryTimes(() =>
      HttpBackend.post(`/activity/exercise/variation/${id}/importTranslation`, { value, lang, field }),
    );
  }
}
