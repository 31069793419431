/**
 * Created by neo on 05.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { MicroCycleTemplateConfiguration } from '../../../../../Model/Coach/SuperMacro/MicroCycleTemplateConfiguration';
import styled from '@emotion/styled';
import { WorkoutDayEditor } from './WorkoutDayEditor';
import { SuperMacroWorkoutDayConfiguration } from '../../../../../Model/Coach/SuperMacro/SuperMacroWorkoutDayConfiguration';
import { SuperMacroMesoCycleTemplate } from '../../../../../Model/Coach/SuperMacro/SuperMacroMesoCycleTemplate';

export type MicroCycleConfigurationEditorProps = {
  microCycleConfiguration: MicroCycleTemplateConfiguration;
  mesoCycle: SuperMacroMesoCycleTemplate;
};

const Container = styled.div`
  background-color: #cee741;
  display: flex;
  flex: 1;
  border-bottom: 1px solid black;
`;

const AddWorkoutDayButton = styled.button`
  border: 1px dashed lightgray;
  padding: 8px;
  &:hover {
    border: 1px dashed black;
  }
`;

export const MicroCycleConfigurationEditor: React.FC<MicroCycleConfigurationEditorProps> = observer(
  ({ microCycleConfiguration, mesoCycle }: MicroCycleConfigurationEditorProps) => {
    const handleAddWorkoutDay = React.useCallback(() => {
      microCycleConfiguration.workoutDays.push(new SuperMacroWorkoutDayConfiguration());
      mesoCycle.save();
    }, [microCycleConfiguration, mesoCycle]);

    return (
      <Container>
        {microCycleConfiguration.workoutDays.map((workoutDay, index) => (
          <WorkoutDayEditor key={workoutDay.id} mesoCycle={mesoCycle} workoutDay={workoutDay} index={index} />
        ))}
        {/*<AddWorkoutDayButton onClick={handleAddWorkoutDay}>*/}
        {/*  Add*/}
        {/*  <br />*/}
        {/*  Day*/}
        {/*</AddWorkoutDayButton>*/}
      </Container>
    );
  },
);
