/**
 * Created by neo on 01.02.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { Form, Input } from 'antd';
import { runInAction } from 'mobx';
import { ViewMediaItem } from '../../../../../Components/ViewMedia/ViewMediaItem';
import Dropzone from 'react-dropzone';
import MediaService from '../../../../../Services/MediaService';
import { Instructor } from '../../../../../Model/Explore/Instructor';
import { TranslationInputArray } from '../../../../../Components/Translation/TranslationInputArray';

export type YogaInstructorEditProps = {
  entry: { instructor?: Instructor; narrator?: Instructor };
  field?: 'instructor' | 'narrator';
};

export const YogaInstructorEdit: React.FC<YogaInstructorEditProps> = observer(({ entry, field = 'instructor' }) => {
  const person = entry[field];

  const handleNameChange = React.useCallback(
    ({ target: { value } }) => {
      if (!entry[field]) {
        entry[field] = new Instructor();
      }
      runInAction(() => {
        if (field === 'narrator' && entry.narrator) {
          entry.narrator.name = value;
        } else if (entry.instructor) {
          entry.instructor.name = value;
        }
      });
    },
    [entry, field],
  );

  const handleDropImage = React.useCallback(
    (files: File[]) => {
      const file = files.length > 0 ? files[0] : undefined;
      if (!entry[field]) {
        entry[field] = new Instructor();
      }
      if (file) {
        MediaService.uploadMedia(file).then((media) =>
          runInAction(() => {
            if (field === 'narrator' && entry.narrator) {
              entry.narrator.image = media;
            } else if (entry.instructor) {
              entry.instructor.image = media;
            }
          }),
        );
      }
    },
    [entry, field],
  );

  const handleRemoveFile = React.useCallback(() => {
    if (!entry[field]) {
      entry[field] = new Instructor();
    }
    runInAction(() => {
      if (field === 'narrator' && entry.narrator) {
        entry.narrator.image = undefined;
      } else if (entry.instructor) {
        entry.instructor.image = undefined;
      }
    });
  }, [entry, field]);

  return (
    <Row>
      <Col>
        <Form.Item label={'Narrator / Instructor Name'}>
          <Input value={person?.name} onChange={handleNameChange} />
        </Form.Item>
        {person && (
          <Form.Item label={'Narrator / Instructor Description'}>
            <TranslationInputArray entity={person} field="description" />
          </Form.Item>
        )}
        <Form.Item label={'Narrator / Instructor Image'}>
          {person?.image ? (
            <ViewMediaItem media={person.image} onRemove={handleRemoveFile} />
          ) : (
            <Dropzone onDrop={handleDropImage}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className="dropzone"
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input {...getInputProps()} />
                  {'Drop an image here'}
                </div>
              )}
            </Dropzone>
          )}
        </Form.Item>
      </Col>
    </Row>
  );
});
