/**
 * Created by neo on 16.03.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { MesoCycle } from '../../../../../Model/Coach/Program/MesoCycle';
import { MicroCycle } from '../../../../../Model/Coach/Program/MicroCycle';
import { Col, Row } from 'reactstrap';
import { Alert } from 'antd';
import { MicroCycleView } from '../MicroCycleView';
import { CoachProgram } from '../../../../../Model/Coach/Program/CoachProgram';
import { FitnessProgramTemplate } from '../../../../../Model/Coach/Program/Template/FitnessProgramTemplate';
import { SingleColRow } from '../../../../../Components/SingleColRow';

export type FitnessProgramMesoCycleViewProps = {
  program: CoachProgram;
  mesoCycle: MesoCycle;
  index: number;
};

export const FitnessProgramMesoCycleView: React.FC<FitnessProgramMesoCycleViewProps> = observer(
  ({ program, mesoCycle, index }) => {
    const [fetching, setFetching] = useState(true);
    const [microCycles, setMicroCycles] = useState<MicroCycle[]>([]);

    const mesoCycleTemplate =
      program.template instanceof FitnessProgramTemplate
        ? program.template?.mesoCycles.find((m) => m.id === mesoCycle.configuration.mesoCycleTemplateId)
        : undefined;

    useEffect(() => {
      setFetching(true);
      setMicroCycles([]);
      MicroCycle.find({ athleteId: program.athleteId, mesoCycleId: mesoCycle.id })
        .then((res) => setMicroCycles(res))
        .finally(() => setFetching(false));
    }, [mesoCycle, program]);

    return (
      <React.Fragment>
        <Row style={{ marginTop: 16 }}>
          <Col>
            <h3>
              {`${index + 1}. `}
              {mesoCycleTemplate?.templateName ?? mesoCycleTemplate?.type}
            </h3>
          </Col>
        </Row>
        {fetching && (
          <SingleColRow>
            <Alert message="Loading..." />
          </SingleColRow>
        )}
        <Row>
          {microCycles
            .slice()
            .sort((a, b) => b.weekIndex - a.weekIndex)
            .map((microCycle, index) => (
              <Col xs={12} key={microCycle.id}>
                <MicroCycleView program={program} mesoCycle={mesoCycle} microCycle={microCycle} />
              </Col>
            ))}
        </Row>
      </React.Fragment>
    );
  },
);
