/**
 * Created by neo on 01.05.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { SingleColRow } from '../../../Components/SingleColRow';
import { Col, Row } from 'reactstrap';
import { BodyPartJoint } from '../../../Model/BodyPart/BodyPartJoint';
import { BodyPartJointTable } from './BodyPartJointTable';
import { Input, Button } from 'antd';
import { useDebouncedEffect } from '../../../Utils/useDebouncedEffect';
import { useNavigate } from 'react-router-dom';

export type BodyPartJointTabProps = {};

export const BodyPartJointTab: React.FC<BodyPartJointTabProps> = observer((props: BodyPartJointTabProps) => {
  const navigate = useNavigate();

  const [result, setResult] = useState<BodyPartJoint[]>([]);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');

  useDebouncedEffect(() => {
    BodyPartJoint.find({ name: query, page, sort: 'defaultName,ASC', size: 200 }).then((res) => setResult(res));
  }, [query, page]);

  const handleRemove = React.useCallback(
    async (bodyPart: BodyPartJoint) => {
      if (window.confirm('Are you sure?')) {
        await bodyPart.remove();
        navigate(0);
      }
    },
    [navigate],
  );

  const handlePage = React.useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handleQueryChange = React.useCallback(({ target: { value } }: any) => {
    setQuery(value);
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Input type="text" onChange={handleQueryChange} placeholder="Search..." />
        </Col>
        <Col xs="auto">
          <Button type="primary" href={`/metadata/bodypart/joint/view/create`}>
            {'Create Joint'}
          </Button>
        </Col>
      </Row>
      <SingleColRow>
        <BodyPartJointTable joints={result} onRemove={handleRemove} />
      </SingleColRow>
    </React.Fragment>
  );
});
