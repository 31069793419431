/**
 * @flow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { ExerciseVariationFilterMultipleSelection, ValueEntry } from './ExerciseVariationFilterMultipleSelection';

export type ExerciseVariationFilterExecutionTypesProps = {
  filter: ExerciseFilter;
};

const Values = [
  { value: 'ONE_SIDED', label: 'One Sided' },
  { value: 'BOTH_SIDED', label: 'Both Sided' },
  { value: 'ALTERNATING', label: 'Alternating' },
];

export const ExerciseVariationFilterExecutionTypes: React.FC<ExerciseVariationFilterExecutionTypesProps> = observer(
  ({ filter }: ExerciseVariationFilterExecutionTypesProps) => {
    const handleChange = React.useCallback(
      (values: ValueEntry[]) => {
        filter.executionTypes = (values ?? []).map((e) => e.value);
      },
      [filter],
    );

    return (
      <ExerciseVariationFilterMultipleSelection
        label="Execution Types"
        container={filter.executionTypes || []}
        values={Values}
        onChange={handleChange}
      />
    );
  },
);
