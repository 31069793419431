/**
 * Created by neo on 02.12.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';

export type GymRouteChallengeTableRowProps = {
  challenge: RouteChallenge;
};

export const GymRouteChallengeTableRow: React.FC<GymRouteChallengeTableRowProps> = observer(({ challenge }) => {
  const [participantCount, setParticipantCount] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(
  //     () =>
  //       GymChallengeParticipant.count({ challengeId: challenge.id, gymId: challenge.gymId }).then((result) =>
  //         setParticipantCount(result),
  //       ),
  //     5000,
  //   );
  //   return () => clearInterval(interval);
  // }, [challenge]);

  return (
    <tr>
      <td>
        <Link to={`/engagement/route-challenges/${challenge.id}`}>{challenge.id}</Link>
      </td>
      <td>{challenge.defaultName}</td>
      <td>{dayjs(challenge.startDateTime).format('DD.MM.YYYY')}</td>
      <td>{dayjs(challenge.endDateTime).format('DD.MM.YYYY HH:mm')}</td>
      <td>
        {challenge.totalCollectedPoints} / {challenge.locations[challenge.locations.length - 1]?.totalPointsRequired}
      </td>
      <td>TODO</td>
    </tr>
  );
});
