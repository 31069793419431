/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { DefaultStageNodeWidget, StageNodeWidgetProps } from '../Default/DefaultStageNodeWidget';
import { CollectNodeModel } from './CollectNodeModel';

export type FlattenNodeProps = {
  node: CollectNodeModel;
} & StageNodeWidgetProps;

export const CollectNodeWidget: React.FC<FlattenNodeProps> = observer(({ node, engine, size }: FlattenNodeProps) => {
  return <DefaultStageNodeWidget engine={engine} node={node} size={size} disableEdit={true} />;
});
