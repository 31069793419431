/**
 * Created by neo on 17.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

export type HourDistributionChartProps = {
  data: AnalyticsRow[];
};

export const HourDistributionChart: React.FC<HourDistributionChartProps> = observer(({ data }) => {
  const [enabledFields, setEnabledFields] = useState<{ [key: string]: boolean }>({});

  const handleLegendClick = React.useCallback((data) => {
    const dataKey = typeof data.dataKey === 'string' ? data.dataKey : 'avg_activities_per_user';
    setEnabledFields((existing) => Object.assign({}, existing, { [dataKey]: !existing[dataKey] }));
  }, []);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart height={250} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" />
        <YAxis />
        <Tooltip />
        <Legend onClick={handleLegendClick} />

        <Bar
          type="monotone"
          dataKey="FeedEntry_read_count"
          stackId="a"
          fill="#913CA4"
          hide={enabledFields.FeedEntry_read_count}
          name="# Articles Read"
        />
        <Bar
          type="monotone"
          dataKey="gym_strength_count"
          stackId="a"
          fill="#D2A45F"
          hide={enabledFields.gym_strength_count}
          name="# Workouts"
        />
        <Bar
          type="monotone"
          dataKey="workplace_count"
          stackId="a"
          fill="#6980C9"
          hide={enabledFields.workplace_count}
          name="# Workplace Workouts"
        />
        <Bar
          type="monotone"
          dataKey="activity_logged_count"
          stackId="a"
          fill="#0723D9"
          hide={enabledFields.activity_logged_count}
          name="# Activities Logged"
        />
        <Bar
          type="monotone"
          dataKey="breathingSession_finished_count"
          stackId="a"
          fill="#E98FF1"
          hide={enabledFields.breathingSession_finished_count}
          name="# Breathing Sessions"
        />
        <Bar
          type="monotone"
          dataKey="meditationSession_finished_count"
          stackId="a"
          fill="#1BDF91"
          hide={enabledFields.meditationSession_finished_count}
          name="# Meditation Sessions"
        />
        <Bar
          type="monotone"
          dataKey="recipe_count"
          stackId="a"
          fill="#291412"
          hide={enabledFields.recipe_count}
          name="# Recipes Read"
        />
        <Bar
          type="monotone"
          dataKey="yogaSession_finished_count"
          stackId="a"
          fill="#53658D"
          hide={enabledFields.yogaSession_finished_count}
          name="# Yoga Sessions"
        />
        <Bar
          type="monotone"
          dataKey="user_count"
          fill="#FFE58D"
          hide={enabledFields.user_count}
          name="# Unique Users"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
});
