/**
 * Created by neo on 05.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { PipelineParam } from '../../../../Model/Coach/PipelineParam';
import { Input, InputNumber, Checkbox } from 'antd';
import { runInAction } from 'mobx';
import { v4 as UUID } from 'uuid';
import CreatableSelect from 'react-select/creatable';

export type PipelineParamEntryDefaultValueProps = {
  param: PipelineParam;
};

export const PipelineParamEntryDefaultValue: React.FC<PipelineParamEntryDefaultValueProps> = observer(
  ({ param }: PipelineParamEntryDefaultValueProps) => {
    const handleChangeNumber = React.useCallback(
      (value?: number | string | null) => {
        param.defaultValue = value;
      },
      [param],
    );

    const handleChangeString = React.useCallback(
      ({ target: { value } }) => {
        param.defaultValue = value;
      },
      [param],
    );

    const handleChangeBoolean = React.useCallback(
      ({ target: { checked } }) => {
        param.defaultValue = checked;
      },
      [param],
    );

    const handleChangeStringArray = React.useCallback(
      (tags?: { label: string; value: string }[]) => {
        console.log('handleChangeStringArray', tags);
        runInAction(() => {
          param.defaultValue = (tags ?? []).map((t) => t.label);
        });
      },
      [param],
    );

    const handleChangeBooleanArray = React.useCallback(
      (tags?: { label: string; value: string }[]) => {
        runInAction(() => {
          param.defaultValue = (tags ?? []).map((t) => (t.label === 'false' || !t.label ? false : true));
        });
      },
      [param],
    );

    const handleChangeNumberArray = React.useCallback(
      (tags?: { label: string; value: string }[]) => {
        runInAction(() => {
          param.defaultValue = (tags ?? []).map((t) => Number(t.label));
        });
      },
      [param],
    );

    switch (param.type) {
      case 'number':
        return <InputNumber value={param.defaultValue ?? 0} onChange={handleChangeNumber} />;
      case 'boolean':
        return <Checkbox onChange={handleChangeBoolean} checked={param.defaultValue} />;
      case 'stringArray':
        return (
          <CreatableSelect
            isClearable
            isMulti
            onChange={handleChangeStringArray as any}
            isValidNewOption={() => true}
            value={(param.defaultValue ?? []).map((label) => ({ label, value: UUID() }))}
          />
        );
      case 'numberArray':
        return (
          <CreatableSelect
            isClearable
            isMulti
            onChange={handleChangeNumberArray as any}
            isValidNewOption={() => true}
            value={(param.defaultValue ?? []).map((label) => ({ label: `${label}`, value: UUID() }))}
          />
        );
      case 'booleanArray':
        return (
          <CreatableSelect
            isClearable
            isMulti
            onChange={handleChangeBooleanArray as any}
            isValidNewOption={() => true}
            value={(param.defaultValue ?? []).map((label) => ({ label: label ? 'true' : 'false', value: UUID() }))}
          />
        );
      default:
        return <Input value={param.defaultValue ?? ''} onChange={handleChangeString} />;
    }
  },
);
