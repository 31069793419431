/**
 *
 * Created by neo on 04.03.17.
 */
import { toJS, observable, action, computed } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Location } from './Location';
import { Address } from '../Address';
import { PageResult } from '../PageResult';
import { Media } from '../Media/Media';
import { GymBranchInfo } from './GymBranchInfo';

export class Branch {
  @observable
  id: string = '';
  @observable
  gymId: string = '';
  @observable
  name: string = '';
  @observable
  address: Address = new Address();
  @observable
  location: Location = new Location();
  @observable
  branchInfo: GymBranchInfo = new GymBranchInfo();
  @observable
  medias: Array<Media> = [];

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.gymId = json.gymId;
      this.name = json.name;
      this.location = new Location(json.location || {});
      this.address = new Address(json.address || {});
      this.branchInfo = new GymBranchInfo(json.branchInfo || {});
      this.medias = (json.medias || []).map((m) => new Media(m));
    }
  }

  @computed
  get _isNew(): boolean {
    return !this.id || !this.id.length;
  }

  @action
  async save() {
    const res = await HttpBackend.post('/gym/infrastructure/branch', toJS(this));
    if (res) {
      this.id = res.id;
    }
    return res;
  }

  static get(branchId: string): Promise<Branch | undefined> {
    return HttpBackend.get(`/gym/infrastructure/branch/${branchId}`).then((res) => res ? new Branch(res) : undefined);
  }

  static async find(params: any): Promise<PageResult<Branch>> {
    const res = await HttpBackend.get('/gym/infrastructure/branch', params);
    if (res) {
      res.content = res.content.map((b) => new Branch(b));
      return new PageResult(res);
    }
    return new PageResult();
  }

  static listByGym(gymId: String): Promise<Branch[]> {
    return HttpBackend.get(`/gym/${gymId}/branches`).then((res) => (res ?? []).map((r) => new Branch(r)));
  }

  async remove() {
    return HttpBackend.delete(`/gym/${this.gymId}/branch/${this.id}`);
  }
}
