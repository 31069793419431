import { Stage } from './Stage';
import { LocalizedValue } from '../../LocalizedValue';

export interface TrackingKeyConfig {
  key: string;
  minValue?: number | string;
}

export interface ExerciseBlockStageConfig {
  description?: LocalizedValue[];
  trackingKeys?: Array<TrackingKeyConfig>;
  startSets?: any; // default 1 or max if not set (can be higher than maxSets -> decrements)
}

export class ExerciseBlockStage extends Stage<ExerciseBlockStageConfig> {
  process() {
    console.log('noop');
  }

  get type(): string {
    return 'exerciseBlock';
  }
}
