/**
 * Created by neo on 13.04.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import styled from '@emotion/styled';
import { runInAction } from 'mobx';
import { SingleColRow } from '../../../../../../Components/SingleColRow';
import { Button, Popconfirm, Select, Space } from 'antd';
import { MindfulnessProgramTemplate } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplate';
import { MindfulnessProgramTemplatePhase } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplatePhase';
import { MindfulnessProgramTemplateDay } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDay';
import { PhaseEditModal } from './PhaseEditModal';
import { PhaseDayEditor } from './PhaseDayEditor';

export type MindfulnessProgramTemplatePhaseEditorProps = {
  template: MindfulnessProgramTemplate;
  phase: MindfulnessProgramTemplatePhase;
  index: number;
  headerColor?: string;
  onRemove?: (mesoCycle: MindfulnessProgramTemplatePhase) => any;
};

const Container = styled.div`
  min-width: 400px;
  width: 49%;
  margin: 4px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
`;

const Row = styled.div`
  display: flex;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.color || '#8fd14f'};
  padding: 4px;
  padding-top: 16px;
`;

const numbers = new Array(16).fill(0);

export const MindfulnessProgramTemplatePhaseEditor: React.FC<MindfulnessProgramTemplatePhaseEditorProps> = observer(
  ({ template, phase, headerColor, onRemove, index }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(phase);
    }, [phase, onRemove]);

    const handleChangeCycles = React.useCallback(
      (value) => {
        phase.cycles = Number(value);
      },
      [phase],
    );

    const handleEdit = React.useCallback(() => {
      setModalOpen(true);
    }, []);

    const handleAddDay = React.useCallback(() => {
      runInAction(() => phase.days.push(new MindfulnessProgramTemplateDay()));
    }, [phase]);

    const handleCopy = React.useCallback(() => {
      const copy = phase.copy();
      copy.name.forEach((name) => (name.value = `${name.value} Copy`));
      runInAction(() => template.phases.splice(index + 1, 0, copy));
    }, [index, phase, template.phases]);

    const handleCloseModal = React.useCallback(() => {
      setModalOpen(false);
    }, []);

    const handleRemoveDay = React.useCallback(
      (workoutDay: MindfulnessProgramTemplateDay, index: number) => {
        runInAction(() => phase.days.splice(index, 1));
      },
      [phase],
    );

    return (
      <Container>
        <Header color={headerColor}>
          <SingleColRow>
            <h5 style={{ textAlign: 'center' }}>{`Cycle ${index + 1}`}</h5>
            <h6 style={{ textAlign: 'center' }}>{`${phase.defaultName} (${phase.type})`}</h6>
          </SingleColRow>
          <Row>
            <div style={{ display: 'flex', flex: 1, paddingLeft: 4, paddingRight: 4 }}>
              <Select style={{ flex: 1 }} value={phase.cycles.toString()} onChange={handleChangeCycles}>
                {numbers.map((n, index) => (
                  <Select.Option key={index.toString()} value={`${index + 1}`}>{`${index + 1} Weeks`}</Select.Option>
                ))}
              </Select>
            </div>
            <div style={{ display: 'flex', flex: 0 }}>
              <Space>
                <Button type="link" onClick={handleEdit}>
                  Edit
                </Button>
                <Button type="link" onClick={handleAddDay}>
                  Add Day
                </Button>
                <Button type="link" onClick={handleCopy}>
                  Copy
                </Button>
                <Popconfirm title={`Remove Phase ${phase.defaultName}?`} onConfirm={handleRemove}>
                  <Button type="link" danger>
                    Remove
                  </Button>
                </Popconfirm>
              </Space>
            </div>
          </Row>
        </Header>
        <Body>
          {phase.days.map((workoutDay, workoutDayIndex) => (
            <PhaseDayEditor
              key={workoutDay.id}
              template={template}
              workoutDay={workoutDay}
              index={workoutDayIndex}
              onRemove={handleRemoveDay}
            />
          ))}
        </Body>
        {modalOpen ? <PhaseEditModal onClose={handleCloseModal} phase={phase} index={index} /> : null}
      </Container>
    );
  },
);
