/**
 * Created by andreaskarantzas on 06.05.21.
 */
import { observer } from 'mobx-react';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { Gym } from '../../../../../Model/Gym/Gym';
import { useState } from 'react';
import { Button } from 'antd';
import copy from 'copy-to-clipboard';

async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export type GymInfoToCopyProps = {
  gym: Gym;
  isNew?: boolean;
};

export const GymInfoToCopy: React.FC<GymInfoToCopyProps> = observer(({ isNew, gym }) => {
  const [copiedId, setCopiedId] = useState<boolean>(false);

  const handleCopyId = React.useCallback(() => {
    copy(`${gym.id}`);
    setCopiedId(true);
    setTimeout(() => {
      setCopiedId(false);
    }, 3000);
  }, [gym]);

  return (
    <Row>
      <Col>{gym.id}</Col>
      <Col xs="auto">
        <Button type="primary" shape="round" size="small" onClick={handleCopyId}>
          {copiedId ? 'Copied!' : 'Copy to clipboard'}
        </Button>
      </Col>
    </Row>
  );
});

const styles = {
  container: {
    paddingBottom: 16,
    paddingLeft: 0,
  },
};
