/**
 * Created by neo on 30.09.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Badge } from 'reactstrap';
import { ExerciseVariationAdmin } from '../../../../Model/Exercise/Admin/ExerciseVariationAdmin';

export type ExerciseBodyPartBadgesProps = {
  exercise: ExerciseVariationAdmin;
};

export const ExerciseBodyPartBadges: React.FC<ExerciseBodyPartBadgesProps> = observer(({ exercise }) => {
  return (
    <React.Fragment>
      {(exercise.bodyParts || []).map((e) => (
        <React.Fragment key={e.id}>
          <Badge color="warning">{e.defaultName}</Badge>
          <br />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
});
