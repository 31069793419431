/**
 * Created by neo on 13.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ProgramParams } from '../../../../Model/Coach/Program/ProgramParams';
import { Col, Row } from 'reactstrap';
import { ProgramParamsTagsInput } from './ProgramParamsTagsInput';
import { EquipmentTypeInput } from '../../../../Components/EquipmentTypeInput';
import { Checkbox, Form } from 'antd';
import { ProgramTemplateProgramParams } from '../../../../Model/Coach/Program/Template/ProgramTemplateProgramParams';
import { runInAction } from 'mobx';

export type ProgramParamsEditorProps = {
  params: ProgramParams;
  onChange?: (params: ProgramParams) => any;
};

export const ProgramParamsEditor: React.FC<ProgramParamsEditorProps> = observer(({ params, onChange }) => {
  const handleEquipmentChange = React.useCallback(() => {
    onChange && onChange(params);
  }, [params, onChange]);

  const handleChangeEquipmentLocked = React.useCallback(
    ({ target: { checked } }) => {
      if (params instanceof ProgramTemplateProgramParams) {
        runInAction(() => (params.equipmentLocked = checked));
      }
    },
    [params],
  );

  return (
    <Row>
      <Col>
        <Form.Item label="Tags" extra="Used for workout generation">
          <ProgramParamsTagsInput params={params} />
        </Form.Item>
      </Col>
      <Col>
        <EquipmentTypeInput
          label="Equipment"
          extra="Used for workout generation"
          container={params.equipmentConfiguration.equipmentTypes}
          onChange={handleEquipmentChange}
        />
      </Col>
      <Col>
        <EquipmentTypeInput
          label="Excluded Equipment"
          extra="Used for workout generation"
          container={params.equipmentConfiguration.excludedEquipmentTypes}
          onChange={handleEquipmentChange}
        />
      </Col>
      {params instanceof ProgramTemplateProgramParams && (
        <Col xs="auto">
          <Form.Item extra="User cannot change equipment">
            <Checkbox checked={params.equipmentLocked} onChange={handleChangeEquipmentLocked}>
              Equipment Locked?
            </Checkbox>
          </Form.Item>
        </Col>
      )}
    </Row>
  );
});
