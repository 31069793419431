/**
 * Created by neo on 19.04.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Button, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Pager } from '../../../../Components/Pager';
import { EquipmentTypeTable } from './EquipmentTypeTable';
import { PageResult } from '../../../../Model/PageResult';
import { EquipmentType } from '../../../../Model/Equipment/EquipmentType';

export type EquipmentTypeListScreenProps = {};

export const EquipmentTypeListScreen: React.FC<EquipmentTypeListScreenProps> = observer((props) => {
  const [result, setResult] = useState(new PageResult<EquipmentType>());
  const [coachEquipmentTypeIds, setCoachEquipmentTypeIds] = useState<string[]>([]);
  const [query, setQuery] = useState('');
  const page = React.useRef(0);
  const debounce = React.useRef<ReturnType<typeof setTimeout>>();

  const history = useNavigate();

  const load = React.useRef((query: string, page: number) => {
    const size = 20;
    PageResult.execute(EquipmentType.find({ query, page }), EquipmentType.count({ query }), page, size).then((result) =>
      setResult(result),
    );
  }).current;

  useEffect(() => {
    load('', 0);
  }, []);

  useEffect(() => {
    EquipmentType.findCoachSubset().then((result) => setCoachEquipmentTypeIds(result));
  }, []);

  const handleDelete = React.useCallback(
    (type: EquipmentType) => {
      if (window.confirm(`CAREFUL: Delete ${type.defaultName || type.id}?`)) {
        if (window.confirm(`Are you really sure? Delete ${type.defaultName || type.id}?`)) {
          type.delete().then(() => load(query, page.current));
        }
      }
    },
    [load, query],
  );

  const handlePage = React.useCallback(
    (newPage: number): void => {
      if (page.current !== newPage) {
        page.current = newPage;
        load(query, page.current);
      }
    },
    [load, query],
  );

  const handleQueryChange = React.useCallback(
    ({ target: { value } }: any) => {
      setQuery(value);
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => {
        page.current = 0;
        load(value, page.current);
      });
    },
    [load],
  );

  return (
    <Container>
      <Row>
        <Col>
          <h1>{'Equipment Types'}</h1>
        </Col>
        <Col xs="auto">
          <Button onClick={() => history('create')} type="primary">
            {'Create Type'}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input type="text" onChange={handleQueryChange} value={query} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Pager page={result} onPage={handlePage} />
        </Col>
      </Row>
      <Row>
        <Col>
          <EquipmentTypeTable
            types={result.content}
            coachEquipmentTypeIds={coachEquipmentTypeIds}
            onDelete={handleDelete}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Pager page={result} onPage={handlePage} />
        </Col>
      </Row>
    </Container>
  );
});
