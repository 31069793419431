/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { DiagramEngine, DefaultPortLabel } from '@projectstorm/react-diagrams';
import styled from '@emotion/styled';
import { DefaultStageNodeModel } from './DefaultStageNodeModel';
import { NodeColors } from '../../NodeColors';
import { NodeWidgetHeaderTitle } from './NodeWidgetHeaderTitle';
import { NodeWidgetHeaderLink } from './NodeWidgetHeaderLink';

export type StageNodeWidgetProps = {
  node: DefaultStageNodeModel;
  engine: DiagramEngine;
  size?: number;
  disableEdit?: boolean;
  title?: string;
  content?: React.ReactNode;
};

const StyledNode = styled.div<{ background: string; selected: boolean }>`
  background-color: ${(p) => p.background};
  border-radius: 5px;
  font-family: sans-serif;
  color: white;
  border: solid 2px black;
  overflow: visible;
  font-size: 11px;
  border: solid 2px ${(p) => (p.selected ? 'rgb(0,192,255)' : 'black')};
`;

const StyledTitle = styled.div`
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  white-space: nowrap;
  justify-items: center;
`;

const StyledPorts = styled.div`
  display: flex;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
`;

const StyledPortsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  &:first-of-type {
    margin-right: 10px;
  }
  &:only-child {
    margin-right: 0px;
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledRow = styled.div`
  display: flex;
  flex: 1;
  padding: 2px;
`;

export const StyledCol = styled.div`
  display: flex;
  flex: ${(props: { auto?: boolean }) => (props.auto ? '0' : '1')};
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DefaultStageNodeWidget: React.FC<StageNodeWidgetProps> = observer(
  ({ title, node, engine, content, disableEdit }: StageNodeWidgetProps) => {
    const generatePort = (port) => {
      return <DefaultPortLabel engine={engine} port={port} key={port.getID()} />;
    };

    const handleEdit = React.useCallback(
      (e) => {
        e.preventDefault();
        node.fireEvent({}, 'editClicked');
      },
      [node],
    );

    const handleExecute = React.useCallback(
      (e) => {
        e.preventDefault();
        node.fireEvent({}, 'executeClicked');
      },
      [node],
    );

    return (
      <StyledNode
        data-default-node-name={node.stage.type}
        selected={node.isSelected()}
        background={NodeColors[node.stage.type] ?? NodeColors.default}
      >
        <StyledTitle>
          <NodeWidgetHeaderTitle title={title ?? node.stage.type} />
          {disableEdit ? null : <NodeWidgetHeaderLink onClick={handleEdit} label="Edit" />}
          <NodeWidgetHeaderLink onClick={handleExecute} label="Execute" />
        </StyledTitle>
        <StyledPorts>
          <StyledPortsContainer>{node.getInPorts().map((p) => generatePort(p))}</StyledPortsContainer>
          <StyledPortsContainer>{node.getOutPorts().map((p) => generatePort(p))}</StyledPortsContainer>
        </StyledPorts>
        {content ? <StyledContent>{content}</StyledContent> : null}
      </StyledNode>
    );
  },
);
