/**
 * Created by neo on 15.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';

function time(value?: string) {
  return value ? timeFormat(Number(value)) : '';
}

function timeFormat(value?: number) {
  return value && value >= 1 ? dayjs.utc(value * 1000).format('HH:mm:ss') : '';
}

function delta(value0?: string, value1?: string): number | undefined {
  if (value0 || value1) {
    const dec0 = value0 !== null && value0 !== undefined ? Number(value0) : 0;
    const dec1 = value1 !== null && value1 !== undefined ? Number(value1) : 0;
    return dec0 - dec1;
  }
  return undefined;
}

export type DurationColumnProps = {
  field: string;
  row: AnalyticsRow;
  previousRow?: AnalyticsRow;
};

export const DurationColumn: React.FC<DurationColumnProps> = observer(({ field, row, previousRow }) => {
  const value = row[field];
  const previousValue = previousRow ? previousRow[field] : undefined;

  const diff = delta(value, previousValue);
  const deltaFormat = diff ? timeFormat(Math.abs(diff)) : undefined;

  const engagementTime = row.avg_engagement_seconds ? Number(row.avg_engagement_seconds) : 0;

  return (
    <td style={engagementTime < 10 ? { backgroundColor: '#dca1a1' } : undefined}>
      {time(value)}{' '}
      {deltaFormat && diff ? (
        <React.Fragment>
          <br />
          <span style={{ color: diff > 0 ? 'green' : 'red' }}>
            {value > (previousValue ?? 0) ? '+' : '-'}
            {deltaFormat}
          </span>
        </React.Fragment>
      ) : null}
    </td>
  );
});
