/**
 * Created by neo on 28.03.2025
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RegisteredUserPerGymAggregation } from './RegisteredUserPerGymAggregation';
import dayjs from 'dayjs';

export type CompanyDashboardScreenRowColumnProps = {
  company: RegisteredUserPerGymAggregation;
  date: Date;
};

// existing helper for delta/percentage change colors
const getColor = (value: number): string => {
  if (value >= 5) return 'green';
  if (value <= -5) return 'red';
  return 'orange';
};

// Helper to determine onboarding color based on company size thresholds
const getOnboardingColor = (rate: number, total: number): string => {
  if (total < 50) {
    if (rate > 70) return 'green';
    if (rate >= 50 && rate <= 70) return 'orange';
    return 'red';
  } else if (total >= 50 && total <= 250) {
    if (rate > 60) return 'green';
    if (rate >= 40 && rate <= 60) return 'orange';
    return 'red';
  } else if (total >= 251 && total <= 1000) {
    if (rate > 50) return 'green';
    if (rate >= 30 && rate <= 50) return 'orange';
    return 'red';
  } else if (total >= 1001 && total <= 5000) {
    if (rate > 40) return 'green';
    if (rate >= 20 && rate <= 40) return 'orange';
    return 'red';
  } else {
    if (rate > 25) return 'green';
    if (rate >= 10 && rate <= 25) return 'orange';
    return 'red';
  }
};

const getOnboardingStatus = (rate: number, total: number): string => {
  if (total < 50) {
    if (rate > 70) return 'Good';
    if (rate >= 50 && rate <= 70) return 'Medium';
    return 'Bad';
  } else if (total >= 50 && total <= 250) {
    if (rate > 60) return 'Good';
    if (rate >= 40 && rate <= 60) return 'Medium';
    return 'Bad';
  } else if (total >= 251 && total <= 1000) {
    if (rate > 50) return 'Good';
    if (rate >= 30 && rate <= 50) return 'Medium';
    return 'Bad';
  } else if (total >= 1001 && total <= 5000) {
    if (rate > 40) return 'Good';
    if (rate >= 20 && rate <= 40) return 'Medium';
    return 'Bad';
  } else {
    if (rate > 25) return 'Good';
    if (rate >= 10 && rate <= 25) return 'Medium';
    return 'Bad';
  }
};

export const CompanyDashboardScreenRowColumn: React.FC<CompanyDashboardScreenRowColumnProps> = observer(
  ({ company, date }) => {
    const entry = company.entries.find((entry) => entry.date.toISOString() === date.toISOString());
    const isBefore = entry ? dayjs(entry.date).isBefore(dayjs(company.gym.createdAt).startOf('month')) : false;

    if (entry && !isBefore) {
      return (
        <td key={`${company.id}-${entry.date.toISOString()}`}>
          <div>
            <strong>Users: {entry.cumulative}</strong>
          </div>
          <div>
            Delta:&nbsp;
            <span style={{ color: getColor(entry.deltaUsers) }}>
              {entry.deltaUsers > 0 ? '+' : ''}
              {entry.deltaUsers}
            </span>
            , Change:&nbsp;
            <span style={{ color: getColor(entry.percentageChange) }}>
              {entry.percentageChange > 0 ? '+' : ''}
              {entry.percentageChange.toFixed(2)}%
            </span>
          </div>
          {/*<div style={{ marginTop: 4 }}>*/}
          {/*  <strong>Onboarding Rate: {entry.onboardingRate}%</strong>*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  Status:&nbsp;*/}
          {/*  <span style={{ color: getOnboardingColor(entry.onboardingRate, totalPotentialUsers) }}>*/}
          {/*    {getOnboardingStatus(entry.onboardingRate, totalPotentialUsers)}*/}
          {/*  </span>*/}
          {/*</div>*/}
          <div style={{ marginTop: 4 }}>
            <strong>Activities: {entry.activitiesLogged}</strong>
          </div>
          <div>
            Change:&nbsp;
            <span style={{ color: getColor(entry.percentageChangeActivities) }}>
              {entry.percentageChangeActivities > 0 ? '+' : ''}
              {entry.percentageChangeActivities.toFixed(2)}%
            </span>
          </div>
          <div style={{ marginTop: 4 }}>
            <strong>Users Logged Activities: {entry.usersLoggedActivities}</strong>
          </div>
          <div>
            Change:&nbsp;
            <span style={{ color: getColor(entry.percentageChangeActivitiesUsers) }}>
              {entry.percentageChangeActivitiesUsers > 0 ? '+' : ''}
              {entry.percentageChangeActivitiesUsers.toFixed(2)}%
            </span>
          </div>
        </td>
      );
    }
    return <td/>;
  },
);
