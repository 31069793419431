import * as React from 'react';
import { AppHeader } from './Header';
import { Footer } from './Footer';
import { Outlet } from 'react-router-dom';
import { Container } from 'reactstrap';
import { SingleColRow } from '../../Components/SingleColRow';
import { observer } from 'mobx-react';

export type MainLayoutComponentProps = {};

export const MainLayout: React.FC<MainLayoutComponentProps> = observer(() => {
  return (
    <React.Fragment>
      <AppHeader />
      <Container id="main-container" fluid style={{ paddingBottom: 60 }}>
        <SingleColRow>
          <Outlet />
        </SingleColRow>
      </Container>
      <Footer />
    </React.Fragment>
  );
});
