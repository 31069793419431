/**
 * Created by neo on 17.11.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { RouteChallengeLocation } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeLocation';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { Form, InputNumber, message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { runInAction } from 'mobx';
import { RcFile } from 'antd/es/upload';
import MediaService from '../../../../../Services/MediaService';
import { Col, Row } from 'reactstrap';
import { TranslationInputArray } from '../../../../../Components/Translation/TranslationInputArray';
import { RouteChallengeLocationModalMedia } from './RouteChallengeLocationModalMedia';
import { Media } from '../../../../../Model/Media/Media';

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export type RouteChallengeLocationEntryProps = {
  challenge: RouteChallenge;
  location: RouteChallengeLocation;
  selected?: boolean;
};

export const RouteChallengeLocationEntry: React.FC<RouteChallengeLocationEntryProps> = observer(
  ({ challenge, location, selected }) => {
    const index = challenge.locations.findIndex((l) => l.id === location.id);
    const number = index + 1;

    const isFirst = index === 0;
    const isLast = index === challenge.locations.length - 1;

    const [uploading, setUploading] = useState(false);

    const beforeUpload = (file: RcFile) => {
      if (!file.type.toLowerCase().startsWith('image/')) {
        message.error('You can only upload JPG/PNG file!');
      } else if (file.size / 1024 / 1024 > 20) {
        message.error('Image must smaller than 20MB!');
      } else {
        setUploading(true);
        MediaService.uploadMedia(file)
          .then((media) => {
            runInAction(() => (location.image = media));
          })
          .catch(() => message.error('Failed to upload image'))
          .finally(() => setUploading(false));
      }

      return false;
    };

    const handleImageUploaded = React.useCallback(
      (medias: Media[]) => {
        runInAction(() => (location.medias = location.medias.concat(medias)));
      },
      [location],
    );

    const handleRemoveImage = React.useCallback(
      (media: Media) => {
        runInAction(() => (location.medias = location.medias.filter((v) => v.id !== media.id)));
      },
      [location],
    );

    const uploadButton = (
      <div>
        {uploading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    return (
      <Form layout="vertical">
        <Row>
          <Col md={12}>
            <Form.Item label="Name">
              <TranslationInputArray entity={location} field="name" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Description">
              <TranslationInputArray markdown={true} type="textarea" entity={location} field="description" />
            </Form.Item>
          </Col>
          {!isFirst && (
            <Col md={6}>
              <Form.Item label="Points Required">
                <InputNumber
                  value={location.pointsRequired}
                  min={1}
                  step={1}
                  onChange={(value) => runInAction(() => (location.pointsRequired = Number(value)))}
                />
              </Form.Item>
            </Col>
          )}
          <Col md={6}>
            <Form.Item label="Image">
              <Upload
                key="avatar"
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                style={{ width: '100%' }}
              >
                {location.image?.medium ? (
                  <img
                    src={location.image?.medium}
                    alt="avatar"
                    style={{ width: '100%', height: 100, objectFit: 'contain' }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Images">
              <div style={{ display: 'flex' }}>
                {location.medias.concat([undefined] as any).map((media) => (
                  <div key={media?.id} style={{ marginLeft: 8 }}>
                    <RouteChallengeLocationModalMedia
                      onRemove={handleRemoveImage}
                      onUploaded={handleImageUploaded}
                      // onClick={handleClickMedia}
                      media={media}
                    />
                  </div>
                ))}
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  },
);
