/**
 * Created by neo on 28.05.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

export type PhaseStageTypeSelectProps = {
  config: {
    type: string;
  };
};

const StyledSelect = styled.select`
  flex: 1;
  color: black;
  margin-right: 4px;
  border: 1px solid grey;
  border-radius: 4px;
`;

export const PhaseStageTypeSelect: React.FC<PhaseStageTypeSelectProps> = observer(({ config }) => {
  const handleChange = React.useCallback(
    ({ target: { value } }) => {
      config.type = value ?? 'strength';
    },
    [config],
  );

  return (
    <StyledSelect value={config.type} onChange={handleChange}>
      <option value="strength">Strength</option>
      <option value="cardio">Cardio</option>
      <option value="warmup">Warmup</option>
      <option value="mobilization">Mobilization</option>
      <option value="balance">Balance</option>
      <option value="interval">Interval</option>
      <option value="stretching">Stretching</option>
      <option value="relax">Relax</option>
      <option value="cooldown">Cooldown</option>
      <option value="fascia">Fascia</option>
      <option value="yoga">Yoga</option>
    </StyledSelect>
  );
});
