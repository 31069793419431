/**
 * Created by katarinababic on 3.5.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ExploreEntry } from '../../../../../Model/Explore/ExploreEntry';
import dayjs from 'dayjs';
import { AnalyticsData, AnalyticsRow } from '../../../../../Model/Analytics/AnalyticsData';
import { querySessionFinished } from './Queries/querySessionFinished';
import { ExploreEntryModalAnalyticsFinishedSessionsChart } from './ExploreEntryModalAnalyticsFinishedSessionsChart';
import { Col, Row, Spinner } from 'reactstrap';
import { DatePicker, Form } from 'antd';
import { SingleColRow } from '../../../../../Components/SingleColRow';

export type ExploreEntryModalAnalyticsFinishedSessionsProps = {
  entry: ExploreEntry;
  eventName: string;
};

export const ExploreEntryModalAnalyticsFinishedSessions: React.FC<ExploreEntryModalAnalyticsFinishedSessionsProps> =
  observer(({ entry, eventName }) => {
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
    const [endDate, setEndDate] = useState(dayjs());
    const [data, setData] = useState<AnalyticsRow[] | undefined>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(true);
      AnalyticsData.query({
        query: querySessionFinished,
        parameters: Object.assign({
          event_name: {
            value: eventName,
          },
          start_date: {
            value: startDate.format('YYYYMMDD'),
          },
          end_date: {
            value: endDate.format('YYYYMMDD'),
          },
          entry_id: {
            value: entry.id,
          },
        }) as any,
      })
        .then((res) => {
          setData(
            res.map((d) =>
              Array.from(Object.entries(d))
                .map(([key, value]) => [key, key === 'entry_id' ? value : Number(value)])
                .reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {} as any),
            ),
          );
        })
        .finally(() => setLoading(false));
    }, [startDate, endDate, entry]);

    const handleChangeValidFrom = React.useCallback(
      (date) => setStartDate(date ?? dayjs().subtract(1, 'month')),
      [],
    );

    const handleChangeValidUntil = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

    return (
      <React.Fragment>
        <SingleColRow>
          <h6>Finished sessions</h6>
        </SingleColRow>
        <Row>
          <Col xs={6}>
            <Form.Item label="Start Date">
              <DatePicker value={startDate} onChange={handleChangeValidFrom} />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item label="End Date">
              <DatePicker value={endDate} onChange={handleChangeValidUntil} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            {loading ? <Spinner /> : data ? <ExploreEntryModalAnalyticsFinishedSessionsChart data={data} /> : null}
          </Col>
        </Row>
      </React.Fragment>
    );
  });
