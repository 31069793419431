/**
 * Created by neo on 11.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExploreContent } from '../../../Model/Explore/ExploreContent';
import { ExploreCategoryRendererRoot } from './ExploreCategoryRendererRoot';
import { ExploreCategoryRenderer } from './ExploreCategoryRenderer';
import { ExploreCategory } from '../../../Model/Explore/ExploreCategory';
import styled from '@emotion/styled';
import { ExploreEntryCard } from './ExploreEntryCard/ExploreEntryCard';
import { ExploreEntry } from '../../../Model/Explore/ExploreEntry';
import { useNavigate } from 'react-router';
import { Gym } from '../../../Model/Gym/Gym';

const CategoryContainer = styled.div<{ index: number }>`
  background-color: ${({ index }) => (index % 2 === 0 ? '#e8e8e8' : 'white')};
  padding: 32px 16px;
  width: 100%;
  flex: 1 0 100%;
`;

export type ExploreContentRendererSwitchProps = {
  content: ExploreContent;
  index: number;
  gym?: Gym;
};

export const ExploreContentRendererSwitch: React.FC<ExploreContentRendererSwitchProps> = observer(
  ({ content, index, gym }) => {
    const history = useNavigate();

    const handleSelectEntry = React.useCallback(
      (entry) => {
        history(`${entry.id}/edit?gymId=${gym?.id ?? ''}`);
      },
      [history, gym],
    );

    if (content instanceof ExploreCategory) {
      return content.isRoot ? (
        <ExploreCategoryRendererRoot content={content} />
      ) : (
        <CategoryContainer index={content.type === 'category_group' || content.type === 'link_list' ? 1 : index}>
          <ExploreCategoryRenderer category={content} />
        </CategoryContainer>
      );
    } else if (content instanceof ExploreEntry) {
      return <ExploreEntryCard key={content.id} entry={content} onSelect={handleSelectEntry} />;
    }

    return null;
  },
);
