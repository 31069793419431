/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import styled from '@emotion/styled';
import { PhaseTemplateWidget } from './PhaseTemplateWidget';
import { PhaseTemplateDropZone } from './PhaseTemplateDropZone';
import { PhaseConfiguration } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/PhaseConfiguration';
import { Modal } from 'antd';
import { WorkoutPhaseTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutPhaseTemplate';
import { TranslationInputArray } from '../../../../../Components/Translation/TranslationInputArray';
import { WorkoutTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';

export type WorkoutTemplateContainerProps = {
  phaseConfiguration: PhaseConfiguration;
  workoutTemplate?: WorkoutTemplate;
};

export const StyledLayer = styled.div`
  min-height: 800px;
`;

export const WorkoutTemplateContainer: React.FC<WorkoutTemplateContainerProps> = observer(
  ({ phaseConfiguration, workoutTemplate }: WorkoutTemplateContainerProps) => {
    const [selectedPhase, setSelectedPhase] = useState<WorkoutPhaseTemplate | undefined>(undefined);
    const handleDrop = React.useCallback(
      (phaseTemplate, index) => {
        phaseConfiguration.addPhaseTemplate(phaseTemplate, index);
      },
      [phaseConfiguration],
    );

    const handleChangeIndex = React.useCallback(
      (phaseTemplateId, index) => {
        phaseConfiguration.changePhaseTemplateIndex(phaseTemplateId, index);
      },
      [phaseConfiguration],
    );

    const handleRemove = React.useCallback(
      (phase) => {
        phaseConfiguration.removePhaseTemplate(phase.id);
      },
      [phaseConfiguration],
    );

    const handleCopy = React.useCallback(
      (phase) => {
        const index = phaseConfiguration.phaseTemplates.findIndex((p) => p.id === phase.id);
        const newPhase = phase.copy();
        phaseConfiguration.addPhaseTemplate(newPhase, index + 1);
      },
      [phaseConfiguration],
    );

    const handleCloseModal = React.useCallback(() => {
      setSelectedPhase(undefined);
    }, []);

    const handleEdit = React.useCallback((phase) => setSelectedPhase(phase), []);

    return (
      <StyledLayer>
        <PhaseTemplateDropZone index={0} onDrop={handleDrop} onChangeIndex={handleChangeIndex} />
        {phaseConfiguration.phaseTemplates.map((phase, index) => (
          <React.Fragment key={phase.id}>
            <PhaseTemplateWidget
              phaseTemplate={phase}
              onRemove={handleRemove}
              onCopy={handleCopy}
              onEdit={handleEdit}
              phaseConfiguration={phaseConfiguration}
              workoutTemplate={workoutTemplate}
            />
            <PhaseTemplateDropZone index={index + 1} onDrop={handleDrop} onChangeIndex={handleChangeIndex} />
          </React.Fragment>
        ))}
        {selectedPhase ? (
          <Modal
            width={1920}
            style={{ top: 20 }}
            open={true}
            title={`Edit ${selectedPhase.pipeline?.name}`}
            onOk={handleCloseModal}
            onCancel={handleCloseModal}
          >
            <TranslationInputArray
              entity={selectedPhase}
              type="textarea"
              field="description"
              label="Description"
            />
          </Modal>
        ) : null}
      </StyledLayer>
    );
  },
);
