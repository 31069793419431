/**
 * Created by neo on 17.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Button, Input, Modal, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Recipe } from '../../../../Model/Diet/Recipe/Recipe';
import { RecipeEditModalBasicInfo } from './RecipeEditModalBasicInfo';
import { RecipeEditModalIngredients } from './RecipeEditModalIngredients';
import { RecipeEditModalInstructionsTab } from './RecipeEditModalInstructionsTab';
import { RecipeEditModalChatGptJsonTab } from './RecipeEditModalChatGptJsonTab';
import { RecipeEditModalNutrientsTab } from './RecipeEditModalNutrientsTab';
import { GptResponseService } from '../../../../Services/GptResponseService';

export type RecipeEditModalProps = {
  onSaved?: () => void;
  onArchived?: () => void;
};

export const RecipeEditModal: React.FC<RecipeEditModalProps> = observer(({ onSaved, onArchived }) => {
  const history = useNavigate();
  const { recipeId } = useParams<{ recipeId: string }>();
  const [processing, setProcessing] = useState(false);
  const [checkingGpt, setCheckingGpt] = useState(false);
  const [gptDebug, setGptDebug] = useState('');
  const [recipe, setRecipe] = useState(new Recipe());
  const [activeTab, setActiveTab] = useState('basic');
  const isNew = recipeId === 'new';

  useEffect(() => {
    if (recipeId && recipeId !== 'new') {
      setProcessing(true);
      Recipe.get(recipeId)
        .then((result) => setRecipe(result))
        .finally(() => setProcessing(false));
    } else {
      setRecipe(new Recipe());
    }
  }, [recipeId]);

  const handleSave = React.useCallback(() => {
    setProcessing(true);
    recipe
      .save()
      .then(() => setProcessing(false))
      .then(() => onSaved && onSaved());
  }, [recipe, onSaved]);

  const handleCancel = React.useCallback(() => {
    history(-1);
  }, [history]);

  const handleArchive = React.useCallback(() => {
    setProcessing(true);
    recipe
      .archive()
      .then(() => onArchived && onArchived())
      .then(() => history(-1))
      .finally(() => setProcessing(false));
  }, [history, recipe, onArchived]);

  const handleRestore = React.useCallback(() => {
    setProcessing(true);
    recipe.archived = false;
    recipe
      .save()
      .then(() => onSaved && onSaved())
      .then(() => history(-1))
      .finally(() => setProcessing(false));
  }, [history, onSaved, recipe]);

  const handleChangeTab = React.useCallback((tab: string) => setActiveTab(tab), []);

  const handleSetProcessing = React.useCallback(() => setProcessing(true), []);

  const handleUnsetProcessing = React.useCallback(() => setProcessing(false), []);

  const handleCheckRecipeGpt = React.useCallback(() => {
    setProcessing(true);
    setCheckingGpt(true);

    new GptResponseService([
      {
        role: 'system',
        content: `
You are a top-level chef and programmer. You will receive a recipe as a JSON object, which you must analyze carefully, read thoroughly, and fully understand.

Your task is to respond ONLY with the improved recipe in the exact same JSON format provided. 
Carefully enhance any parts of the recipe (such as description, ingredients, instructions) that you think could be improved or clarified.

Important rules you MUST follow:
- Respond exclusively with the complete JSON in the original format. NEVER omit or shorten any text, array elements, or fields. The use of "[...]" or "{...}" is NOT allowed.
- Keep the original "id" field exactly the same as in the input JSON. 
- Instructions and descriptive texts are provided as arrays of objects. 
- Only answer with an english translation the other languages can be ignored when responding.
- When analyzing the content for improvements, focus only on the English version (en).
- The recipes should be healthy but also tasty. If possible avoid unhealthy ingredients unless necessary.

Fields you can omit in the response (also in nested objects):
- createdAt
- createdBy
- updatedAt
- updatedBy
- image
- archived
- veryHealthy
- sustainable
      `,
      },
      {
        role: 'user',
        content: JSON.stringify(recipe.englishOnly().toJS()),
      },
    ])
      .generate((message) => {
        setGptDebug(message);
      })
      .then((res) => JSON.parse(res.replace('ß', 'ss')))
      .then((response) => {
        console.log('response', response);
        console.log('response::update', recipe.update(response).toJS());
        setRecipe(new Recipe(recipe.update(response).toJS()));
      })
      .finally(() => {
        setProcessing(false);
        setCheckingGpt(false);
      });
  }, [recipe]);

  return (
    <Modal
      open={true}
      title={isNew ? 'New Recipe' : recipe.defaultName}
      confirmLoading={processing}
      onCancel={handleCancel}
      destroyOnClose={false}
      footer={[
        <Button key="1" onClick={handleCancel} disabled={processing}>
          Close
        </Button>,
        <Button key="plauscheck" onClick={handleCheckRecipeGpt} disabled={processing}>
          Improve Recipe (GPT)
        </Button>,
        <Button key="2" danger onClick={recipe.archived ? handleRestore : handleArchive} disabled={processing}>
          {recipe.archived ? 'Restore' : 'Archive'}
        </Button>,
        <Button key="3" type="primary" onClick={handleSave} disabled={processing}>
          Save
        </Button>,
      ]}
      width={1280}
    >
      {checkingGpt ? (
        <Input.TextArea value={gptDebug} rows={20} />
      ) : (
        <Tabs defaultActiveKey="1" onChange={handleChangeTab}>
          <Tabs.TabPane tab="Basic" key="basic">
            <RecipeEditModalBasicInfo recipe={recipe} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Ingredients" key="ingredients">
            <RecipeEditModalIngredients recipe={recipe} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Instructions" key="instructions">
            <RecipeEditModalInstructionsTab recipe={recipe} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Nutrients" key="nutrients">
            <RecipeEditModalNutrientsTab recipe={recipe} />
          </Tabs.TabPane>
          {isNew && (
            <Tabs.TabPane tab="ChatGPT JSON" key="gpt-json">
              <RecipeEditModalChatGptJsonTab
                recipe={recipe}
                onBeforeProcessing={handleSetProcessing}
                onAfterProcessing={handleUnsetProcessing}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      )}
    </Modal>
  );
});
