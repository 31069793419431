/**
 * Created by neo on 10.05.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { CoachTts } from '../../../../Model/Coach/TextToSpeech/CoachTts';
import { Col, Row } from 'reactstrap';
import { Input } from 'antd';
import { runInAction } from 'mobx';

export type VariableTestInputProps = {
  tts: CoachTts;
  variable: string;
};

export const VariableTestInput: React.FC<VariableTestInputProps> = observer(({ tts, variable }) => {
  const handleChange = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (tts.testValues[variable] = value));
    },
    [tts, variable],
  );

  const testValue = tts.testValues[variable];

  return (
    <Row>
      <Col sm={3}>
        <Input disabled={true} value={variable} />
      </Col>
      <Col sm={3}>
        <Input value={testValue} onChange={handleChange} />
      </Col>
    </Row>
  );
});
