import React, { useEffect, useState, useCallback } from 'react';
import { Card, Tag, Typography, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import { SubscribedEventEntry } from '../../../../Model/Coach/Schedule/SubscribedEventEntry';

const { Meta } = Card;
const TitleText = styled(Typography.Title)`
  && {
    margin: 0;
    font-size: 20px;
  }
`;

export type ExploreEntryListEntryCardProps = {
  entry: ExploreEntry;
  gymId?: string;
  language: string;
  onClick?: (entry: ExploreEntry) => void;
};

export const ExploreEntryListEntryCard: React.FC<ExploreEntryListEntryCardProps> = observer(
  ({ entry, gymId, language, onClick }) => {
    const navigate = useNavigate();
    const [subscribedCount, setSubscribedCount] = useState(0);

    useEffect(() => {
      if (entry.type === 'onlineMeeting') {
        SubscribedEventEntry.count({ objectId: entry.id }).then((res) => setSubscribedCount(res));
      }
    }, [entry]);

    const handleSelect = useCallback(() => {
      if (onClick) {
        onClick(entry);
      } else {
        navigate(`/coach/explore/entries/${entry.id}?gymId=${entry.gymId || ''}`);
      }
    }, [entry, onClick, navigate]);

    const localizedName = entry.getName(language);
    // Shorten description to 100 characters
    const fullDescription = entry.getDescription(language);
    const localizedDescription =
      fullDescription && fullDescription.length > 100 ? fullDescription.slice(0, 100) + '...' : fullDescription;

    return (
      <Card
        hoverable
        onClick={handleSelect}
        cover={
          <div style={{ position: 'relative' }}>
            {entry.image?.medium ? (
              <img
                alt={localizedName}
                src={entry.image.medium}
                style={{ width: '100%', height: 200, objectFit: 'cover' }}
              />
            ) : (
              <div style={{ width: '100%', height: 200, backgroundColor: 'grey' }} />
            )}
            {entry.gym && entry.gym.name && (
              <div
                style={{
                  position: 'absolute',
                  top: 8,
                  left: 8,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  padding: '4px 8px',
                  borderRadius: 4,
                  fontWeight: 'bold',
                }}
              >
                {entry.gym.name}
              </div>
            )}
          </div>
        }
        style={{ borderRadius: 16, overflow: 'hidden', marginBottom: 16 }}
      >
        <Meta title={<TitleText>{localizedName}</TitleText>} description={localizedDescription} />
        <Row style={{ marginTop: 8 }}>
          <Col>
            <Tag color="blue">{entry.type}</Tag>
            <Tag color={entry.state === 'published' ? 'green' : entry.state === 'draft' ? 'orange' : 'red'}>
              {entry.state}
            </Tag>
            <Tag>Subscribed: {entry.subscriberCount || subscribedCount}</Tag>
          </Col>
        </Row>
      </Card>
    );
  },
);
