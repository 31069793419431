import * as React from 'react';
import { observer } from 'mobx-react';
import { Phase } from '../../../../../Model/ProgramPortfolio/Phase';
import { ExerciseBlock } from '../../../../../Model/ProgramPortfolio/ExerciseBlock';
import { ExerciseBlockCardContent } from './ExerciseBlockCardContent';
import { Card, Space } from 'antd';
import dayjs from 'dayjs';

export type PhaseTableProps = {
  phase: Phase;
  onAddExercise?: (phase: Phase) => void;
  onRemove?: (phase: Phase) => any;
};

export const PhaseTable: React.FC<PhaseTableProps> = observer(
  ({ phase, phase: { exerciseBlocks }, onAddExercise, onRemove }: PhaseTableProps) => {
    const handleAddExercise = React.useCallback(
      (e) => {
        e.preventDefault();
        onAddExercise && onAddExercise(phase);
      },
      [onAddExercise, phase],
    );

    const handleRemove = React.useCallback(
      (block: ExerciseBlock) => {
        phase.removeExerciseBlock(block);
      },
      [phase],
    );

    const handleRemovePhase = React.useCallback(
      (e) => {
        e.preventDefault();
        onRemove && onRemove(phase);
      },
      [onRemove, phase],
    );

    return (
      <Card
        title={`${phase.name || phase.type || '<no name>'} (${dayjs.utc(phase.minDuration).format('HH:mm:ss')} - ${dayjs
          .utc(phase.maxDuration)
          .format('HH:mm:ss')})`}
        extra={
          <Space>
            <a href="" onClick={handleAddExercise}>
              Add Exercise
            </a>
            {' | '}
            <a href="" onClick={handleRemovePhase}>
              Remove Phase
            </a>
          </Space>
        }
      >
        {exerciseBlocks.map((block, index) => (
          <ExerciseBlockCardContent
            key={block.exerciseBlockId}
            exerciseBlock={block}
            index={index}
            onRemove={handleRemove}
          />
        ))}
      </Card>
    );
  },
);
