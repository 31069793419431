/**
 * Created by neo on 01.02.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Form } from 'antd';
import { runInAction } from 'mobx';
import { BreathingTechniqueEntry } from './BreathingTechniqueEntry';
import { Col, Row } from 'reactstrap';
import { BreathingTechniqueStep } from '../../../../../../../Model/Explore/BreathingTechniqueStep';
import { MindfulnessProgramTemplateDayUnitBreathing } from '../../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDayUnitBreathing';
import { Media } from '../../../../../../../Model/Media/Media';
import { ConditionalMedia } from '../../../../../../../Model/Coach/SuperMacro/ConditionalMedia';
import { ViewMedia } from '../../../../../../../Components/ViewMedia/ViewMedia';

export type BreathingEntryContentProps = {
  entry: MindfulnessProgramTemplateDayUnitBreathing;
};

export const BreathingEntryContent: React.FC<BreathingEntryContentProps> = observer(({ entry }) => {
  const handleAddTechniqueStep = React.useCallback(
    () => runInAction(() => entry.technique.push(new BreathingTechniqueStep())),
    [entry],
  );

  const handleSaveMedia = React.useCallback((media: Media) => new ConditionalMedia(media.toJS()), []);

  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <Form.Item label="Background Images">
            <ViewMedia medias={entry.backgroundImages} disableCamera={true} onTransform={handleSaveMedia} />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <h5>Technique steps</h5>
          {entry.technique.map((step, index) => (
            <div key={index.toString()} style={{ marginBottom: 16 }}>
              <BreathingTechniqueEntry entry={entry} techniqueStep={step} index={index} />
            </div>
          ))}
          <Button block onClick={handleAddTechniqueStep}>
            Add technique step
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
});
