import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { Table } from 'reactstrap';
import dayjs from 'dayjs';
import { RegisteredUserPerGymAggregation } from './RegisteredUserPerGymAggregation';
import { CompanyDashboardScreenRow } from './CompanyDashboardScreenRow';

const tableContainerStyle = {
  position: 'relative',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
} as any;

const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
} as any;

const fixedColumnStyle = {
  position: 'sticky',
  left: 0,
  zIndex: 2,
  minWidth: 200,
} as any;

const fixedColumnHeaderStyle = {
  ...fixedColumnStyle,
  zIndex: 3,
};

const stickyHeaderStyle = {
  position: 'sticky',
  top: 0,
  background: '#fff',
  zIndex: 4,
} as any;

export type CompanyDashboardScreenProps = {};

export const CompanyDashboardScreen: React.FC<CompanyDashboardScreenProps> = observer((props) => {
  const [data, setData] = useState<RegisteredUserPerGymAggregation[]>([]);

  const allDates = useMemo(() => data.flatMap((item) => item.entries.map((entry) => entry.date)), [data]);

  const dates = useMemo(
    () =>
      [...new Set(allDates.map((item) => item.toISOString()))]
        .map((d) => new Date(d))
        .sort((a, b) => a.getTime() - b.getTime())
        .reverse(),
    [allDates],
  );

  useEffect(() => {
    RegisteredUserPerGymAggregation.find({ window: 'month' }).then((res) =>
      setData(res.sort((a, b) => a.gym.name.localeCompare(b.gym.name))),
    );
  }, []);

  return (
    <div style={tableContainerStyle}>
      <Table style={tableStyle} size="sm" hover striped>
        <thead>
          <tr style={stickyHeaderStyle}>
            <th style={{ ...fixedColumnHeaderStyle, ...stickyHeaderStyle }}>Company</th>
            <th style={stickyHeaderStyle}>Status</th>
            {dates.map((date) => (
              <th key={date.toISOString()} style={stickyHeaderStyle}>
                {dayjs(date).format('DD.MM.YYYY')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((company) => (
            <CompanyDashboardScreenRow key={company.id} company={company} dates={dates} />
          ))}
        </tbody>
      </Table>
    </div>
  );
});
