/**
 * Created by andreaskarantzas on 04.05.20.
 */

import React from 'react';
import { observer } from 'mobx-react';
import Grid from '@mui/material/Grid';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { NativeFeedSideDrawerComponents } from './NativeFeedSideDrawerComponents/NativeFeedSideDrawerComponents';
import { NativeFeedSideDrawerComponentStyles } from './NativeFeedSideDrawerComponentStyles/NativeFeedSideDrawerComponentStyles';
import FeedEntryViewStore from '../../FeedEntryViewStore';
import { SimpleButton } from '../../../Buttons/SimpleButton';
import { ArticleEntry } from '../../../../Model/Explore/ArticleEntry';
import { makeStyles } from 'tss-react/mui';
import { ThemeConfig } from '../../../../Config/Theme';
import { TranslationArrayInputMaterial } from '../../../Translation/TranslationArrayInputMaterial';
import Typography from '@mui/material/Typography';

export type NativeFeedSideDrawerProps = {
  entry: ArticleEntry;
  store: FeedEntryViewStore;
};

export const NativeFeedSideDrawer: React.FC<NativeFeedSideDrawerProps> = observer(({ entry, store }) => {
  const { classes } = useStyles();

  const handlePressAllItems = React.useCallback(() => {
    store.setDefaultSideDrawerMode();
  }, [store]);

  return (
    <Grid item className={classes.editorContainer} xs={2}>
      <Grid item id="nativeFeedButtons">
        {!store.showDefaultSideDrawer && (
          <SimpleButton
            icon={<ArrowBack color="primary" />}
            onClick={handlePressAllItems}
            style={classes.showAllButton}
          />
        )}
      </Grid>
      <Grid item xs={12} className={classes.previewContainer}>
        <React.Fragment>
          <Typography className={classes.heading}>Preview (optional)</Typography>
          <Typography className={classes.secondaryHeading}>
            The preview text is a short summary of this feed article
          </Typography>
          <TranslationArrayInputMaterial translations={entry.preview} />
        </React.Fragment>
      </Grid>
      {store.showDefaultSideDrawer ? <NativeFeedSideDrawerComponents /> : null}
      {store.selectedComponentToEdit && !store.showDefaultSideDrawer ? (
        <NativeFeedSideDrawerComponentStyles component={store.selectedComponentToEdit} />
      ) : null}
    </Grid>
  );
});

const useStyles = makeStyles()((theme) => ({
  editorContainer: {
    backgroundColor: ThemeConfig.Colors.notificationColor,
    padding: 16,
    minWidth: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '100%',
  },
  showAllButton: {
    backgroundColor: 'white',
    width: '17%',
    marginBottom: 16,
    marginRight: 4,
  },
  previewContainer: {
    backgroundColor: 'white',
    marginBottom: 16,
    borderRadius: 4,
    padding: 16,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
    flexBasis: '100%',
  },
}));
