/**
 * Created by neo on 12.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Input } from 'antd';
import { Row, Col } from 'reactstrap';
import { ObservableMap } from 'mobx';

export type MultiRowMapEntryProps = {
  container: Map<string, any> | Record<string, any>;
  valueKey: string;
};

export const MultiRowMapEntry: React.FC<MultiRowMapEntryProps> = observer(({ valueKey, container }) => {
  const value =
    container instanceof Map || container instanceof ObservableMap ? container.get(valueKey) : container[valueKey];

  const handleRemoveRow = React.useCallback(() => {
    if (container instanceof Map || container instanceof ObservableMap) {
      container.delete(valueKey);
    } else {
      delete container[valueKey];
    }
  }, [container, valueKey]);

  return (
    <Row style={{ paddingTop: 8, paddingBottom: 8 }}>
      <Col>
        <Input type="text" value={valueKey} disabled />
      </Col>
      <Col>
        <Input type="text" value={value} onChange={(e) => container.set(valueKey, e.target.value)} />
      </Col>
      <Col xs="auto">
        <Button danger onClick={handleRemoveRow}>
          Remove
        </Button>
      </Col>
    </Row>
  );
});
