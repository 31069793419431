/**
 * Created by neo on 20.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Form } from 'antd';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { ChartColors } from '../ChartColors';

export type SelectContentChartProps = {
  data: AnalyticsRow[];
  categories: string[];
};

export const SelectContentChart: React.FC<SelectContentChartProps> = observer(({ data, categories }) => {
  const [disabledFields, setDisabledFields] = useState<{ [key: string]: boolean }>({});

  const handleLegendClick = React.useCallback((data) => {
    const dataKey = typeof data.dataKey === 'string' ? data.dataKey : 'avg_activities_per_user';
    setDisabledFields((existing) => Object.assign({}, existing, { [dataKey]: !existing[dataKey] }));
  }, []);

  return (
    <Form.Item label="Select Content">
      <ResponsiveContainer width="100%" height={400}>
        <BarChart height={250} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="event_date" />
          <YAxis />
          <Tooltip />
          <Legend onClick={handleLegendClick} />
          {categories.map((category, index) => (
            <Bar
              key={category}
              type="monotone"
              dataKey={category}
              fill={ChartColors[index % ChartColors.length]}
              stackId="a"
              hide={disabledFields[category]}
              name={`# Unique users ${category}`}
            />
          ))}
          {categories.map((category, index) => (
            <Bar
              key={`${category}_select`}
              type="monotone"
              dataKey={`${category}_select`}
              fill={ChartColors[index % ChartColors.length]}
              stackId="b"
              hide={disabledFields[`${category}_select`]}
              name={`# selected ${category} `}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Form.Item>
  );
});
