/**
 * Created by neo on 27.11.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { RouteChallengeLocation } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeLocation';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { AdvancedMarker, Pin, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { runInAction } from 'mobx';
import { LocalizedValue } from '../../../../../Model/LocalizedValue';
import { decode } from '@googlemaps/polyline-codec';
import { calculateArcAndEncode } from './calculateArcAndEncode';

export type RouteChallengeMapEditorMapContentLocationProps = {
  challenge: RouteChallenge;
  location: RouteChallengeLocation;
  selectedLocation?: RouteChallengeLocation;
  onSelect?: (location: RouteChallengeLocation) => void;
};

export const RouteChallengeMapEditorMapContentLocation: React.FC<RouteChallengeMapEditorMapContentLocationProps> =
  observer(({ challenge, location, selectedLocation, onSelect }) => {
    const mapId = `challenge-map-${challenge.id}`;
    const map = useMap();
    const geocoderLibrary = useMapsLibrary('geocoding');
    const routesLibrary = useMapsLibrary('routes');
    const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService>();
    const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();

    const [geocoderService, setGeocoderService] = useState<google.maps.Geocoder | undefined>();

    const decodedCoordinates = useMemo(
      () =>
        location.googleMapsRouteToNextLocation
          ? decode(location.googleMapsRouteToNextLocation).map(
              (t) =>
                new google.maps.LatLng({
                  lat: t[0],
                  lng: t[1],
                }),
            )
          : undefined,
      [location.googleMapsRouteToNextLocation],
    );

    const flightPath = useMemo(
      () =>
        new google.maps.Polyline({
          path: [],
          geodesic: true,
          strokeColor: '#FF0000',
          strokeOpacity: 1.0,
          strokeWeight: 2,
        }),
      [],
    );

    const locationIndex = challenge.locations.findIndex((l) => l.id === location.id);
    const isFirstLocation = locationIndex === 0;
    const isLastLocation = locationIndex === challenge.locations.length - 1;
    const showPath =
      (!selectedLocation || selectedLocation.id === location.id) && decodedCoordinates && !isLastLocation;
    const firstPathCoordinate = decodedCoordinates?.[0];
    const lastPathCoordinates = decodedCoordinates?.[decodedCoordinates.length - 1];

    // Initialize directions service and renderer
    useEffect(() => {
      if (!routesLibrary || !map) return;
      setDirectionsService(new routesLibrary.DirectionsService());
      setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
    }, [routesLibrary, map]);

    // Use directions service
    useEffect(() => {
      if (!directionsService || !directionsRenderer) return;

      return () => directionsRenderer.setMap(null);
    }, [directionsService, directionsRenderer]);

    useEffect(() => {
      if (geocoderLibrary) {
        setGeocoderService(new geocoderLibrary.Geocoder());
      }
    }, [geocoderLibrary]);

    useEffect(() => {
      if (showPath) {
        flightPath.setPath(decodedCoordinates);
      } else {
        flightPath.setPath([]);
      }
    }, [decodedCoordinates, flightPath, showPath]);

    useEffect(() => {
      if (map && flightPath) {
        flightPath.setMap(map);
      }
    }, [map, flightPath]);

    const updateLocationName = React.useCallback(
      (location: RouteChallengeLocation, latLng: google.maps.LatLng) => {
        geocoderService
          ?.geocode({
            location: latLng,
          })
          .then((results) => {
            const locality = results.results.find((r) => r.types.includes('locality') || r.types.includes('political'));
            const newName = locality?.address_components.find((a) => a.types.includes('locality'))?.long_name;

            console.log('geocode', locality);
            if (locality && location.name.length <= 1 && newName) {
              runInAction(() => (location.name = [new LocalizedValue({ lang: 'en', value: newName })]));
            }
          });
      },
      [geocoderService],
    );

    const changeLocationPosition = React.useCallback(
      (location: RouteChallengeLocation, latLng: google.maps.LatLng) => {
        const locationIndex = challenge.locations.findIndex((l) => l.id === location.id);

        updateLocationName(location, latLng);

        if (locationIndex > 0) {
          const previousLocation = challenge.locations[locationIndex - 1];
          if (previousLocation.position.xorLat && previousLocation.position.yorLng) {
            console.log('calculate route to previous location', previousLocation);
            directionsService
              ?.route({
                origin: new google.maps.LatLng({
                  lat: previousLocation.position.xorLat,
                  lng: previousLocation.position.yorLng,
                }),
                destination: latLng,
                provideRouteAlternatives: true,
                travelMode: google.maps.TravelMode.WALKING,
              })
              .then((res) => {
                const route = res.routes[0];
                console.log('result destination', res);
                if (route) {
                  console.log('route to previous', res);
                  runInAction(() => (previousLocation.googleMapsRouteToNextLocation = route.overview_polyline));
                }
              })
              .catch((err) => {
                console.error('route error', err);
                runInAction(
                  () =>
                    (previousLocation.googleMapsRouteToNextLocation = calculateArcAndEncode(
                      previousLocation.position.xorLat,
                      previousLocation.position.yorLng,
                      latLng.lat(),
                      latLng.lng(),
                      100,
                    )),
                );
              });
          }
        }

        if (locationIndex < challenge.locations.length - 1) {
          const nextLocation = challenge.locations[locationIndex + 1];
          if (nextLocation.position.xorLat && nextLocation.position.yorLng) {
            console.log('calculate route to next location', nextLocation);
            directionsService
              ?.route({
                origin: latLng,
                destination: new google.maps.LatLng({
                  lat: nextLocation.position.xorLat,
                  lng: nextLocation.position.yorLng,
                }),
                provideRouteAlternatives: true,
                travelMode: google.maps.TravelMode.WALKING,
              })
              .then((res) => {
                console.log('route to next', res);
                const route = res.routes[0];
                if (route) {
                  runInAction(() => (location.googleMapsRouteToNextLocation = route.overview_polyline));
                }
              })
              .catch((err) => {
                console.error('route error', err);
                runInAction(
                  () =>
                    (location.googleMapsRouteToNextLocation = calculateArcAndEncode(
                      latLng.lat(),
                      latLng.lng(),
                      nextLocation.position.xorLat,
                      nextLocation.position.yorLng,
                      100,
                    )),
                );
              });
          }
        }

        runInAction(() => {
          location.position.xorLat = latLng.lat();
          location.position.yorLng = latLng.lng();
        });
      },
      [challenge, directionsService, updateLocationName],
    );

    return (
      <React.Fragment>
        {!selectedLocation && (
          <AdvancedMarker
            key={location.id}
            onClick={() => onSelect?.(location)}
            draggable={true}
            onDragEnd={({ latLng }) => latLng && changeLocationPosition(location, latLng)}
            position={{
              lng: location.position.yorLng,
              lat: location.position.xorLat,
            }}
          >
            <Pin background="#FBBC04" glyphColor={'#000'} borderColor={'#000'} />
          </AdvancedMarker>
        )}
        {selectedLocation?.id === location.id && (
          <React.Fragment>
            {firstPathCoordinate && (
              <AdvancedMarker
                key={location.id}
                draggable={true}
                onDragEnd={({ latLng }) => latLng && changeLocationPosition(location, latLng)}
                position={firstPathCoordinate}
                onClick={() => onSelect?.(location)}
              >
                <Pin background="green" glyphColor={'#000'} borderColor={'#000'} />
              </AdvancedMarker>
            )}
            {lastPathCoordinates && (
              <AdvancedMarker key={location.id} position={lastPathCoordinates}>
                <Pin background="red" glyphColor={'#000'} borderColor={'#000'} />
              </AdvancedMarker>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  });
