/**
 * Created by katarinababic on 29.7.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Row, Spinner, Table } from 'reactstrap';
import { ExploreEntry } from '../../../../../Model/Explore/ExploreEntry';
import dayjs from 'dayjs';
import { AnalyticsData, AnalyticsRow } from '../../../../../Model/Analytics/AnalyticsData';
import { queryVideoFullscreenDismissed } from './Queries/queryVideoFullscreenDismissed';
import { DatePicker, Form } from 'antd';

export type ExploreEntryModalAnalyticsVideoFullscreenDismissedProps = {
  entry: ExploreEntry;
};

export const ExploreEntryModalAnalyticsVideoFullscreenDismissed: React.FC<ExploreEntryModalAnalyticsVideoFullscreenDismissedProps> =
  observer(({ entry }) => {
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
    const [endDate, setEndDate] = useState(dayjs());
    const [data, setData] = useState<AnalyticsRow[] | undefined>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(true);
      AnalyticsData.query({
        query: queryVideoFullscreenDismissed,
        parameters: Object.assign({
          video_id: {
            value: entry.videos[0].id,
          },
          start_date: {
            value: startDate.format('YYYYMMDD'),
          },
          end_date: {
            value: endDate.format('YYYYMMDD'),
          },
        }) as any,
      })
        .then((res) => {
          setData(res);
        })
        .finally(() => setLoading(false));
    }, [startDate, endDate, entry]);

    const handleChangeValidFrom = React.useCallback(
      (date) => setStartDate(date ?? dayjs().subtract(1, 'month')),
      [],
    );

    const handleChangeValidUntil = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

    return (
      <React.Fragment>
        <Row>
          <Col xs={6}>
            <Form.Item label="Start Date">
              <DatePicker value={startDate} onChange={handleChangeValidFrom} />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item label="End Date">
              <DatePicker value={endDate} onChange={handleChangeValidUntil} />
            </Form.Item>
          </Col>
        </Row>
        {loading ? (
          <Spinner />
        ) : data && data.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>Video id</th>
                <th>Total count</th>
                <th>User count</th>
                <th>Answer index</th>
                <th>Answer count</th>
                <th>Answer count %</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <th scope="row">{index === 0 ? row.video_id : ''}</th>
                  <td>{index === 0 ? row.total_count : ''}</td>
                  <td>{index === 0 ? row.user_count : ''}</td>
                  <td>{row.answer_index}</td>
                  <td>{row.answer_count}</td>
                  <td>{row.answer_percentage}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No results for the selected time period</p>
        )}
      </React.Fragment>
    );
  });
