/**
 * Created by neo on 05.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { runInAction } from 'mobx';
import { Button, Popconfirm } from 'antd';
import { MindfulnessProgramTemplateDay } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDay';
import { MindfulnessProgramTemplate } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplate';
import { UnitEntryEditor } from './UnitEntryEditor';
import { MindfulnessProgramTemplateDayUnit } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDayUnit';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
`;

const Header = styled.div<{ dragOver?: boolean }>`
  padding: 8px;
  border-bottom: 1px solid lightgray;
`;

const Title = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px 4px;
`;

export type PhaseDayEditorProps = {
  template: MindfulnessProgramTemplate;
  workoutDay: MindfulnessProgramTemplateDay;
  index: number;
  onRemove?: (workoutDay: MindfulnessProgramTemplateDay, index: number) => void;
};

export const PhaseDayEditor: React.FC<PhaseDayEditorProps> = observer(({ template, workoutDay, index, onRemove }) => {
  const handleRemoveEntry = React.useCallback(
    (entry) => {
      runInAction(() => (workoutDay.units = workoutDay.units.filter((w) => w.id !== entry.id)));
    },
    [workoutDay],
  );

  const handleRemove = React.useCallback(() => {
    onRemove && onRemove(workoutDay, index);
  }, [onRemove, workoutDay, index]);

  const handleAddUnit = React.useCallback(
    () => runInAction(() => workoutDay.units.push(new MindfulnessProgramTemplateDayUnit())),
    [workoutDay],
  );

  return (
    <Container>
      <Header>
        <Title>{`Day ${index + 1}`}</Title>
      </Header>
      <Body>
        {workoutDay.units.map((unit, index) => (
          <UnitEntryEditor
            key={unit.id}
            index={index}
            workoutDay={workoutDay}
            template={template}
            unit={unit}
            onRemove={handleRemoveEntry}
          />
        ))}
        <Button type="primary" onClick={handleAddUnit} style={{ marginTop: 8 }}>
          Create new Unit
        </Button>
        <Popconfirm title={`Remove Day ${index + 1}?`} onConfirm={handleRemove}>
          <Button danger type="link" block={true}>
            Remove Day
          </Button>
        </Popconfirm>
      </Body>
    </Container>
  );
});
