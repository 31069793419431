/**
 * Created by neo on 12.04.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, message, Popconfirm, Space } from 'antd';
import { runInAction } from 'mobx';
import { Col, Container, Row } from 'reactstrap';
import { MindfulnessProgramTemplate } from '../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplate';
import { MindfulnessProgramTemplatePhase } from '../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplatePhase';
import { MindfulnessProgramTemplatePhasesEditor } from './MindfulnessProgramTemplatePhasesEditor';
import { MindfulnessProgramMainAttributesEditor } from './MindfulnessProgramMainAttributesEditor';

export type MindfulnessProgramTemplateViewScreenProps = {};

export const MindfulnessProgramTemplateViewScreen: React.FC<MindfulnessProgramTemplateViewScreenProps> = observer(
  (props) => {
    const history = useNavigate();
    const { templateId } = useParams<any>();
    const [template, setTemplate] = useState<MindfulnessProgramTemplate>(() => new MindfulnessProgramTemplate());

    useEffect(() => {
      if (templateId && templateId !== 'create' && templateId !== 'new') {
        MindfulnessProgramTemplate.findOne(templateId).then((result) =>
          setTemplate(result ?? new MindfulnessProgramTemplate()),
        );
      } else {
        setTemplate(new MindfulnessProgramTemplate());
      }
    }, [templateId]);

    const handleSave = React.useCallback(() => {
      template
        .save()
        .then((template) => {
          message.success('Template saved');
          history(`/coach/mindfulness-template/${template.id}`, { replace: true });
        })
        .catch(() => message.error('Error saving Program'));
    }, [history, template]);

    const handleDelete = React.useCallback(() => {
      template.delete().then(() => history(-1));
    }, [history, template]);

    const handleAddPhase = React.useCallback(() => {
      runInAction(() => template.phases.push(new MindfulnessProgramTemplatePhase()));
    }, [template]);

    return (
      <React.Fragment>
        <Form layout="vertical">
          <Container style={{ paddingBottom: 0 }}>
            <Row>
              <Col>
                <h1>Mindfulness Program Template</h1>
              </Col>
              <Col md="auto">
                <Space>
                  <Popconfirm title={`Delete?`} onConfirm={handleDelete}>
                    <Button type="link" danger onClick={handleDelete}>
                      Delete
                    </Button>
                  </Popconfirm>
                  <Button type="primary" onClick={handleSave}>
                    Save
                  </Button>
                </Space>
              </Col>
            </Row>
            <MindfulnessProgramMainAttributesEditor template={template} />
            <Row>
              <Col>
                <h3>Phases</h3>
              </Col>
              <Col md="auto">
                <Button type="primary" onClick={handleAddPhase}>
                  Add Phase
                </Button>
              </Col>
            </Row>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <MindfulnessProgramTemplatePhasesEditor template={template} />
              </div>
            </div>
          </Container>
        </Form>
      </React.Fragment>
    );
  },
);
