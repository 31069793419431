/**
 * @flow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import CreatableSelect from 'react-select/creatable';
import { Form } from 'antd';

export type ExerciseVariationFilterTagsExcludedProps = {
  filter: ExerciseFilter;
};

export const ExerciseVariationFilterTagsExcluded: React.FC<ExerciseVariationFilterTagsExcludedProps> = observer(
  ({ filter }) => {
    const handleChange = (tags: any[]) => {
      filter.excludedTags = (tags ?? []).map((e) => (e.value ? e.value : e));
    };

    return (
      <Form.Item label="Excluded Tags">
        <CreatableSelect
          value={(filter.excludedTags || []).map((e) => ({ value: e, label: e }))}
          isClearable
          isMulti
          onChange={handleChange as any}
        />
      </Form.Item>
    );
  },
);
