/**
 * Created by neo on 07.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import styled from '@emotion/styled';
import { Button } from 'antd';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { ExploreContent } from '../../../Model/Explore/ExploreContent';

export type ExploreCategoryRendererRootProps = {
  content: ExploreContent;
};

const Container = styled.div<{ backgroundImage?: string }>`
  border-radius: 16px;
  padding: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 160px;
  width: 100%;
  flex: 1 0 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  cursor: pointer;
  ${({ backgroundImage }) => (backgroundImage ? `background-image: url(${backgroundImage})` : '')}
`;

export const ExploreCategoryRendererRoot: React.FC<ExploreCategoryRendererRootProps> = observer(({ content }) => {
  const history = useNavigate();
  const location = useLocation();
  const gymId = new URLSearchParams(location.search).get('gymId') ?? undefined;
  const [over, setOver] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  const handleMouseOver = React.useCallback(() => {
    setMouseOver(true);
  }, []);

  const handleMouseOut = React.useCallback(() => {
    setMouseOver(false);
  }, []);

  const handleClickEdit = React.useCallback(() => {
    history(`${content.id}/${content.id}/edit?gymId=${gymId ?? ''}`);
  }, [history, content, gymId]);

  return (
    <Container backgroundImage={content.image?.medium} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <div style={Object.assign({}, styles.overlay, mouseOver ? styles.darkOverlay : {})}>
        <Button
          onClick={(e) => {
            handleClickEdit();
            e.stopPropagation();
          }}
        >
          Edit
        </Button>
      </div>

      <Link style={styles.title} to={`${content.id}?gymId=${gymId ?? ''}`}>
        {content.defaultName}
      </Link>
    </Container>
  );
});

const styles = {
  title: {
    fontSize: 28,
    color: 'white',
    zIndex: 100,
    textAlign: 'center',
  },
  overlay: {
    borderRadius: 16,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  darkOverlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  buttonOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
  },
} as any;
