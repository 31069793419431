/**
 *
 * Created by neo on 20.01.17.
 */

import { toJS, observable, computed } from 'mobx';
import { HttpBackend } from '../Services/Http/HttpBackend';
import { Audited, AuditedJson } from './Audited';

export type UserJson = AuditedJson & {
  email: string;
  password?: string;
  roles: string[];
};

export class User extends Audited {
  @observable
  email: string = '';
  @observable
  password?: string = undefined;
  @observable
  roles: Array<string> = [];

  constructor(json?: Partial<UserJson>) {
    super(json);
    if (json) {
      this.email = json.email ?? '';
      this.password = json.password;
      this.roles = json.roles || [];
    }
  }

  toJS(): UserJson {
    return Object.assign(super.toJS(), {
      email: this.email,
      password: this.password,
      roles: this.roles,
    });
  }

  remove() {
    return HttpBackend.delete(`/uaa/user/${this.id}`);
  }

  save() {
    return HttpBackend.post(`/uaa/user`, toJS(this)).then(() => this);
  }

  @computed
  get isAdmin(): boolean {
    return !!this.roles.find((r) => r === 'ROLE_ADMIN');
  }

  @computed
  get isGodAdmin(): boolean {
    return !!this.roles.find((r) => r === 'ROLE_GOD_ADMIN');
  }

  static async get(userId: string): Promise<User | undefined> {
    const res = await HttpBackend.get(`/uaa/user/${userId}`);
    if (res) {
      return new User(res);
    }
    return undefined;
  }

  static find(pageable: any = { page: 0, sort: 'email,ASC' }): Promise<User[]> {
    return HttpBackend.get('/uaa/user', pageable).then((res) => res.map((u) => new User(u)));
  }

  static count(params: any = {}): Promise<number> {
    return HttpBackend.get('/uaa/user/count', params);
  }
}
