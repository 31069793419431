import * as React from 'react';
import { Container } from 'reactstrap';
import { Tabs } from 'antd';
import { CampaignCalendarTableView } from './CampaignCalendarTableView';
import { CampaignCalendarCalendarView } from './CampaignCalendarCalendarView';

export type CampaignCalendarIndexScreenProps = {};

export const CampaignCalendarIndexScreen: React.FC<CampaignCalendarIndexScreenProps> = () => {
  return (
    <Container>
      <Tabs defaultActiveKey="table">
        <Tabs.TabPane tab="Table View" key="table">
          <CampaignCalendarTableView />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Calendar View" key="calendar">
          <CampaignCalendarCalendarView />
        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
};
