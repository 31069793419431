/**
 * Created by neo on 13.07.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { SortStageOrder } from '../../../../../../../Model/Coach/Stages/SortStage';
import styled from '@emotion/styled';
import { SortNodeModel } from './SortNodeModel';

export type SortingDirectionProps = {
  node: SortNodeModel;
};

const StyledSelect = styled.select`
  color: black;
  margin-right: 4px;
  flex: 1;
`;

export const SortingDirection: React.FC<SortingDirectionProps> = observer(
  ({ node: { stage } }: SortingDirectionProps) => {
    const handleChangeSortOrder = React.useCallback(
      ({ target: { value } }: any) => {
        stage.config.order = value as SortStageOrder;
      },
      [stage],
    );
    return (
      <StyledSelect
        value={stage.config.order}
        onChange={handleChangeSortOrder}
        disabled={!!stage.config.customSort?.length}
      >
        <option value="ascending">Ascending</option>
        <option value="descending">Descending</option>
      </StyledSelect>
    );
  },
);
