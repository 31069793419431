/**
 *
 * Created by neo on 25.02.17.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import MediaService from '../../Services/MediaService';
import { Media } from '../../Model/Media/Media';
import { SingleColRow } from '../SingleColRow';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { ModalMediaView } from './ModalMediaView';
import { MediaEditModal } from '../../View/MetaData/Media/MediaEditModal/MediaEditModal';
import { UploadFile, UploadFileStatus } from 'antd/lib/upload/interface';
import { ConditionalMedia } from '../../Model/Coach/SuperMacro/ConditionalMedia';
import { runInAction } from 'mobx';

const VideoSource = observer(({ media }: { media: Media }) => (
  <div
    style={{
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <video controls>
      <source src={media.smallest} type={media.mediaType} />
    </video>
  </div>
));

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export type ViewMediaProps = {
  containerName?: string;
  medias: Media[];
  message?: string;
  disableEdit?: boolean;
  disableCamera?: boolean;
  onSave?: (media: Media) => void;
  onTransform?: (media: Media) => Media;
};

export interface MediaUploadFile extends UploadFile {
  media?: Media;
}

export const ViewMedia: React.FC<ViewMediaProps> = observer(
  ({ medias, onSave, disableEdit, disableCamera, onTransform }) => {
    const [fileList, setFileList] = useState<MediaUploadFile[]>([]);
    const [preview, setPreview] = useState<Media | undefined>();

    const updateFileList = React.useCallback((medias: Media[]) => {
      return medias.map((media) => ({
        uid: media.url,
        size: media.size ?? 0,
        type: media.mediaType,
        name: media.name ?? '',
        status: 'done' as UploadFileStatus,
        url: media.smallest,
        media,
      })) as any[];
    }, []);

    useEffect(() => {
      setFileList(updateFileList(medias ?? []));
    }, [medias, updateFileList]);

    const handleCancel = React.useCallback(() => setPreview(undefined), []);

    const handleSave = React.useCallback(
      (media: Media) => {
        setPreview(undefined);
        console.log('media', media, onSave);
        onSave && onSave(media);
        const index = medias.findIndex((m) => m.id === media.id);
        if (index !== -1) {
          runInAction(() => (medias[index] = media));
        }
      },
      [onSave, medias],
    );

    const handlePreview = React.useCallback(async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreview(file.media);
    }, []);

    const handleRemove = React.useCallback(
      (file) => {
        const index = medias?.findIndex((m) => m.url === file.uid) ?? -1;
        console.log('remove', file, index, medias);
        if (index !== -1) {
          medias?.splice(index, 1);
        }
        setFileList(updateFileList(medias ?? []));
      },
      [medias, updateFileList],
    );

    const handleBeforeUpload = React.useCallback(
      (file) => {
        setFileList((l) => l.concat([Object.assign(file, { status: 'uploading' })]));
        MediaService.uploadMedia(file, (event) => {
          console.log('event', event);
        })
          .then((media) => {
            media = (onTransform && onTransform(media)) ?? media;
            medias?.push(media);
            console.log('media', media, medias);
            setFileList(updateFileList(medias ?? []));
          })
          .catch(() => {
            setFileList((l) => {
              l = l.filter((f) => f.uid !== file.uid);
              file.status = 'error';
              return l.concat([file]);
            });
          });
        return false;
      },
      [medias, onTransform, updateFileList],
    );

    return (
      <React.Fragment>
        <SingleColRow>
          <Upload
            beforeUpload={handleBeforeUpload}
            listType="picture-card"
            fileList={fileList}
            disabled={disableEdit}
            onPreview={handlePreview}
            onRemove={handleRemove}
            itemRender={(item, { media }: MediaUploadFile) => (
              <div key={media?.id}>
                {item}
                {media instanceof ConditionalMedia ? (
                  <React.Fragment>
                    {media.tagConditions.map((c, index) => (
                      <div key={index.toString()}>{c.tagString}</div>
                    ))}
                  </React.Fragment>
                ) : null}
              </div>
            )}
          >
            <div>
              <PlusOutlined />
              <div className="ant-upload-text">Upload</div>
            </div>
          </Upload>
        </SingleColRow>
        {preview ? (
          <React.Fragment>
            {preview.mediaType.startsWith('video') ? (
              <MediaEditModal media={preview} onCancel={handleCancel} onSave={handleSave} />
            ) : (
              <Modal open={true} width={1024} title={preview.name} footer={null} onCancel={handleCancel}>
                <ModalMediaView media={preview} />
              </Modal>
            )}
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  },
);
