/**
 * Created by neo on 13.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import dayjs from 'dayjs';
import { UserIdColumn, UserIdColumnProps } from './UserIdColumn';
import { NumericColumn } from './NumericColumn';
import { DurationColumn } from './DurationColumn';
import { PercentageColumn } from './PercentageColumn';

function diff(value0?: string, value1?: string) {
  const dec0 = value0 !== null && value0 !== undefined ? Number(value0) : 0;
  const dec1 = value1 !== null && value1 !== undefined ? Number(value1) : 0;
  return dec0 - dec1;
}

function diffString(value0?: string, value1?: string) {
  if (value0 || value1) {
    const dec0 = value0 !== null && value0 !== undefined ? Number(value0) : 0;
    const dec1 = value1 !== null && value1 !== undefined ? Number(value1) : 0;
    return diffNumber(dec0, dec1);
  }
  return '';
}

function diffNumber(value0?: number, value1?: number) {
  if (value0 || value1) {
    const dec0 = value0 ?? 0;
    const dec1 = value1 ?? 0;
    const delta = dec0 - dec1;
    if (delta > 0) {
      return `(+${delta})`;
    }
    return `(${delta})`;
  }
  return '';
}

function time(value?: string) {
  return value ? dayjs.utc(Number(value) * 1000).format('HH:mm:ss') : '';
}

export type UserStatisticsWeekTableRowProps = Pick<UserIdColumnProps, 'onUserSelect'> & {
  previousRow?: AnalyticsRow;
  row: AnalyticsRow;
  index: number;
};

export const UserStatisticsWeekTableRow: React.FC<UserStatisticsWeekTableRowProps> = observer(
  ({ row, previousRow, onUserSelect, index }) => {
    const engagementTime = row.avg_engagement_seconds ? Number(row.avg_engagement_seconds) : 0;

    return (
      <tr key={row.user_id}>
        <th scope="row">{`${index + 1}`}</th>
        <UserIdColumn row={row} onUserSelect={onUserSelect} />
        <td style={engagementTime < 10 ? { backgroundColor: '#dca1a1' } : undefined}>
          {dayjs(Number(row.event_timestamp) / 1000).format('DD.MM.YYYY')}
        </td>
        <NumericColumn field="article_count" row={row} previousRow={previousRow} />
        <DurationColumn field="article_avg" row={row} previousRow={previousRow} />
        <NumericColumn field="recipe_count" row={row} previousRow={previousRow} />
        <NumericColumn field="breathing_count" row={row} previousRow={previousRow} />
        <PercentageColumn field="breathing_avg_percentage" row={row} previousRow={previousRow} />
        <DurationColumn field="breathing_avg_duration" row={row} previousRow={previousRow} />
        <NumericColumn field="meditation_count" row={row} previousRow={previousRow} />
        <PercentageColumn field="meditation_avg_percentage" row={row} previousRow={previousRow} />
        <DurationColumn field="meditation_avg_duration" row={row} previousRow={previousRow} />
        <NumericColumn field="yoga_count" row={row} previousRow={previousRow} />
        <PercentageColumn field="yoga_avg_percentage" row={row} previousRow={previousRow} />
        <DurationColumn field="yoga_avg_duration" row={row} previousRow={previousRow} />
        <NumericColumn field="workout_count" row={row} previousRow={previousRow} />
        <PercentageColumn field="workout_avg_percentage" row={row} previousRow={previousRow} />
        <DurationColumn field="workout_avg_duration_seconds" row={row} previousRow={previousRow} />
        <NumericColumn field="workout_avg_completed_exercises" row={row} previousRow={previousRow} />
        <NumericColumn field="workplace_count" row={row} previousRow={previousRow} />
        <PercentageColumn field="workplace_avg_percentage" row={row} previousRow={previousRow} />
        <DurationColumn field="workplace_avg_duration_seconds" row={row} previousRow={previousRow} />
        <NumericColumn field="workplace_avg_completed_exercises" row={row} previousRow={previousRow} />
        <DurationColumn field="avg_engagement_seconds" row={row} previousRow={previousRow} />
      </tr>
    );
  },
);
