/**
 * Created by neo on 24.09.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import { SuperMacroMesoCycleTemplate } from '../../../../../Model/Coach/SuperMacro/SuperMacroMesoCycleTemplate';
import { TranslationInputArray } from '../../../../../Components/Translation/TranslationInputArray';

export type MesoCycleEditModalProps = {
  mesoCycle: SuperMacroMesoCycleTemplate;
  index: number;
  onClose?: () => any;
};

export const MesoCycleEditModal: React.FC<MesoCycleEditModalProps> = observer(
  ({ mesoCycle, index, onClose }: MesoCycleEditModalProps) => {
    return (
      <Modal
        width={1920}
        style={{ top: 20 }}
        open={true}
        title={`Edit ${mesoCycle.type} Cycle ${index + 1}`}
        onOk={onClose}
        onCancel={onClose}
      >
        <TranslationInputArray entity={mesoCycle} field="description" label="Description"  />
      </Modal>
    );
  },
);
