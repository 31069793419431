/**
 * Created by neo on 03.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { EarnModelTemplate } from '../../../../Model/Engagement/EarnModelTemplate';
import { EarnModelTemplateCategoryPanel } from './EarnModelTemplateCategoryPanel';

export type EarnModelTemplateCategoryEditorProps = {
  template: EarnModelTemplate;
};

export const EarnModelTemplateCategoryEditor: React.FC<EarnModelTemplateCategoryEditorProps> = observer(
  ({ template }) => {
    return (
      <React.Fragment>
        {template.categories.map((category) => (
          <EarnModelTemplateCategoryPanel key={category.id} template={template} category={category} />
        ))}
      </React.Fragment>
    );
  },
);
