/**
 * Created by neo on 30.09.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

export type InfiniteScrollProps = {
  onLoad?: () => any;
  loading?: boolean;
};

export const InfiniteScroll: React.FC<InfiniteScrollProps> = observer(({ onLoad, loading }: InfiniteScrollProps) => {
  const loader = React.useRef<any>(null);

  const handleObserver = React.useCallback((entities) => {
    const target = entities[0];
    if (target.isIntersecting && !loading) {
      onLoad && onLoad();
    }
  }, [loading, onLoad]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };
    // initialize IntersectionObserver and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, [handleObserver]);

  return <div ref={loader} />;
});
