/**
 * Created by katarinababic on 3.5.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { AnalyticsData, AnalyticsRow } from '../../../../../Model/Analytics/AnalyticsData';
import { querySelectedEntry } from './Queries/querySelectedEntry';
import { ExploreEntry } from '../../../../../Model/Explore/ExploreEntry';
import { Col, Row, Spinner } from 'reactstrap';
import { DatePicker, Form } from 'antd';
import { ExploreEntryModalAnalyticsSelectContentChart } from './ExploreEntryModalAnalyticsSelectContentChart';
import { ExploreEntryModalAnalyticsContentSwitch } from './ExploreEntryModalAnalyticsContentSwitch';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { ExploreEntryModalAnalyticsVideoFullscreenDismissed } from './ExploreEntryModalAnalyticsVideoFullscreenDismissed';

export type ExploreEntryModalAnalyticsProps = {
  entry: ExploreEntry;
};

export const ExploreEntryModalAnalytics: React.FC<ExploreEntryModalAnalyticsProps> = observer(({ entry }) => {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(dayjs());
  const [data, setData] = useState<AnalyticsRow[] | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    AnalyticsData.query({
      query: querySelectedEntry,
      parameters: Object.assign({
        start_date: {
          value: startDate.format('YYYYMMDD'),
        },
        end_date: {
          value: endDate.format('YYYYMMDD'),
        },
        entry_type: {
          value: entry.type,
        },
        entry_id: {
          value: entry.id,
        },
      }) as any,
    })
      .then((res) => {
        setData(
          res.map((d) =>
            Array.from(Object.entries(d))
              .map(([key, value]) => [key, key === 'entry_id' ? value : Number(value)])
              .reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {} as any),
          ),
        );
      })
      .finally(() => setLoading(false));
  }, [startDate, endDate, entry]);

  const handleChangeValidFrom = React.useCallback(
    (date) => setStartDate(date ?? dayjs().subtract(1, 'month')),
    [],
  );

  const handleChangeValidUntil = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <SingleColRow>
            <h6>Number of clicks</h6>
          </SingleColRow>
          <Row>
            <Col xs={6}>
              <Form.Item label="Start Date">
                <DatePicker value={startDate} onChange={handleChangeValidFrom} />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item label="End Date">
                <DatePicker value={endDate} onChange={handleChangeValidUntil} />
              </Form.Item>
            </Col>
            <Col xs={12}>
              {loading ? <Spinner /> : data ? <ExploreEntryModalAnalyticsSelectContentChart data={data} /> : null}
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <ExploreEntryModalAnalyticsContentSwitch entry={entry} />
        </Col>
        {entry.type === 'article' && entry.videos.length > 0 && (
          <Row style={styles.row}>
            <Col xs={6}>
              <SingleColRow>
                <h6>Video fullscreen dismissed event stats</h6>
              </SingleColRow>
              <ExploreEntryModalAnalyticsVideoFullscreenDismissed entry={entry} />
            </Col>
            <Col xs={1} />
            <Col xs={5}>
              <h6>Answer index values</h6>
              <p>{'0 - It was too long'}</p>
              <p>{"1 - I didn't like the content"}</p>
              <p>{"2 - I don't have enough time"}</p>
              <p>{'3 - I started it by mistake'}</p>
              <p>{'4 - I was not in the right environment'}</p>
            </Col>
          </Row>
        )}
      </Row>
    </React.Fragment>
  );
});

const styles = {
  row: {
    marginTop: 80,
  },
};
