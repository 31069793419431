/**
 * Created by neo on 04.11.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { Pager } from '../../../../../Components/Pager';
import { PageResult } from '../../../../../Model/PageResult';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CustomerGroup } from '../../../../../Model/Gym/CustomerGroup/CustomerGroup';
import { CustomerGroupMember } from '../../../../../Model/Gym/CustomerGroup/CustomerGroupMember';

export type CustomerGroupModalMembersTabProps = {
  customerGroup: CustomerGroup;
};

export const CustomerGroupModalMembersTab: React.FC<CustomerGroupModalMembersTabProps> = observer(
  ({ customerGroup }) => {
    const [result, setResult] = useState(new PageResult<CustomerGroupMember>());
    const [page, setPage] = useState(0);

    useEffect(() => {
      PageResult.execute(
        CustomerGroupMember.find(customerGroup.id, customerGroup.gymId, { page, size: 20 }),
        CustomerGroupMember.count(customerGroup.id, customerGroup.gymId),
        page,
        20,
      ).then((res) => setResult(res));
    }, [page, customerGroup]);

    const handlePage = React.useCallback((newPage: number) => {
      setPage(newPage);
    }, []);

    return (
      <React.Fragment>
        <SingleColRow>
          <Pager page={result} onPage={handlePage} />
        </SingleColRow>
        <SingleColRow>
          <Table size="sm">
            <thead>
              <tr>
                <th>User</th>
              </tr>
            </thead>
            <tbody>
              {result.content.map((member, index) => (
                <tr key={member.customer.id}>
                  <td>
                    <Link to={`/athlete/${member.customer.athlete.id}`}>
                      {member.customer.athlete?.fullName || member.customer.athlete.id}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </SingleColRow>
        <SingleColRow>
          <Pager page={result} onPage={handlePage} />
        </SingleColRow>
      </React.Fragment>
    );
  },
);
