/**
 *
 * Created by neo on 18.01.17.
 */

import { observable, computed, action, onBecomeObserved } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Athlete } from '../Athlete/Athlete';
import { AbstractWorkout } from './AbstractWorkout';

export class Workout extends AbstractWorkout {
  @observable
  name?: string = undefined;
  @observable
  description?: string = undefined;
  @observable
  lastExecutionTimestamp: number = 0;
  @observable
  executedCount: number = 0;
  @observable
  createdAt = new Date();
  @observable
  updatedAt = new Date();
  @observable
  createdBy?: string = undefined;
  @observable
  updatedBy?: string = undefined;
  @observable
  creator?: Athlete = undefined;
  @observable
  updater?: Athlete = undefined;

  constructor(json?: any) {
    super(json);
    if (json) {
      this.name = json.name;
      this.description = json.description;
      this.lastExecutionTimestamp = json.lastExecutionTimestamp || 0;
      this.executedCount = json.executedCount;
      this.createdAt = json.createdAt ? new Date(json.createdAt) : new Date();
      this.updatedAt = json.updatedAt ? new Date(json.updatedAt) : new Date();
      this.createdBy = json.createdBy;
      this.updatedBy = json.updatedBy;
    }

    onBecomeObserved(this, 'creator', this.fetchCreator);
    onBecomeObserved(this, 'updater', this.fetchUpdater);
  }

  fetchCreator = async () => {
    if (!this.creator && this.createdBy) {
      this.creator = await Athlete.get(this.createdBy);
    }
  };

  fetchUpdater = async () => {
    if (!this.updater && this.updatedBy) {
      this.updater = await Athlete.get(this.updatedBy);
    }
  };

  toJS(newId?: boolean): any {
    return Object.assign(super.toJS(), {
      name: this.name,
      description: this.description,
      lastExecutionTimestamp: this.lastExecutionTimestamp,
      executedCount: this.executedCount,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
    });
  }

  @action
  remove() {
    return HttpBackend.delete(`/workout/${this.id}`);
  }

  save() {
    return HttpBackend.post('/workout/save', this.toJS()).then(() => this);
  }

  @computed
  get nameValid(): boolean {
    if (this.name) {
      return this.name.trim().length > 0;
    }
    return false;
  }

  @computed
  get valid(): boolean {
    if (this.nameValid) {
      if (this.hasPhases) {
        const validPhases = this.phases.filter((p) => p.valid);
        return validPhases.length > 0;
      }
      // console.tron.log('no phases');
    }
    return false;
  }

  @computed
  get invalid(): boolean {
    return !this.valid;
  }

  static find(params: any = {}): Promise<Workout[]> {
    return HttpBackend.get('/workout', params).then((res) => res.map((w) => new Workout(w)));
  }

  static count(params: any = {}): Promise<number> {
    return HttpBackend.get('/workout/count', params);
  }

  static get(workoutId: string): Promise<Workout> {
    return HttpBackend.get(`/workout/${workoutId}`).then((res) => new Workout(res));
  }
}
