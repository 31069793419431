import * as React from 'react';
import { observer } from 'mobx-react';

const styles = {
  footer: {
    padding: '0.5rem',
    fontSize: '1rem',
    backgroundColor: '#1f1f1f',
    textAlign: 'center',
    color: 'white',
  },
};

export type FooterProps = {};

export const Footer: React.FC<FooterProps> = observer(() => {
  return (
    <footer style={styles.footer as any} className="fixed-bottom footer">
      &copy; 2020 - kinastic AG
    </footer>
  );
});
