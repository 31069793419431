/**
 * @fow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Container, Col, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { BodyPartJointBasicInfoTab } from './BodyPartJointBasicInfoTab';
import { ViewMedia } from '../../../../Components/ViewMedia/ViewMedia';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { runInAction } from 'mobx';
import { BodyPartJointExercisesTab } from './BodyPartJointExercisesTab';
import { Button, Space } from 'antd';
import { BodyPartJoint } from '../../../../Model/BodyPart/BodyPartJoint';

export const BodyPartJointViewScreen: React.FC<unknown> = observer(() => {
  const history = useNavigate();
  const { jointId } = useParams<any>();
  const store = useLocalStore(() => ({
    joint: new BodyPartJoint(),
    activeTab: 'basic',
    processing: false,
    fetch(jointId?: string) {
      if (jointId && 'create' !== jointId) {
        store.get(jointId);
      } else {
        store.joint = new BodyPartJoint();
      }
    },
    get(id: string) {
      store.processing = true;
      BodyPartJoint.get(id)
        .then((result) => runInAction(() => (store.joint = result ?? new BodyPartJoint())))
        .finally(() => runInAction(() => (store.processing = false)));
    },
  }));

  useEffect(() => {
    store.fetch(jointId);
  }, [jointId]);

  const toggle = React.useCallback(
    (tab) => {
      store.activeTab = tab;
    },
    [store],
  );

  const handleSave = React.useCallback(async () => {
    store.processing = true;
    try {
      await store.joint.save();
      history(`/metadata/bodypart/joint/view/${store.joint.id}`, { replace: true });
    } finally {
      store.processing = false;
    }
  }, [store]);

  const createNew = React.useCallback(async () => {
    history(`/metadata/bodypart/joint/view/create`, { replace: true });
  }, [history]);

  const isNew = React.useMemo(() => !jointId || jointId === 'new', [jointId]);

  return (
    <Container>
      <Row>
        <Col>
          <h1>{!isNew ? `${store.joint.defaultName}` : 'New Joint'}</h1>
        </Col>
        <Col xs="auto">
          <Space>
            <Button type="dashed" onClick={createNew} disabled={store.processing}>
              {'Create Another'}
            </Button>
            <Button type="primary" onClick={handleSave} disabled={store.processing}>
              {'Save'}
            </Button>
          </Space>
        </Col>
      </Row>
      <SingleColRow>
        <Nav tabs>
          <NavItem>
            <NavLink className={classnames({ active: store.activeTab === 'basic' })} onClick={() => toggle('basic')}>
              {'Basic Info'}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: store.activeTab === 'media' })} onClick={() => toggle('media')}>
              {'Medias'}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: store.activeTab === 'exercises' })}
              onClick={() => toggle('exercises')}
            >
              {'Exercises'}
            </NavLink>
          </NavItem>
        </Nav>
      </SingleColRow>
      <SingleColRow style={{ paddingTop: 8 }}>
        <TabContent activeTab={store.activeTab}>
          <TabPane tabId="basic">
            <BodyPartJointBasicInfoTab joint={store.joint} />
          </TabPane>
          <TabPane tabId="media">
            <ViewMedia medias={store.joint.medias} />
          </TabPane>
          <TabPane tabId="exercises">
            <BodyPartJointExercisesTab joint={store.joint} />
          </TabPane>
        </TabContent>
      </SingleColRow>
    </Container>
  );
});
