/**
 * Created by andreaskarantzas on 09.04.2021.
 */
import { observable, onBecomeObserved, runInAction, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { ActivityWorkoutEntry } from './ActivityWorkoutEntry';
import { Activity } from '../Activity/Activity';
import { SubscribableEntry, SubscribableEntryJson } from './SubscribableEntry';
import { OfflineMeetingLocation, OfflineMeetingLocationJson } from './OfflineMeetingLocation';
import { FormField, FormFieldJson } from './FormField';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';

export type OfflineMeetingEntryJson = SubscribableEntryJson & {
  /**
   * optional -> if set it means we will log an activity log
   */
  activityIdentifier?: string;
  data: Record<string, any>;
  language: string;
  previewTitle: LocalizedValueJson[];
  previewDescription: LocalizedValueJson[];
  minParticipants: number;
  maxParticipants: number;
  registrationFields: FormFieldJson[];
  location: OfflineMeetingLocationJson;
  registerUntilDate: string;
};

export class OfflineMeetingEntry extends SubscribableEntry {
  @observable
  activityIdentifier?: string;
  @observable
  data: Record<string, any> = {};
  @observable
  language = 'de';
  @observable
  previewTitle: LocalizedValue[] = [];
  @observable
  previewDescription: LocalizedValue[] = [];
  @observable
  minParticipants = 0;
  @observable
  maxParticipants = -1;
  @observable
  registrationFields: FormField[] = [];
  @observable
  location = new OfflineMeetingLocation();
  @observable
  registerUntilDate = new Date();

  @observable
  activity?: Activity;

  constructor(json?: Partial<OfflineMeetingEntryJson>) {
    super(
      Object.assign(
        json ?? {},
        { type: 'offlineMeeting' },
        { version: { minVersionIos: '1.54.0', minVersionAndroid: '1.54.0' } },
      ),
    );
    if (json) {
      this.activityIdentifier = json.activityIdentifier;
      this.data = json.data ?? {};
      this.type = 'offlineMeeting';
      this.language = json.language ?? 'de';
      this.previewTitle = json.previewTitle?.map((t) => new LocalizedValue(t)) ?? [];
      this.previewDescription = json.previewDescription?.map((t) => new LocalizedValue(t)) ?? [];
      this.minParticipants = json.minParticipants ?? 0;
      this.maxParticipants = json.maxParticipants ?? 0;
      this.registrationFields = json.registrationFields?.map((f) => new FormField(f)) ?? [];
      this.location = new OfflineMeetingLocation(json.location);
      this.registerUntilDate = json.registerUntilDate ? new Date(json.registerUntilDate) : new Date();
    }

    onBecomeObserved(this, 'activity', this.fetchActivity);
  }

  fetchActivity = () => {
    if (!this.activity) {
      if (this.activityIdentifier) {
        return Activity.get(this.activityIdentifier).then((result) => {
          runInAction(() => (this.activity = result));
          return result;
        });
      }
    }
    return Promise.resolve(this.activity);
  };

  toJS(): OfflineMeetingEntryJson {
    return Object.assign(super.toJS(), {
      activityIdentifier: this.activityIdentifier,
      data: toJS(this.data),
      language: this.language,
      previewTitle: this.previewTitle.map((t) => t.toJS()),
      previewDescription: this.previewDescription.map((t) => t.toJS()),
      minParticipants: this.minParticipants,
      maxParticipants: this.maxParticipants,
      registrationFields: this.registrationFields.map((f) => f.toJS()),
      location: this.location.toJS(),
      registerUntilDate: this.registerUntilDate.toISOString(),
    });
  }

  static async find(request: Omit<ExploreEntryQueryRequest, 'type'>): Promise<OfflineMeetingEntry[]> {
    return HttpBackend.get(`/coach/explore/admin`, Object.assign(toJS(request), { type: 'offlineMeeting' })).then(
      (res) => (res ?? []).map((r) => new ActivityWorkoutEntry(r)),
    );
  }

  static async findOne(id: string): Promise<OfflineMeetingEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new OfflineMeetingEntry(res));
  }
}
