/**
 * Created by neo on 03.08.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { AnalyticsData, AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { Col, Container, Row } from 'reactstrap';
import { SingleColRow } from '../../../Components/SingleColRow';
import { DatePicker, Form } from 'antd';
import { Gym } from '../../../Model/Gym/Gym';
import { percentageCompleted, percentageCompletedGym } from './Queries/queryPercentageCompleted';
import { PercentageCompletedChart } from './PercentageCompletedChart';

const labels = {
  breathingSession_finished: 'Breathing',
  meditationSession_finished: 'Meditation',
  stretching: 'Stretching',
  gym_strength: 'Workout',
  workplace: 'Workplace',
};

export type PercentageCompletedProps = {
  gym?: Gym;
};

export const PercentageCompleted: React.FC<PercentageCompletedProps> = observer(({ gym }) => {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'year'));
  const [endDate, setEndDate] = useState(dayjs());
  const [data, setData] = useState<AnalyticsRow[] | undefined>();

  useEffect(() => {
    setData(undefined);
    AnalyticsData.query({
      query: gym ? percentageCompletedGym : percentageCompleted,
      parameters: {
        start_date: {
          value: startDate.format('YYYYMMDD'),
        },
        end_date: {
          value: endDate.format('YYYYMMDD'),
        },
        gym_id: {
          value: gym?.id ?? '',
        },
      },
    }).then((data) =>
      setData(
        data.map((d) =>
          Array.from(Object.entries(d))
            .map(([key, value]) => [key, key === 'type' ? value : Number(value)])
            .reduce(
              (obj, [key, value]) =>
                Object.assign(obj, { [key]: value }, key === 'type' ? { label: labels[value] ?? value } : {}),
              {},
            ),
        ),
      ),
    );
  }, [gym, startDate, endDate]);

  const handleChangeValidFrom = React.useCallback(
    (date) => setStartDate(date ?? dayjs().subtract(1, 'month')),
    [],
  );

  const handleChangeValidUntil = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

  return (
    <Container>
      <SingleColRow>
        <h5>Percentage Completed</h5>
      </SingleColRow>
      <Row>
        <Col xs={6}>
          <Form.Item label="Start Date">
            <DatePicker value={startDate} onChange={handleChangeValidFrom} />
          </Form.Item>
        </Col>
        <Col xs={6}>
          <Form.Item label="End Date">
            <DatePicker value={endDate} onChange={handleChangeValidUntil} />
          </Form.Item>
        </Col>
        <Col xs={12}>{data && <PercentageCompletedChart data={data} />}</Col>
      </Row>
    </Container>
  );
});
