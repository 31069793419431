/**
 * Created by neo on 15.12.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AthleteCoachSubscription } from '../../../../Model/Subscription/AthleteCoachSubscription';
import { Link } from 'react-router-dom';
import { Button, Space } from 'antd';
import dayjs from 'dayjs';

export type AthleteCoachSubscriptionTableRowProps = {
  subscription: AthleteCoachSubscription;
  onRemove?: (subscription: AthleteCoachSubscription) => void;
};

export const AthleteCoachSubscriptionTableRow: React.FC<AthleteCoachSubscriptionTableRowProps> = observer(
  ({ subscription, onRemove }) => {
    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(subscription);
    }, [onRemove, subscription]);

    return (
      <tr>
        <td>
          <Link to={`${subscription.id}`}>{subscription.id}</Link>{' '}
        </td>
        <td>
          <Link to={`/infrastructure/gym/${subscription.gymId}`}>{subscription.gym?.name}</Link>
        </td>
        <td>{subscription.plan}</td>
        <td>{dayjs(subscription.validFrom).format('DD.MM.YYYY HH:mm')}</td>
        <td>{subscription.validUntil ? dayjs(subscription.validUntil).format('DD.MM.YYYY HH:mm') : 'unlimited'}</td>
        <td>
          <Space>
            {onRemove && !subscription.parentId && subscription.plan !== 'free' && (
              <Button onClick={handleRemove} danger>
                Delete
              </Button>
            )}
          </Space>
        </td>
      </tr>
    );
  },
);
