// application/src/App/View/Coach/OffboardingModal.tsx
import React, { useState, useCallback } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import { HttpBackend } from '../../../../../Services/Http/HttpBackend';

export type OffboardingModalProps = {
  visible: boolean;
  gymId: string;
  onClose: () => void;
};

export const OffboardingModal: React.FC<OffboardingModalProps> = ({ visible, gymId, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = useCallback(async () => {
    try {
      const values = await form.validateFields();
      const payload = {
        email: values.email || null,
        employeeNumber: values.employeeNumber || null,
        customerId: values.customerId || null,
        gymId: gymId,
      };
      setLoading(true);
      // POST request to offboard endpoint
      await HttpBackend.post('/coach-program/offboarding/admin', payload);
      message.success('Offboarding request submitted successfully');
      form.resetFields();
      onClose();
    } catch (err: any) {
      message.error('Failed to submit offboarding request');
    } finally {
      setLoading(false);
    }
  }, [form, gymId, onClose]);

  return (
    <Modal
      visible={visible}
      title="Offboard Employee/Customer"
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Email" name="email">
          <Input placeholder="Enter email" />
        </Form.Item>
        <Form.Item label="Employee Number" name="employeeNumber">
          <Input placeholder="Enter employee number" />
        </Form.Item>
        <Form.Item label="Customer ID" name="customerId">
          <Input placeholder="Enter customer ID" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
