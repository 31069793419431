/**
 * Created by neo on 18.01.17.
 */

import { action, computed, observable } from 'mobx';
import { v4 as UUID } from 'uuid';
import { LocalizedValue } from '../../LocalizedValue';
import ExerciseVariationDocument from '../../Exercise/ExerciseVariationDocument';
import { WorkoutResponsePhase } from './WorkoutResponsePhase';
import { WorkoutResponseSet } from './WorkoutResponseSet';
import { BaseTrackingKey, TrackingKeys } from '../../ProgramPortfolio/TrackingKeys';
import { languagePriority } from '../../LocalizedArrayEntity';

export class WorkoutResponseExerciseBlock {
  @observable exerciseBlockId: string = UUID();
  @observable
  description: Array<LocalizedValue> = [];
  @observable exercise: ExerciseVariationDocument = new ExerciseVariationDocument();
  @observable.shallow sets: Array<WorkoutResponseSet> = [];
  @observable phase: WorkoutResponsePhase;

  constructor(phase: WorkoutResponsePhase, json?: any) {
    this.phase = phase;
    if (json) {
      this.setData(json);
    }
  }

  toJS(newId?: boolean): any {
    return {
      exerciseBlockId: newId ? UUID() : this.exerciseBlockId,
      description: this.description.map((l) => l.toJS()),
      exercise: this.exercise.toJS(),
      sets: this.sets.map((s) => s.toJS()),
    };
  }

  @action
  setBasicData(json: any) {
    this.exerciseBlockId = json.exerciseBlockId || UUID();
    this.description = (json.description ?? []).map((l) => new LocalizedValue(l));
    this.exercise =
      json.exercise instanceof ExerciseVariationDocument ? json.exercise : new ExerciseVariationDocument(json.exercise);
  }

  @action
  setData(json: any) {
    this.setBasicData(json);
    this.sets = (json.sets || []).map((s: any) => new WorkoutResponseSet(this, s));
  }

  calculateCalories(bmr24: number): number {
    return this.sets.reduce((total, set) => total + set.calculateCalories(bmr24), 0);
  }

  // suggestNew(params?: PipelineContext): Promise<Coach[]> {
  //   const context = params ?? new PipelineContext();
  //   context.excludeExerciseIds = this.phase.exerciseBlocks.map((b) => b.exercise.id);
  //   return HttpBackend.post(
  //     `/coach/workout/${this.phase.workout.id}/${this.phase.phaseId}/suggest`,
  //     context.toJS(),
  //   ).then((result) => result.map((r) => new CoachExerciseBlock(this.phase, r)));
  // }
  //
  // replace(newBlock: CoachExerciseBlock) {
  //   const promise = HttpBackend.post(`/coach/workout/${this.phase.workout.id}/replaceExerciseBlock`, {
  //     exerciseBlockId: this.exerciseBlockId,
  //     exerciseBlock: newBlock.toJS(),
  //   }).then(() => this);
  //   this.setData(newBlock);
  //   return promise;
  // }

  @action
  async save() {
    return true;
  }

  @computed
  get valid(): boolean {
    return true;
  }

  @computed
  get duration(): number {
    return this.sets.reduce((total: number, set) => total + set.totalDuration, 0);
  }

  @computed
  get breakTime(): number {
    return this.sets.reduce((total: number, set) => total + set.breakTime, 0);
  }

  @computed
  get totalTimeExercising(): number {
    return this.sets.reduce((total: number, set) => total + set.plannedDurationMs, 0);
  }

  @computed
  get tons(): number {
    return this.sets.reduce((total: number, set) => total + set.tons, 0);
  }

  @computed
  get lastSet(): WorkoutResponseSet | undefined {
    const { length } = this.sets;
    if (length > 0) {
      return this.sets[length - 1];
    }
    return undefined;
  }

  @computed
  get firstSet(): WorkoutResponseSet | undefined {
    const { sets } = this;
    if (sets.length > 0) {
      return sets[0];
    }
    return undefined;
  }

  @computed
  get prevSet(): WorkoutResponseSet | undefined {
    if (!this.lastSet) {
      return this.prevBlock && this.prevBlock.prevSet;
    }
    return this.lastSet;
  }

  @computed
  get followingSet(): WorkoutResponseSet | undefined {
    return this.firstSet || (this.nextBlock ? this.nextBlock.followingSet : undefined);
  }

  @computed
  get exerciseBlockIndex(): number {
    return this.phase ? this.phase.exerciseBlocks.findIndex((b) => b.exerciseBlockId === this.exerciseBlockId) : -1;
  }

  @computed
  get isLastBlock(): boolean {
    if (this.phase) {
      const { exerciseBlockIndex } = this;
      return exerciseBlockIndex + 1 === this.phase.exerciseBlocks.length;
    }
    return true;
  }

  @computed
  get prevBlock(): WorkoutResponseExerciseBlock | undefined {
    if (this.phase) {
      const { exerciseBlockIndex } = this;
      if (exerciseBlockIndex === 0) {
        return this.phase.prevPhase?.prevBlock;
      }
      return this.phase.exerciseBlocks[exerciseBlockIndex - 1];
    }
    return undefined;
  }

  @computed
  get nextBlock(): WorkoutResponseExerciseBlock | undefined {
    if (this.phase) {
      const { exerciseBlockIndex, isLastBlock } = this;
      if (isLastBlock) {
        return this.phase.nextPhase?.nextBlock;
      }
      const nextIndex = exerciseBlockIndex + 1;
      return nextIndex < this.phase.exerciseBlocks.length ? this.phase.exerciseBlocks[nextIndex] : undefined;
    }
    return undefined;
  }

  @computed
  get defaultTrackingKeys(): BaseTrackingKey[] {
    return this.exercise.trackingParameters;
  }

  @computed
  get trackingParameters(): BaseTrackingKey[] {
    return this.defaultTrackingKeys;
  }

  @computed
  get sortedTrackingParameters(): BaseTrackingKey[] {
    const trackingKeys = Object.keys(TrackingKeys);
    return this.trackingParameters.slice().sort((a, b) => trackingKeys.indexOf(a) - trackingKeys.indexOf(b));
  }

  @computed
  get estimatedTotalExerciseTime(): number {
    return this.sets.reduce((total: number, set) => total + set.totalDuration, 0);
  }

  @computed
  get defaultDescription(): string {
    for (const lang of languagePriority) {
      const entry = this.description.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.description[0];
    return first?.value ?? '';
  }

  @computed
  get minDuration(): number {
    let totalTime = 0;
    const sets = (this.sets || []).slice();
    for (const set of sets) {
      totalTime += set.minDuration;
    }
    return totalTime;
  }

  @computed
  get maxDuration(): number {
    return this.sets.reduce((total, set) => total + set.maxDuration, 0);
  }
}
