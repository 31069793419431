/**
 * @flow
 * Created by neo on 18.01.17.
 */

import { observable, computed, action, toJS, set, remove } from 'mobx';
import { v4 as UUID } from 'uuid';
import { LocalizedArrayEntity } from '../../../LocalizedArrayEntity';
import { LocalizedValue } from '../../../LocalizedValue';
import { HttpBackend } from '../../../../Services/Http/HttpBackend';
import { PipelineParam } from '../../PipelineParam';
import { PipelineContext } from '../../PipelineContext';
import { PhaseConfiguration } from './PhaseConfiguration';
import { paramSort } from '../paramSort';
import { ConditionalMedia } from '../ConditionalMedia';
import { WorkoutResponse } from '../../Script/WorkoutResponse';
import { Retry } from '../../../../Utils/Retry';
import { Pageable } from '../../../Pageable';

export type WorkoutTemplateQueryRequest = Pageable & {
  name?: string;
  tags?: string[];
  archived?: boolean;
  phaseScriptId?: string;
};

export class WorkoutTemplate extends LocalizedArrayEntity {
  @observable
  templateName?: string = undefined;
  @observable
  type: string = 'gym_strength';
  @observable
  medias: ConditionalMedia[] = [];
  @observable
  params: {
    [key: string]: any;
  } = {};
  @observable
  tags: string[] = [];
  @observable
  phaseConfigurations: PhaseConfiguration[] = [new PhaseConfiguration()];
  @observable
  minDurationInMinutes?: number;
  @observable
  maxDurationInMinutes?: number;
  @observable
  intensity = 'medium';

  constructor(json?: any) {
    super(json);
    if (json) {
      this.id = json.id || UUID();
      this.setData(json);
    }
  }

  toJS(replaceId: boolean = false): any {
    return Object.assign(super.toJS(), {
      id: replaceId ? UUID() : this.id,
      templateName: this.templateName,
      type: this.type,
      tags: toJS(this.tags),
      phaseConfigurations: this.phaseConfigurations.map((p) => p.toJS(replaceId)),
      minDurationInMinutes: this.minDurationInMinutes,
      maxDurationInMinutes: this.maxDurationInMinutes,
      params: toJS(this.params),
      medias: this.medias.map((m) => m.toJS()),
      intensity: this.intensity,
    });
  }

  @action
  setData(json?: any) {
    this.type = json.type;
    this.templateName = json.templateName;
    this.medias = (json.medias ?? []).map((m) => new ConditionalMedia(m));
    this.name = (json.name || []).map((v) => new LocalizedValue(v));
    this.tags = json.tags || [];
    this.params = json.params ?? {};
    this.phaseConfigurations = (json.phaseConfigurations ?? []).map((p) => new PhaseConfiguration(p));
    this.minDurationInMinutes = json.minDurationInMinutes;
    this.maxDurationInMinutes = json.maxDurationInMinutes;
    this.description = (json.description || []).map((v) => new LocalizedValue(v));
    this.intensity = json.intensity ?? 'medium';
  }

  copy(data?: any): WorkoutTemplate {
    return new WorkoutTemplate(Object.assign(this.toJS(true), data || {}));
  }

  update(json?: any) {
    this.setData(json);
  }

  delete() {
    return HttpBackend.delete(`/coach/program/template/workout/${this.id}`);
  }

  @action
  setParam(paramName: string, value?: any) {
    this.params = this.params ?? {};
    set(this.params, paramName, value);
    // this.params[paramName] = value;
  }

  @action
  removeParam(paramName: string) {
    this.params = this.params ?? {};
    remove(this.params, paramName);
    // delete this.params[paramName];
  }

  async save(): Promise<WorkoutTemplate> {
    return HttpBackend.post('/coach/program/template/workout', this.toJS()).then(() => this);
  }

  @computed
  get isNew(): boolean {
    return !this.id || this.id.length <= 0;
  }

  @computed
  get nameValid(): boolean {
    if (this.defaultName) {
      return this.defaultName.trim().length > 0;
    }
    return false;
  }

  @computed
  get valid(): boolean {
    return this.nameValid;
  }

  @computed
  get invalid(): boolean {
    return !this.valid;
  }

  @computed
  get tagMap(): any {
    return this.tags
      .map((t) => t.split(':'))
      .reduce((result: any, tag: Array<string>) => {
        const entry = result[tag[0]] || [];
        entry.push(tag[1]);
        result[tag[0]] = entry.sort();
        return result;
      }, {});
  }

  @computed
  get allParams(): PipelineParam[] {
    const processed = new Map<string, boolean>();
    return this.phaseConfigurations
      .flatMap((p) => p.allParams)
      .filter((p) => p.name !== 'weight')
      .filter((p) => {
        if (processed.has(p.name)) {
          return false;
        }
        processed.set(p.name, true);
        return true;
      })
      .sort((a, b) => {
        const aIndex = paramSort.indexOf(a.name) === -1 ? 1000 : paramSort.indexOf(a.name);
        const bIndex = paramSort.indexOf(b.name) === -1 ? 1000 : paramSort.indexOf(b.name);
        return aIndex - bIndex;
      });
  }

  execute(context: PipelineContext = new PipelineContext()): Promise<WorkoutResponse> {
    return Retry.tryTimes(() =>
      HttpBackend.post(`/coach/program/template/workout/execute`, {
        template: this.toJS(),
        context: context.toJS(),
      }).then((result) => new WorkoutResponse(result)),
    );
  }

  static find(params: WorkoutTemplateQueryRequest = {}): Promise<WorkoutTemplate[]> {
    return HttpBackend.get('/coach/program/template/workout', params).then((res) =>
      res.map((w) => new WorkoutTemplate(w)),
    );
  }

  static count(params: WorkoutTemplateQueryRequest = {}): Promise<number> {
    return HttpBackend.get('/coach/program/template/workout/count', params);
  }

  static list(params: WorkoutTemplateQueryRequest = {}): Promise<Array<WorkoutTemplate>> {
    return HttpBackend.get('/coach/program/template/workout/list', params).then((res) =>
      (res ?? []).map((r) => new WorkoutTemplate(r)),
    );
  }

  static get(workoutId: string): Promise<WorkoutTemplate | undefined> {
    return HttpBackend.get(`/coach/program/template/workout/${workoutId}`).then((res) =>
      res ? new WorkoutTemplate(res) : undefined,
    );
  }

  static getAll(workoutIds: Array<string>): Promise<Array<WorkoutTemplate>> {
    return Promise.all(workoutIds.map((w) => WorkoutTemplate.get(w))).then(
      (result) => result.filter((w) => !!w) as Array<WorkoutTemplate>,
    );
  }

  test(context?: PipelineContext, limit?: number): Promise<WorkoutResponse[]> {
    return Retry.tryTimes(() =>
      HttpBackend.post(
        `/coach/super-macro/template/completeness-check/workout/${this.id}?limit=${limit || ''}`,
        context?.toJS(),
      ).then((results) => results.map((res) => new WorkoutResponse(res))),
    );
  }
}
