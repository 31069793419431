/**
 * Created by neo on 29.10.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { WorkoutTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';
import { Modal, Tabs } from 'antd';
import { PhaseConfiguration } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/PhaseConfiguration';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Container } from 'reactstrap';
import { PhaseConfigurationView } from './PhaseConfigurationView';

export type WorkoutTemplateEditorTabProps = {
  workoutTemplate: WorkoutTemplate;
};

export const PhaseEditorTab: React.FC<WorkoutTemplateEditorTabProps> = observer(
  (props: WorkoutTemplateEditorTabProps) => {
    const { workoutTemplate } = props;

    const store = useLocalStore(
      (source) => ({
        activeTab: source.workoutTemplate.phaseConfigurations[0]?.id,
      }),
      props,
    );

    const handleTabChange = React.useCallback((tab) => (store.activeTab = tab), [store]);

    const handleEditTabs = React.useCallback(
      (targetKey, action) => {
        if (action === 'add') {
          const copy = (
            workoutTemplate.phaseConfigurations.find((c) => c.id === store.activeTab) ?? new PhaseConfiguration()
          ).copy();
          workoutTemplate.phaseConfigurations.push(copy);
          store.activeTab = copy.id;
        } else if (action === 'remove') {
          Modal.confirm({
            title: `Delete Phase Configuration?`,
            icon: <ExclamationCircleOutlined />,
            onOk() {
              workoutTemplate.phaseConfigurations = workoutTemplate.phaseConfigurations.filter(
                (p) => p.id !== targetKey,
              );
            },
          });
        }
        console.log('action', action, targetKey);
      },
      [store],
    );

    return (
      <Container>
        <Tabs
          type="editable-card"
          activeKey={store.activeTab}
          animated={false}
          onChange={handleTabChange}
          onEdit={handleEditTabs}
        >
          {workoutTemplate.phaseConfigurations.map((config) => (
            <Tabs.TabPane tab={config.friendlyTagString} key={config.id} closable>
              <PhaseConfigurationView phaseConfiguration={config} workoutTemplate={workoutTemplate} />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Container>
    );
  },
);
