/**
 * Created by neo on 30.09.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AbstractExercise } from '../../../../Model/Exercise/AbstractExercise';
import { Badge } from 'reactstrap';

export type ExerciseForceBadgesProps = {
  exercise: AbstractExercise;
};

export const ExerciseForceBadge: React.FC<ExerciseForceBadgesProps> = observer(
  ({ exercise }: ExerciseForceBadgesProps) => {
    return (
      <React.Fragment>
        {exercise.force ? (
          <React.Fragment>
            <Badge color="primary">{exercise.force}</Badge>
            <br />
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  },
);
