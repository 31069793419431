/**
 * Created by neo on 20.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Media } from '../../../../../../Model/Media/Media';
import { MediaAudioTrackEntry } from './MediaAudioTrackEntry';
import { Form, Input } from 'antd';
import { runInAction } from 'mobx';
import { SingleColRow } from '../../../../../../Components/SingleColRow';
import MediaService from '../../../../../../Services/MediaService';
import { MediaAudioTrack } from '../../../../../../Model/Media/MediaAudioTrack';

export type MediaAudioTrackTabProps = {
  media: Media;
};

export const MediaAudioTrackTab: React.FC<MediaAudioTrackTabProps> = observer(({ media }) => {
  const [uploading, setUploading] = useState(false);

  const handleAddAudioTrack = React.useCallback(() => {
    runInAction(() => media.addAudioTrack());
  }, [media]);

  const handleChangeAudioTrack = React.useCallback(
    ({ target: { files } }) => {
      console.log('file', files);
      setUploading(true);
      Promise.all(Array.from(files).map((file: any) => MediaService.uploadMedia(file)))
        .then((medias) =>
          medias.map(
            (media) =>
              new MediaAudioTrack({
                url: media.url,
                index: media.audioTracks.length,
                language: 'en',
                mimeType: media.mediaType,
                title: 'English',
                filename: media.name,
              }),
          ),
        )
        .then((audioTracks) => runInAction(() => (media.audioTracks = media.audioTracks.concat(audioTracks))))
        .finally(() => setUploading(false));
    },
    [media],
  );

  return (
    <React.Fragment>
      <SingleColRow>
        {media.audioTracks
          .slice()
          .sort((a, b) => a.index - b.index)
          .map((track) => (
            <MediaAudioTrackEntry key={track.index} media={media} track={track} />
          ))}
      </SingleColRow>
      <SingleColRow>
        <Form.Item label="Upload new audio track">
          <Input type="file" onChange={handleChangeAudioTrack} disabled={uploading} />
        </Form.Item>
      </SingleColRow>
    </React.Fragment>
  );
});
