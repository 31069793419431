/**
 *
 */
import * as React from 'react';
import { observer } from 'mobx-react';

const styles = {
  container: {
    height: '100vh',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 32,
  },
};

export type LoadingContainerProps = {
  text?: string;
};

export const LoadingContainer: React.FC<LoadingContainerProps> = observer(({ text }) => {
  return <div style={styles.container}>{text || 'Loading...'}</div>;
});
