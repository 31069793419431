/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { DefaultStageNodeWidget, StageNodeWidgetProps } from '../Default/DefaultStageNodeWidget';

export type ConcatWidgetProps = {} & StageNodeWidgetProps;

export const ConcatWidget: React.FC<ConcatWidgetProps> = observer(({ node, engine, size }: ConcatWidgetProps) => {
  return <DefaultStageNodeWidget engine={engine} node={node} size={size} disableEdit={true} />;
});
