/**
 * Created by neo on 13.11.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { ExploreEntryModalMediaField } from '../../../../Coach/Explore/ExploreEntryModalMediaField';
import { Media } from '../../../../../Model/Media/Media';
import { runInAction } from 'mobx';
import { MediaEditModal } from '../../../../MetaData/Media/MediaEditModal/MediaEditModal';
import { message } from 'antd';

export type RouteChallengeImagesProps = {
  challenge: RouteChallenge;
};

export const RouteChallengeImages: React.FC<RouteChallengeImagesProps> = observer(({ challenge }) => {
  const [selectedMedia, setSelectedMedia] = useState<Media | undefined>();

  const handleRemoveImage = React.useCallback(
    (media: Media) => {
      runInAction(() => (challenge.images = challenge.images.filter((v) => v.id !== media.id)));
    },
    [challenge],
  );

  const handleImageUploaded = React.useCallback(
    (media: Media[]) => {
      runInAction(() => (challenge.images = challenge.images.concat(media)));
    },
    [challenge],
  );

  const handleClickMedia = React.useCallback((media: Media) => {
    setSelectedMedia(media);
  }, []);

  const handleCloseMedia = React.useCallback(() => setSelectedMedia(undefined), []);

  const handleMediaSave = React.useCallback(
    (media: Media) => {
      runInAction(() => {
        if (challenge.images.some((v) => v.id === media.id)) {
          const index = challenge.images.findIndex((v) => v.id === media.id);
          if (index !== -1) {
            challenge.images[index] = media;
          }
        }
      });

      challenge.save().then(() => message.success('Challenge saved'));
    },
    [challenge],
  );

  return (
    <React.Fragment>
      <div style={{ display: 'flex' }}>
        {challenge.images.concat([undefined] as any).map((media) => (
          <div key={media?.id} style={{ marginLeft: 8 }}>
            <ExploreEntryModalMediaField
              allowedTypes={['image/jpeg', 'image/png', 'image/gif']}
              onRemove={handleRemoveImage}
              onUploaded={handleImageUploaded}
              onClick={handleClickMedia}
              media={media}
            />
          </div>
        ))}
      </div>
      {selectedMedia && <MediaEditModal media={selectedMedia} onCancel={handleCloseMedia} onSave={handleMediaSave} />}
    </React.Fragment>
  );
});
