/**
 * Created by neo on 12.04.22.
 */
import {
  MindfulnessProgramTemplateDayUnit,
  MindfulnessProgramTemplateDayUnitJson,
} from './MindfulnessProgramTemplateDayUnit';
import { Media, MediaJson } from '../../../Media/Media';
import { observable } from 'mobx';
import { Instructor, InstructorJson } from '../../../Explore/Instructor';

export type MindfulnessProgramTemplateDayUnitVideoJson = MindfulnessProgramTemplateDayUnitJson & {
  videos: MediaJson[];
  instructor: InstructorJson;
  spotifyPlaylistLink?: string;
};

export class MindfulnessProgramTemplateDayUnitVideo extends MindfulnessProgramTemplateDayUnit {
  @observable.ref
  instructor: Instructor = new Instructor();
  @observable
  videos: Media[] = [];
  @observable
  spotifyPlaylistLink?: string;

  constructor(json?: Partial<MindfulnessProgramTemplateDayUnitVideoJson>) {
    super(Object.assign({}, json ?? {}, { type: 'video' }));
    if (json) {
      this.videos = (json.videos ?? []).map((a) => new Media(a));
      this.instructor = new Instructor(json.instructor);
      this.spotifyPlaylistLink = json.spotifyPlaylistLink;
    }
  }

  toJS(newId: boolean = false): MindfulnessProgramTemplateDayUnitVideoJson {
    return Object.assign(super.toJS(newId), {
      instructor: this.instructor.toJS(),
      videos: this.videos.map((a) => a.toJS()),
      activityId: this.activityIdentifier,
      spotifyPlaylistLink: this.spotifyPlaylistLink,
    });
  }
}
