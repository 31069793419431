/**
 * Created by neo on 15.07.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExerciseBlockStage, TrackingKeyConfig } from '../../../../../../../Model/Coach/Stages/ExerciseBlockStage';
import styled from '@emotion/styled';
import { TrackingKeysList } from '../../../../../../../Model/ProgramPortfolio/TrackingKeys';
import { runInAction } from 'mobx';
import { PhaseStage } from '../../../../../../../Model/Coach/Stages/PhaseStage';

export type TrackingKeySelectionProps = {
  stage: ExerciseBlockStage | PhaseStage;
  trackingKey: TrackingKeyConfig;
};

const StyledSelect = styled.select`
  flex: 1;
  color: black;
  margin-right: 4px;
`;

export const TrackingKeySelection: React.FC<TrackingKeySelectionProps> = observer(
  ({ trackingKey, stage }) => {
    const usedTrackingKeys = stage.config.trackingKeys?.map((t) => t.key) || [];

    const selectableTrackingKeys = TrackingKeysList.filter((t) => usedTrackingKeys.findIndex((t1) => t1 === t) === -1);

    const handleChange = React.useCallback(
      ({ target: { value } }) => {
        console.log('change', value, trackingKey.key);
        runInAction(() => (trackingKey.key = value));
      },
      [trackingKey],
    );

    return (
      <StyledSelect value={trackingKey.key} onChange={handleChange}>
        {TrackingKeysList.map((key) => (
          <option value={key} key={key} disabled={!selectableTrackingKeys.find((t) => t === key)}>
            {key}
          </option>
        ))}
      </StyledSelect>
    );
  },
);
