/**
 *
 * Created by neo on 18.01.17.
 */
import {
  toJS,
  action,
  observable,
  computed,
  onBecomeObserved,
  onBecomeUnobserved,
  IReactionDisposer,
  reaction,
} from 'mobx';
import * as mobxUtils from 'mobx-utils';
import { ExerciseBlockSet } from '../ProgramPortfolio/ExerciseBlockSet';
import { ExerciseBlockLog } from './ExerciseBlockLog';
import { HttpBackend } from '../../Services/Http/HttpBackend';

import { v4 as UUID } from 'uuid';
import { Exercise } from '../Exercise/Exercise';
import { BaseTrackingKey, PlanableTrackingKey } from '../ProgramPortfolio/TrackingKeys';
import dayjs from 'dayjs';
import { ExerciseSetPause } from './ExerciseSetPause';

export class ExerciseSet extends ExerciseBlockSet {
  exerciseBlockLog: ExerciseBlockLog;
  @observable
  setId: string = UUID();
  @observable
  workoutLogId?: string = undefined;
  @observable
  startDateTime?: Date;
  @observable
  endDateTime?: Date;
  @observable
  caloriesBurnt: number = 0;
  @observable
  elapsedTime: number = 0;
  @observable
  elapsedBreak: number = 0;
  @observable
  met = 5.5;
  @observable pauses: ExerciseSetPause[] = [];

  elapsedDisposer?: IReactionDisposer;
  elapsedBreakDisposer?: IReactionDisposer;

  constructor(exerciseBlockLog: ExerciseBlockLog, json?: any) {
    super(undefined, json);
    this.exerciseBlockLog = exerciseBlockLog;
    if (json) {
      this.setId = json.setId || UUID();
      this.workoutLogId = json.workoutLogId;
      this.startDateTime = json.startDateTime ? new Date(json.startDateTime) : undefined;
      this.endDateTime = json.endDateTime ? new Date(json.endDateTime) : undefined;
      this.pauses = (json.pauses || []).map((p) => new ExerciseSetPause(p));
      this.caloriesBurnt = json.caloriesBurnt || 0;
      this.met = json.met || 5.5;
    }

    onBecomeObserved(this, 'elapsedTime', this.startElapsedTimeInterval);
    onBecomeUnobserved(this, 'elapsedTime', this.stopElapsedTimeInterval);

    onBecomeObserved(this, 'elapsedBreak', this.startElapsedBreakTimeInterval);
    onBecomeUnobserved(this, 'elapsedBreak', this.stopElapsedBreakTimeInterval);
  }

  toJS(newId: boolean = false): any {
    const json = super.toJS();
    return Object.assign(json, {
      setId: newId ? UUID() : this.setId,
      workoutLogId: this.workoutLogId,
      startDateTime: this.startDateTime?.toISOString(),
      endDateTime: this.endDateTime?.toISOString(),
      caloriesBurnt: this.caloriesBurnt,
      pauses: this.pauses.map((p) => p.toJS()),
    });
  }

  toDto() {
    return Object.assign(super.toJS(), {
      setId: this.setId,
      workoutLogId: this.workoutLogId,
      startDateTime: this.startDateTime?.toISOString(),
      endDateTime: this.endDateTime?.toISOString(),
      caloriesBurnt: this.caloriesBurnt,
      exerciseId: this.exercise?.exerciseIdentifier.toJS(),
      met: this.exercise?.met,
      phaseId: this.exerciseBlockLog.phaseId,
      exerciseBlockId: this.exerciseBlockLog.exerciseBlockId,
    });
  }

  startElapsedTimeInterval = () => {
    this.elapsedDisposer && this.elapsedDisposer();
    this.elapsedDisposer = reaction(
      () => mobxUtils.now(250),
      (timestamp: number) => {
        if (this.isRunning && timestamp) {
          this.elapsedTime = timestamp - (this.startDateTime?.valueOf() ?? 0);
        }
      },
      { name: 'ExerciseSet::elapsedTime' },
    );
  };

  stopElapsedTimeInterval = () => {
    this.elapsedDisposer && this.elapsedDisposer();
    this.elapsedDisposer = undefined;
  };

  startElapsedBreakTimeInterval = () => {
    this.elapsedBreakDisposer && this.elapsedBreakDisposer();
    this.elapsedBreakDisposer = reaction(
      () => mobxUtils.now(250),
      (timestamp: number) => {
        if (this.isFinished && (!this.nextSet || !this.nextSet.isStarted)) {
          this.elapsedBreak = timestamp - (this.endDateTime?.valueOf() ?? 0);
        }
      },
      { name: 'ExerciseSet::elapsedBreak' },
    );
  };

  stopElapsedBreakTimeInterval = () => {
    this.elapsedBreakDisposer && this.elapsedBreakDisposer();
    this.elapsedBreakDisposer = undefined;
  };

  @action
  fixValues(key: BaseTrackingKey) {
    const value = this.values.get(key) || 0;
    const minKey = `MIN_${key}` as PlanableTrackingKey;
    const maxKey = `MAX_${key}` as PlanableTrackingKey;
    const minValue = this.values.get(minKey) || this.values.get(maxKey) || value;
    const maxValue = this.values.get(maxKey) || this.values.get(minKey) || value;
    this.values.set(minKey, minValue);
    this.values.set(maxKey, maxValue);
    this.values.set(key, value);
  }

  @action
  stop(endTimestamp: number = Date.now()) {
    this.changeIfInvalid('WEIGHT');
    this.changeIfInvalid('REPETITIONS');

    this.save();

    if (this.isRunning) {
      this.endDateTime = new Date();
    }
  }

  calculateCalories(bmr24: number): number {
    const { nextSetChronological } = this;

    const met =
      this.met ?? ((this.exerciseBlockLog.exercise?.met ?? 0) > 0 ? this.exerciseBlockLog.exercise?.met ?? 5.5 : 5.5);
    const breakCalories = nextSetChronological
      ? this.calculateCaloriesTime(bmr24, 5.5, Math.min(this.breakTime, 600000) / 1000.0)
      : 0;
    const exerciseCalories = this.calculateCaloriesTime(bmr24, met, this.durationSeconds);
    return exerciseCalories + breakCalories;
  }

  calculateCaloriesTime(bmr24: number, met: number, durationSeconds: number): number {
    const { loggedCalories } = this;
    if (loggedCalories && loggedCalories > 0) {
      return loggedCalories;
    }
    const timeInHours = Math.max(1, durationSeconds) / 3600.0;

    return Math.round(Math.max(1, Math.round(bmr24 * (met || 5.5) * timeInHours)) * 1.2);
  }

  @computed
  get calories(): number {
    return Math.round(this.loggedCalories || this.caloriesBurnt);
  }

  @computed
  get loggedCalories(): number | undefined {
    return this.values.get('CALORIES');
  }

  @computed
  get isLastSet(): boolean {
    const { index, exerciseBlockLog } = this;
    return index + 1 >= exerciseBlockLog.sets.length;
  }

  @computed
  get prevSetChronological(): ExerciseSet | undefined {
    const { chronologicalIndex } = this;
    if (chronologicalIndex > 0) {
      return this.exerciseBlockLog.workoutLog.sets[chronologicalIndex - 1];
    }
    return undefined;
  }

  @computed
  get prevSet(): ExerciseSet | undefined {
    const { index } = this;
    if (0 === index) {
      return this.exerciseBlockLog.prevBlockLog ? this.exerciseBlockLog.prevBlockLog.prevSet : undefined;
    }
    return this.exerciseBlockLog.sets[index - 1];
  }

  @computed
  get nextSetChronological(): ExerciseSet | undefined {
    const { chronologicalIndex } = this;
    if (chronologicalIndex !== -1) {
      const {
        exerciseBlockLog: {
          workoutLog: { sets },
        },
      } = this;
      const nextIndex = chronologicalIndex + 1;
      return nextIndex < sets.length ? sets[nextIndex] : undefined;
    }
    return undefined;
  }

  @computed
  get nextSet(): ExerciseSet | undefined {
    const { index, isLastSet } = this;
    if (isLastSet) {
      return this.exerciseBlockLog.nextBlockLog ? this.exerciseBlockLog.nextBlockLog.followingSet : undefined;
    }
    const nextIndex = index + 1;
    return nextIndex < this.exerciseBlockLog.sets.length ? this.exerciseBlockLog.sets[nextIndex] : undefined;
  }

  @computed
  get isCompleted(): boolean {
    return this.isFinished && (!this.nextSet || this.nextSet.isStarted);
  }

  @computed
  get isFinished(): boolean {
    return !!this.endDateTime;
  }

  @computed
  get isStarted(): boolean {
    return !!this.startDateTime;
  }

  @computed
  get isRunning(): boolean {
    return this.isStarted && !this.isFinished;
  }

  @computed
  get duration(): number {
    if (this.isRunning) {
      return this.elapsedTime;
    } else if (this.endDateTime) {
      const value = this.values.get('DURATION');
      if (value) {
        return value * 1000;
      }
      return Math.abs((this.endDateTime ?? new Date()).valueOf() - (this.startDateTime ?? new Date()).valueOf());
    }
    return 0;
  }

  @computed
  get durationSeconds(): number {
    return this.duration / 1000;
  }

  @computed
  get durationFormatted(): string {
    const { duration } = this;
    if (duration > 0) {
      if (duration > 359999) {
        return dayjs.utc(duration).format('HH:mm:ss');
      }
      return dayjs.utc(duration).format('mm:ss');
    }
    return '00:00';
  }

  @computed
  get breakTime(): number {
    if (this.isFinished) {
      if (this.exerciseBlockLog.workoutLog.active) {
        if (!this.nextSet || !this.nextSet.isStarted) {
          return this.elapsedBreak;
        }
        const value = this.values.get('BREAK');
        if (value) {
          return value * 1000;
        }
        return Math.abs(
          (this.nextSet.startDateTime ?? new Date()).valueOf() - (this.endDateTime ?? new Date()).valueOf(),
        );
      } else if (this.nextSet) {
        const value = this.values.get('BREAK');
        if (value) {
          return value * 1000;
        }
        return Math.abs(
          (this.nextSet.startDateTime ?? new Date()).valueOf() - (this.endDateTime ?? new Date()).valueOf(),
        );
      }
    }
    return 0;
  }

  @computed
  get breakTimeSeconds(): number {
    return this.breakTime / 1000;
  }

  @computed
  get breakTimeFormatted(): string {
    const { breakTime } = this;
    if (breakTime > 0) {
      if (breakTime > 359999) {
        return dayjs(breakTime).format('HH:mm:ss');
      }
      return dayjs(breakTime).format('mm:ss');
    }
    return '00:00';
  }

  @computed
  get chronologicalIndex(): number {
    return this.exerciseBlockLog.workoutLog.sets.findIndex((s) => s.setId === this.setId);
  }

  @computed
  get index(): number {
    return this.exerciseBlockLog ? this.exerciseBlockLog.sets.findIndex((s) => s.setId === this.setId) : -1;
  }

  @computed
  get setNr(): number {
    return this.index + 1;
  }

  @computed
  get tons(): number {
    const weight = this.values.get('WEIGHT') || 0;
    const reps = this.values.get('REPETITIONS') || 0;
    return (reps * weight) / 1000;
  }

  @computed
  get repetitions(): number {
    return this.values.get('REPETITIONS') || 0;
  }

  @computed
  get repetitionDuration(): number {
    return this.duration / this.repetitions / 1000.0;
  }

  @computed
  get exercise(): Exercise | undefined {
    return this.exerciseBlockLog.exercise;
  }

  changeIfInvalid(key: BaseTrackingKey) {
    const value = this.values.get(key);
    if (!value) {
      const minKey = `MIN_${key}` as PlanableTrackingKey;
      const maxKey = `MAX_${key}` as PlanableTrackingKey;
      const minValue = this.values.get(minKey) || 0;
      const maxValue = this.values.get(maxKey) || 0;
      if (minValue > 0) {
        this.setKey(key, minValue);
      } else if (maxValue > 0) {
        this.setKey(key, maxValue);
      }
    }
  }

  save() {
    if (this.workoutLogId && this.endDateTime) {
      HttpBackend.post('/activity/workoutlog/changeSet', {
        workoutLogId: this.workoutLogId,
        setId: this.setId,
        values: toJS(this.values),
      });
    }
  }
}
