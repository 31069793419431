/**
 * Created by andreaskarantzas on 04.05.20.
 */

import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { ElementRenderer } from './Components/ElementRenderer';
import clsx from 'clsx';
import { ThemeConfig } from '../../../../Config/Theme';
import FeedEntryViewStore from '../../FeedEntryViewStore';
import { ArticleEntry } from '../../../../Model/Explore/ArticleEntry';

export type NativeFeedEditorProps = {
  entry: ArticleEntry;
  store: FeedEntryViewStore;
  lang?: string;
};

export const NativeFeedStructureView: React.FC<NativeFeedEditorProps> = observer(({ entry, store, lang }) => {
  const { classes } = useStyles();
  /**
   * used here instead of inside the component to force a re-render
   * It is a hack to update the snapshot created initially or during dragging
   */
  const [hoveredKey, setHoveredKey] = React.useState<string | undefined>(undefined);

  const handleMouseOver = React.useCallback((key: string) => {
    setHoveredKey(key);
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    setHoveredKey(undefined);
  }, []);

  return (
    <Droppable droppableId="nativeFeedStructureView">
      {(provided) => (
        <div
          {...provided.droppableProps}
          className={classes.container}
          ref={provided.innerRef}
          id="nativeFeedStructureViewDroppableArea"
        >
          {entry.content.map((component, index) => (
            <Draggable key={component.key} draggableId={component.key} index={index}>
              {(provided, snapshot) => (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  id={`nativeFeedStructureView_${index}_${component.type}`}
                  className={clsx(
                    snapshot.isDragging ? classes.draggingContainer : null,
                    hoveredKey === component.key ? classes.hoverContainer : classes.innerContainer,
                  )}
                  onMouseOver={() => handleMouseOver(component.key)}
                  onMouseLeave={handleMouseLeave}
                >
                  <ElementRenderer
                    entry={entry}
                    component={component}
                    store={store}
                    index={index}
                    isHover={hoveredKey === component.key}
                    onDelete={handleMouseLeave}
                    lang={lang}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {entry.content.length === 0 && (
            <Typography className={classes.text} variant="subtitle1" id="dropComponentsTitle">
              Drop components here
            </Typography>
          )}
        </div>
      )}
    </Droppable>
  );
});

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 4,
    paddingTop: 8,
    paddingBottom: 200,
  },
  innerContainer: {
    padding: '8px 0px',
    position: 'relative',
  },
  hoverContainer: {
    borderRadius: 8,
    border: `1px solid ${ThemeConfig.Colors.primaryColor}`,
    padding: '8px 0',
    marginTop: 4,
    position: 'relative',
  },
  text: {
    textAlign: 'center',
    paddingTop: '45%',
  },
  draggingContainer: {
    borderRadius: 8,
    border: `1px dotted ${ThemeConfig.Colors.charcoalGrey}`,
    background: 'lightsteelblue',
    marginTop: 4,
    position: 'relative',
  },
}));
