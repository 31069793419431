// File: application/src/App/View/MetaData/Translations/TranslationsListScreen.tsx
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Layout, Space, Button, Input, Modal, Select, Checkbox, Switch, Form, Row, Col, Card } from 'antd';
import { PageResult } from '../../../Model/PageResult';
import { MergedTranslation } from '../../../Model/Translation/MergedTranslation';
import { Pager } from '../../../Components/Pager';
import { TranslationTable } from './TranslationTable';
import { TranslateMissingTranslationsButton } from './TranslateMissingTranslationsButton';
import { availableLanguages } from '../../../Utils/availableLanguages';
import { ModalAddKeyContent } from './ModalAddKeyContent';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebouncedEffect } from '../../../Utils/useDebouncedEffect';
import AIHelper from './AIHelper';
import { TranslationKey } from '../../../Model/Translation/TranslationKey';

const { Header, Content } = Layout;

export const TranslationsListScreen: React.FC = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const namespace = searchParams.get('namespace') || undefined;
  const searchQuery = searchParams.get('query') || '';
  const page = Number(searchParams.get('page') || '0');

  const [translations, setTranslations] = useState<PageResult<MergedTranslation>>(new PageResult());
  const [query, setQuery] = useState(searchQuery);
  const [showMissing, setShowMissing] = useState(false);
  const [namespaces, setNamespaces] = useState<string[]>([]);
  const [onlyInValue, setOnlyValue] = useState(false);
  const [caseSensitive, setCaseSensitive] = useState(false);
  const [aiHelperVisible, setAiHelperVisible] = useState(false);

  const translationRequest = React.useMemo(
    () => ({
      query,
      namespace,
      onlyInValue,
      caseSensitive,
    }),
    [query, namespace, onlyInValue, caseSensitive],
  );

  useEffect(() => {
    MergedTranslation.listNamespaces().then((res) => setNamespaces(res.sort((a, b) => a.localeCompare(b))));
  }, []);

  useDebouncedEffect(() => {
    setTranslations(new PageResult());
    PageResult.execute(
      MergedTranslation.list({ ...translationRequest, page, size: 20 }),
      MergedTranslation.count(translationRequest),
      page,
      20,
    ).then((res) => setTranslations(res));
  }, [translationRequest, page]);

  const handleChangeQuery = React.useCallback(
    ({ target: { value } }) => {
      setQuery(value);
      searchParams.set('page', '0');
      searchParams.set('query', encodeURIComponent(value || ''));
      navigate({
        pathname: location.pathname,
        search: `?${searchParams.toString()}`,
      });
    },
    [searchParams, navigate, location],
  );

  const handleAddKey = React.useCallback(() => {
    let key = '';
    let ns = namespace || 'coach-app';
    Modal.confirm({
      content: (
        <ModalAddKeyContent
          defaultNamespace={namespace}
          onTranslationKeyChange={(newKey) => (key = newKey)}
          onNamespaceChange={(newNs) => (ns = newNs)}
        />
      ),
      okText: 'Add',
      onOk() {
        const cleanKey = key.trim();
        if (cleanKey) {
          TranslationKey.findOne(cleanKey, ns).then((res) => {
            if (!res) {
              Promise.all(
                availableLanguages.map((language) =>
                  new TranslationKey({
                    key: cleanKey,
                    language: language.value,
                    namespace: ns,
                  }).save(),
                ),
              ).then((keys) =>
                setTranslations(
                  (prev) =>
                    new PageResult(
                      Object.assign({}, prev.toJS(), {
                        content: [
                          new MergedTranslation({
                            id: cleanKey,
                            keys: keys.map((k) => k.toJS()),
                          }),
                          ...prev.content,
                        ],
                      }),
                    ),
                ),
              );
            }
          });
        }
      },
    });
  }, [namespace]);

  const handleShowMissing = React.useCallback((checked: boolean) => {
    setShowMissing(checked);
  }, []);

  const handlePage = React.useCallback(
    (newPage: number) => {
      searchParams.set('page', newPage.toString());
      navigate({
        pathname: location.pathname,
        search: `?${searchParams.toString()}`,
      });
    },
    [searchParams, location, navigate],
  );

  const handleChangeNamespace = React.useCallback(
    (ns?: string) => {
      searchParams.set('namespace', ns || '');
      navigate({
        pathname: location.pathname,
        search: `?${searchParams.toString()}`,
      });
    },
    [searchParams, location, navigate],
  );

  const toggleOnlyInValue = React.useCallback(
    ({ target: { checked } }) => {
      setOnlyValue(!onlyInValue);
    },
    [onlyInValue],
  );

  const toggleCaseSensitive = React.useCallback(
    ({ target: { checked } }) => {
      setCaseSensitive(!caseSensitive);
    },
    [caseSensitive],
  );

  return (
    <Layout style={{ height: '100vh' }}>
      <Header style={{ padding: '0 16px', background: '#fff' }}>
        <Row justify="space-between" align="middle">
          <Col>
            <Space size="middle">
              <Select value={namespace} style={{ minWidth: 360 }} onChange={handleChangeNamespace} allowClear>
                <Select.Option value="">&lt;all namespaces&gt;</Select.Option>
                {namespaces.map((ns) => (
                  <Select.Option key={ns} value={ns}>
                    {ns}
                  </Select.Option>
                ))}
              </Select>
              <Button type="primary" onClick={handleAddKey}>
                Add Key
              </Button>
              <TranslateMissingTranslationsButton request={translationRequest} />
              <Switch
                checkedChildren="Show only missing"
                unCheckedChildren="Show all"
                checked={showMissing}
                onChange={handleShowMissing}
              />
            </Space>
          </Col>
          <Col>
            <Button type="default" onClick={() => setAiHelperVisible(true)}>
              AI Helper
            </Button>
          </Col>
        </Row>
      </Header>
      <Content style={{ margin: '16px' }}>
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Card>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Input placeholder="Search" value={query} onChange={handleChangeQuery} allowClear />
              </Col>
              <Col xs={24} sm={12}>
                <Form layout="inline">
                  <Form.Item>
                    <Checkbox checked={onlyInValue} onChange={toggleOnlyInValue}>
                      Search in value only
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Checkbox checked={caseSensitive} onChange={toggleCaseSensitive}>
                      Case Sensitive
                    </Checkbox>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Card>
          <Card>
            <Pager page={translations} onPage={handlePage} />
            <TranslationTable mergedTranslations={translations.content} namespaces={namespaces} />
            <Pager page={translations} onPage={handlePage} />
          </Card>
        </Space>
      </Content>
      <AIHelper visible={aiHelperVisible} onClose={() => setAiHelperVisible(false)} translations={translations} />
    </Layout>
  );
});
