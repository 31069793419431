/**
 * Created by neo on 13.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Gym } from '../../../Model/Gym/Gym';
import { observable, runInAction } from 'mobx';
import { AnalyticsData, AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import dayjs from 'dayjs';
import { UserStatisticsWeekTable } from './UserStatisticsWeekTable';
import { Alert } from 'reactstrap';
import { allUsersQuery, hugeUserQueries, usersQuery } from '../Queries/queryHugeUserTable';

const hugeUserQuery = (gym: Gym | undefined, startDate: Date, endDate: Date): Promise<AnalyticsRow[]> => {
  const query =
    hugeUserQueries.reduce(
      (acc, nextQuery, index) => acc + `LEFT JOIN (${nextQuery}) q${index}\n ON users.user_id = q${index}.user_id\n`,
      `SELECT * FROM (${gym ? usersQuery : allUsersQuery}) users\n`,
    ) + 'ORDER BY users.event_timestamp';

  return AnalyticsData.query({
    query,
    parameters: Object.assign(
      {
        gym_start_date: {
          value: gym ? dayjs(gym.createdAt).format('YYYYMMDD') : '20210327',
        },
        start_date: {
          value: dayjs(startDate).format('YYYYMMDD'),
        },
        end_date: {
          value: dayjs(endDate).format('YYYYMMDD'),
        },
      },
      gym
        ? {
            gym_id: {
              value: gym.id,
            },
          }
        : {},
    ) as any,
  });
};

export type WeekResult = {
  startDate: Date;
  endDate: Date;
  data: AnalyticsRow[];
};

export type UserStatisticsTablesProps = {
  gym?: Gym;
};

export const UserStatisticsTables: React.FC<UserStatisticsTablesProps> = observer(({ gym }) => {
  const [store] = useState(() =>
    observable({
      selectedWeekIndex: 0,
      loading: false,
      weekResult: new Array<WeekResult>(),
    }),
  );

  useEffect(() => {
    runInAction(() => (store.weekResult = []));
    if (gym) {
      runInAction(() => (store.loading = true));
      const startDate = dayjs().subtract(6, 'days').toDate();
      const endDate = new Date();
      const weekBeforeEnd = dayjs(startDate).subtract(1, 'days').toDate();
      const weekBeforeStart = dayjs(weekBeforeEnd).subtract(6, 'days').toDate();

      Promise.all([hugeUserQuery(gym, startDate, endDate), hugeUserQuery(gym, weekBeforeStart, weekBeforeEnd)])
        .then(([currentWeek, previousWeek]) =>
          runInAction(() => {
            store.selectedWeekIndex = 0;
            store.weekResult.push({
              startDate,
              endDate,
              data: currentWeek,
            });

            store.weekResult.push({
              startDate: weekBeforeStart,
              endDate: weekBeforeEnd,
              data: previousWeek,
            });
          }),
        )
        .finally(() => runInAction(() => (store.loading = false)));
    }
  }, [store, gym]);

  const handleLoadWeek = React.useCallback(
    (weekIndex: number) => {
      const existing = store.weekResult[weekIndex];
      const existingBefore = store.weekResult[weekIndex + 1];

      if (!existing) {
        const previous = store.weekResult[weekIndex - 1];
        if (previous && gym) {
          runInAction(() => {
            store.selectedWeekIndex = weekIndex;
            store.loading = true;
          });

          const nextDate = dayjs(previous.startDate).subtract(1, 'day');
          const endDate = nextDate.toDate();
          const startDate = nextDate.subtract(6, 'days').toDate();

          const weekBeforeEnd = dayjs(startDate).subtract(1, 'days').toDate();
          const weekBeforeStart = dayjs(weekBeforeEnd).subtract(6, 'days').toDate();

          Promise.all([hugeUserQuery(gym, startDate, endDate), hugeUserQuery(gym, weekBeforeStart, weekBeforeEnd)])
            .then(([currentWeek, previousWeek]) =>
              runInAction(() => {
                store.weekResult.splice(weekIndex, 0, {
                  startDate,
                  endDate,
                  data: currentWeek,
                });

                store.weekResult.splice(weekIndex + 1, 0, {
                  startDate: weekBeforeStart,
                  endDate: weekBeforeEnd,
                  data: previousWeek,
                });
              }),
            )
            .finally(() => runInAction(() => (store.loading = false)));
        }
      } else if (!existingBefore) {
        runInAction(() => (store.selectedWeekIndex = weekIndex));
        if (gym) {
          const nextDate = dayjs(existing.startDate).subtract(1, 'day');
          const endDate = nextDate.toDate();
          const startDate = nextDate.subtract(6, 'days').toDate();

          hugeUserQuery(gym, startDate, endDate)
            .then((data) =>
              runInAction(() => {
                store.weekResult.splice(weekIndex + 1, 0, {
                  startDate,
                  endDate,
                  data,
                });
              }),
            )
            .finally(() => runInAction(() => (store.loading = false)));
        }
      } else {
        runInAction(() => (store.selectedWeekIndex = weekIndex));
      }
    },
    [store, gym],
  );

  return (
    <React.Fragment>
      {store.loading ? (
        <Alert color="info">Loading Data... Please wait</Alert>
      ) : (
        <UserStatisticsWeekTable
          gym={gym}
          selectedWeekIndex={store.selectedWeekIndex}
          weekResults={store.weekResult}
          onLoadWeek={handleLoadWeek}
        />
      )}
    </React.Fragment>
  );
});
