/**
 * Created by neo on 24.09.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Input, Modal } from 'antd';
import { Col, Row } from 'reactstrap';
import { runInAction } from 'mobx';
import { MindfulnessProgramTemplatePhase } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplatePhase';
import { TranslationInputArray } from '../../../../../../Components/Translation/TranslationInputArray';

const typeNamesDe = {
  skill: 'Skill',
  engage: 'Engage with it',
  feel: 'Feel it',
};

const typeNamesEn = {
  skill: 'Skill',
  engage: 'Engage with it',
  feel: 'Feel it',
};

export type PhaseEditModalProps = {
  phase: MindfulnessProgramTemplatePhase;
  index: number;
  onClose?: () => any;
};

export const PhaseEditModal: React.FC<PhaseEditModalProps> = observer(
  ({ phase, index, onClose }: PhaseEditModalProps) => {
    // useEffect(
    //   () =>
    //     reaction(
    //       () => phase.type,
    //       (type) => {
    //         const german = phase.name.find((n) => n.lang === 'de');
    //         const english = phase.name.find((n) => n.lang === 'en');
    //         const germanName = typeNamesDe[type];
    //         const englishName = typeNamesEn[type];
    //         if (germanName) {
    //           if (german) {
    //             runInAction(() => (german.value = germanName));
    //           } else {
    //             runInAction(() => phase.name.push(new LocalizedValue({ lang: 'de', value: germanName })));
    //           }
    //         }
    //
    //         if (englishName) {
    //           if (english) {
    //             runInAction(() => (english.value = englishName));
    //           } else {
    //             runInAction(() => phase.name.push(new LocalizedValue({ lang: 'en', value: englishName })));
    //           }
    //         }
    //       },
    //       { fireImmediately: true },
    //     ),
    //   [phase],
    // );

    const handleChangeType = React.useCallback(
      ({ target: { value } }) => {
        runInAction(() => (phase.type = value.trim().replace(/\s/, '').toLowerCase()));
      },
      [phase],
    );

    return (
      <Modal
        width={1920}
        style={{ top: 20 }}
        open={true}
        title={`Edit ${phase.type} Cycle ${index + 1}`}
        onOk={onClose}
        onCancel={onClose}
      >
        <Row>
          <Col>
            <Input value={phase.type} onChange={handleChangeType} />
          </Col>
          <Col>
            <TranslationInputArray entity={phase} field="name" label="Name"  />
          </Col>
          <Col>
            <TranslationInputArray
              type="textarea"
              entity={phase}
              field="description"
              label="Description"
            />
          </Col>
        </Row>
      </Modal>
    );
  },
);
