import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { GymCoachSubscription } from '../../../../Model/Subscription/GymCoachSubscription';
import { Gym } from '../../../../Model/Gym/Gym';
import { Layout, Card, Row, Col, Typography, Button, Popconfirm, Space, Badge, message } from 'antd';
import { useParams, useNavigate, Route, Routes } from 'react-router-dom';
import { GymViewScreenTabs } from './GymViewScreenTabs';
import { useRootStore } from '../../../../Store/useRootStore';
import { GymInfoToCopy } from './GymBasicInfoTab/GymInfoToCopy';

const { Content } = Layout;
const { Title, Text } = Typography;

function subscriptionColor(subscription?: GymCoachSubscription) {
  if (subscription?.valid) {
    if (subscription.plan === 'standard') return 'green';
    if (subscription.plan === 'engagement') return 'gold';
    if (subscription.plan === 'move') return 'default';
    if (subscription.plan === 'academy') return 'blue';
    return 'geekblue';
  }
  return 'red';
}

export const GymViewScreen: React.FC = observer(() => {
  const { gymId } = useParams();
  const { authentication } = useRootStore();
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);
  const [subscription, setSubscription] = useState<GymCoachSubscription | undefined>(undefined);
  const [gym, setGym] = useState(new Gym());
  const isNew = !gymId || gymId === 'new';

  const fetchCurrentSubscription = useCallback(() => {
    if (gymId && gymId !== 'new') {
      GymCoachSubscription.current({ gymId }).then(setSubscription);
    } else {
      setSubscription(undefined);
    }
  }, [gymId]);

  useEffect(() => {
    fetchCurrentSubscription();
  }, [gymId, fetchCurrentSubscription]);

  useEffect(() => {
    const handler = () => fetchCurrentSubscription();
    window.addEventListener('subscription-updated', handler);
    return () => window.removeEventListener('subscription-updated', handler);
  }, [fetchCurrentSubscription]);

  useEffect(() => {
    setLoaded(false);
    if (gymId && gymId !== 'new') {
      Gym.get(gymId)
        .then((result) => setGym(result ?? new Gym()))
        .finally(() => setLoaded(true));
    } else {
      setGym(new Gym());
      setLoaded(true);
    }
  }, [gymId]);

  const handleSave = useCallback(() => {
    gym
      .save()
      .then(() => {
        navigate(`/infrastructure/gym/${gym.id}`, { replace: true });
        message.success('Gym saved');
      })
      .catch(() => message.error('Error saving gym'));
  }, [gym, navigate]);

  const handleTabChange = useCallback(
    (key: string) => {
      navigate(`${key}`, { replace: true });
    },
    [navigate],
  );

  const handleDelete = useCallback(() => {
    gym
      .delete()
      .then(() => navigate('/infrastructure/gym', { replace: true }))
      .catch(() => message.error('Error deleting gym'));
  }, [gym, navigate]);

  return (
    <Layout style={{ backgroundColor: '#f0f2f5', minHeight: '100vh' }}>
      <Content style={{ padding: '24px' }}>
        <div style={{ maxWidth: 1200, margin: '0 auto' }}>
          <Card bordered={false}>
            {/* First row: Title on left; subscription badge and Save/Delete buttons on the right */}
            <Row justify="space-between" align="middle">
              <Col>
                <Title level={2}>{gym.name || 'New Gym'}</Title>
                {subscription && <Badge color={subscriptionColor(subscription)} text={subscription.plan} />}
                <GymInfoToCopy gym={gym} />
              </Col>
              <Col>
                <Space direction="vertical" align="end">
                  <Space>
                    <Button type="primary" onClick={handleSave}>
                      Save
                    </Button>
                    {!isNew && loaded && authentication.isOneAboveAll && (
                      <Popconfirm
                        title={`Delete ${gym.name}?`}
                        onConfirm={handleDelete}
                        okText="Delete"
                        cancelText="Cancel"
                      >
                        <Button danger>Delete</Button>
                      </Popconfirm>
                    )}
                  </Space>
                </Space>
              </Col>
            </Row>
          </Card>
          <Card style={{ marginTop: '24px' }}>
            {loaded ? (
              <Routes>
                <Route path=":tab?/*" element={<GymViewScreenTabs gym={gym} onTabChange={handleTabChange} />} />
                <Route path="*" element={<GymViewScreenTabs gym={gym} onTabChange={handleTabChange} />} />
              </Routes>
            ) : (
              <Text>Loading gym data...</Text>
            )}
          </Card>
        </div>
      </Content>
    </Layout>
  );
});
