import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Observer, observer } from 'mobx-react';
import { Layout, Row, Col, Button, Table, Space, Pagination, Tag, AutoComplete, Input } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { Gym } from '../../../Model/Gym/Gym';
import { PageResult } from '../../../Model/PageResult';

const { Content } = Layout;
const { Search } = Input;

export type GymListScreenProps = {};

export const GymListScreen: React.FC<GymListScreenProps> = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const urlQuery = params.get('query') ?? '';
  const urlPage = Number(params.get('page') ?? '0');
  const debounce = useRef<any>();
  const count = useRef(0);
  const [result, setResult] = useState(new PageResult<Gym>());
  const [query, setQuery] = useState(urlQuery);
  const [currentPage, setCurrentPage] = useState(urlPage);
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>('ASC');
  const [pageSize, setPageSize] = useState(10);
  const [suggestions, setSuggestions] = useState<Gym[]>([]);

  const updateSearchParams = useCallback(
    (q: string, page: number = 0) => {
      if (debounce.current) clearTimeout(debounce.current);
      debounce.current = setTimeout(() => {
        const params = new URLSearchParams(location.search);
        params.set('query', q);
        params.set('page', page.toString());
        navigate(
          {
            pathname: location.pathname,
            search: params.toString(),
          },
          { replace: true },
        );
      }, 300);
    },
    [location, navigate],
  );

  const load = useCallback(
    (q: string, page: number = 0, sortFieldParam: string = sortField, sortOrderParam: 'ASC' | 'DESC' = sortOrder) => {
      PageResult.execute(
        Gym.find({ query: q, page, sort: `${sortFieldParam},${sortOrderParam}`, size: pageSize }),
        page === 0 ? Gym.count({ query: q }).then((res) => (count.current = res)) : Promise.resolve(count.current),
        page,
        pageSize,
      ).then((res) => setResult(res));
    },
    [sortField, sortOrder, pageSize],
  );

  useEffect(() => {
    load(query, currentPage);
  }, [query, currentPage, load]);

  const onSearch = (value: string) => {
    setQuery(value);
    setCurrentPage(0);
    updateSearchParams(value, 0);
    load(value, 0, sortField, sortOrder);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page - 1);
    updateSearchParams(query, page - 1);
    load(query, page - 1, sortField, sortOrder);
  };

  const handlePageSizeChange = (_current: number, size: number) => {
    setPageSize(size);
    setCurrentPage(0);
    load(query, 0, sortField, sortOrder);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.order) {
      const order = sorter.order === 'ascend' ? 'ASC' : 'DESC';
      setSortField(sorter.field);
      setSortOrder(order);
      load(query, currentPage, sorter.field, order);
    }
  };

  // Fetch suggestions for the autocomplete
  const handleSearchSuggestions = (value: string) => {
    if (value.trim() === '') {
      setSuggestions([]);
      return;
    }
    // Fetch a smaller list of gyms for preview (limit to 5)
    Gym.find({ query: value, size: 5 }).then((gyms) => setSuggestions(gyms));
  };

  // Build autocomplete options with a preview of logo and name
  const suggestionOptions = suggestions.map((gym) => ({
    value: gym.id,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {gym.logo?.url && (
          <img src={gym.logo.url} style={{ width: 30, height: 30, objectFit: 'contain', marginRight: 8 }} />
        )}
        <span>{gym.name}</span>
      </div>
    ),
  }));

  const columns = [
    {
      title: 'Logo',
      dataIndex: 'logoUrl',
      key: 'logo',
      render: (_: any, record: Gym) => (
        <img src={record.logo?.url} style={{ width: 50, height: 50, objectFit: 'contain' }} />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (_: any, record: Gym) => (
        <a
          onClick={() => navigate(`/infrastructure/gym/${record.id}`)}
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          {record.name}
        </a>
      ),
    },
    {
      title: 'Domains',
      dataIndex: 'domains',
      key: 'domains',
      render: (_: any, record: Gym) => (
        <>{record.domains && record.domains.map((domain: string) => <Tag key={domain}>{domain}</Tag>)}</>
      ),
    },
    {
      title: 'Subscription',
      dataIndex: 'subscription',
      key: 'subscription',
      render: (_: any, record: Gym) => (
        <Observer>
          {() => (
            <span style={{ color: record.subscription?.plan !== 'free' ? 'green' : 'red' }}>
              {record.subscription?.plan !== 'free' ? 'Active' : 'Inactive'}
            </span>
          )}
        </Observer>
      ),
    },
    {
      title: 'Customers',
      dataIndex: 'totalCustomers',
      key: 'totalCustomers',
      render: (_: any, record: Gym) => <Observer>{() => <span>{record.totalCustomers}</span>}</Observer>,
    },
  ];

  return (
    <Layout style={{ padding: '24px' }}>
      <Content>
        <div style={{ maxWidth: 1200, margin: '0 auto' }}>
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Row justify="space-between" align="middle">
              <Col>
                <h1>Companies</h1>
              </Col>
              <Col>
                <Button type="primary" onClick={() => navigate('/infrastructure/gym/new')}>
                  Create New Company
                </Button>
              </Col>
            </Row>
            <AutoComplete
              style={{ width: '100%' }}
              options={suggestionOptions}
              onSearch={handleSearchSuggestions}
              onSelect={(value) => navigate(`/infrastructure/gym/${value}`)}
              placeholder="Search companies..."
            >
              <Input.Search defaultValue={query} onSearch={onSearch} enterButton />
            </AutoComplete>
            <Table<Gym>
              rowKey="id"
              columns={columns}
              dataSource={result.content.slice()}
              pagination={false}
              bordered
              size="middle"
              onChange={handleTableChange}
            />
            <Pagination
              current={currentPage + 1}
              total={result.totalElements || count.current}
              pageSize={pageSize}
              onChange={handlePageChange}
              showSizeChanger
              pageSizeOptions={['10', '20', '50', '100']}
              onShowSizeChange={handlePageSizeChange}
            />
          </Space>
        </div>
      </Content>
    </Layout>
  );
});
