// File: application/src/App/View/Infrastructure/Gym/GymViewScreen/CoachSubscriptionTab/GymCoachSubscriptionTab.tsx
import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Gym } from '../../../../../Model/Gym/Gym';
import { GymCoachSubscription } from '../../../../../Model/Subscription/GymCoachSubscription';
import { Table, Button, Popconfirm, Row, Col, Typography, Tag } from 'antd';
import { Link, Route, useNavigate, Routes } from 'react-router-dom';
import dayjs from 'dayjs';
import { GymCoachSubscriptionModal } from './GymCoachSubscriptionModal';

const { Title } = Typography;

export type GymCoachSubscriptionTabProps = {
  gym: Gym;
};

export const GymCoachSubscriptionTab: React.FC<GymCoachSubscriptionTabProps> = observer(({ gym }) => {
  const [subscriptions, setSubscriptions] = useState<GymCoachSubscription[]>([]);
  const navigate = useNavigate();

  const fetchSubscriptions = useCallback(() => {
    GymCoachSubscription.find({ gymId: gym.id, sort: 'validFrom,DESC' }).then(setSubscriptions);
  }, [gym]);

  useEffect(() => {
    fetchSubscriptions();
  }, [gym, fetchSubscriptions]);

  // Listen for subscription updates from the modal.
  useEffect(() => {
    const handler = () => fetchSubscriptions();
    window.addEventListener('subscription-updated', handler);
    return () => window.removeEventListener('subscription-updated', handler);
  }, [fetchSubscriptions]);

  const handleCreate = useCallback(() => {
    navigate('new');
  }, [navigate]);

  const handleDeleteSubscription = useCallback((subscription: GymCoachSubscription) => {
    subscription.delete().then(() => {
      setSubscriptions((prev) => prev.filter((s) => s.id !== subscription.id));
      window.dispatchEvent(new CustomEvent('subscription-updated'));
    });
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: string) => <Link to={id}>{`${id.substring(0, 8)}...`}</Link>,
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
    },
    {
      title: 'Valid From',
      dataIndex: 'validFrom',
      render: (date: string) => dayjs(date).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Valid Until',
      dataIndex: 'validUntil',
      render: (date: string | null) => (date ? dayjs(date).format('DD.MM.YYYY HH:mm') : 'unlimited'),
    },
    {
      title: 'Licenses',
      dataIndex: 'licenseCount',
      render: (licenseCount: number | null) => licenseCount ?? 'unlimited',
    },
    {
      title: 'Enabled Modules',
      dataIndex: 'enabledModules',
      render: (modules: string[]) =>
        modules && modules.length > 0
          ? modules.map((module) => <Tag key={module}>{module.replace('_', ' ')}</Tag>)
          : '-',
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_: any, record: GymCoachSubscription) => (
        <Popconfirm title="Delete Subscription?" onConfirm={() => handleDeleteSubscription(record)}>
          <Button type="link" danger>
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <Title level={4}>Subscriptions</Title>
        </Col>
        <Col>
          <Button type="primary" onClick={handleCreate}>
            Create Subscription
          </Button>
        </Col>
      </Row>
      <Table dataSource={subscriptions} columns={columns} pagination={{ pageSize: 10 }} />
      <Routes>
        <Route path=":subscriptionId" element={<GymCoachSubscriptionModal />} />
      </Routes>
    </>
  );
});
