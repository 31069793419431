import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { CampaignCalendarTemplateEntry } from '../../../../Model/CampaignCalendar/CampaignCalendarTemplateEntry';
import { Col, Row, Table } from 'reactstrap';
import { Badge, Button, Checkbox, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { CampaignCalendarEntryModalFilesTabTableRow } from './CampaignCalendarEntryModalFilesTabTableRow';
import { RcFile } from 'antd/es/upload';
import MediaService from '../../../../Services/MediaService';
import { runInAction } from 'mobx';
import { CampaignCalendarEntryAttachment } from '../../../../Model/CampaignCalendar/CampaignCalendarEntryAttachment';
import { DndContext, DragEndEvent, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

export type CampaignCalendarEntryModalFilesTabProps = {
  campaign: CampaignCalendarTemplateEntry;
  onStartUpload?: () => void;
  onUploadComplete?: () => void;
};

export const CampaignCalendarEntryModalFilesTab: React.FC<CampaignCalendarEntryModalFilesTabProps> = observer(
  ({ campaign, onUploadComplete, onStartUpload }) => {
    const [uploading, setUploading] = useState(false);
    const uploadProgress = React.useRef(false);
    const sensors = useSensors(useSensor(PointerSensor));

    const beforeUpload = (singleFile: RcFile, fileList: RcFile[]) => {
      if (!uploadProgress.current) {
        uploadProgress.current = true;
        setUploading(true);
        onStartUpload?.();
        Promise.all(fileList.map((file) => MediaService.uploadMedia(file)))
          .then((files) => {
            runInAction(() => {
              files
                .map((file) => new CampaignCalendarEntryAttachment({ file: file.toJS() }))
                .forEach((entry) => campaign.attachments.unshift(entry));
            });
          })
          .catch(() => {
            message.error('Failed to upload file');
          })
          .finally(() => {
            uploadProgress.current = false;
            setUploading(false);
          })
          .then(() => onUploadComplete?.());
      }
      return false;
    };

    const handleDragEnd = (event: DragEndEvent) => {
      const { active, over } = event;
      if (over && active.id !== over.id) {
        const oldIndex = campaign.attachments.findIndex((att) => att.id.toString() === active.id);
        const newIndex = campaign.attachments.findIndex((att) => att.id.toString() === over.id);
        runInAction(() => {
          campaign.attachments = arrayMove(campaign.attachments, oldIndex, newIndex);
        });
      }
    };

    const handleToggleAllTemplate = (e: any) => {
      const checked = e.target.checked;
      runInAction(() => {
        campaign.attachments.forEach((att) => (att.template = checked));
      });
      campaign
        .save()
        .then(() => message.success('All attachments updated'))
        .catch(() => message.error('Failed to update attachments'));
    };

    return (
      <Row>
        <Col>
          <h5>Files</h5>
        </Col>
        <Col xs="auto">
          <Upload beforeUpload={beforeUpload} multiple disabled={uploading} showUploadList={false}>
            <Button icon={<UploadOutlined />} disabled={uploading}>
              Click to Upload or Drag &amp; Drop here to this button
            </Button>
          </Upload>
          {uploading && (
            <div>
              <Badge status="processing" text="Uploading..." />
            </div>
          )}
        </Col>
        <Col xs={12}>
          <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <SortableContext
              items={campaign.attachments.map((att) => att.id.toString())}
              strategy={verticalListSortingStrategy}
            >
              <Table>
                <thead>
                  <tr>
                    <th />
                    <th>ID</th>
                    <th>File</th>
                    <th>Set as entry image</th>
                    <th>Language</th>
                    <th>
                      <Checkbox onChange={handleToggleAllTemplate}>Template\?</Checkbox>
                    </th>
                    <th>Name</th>
                    <th>Size</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {campaign.attachments.map((attachment, index) => (
                    <CampaignCalendarEntryModalFilesTabTableRow
                      key={attachment.id}
                      index={index}
                      campaign={campaign}
                      attachment={attachment}
                    />
                  ))}
                </tbody>
              </Table>
            </SortableContext>
          </DndContext>
        </Col>
      </Row>
    );
  },
);
