/**
 * Created by neo on 24.08.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space } from 'antd';
import dayjs from 'dayjs';
import { MindfulnessProgramTemplate } from '../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplate';
import { ViewMediaItem } from '../../../../Components/ViewMedia/ViewMediaItem';

export type MindfulnessProgramTemplateTableRowProps = {
  template: MindfulnessProgramTemplate;
  onCopy?: (superMacro: MindfulnessProgramTemplate) => void;
  onRemove?: (superMacro: MindfulnessProgramTemplate) => void;
};

export const MindfulnessProgramTemplateTableRow: React.FC<MindfulnessProgramTemplateTableRowProps> = observer(
  ({ template, onCopy, onRemove }) => {
    // const [stats, setStats] = useState<ProgramStatisticsUsage | undefined>();
    //
    // useEffect(() => {
    //   ProgramStatisticsUsage.findOne(template.id).then((res) => setStats(res));
    // }, [template]);

    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(template);
    }, [template, onRemove]);

    const handleCopy = React.useCallback(() => {
      onCopy && onCopy(template);
    }, [onCopy, template]);

    return (
      <tr>
        <td>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {template.images
              .filter((m) => m.mediaType.startsWith('image'))
              .map((media) => (
                <ViewMediaItem key={media.id} media={media} size={64} />
              ))}
          </div>
        </td>
        <td>
          <Link to={`/coach/mindfulness-template/${template.id}`}>{template.templateName || '<no name>'}</Link>
        </td>
        <td>
          {template.name.map((name) => (
            <React.Fragment key={name.lang}>
              {`${name.value} (${name.lang})`}
              <br />
            </React.Fragment>
          ))}
        </td>
        <td style={{ fontSize: 12 }}>{template.tags.sort((a, b) => a.localeCompare(b)).join(', ')}</td>
        <td>
          {template.minDurationInMinutes ? dayjs.utc(template.minDurationInMinutes * 60000).format('HH:mm') : '-'}
        </td>
        <td>
          {template.maxDurationInMinutes ? dayjs.utc(template.maxDurationInMinutes * 60000).format('HH:mm') : '-'}
        </td>
        <td>
          <Space>
            <Popconfirm title={`Delete Template ${template.templateName}?`} onConfirm={handleRemove}>
              <Button type="link" danger>
                Delete
              </Button>
            </Popconfirm>
            <Button type="dashed" onClick={handleCopy}>
              Copy
            </Button>
          </Space>
        </td>
      </tr>
    );
  },
);
