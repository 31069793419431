/**
 * Created by neo on 06.02.17.
 */

import { observer } from 'mobx-react';
import * as React from 'react';
import { Badge } from 'reactstrap';
import { TableRowMedia } from '../../../../Components/TableRowMedia';
import { HighlightedLocalized } from '../../../../Components/HighlightedLocalized';
import { ExerciseVariationAdmin } from '../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { Button, message, Space } from 'antd';
import { ExerciseEquipmentTypeBadges } from './ExerciseEquipmentTypeBadges';
import { ExerciseBodyPartBadges } from './ExerciseBodyPartBadges';
import { ExerciseTagBadges } from './ExerciseTagBadges';
import { ExerciseTrackingKeyBadges } from './ExerciseTrackingKeyBadges';
import { ExerciseForceBadge } from './ExerciseForceBadge';
import { useRootStore } from '../../../../Store/useRootStore';

export type ExerciseVariationAdminTableRowProps = {
  variation: ExerciseVariationAdmin;
  onClick?: (exercise: ExerciseVariationAdmin) => any;
  onRemove?: (exercise: ExerciseVariationAdmin, hard: boolean) => void;
  onCopy?: (exercise: ExerciseVariationAdmin) => any;
  query?: string;
};

export const ExerciseVariationAdminTableRow: React.FC<ExerciseVariationAdminTableRowProps> = observer(
  ({ variation, query, onClick, onCopy, onRemove }) => {
    const { authentication } = useRootStore();

    const handleToggle = React.useCallback(() => {
      onClick && onClick(variation);
      navigator.clipboard.readText().then((text) => {
        if (text !== variation.id) {
          navigator.clipboard.writeText(variation.id).then(() => message.info(`Copied ${variation.id}`));
        }
      });
    }, [variation, onClick]);

    const handleArchive = React.useCallback(() => {
      onRemove && onRemove(variation, false);
    }, [onRemove, variation]);

    const handleDelete = React.useCallback(() => {
      onRemove && onRemove(variation, true);
    }, [onRemove, variation]);

    const handleCopy = React.useCallback(() => {
      onCopy && onCopy(variation);
    }, [onCopy, variation]);

    return (
      <>
        <tr style={variation.archived ? { color: 'red' } : undefined}>
          <td>
            <TableRowMedia medias={variation.medias?.slice()} />
          </td>
          <td>
            <Button
              type="link"
              style={Object.assign({ textAlign: 'left' }, variation.archived ? { color: 'red' } : {}) as any}
              onClick={handleToggle}
            >
              {variation.nameGermanOrNext || '<no name specified>'}
            </Button>
            <div>
              <HighlightedLocalized strings={variation.name} query={query} />
            </div>
          </td>
          <td>
            <ExerciseEquipmentTypeBadges exercise={variation} />
          </td>
          <td>
            <ExerciseBodyPartBadges exercise={variation} />
          </td>
          <td>
            <ExerciseTagBadges exercise={variation} />
          </td>
          <td>
            <ExerciseTrackingKeyBadges exercise={variation} />
          </td>
          <td>
            <ExerciseForceBadge exercise={variation} />
            <Badge color="secondary">{variation.mechanics}</Badge>
            <br />
            <Badge color="success">{variation.utility}</Badge>
          </td>
          <td>{variation.type}</td>
          <td>
            {variation.previewMedias?.some((m) => m.visibleRect) ? (
              <Badge color="success">Yes</Badge>
            ) : (
              <Badge color="danger">No</Badge>
            )}
          </td>
          <td>
            <Space>
              {onRemove && !variation.archived && (
                <Button size="small" danger onClick={handleArchive}>
                  Archive
                </Button>
              )}
              {onRemove && variation.archived && authentication.isOneAboveAll && (
                <Button size="small" danger onClick={handleDelete}>
                  DELETE
                </Button>
              )}
              {onCopy && (
                <Button size="small" type="primary" ghost onClick={handleCopy}>
                  Copy
                </Button>
              )}
            </Space>
          </td>
        </tr>
      </>
    );
  },
);
