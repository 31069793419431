/**
 * Created by neo on 01.02.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { MeditationEntry } from '../../../../../Model/Explore/MeditationEntry';
import { MeditationNarrator } from '../../../../../Model/Explore/MeditationNarrator';
import { Col, Row } from 'reactstrap';
import { Button, Form, Input } from 'antd';
import { runInAction } from 'mobx';
import { ViewMediaItem } from '../../../../../Components/ViewMedia/ViewMediaItem';
import Dropzone from 'react-dropzone';
import MediaService from '../../../../../Services/MediaService';
import { MediaNarratorAudioTrackEntry } from './MediaNarratorAudioTrackEntry';
import { MediaNarratorAudioTrack } from '../../../../../Model/Explore/MediaNarratorAudioTrack';

export type MeditationNarratorEntryProps = {
  entry: MeditationEntry;
  narrator: MeditationNarrator;
  index: number;
};

export const MeditationNarratorEntry: React.FC<MeditationNarratorEntryProps> = observer(
  ({ entry, narrator, index }) => {
    const [uploading, setUploading] = useState(false);

    const handleNameChange = React.useCallback(
      ({ target: { value } }) => {
        runInAction(() => (narrator.name = value));
      },
      [narrator],
    );

    const handleDropImage = React.useCallback(
      (files: File[]) => {
        const file = files.length > 0 ? files[0] : undefined;
        if (file) {
          MediaService.uploadMedia(file).then((media) => runInAction(() => (narrator.image = media)));
        }
      },
      [narrator],
    );

    const handleRemoveFile = React.useCallback(() => {
      runInAction(() => (narrator.image = undefined));
    }, [narrator]);

    const handleChangeAudioTrack = React.useCallback(
      (e) => {
        const { target: { files } } = e;
        e.preventDefault();
        console.log('file', files);
        setUploading(true);
        Promise.all(Array.from(files).map((file: any) => MediaService.uploadMedia(file)))
          .then((medias) => medias.map((media) => new MediaNarratorAudioTrack({ media: media.toJS() })))
          .then((audioTracks) => runInAction(() => (narrator.audioTracks = narrator.audioTracks.concat(audioTracks))))
          .finally(() => setUploading(false));
      },
      [narrator],
    );

    const handleRemoveNarrator = React.useCallback(() => {
      runInAction(() => entry.narrators.splice(index, 1));
    }, [entry, index]);

    return (
      <React.Fragment>
        <Row>
          <Col>
            <Form.Item label="Narrator Name">
              <Input value={narrator.name} onChange={handleNameChange} />
            </Form.Item>
            <Form.Item label="Narrator Image">
              <Row>
                <Col>{narrator.image && <ViewMediaItem media={narrator.image} onRemove={handleRemoveFile} />}</Col>
                <Col>
                  <Dropzone onDrop={handleDropImage}>
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className="dropzone"
                        style={{
                          flex: 1,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <input {...getInputProps()} />
                        {'Drop an image here'}
                      </div>
                    )}
                  </Dropzone>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Audio Tracks">
              {narrator.audioTracks.map((audioTrack) => (
                <MediaNarratorAudioTrackEntry
                  key={audioTrack.media.url}
                  entry={entry}
                  narrator={narrator}
                  audioTrack={audioTrack}
                />
              ))}
            </Form.Item>
            <Form.Item label="Upload new audio track">
              <Input type="file" onChange={handleChangeAudioTrack} disabled={uploading} value="" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col />
          <Col xs="auto">
            <Button danger onClick={handleRemoveNarrator}>
              Remove {`${narrator.name}`}
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  },
);
