import * as React from 'react';
import { Upload, Button, Form, message, Checkbox } from 'antd';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import MediaService from '../../../Services/MediaService';
import { Media } from '../../../Model/Media/Media';
import { runInAction } from 'mobx';
import { Observer } from 'mobx-react';
import { useState } from 'react';

interface CustomUploadFile<T = any> extends UploadFile<T> {
  media?: Media;
}

export type ExploreEntryModalMediaFieldProps = {
  label?: string;
  allowedTypes?: string[];
  onUploaded: (medias: Media[]) => void;
  onRemove?: (media: Media) => void;
  onClick?: (media: Media) => void;
  onSaved?: (media: Media) => void /* new callback */;
  media?: Media;
  multiple?: boolean;
};

export const ExploreEntryModalMediaField: React.FC<ExploreEntryModalMediaFieldProps> = ({
  label,
  allowedTypes = ['image/*', 'video/*'],
  onUploaded,
  onRemove,
  onClick,
  onSaved,
  media,
  multiple = true,
}) => {
  const getInitialFileList = (mediaItem?: Media): CustomUploadFile[] => {
    return mediaItem
      ? [
          {
            uid: String(mediaItem.id || mediaItem.url),
            name: mediaItem.name || 'file',
            status: 'done',
            url: mediaItem.url,
            media: mediaItem,
          },
        ]
      : [];
  };

  const [fileList, setFileList] = React.useState<CustomUploadFile[]>(getInitialFileList(media));

  React.useEffect(() => {
    setFileList(getInitialFileList(media));
  }, [media]);

  const beforeUpload = (file: File) => {
    if (!allowedTypes.includes(file.type)) {
      message.error(`File type ${file.type} not allowed`);
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const customRequest = async (options: any) => {
    const { file, onProgress, onSuccess, onError } = options;
    try {
      const uploadedMedia = await MediaService.uploadMedia(file, (event: any) => {
        const percent = Math.round((event.loaded * 100) / event.total);
        if (onProgress) {
          onProgress({ percent });
        }
      });
      onUploaded([uploadedMedia]);
      setFileList((current) => current.map((f) => (f.uid === file.uid ? { ...f, media: uploadedMedia } : f)));
      onSuccess(uploadedMedia);
      message.success(`File ${file.name} uploaded successfully`);
    } catch (error) {
      message.error(`Error uploading file ${file.name}`);
      onError(error);
    }
  };

  const itemRender = (originNode: React.ReactNode, file: CustomUploadFile, fileList: CustomUploadFile[]) => {
    const [saving, setSaving] = useState(false);

    const mediaObj = file.media;
    if (mediaObj) {
      const handleRemoveClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (onRemove) {
          onRemove(mediaObj);
          setFileList((curr) => curr.filter((f) => f.uid !== file.uid));
        }
      };

      const handleCheckboxChange = (e) => {
        e.preventDefault();
        setSaving(true);
        runInAction(() => (mediaObj.aiContent = e.target.checked));
        mediaObj
          .save()
          .then(() => onSaved?.(mediaObj))
          .finally(() => setSaving(false));
      };

      return (
        <Observer>
          {() => (
            <div style={{ position: 'relative', width: 200, height: 200 }}>
              {mediaObj.mediaType.startsWith('image') && (
                <img
                  src={mediaObj.url}
                  alt={mediaObj.name || 'image'}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    cursor: onClick ? 'pointer' : 'default',
                  }}
                  onClick={() => onClick && onClick(mediaObj)}
                />
              )}
              {mediaObj.mediaType.startsWith('video') && (
                <video
                  src={mediaObj.url}
                  controls
                  muted
                  style={{
                    width: 180,
                    height: 180,
                    objectFit: 'cover',
                    cursor: onClick ? 'pointer' : 'default',
                  }}
                  onClick={() => onClick && onClick(mediaObj)}
                />
              )}
              {onRemove && (
                <div
                  onClick={handleRemoveClick}
                  style={{
                    position: 'absolute',
                    top: 4,
                    right: 4,
                    zIndex: 1,
                  }}
                >
                  <CloseOutlined
                    style={{
                      fontSize: 16,
                      color: '#fff',
                      background: 'rgba(0, 0, 0, 0.6)',
                      borderRadius: '50%',
                      padding: 4,
                    }}
                  />
                </div>
              )}
              {mediaObj.language && (
                <div
                  style={{
                    position: 'absolute',
                    bottom: 20,
                    left: 4,
                    background: 'rgba(0, 0, 0, 0.6)',
                    borderRadius: 4,
                    padding: '2px 4px',
                    color: '#fff',
                    fontSize: 12,
                    zIndex: 1,
                  }}
                >
                  {mediaObj.language}
                </div>
              )}
              <div style={{ marginTop: 8 }}>
                <Checkbox checked={mediaObj.aiContent} onChange={handleCheckboxChange} disabled={saving}>
                  AI content
                </Checkbox>
              </div>
            </div>
          )}
        </Observer>
      );
    }
    return originNode;
  };

  return (
    <Form.Item label={label}>
      <Upload
        multiple={typeof multiple !== 'undefined' ? multiple : !media}
        customRequest={customRequest}
        beforeUpload={beforeUpload}
        fileList={fileList}
        onChange={({ fileList: newFileList }) => setFileList(newFileList as CustomUploadFile[])}
        onRemove={(file) => {
          setFileList((curr) => curr.filter((f) => f.uid !== file.uid));
          if (onRemove && (file as CustomUploadFile).media) {
            onRemove((file as CustomUploadFile).media!);
          }
          return true;
        }}
        onPreview={(file) => {
          if (onClick && (file as CustomUploadFile).media) {
            onClick((file as CustomUploadFile).media!);
          }
        }}
        itemRender={itemRender}
        showUploadList={{ showRemoveIcon: false }}
      >
        {!media && <Button icon={<UploadOutlined />}>Upload {(!media && 'Files') || 'File'}</Button>}
      </Upload>
    </Form.Item>
  );
};
