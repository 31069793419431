/**
 * Created by neo on 11.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Alert, Button, Checkbox, DatePicker, Form, Input, InputNumber, Modal, Select } from 'antd';
import { ExploreCategory, ExploreCategoryType } from '../../../Model/Explore/ExploreCategory';
import { useNavigate } from 'react-router';
import { reaction, runInAction } from 'mobx';
import { SingleColRow } from '../../../Components/SingleColRow';
import { TranslationInputArray } from '../../../Components/Translation/TranslationInputArray';
import AsyncSelect from 'react-select/async';
import { TagConditionsInput } from '../SuperMacro/View/TagConditionsInput';
import { Col, Row } from 'reactstrap';
import dayjs from 'dayjs';
import { ExploreEntryModalMediaField } from './ExploreEntryModalMediaField';
import { Media } from '../../../Model/Media/Media';
import { ExploreCategoryUrl } from './ExploreCategoryUrl';
import { ExploreCategoryModalLinkListEditor } from './ExploreCategoryModalLinkListEditor';
import { ExploreContentViewType } from '../../../Model/Explore/ExploreContent';
import { MediaEditModal } from '../../MetaData/Media/MediaEditModal/MediaEditModal';
import { Gym } from '../../../Model/Gym/Gym';

export type ExploreCategoryModalProps = {
  category: ExploreCategory;
  onSaved?: (category: ExploreCategory) => void;
  isNew?: boolean;
};

const IDENTIFIER_REGEX = /^[a-z-_0-9]{3,48}$/;

export const ExploreCategoryModal: React.FC<ExploreCategoryModalProps> = observer(({ category, onSaved, isNew }) => {
  const history = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<Media | undefined>();
  const [parents, setParents] = useState<ExploreCategory[]>([]);
  const [gym, setGym] = useState<Gym | undefined>();

  const identifierInvalid = !category.identifier.match(IDENTIFIER_REGEX);
  const validateStatus = identifierInvalid ? 'error' : undefined;

  console.log('category', category);

  useEffect(
    () =>
      reaction(
        () => category.categories,
        (parentIds) => {
          setParents([]);
          if (parentIds.length > 0) {
            ExploreCategory.findAll(parentIds, { gymId: category.gymId || undefined }).then((res) => setParents(res));
          }
        },
        { fireImmediately: true },
      ),
    [category],
  );

  useEffect(
    () =>
      reaction(
        () => category.gymId,
        (gymId) => {
          setGym(undefined);
          if (gymId) {
            Gym.get(gymId).then((res) => setGym(res));
          }
        },
        { fireImmediately: true },
      ),
    [category],
  );

  const handleSave = React.useCallback(() => {
    setProcessing(true);
    onSaved && onSaved(category);
    category
      .save()
      .finally(() => setProcessing(false))
      .then(() => history(-1));
  }, [category, onSaved, history]);

  const handleCancel = React.useCallback(() => history(-1), [history]);

  const handleChange = React.useCallback(
    (parents: ExploreCategory[] | undefined) => {
      category.categories = (parents ?? []).map((c) => c.identifier);
    },
    [category],
  );

  const fetchParent = React.useCallback(
    (query: string) =>
      ExploreCategory.find({ query, gymId: category?.gymId }).then((res) =>
        res.filter((n) => !category.categories.some((c) => c === n.identifier)),
      ),
    [category],
  );

  const getOptionLabel = React.useCallback(
    (option: ExploreCategory) => `${option.defaultName} (${option.identifier})`,
    [],
  );

  const getOptionValue = React.useCallback((option: ExploreCategory) => option, []);

  const handleChangePublishDate = React.useCallback(
    (date) => runInAction(() => (category.publishDate = date?.toDate())),
    [category],
  );

  const handleChangeUnpublishDate = React.useCallback(
    (date) => runInAction(() => (category.unpublishDate = date?.toDate())),
    [category],
  );

  const handleImageUploaded = React.useCallback(
    (media: Media[]) => {
      runInAction(() => (category.images = category.images.concat(media)));
    },
    [category],
  );

  const handleRemoveImage = React.useCallback(
    (media: Media) => {
      runInAction(() => (category.images = category.images.filter((v) => v.id !== media.id)));
    },
    [category],
  );

  const handleChangeMinVersionIos = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (category.version.minVersionIos = value ? value : undefined));
    },
    [category],
  );

  const handleChangeMaxVersionIos = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (category.version.maxVersionIos = value ? value : undefined));
    },
    [category],
  );

  const handleChangeMinVersionAndroid = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (category.version.minVersionAndroid = value ? value : undefined));
    },
    [category],
  );

  const handleChangeMaxVersionAndroid = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (category.version.maxVersionAndroid = value ? value : undefined));
    },
    [category],
  );

  const handleChangeOrder = React.useCallback(
    (count?: number | string | null) => {
      const limit = typeof count === 'string' ? Number(count) : count ?? 0;
      runInAction(() => (category.order = limit));
    },
    [category],
  );

  const handleChangeType = React.useCallback(
    (value: string) => {
      runInAction(() => (category.type = value as ExploreCategoryType));
    },
    [category],
  );

  const handleChangeViewType = React.useCallback(
    (value: string) => {
      runInAction(() => (category.viewType = value as ExploreContentViewType));
    },
    [category],
  );

  const handleUnpublish = React.useCallback(() => {
    runInAction(
      () =>
        (category.unpublishDate =
          category.unpublishDate && dayjs(category.unpublishDate).isBefore(dayjs())
            ? category.unpublishDate
            : new Date()),
    );

    setProcessing(true);
    onSaved && onSaved(category);
    category
      .save()
      .finally(() => setProcessing(false))
      .then(() => history(-1));
  }, [category, history, onSaved]);

  const handlePublish = React.useCallback(() => {
    runInAction(() => {
      category.publishDate =
        category.publishDate && dayjs(category.publishDate).isBefore(dayjs()) ? category.publishDate : new Date();
      category.unpublishDate =
        !category.unpublishDate || dayjs(category.unpublishDate).isAfter(dayjs()) ? category.unpublishDate : undefined;
    });

    setProcessing(true);
    category
      .save()
      .finally(() => setProcessing(false))
      .then(() => history(-1));
    onSaved && onSaved(category);
  }, [category, history, onSaved]);

  const handleClickMedia = React.useCallback((media: Media) => {
    setSelectedMedia(media);
  }, []);

  const handleCloseMedia = React.useCallback(() => setSelectedMedia(undefined), []);

  const handleMediaSave = React.useCallback(
    (media: Media) => {
      runInAction(() => {
        if (category.images.some((v) => v.id === media.id)) {
          const index = category.images.findIndex((v) => v.id === media.id);
          if (index !== -1) {
            category.images[index] = media;
          }
        }
      });

      category.save();
    },
    [category],
  );

  const fetchGym = React.useCallback((query: string) => Gym.find({ query }), []);

  const optionLabel = React.useCallback((obj) => obj.name, []);

  const optionValue = React.useCallback((obj) => obj, []);

  const handleChangeObject = React.useCallback(
    (value?: Gym) => {
      runInAction(() => (category.gymId = value?.id));
    },
    [category],
  );

  const handleToggleUpcomingSort = React.useCallback(
    ({ target: { checked } }) => {
      runInAction(() => (category.sortingRule = checked ? 'upcoming' : undefined));
    },
    [category],
  );

  return (
    <React.Fragment>
      <Modal
        open={true}
        title={isNew ? 'New Category' : category.defaultName}
        onOk={handleSave}
        onCancel={handleCancel}
        confirmLoading={processing}
        footer={[
          <Button key="1" onClick={handleCancel} disabled={processing}>
            Close
          </Button>,
          <React.Fragment key="2">
            {category.state === 'published' ? (
              <Button danger onClick={handleUnpublish} disabled={processing || isNew}>
                Unpublish
              </Button>
            ) : null}
          </React.Fragment>,
          <Button key="3" type="primary" onClick={handleSave} disabled={processing || category.type === 'default'}>
            {category.publishDate ? 'Push Update' : 'Save Draft'}
          </Button>,
          <React.Fragment key="4">
            {category.state === 'unpublished' || category.state === 'draft' ? (
              <Button
                key="4"
                type="primary"
                onClick={handlePublish}
                disabled={processing || category.type === 'default'}
              >
                Publish Entry
              </Button>
            ) : null}
          </React.Fragment>,
        ]}
        width={1280}
      >
        <Form layout="vertical">
          {category.gym && (
            <SingleColRow>
              <Alert type="warning" message={`You are in '${category.gym?.name}'`} />
            </SingleColRow>
          )}
          <Row>
            <Col xs={6}>
              <Form.Item label="Parents">
                <AsyncSelect
                  defaultOptions
                  isMulti={true}
                  isClearable={true}
                  loadOptions={fetchParent}
                  value={parents}
                  hideSelectedOptions={false}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={getOptionValue as any}
                  onChange={handleChange as any}
                />
              </Form.Item>
            </Col>
            {!isNew && (
              <Col xs={12}>
                <ExploreCategoryUrl category={category} />
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <Form.Item label="Valid From">
                <DatePicker
                  value={category.publishDate ? dayjs(category.publishDate) : undefined}
                  onChange={handleChangePublishDate}
                  allowClear={true}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Valid Until">
                <DatePicker
                  value={category.unpublishDate ? dayjs(category.unpublishDate) : undefined}
                  onChange={handleChangeUnpublishDate}
                  allowClear={true}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Order" extra="The lower the number the higher it is in the list. (0 = on top)">
                <InputNumber value={category.order} onChange={handleChangeOrder} min={0} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Form.Item
                label="Webinar Sorting"
                extra="Select it only if the categories contains ONLY webinars e.g. online meeting entries. Otherwise any other entry won't be shown."
              >
                <Checkbox checked={category.sortingRule === 'upcoming'} onChange={handleToggleUpcomingSort}>
                  Sort by upcoming date?
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item
                label="Gym / Company"
                extra="If you change it all sub categories and entries below it will also be assigned to the new gym / company!!"
              >
                <AsyncSelect
                  cacheOptions
                  value={gym}
                  isClearable={true}
                  loadOptions={fetchGym as any}
                  getOptionLabel={optionLabel}
                  getOptionValue={optionValue as any}
                  onChange={handleChangeObject as any}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="Type">
                <Select value={category.type} onChange={handleChangeType}>
                  <Select.Option value="preview">Preview</Select.Option>
                  <Select.Option value="big_preview">Big Preview</Select.Option>
                  <Select.Option value="card_link">Card Link</Select.Option>
                  <Select.Option value="category_group">Category Group</Select.Option>
                  <Select.Option value="link_list">Link List</Select.Option>
                  <Select.Option value="recipe_recommendations">Recipe Recommendations</Select.Option>
                  <Select.Option value="recipe_favorites">Recipe Favorites</Select.Option>
                  <Select.Option value="recipe_all">All Recipes</Select.Option>
                  <Select.Option value="single_exercises">Single Exercises</Select.Option>
                  <Select.Option value="tools">Tools</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Display Type" extra="How the content will be displayed when inside the category">
                <Select value={category.viewType} onChange={handleChangeViewType}>
                  <Select.Option value="small_cards">Small Cards</Select.Option>
                  <Select.Option value="big_cards">Big Cards</Select.Option>
                  <Select.Option value="full_size">Full Width</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {(category.type === 'link_list' || category.type === 'tools') && (
            <ExploreCategoryModalLinkListEditor category={category} />
          )}
          {category.type === 'card_link' && (
            <React.Fragment>
              <Form.Item label="Link">
                <Input
                  value={category.link}
                  onChange={({ target: { value } }) => runInAction(() => (category.link = value))}
                />
              </Form.Item>
            </React.Fragment>
          )}
          <Row>
            <Col md={6}>
              <TranslationInputArray entity={category} field="name" label="Name" />
            </Col>
            <Col md={6}>
              <TranslationInputArray entity={category} field="description" type="textarea" label="Description" />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="iOS min. Version" extra="Default is 0.0.0">
                <Input value={category.version.minVersionIos} onChange={handleChangeMinVersionIos} />
              </Form.Item>
            </Col>
            {/*<Col>*/}
            {/*  <Form.Item label="iOS max. Version" extra="In 99.99% of the cases... don't touch this field">*/}
            {/*    <Input value={category.version.maxVersionIos} onChange={handleChangeMaxVersionIos} />*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}
            <Col>
              <Form.Item label="Android min. Version" extra="Default is 0.0.0">
                <Input value={category.version.minVersionAndroid} onChange={handleChangeMinVersionAndroid} />
              </Form.Item>
            </Col>
            {/*<Col>*/}
            {/*  <Form.Item label="Android max. Version" extra="In 99.99% of the cases... don't touch this field">*/}
            {/*    <Input value={category.version.maxVersionAndroid} onChange={handleChangeMaxVersionAndroid} />*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}
          </Row>
          <SingleColRow>
            <Form.Item label="Tag Conditions">
              <TagConditionsInput tagConditions={category.tagConditions} />
            </Form.Item>
          </SingleColRow>
          <SingleColRow>
            <Form.Item label="Images">
              <div style={{ display: 'flex' }}>
                {category.images.concat([undefined] as any).map((media) => (
                  <div key={media?.id} style={{ marginLeft: 8 }}>
                    <ExploreEntryModalMediaField
                      allowedTypes={['image/png', 'image/jpeg', 'image/jpg']}
                      onRemove={handleRemoveImage}
                      onUploaded={handleImageUploaded}
                      onClick={handleClickMedia}
                      media={media}
                    />
                  </div>
                ))}
              </div>
            </Form.Item>
          </SingleColRow>
        </Form>
      </Modal>
      {selectedMedia && <MediaEditModal media={selectedMedia} onCancel={handleCloseMedia} onSave={handleMediaSave} />}
    </React.Fragment>
  );
});
