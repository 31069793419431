/**
 * Created by andreaskarantzas on 06.05.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { Alert, Button, Form } from 'antd';
import { useState } from 'react';
import copy from 'copy-to-clipboard';
import { ExploreCategory } from '../../../Model/Explore/ExploreCategory';

const prefix = 'https://coach.kinastic.com';
// const prefix = 'https://open.kinastic.com/?link=https://coach.kinastic.com';
const suffix = '';
// const suffix = '&apn=app.flinq.athlete&isi=1465156373&ibi=app.flinq.athlete';

export type ExploreCategoryUrlProps = {
  category: ExploreCategory;
};

export const ExploreCategoryUrl: React.FC<ExploreCategoryUrlProps> = observer(({ category }) => {
  const [copiedCoachUrl, setCopiedCoachUrl] = useState<boolean>(false);
  const [copiedImageUrl, setCopiedImageUrl] = useState<boolean>(false);

  const coachUrl = category.link || `${prefix}/explore-tab/${category.identifier}${suffix}`;

  const handleCopy = React.useCallback(() => {
    copy(`${coachUrl}`);
    setCopiedCoachUrl(true);
    setTimeout(() => {
      setCopiedCoachUrl(false);
    }, 3000);
  }, [coachUrl]);

  const handleCopyImageUrl = React.useCallback(() => {
    copy(`${category.image?.smallest}`);
    setCopiedImageUrl(true);
    setTimeout(() => {
      setCopiedImageUrl(false);
    }, 3000);
  }, [category]);

  return (
    <Row>
      <Col xs={6} style={styles.container}>
        <Form.Item label="Content link (to be used inside data as `link`)">
          <span>{coachUrl || 'No link available'}</span>
          <Button type="primary" shape="round" size="small" disabled={!coachUrl} onClick={handleCopy}>
            {copiedCoachUrl ? 'Copied!' : 'Copy to clipboard'}
          </Button>
        </Form.Item>
      </Col>
      <Col xs={6} style={styles.container}>
        <Form.Item
          label="Notification Image link"
          extra="to be used in the notification image as link. Make sure it is below 300KB"
        >
          {category.image ? (
            <React.Fragment>
              <a href={category.image.smallest} rel="noreferrer" target="_blank">
                {category.image.smallest}
              </a>
              <Button
                type="primary"
                shape="round"
                size="small"
                disabled={!category.image?.smallest}
                onClick={handleCopyImageUrl}
              >
                {copiedImageUrl ? 'Copied!' : 'Copy to clipboard'}
              </Button>
            </React.Fragment>
          ) : (
            <Alert message="Missing image. Add an image first" type="warning" />
          )}
        </Form.Item>
      </Col>
    </Row>
  );
});

const styles = {
  container: {},
};
