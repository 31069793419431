/**
 * Created by neo on 30.09.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { MediaOverlay } from '../../../../../../Model/Media/MediaOverlay';
import styled from '@emotion/styled';

export type MediaOverlayContentItemProps = {
  overlay: MediaOverlay;
  language?: string;
  store: {
    currentSeek: number;
  };
};

const Container = styled.div`
  font-size: 24px;
  color: white;
`;

export const MediaOverlayContentItem: React.FC<MediaOverlayContentItemProps> = observer(
  ({ overlay, language, store }) => {
    const visible =
      store.currentSeek >= overlay.startSeconds && (!overlay.endSeconds || store.currentSeek < overlay.endSeconds);
    const text = overlay.getText(language ?? 'de');

    return visible ? <Container>{text}</Container> : null;
  },
);
