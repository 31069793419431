/**
 * Created by andreaskarantzas on 09.04.2021.
 */
import { observable, onBecomeObserved, runInAction, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { ActivityWorkoutEntry } from './ActivityWorkoutEntry';
import { Activity } from '../Activity/Activity';
import { SubscribableEntry, SubscribableEntryJson } from './SubscribableEntry';
import { OnlineMeetingEntryEmbedData, OnlineMeetingEntryEmbedDataJson } from './OnlineMeetingEntryEmbedData';

export type OnlineMeetingEntryJson = SubscribableEntryJson & {
  meetingLink: string;
  language: string;
  /**
   * optional -> if set it means we will log an activity log
   */
  activityIdentifier?: string;
  data: Record<string, any>;
  embedData?: OnlineMeetingEntryEmbedDataJson;
};

export class OnlineMeetingEntry extends SubscribableEntry {
  @observable
  meetingLink = '';
  @observable
  language = 'de';
  @observable
  activityIdentifier?: string;
  @observable
  data: Record<string, any> = {};
  @observable
  embedData?: OnlineMeetingEntryEmbedData;
  @observable
  activity?: Activity;

  constructor(json?: Partial<OnlineMeetingEntryJson>) {
    super(
      Object.assign(
        json ?? {},
        { type: 'onlineMeeting' },
        { version: { minVersionIos: '1.47.0', minVersionAndroid: '1.47.0' } },
      ),
    );
    if (json) {
      this.type = 'onlineMeeting';
      this.meetingLink = json.meetingLink ?? '';
      this.language = json.language ?? 'de';
      this.activityIdentifier = json.activityIdentifier;
      this.data = json.data ?? {};
      this.embedData = json.embedData ? new OnlineMeetingEntryEmbedData(json.embedData) : undefined;
    }

    onBecomeObserved(this, 'activity', this.fetchActivity);
  }

  fetchActivity = () => {
    if (!this.activity) {
      if (this.activityIdentifier) {
        return Activity.get(this.activityIdentifier).then((result) => {
          runInAction(() => (this.activity = result));
          return result;
        });
      }
    }
    return Promise.resolve(this.activity);
  };

  toJS(): OnlineMeetingEntryJson {
    return Object.assign(super.toJS(), {
      meetingLink: this.meetingLink,
      language: this.language,
      activityIdentifier: this.activityIdentifier,
      data: toJS(this.data),
      embedData: this.embedData?.toJS(),
    });
  }

  static async find(request: Omit<ExploreEntryQueryRequest, 'type'>): Promise<OnlineMeetingEntry[]> {
    return HttpBackend.get(`/coach/explore/admin`, Object.assign(toJS(request), { type: 'onlineMeeting' })).then((res) =>
      (res ?? []).map((r) => new ActivityWorkoutEntry(r)),
    );
  }

  static async findOne(id: string): Promise<OnlineMeetingEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new OnlineMeetingEntry(res));
  }
}
