// File: application/src/App/View/MetaData/Translations/TranslationTableRow.tsx
import * as React from 'react';
import { observer } from 'mobx-react';
import { Card, Space, Button, Select, message, Modal, Row, Col } from 'antd';
import { runInAction } from 'mobx';
import copy from 'copy-to-clipboard';
import { availableLanguages } from '../../../Utils/availableLanguages';
import { TranslationKey } from '../../../Model/Translation/TranslationKey';
import { TranslationTableRowEntry } from './TranslationTableRowEntry';
import { MergedTranslation } from '../../../Model/Translation/MergedTranslation';

export type TranslationTableRowProps = {
  mergedTranslation: MergedTranslation;
  index: number;
  namespaces: string[];
};

export const TranslationTableRow: React.FC<TranslationTableRowProps> = observer(({ mergedTranslation, namespaces }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const missingLanguages = availableLanguages.filter(
    (l) => !mergedTranslation.keys.some((k) => k.language === l.value),
  );

  const handleDeleteKey = () => {
    Modal.confirm({
      title: `Delete ${mergedTranslation.id}?`,
      onOk() {
        Promise.all(mergedTranslation.keys.map((k) => k.delete()));
      },
    });
  };

  const handleAddLanguage = () => {
    let language = missingLanguages[0]?.value;
    if (language) {
      Modal.confirm({
        content: (
          <Select defaultValue={language} style={{ minWidth: 120 }} onChange={(value) => (language = value)}>
            {missingLanguages.map((lang) => (
              <Select.Option key={lang.value} value={lang.value}>
                {lang.label}
              </Select.Option>
            ))}
          </Select>
        ),
        okText: 'Add Language',
        onOk() {
          new TranslationKey({
            language: language.toLowerCase().trim(),
            key: mergedTranslation.id,
            namespace: mergedTranslation.namespace,
          })
            .save()
            .then((res) => runInAction(() => mergedTranslation.keys.push(res)));
        },
      });
    }
  };

  const handleAddMissingLanguages = () => {
    Promise.all(
      missingLanguages.map((language) =>
        new TranslationKey({
          language: language.value,
          key: mergedTranslation.id,
          namespace: mergedTranslation.namespace,
        }).save(),
      ),
    ).then((result) => runInAction(() => (mergedTranslation.keys = mergedTranslation.keys.concat(result))));
  };

  const handleCopy = (e: React.MouseEvent) => {
    if (copy(mergedTranslation.id)) {
      e.preventDefault();
      messageApi.success('Copied to clipboard');
    }
  };

  return (
    <Card
      size="small"
      title={
        <Space align="center">
          {contextHolder}
          <a href="#" onClick={handleCopy}>
            {mergedTranslation.id}
          </a>
          <Select value={mergedTranslation.namespace} style={{ minWidth: 360 }} disabled>
            <Select.Option value="">&lt;all namespaces&gt;</Select.Option>
            {namespaces.map((ns) => (
              <Select.Option key={ns} value={ns}>
                {ns}
              </Select.Option>
            ))}
          </Select>
        </Space>
      }
      extra={
        <Space>
          <Button danger size="small" onClick={handleDeleteKey}>
            Delete Key
          </Button>
          {missingLanguages.length > 0 && (
            <>
              <Button type="primary" size="small" onClick={handleAddLanguage}>
                Add Language
              </Button>
              <Button type="primary" size="small" onClick={handleAddMissingLanguages}>
                Add Missing Languages
              </Button>
            </>
          )}
        </Space>
      }
    >
      <Row gutter={[16, 16]}>
        {mergedTranslation.keys.map((trans) => (
          <Col xs={24} sm={12} key={trans.language}>
            <TranslationTableRowEntry translation={mergedTranslation} entry={trans} />
          </Col>
        ))}
      </Row>
    </Card>
  );
});
