/**
 * @flow
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Input } from 'reactstrap';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import { BodyPartRegionAdmin } from '../../../../Model/BodyPart/BodyPartRegionAdmin';
import { ExerciseVariationAdmin } from '../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { useEffect } from 'react';
import { ExerciseVariationAdminTable } from '../../Exercise/ExerciseListScreen/ExerciseVariationAdminTable';

export type BodyPartExercisesTabProps = {
  bodyPart: BodyPartRegionAdmin;
};

export const BodyPartExercisesTab: React.FC<BodyPartExercisesTabProps> = observer(
  ({ bodyPart }: BodyPartExercisesTabProps) => {
    const debounce = React.useRef<any>(undefined);
    const store = useLocalStore(() => ({
      page: 0,
      result: new Array<ExerciseVariationAdmin>(),
      query: '',
      async load(bodyPartId: string, page: number) {
        try {
          const result = await ExerciseVariationAdmin.find({
            query: this.query,
            page,
            bodyPartIds: bodyPartId,
          });
          runInAction(() => {
            store.result = result;
          });
        } finally {
        }
      },
    }));

    useEffect(() => {
      store.load(bodyPart.id, store.page);
    }, [bodyPart.id]);

    const handlePage = React.useCallback(
      (page: number) => {
        if (store.page !== page) {
          store.page = page;
          store.load(bodyPart.id, store.page);
        }
      },
      [store, bodyPart.id],
    );

    const handleQueryChange = React.useCallback(
      ({ target: { value } }) => {
        debounce.current && clearTimeout(debounce.current);
        store.query = value;
        debounce.current = window.setTimeout(() => {
          store.page = 0;
          store.load(bodyPart.id, store.page);
        }, 250);
      },
      [store, bodyPart.id],
    );

    return (
      <React.Fragment>
        <SingleColRow>
          <Input type="text" value={store.query} onChange={handleQueryChange} />
        </SingleColRow>
        <SingleColRow>
          {/*<Pager onPage={handlePage} page={store.result} />*/}
        </SingleColRow>
        <SingleColRow>
          <ExerciseVariationAdminTable variations={store.result} query={store.query} />
        </SingleColRow>
        <SingleColRow>
          {/*<Pager onPage={handlePage} page={store.result} />*/}
        </SingleColRow>
      </React.Fragment>
    );
  },
);
