import { StagePort } from './StagePort';
import { Stage } from '../Stages/Stage';
import { StageInPort } from './StageInPort';

export class StageOutPort extends StagePort {
  constructor(stage: Stage<any>, type: string = 'default', inPort?: StageInPort) {
    super(stage, type);
    this.otherPort = inPort;
  }
}
