/**
 *
 * Created by neo on 06.03.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useRootStore } from '../../../../Store/useRootStore';
import { Button, Space } from 'antd';
import { CoachProgram } from '../../../../Model/Coach/Program/CoachProgram';
import dayjs from 'dayjs';

export type AthleteCoachProgramTableRowProps = {
  program: CoachProgram;
  onRemove?: (program: CoachProgram) => void;
};

export const AthleteCoachProgramTableRow: React.FC<AthleteCoachProgramTableRowProps> = observer(
  ({ program, onRemove }: AthleteCoachProgramTableRowProps) => {
    const { authentication } = useRootStore();

    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(program);
    }, [onRemove, program]);

    return (
      <tr>
        <td>
          <Link to={`/coach/fitness-program/${program.id}`}>{program.template?.templateName}</Link>
        </td>
        <td>{program.startDateTime ? dayjs(program.startDateTime).format('DD.MM.YYYY HH:mm') : '-'}</td>
        <td>{program.endDateTime ? dayjs(program.endDateTime).format('DD.MM.YYYY HH:mm') : '-'}</td>
        <td>{dayjs(program.createdAt).format('DD.MM.YYYY HH:mm')}</td>
        <td>
          <Space>
            {onRemove && authentication.isOneAboveAll && !program.endDateTime ? (
              <React.Fragment>
                <Button danger onClick={handleRemove}>
                  End
                </Button>
              </React.Fragment>
            ) : null}
          </Space>
        </td>
      </tr>
    );
  },
);
