import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  InputNumber,
  Card,
  Upload,
  Avatar,
  message,
  Select,

} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { runInAction } from 'mobx';
import { Gym } from '../../../../../Model/Gym/Gym';
import MediaService from '../../../../../Services/MediaService';

export type GymBasicInfoTabProps = {
  gym: Gym;
  onSave?: () => any;
  isNew?: boolean;
};

export const GymBasicInfoTab: React.FC<GymBasicInfoTabProps> = observer(({ gym, onSave, isNew }) => {
  const [form] = Form.useForm();
  const [uploading, setUploading] = useState(false);

  const handleCoBrandingCheck = useCallback(
    ({ target: { checked } }) => runInAction(() => (gym.axaCoBrandingEnabled = checked)),
    [gym],
  );

  const handleAllowDomainSignupCheck = useCallback(
    ({ target: { checked } }) => runInAction(() => (gym.allowDomainSignup = checked)),
    [gym],
  );

  const beforeUpload = (file: File) => {
    const isImage = file.type.indexOf('image/') === 0;
    if (!isImage) {
      message.error('You can only upload image files!');
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('Image must be smaller than 10MB!');
    }
    return isImage && isLt10M;
  };

  const handleDomainsChange = (values: string[]) => {
    runInAction(() => {
      gym.domains.splice(0, gym.domains.length, ...values);
    });
  };

  const customUpload = async (options: any) => {
    const { file, onSuccess, onError } = options;
    try {
      setUploading(true);
      const result = await MediaService.uploadMedia(file);
      runInAction(() => (gym.logo = result));
      onSuccess(result, file);
      message.success('Logo uploaded successfully');
    } catch (err) {
      onError(err);
      message.error('Logo upload failed');
    } finally {
      setUploading(false);
    }
  };

  const handleLogoChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setUploading(true);
    }
    if (info.file.status === 'done' || info.file.status === 'error') {
      setUploading(false);
    }
  };

  return (
    <Card bordered={false}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          name: gym.name || '',
          customerLimit: gym.customerLimit,
          totalPotentialUsers: gym.totalPotentialUsers,
          axaCoBrandingEnabled: gym.axaCoBrandingEnabled,
          allowDomainSignup: gym.allowDomainSignup,
          domains: gym.domains || [],
        }}
        onFinish={onSave}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter the gym name' }]}>
              <Input placeholder="Gym name" onChange={(e) => (gym.name = e.target.value)} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="AXA Co-Branding" name="axaCoBrandingEnabled" valuePropName="checked">
              <Checkbox onChange={handleCoBrandingCheck}>Enabled</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Customer Limit" name="customerLimit">
              <InputNumber
                placeholder="Limit"
                min={0}
                style={{ width: '100%' }}
                onChange={(value) => runInAction(() => (gym.customerLimit = value && value > 0 ? value : undefined))}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Total Potential Users" name="totalPotentialUsers">
              <InputNumber
                placeholder="Total users"
                min={0}
                style={{ width: '100%' }}
                onChange={(value) => runInAction(() => (gym.totalPotentialUsers = value && value > 0 ? value : 0))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} align="middle">
          <Col xs={24} md={16}>
            <Form.Item label="Domains" name="domains">
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Enter domains"
                onChange={handleDomainsChange}
              />
            </Form.Item>
            <Form.Item name="allowDomainSignup" extra="If not set, then users can only join via invite.">
              <Checkbox onChange={handleAllowDomainSignupCheck} checked={gym.allowDomainSignup}>
                Allow Domain Signup
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} md={8} style={{ textAlign: 'center' }}>
            <Form.Item label="Gym Logo">
              <Upload
                name="logo"
                showUploadList={false}
                customRequest={customUpload}
                beforeUpload={beforeUpload}
                onChange={handleLogoChange}
              >
                <Avatar
                  shape="square"
                  size={128}
                  src={gym.logo?.url}
                  icon={<UploadOutlined />}
                  style={{ cursor: 'pointer', marginBottom: 8 }}
                />
              </Upload>
              <div>{uploading ? 'Uploading...' : 'Click the logo to change'}</div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
});
