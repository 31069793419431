/**
 *
 * Created by neo on 25.02.17.
 */
import { observer } from 'mobx-react';
import * as React from 'react';
import { TranslationInputArrayEntry, TranslationInputArrayEntryProps } from './TranslationInputArrayEntry';
import { LocalizedValue } from '../../Model/LocalizedValue';
import { SingleColRow } from '../SingleColRow';
import { Form, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TranslationInputArrayContainer } from './TranslationInputArrayContainer';
import { EMPTY_ARRAY } from '../../Utils/Constants';
import { InputProps } from 'reactstrap/types/lib/Input';
import { TranslationService } from '../../Services/TranslationService';
import { useState } from 'react';

const defaultLanguages = ['en', 'de', 'fr', 'it']; //, 'es', 'cn', 'fi'];

export type TranslationInputArrayProps = Omit<TranslationInputArrayEntryProps, 'language' | 'values'> & {
  entity: any;
  field: string;
  label?: string;
  type?: InputProps['type'];
  languages?: string[];
  colSize?: number;
};

export const TranslationInputArray: React.FC<TranslationInputArrayProps> = observer(
  ({ entity, label, languages = defaultLanguages, field, type, disabled, colSize = 12, ...moreProps }) => {
    const [adding, setAdding] = useState(false);

    const valueArray = entity[field] ?? EMPTY_ARRAY;
    const existingKeys = valueArray.map((l) => l.lang);
    const missingTranslations = (languages || defaultLanguages).filter(
      (l) => existingKeys.findIndex((k) => k === l) === -1,
    );
    const canAddTranslation = missingTranslations.length > 0;

    const handleAddTranslation = React.useCallback(() => {
      setAdding(true);
      return Promise.all(
        missingTranslations.map((nextKey) => {
          const source =
            valueArray.find((e) => e.lang === 'de' && e.lang !== nextKey && !!e.value.trim()) ??
            valueArray.find((e) => e.lang === 'en' && e.lang !== nextKey && !!e.value.trim()) ??
            valueArray.find((e) => e.lang !== nextKey && !!e.value.trim());
          if (source) {
            return TranslationService.openai({
              text: source.value,
              sourceLanguage: source.lang,
              targetLanguage: nextKey,
            }).then((result) => valueArray.push(new LocalizedValue({ lang: nextKey, value: result ?? '' })));
          } else {
            valueArray.push(new LocalizedValue({ lang: nextKey, value: '' }));
            return Promise.resolve();
          }
        }),
      ).finally(() => setAdding(false));
    }, [missingTranslations, valueArray]);

    return (
      <TranslationInputArrayContainer label={label}>
        <SingleColRow>
          {existingKeys.map((k) => (
            <TranslationInputArrayEntry
              key={k}
              language={k}
              values={valueArray}
              type={type}
              disabled={disabled}
              {...moreProps}
            />
          ))}
        </SingleColRow>
        {canAddTranslation ? (
          <Form.Item>
            <Button
              type="dashed"
              onClick={handleAddTranslation}
              disabled={adding || disabled}
              style={{ width: '100%' }}
            >
              <PlusOutlined /> Add missing translations
            </Button>
          </Form.Item>
        ) : null}
      </TranslationInputArrayContainer>
    );
  },
);
