/**
 * Created by neo on 18.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Recipe } from '../../../../Model/Diet/Recipe/Recipe';
import { RecipeInstruction } from '../../../../Model/Diet/Recipe/RecipeInstruction';
import { RecipeInstructionStep } from '../../../../Model/Diet/Recipe/RecipeInstructionStep';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';
import { Button, Card, Form } from 'antd';
import AsyncSelect from 'react-select/async';
import { Ingredient } from '../../../../Model/Diet/Ingredient/Ingredient';
import { isObservableArray, runInAction } from 'mobx';
import { RecipeIngredient } from '../../../../Model/Diet/Recipe/RecipeIngredient';
import { Col, Row } from 'reactstrap';
import { RecipeEquipment } from '../../../../Model/Diet/Recipe/RecipeEquipment';
import { DietEquipment } from '../../../../Model/Diet/Equipment/DietEquipment';

export type RecipeEditModalInstructionStepEntryProps = {
  recipe: Recipe;
  instruction: RecipeInstruction;
  step: RecipeInstructionStep;
  index: number;
  onRemove?: (index: number) => void;
};

export const RecipeEditModalInstructionStepEntry: React.FC<RecipeEditModalInstructionStepEntryProps> = observer(
  ({ recipe, instruction, step, index, onRemove }) => {
    const fetch = React.useCallback(
      (query?: string) =>
        Ingredient.search({
          query,
        }).then((res) => res.filter((b) => !step.ingredients?.find((b1) => b1 === b.id))),
      [step],
    );

    const fetchEquipment = React.useCallback(
      (query?: string) =>
        DietEquipment.search({
          query,
        }).then((res) => res.filter((b) => !step.equipment?.find((b1) => b1.id === b.id))),
      [step],
    );

    const handleChangeIngredients = React.useCallback(
      (bodyParts?: Ingredient[]) => {
        const newData = bodyParts ?? [];
        if (isObservableArray(step.ingredients)) {
          step.ingredients.replace(newData.map((b) => b.id));
        } else {
          step.ingredients.splice(0, step.ingredients.length);
          newData.forEach((b) => step.ingredients.push(b.id));
        }
        newData
          .filter((ingredient) => !recipe.ingredients.some((i) => i.id === ingredient.id))
          .forEach((ingredient) => runInAction(() => recipe.ingredients.push(new RecipeIngredient(ingredient.toJS()))));
      },
      [step, recipe],
    );

    const handleChangeEquipment = React.useCallback(
      (equipments?: DietEquipment[]) => {
        const newData = equipments ?? [];
        if (isObservableArray(step.equipment)) {
          step.equipment.replace(newData.map((b) => b.id));
        } else {
          step.equipment.splice(0, step.equipment.length);
          newData.forEach((b) => step.equipment.push(new RecipeEquipment(b.toJS())));
        }
      },
      [step],
    );

    const getOptionLabel = React.useCallback((option: Ingredient) => `${option.defaultName}`, []);

    const getOptionValue = React.useCallback((option: Ingredient) => option, []);

    const getEquipmentOptionLabel = React.useCallback((option: RecipeEquipment) => `${option.defaultName}`, []);

    const getEquipmentOptionValue = React.useCallback((option: RecipeEquipment) => option, []);

    const handleRemove = React.useCallback(() => onRemove && onRemove(index), [onRemove, index]);

    return (
      <Card>
        <Row>
          <Col>
            <h6>{`${step.number}. Step`}</h6>
          </Col>
          <Col xs="auto">
            <Button type="primary" danger onClick={handleRemove}>
              Remove Step
            </Button>
          </Col>
        </Row>
        <SingleColRow>
          <TranslationInputArray entity={step} field="step" type="textarea" />
        </SingleColRow>
        <Row>
          <Col md={6}>
            <Form.Item label="Ingredients">
              <AsyncSelect
                isMulti={true}
                isClearable={true}
                loadOptions={fetch}
                value={step.ingredientData ?? []}
                hideSelectedOptions={false}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue as any}
                onChange={handleChangeIngredients as any}
              />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item label="Equipment">
              <AsyncSelect
                isMulti={true}
                isClearable={true}
                loadOptions={fetchEquipment}
                value={step.fullEquipment ?? []}
                hideSelectedOptions={false}
                getOptionLabel={getEquipmentOptionLabel}
                getOptionValue={getEquipmentOptionValue as any}
                onChange={handleChangeEquipment as any}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    );
  },
);
