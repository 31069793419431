/**
 * Created by neo on 02.08.22.
 */
import { observable } from 'mobx';
import { EarnModelRuleTimeConstraint, EarnModelRuleTimeConstraintJson } from './EarnModelRuleTimeConstraint';
import { EarnModelTemplateRuleAbstract, EarnModelTemplateRuleAbstractJson } from './EarnModelTemplateRuleAbstract';
import { EarnModelTemplateRuleSimple, EarnModelTemplateRuleSimpleJson } from './EarnModelTemplateRuleSimple';

export type EarnModelTemplateRuleGroupedJson = EarnModelTemplateRuleAbstractJson & {
  timeConstraint: EarnModelRuleTimeConstraintJson;
  rules: EarnModelTemplateRuleSimpleJson[];
};

export class EarnModelTemplateRuleGrouped extends EarnModelTemplateRuleAbstract {
  @observable
  timeConstraint = new EarnModelRuleTimeConstraint();
  @observable
  rules: EarnModelTemplateRuleSimple[] = [];

  constructor(json?: Partial<EarnModelTemplateRuleGroupedJson>) {
    super(Object.assign({}, json ?? {}, { type: 'grouped' }));
    if (json) {
      this.timeConstraint = new EarnModelRuleTimeConstraint(json.timeConstraint);
      this.rules = json.rules?.map((r) => new EarnModelTemplateRuleSimple(r)) ?? [];
    }
  }

  toJS(newId: boolean = false): EarnModelTemplateRuleGroupedJson {
    return Object.assign(super.toJS(newId), {
      timeConstraint: this.timeConstraint?.toJS(),
      rules: this.rules.map((r) => r.toJS()),
    });
  }

  copy(): EarnModelTemplateRuleGrouped {
    return new EarnModelTemplateRuleGrouped(this.toJS(true));
  }
}
