/**
 * Created by neo on 02.02.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { WorkoutTemplateEntry } from '../../../../../Model/Explore/WorkoutTemplateEntry';
import { Col, Row } from 'reactstrap';
import { Form } from 'antd';
import { EquipmentTypeInput } from '../../../../../Components/EquipmentTypeInput';
import CreatableSelect from 'react-select/creatable';
import { notUndefined } from '../../../../../Utils/notUndefined';
import { runInAction } from 'mobx';

const allowedTags = [
  'duration:short',
  'duration:medium',
  'duration:long',
  'age:junior',
  'age:adult',
  'age:senior',
  'age:elder',
  'gender:male',
  'gender:female',
  'activity:sitting',
  'activity:standing',
  'activity:physical',
  'equipment:yes',
  'equipment:no',
  'equipment:bottle',
  'level:beginner',
  'level:intermediate',
  'level:advanced',
].sort((a, b) => a.localeCompare(b));

const defaultOptions = allowedTags.map((value) => ({ value, label: value }));

export type PipelineContextEditorProps = {
  entry: WorkoutTemplateEntry;
};

export const PipelineContextEditor: React.FC<PipelineContextEditorProps> = observer(({ entry }) => {
  const includedTags = entry.context.tags.map((value) => ({ label: value, value }));
  const includedTagCategories = entry.context.tags.map((t) => t.split(':')[0]).filter(notUndefined);

  const allowedIncludedTags = defaultOptions.filter(
    ({ value }) => !includedTagCategories.some((cat) => value.startsWith(cat)),
  );

  const handleTagsChange = React.useCallback(
    (tags?: any[]) => {
      runInAction(() => {
        entry.context.tags.splice(0, entry.context.tags.length);
        (tags ?? []).map((t) => t.value).forEach((t) => entry.context.tags.push(t));
      });
    },
    [entry],
  );

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Form.Item label="Tags">
            <CreatableSelect
              isClearable
              isMulti
              options={allowedIncludedTags}
              onChange={handleTagsChange as any}
              value={includedTags}
              placeholder="Tags"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Equipment Types">
            <EquipmentTypeInput container={entry.context.equipmentTypes} />
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
});
