/**
 * Created by neo on 01.02.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, DatePicker, Form, Input, Select, Tag, TimePicker } from 'antd';
import { Col, Row } from 'reactstrap';
import { MultiRowMap } from '../../../../Security/OAuth/View/MultiRowMap';
import { ZoomMeetingEntry } from '../../../../../Model/Explore/ZoomMeetingEntry';
import { Activity } from '../../../../../Model/Activity/Activity';
import AsyncSelect from 'react-select/async';
import { runInAction } from 'mobx';
import dayjs from 'dayjs';
import { InstructorEdit } from './InstructorEdit';
import { Instructor } from '../../../../../Model/Explore/Instructor';
import { RecurringPattern } from '../../../../../Model/Coach/Schedule/RecurringPattern';
import { RecurringPatternEditor } from './RecurringPatternEditor';
import { Timezones } from '../../../../../Utils/Timezones';

export type ZoomMeetingEntryContentProps = {
  entry: ZoomMeetingEntry;
};

export const ZoomMeetingEntryContent: React.FC<ZoomMeetingEntryContentProps> = observer(({ entry }) => {
  const handleChangeActivity = React.useCallback(
    (activity: Activity | undefined) => {
      entry.activityIdentifier = activity?.identifier ?? '';
    },
    [entry],
  );

  const fetchActivity = React.useCallback((query: string) => Activity.find({ query }), []);

  const getOptionLabel = React.useCallback((option: Activity) => `${option.defaultName} (${option.identifier})`, []);

  const getOptionValue = React.useCallback((option: Activity) => option, []);

  const handleChangeMeetingId = React.useCallback(
    ({ target: { value } }) => runInAction(() => (entry.meetingId = value.trim().replace(/\s/g, ''))),
    [entry],
  );

  const handleChangePassword = React.useCallback(
    ({ target: { value } }) => runInAction(() => (entry.password = value.trim() ? value.trim() : undefined)),
    [entry],
  );

  const handleChangeStartDate = React.useCallback(
    (date) =>
      runInAction(() => (entry.startDate = date?.format('YYYY-MM-DD') ?? dayjs().add(1, 'day').format('YYYY-MM-DD'))),
    [entry],
  );

  const handleChangeStartTime = React.useCallback(
    (date, dateString) => runInAction(() => (entry.startTime = dateString ?? entry.startTime)),
    [entry],
  );

  const handleChangeTimezone = React.useCallback(
    (timezone: string) => runInAction(() => (entry.timezone = timezone)),
    [entry],
  );

  const handleChangeDuration = React.useCallback(
    (date, dateString) => {
      if (date) {
        runInAction(() => (entry.duration = dayjs.duration(dayjs(date.toDate()).diff(dayjs(0))).toISOString()));
      }
    },
    [entry],
  );

  const handleChangeRecurring = React.useCallback(
    ({ target: { checked } }) => {
      if (checked) {
        runInAction(() => (entry.recurringPattern = new RecurringPattern()));
      } else {
        runInAction(() => (entry.recurringPattern = undefined));
      }
    },
    [entry],
  );

  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <Form.Item label="Meeting ID">
            <Input value={entry.meetingId} onChange={handleChangeMeetingId} />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label="Password" extra="Optional: Just if there is a password for the meeting">
            <Input value={entry.password} onChange={handleChangePassword} />
          </Form.Item>
        </Col>
        <Col xs={3}>
          <Form.Item label="Meeting Date">
            <DatePicker
              value={dayjs(entry.startDateTime?.toDate())}
              disabledDate={(date) => !date.isAfter(dayjs().startOf('day'))}
              onChange={handleChangeStartDate}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col xs={3}>
          <Form.Item label="Meeting Time" extra={entry.localStartDateTime?.format('DD.MM.YYYY HH:mm')}>
            <TimePicker
              value={dayjs(entry.startTime, 'HH:mm')}
              showSecond={false}
              format="HH:mm"
              onChange={handleChangeStartTime}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col xs={3}>
          <Form.Item label="Meeting Duration">
            <TimePicker
              value={dayjs.utc(entry.durationDayjs.toDate())}
              showSecond={false}
              format="HH:mm"
              onChange={handleChangeDuration}
              minuteStep={5}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col xs={3}>
          <Form.Item label="Timezone">
            <Select value={entry.timezone} onChange={handleChangeTimezone}>
              {Timezones.map((v) => (
                <Select.Option key={v} value={v}>
                  {v}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Checkbox checked={!!entry.recurringPattern} onChange={handleChangeRecurring}>
            Recurring Meeting?
          </Checkbox>
          {entry.recurringPattern && <RecurringPatternEditor recurringPattern={entry.recurringPattern} />}
          {entry.recurringPattern && (
            <React.Fragment>
              {entry.possibleDates.map((date) => (
                <Tag key={date.toISOString()}>{date.format('DD.MM.YYYY HH:mm')}</Tag>
              ))}
            </React.Fragment>
          )}
        </Col>
        <Col md={12}>
          <Form.Item label="Hosts">
            <Row>
              {entry.hosts.map((host, index) => (
                <Col md={3} key={index.toString()}>
                  <InstructorEdit
                    key={index}
                    index={index}
                    instructor={host}
                    onRemove={() => runInAction(() => entry.hosts.splice(index, 1))}
                  />
                </Col>
              ))}
            </Row>
            <Button type="primary" onClick={() => runInAction(() => entry.hosts.push(new Instructor()))}>
              Add Host
            </Button>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="Activity"
            extra="This is optional. If set we will log an activity after the user leaves the meeting"
          >
            <AsyncSelect
              defaultOptions
              isClearable={true}
              loadOptions={fetchActivity}
              value={entry.activity}
              hideSelectedOptions={false}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue as any}
              onChange={handleChangeActivity as any}
            />
          </Form.Item>
        </Col>
        <Col md={6}>
          <MultiRowMap label="Data" container={entry.data} />
        </Col>
      </Row>
    </React.Fragment>
  );
});
