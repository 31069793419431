import { Stage } from './Stage';
import objectPath from 'object-path';
import { DataSignal } from '../Signal/DataSignal';

export type SortStageOrder = 'ascending' | 'descending' | 'custom';

const bodyPartSizes = ['huge', 'large', 'medium', 'small', 'tiny'];

export interface SortStageConfig {
  attribute: string;
  order?: SortStageOrder;
  customSort?: Array<any>;
}

export class SortStage extends Stage<SortStageConfig> {
  signals: DataSignal[] = [];

  process() {
    const { dataSignals: input } = this;
    if (this.config.attribute === 'bodyPartSizeDesc' || this.config.attribute === 'bodyPartSizeAsc') {
      this.sortBodyPart(input)
        .map((data, index) => new DataSignal(data, index))
        .forEach((signal) => this.processNext(signal));
    } else if (this.config.customSort && this.config.customSort.length > 0) {
      this.sortCustom(this.config.customSort, input)
        .map((signal, index) => new DataSignal(signal.data, index))
        .forEach((signal) => this.processNext(signal));
    } else {
      input
        .sort((exA, exB) => {
          const a = objectPath.get(exA.data, this.config.attribute);
          const b = objectPath.get(exB.data, this.config.attribute);
          if (this.config.order === 'descending') {
            if (a < b) {
              return -1;
            } else if (a > b) {
              return 1;
            }
            return 0;
          }
          if (a > b) {
            return -1;
          } else if (a < b) {
            return 1;
          }
          return 0;
        })
        .map((signal, index) => new DataSignal(signal.data, index))
        .forEach((signal) => this.processNext(signal));
    }
  }

  private sortCustom(sort: Array<string>, input: DataSignal[]) {
    // if (this.attributeIsBodyPart()) {
    //
    // }
    const copy = input.slice(0);
    const result = new Array<any>(input.length);
    for (let i = 0, l = input.length; i < l; i++) {
      const sortValue = sort[i % sort.length];
      const entryIndex = copy.findIndex((signal) => objectPath.get(signal.data, this.config.attribute) === sortValue);
      // console.log(
      //   'sorting',
      //   sortValue,
      //   entryIndex,
      //   copy.map((c) => c.utility),
      // );
      if (entryIndex === -1) {
        result[i] = copy.splice(0, 1)[0];
      } else {
        result[i] = copy.splice(entryIndex, 1)[0];
      }
    }
    return result;
  }

  private sortBodyPart(input: DataSignal[]) {
    const copy = input.slice(0);
    return copy.sort((a, b) =>
      this.config.attribute === 'bodyPartSizeAsc'
        ? (a.data?.biggestBodyPart?.sortIndex ?? 2) - (b.data?.biggestBodyPart?.sortIndex ?? 2)
        : (b.data?.biggestBodyPart?.sortIndex ?? 2) - (a.data?.biggestBodyPart?.sortIndex ?? 2),
    );
  }

  get type(): string {
    return 'sort';
  }
}
