/**
 * Created by neo on 02.11.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ActivityLog } from '../../../../Model/Activity/ActivityLog';
import { Table } from 'reactstrap';
import { ActivityLogTableRow } from './ActivityLogTableRow';

export type ActivityLogTableProps = {
  activityLogs: ActivityLog[];
};

export const ActivityLogTable: React.FC<ActivityLogTableProps> = observer(({ activityLogs }) => {
  return (
    <Table size="sm" striped>
      <thead>
        <tr>
          <td>Date</td>
          <td>From</td>
          <td>To</td>
          <td>Activity</td>
          <td>Data</td>
          <td>Source</td>
        </tr>
      </thead>
      <tbody>
        {activityLogs.map((activityLog) => (
          <ActivityLogTableRow key={activityLog.id} activityLog={activityLog} />
        ))}
      </tbody>
    </Table>
  );
});
