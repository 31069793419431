/**
 * Created by neo on 05.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { WorkoutEntry } from '../../../../../Model/Coach/SuperMacro/WorkoutEntry';
import styled from '@emotion/styled';
import { WorkoutEntryDropZone } from './WorkoutEntryDropZone';
import { Button, Popconfirm } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { WorkoutEntryModal } from '../WorkoutEntryModal';
import { FitnessProgramTemplate } from '../../../../../Model/Coach/Program/Template/FitnessProgramTemplate';
import { WorkoutDayEntry } from '../../../../../Model/Coach/Program/Template/WorkoutDayEntry';
import { DeleteOutlined } from '@mui/icons-material';

export type WorkoutEntryEditorProps = {
  template: FitnessProgramTemplate;
  workoutEntry: WorkoutDayEntry;
  onRemove?: (workoutEntry: WorkoutEntry) => any;
  onEdit?: (workoutEntry: WorkoutEntry) => void;
};

const Container = styled.div`
  color: black;
  background-color: #414bb2;
  font-family: Helvetica, Arial;
  font-size: 12px;
  padding: 5px;
  color: white;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px;
`;

const Title = styled.div`
  flex: 1;
  font-size: 10px;
`;

const Extra = styled.div`
  font-size: 10px;

  a,
  a:visited {
    font-size: 12px;
    color: #ff4d4f;

    &:hover,
    &:active {
      color: #ff7875;
    }
  }
`;

const Body = styled.div``;

export const WorkoutEntryEditor: React.FC<WorkoutEntryEditorProps> = observer(
  ({ template, workoutEntry, onRemove }) => {
    const [over, setOver] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(workoutEntry);
    }, [onRemove, workoutEntry]);

    const handleEdit = React.useCallback(() => {
      setModalVisible(true);
    }, []);

    const handleCloseModal = React.useCallback(() => {
      setModalVisible(false);
    }, []);

    const handleDragOver = React.useCallback((event) => {
      console.log('dragOver');
      event.preventDefault();
      setOver(true);
    }, []);

    const handleDragLeave = React.useCallback((event) => {
      console.log('dragLEave');
      event.preventDefault();
      setOver(false);
    }, []);

    const handleDrop = React.useCallback((workoutEntry) => {
      setOver(false);
    }, []);

    return (
      <React.Fragment>
        <Container onDragOver={handleDragOver} onDragLeave={handleDragLeave}>
          <Header>
            <Title>{workoutEntry.includedTags.join(', ')}</Title>
            <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={handleEdit} />
            <Popconfirm title={`Remove ${workoutEntry.workoutTemplate?.templateName}?`} onConfirm={handleRemove}>
              <Button type="primary" danger shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Header>
          <Body>
            <WorkoutEntryDropZone workoutEntry={workoutEntry} onDrop={handleDrop} />
          </Body>
        </Container>
        {modalVisible && (
          <WorkoutEntryModal template={template} workoutEntry={workoutEntry} onClose={handleCloseModal} />
        )}
      </React.Fragment>
    );
  },
);
