/**
 * Created by neo on 15.12.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DatePicker, Form, Modal, Select } from 'antd';
import { useNavigate } from 'react-router';
import { Col, Row } from 'reactstrap';
import dayjs from 'dayjs';
import { runInAction } from 'mobx';
import { AthleteCoachSubscription } from '../../../../Model/Subscription/AthleteCoachSubscription';
import { SubscriptionPlan } from '../../../../Model/Subscription/SubscriptionPlan';

export type AthleteCoachSubscriptionModalProps = {};

export const AthleteCoachSubscriptionModal: React.FC<AthleteCoachSubscriptionModalProps> = observer((props) => {
  const { subscriptionId, athleteId } = useParams<{ subscriptionId: string; athleteId: string }>();
  const [subscription, setSubscription] = useState(new AthleteCoachSubscription());
  const [saving, setSaving] = useState(false);
  const history = useNavigate();
  const isNew = subscriptionId === 'new';

  useEffect(() => {
    if (subscriptionId && subscriptionId !== 'new') {
      AthleteCoachSubscription.get(subscriptionId).then((result) => setSubscription(result));
    } else {
      setSubscription(new AthleteCoachSubscription({ athleteId }));
    }
  }, [subscriptionId, athleteId]);

  const handleClose = React.useCallback(() => {
    history(-1);
  }, [history]);

  const handleSave = React.useCallback(() => {
    setSaving(true);
    subscription
      .save()
      .finally(() => setSaving(false))
      .then(() => history(-1));
  }, [subscription, history]);

  const handleChangePlan = React.useCallback(
    (value: SubscriptionPlan) => runInAction(() => (subscription.plan = value)),
    [subscription],
  );

  const handleChangeValidFrom = React.useCallback(
    (date) => runInAction(() => (subscription.validFrom = date?.toDate() ?? new Date())),
    [subscription],
  );

  const handleChangeValidUntil = React.useCallback(
    (date) => runInAction(() => (subscription.validUntil = date?.toDate())),
    [subscription],
  );

  return (
    <Modal
      title={isNew ? 'New Subscription' : subscription.id}
      open={true}
      onOk={handleSave}
      onCancel={handleClose}
      confirmLoading={saving}
    >
      <Form layout="vertical">
        <Row>
          <Col>
            <Form.Item label="Plan">
              <Select value={subscription.plan} onChange={handleChangePlan} disabled={!isNew}>
                <Select.Option value="free">Free</Select.Option>
                <Select.Option value="standard">Standard</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item label="Plan">
              <DatePicker value={dayjs(subscription.validFrom)} onChange={handleChangeValidFrom} disabled={!isNew} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Plan">
              <DatePicker
                value={subscription.validUntil ? dayjs(subscription.validUntil) : undefined}
                onChange={handleChangeValidUntil}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
