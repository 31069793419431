/**
 * Created by neo on 30.10.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'reactstrap';
import { TestTableRow, TestTableRowProps } from './TestTableRow';
import { WorkoutResponse } from '../../../../../Model/Coach/Script/WorkoutResponse';

export type TestTableProps = {
  result: WorkoutResponse[];
  tags?: string[];
} & Pick<TestTableRowProps, 'onRetest'>;

export const TestTable: React.FC<TestTableProps> = observer(({ result, onRetest, tags = [] }: TestTableProps) => {
  return (
    <Table size="sm" striped>
      <thead>
        <tr>
          <th>#</th>
          <th>Media</th>
          <th>Tags</th>
          <th>Duration</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {result
          .sort((a, b) => a.sortedTags.join(',').localeCompare(b.sortedTags.join(',')))
          .filter((workout) => (!tags || tags.length > 0 ? workout.hasTags(tags) : true))
          .map((workout, index) => (
            <TestTableRow key={workout.id} workout={workout} index={index} onRetest={onRetest} />
          ))}
      </tbody>
    </Table>
  );
});
