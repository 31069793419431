// File: application/src/App/View/MetaData/Translations/TranslationTableRowEntry.tsx
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Popconfirm } from 'antd';
import { runInAction } from 'mobx';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { TranslationKey } from '../../../Model/Translation/TranslationKey';
import { MergedTranslation } from '../../../Model/Translation/MergedTranslation';

export type TranslationTableRowEntryProps = {
  translation: MergedTranslation;
  entry: TranslationKey;
};

export const TranslationTableRowEntry: React.FC<TranslationTableRowEntryProps> = observer(({ entry, translation }) => {
  const [input, setInput] = useState(entry.value ?? '');
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    setInput(entry.value ?? '');
  }, [entry.value]);

  const handleChangeInput = React.useCallback(
    ({ target: { value: newInput } }) => {
      setInput(newInput);
      setDirty(newInput !== (entry.value ?? ''));
    },
    [entry.value],
  );

  const handleSave = React.useCallback(() => {
    runInAction(() => (entry.value = input));
    setDirty(false);
    entry.save();
  }, [entry, input]);

  const handleRemove = React.useCallback(() => {
    runInAction(() => {
      const index = translation.keys.findIndex((k) => k.id === entry.id);
      if (index !== -1) {
        translation.keys.splice(index, 1);
      }
    });
    entry.delete();
  }, [entry, translation]);

  return (
    <Row gutter={[4, 4]} style={{ marginBottom: 4 }}>
      <Col span={24}>
        <Row align="middle" justify="space-between">
          <Col>
            <span style={{ fontWeight: 500 }}>{entry.language.toUpperCase()}</span>
          </Col>
          <Col>
            <Popconfirm title="Delete this entry?" onConfirm={handleRemove}>
              <Button type="link" danger icon={<DeleteOutlined />} size="small" />
            </Popconfirm>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Input.TextArea
          value={input}
          onChange={handleChangeInput}
          size="small"
          autoSize={{ minRows: 1 }}
          placeholder="Enter translation..."
        />
      </Col>
      {dirty && (
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" icon={<SaveOutlined />} size="small" onClick={handleSave}>
            Save
          </Button>
        </Col>
      )}
    </Row>
  );
});
