import { Stage } from './Stage';
import { DataSignal } from '../Signal/DataSignal';

export class FlattenStage extends Stage<any> {
  process() {
    this.dataSignals
      .reduce((arr: DataSignal[], signal: DataSignal) => {
        if (Array.isArray(signal.data)) {
          return arr.concat(signal.data.map((entry, index) => new DataSignal(entry, index)));
        }
        arr.push(signal);
        return arr;
      }, new Array<any>())
      .map((signal, index) => new DataSignal(signal.data, index))
      .forEach((signal) => this.processNext(signal));
  }

  get type(): string {
    return 'flatten';
  }
}
