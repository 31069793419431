/**
 * Created by neo on 30.10.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Media } from '../../../../../Model/Media/Media';

export type TestTableRowMediaProps = {
  media: Media;
};

export const TestTableRowMedia: React.FC<TestTableRowMediaProps> = observer(({ media }: TestTableRowMediaProps) => {
  return (
    <div style={{ ...styles.imageContainer, width: 80, height: 80, backgroundImage: `url('${media.smallest}')` }} />
  );
});

const styles = {
  imageContainer: {
    width: 120,
    height: 120,
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  image: {
    width: 120,
    height: 120,
    maxWidth: '100%',
    maxHeight: '100%',
  },
};
