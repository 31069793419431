/**
 * Created by neo on 02.11.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import { PageResult } from '../../../../Model/PageResult';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { Pager } from '../../../../Components/Pager';
import { HttpBackend } from '../../../../Services/Http/HttpBackend';
import { Col, Row, Table } from 'reactstrap';
import dayjs from '../../../../Utils/dayjs';

export type PointsHistoryTabProps = {
  athlete: Athlete;
};

export const PointsHistoryTab: React.FC<PointsHistoryTabProps> = observer(({ athlete }) => {
  const [selectedResult, setSelectedResult] = useState<any>();
  const [result, setResult] = useState(new PageResult<any>());
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    PageResult.execute(
      HttpBackend.get(`/engagement/earn/history`, {
        athleteId: athlete.id,
        page,
        size: 30,
        sort: 'request.eventDateTime,DESC',
      }),
      HttpBackend.get(`/engagement/earn/history/count`, { athleteId: athlete.id }),
      page,
      30,
    ).then((res) => setResult(res));
  }, [athlete, page]);

  useEffect(() => {
    if (selectedResult) {
      const index = result.content.findIndex((r) => r.id === selectedResult.id);
      if (index > -1) {
        const res = result.content
          .slice(0, index + 1)
          .filter((a) => a.allowInChallenge)
          .reduce((acc, cur) => acc + cur.totalPointsCollected, 0);
        setTotal(res);
      }
    } else {
      setTotal(0);
    }
  }, [result, selectedResult]);

  const handlePage = React.useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const selectResult = React.useCallback((res) => {
    setSelectedResult((p) => (p?.id === res.id ? undefined : res));
  }, []);

  return (
    <React.Fragment>
      {selectedResult && total ? (
        <Row>
          <Col xs></Col>
          <Col xs="auto">
            <h3>Challenge Total: {total}</h3>
          </Col>
        </Row>
      ) : null}
      <SingleColRow>
        <Pager page={result} onPage={handlePage} />
      </SingleColRow>
      <SingleColRow>
        <Table size="sm">
          <thead>
          <tr>
            <th>Event Date</th>
            <th>Processing Date</th>
            <th>Event</th>
            <th>Rule</th>
            <th>Points</th>
          </tr>
          </thead>
          <tbody>
          {result.content.map((res) => (
              <tr
                key={res.id}
                onClick={() => selectResult(res)}
                style={{ backgroundColor: res.id === selectedResult?.id ? 'lightgrey' : undefined }}
              >
                <td>
                  {dayjs(res.request.eventDateTime).format('DD.MM.YYYY HH:mm')}
                </td>
                <td>
                  {dayjs(res.createdAt).format('DD.MM.YYYY HH:mm')}
                </td>
                <td>{res.request.event}</td>
                <td>{res.ruleIdentifier}</td>
                <td>
                  <span
                    style={{
                      color: res.allowInChallenge ? 'red' : 'black',
                      fontWeight: res.allowInChallenge ? 'bold' : undefined,
                    }}
                  >
                    {res.pointsCollected}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </SingleColRow>
      <SingleColRow>
        <Pager page={result} onPage={handlePage} />
      </SingleColRow>
    </React.Fragment>
  );
});
