/**
 * Created by neo on 01.06.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Button, Checkbox, DatePicker, Form, Modal, ModalProps, Progress, TimePicker } from 'antd';
import { Col, Row } from 'reactstrap';
import { observable, runInAction } from 'mobx';
import { TagConditionsInput } from '../SuperMacro/View/TagConditionsInput';
import { TagCondition } from '../../../Model/Coach/SuperMacro/TagCondition';
import AsyncSelect from 'react-select/async';
import { ExploreEntry } from '../../../Model/Explore/ExploreEntry';
import { ExploreEntryBuilder } from '../../../Model/Explore/ExploreEntryBuilder';
import { EventEntry } from '../../../Model/Coach/Schedule/EventEntry';
import { RecurringPattern } from '../../../Model/Coach/Schedule/RecurringPattern';
import dayjs from 'dayjs';
import { DayOfWeek } from '../../../Model/DayOfWeek';
import { CoachProgram } from '../../../Model/Coach/Program/CoachProgram';
import { TranslationInputArray } from '../../../Components/Translation/TranslationInputArray';
import { NotificationMessage } from '../../../Model/Explore/NotificationMessage';
import { AthleteConfiguration } from '../../../Model/Athlete/AthleteConfiguration';
import { NotificationRequest } from '../../../Model/Notification/NotificationRequest';
import { DurationBefore, NotificationInterval } from '../../../Model/Coach/Schedule/NotificationInterval';

export type EventSchedulerModalProps = Pick<ModalProps, 'onCancel'> & {};

export const EventSchedulerModal: React.FC<EventSchedulerModalProps> = observer(({ onCancel }) => {
  const [object, setObject] = useState<ExploreEntry | undefined>();
  const [filter] = useState(() =>
    observable({
      estimatedCount: 0,
      processing: false,
      done: false,
      scheduledCount: 0,
      skippedCount: 0,
      tagConditions: new Array<TagCondition>(),
      onlyUnplanned: true,
      type: 'explore',
      template: new EventEntry({
        startDate: dayjs().format('YYYY-MM-DD'),
        startTime: dayjs().format('HH:mm'),
        endTime: dayjs().format('HH:mm'),
        recurringPattern: {
          separationCount: 1,
          daysOfWeek: [],
          type: 'weekly',
        },
      }),
      notification: new NotificationMessage(),
      scheduledAthlete: new Set<string>(),
    }),
  );

  const processMacro = React.useCallback(
    (template: EventEntry, object: ExploreEntry, macro: CoachProgram) => {
      return Promise.all([
        AthleteConfiguration.get(macro.athleteId),
        EventEntry.find({ athleteId: macro.athleteId, parent: true, size: 50 }),
      ]).then(async ([config, events]) => {
        const nonRecipes = events.filter((e) => e.objectType !== 'recipe');
        const sameEntryTypes = nonRecipes.filter((e) => e.objectType === object.type);
        const daysOfWeek =
          object.type === 'workoutTemplate'
            ? filter.template.recurringPattern?.daysOfWeek.filter((d) => !macro.schedule.entries[d]) ?? []
            : filter.template.recurringPattern?.daysOfWeek.filter(
                (d) => !sameEntryTypes.some((e) => e.recurringPattern?.daysOfWeek.some((dw) => dw === d)),
              ) ?? [];

        console.log(
          'processMacro',
          macro.athleteId,
          nonRecipes,
          nonRecipes.map((t) => t.objectType),
          object.type,
          daysOfWeek,
        );
        if (daysOfWeek.length > 0) {
          const eventEntry = await new EventEntry(
            Object.assign(template.toJS(true), {
              athleteId: macro.athleteId,
              objectType: object.type,
              objectId: object.id,
              aiPlanned: true,
              recurringPattern: new RecurringPattern({
                type: 'weekly',
                daysOfWeek,
              }),
              notificationIntervals: [
                new NotificationInterval({
                  durationBefore: DurationBefore.AT_EVENT,
                  title: object.getNotificationTitle(config.language ?? 'de', []),
                  message: object.getNotificationMessage(config.language ?? 'de', []),
                  customData: {
                    name: object.getName(config.language ?? 'de'),
                    link: `https://coach.kinastic.com/explore/${object.type}/${object.id}`,
                    imageUrl: object.image?.smallest,
                  },
                }),
              ],
            }),
          ).save();

          console.log('scheduled event', eventEntry.toJS());

          const title = filter.notification.getTitle(config.language === 'en' ? 'en' : 'de');
          const message = filter.notification.getMessage(config.language === 'en' ? 'en' : 'de');
          if (title && message) {
            const notification = await new NotificationRequest({
              athleteIds: [macro.athleteId],
              title,
              message,
              payload: {
                link: `https://coach.kinastic.com/explore/${object.type}/${object.id}`,
                refreshWorkout: 'true',
              },
            }).send();

            console.log('sending notification to', macro.athleteId, title, message);
          }
          runInAction(() => (filter.scheduledCount += 1));
        } else {
          runInAction(() => (filter.skippedCount += 1));
        }

        return undefined;
      });
    },
    [filter],
  );

  const processMacros = React.useCallback(
    (template: EventEntry, object: ExploreEntry, superMacros: CoachProgram[]) => {
      return Promise.all(superMacros.map((macro) => processMacro(template, object, macro)));
    },
    [processMacro],
  );

  const processCondition = React.useCallback(
    async (template: EventEntry, object: ExploreEntry, cond: TagCondition) => {
      let hasMore = true;
      let page = 0;
      while (hasMore) {
        console.log('processCondition', hasMore, page);
        const superMacros = await CoachProgram.findLatest({
          tags: cond.includedTags,
          excludedTags: cond.excludedTags,
          page,
          size: 10,
        });
        hasMore = superMacros.length >= 10;
        page += 1;
        await processMacros(template, object, superMacros);
      }
    },
    [processMacros],
  );

  const handlePlan = React.useCallback(async () => {
    if (object) {
      runInAction(() => {
        filter.done = false;
        filter.processing = true;
        filter.skippedCount = 0;
        filter.scheduledCount = 0;
      });
      try {
        for (const cond of filter.tagConditions) {
          await processCondition(filter.template, object, cond);
        }
      } finally {
        runInAction(() => {
          filter.processing = false;
          filter.done = true;
        });
      }
    }
  }, [filter, object, processCondition]);

  const estimateCount = React.useCallback(() => {
    Promise.all(
      filter.tagConditions.map((cond) =>
        CoachProgram.countLatest({ tags: cond.includedTags, excludedTags: cond.excludedTags }),
      ),
    )
      .then((result) => result.reduce((total, elem) => Math.max(total, elem), 0))
      .then((result) => runInAction(() => (filter.estimatedCount = result)));
  }, [filter]);

  const fetchObject = React.useCallback((query: string) => {
    // if (filter.type === 'explore') {
    return ExploreEntryBuilder.find({ query });
    // }
    // return Recipe.search({ query });
  }, []);

  const handleChangeOnlyUnplanned = React.useCallback(
    ({ target: { checked } }) => {
      runInAction(() => (filter.onlyUnplanned = checked));
      estimateCount();
    },
    [filter, estimateCount],
  );

  const handleChangeObject = React.useCallback((value?: ExploreEntry) => {
    setObject(value);
  }, []);

  const handleChangeType = React.useCallback(
    (type: string) => {
      runInAction(() => (filter.type = type));
    },
    [filter],
  );

  const optionLabel = React.useCallback((obj) => obj.defaultName, []);

  const optionValue = React.useCallback((obj) => obj, []);

  const handleChangeRecurring = React.useCallback(
    ({ target: { checked } }) => {
      if (checked) {
        runInAction(() => (filter.template.recurringPattern = new RecurringPattern()));
      } else {
        runInAction(() => (filter.template.recurringPattern = undefined));
      }
    },
    [filter],
  );

  const handleChangeTagConditions = React.useCallback(() => {
    estimateCount();
  }, [estimateCount]);

  const handleChangeDate = React.useCallback(
    (date, dateString) => {
      runInAction(() => {
        filter.template.startDate = (date ?? dayjs()).format('YYYY-MM-DD');
        filter.template.endDate = (date ?? dayjs()).format('YYYY-MM-DD');
      });
    },
    [filter],
  );

  const handleChangeStartTime = React.useCallback(
    (date, dateString) => {
      if (dateString) {
        runInAction(() => {
          filter.template.startTime = date.format('HH:mm');
          filter.template.endTime = date.format('HH:mm');
        });
      }
    },
    [filter],
  );

  const handleChangeEndTime = React.useCallback(
    (date, dateString) => {
      runInAction(() => {
        filter.template.endTime = (date ?? dayjs()).format('HH:mm');
      });
    },
    [filter],
  );

  const handleChangeWeekDay = React.useCallback(
    (weekDay: DayOfWeek) => ({ target: { checked } }) => {
      runInAction(() => {
        if (filter.template.recurringPattern) {
          if (checked) {
            if (!filter.template.recurringPattern.daysOfWeek.includes(weekDay)) {
              filter.template.recurringPattern.daysOfWeek.push(weekDay);
            }
          } else {
            filter.template.recurringPattern.daysOfWeek = filter.template.recurringPattern.daysOfWeek.filter(
              (w) => w !== weekDay,
            );
          }
        }
      });
    },
    [filter],
  );

  return (
    <Modal
      width={1280}
      open={true}
      title="Schedule Events"
      footer={[
        <Button key="1" onClick={onCancel} disabled={filter.processing}>
          Close
        </Button>,
        // <Button key="2" danger onClick={ingredient.archived ? handleRestore : handleArchive}>
        //   {ingredient.archived ? 'Restore' : 'Archive'}
        // </Button>,
        <Button
          key="3"
          type="primary"
          onClick={handlePlan}
          disabled={
            filter.estimatedCount <= 0 ||
            !object ||
            filter.processing ||
            (filter.template.recurringPattern?.daysOfWeek?.length ?? 0) <= 0
          }
        >
          Schedule for {filter.estimatedCount} Users
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Row>
          <Col xs={12}>
            <Form.Item label="Tag Conditions">
              <TagConditionsInput tagConditions={filter.tagConditions} onChange={handleChangeTagConditions} />
            </Form.Item>
          </Col>
          {/*<Col xs={6}>*/}
          {/*  <Form.Item label="Object Type">*/}
          {/*    <Select value={filter.type} onChange={handleChangeType}>*/}
          {/*      <Select.Option value="recipe">Recipe</Select.Option>*/}
          {/*      <Select.Option value="explore">Explore Entry</Select.Option>*/}
          {/*    </Select>*/}
          {/*  </Form.Item>*/}
          {/*</Col>*/}
          <Col xs={6}>
            <Form.Item label="Object">
              <AsyncSelect
                value={object as any}
                cacheOptions
                defaultOptions
                isClearable={true}
                loadOptions={fetchObject as any}
                getOptionLabel={optionLabel}
                getOptionValue={optionValue as any}
                onChange={handleChangeObject as any}
              />
            </Form.Item>
          </Col>
          {/*<Col xs={6}>*/}
          {/*  <Checkbox checked={filter.onlyUnplanned} onChange={handleChangeOnlyUnplanned}>*/}
          {/*    Schedule only for people that have nothing planned*/}
          {/*  </Checkbox>*/}
          {/*</Col>*/}
          <Col xs={6}>
            <Checkbox checked={!!filter.template.recurringPattern} onChange={handleChangeRecurring}>
              Recurring event?
            </Checkbox>
          </Col>
          <Col>
            <Row>
              <Col>
                <Form.Item label="Date">
                  <DatePicker
                    onChange={handleChangeDate}
                    format="YYYY-MM-DD"
                    value={dayjs(filter.template.startDate, 'YYYY-MM-DD')}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Start Time">
                  <TimePicker
                    onChange={handleChangeStartTime}
                    format="HH:mm"
                    value={dayjs(filter.template.startTime, 'HH:mm')}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="End Time">
                  <TimePicker
                    onChange={handleChangeEndTime}
                    format="HH:mm"
                    value={dayjs(filter.template.endTime, 'HH:mm')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            {filter.template.recurringPattern && (
              <Row>
                <Col>
                  <Checkbox
                    checked={filter.template.recurringPattern.daysOfWeek.includes('MONDAY')}
                    onChange={handleChangeWeekDay('MONDAY')}
                  >
                    Monday
                  </Checkbox>
                </Col>
                <Col>
                  <Checkbox
                    checked={filter.template.recurringPattern.daysOfWeek.includes('TUESDAY')}
                    onChange={handleChangeWeekDay('TUESDAY')}
                  >
                    Tuesday
                  </Checkbox>
                </Col>
                <Col>
                  <Checkbox
                    checked={filter.template.recurringPattern.daysOfWeek.includes('WEDNESDAY')}
                    onChange={handleChangeWeekDay('WEDNESDAY')}
                  >
                    Wednesday
                  </Checkbox>
                </Col>
                <Col>
                  <Checkbox
                    checked={filter.template.recurringPattern.daysOfWeek.includes('THURSDAY')}
                    onChange={handleChangeWeekDay('THURSDAY')}
                  >
                    Thursday
                  </Checkbox>
                </Col>
                <Col>
                  <Checkbox
                    checked={filter.template.recurringPattern.daysOfWeek.includes('FRIDAY')}
                    onChange={handleChangeWeekDay('FRIDAY')}
                  >
                    Friday
                  </Checkbox>
                </Col>
                <Col>
                  <Checkbox
                    checked={filter.template.recurringPattern.daysOfWeek.includes('SATURDAY')}
                    onChange={handleChangeWeekDay('SATURDAY')}
                  >
                    Saturday
                  </Checkbox>
                </Col>
                <Col>
                  <Checkbox
                    checked={filter.template.recurringPattern.daysOfWeek.includes('SUNDAY')}
                    onChange={handleChangeWeekDay('SUNDAY')}
                  >
                    Sunday
                  </Checkbox>
                </Col>
              </Row>
            )}
          </Col>
          <Col xs={12}>
            <Row>
              <Col>
                <TranslationInputArray
                  entity={filter.notification}
                  field="title"
                  label="Initial Notification Title"
                />
              </Col>
              <Col>
                <TranslationInputArray
                  entity={filter.notification}
                  field="message"
                  label="Initial Notification Message"
                />
              </Col>
            </Row>
          </Col>
          {(filter.processing || filter.done) && (
            <Col xs={12}>
              <Progress percent={((filter.skippedCount + filter.scheduledCount) / filter.estimatedCount) * 100} />
              <div style={{ textAlign: 'center' }}>
                {`Scheduled: ${filter.scheduledCount} / Skipped: ${filter.skippedCount}`}
              </div>
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  );
});
