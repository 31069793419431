/**
 * Created by neo on 29.10.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Form, Input, InputNumber, Select } from 'antd';
import { Col, Container, Row } from 'reactstrap';
import { TranslationInputArray } from '../../../../../Components/Translation/TranslationInputArray';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { ViewMedia } from '../../../../../Components/ViewMedia/ViewMedia';
import { PhaseParamEditor } from '../PhaseEditorTab/PhaseParamEditor';
import { WorkoutTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';
import { runInAction } from 'mobx';
import { PipelineContext } from '../../../../../Model/Coach/PipelineContext';
import { WorkoutResponse } from '../../../../../Model/Coach/Script/WorkoutResponse';
import { Media } from '../../../../../Model/Media/Media';
import { ConditionalMedia } from '../../../../../Model/Coach/SuperMacro/ConditionalMedia';

export type BasicInfoTabProps = {
  workoutTemplate: WorkoutTemplate;
};

export const BasicInfoTab: React.FC<BasicInfoTabProps> = observer(({ workoutTemplate }) => {
  const [minGeneratedWorkout, setMinGeneratedWorkout] = useState<WorkoutResponse | undefined>();
  const [maxGeneratedWorkout, setMaxGeneratedWorkout] = useState<WorkoutResponse | undefined>();

  const minRecalculate = React.useCallback(() => {
    workoutTemplate
      .execute(new PipelineContext({ tags: ['duration:short'] }))
      .then((res) => setMinGeneratedWorkout(res));
  }, [workoutTemplate]);

  const maxRecalculate = React.useCallback(() => {
    workoutTemplate
      .execute(new PipelineContext({ tags: ['duration:long'] }))
      .then((res) => setMaxGeneratedWorkout(res));
  }, [workoutTemplate]);

  useEffect(() => {
    Promise.all([minRecalculate(), maxRecalculate()]);
  }, [minRecalculate, maxRecalculate]);

  useEffect(() => {
    if (minGeneratedWorkout && maxGeneratedWorkout) {
      const minDuration =
        minGeneratedWorkout.minDurationMinutes < 10
          ? Math.max(minGeneratedWorkout.minDurationMinutes, 5)
          : Math.round(minGeneratedWorkout.minDurationMinutes / 5) * 5;
      const maxDuration =
        maxGeneratedWorkout.maxDurationMinutes < 10
          ? Math.max(maxGeneratedWorkout.maxDurationMinutes, 5)
          : Math.round(maxGeneratedWorkout.maxDurationMinutes / 5) * 5;

      runInAction(() => {
        workoutTemplate.minDurationInMinutes = Math.min(minDuration, maxDuration);
        workoutTemplate.maxDurationInMinutes = Math.max(maxDuration, minDuration);
      });
    }
  }, [workoutTemplate, minGeneratedWorkout, maxGeneratedWorkout]);

  const handleChangeTemplateName = React.useCallback(
    ({ target: { value } }: any) => {
      runInAction(() => (workoutTemplate.templateName = value));
    },
    [workoutTemplate],
  );

  const handleChangeType = React.useCallback(
    (value?: string) => {
      runInAction(() => (workoutTemplate.type = value ?? 'gym_strength'));
    },
    [workoutTemplate],
  );

  const handleChangeIntensity = React.useCallback(
    (value?: string) => {
      runInAction(() => (workoutTemplate.intensity = value ?? 'medium'));
    },
    [workoutTemplate],
  );

  const handleChangeMinDuration = React.useCallback(
    (value: number | string | undefined | null) => {
      if (value) {
        runInAction(() => (workoutTemplate.minDurationInMinutes = Number(value)));
      }
    },
    [workoutTemplate],
  );

  const handleChangeMaxDuration = React.useCallback(
    (value: number | string | undefined | null) => {
      if (value) {
        runInAction(() => (workoutTemplate.maxDurationInMinutes = Number(value)));
      }
    },
    [workoutTemplate],
  );

  const handleSaveMedia = React.useCallback((media: Media) => new ConditionalMedia(media.toJS()), []);

  return (
    <Container>
      <Form layout="vertical">
        <Row>
          <Col>
            <SingleColRow>
              <Form.Item label="Template Name (internal)">
                <Input
                  onChange={handleChangeTemplateName}
                  value={workoutTemplate.templateName}
                  placeholder="Template Name"
                />
              </Form.Item>
            </SingleColRow>
            <SingleColRow>
              <Form.Item label="Workout Type">
                <Select value={workoutTemplate.type} onChange={handleChangeType}>
                  <Select.Option value="gym_strength">{'Gym / Strength Workout'}</Select.Option>
                  <Select.Option value="endurance">{'Endurance'}</Select.Option>
                  <Select.Option value="meditation">{'Meditation'}</Select.Option>
                  <Select.Option value="yoga">{'Yoga'}</Select.Option>
                  <Select.Option value="stretching">{'Stretching'}</Select.Option>
                  <Select.Option value="workplace">{'Workplace'}</Select.Option>
                </Select>
              </Form.Item>
            </SingleColRow>
            <SingleColRow>
              <Form.Item label="Intensity">
                <Select value={workoutTemplate.intensity} onChange={handleChangeIntensity}>
                  <Select.Option value="low">{'Low'}</Select.Option>
                  <Select.Option value="medium">{'Medium'}</Select.Option>
                  <Select.Option value="high">{'High'}</Select.Option>
                </Select>
              </Form.Item>
            </SingleColRow>
            <Row>
              <Col>
                <Form.Item
                  label="Min Duration in Minutes"
                  extra={
                    minGeneratedWorkout ? `${minGeneratedWorkout?.minDurationMinutes}min` : 'Calculating duration...'
                  }
                >
                  <InputNumber value={workoutTemplate.minDurationInMinutes} onChange={handleChangeMinDuration} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Max Duration in Minutes"
                  extra={
                    maxGeneratedWorkout ? `${maxGeneratedWorkout?.maxDurationMinutes}min` : 'Calculating duration...'
                  }
                >
                  <InputNumber value={workoutTemplate.maxDurationInMinutes} onChange={handleChangeMaxDuration} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col>
            <TranslationInputArray entity={workoutTemplate} field="name" label="Name (public)"  />
          </Col>
          <Col>
            <TranslationInputArray
              entity={workoutTemplate}
              type="textarea"
              field="description"
              label="Description (public)"
            />
          </Col>
        </Row>
        <SingleColRow style={{ paddingBottom: 98 }}>
          <Form.Item label="Images">
            <ViewMedia medias={workoutTemplate.medias} disableCamera={true} onTransform={handleSaveMedia} />
          </Form.Item>
        </SingleColRow>
        <SingleColRow>
          <h4>Workout Default Params</h4>
        </SingleColRow>
        <Row style={{ paddingBottom: 32 }}>
          {workoutTemplate.allParams.map((param) => (
            <Col key={param.name} xs={6} md={3}>
              <PhaseParamEditor phaseTemplate={workoutTemplate} param={param} />
            </Col>
          ))}
        </Row>
      </Form>
    </Container>
  );
});
