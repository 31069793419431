/**
 * Created by neo on 25.05.22.
 */
import { action, observable } from 'mobx';
import { TranslationKey, TranslationKeyJson } from './TranslationKey';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Pageable } from '../Pageable';

export type TranslationQueryRequest = Pageable & {
  query?: string;
  language?: string;
  namespace?: string;
  version?: string;
  showMissing?: boolean;
  caseSensitive?: boolean;
  onlyInValue?: boolean;
};

export type MergedTranslationJson = {
  id: string;
  namespace: string;
  keys: TranslationKeyJson[];
};

export class MergedTranslation {
  @observable
  id = '';
  @observable
  namespace = '';
  @observable
  keys: TranslationKey[] = [];

  constructor(json?: Partial<MergedTranslationJson>) {
    if (json) {
      this.id = json.id ?? '';
      this.namespace = json.namespace ?? 'coach-app';
      this.keys = (json.keys ?? []).map((t) => new TranslationKey(t));
    }
  }

  save() {
    return Promise.all(this.keys.map((t) => t.save()));
  }

  @action
  addTranslation(language: string, value: string, version: string = 'latest'): Promise<TranslationKey> {
    const newEntry = new TranslationKey({
      language,
      key: this.id,
      value,
      namespace: this.namespace,
      version,
    });
    this.keys.push(newEntry);
    return newEntry.save();
  }

  static list(request?: TranslationQueryRequest): Promise<MergedTranslation[]> {
    return HttpBackend.get('/translation/admin', request).then((result) =>
      (result ?? []).map((res) => new MergedTranslation(res)),
    );
  }

  static count(request?: TranslationQueryRequest): Promise<number> {
    return HttpBackend.get('/translation/admin/count', request).then((result) => result ?? 0);
  }

  static findOne(id: string): Promise<MergedTranslation> {
    return HttpBackend.get(`/translation/admin/${id}`).then((res) => new MergedTranslation(res));
  }

  static listNamespaces(): Promise<string[]> {
    return HttpBackend.get(`/translation/admin/namespaces`).then((res) => res ?? []);
  }

  static listLanguages(): Promise<string[]> {
    return HttpBackend.get(`/translation/admin/languages`).then((res) => res ?? []);
  }
}
