/**
 * Created by neo on 02.05.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { ValueEntry } from './ExerciseVariationFilterSingleSelection';
import { Form, Select } from 'antd';
import { SpineForceType } from '../../Model/Exercise/AbstractExercise';

const Values = [
  { value: 'stretch', label: 'Stretch / Zug' },
  { value: 'contract', label: 'Contract / Druck' },
];

export type ExerciseVariationFilterSpineForceTypeProps = {
  filter: ExerciseFilter;
};

export const ExerciseVariationFilterSpineForceType: React.FC<ExerciseVariationFilterSpineForceTypeProps> = observer(
  ({ filter }: ExerciseVariationFilterSpineForceTypeProps) => {
    const handleChange = React.useCallback(
      (value) => {
        if (value) {
          filter.spineForceType = value as SpineForceType;
        } else {
          filter.spineForceType = undefined;
        }
      },
      [filter],
    );

    return (
      <Form.Item label="Spine Force">
        <Select value={filter.spineForceType} onChange={handleChange}>
          <Select.Option value="">{''}</Select.Option>
          {Values.map((v: ValueEntry) => (
            <Select.Option key={v.value} value={v.value}>
              {v.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  },
);
