/**
 * Created by neo on 10.08.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { useState } from 'react';
import { ExploreContent } from '../../../Model/Explore/ExploreContent';
import { ExploreContentRendererSwitch } from './ExploreContentRendererSwitch';
import { Gym } from '../../../Model/Gym/Gym';

const DropContainer = styled.div<{ over?: boolean }>`
  border-radius: 16px;
  padding: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 160px;
  width: 100%;
  flex: 1 0 48%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  background-color: lightgrey;
  border: 4px dashed ${({ over }) => (over ? 'black' : 'grey')};
  font-size: 24px;
`;

export type ExploreCategoryCardProps = {
  content: ExploreContent;
  index: number;
  onDragStart?: (category: ExploreContent, index: number) => void;
  onDragEnd?: (category: ExploreContent) => void;
  onDrop?: (categoryId: string, newIndex: number) => void;
  draggable?: boolean;
  dragIndex?: number;
  gym?: Gym;
};

export const ExploreCategoryCard: React.FC<ExploreCategoryCardProps> = observer(
  ({ content, index, onDragStart, onDragEnd, onDrop, dragIndex, draggable, gym }) => {
    const location = useLocation();
    const [over, setOver] = useState(false);
    const [mouseOver, setMouseOver] = useState(false);
    const handleDragStart = React.useCallback(
      (event) => {
        console.log('handleDragStart', content);
        event.dataTransfer.setData('drag-category-id', content.id);
        onDragStart && onDragStart(content, index);
      },
      [content, onDragStart, index],
    );

    const handleDragEnd = React.useCallback(
      (event) => {
        console.log('handleDragEnd', content);
        event.dataTransfer.setData('drag-category-id', undefined);
        onDragEnd && onDragEnd(content);
      },
      [content, onDragEnd],
    );

    const handleDragOver = React.useCallback((event) => {
      setOver(true);
      event.preventDefault();
    }, []);

    const handleDragLeave = React.useCallback((event) => {
      setOver(false);
    }, []);

    const handleDrop = React.useCallback(
      (event) => {
        const categoryId = event.dataTransfer.getData('drag-category-id');
        if (categoryId && onDrop) {
          onDrop(categoryId, index + 1);
        }
      },
      [index, onDrop],
    );

    const handleDropFirst = React.useCallback(
      (event) => {
        const categoryId = event.dataTransfer.getData('drag-category-id');
        if (categoryId && onDrop) {
          onDrop(categoryId, 0);
        }
      },
      [onDrop],
    );

    return (
      <React.Fragment>
        {draggable && index === 0 && (
          <DropContainer onDrop={handleDropFirst} onDragOver={handleDragOver} onDragLeave={handleDragLeave} over={over}>
            Drop Here
          </DropContainer>
        )}
        <ExploreContentRendererSwitch content={content} index={index} gym={gym} />
        {draggable && (
          <DropContainer onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={handleDragLeave} over={over}>
            Drop Here
          </DropContainer>
        )}
      </React.Fragment>
    );
  },
);

const styles = {
  title: {
    fontSize: 28,
    color: 'white',
    zIndex: 100,
    textAlign: 'center',
  },
  overlay: {
    borderRadius: 16,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  darkOverlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  buttonOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
  },
} as any;
