/**
 * Created by neo on 19.06.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

export type TrayItemWidgetProps = {
  model: any;
  color?: string;
  name: string;
};

export const StyledTray = styled.div<{ color: string }>`
  color: white;
  font-family: Helvetica, Arial;
  padding: 5px;
  margin: 0px 10px;
  border: solid 1px ${(p) => p.color};
  border-radius: 5px;
  margin-bottom: 2px;
  cursor: pointer;
`;

export const TrayItemWidget: React.FC<TrayItemWidgetProps> = observer(({ color, name, model }: TrayItemWidgetProps) => {
  return (
    <StyledTray
      color={color ?? 'red'}
      draggable={true}
      onDragStart={(event) => {
        event.dataTransfer.setData('storm-diagram-node', JSON.stringify(model));
      }}
      className="tray-item"
    >
      {name}
    </StyledTray>
  );
});
