/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { DefaultStageNodeWidget, StageNodeWidgetProps } from '../Default/DefaultStageNodeWidget';

export type FetchNodeWidgetProps = {} & StageNodeWidgetProps;

export const FetchNodeWidget: React.FC<FetchNodeWidgetProps> = observer(
  ({ node, engine, size }: FetchNodeWidgetProps) => {
    return <DefaultStageNodeWidget engine={engine} node={node} size={size} />;
  },
);
