/**
 * Created by neo on 20.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Media } from '../../../../../../Model/Media/Media';
import { Col, Row } from 'reactstrap';
import { Button, Select, Space } from 'antd';
import { runInAction } from 'mobx';
import { MediaTextTrack } from '../../../../../../Model/Media/MediaTextTrack';

export type MediaTextTrackEntryProps = {
  media: Media;
  track: MediaTextTrack;
  onEdit?: (track: MediaTextTrack) => void;
};

const languages = [
  { lang: 'de', title: 'Deutsch' },
  { lang: 'en', title: 'English' },
  { lang: 'fr', title: 'Français' },
  { lang: 'it', title: 'Italiano' },
  { lang: 'es', title: 'Español' },
  { lang: 'fi', title: 'Suomi' },
];

export const MediaTextTrackEntry: React.FC<MediaTextTrackEntryProps> = observer(({ media, track, onEdit }) => {
  const handleChangeLanguage = React.useCallback(
    (newLang) =>
      runInAction(() => {
        track.language = newLang;
        track.title = languages.find((l) => l.lang === newLang)?.title ?? newLang;
      }),
    [track],
  );

  const handleRemove = React.useCallback(() => {
    runInAction(() => (media.textTracks = media.textTracks.filter((m) => m.url !== track.url)));
  }, [media, track]);

  const handleEdit = React.useCallback(() => {
    onEdit && onEdit(track);
  }, [onEdit, track]);

  return (
    <Row>
      <Col>
        <Select className="select-before" value={track.language} onChange={handleChangeLanguage}>
          {languages.map(({ lang, title }) => (
            <Select.Option key={lang} value={lang}>
              {`${title} (${lang})`}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col>{track.url}</Col>
      <Col xs="auto">
        <Space>
          <Button danger size="small" onClick={handleRemove}>
            Remove
          </Button>
          <Button type="primary" size="small" onClick={handleEdit}>
            Edit
          </Button>
        </Space>
      </Col>
    </Row>
  );
});
