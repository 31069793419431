/**
 * Created by neo on 03.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, Form, Input, Modal } from 'antd';
import { EarnModelTemplate } from '../../../../Model/Engagement/EarnModelTemplate';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { runInAction } from 'mobx';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';

export type EarnModelTemplateCategoryEditModalProps = {
  template: EarnModelTemplate;
};

export const EarnModelTemplateCategoryEditModal: React.FC<EarnModelTemplateCategoryEditModalProps> = observer(
  ({ template }) => {
    const { categoryId } = useParams<{ categoryId: string }>();
    const category = template.categories.find((c) => c.id === categoryId);

    const history = useNavigate();

    const handleCancel = React.useCallback(() => {
      history(-1);
    }, [history]);

    const handleChangeTemplateName = React.useCallback(
      ({ target: { value } }) => {
        if (category) {
          runInAction(() => (category.templateName = value));
        }
      },
      [category],
    );

    const handleChangeHidden = React.useCallback(
      ({ target: { checked } }) => {
        if (category) {
          runInAction(() => (category.hidden = checked));
        }
      },
      [category],
    );

    return (
      <Modal
        open={true}
        title={category?.templateName ?? '<not found>'}
        onCancel={handleCancel}
        footer={[
          <Button key="1" onClick={handleCancel}>
            Save & Close
          </Button>,
        ]}
        width={1280}
      >
        {category && (
          <React.Fragment>
            <Form layout="vertical">
              <Row>
                <Col md={6}>
                  <Form.Item label="Template Name">
                    <Input onChange={handleChangeTemplateName} value={category.templateName} />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Checkbox checked={category.hidden} onChange={handleChangeHidden}>
                    Hidden
                  </Checkbox>
                </Col>
                <Col md={6}>
                  <Form.Item label="Name">
                    <TranslationInputArray entity={category} field="name" />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item label="Description">
                    <TranslationInputArray type="textarea" entity={category} field="description" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </React.Fragment>
        )}
      </Modal>
    );
  },
);
