import * as React from 'react';
import { Observer, observer } from 'mobx-react';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import { Button, message, Popconfirm, Space, Table, Tag, Input, DatePicker, Menu, Dropdown, Select } from 'antd';
import { CalendarOutlined, DownOutlined } from '@ant-design/icons';
import { Route, Routes, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { DndContext, PointerSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { PageResult } from '../../../Model/PageResult';
import { CampaignCalendarTemplateItemBuilder } from '../../../Model/CampaignCalendar/CampaignCalendarTemplateItemBuilder';
import { CampaignCalendarTemplateItem } from '../../../Model/CampaignCalendar/CampaignCalendarTemplateItem';
import { CampaignCalendarTemplateEntry } from '../../../Model/CampaignCalendar/CampaignCalendarTemplateEntry';
import { CampaignCalendarTemplateFolder } from '../../../Model/CampaignCalendar/CampaignCalendarTemplateFolder';
import { CampaignCalendarItemEditModal } from './CampaignCalendarItemEditModal/CampaignCalendarItemEditModal';
import { ColumnGroupType, ColumnType } from 'antd/lib/table';

type TreeNode = {
  id: string;
  item: CampaignCalendarTemplateItem;
  children: TreeNode[];
};

const buildTree = (data: CampaignCalendarTemplateItem[]): TreeNode[] => {
  const lookup: Record<string, TreeNode> = {};
  data.forEach((item) => {
    lookup[item.id.toString()] = { id: item.id.toString(), item, children: [] };
  });
  const result: TreeNode[] = [];
  data
    .sort((a, b) => a.parentIds.length - b.parentIds.length)
    .sort((a, b) => (a.type === 'folder' ? -1 : 0))
    .forEach((item) => {
      const currentNode = lookup[item.id];
      if (item.parentIds?.length > 0) {
        const parentId = item.parentIds[0];
        const parentNode = lookup[parentId];
        if (parentNode && parentNode.item instanceof CampaignCalendarTemplateFolder) {
          parentNode.children.push(currentNode);
        } else {
          result.push(currentNode);
        }
      } else {
        result.push(currentNode);
      }
    });
  return result;
};

type RowProps = React.HTMLAttributes<HTMLTableRowElement> & { 'data-row-key': string };
const SortableRow: React.FC<RowProps> = (props) => {
  // eslint-disable-next-line react/prop-types
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });
  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };
  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

export type CampaignCalendarTableViewProps = {};

export const CampaignCalendarTableView: React.FC<CampaignCalendarTableViewProps> = observer(() => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [result, setResult] = useState(new PageResult<CampaignCalendarTemplateItem>());
  const [allItems, setAllItems] = useState<CampaignCalendarTemplateItem[]>([]);
  const [language, setLanguage] = useState<'en' | 'de'>('de');
  const [searchInput, setSearchInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [campaignDateFilter, setCampaignDateFilter] = useState<string | null>(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (keys: React.Key[]) => setSelectedRowKeys(keys),
  };

  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 1 } }));

  const handleLanguageChange = (lang: 'en' | 'de') => setLanguage(lang);

  const handleRefresh = useCallback(() => {
    PageResult.execute(
      CampaignCalendarTemplateItemBuilder.find({ size: 500, page, query: searchQuery || undefined }),
      CampaignCalendarTemplateItemBuilder.count(),
      page,
      500,
    ).then((res) => setResult(res));
  }, [page, searchQuery]);

  useEffect(() => {
    PageResult.execute(
      CampaignCalendarTemplateItemBuilder.find({ size: 500, page: 0, query: undefined }),
      CampaignCalendarTemplateItemBuilder.count(),
      0,
      500,
    ).then((res) => setAllItems(res.content));
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [page, searchQuery, handleRefresh]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over || active.id === over.id) return;
    const newData = [...result.content];
    const draggedItem = newData.find((x) => x.id === active.id.toString());
    const overItem = newData.find((x) => x.id === over.id.toString());
    if (!draggedItem || !overItem) return;
    if (overItem instanceof CampaignCalendarTemplateFolder) {
      draggedItem.parentIds = [overItem.id];
      overItem.parentIds = overItem.parentIds.filter((p) => p !== draggedItem.id);
      Promise.all([draggedItem.save(), overItem.save()]);
      setResult((prev) => new PageResult({ ...prev, content: newData }) as any);
    }
  };

  const onDragOver = () => {};

  const handleCopy = useCallback((item: CampaignCalendarTemplateItem) => {
    if (item instanceof CampaignCalendarTemplateEntry) {
      const copy = item.copy();
      copy
        .save()
        .then(() => {
          setResult(
            (prev) =>
              new PageResult({
                ...prev,
                content: [...prev.content, copy],
              }) as any,
          );
          message.success('Campaign copied');
        })
        .catch(() => {
          message.error('Failed to copy campaign');
        });
    }
  }, []);

  const columns: (ColumnType<TreeNode> | ColumnGroupType<TreeNode>)[] = [
    {
      key: 'expand',
      title: '',
      render: () => null,
      width: 32,
    },
    {
      title: 'ID',
      key: 'id',
      fixed: 'left' as const,
      render: (_: any, record: TreeNode) => (
        <span
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
          }}
        >
          {record.item instanceof CampaignCalendarTemplateFolder ? <b>{record.item.id}</b> : record.item.id}
        </span>
      ),
    },
    {
      title: 'Name',
      key: 'name',
      render: (_: any, record: TreeNode) =>
        record.item instanceof CampaignCalendarTemplateFolder ? (
          <span style={{ display: 'block', whiteSpace: 'normal', wordWrap: 'break-word' }}>
            <b>{record.item.getName(language)}</b>
          </span>
        ) : (
          <span style={{ display: 'block', whiteSpace: 'normal', wordWrap: 'break-word' }}>
            {record.item.getName(language)}
          </span>
        ),
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      sorter: (a: TreeNode, b: TreeNode) => {
        const nameA = a.item.gym?.name || 'Global';
        const nameB = b.item.gym?.name || 'Global';
        return nameA.localeCompare(nameB);
      },
      render: (_: any, record: TreeNode) => (
        <Observer>
          {() =>
            record.item.gymId ? (
              <Link to={`/infrastructure/gym/${record.item.gymId}`}>{record.item.gym?.name}</Link>
            ) : (
              <span>Global</span>
            )
          }
        </Observer>
      ),
    },
    {
      title: 'Campaign Date',
      dataIndex: 'campaignDate',
      key: 'campaignDate',
      sorter: (a: TreeNode, b: TreeNode) => {
        const aDate =
          a.item instanceof CampaignCalendarTemplateEntry ? dayjs(a.item.entryDate, 'YYYY-MM-DD').valueOf() : 0;
        const bDate =
          b.item instanceof CampaignCalendarTemplateEntry ? dayjs(b.item.entryDate, 'YYYY-MM-DD').valueOf() : 0;
        return aDate - bDate;
      },
      sortDirections: ['descend', 'ascend'] as ('ascend' | 'descend')[],
      filteredValue: campaignDateFilter ? [campaignDateFilter] : [],
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <DatePicker
            value={selectedKeys[0] ? dayjs(selectedKeys[0], 'YYYY-MM-DD') : null}
            onChange={(date, dateString) => {
              setSelectedKeys(date ? [dateString] : []);
            }}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                setCampaignDateFilter(selectedKeys[0] || null);
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Filter
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                setCampaignDateFilter(null);
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value: any, record: TreeNode) => {
        const filterValue = String(value);
        return record.item instanceof CampaignCalendarTemplateEntry
          ? dayjs(record.item.entryDate).format('YYYY-MM-DD') === filterValue
          : false;
      },
      render: (_: any, record: TreeNode) =>
        record.item instanceof CampaignCalendarTemplateEntry
          ? `${dayjs(record.item.entryDate, 'YYYY-MM-DD').format('DD. MMMM YYYY')}\n${record.item.entryTime}`
          : '-',
    },
    {
      title: 'Publish Date',
      key: 'publishDate',
      sorter: (a: TreeNode, b: TreeNode) => {
        const aDate = a.item.publishDate ? dayjs(a.item.publishDate).valueOf() : 0;
        const bDate = b.item.publishDate ? dayjs(b.item.publishDate).valueOf() : 0;
        return aDate - bDate;
      },
      render: (_: any, record: TreeNode) =>
        record.item.publishDate ? dayjs(record.item.publishDate).format('DD.MM.YYYY') : '-',
    },
    {
      title: 'Unpublish Date',
      key: 'unpublishDate',
      render: (_: any, record: TreeNode) =>
        record.item.unpublishDate ? dayjs(record.item.unpublishDate).format('DD.MM.YYYY') : '-',
    },
    {
      title: 'Published',
      key: 'published',
      render: (_: any, record: TreeNode) =>
        record.item.published ? <Tag color="success">Published</Tag> : <Tag color="error">Unpublished</Tag>,
    },
    {
      title: 'Files',
      key: 'files',
      render: (_: any, record: TreeNode) =>
        record.item instanceof CampaignCalendarTemplateEntry ? record.item.attachments.length : '-',
    },
    {
      title: '',
      key: 'action',
      render: (_: any, record: TreeNode) => {
        const actionMenu = (
          <Menu>
            <Menu.Item key="edit" onClick={() => navigate(`/customer-success/campaign-calendar/${record.item.id}`)}>
              Edit
            </Menu.Item>
            {record.item instanceof CampaignCalendarTemplateFolder && (
              <Menu.Item
                key="createSubFolder"
                onClick={() => navigate(`/customer-success/campaign-calendar/create?parentId=${record.id}&type=folder`)}
              >
                Create Sub Folder
              </Menu.Item>
            )}
            {record.item instanceof CampaignCalendarTemplateFolder && (
              <Menu.Item
                key="createEntry"
                onClick={() => navigate(`/customer-success/campaign-calendar/create?parentId=${record.id}&type=entry`)}
              >
                Create Entry
              </Menu.Item>
            )}
            {record.item instanceof CampaignCalendarTemplateEntry && (
              <Menu.Item key="copy" onClick={() => handleCopy(record.item)}>
                Copy
              </Menu.Item>
            )}
            <Menu.Item key="delete">
              <Popconfirm
                title="Are you sure?"
                onConfirm={() =>
                  record.item
                    .delete()
                    .then(() => handleRefresh())
                    .then(() => {
                      const childrenToUpdate = result.content.filter((c) => c.parentIds.includes(record.item.id));
                      return Promise.all(
                        childrenToUpdate.map((child) => {
                          child.parentIds = child.parentIds.filter((p) => p !== record.item.id);
                          return child.save();
                        }),
                      );
                    })
                    .then(() =>
                      message.success(
                        record.item instanceof CampaignCalendarTemplateFolder ? 'Folder deleted' : 'Campaign deleted',
                      ),
                    )
                    .catch(() =>
                      message.error(
                        record.item instanceof CampaignCalendarTemplateFolder
                          ? 'Failed to delete folder'
                          : 'Failed to delete campaign',
                      ),
                    )
                }
              >
                Delete
              </Popconfirm>
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown.Button overlay={actionMenu} icon={<DownOutlined />}>
            Actions
          </Dropdown.Button>
        );
      },
    },
  ];

  const dataSource = useMemo(() => {
    if (searchQuery) {
      return result.content
        .filter(
          (item) =>
            !(item instanceof CampaignCalendarTemplateFolder) &&
            item.getName(language).toLowerCase().includes(searchQuery.toLowerCase()),
        )
        .map((item) => ({ id: item.id.toString(), item, children: [] }));
    }
    return buildTree(result.content);
  }, [result, searchQuery, language]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h1>Campaign Calendar Templates</h1>
        </Col>
        <Col xs="auto">
          <div style={{ display: 'flex' }}>
            <Button type="primary" onClick={() => navigate(`/customer-success/campaign-calendar/create?type=folder`)}>
              Create new folder
            </Button>
            <Select value={language} onChange={handleLanguageChange} style={{ width: 120, marginLeft: 16 }}>
              <Select.Option value="en">English</Select.Option>
              <Select.Option value="de">Deutsch</Select.Option>
            </Select>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: 16 }}>
        <Col>
          <Input.Search
            placeholder="Search"
            value={searchInput}
            onChange={(e) => {
              const val = e.target.value;
              setSearchInput(val);
              if (val === '') {
                setSearchQuery('');
                PageResult.execute(
                  CampaignCalendarTemplateItemBuilder.find({ size: 500, page, query: undefined }),
                  CampaignCalendarTemplateItemBuilder.count(),
                  page,
                  500,
                ).then((res) => setResult(res));
              }
            }}
            onSearch={(value) => {
              setSearchInput(value);
              setSearchQuery(value);
            }}
            allowClear
            enterButton
            style={{ marginBottom: 32 }}
          />
        </Col>
      </Row>
      <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd} onDragOver={onDragOver}>
        <SortableContext items={dataSource.map((i) => i.id)} strategy={verticalListSortingStrategy}>
          <Table<TreeNode>
            rowKey="id"
            dataSource={dataSource}
            columns={columns}
            pagination={{
              current: page + 1,
              pageSize: 500,
              total: result.totalElements,
              showSizeChanger: false,
              onChange: (p) => setPage(p - 1),
            }}
            expandable={{
              rowExpandable: (record) =>
                record.item.type === 'folder' && record.item instanceof CampaignCalendarTemplateFolder,
              childrenColumnName: 'children',
              expandIconColumnIndex: 0,
            }}
            components={{ body: { row: SortableRow } }}
            sticky
          />
        </SortableContext>
      </DndContext>
      <Routes>
        <Route
          path=":campaignId"
          element={<CampaignCalendarItemEditModal onSaved={handleRefresh} onDeleted={handleRefresh} />}
        />
      </Routes>
    </React.Fragment>
  );
});
