/**
 * Created by neo on 01.02.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { Button, Form, Input } from 'antd';
import { runInAction } from 'mobx';
import { ViewMediaItem } from '../../../../../Components/ViewMedia/ViewMediaItem';
import Dropzone from 'react-dropzone';
import MediaService from '../../../../../Services/MediaService';
import { Instructor } from '../../../../../Model/Explore/Instructor';

export type InstructorEditProps = {
  instructor: Instructor;
  index: number;
  onRemove?: (index: number) => void;
};

export const InstructorEdit: React.FC<InstructorEditProps> = observer(({ instructor, index, onRemove }) => {
  const handleNameChange = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (instructor.name = value));
    },
    [instructor],
  );

  const handleDropImage = React.useCallback(
    (files: File[]) => {
      const file = files.length > 0 ? files[0] : undefined;
      if (file) {
        MediaService.uploadMedia(file).then((media) => runInAction(() => (instructor.image = media)));
      }
    },
    [instructor],
  );

  const handleRemoveFile = React.useCallback(() => {
    runInAction(() => (instructor.image = undefined));
  }, [instructor]);

  return (
    <Row>
      <Col md={12}>
        <Row>
          <Col>
            <Form.Item label={'Person Name'}>
              <Input value={instructor.name} onChange={handleNameChange} />
            </Form.Item>
          </Col>
          {onRemove && (
            <Col xs="auto">
              <Button type="primary" danger onClick={() => onRemove(index)}>
                Remove Person
              </Button>
            </Col>
          )}
        </Row>
      </Col>
      <Col md={12}>
        <Form.Item label={'Person Image'}>
          {instructor.image ? (
            <ViewMediaItem media={instructor.image} onRemove={handleRemoveFile} />
          ) : (
            <Dropzone onDrop={handleDropImage}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className="dropzone"
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input {...getInputProps()} />
                  {'Drop an image here'}
                </div>
              )}
            </Dropzone>
          )}
        </Form.Item>
      </Col>
    </Row>
  );
});
