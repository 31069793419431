/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { DefaultStageNodeWidget, StageNodeWidgetProps } from '../Default/DefaultStageNodeWidget';

export type SwitchNodeWidgetProps = {} & StageNodeWidgetProps;

export const SwitchNodeWidget: React.FC<SwitchNodeWidgetProps> = observer(
  ({ node, engine, size }: SwitchNodeWidgetProps) => {
    return <DefaultStageNodeWidget engine={engine} node={node} size={size} disableEdit={true} />;
  },
);
