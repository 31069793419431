import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Container, Row, Col } from 'reactstrap';
import { SingleColRow } from '../../../Components/SingleColRow';
import { CoachWorkoutTemplateTable } from './CoachWorkoutTemplateTable/CoachWorkoutTemplateTable';
import { useEffect } from 'react';
import { WorkoutTemplate } from '../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';
import { runInAction } from 'mobx';
import { Button, Input } from 'antd';
import { PageResult } from '../../../Model/PageResult';
import { Pager } from '../../../Components/Pager';
import { PipelineContext } from '../../../Model/Coach/PipelineContext';
import { TranslateCoachWorkoutTemplateComponent } from './TranslateCoachWorkoutTemplateComponent';

export type CoachWorkoutTemplateListScreenProps = {};

export const CoachWorkoutTemplateListScreen: React.FC<CoachWorkoutTemplateListScreenProps> = observer(() => {
  const debounce = React.useRef<any>(undefined);

  const store = useLocalStore(() => ({
    result: new PageResult<WorkoutTemplate>(),
    page: 0,
    loading: false,
    hasMore: false,
    query: '',
    load() {
      store.loading = true;
      PageResult.execute(
        WorkoutTemplate.find({
          page: store.page,
          name: store.query,
          sort: 'templateName,ASC',
          size: 20,
        }),
        WorkoutTemplate.count({
          page: store.page,
          name: store.query,
        }),
        store.page,
        20,
      )
        .then((result) => runInAction(() => (store.result = result)))
        .finally(() => runInAction(() => (store.loading = false)));
    },
    loadClear() {
      store.page = 0;
      store.load();
    },
  }));

  useEffect(() => {
    store.load();
  }, [store]);

  const handleChange = React.useCallback(
    ({ target: { value } }: any) => {
      if (store.query !== value) {
        store.query = value;
        clearTimeout(debounce.current);
        debounce.current = setTimeout(() => {
          store.loadClear();
        }, 300);
      }
    },
    [store],
  );

  const handleRemove = React.useCallback(
    (template: WorkoutTemplate) => {
      if (window.confirm(`Delete template ${template.name}?`)) {
        template.delete().then(() => {
          store.result.content = store.result.content.filter((e) => e.id !== template.id);
        });
      }
    },
    [store],
  );

  const handleCopy = React.useCallback(
    (template: WorkoutTemplate) => {
      const newName = `${template.templateName} (Copy)`;
      const copy = template.copy({ templateName: newName });
      copy.save().then((copy) => {
        const index = store.result.content.findIndex((r) => r === template);
        store.result.content.splice(index, 0, copy);
      });
    },
    [store],
  );

  const handlePage = React.useCallback(
    (page: number) => {
      if (store.page !== page) {
        runInAction(() => (store.page = page));
        store.load();
      }
    },
    [store],
  );

  const handleCalculateMinMaxDuration = React.useCallback(async () => {
    let page = 0;
    let hasMore = true;
    while (hasMore) {
      const result = await WorkoutTemplate.find({ page, size: 10 });
      await Promise.all(
        result
          .filter((t) => !t.minDurationInMinutes)
          .map((template) =>
            template.execute(new PipelineContext({ tags: ['duration:short'] })).then((workout) => {
              template.minDurationInMinutes = Math.max(5, Math.round(workout.minDurationMinutes / 5) * 5);
              return template.save();
            }),
          ),
      );

      await Promise.all(
        result
          .filter((t) => !t.maxDurationInMinutes)
          .map((template) =>
            template.execute(new PipelineContext({ tags: ['duration:long'] })).then((workout) => {
              template.maxDurationInMinutes = Math.max(5, Math.round(workout.maxDurationMinutes / 5) * 5);
              return template.save();
            }),
          ),
      );

      hasMore = result.length >= 10;
      page += 1;
    }
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <h1>{'Coach Workout Templates'}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input type="text" onChange={handleChange} value={store.query} />
        </Col>
        <Col xs="auto">
          <Button type="primary" href={`/coach/workout-template/create`} color="primary">
            Create New
          </Button>
          <Button onClick={handleCalculateMinMaxDuration}>Calculate Min Max Duration (missing ones)</Button>
          <TranslateCoachWorkoutTemplateComponent />
        </Col>
      </Row>
      <SingleColRow>
        <Pager page={store.result} onPage={handlePage} />
      </SingleColRow>
      <SingleColRow>
        <CoachWorkoutTemplateTable
          templates={store.result.content.slice()}
          onRemove={handleRemove}
          onCopy={handleCopy}
        />
      </SingleColRow>
      <SingleColRow>
        <Pager page={store.result} onPage={handlePage} />
      </SingleColRow>
    </Container>
  );
});
