/**
 * Created by neo on 28.03.2025
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { RegisteredUserPerGymAggregation } from './RegisteredUserPerGymAggregation';
import { CompanyDashboardScreenRowColumn } from './CompanyDashboardScreenRowColumn';

const fixedColumnStyle = {
  position: 'sticky',
  left: 0,
  zIndex: 3,
  width: 260,
} as React.CSSProperties;

// New style for the second (status) column.
// The left offset is set to 200px (equal to the width of the first column).
const fixedStatusColumnStyle = {
  position: 'sticky',
  left: 260,
  zIndex: 2,
  width: 340,
  backgroundColor: 'inherit',
} as React.CSSProperties;

const getOnboardingColor = (rate: number, total: number): string => {
  if (total < 50) {
    if (rate > 70) return '#c8e6c9';
    if (rate >= 50 && rate <= 70) return '#fff9c4';
    return '#ffcdd2';
  } else if (total >= 50 && total <= 250) {
    if (rate > 60) return '#c8e6c9';
    if (rate >= 40 && rate <= 60) return '#fff9c4';
    return '#ffcdd2';
  } else if (total >= 251 && total <= 1000) {
    if (rate > 50) return '#c8e6c9';
    if (rate >= 30 && rate <= 50) return '#fff9c4';
    return '#ffcdd2';
  } else if (total >= 1001 && total <= 5000) {
    if (rate > 40) return '#c8e6c9';
    if (rate >= 20 && rate <= 40) return '#fff9c4';
    return '#ffcdd2';
  } else {
    if (rate > 25) return '#c8e6c9';
    if (rate >= 10 && rate <= 25) return '#fff9c4';
    return '#ffcdd2';
  }
};

const getOnboardingStatus = (rate: number, total: number): string => {
  if (total < 50) {
    if (rate > 70) return 'Good';
    if (rate >= 50 && rate <= 70) return 'Medium';
    return 'Bad';
  } else if (total >= 50 && total <= 250) {
    if (rate > 60) return 'Good';
    if (rate >= 40 && rate <= 60) return 'Medium';
    return 'Bad';
  } else if (total >= 251 && total <= 1000) {
    if (rate > 50) return 'Good';
    if (rate >= 30 && rate <= 50) return 'Medium';
    return 'Bad';
  } else if (total >= 1001 && total <= 5000) {
    if (rate > 40) return 'Good';
    if (rate >= 20 && rate <= 40) return 'Medium';
    return 'Bad';
  } else {
    if (rate > 25) return 'Good';
    if (rate >= 10 && rate <= 25) return 'Medium';
    return 'Bad';
  }
};

const getActiveUserColor = (active: number): string => {
  if (active > 25) return '#388e3c';
  if (active >= 15 && active <= 25) return '#fbc02d';
  return '#d32f2f';
};

const getActiveUserStatus = (active: number): string => {
  if (active > 25) return 'Good';
  if (active >= 15 && active <= 25) return 'Medium';
  return 'Bad';
};

export type CompanyDashboardScreenRowProps = {
  company: RegisteredUserPerGymAggregation;
  dates: Date[];
};

export const CompanyDashboardScreenRow: React.FC<CompanyDashboardScreenRowProps> = observer(({ company, dates }) => {
  const latestEntry = company.entries.reduce((prev, curr) =>
    new Date(prev.date).getTime() > new Date(curr.date).getTime() ? prev : curr,
  );

  const totalPotentialUsers = company.gym.totalPotentialUsers;
  let bgColor = 'transparent';
  let onboardingMessage = '';

  if (totalPotentialUsers > 0) {
    bgColor = getOnboardingColor(latestEntry.onboardingRate, totalPotentialUsers);
    onboardingMessage = `Onboarding Rate: ${latestEntry.onboardingRate.toFixed(2)}% (${getOnboardingStatus(
      latestEntry.onboardingRate,
      totalPotentialUsers,
    )})`;
  } else {
    bgColor = '#e0e0e0';
    onboardingMessage = `Onboarding Rate: ${latestEntry.onboardingRate.toFixed(2)}% (No potential users specified)`;
  }

  return (
    <tr>
      <td style={fixedColumnStyle}>
        <Link to={`/infrastructure/gym/${company.id}`}>{company.gym.name}</Link>
      </td>
      <td style={{ ...fixedStatusColumnStyle, backgroundColor: bgColor }}>
        {latestEntry && (
          <>
            <div>Users: {latestEntry.cumulative}</div>
            <div>{onboardingMessage}</div>
            <div>
              Activities: {latestEntry.activitiesLogged} ({latestEntry.percentageChangeActivities > 0 ? '+' : ''}
              {latestEntry.percentageChangeActivities.toFixed(2)}%)
            </div>
            <div>
              Users Logged Activities: {latestEntry.usersLoggedActivities} (
              {latestEntry.percentageChangeActivitiesUsers > 0 ? '+' : ''}
              {latestEntry.percentageChangeActivitiesUsers.toFixed(2)}%)
            </div>
            <div>
              Change in Users: {latestEntry.percentageChange > 0 ? '+' : ''}
              {latestEntry.percentageChange.toFixed(2)}%
            </div>
            <div style={{ marginTop: 4 }}>
              <strong>Active Users Rate: {latestEntry.percentageActiveUsers.toFixed(2)}%</strong> (
              <span style={{ color: getActiveUserColor(latestEntry.percentageActiveUsers) }}>
                {getActiveUserStatus(latestEntry.percentageActiveUsers)}
              </span>
              )
            </div>
          </>
        )}
      </td>
      {dates.map((date) => (
        <CompanyDashboardScreenRowColumn key={date.toISOString()} company={company} date={date} />
      ))}
    </tr>
  );
});
