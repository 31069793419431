/**
 * Created by katarinababic on 17.8.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';
import { TranslationArrayInputMaterial } from '../../../../Translation/TranslationArrayInputMaterial';
import { SimpleButton } from '../../../../Buttons/SimpleButton';
import { ThemeConfig } from '../../../../../Config/Theme';

export type ComponentStylesNumberPointProps = {
  component: NativeFeedComponent;
};

export const ComponentStylesNumberPoint: React.FC<ComponentStylesNumberPointProps> = observer(({ component }) => {
  const { classes } = useStyles();

  const handleAddBulletPoint = React.useCallback(() => {
    runInAction(() => {
      component.children.push(
        new NativeFeedComponent({
          type: 'Paragraph',
          text: [{ lang: 'de', value: 'new numbered point' }],
        }),
      );
    });
  }, [component]);

  const handleDeleteNumberPoint = React.useCallback(
    (key: string) => {
      component.removeChild(key);
    },
    [component],
  );

  return (
    <Grid className={classes.container} id="componentStylesNumberPoint">
      <Grid item xs={12}>
        {component.children.map((c) => (
          <React.Fragment key={c.key}>
            <TranslationArrayInputMaterial multiline={true} translations={c.text} />
            <div className={classes.buttonContainer}>
              <SimpleButton
                buttonText="Delete number point"
                onClick={() => handleDeleteNumberPoint(c.key)}
                style={classes.button}
              />
            </div>
          </React.Fragment>
        ))}
      </Grid>
      <Grid item xs={12}>
        <SimpleButton buttonText="Add number point" onClick={handleAddBulletPoint} />
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: 16,
    width: '100%',
  },
  link: {
    width: '65ch',
    marginTop: 16,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    backgroundColor: ThemeConfig.Colors.errorColor,
    marginTop: 8,
    marginBottom: 16,
  },
}));
