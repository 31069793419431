/**
 *
 * Created by neo on 15.01.17.
 */

import { toJS, observable, action } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Athlete, AthleteJson } from '../Athlete/Athlete';
import { Audited, AuditedJson } from '../Audited';

export type EmployeeJson = AuditedJson & {
  gymId: string;
  athlete: AthleteJson;
  roles: string[];
};

export class Employee extends Audited {
  @observable
  gymId: string = '';
  @observable
  athlete: Athlete = new Athlete();
  @observable
  roles: string[] = [];

  constructor(json?: any) {
    super(json);
    if (json) {
      this.gymId = json.gymId;
      this.athlete = new Athlete(json.athlete);
      this.roles = json.roles || [];
    }
  }

  toJS(): EmployeeJson {
    return Object.assign(super.toJS(), {
      gymId: this.gymId,
      athlete: this.athlete.toJS(),
      roles: toJS(this.roles),
    });
  }

  @action
  save() {
    return HttpBackend.post(`/gym/employee?gymId=${this.gymId}`, toJS(this)).then((employee) => {
      this.id = employee.id;
      return this;
    });
  }

  @action
  remove() {
    return HttpBackend.delete(`/gym/employee/${this.id}?gymId=${this.gymId}`);
  }

  static find(gymId: string, pageable: any = { start: 0, sort: 'athlete.firstname,ASC' }): Promise<Employee[]> {
    return HttpBackend.get(`/gym/employee`, { gymId, ...pageable }).then(
      (res) => res?.map((r) => new Employee(r)) ?? [],
    );
  }

  static count(gymId: string, name: string = ''): Promise<number> {
    return HttpBackend.get(`/gym/employee/count`, { name, gymId });
  }
}
