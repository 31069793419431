/**
 * Created by neo on 29.02.2024
 */
import { computed, observable, onBecomeObserved, runInAction } from 'mobx';
import dayjs, { Dayjs } from 'dayjs';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import {
  CampaignCalendarEntryAttachment,
  CampaignCalendarEntryAttachmentJson,
} from './CampaignCalendarEntryAttachment';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { Gym } from '../Gym/Gym';
import { Media } from '../Media/Media';
import {
  CampaignCalendarQueryRequest,
  CampaignCalendarTemplateItem,
  CampaignCalendarTemplateItemJson,
} from './CampaignCalendarTemplateItem';

export type CampaignCalendarTemplateEntryJson = CampaignCalendarTemplateItemJson & {
  instructions: LocalizedValueJson[];
  entryDate: string;
  entryTime: string;
  entryDateTime: string;
  attachments: CampaignCalendarEntryAttachmentJson[];
  notBefore?: string;
  notAfter?: string;
};

export class CampaignCalendarTemplateEntry extends CampaignCalendarTemplateItem {
  @observable
  instructions: Array<LocalizedValue> = [];
  @observable
  entryDate: string = dayjs().add(1, 'month').format('YYYY-MM-DD');
  @observable
  entryTime: string = dayjs().add(1, 'month').format('HH:mm:ss');
  @observable
  attachments: CampaignCalendarEntryAttachment[] = [];
  @observable
  notBefore?: Date;
  @observable
  notAfter?: Date;

  constructor(json?: Partial<CampaignCalendarTemplateEntryJson>) {
    super(Object.assign({}, json, { type: 'entry' }));

    if (json) {
      this.instructions = (json.instructions || []).map((l) => new LocalizedValue(l));
      this.entryDate = json.entryDate ?? dayjs().add(1, 'month').format('YYYY-MM-DD');
      this.entryTime = json.entryTime ?? dayjs().add(1, 'month').format('HH:mm:ss');
      this.attachments = json.attachments?.map((a) => new CampaignCalendarEntryAttachment(a)) ?? [];
      this.tags = json.tags ?? [];
      this.images = json.images?.map((i) => new Media(i)) ?? [];
      this.notBefore = json.notBefore ? new Date(json.notBefore) : undefined;
      this.notAfter = json.notAfter ? new Date(json.notAfter) : undefined;
    }

    onBecomeObserved(this, 'gym', () => this.fetchGym());
  }

  fetchGym() {
    if (!this.gym && this.gymId) {
      Gym.get(this.gymId).then((gym) => runInAction(() => (this.gym = gym)));
    }
  }

  toJS(newId?: boolean): CampaignCalendarTemplateEntryJson {
    return {
      ...super.toJS(newId),
      instructions: this.instructions.map((v) => v.toJS()),
      entryDate: this.entryDate,
      entryTime: this.entryTime,
      entryDateTime: this.entryDateTime,
      attachments: this.attachments.map((a) => a.toJS()),
      notBefore: this.notBefore?.toISOString(),
      notAfter: this.notAfter?.toISOString(),
    };
  }

  copy(): CampaignCalendarTemplateEntry {
    const copy = new CampaignCalendarTemplateEntry(this.toJS(true));
    copy.name = copy.name.map(({ lang, value }) => new LocalizedValue({ lang, value: `${value} (Copy)` }));
    copy.entryDate = this.localDateTime.add(1, 'day').startOf('day').format('YYYY-MM-DD');
    copy.entryTime = this.localDateTime.add(1, 'day').startOf('day').format('HH:mm:00');
    copy.publishDate = undefined;
    copy.unpublishDate = undefined;
    return copy;
  }

  @computed
  get entryDateTime(): string {
    return `${this.entryDate}T${this.entryTime}`;
  }

  @computed
  get localDateTime(): Dayjs {
    return dayjs(this.entryDateTime, 'YYYY-MM-DDTHH:mm:ss');
  }

  static findOne(campaignId: string): Promise<CampaignCalendarTemplateEntry> {
    return HttpBackend.get(`/gym/campaign/calendar/template/admin/${campaignId}`).then(
      (res) => new CampaignCalendarTemplateEntry(res),
    );
  }

  static find(request?: Partial<CampaignCalendarQueryRequest>): Promise<CampaignCalendarTemplateEntry[]> {
    return HttpBackend.get(`/gym/campaign/calendar/template/admin`, Object.assign({}, request, { type: 'entry' })).then(
      (res) => res.map((c) => new CampaignCalendarTemplateEntry(c)),
    );
  }

  static count(request?: Partial<CampaignCalendarQueryRequest>): Promise<number> {
    return HttpBackend.get(
      `/gym/campaign/calendar/template/admin/count`,
      Object.assign({}, request, { type: 'entry' }),
    ).then((res) => res ?? 0);
  }
}
