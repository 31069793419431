/**
 * Created by Azat Fazlyev on 20.09.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import { SingleColRow } from '../../../Components/SingleColRow';
import { Modal } from 'antd';
import { DailyTip } from '../../../Model/DailyTip/DailyTip';
import { TranslationArrayInputMaterial } from '../../../Components/Translation/TranslationArrayInputMaterial';

export type ModalAddDailyTipContentProps = {
  dailyTip: DailyTip;
  modalOpen?: boolean;
  onClose?: () => void;
};

export const DailyTipEditModal: React.FC<ModalAddDailyTipContentProps> = observer(
  ({ dailyTip, modalOpen, onClose }) => {
    const handleSave = useCallback(() => {
      dailyTip.save();
      onClose && onClose();
    }, [dailyTip, onClose]);

    return (
      <Modal open={modalOpen} onCancel={onClose} onOk={handleSave}>
        <SingleColRow>
          <TranslationArrayInputMaterial translations={dailyTip.value} />
        </SingleColRow>
      </Modal>
    );
  },
);
