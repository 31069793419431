/**
 * Created by neo on 25.02.19
 */

import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { ThemeConfig } from '../../Config/Theme';
import { LocalizedValue } from '../../Model/LocalizedValue';
import { TranslationInputArrayEntryMaterial } from './TranslationInputArrayEntryMaterial';
import { TranslationService } from '../../Services/TranslationService';
import { runInAction } from 'mobx';

const defaultLanguages = ['de', 'en', 'fr', 'it']; // , 'es'];

export type TranslationArrayInputMaterialProps = {
  multiline?: boolean;
  translations: any;
};

export const TranslationArrayInputMaterial: React.FC<TranslationArrayInputMaterialProps> = observer(
  ({ translations, multiline }) => {
    const { classes } = useStyles();

    const [processing, setProcessing] = React.useState(false);

    const existingKeys = translations.map((l) => l.lang);
    const missingTranslations = defaultLanguages.filter((l) => existingKeys.findIndex((k) => k === l) === -1);

    const handleAddTranslation = React.useCallback(() => {
      setProcessing(true);
      return Promise.all(
        missingTranslations.map((nextKey) => {
          const source =
            translations.find((e) => e.lang === 'de' && e.lang !== nextKey && !!e.value.trim()) ??
            translations.find((e) => e.lang === 'en' && e.lang !== nextKey && !!e.value.trim()) ??
            translations.find((e) => e.lang !== nextKey && !!e.value.trim());
          if (source) {
            return TranslationService.openai({
              text: source.value,
              sourceLanguage: source.lang,
              targetLanguage: nextKey,
            }).then((result) =>
              runInAction(() =>
                translations.push(
                  new LocalizedValue({
                    lang: nextKey,
                    value: result ?? '',
                  }),
                ),
              ),
            );
          } else {
            runInAction(() =>
              translations.push(
                new LocalizedValue({
                  lang: nextKey,
                  value: '',
                }),
              ),
            );
            return Promise.resolve();
          }
        }),
      ).finally(() => setProcessing(false));
    }, [translations, missingTranslations]);

    return (
      <Grid container direction="column" spacing={1} className={classes.container}>
        {translations.map((value) => (
          <Grid item key={value.lang} className={classes.rowItem}>
            <TranslationInputArrayEntryMaterial
              translations={translations}
              translation={value}
              languages={defaultLanguages}
              multiline={multiline}
            />
          </Grid>
        ))}
        <Grid item>
          {missingTranslations.length > 0 && (
            <Button onClick={handleAddTranslation} className={classes.root} disabled={processing}>
              {processing ? 'Adding translations...' : 'Add missing translations'}
            </Button>
          )}
        </Grid>
      </Grid>
    );
  },
);

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: 8,
  },
  rowItem: {
    marginTop: theme.spacing(1),
  },
  root: {
    color: ThemeConfig.Colors.primaryColor,
    fontWeight: 'bold',
  },
}));
