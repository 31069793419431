import * as React from 'react';
import { Calendar, Badge, Select, Row, Col, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { Observer, observer } from 'mobx-react';
import { useNavigate, Routes, Route } from 'react-router';
import { CampaignCalendarTemplateEntry } from '../../../Model/CampaignCalendar/CampaignCalendarTemplateEntry';
import { CampaignCalendar } from '../../../Model/CampaignCalendar/CampaignCalendar';
import { CampaignCalendarItemEditModal } from './CampaignCalendarItemEditModal/CampaignCalendarItemEditModal';
import { Gym } from '../../../Model/Gym/Gym';

const { Option } = Select;
const { MonthPicker } = DatePicker;

export const CampaignCalendarCalendarView: React.FC = observer(() => {
  const [templateEntries, setTemplateEntries] = React.useState<CampaignCalendarTemplateEntry[]>([]);
  const [clientEntries, setClientEntries] = React.useState<CampaignCalendar[]>([]);
  const [currentRange, setCurrentRange] = React.useState<{ start: dayjs.Dayjs; end: dayjs.Dayjs }>({
    start: dayjs().startOf('month'),
    end: dayjs().endOf('month'),
  });
  const [selectedGym, setSelectedGym] = React.useState<Gym | undefined>(undefined);
  const [gyms, setGyms] = React.useState<Gym[]>([]);
  const navigate = useNavigate();

  // Initial load of gyms.
  React.useEffect(() => {
    Gym.find({ type: 'gym', query: '' }).then((data) => {
      setGyms(data);
    });
  }, []);

  // Combined effect to fetch entries.
  React.useEffect(() => {
    // When a gym is selected, include template entries filtered by gym id.
    CampaignCalendarTemplateEntry.find({
      size: 500,
      page: 0,
      startDateTime: currentRange.start.toDate(),
      endDateTime: currentRange.end.toDate(),
      type: 'entry',
      gymId: selectedGym?.id,
    }).then((data) => {
      setTemplateEntries(data);
    });
    CampaignCalendar.find({
      size: 500,
      page: 0,
      startDateTime: currentRange.start.toDate(),
      endDateTime: currentRange.end.toDate(),
      gymId: selectedGym?.id,
    }).then((data) => {
      setClientEntries(data);
    });
  }, [selectedGym, currentRange]);

  // When month is selected from the MonthPicker.
  const onDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setCurrentRange({
        start: date.startOf('month'),
        end: date.endOf('month'),
      });
    }
  };

  // onSearch handler to fetch gym options based on the text entered.
  const onGymSearch = (value: string) => {
    Gym.find({ type: 'gym', query: value }).then((data) => {
      setGyms(data);
    });
  };

  // onChange handler for the Select returns the selected gym id.
  const onGymChange = (gymId: string | null) => {
    if (gymId) {
      const gymObj = gyms.find((g) => g.id === gymId);
      setSelectedGym(gymObj);
    } else {
      setSelectedGym(undefined);
    }
  };

  // Render function for each calendar cell.
  const dateCellRender = (value: dayjs.Dayjs) => {
    // Filter and process entries as before...
    const adminEntriesForDay = templateEntries.filter((entry) =>
      dayjs(entry.entryDate, 'YYYY-MM-DD').isSame(value, 'day'),
    );
    let clientEntriesForDay = clientEntries.filter((entry) => dayjs(entry.startDateTime).isSame(value, 'day'));
    if (selectedGym) {
      clientEntriesForDay = clientEntriesForDay.filter((entry) => entry.entry.gym?.id === selectedGym.id);
    }

    return (
      <Observer>
        {() => (
          <div style={{ minHeight: '200px', padding: '4px' }}>
            {adminEntriesForDay.length > 0 && (
              <div>
                <div style={{ fontWeight: 600, fontSize: '0.85em' }}>Admin</div>
                <ul style={{ listStyle: '\\none', padding: 0 }}>
                  {adminEntriesForDay.map((entry, index) => {
                    const text = entry.getName('de');
                    const shortenedText = text.length > 120 ? text.slice(0, 117) + '...' : text;
                    const color = entry.type === 'planned' ? 'green' : 'blue';
                    const company = entry.gym ? entry.gym.name : 'Global';
                    return (
                      <li
                        key={index}
                        onClick={() => navigate(`/customer-success/campaign-calendar/${entry.id}`)}
                        style={{ cursor: 'pointer', marginBottom: 4 }}
                      >
                        <Badge color={color} text={`${shortenedText} (${company})`} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {clientEntriesForDay.length > 0 && (
              <div style={{ marginTop: 8 }}>
                <div style={{ fontWeight: 600, fontSize: '0.85em' }}>Client Scheduled</div>
                <ul style={{ listStyle: 'none', padding: 0 }}>
                  {clientEntriesForDay.map((entry, index) => {
                    const text = entry.entry.getName('de');
                    const shortenedText = text.length > 120 ? text.slice(0, 117) + '...' : text;
                    const color = 'green';
                    const company = entry.gymId && entry.entry.gym ? entry.entry.gym.name : 'Global';
                    return (
                      <li key={index} style={{ marginBottom: 4 }}>
                        <Badge color={color} text={`${shortenedText} (${company})`} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        )}
      </Observer>
    );
  };

  return (
    <>
      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col>
          <MonthPicker onChange={onDateChange} defaultValue={currentRange.start} format="MMMM YYYY" />
        </Col>
        <Col>
          <Select
            showSearch
            placeholder="Select company"
            allowClear
            style={{ minWidth: 200 }}
            value={selectedGym ? selectedGym.id : undefined}
            onSearch={onGymSearch}
            onChange={onGymChange}
            filterOption={false}
          >
            {gyms.map((gym) => (
              <Option key={gym.id} value={gym.id}>
                {gym.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Calendar cellRender={dateCellRender} />
      <Routes>
        <Route path=":campaignId" element={<CampaignCalendarItemEditModal />} />
      </Routes>
    </>
  );
});
