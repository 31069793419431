/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { WorkoutPhaseTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutPhaseTemplate';
import styled from '@emotion/styled';
import { Button, Popconfirm, Space } from 'antd';
import { Link } from 'react-router-dom';
import { TagConditionsInput } from '../../../SuperMacro/View/TagConditionsInput';
import { PhaseTemplateParamConditionList } from './PhaseTemplateParamConditionList';
import { PhaseConfiguration } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/PhaseConfiguration';
import { WorkoutTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';

export type PhaseTemplateWidgetProps = {
  phaseTemplate: WorkoutPhaseTemplate;
  onRemove?: (phaseTemplate: WorkoutPhaseTemplate) => any;
  onCopy?: (phaseTemplate: WorkoutPhaseTemplate) => any;
  onEdit?: (phaseTemplate: WorkoutPhaseTemplate) => any;
  phaseConfiguration?: PhaseConfiguration;
  workoutTemplate?: WorkoutTemplate;
};

const Container = styled.div`
  border: 1px solid grey;
  background-color: white;
  min-height: 60px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 8px;
  background-color: lightgrey;
  padding: 8px 16px;
  border-bottom: 1px solid grey;
`;

const ParamContainer = styled.div`
  background-color: lightgrey;
  padding: 8px;
`;

const Title = styled.div`
  flex: 1;
`;

const Extra = styled.div`
  flex: 0;
`;

const Body = styled.div`
  padding: 8px;
`;

export const PhaseTemplateWidget: React.FC<PhaseTemplateWidgetProps> = observer(
  ({ phaseTemplate, onCopy, onEdit, onRemove, phaseConfiguration, workoutTemplate }: PhaseTemplateWidgetProps) => {
    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(phaseTemplate);
    }, [phaseTemplate, onRemove]);

    const handleCopy = React.useCallback(() => {
      onCopy && onCopy(phaseTemplate);
    }, [phaseTemplate, onCopy]);

    const handleEdit = React.useCallback(() => {
      onEdit && onEdit(phaseTemplate);
    }, [phaseTemplate, onEdit]);

    return (
      <Container
        draggable={true}
        onDragStart={(event) => {
          event.dataTransfer.setData('phase-template-id', phaseTemplate.id);
        }}
      >
        <Header>
          <Title>
            <Link to={`/coach/workout-script/${phaseTemplate.scriptId}`}>{phaseTemplate.pipeline?.name}</Link>
          </Title>
          <Extra>
            <Space>
              <Popconfirm title={`Remove ${phaseTemplate.pipeline?.name}?`} onConfirm={handleRemove}>
                <Button type="link" danger>
                  Remove
                </Button>
              </Popconfirm>
              <Button type="link" onClick={handleEdit}>
                Edit
              </Button>
              <Button type="dashed" onClick={handleCopy}>
                Copy
              </Button>
            </Space>
          </Extra>
        </Header>
        <ParamContainer>
          <TagConditionsInput tagConditions={phaseTemplate.tagConditions} />
        </ParamContainer>
        <Body>
          <PhaseTemplateParamConditionList
            phaseTemplate={phaseTemplate}
            phaseConfiguration={phaseConfiguration}
            workoutTemplate={workoutTemplate}
          />
        </Body>
      </Container>
    );
  },
);
