/**
 * Created by katarinababic on 13.12.21..
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Gym } from '../../../../Model/Gym/Gym';
import dayjs from 'dayjs';
import { AnalyticsData, AnalyticsRow } from '../../../../Model/Analytics/AnalyticsData';
import { ExploreCategory } from '../../../../Model/Explore/ExploreCategory';
import { allSelectedCategoryWeekly, gymSelectedCategoryWeekly } from './Queries/querySelectedCategoriesWeekly';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { Col, Container, Row } from 'reactstrap';
import { DatePicker, Form } from 'antd';
import { ExploreCategoryAnalyticsChart } from './ExploreCategoryAnalyticsChart';

export type ExploreCategoryAnalyticsWeeklyProps = {
  gym?: Gym;
};

export const ExploreCategoryAnalyticsWeekly: React.FC<ExploreCategoryAnalyticsWeeklyProps> = observer(({ gym }) => {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(dayjs());
  const [data, setData] = useState<AnalyticsRow[] | undefined>();
  const [categories, setCategories] = useState<string[]>([]);

  const transformCategory = React.useCallback((category: AnalyticsRow) => {
    return ExploreCategory.findOne(category.category_id)
      .then((cat) => {
        category.category_name = cat.defaultName !== '' ? cat.defaultName : category.category_id;
        return category;
      })
      .catch(() => {
        category.category_name = category.category_id;
        return category;
      });
  }, []);

  const fetchAndTransformData = React.useCallback(async () => {
    setData(undefined);

    const fetchedData = await AnalyticsData.query({
      query: gym ? gymSelectedCategoryWeekly : allSelectedCategoryWeekly,
      parameters: {
        start_date: {
          value: startDate.format('YYYYMMDD'),
        },
        end_date: {
          value: endDate.format('YYYYMMDD'),
        },
        gym_id: {
          value: gym?.id ?? '',
        },
      },
    });

    const transformedFetchedData = await Promise.all(fetchedData.map((row) => transformCategory(row))).then(
      (transformedCategories) => transformedCategories,
    );
    const cats = new Array<string>();
    setData(
      transformedFetchedData
        .reduce((agg, row) => {
          const existing = agg.find((r) => r.event_date === row.event_date);
          cats.push(row.category_name);
          if (existing) {
            existing[row.category_name] = row.user_count;
            existing[`${row.category_name}_select`] = row.select_count;
          } else {
            agg.push({
              event_date: row.event_date,
              [row.category_name]: row.user_count,
              [`${row.category_name}_select`]: row.select_count,
            });
          }
          return agg;
        }, new Array<AnalyticsRow>())
        .map((d) =>
          Array.from(Object.entries(d))
            .map(([key, value]) => [key, key === 'event_date' || key === 'category_name' ? value : Number(value)])
            .reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {}),
        ),
    );
    setCategories([...new Set<string>(cats)]);
  }, [startDate, endDate, gym]);

  useEffect(() => {
    fetchAndTransformData();
  }, [startDate, endDate, gym]);

  const handleChangeValidFrom = React.useCallback((date) => setStartDate(date ?? dayjs().subtract(2, 'month')), []);

  const handleChangeValidUntil = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

  return (
    <Container>
      <SingleColRow>
        <h5>Selected categories weekly</h5>
      </SingleColRow>
      <Row>
        <Col xs={6}>
          <Form.Item label="Start Date">
            <DatePicker value={startDate} onChange={handleChangeValidFrom} />
          </Form.Item>
        </Col>
        <Col xs={6}>
          <Form.Item label="End Date">
            <DatePicker value={endDate} onChange={handleChangeValidUntil} />
          </Form.Item>
        </Col>
        <Col xs={12}>{data && <ExploreCategoryAnalyticsChart data={data} categories={categories} />}</Col>
      </Row>
    </Container>
  );
});
