/**
 * Created by neo on 01.02.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { MeditationEntry } from '../../../../../Model/Explore/MeditationEntry';
import { MeditationNarratorEntry } from './MeditationNarratorEntry';
import { Button } from 'antd';
import { runInAction } from 'mobx';
import { MeditationNarrator } from '../../../../../Model/Explore/MeditationNarrator';
import { ActivityWorkoutEntryContent } from '../ActivityWorkoutEntryContent';

export type MeditationEntryContentProps = {
  entry: MeditationEntry;
};

export const MeditationEntryContent: React.FC<MeditationEntryContentProps> = observer(({ entry }) => {
  const handleAddNarrator = React.useCallback(() => runInAction(() => entry.narrators.push(new MeditationNarrator())), [
    entry,
  ]);

  return (
    <React.Fragment>
      <ActivityWorkoutEntryContent entry={entry} />
      {entry.narrators.map((narrator, index) => (
        <div key={index.toString()} style={{ marginBottom: 16 }}>
          <MeditationNarratorEntry entry={entry} narrator={narrator} index={index} />
        </div>
      ))}
      <Button block onClick={handleAddNarrator}>
        Add Narrator
      </Button>
    </React.Fragment>
  );
});
