/**
 * Created by neo on 07.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ExploreCategory } from '../../../Model/Explore/ExploreCategory';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router';
import { ExploreEntry } from '../../../Model/Explore/ExploreEntry';
import { ExploreEntryBuilder } from '../../../Model/Explore/ExploreEntryBuilder';
import { Button, Col, Row } from 'antd';
import { useLocation } from 'react-router-dom';
import { Gym } from '../../../Model/Gym/Gym';

const Container = styled.div`
  flex: 1;
  width: 100%;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 18px;
  padding: 16px 0;
`;

const PreviewContainer = styled.div`
  display: flex;
`;

const EntryContainer = styled.div<{ backgroundImage?: string }>`
  width: 120px;
  height: 120px;
  ${({ backgroundImage }) => (backgroundImage ? `background-image: url('${backgroundImage}');` : '')}
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  margin-right: 4px;
`;

export type ExploreCategoryRendererCardLinkProps = {
  category: ExploreCategory;
  gym?: Gym;
};

export const ExploreCategoryRendererCardLink: React.FC<ExploreCategoryRendererCardLinkProps> = observer(
  ({ category, gym }) => {
    const history = useNavigate();
    const location = useLocation();

    const [entries, setEntries] = useState<ExploreEntry[]>([]);

    const gymId = new URLSearchParams(location.search).get('gymId') || undefined;

    useEffect(() => {
      ExploreEntryBuilder.find({ categories: [category.identifier], size: 4 }).then((result) => setEntries(result));
    }, [category]);

    const handleClickEdit = React.useCallback(() => {
      history(`${category.id}/edit?gymId=${gymId ?? ''}`);
    }, [history, category, gymId]);

    return (
      <Container>
        <img style={styles.image} src={category.image?.medium} />
        <Title>
          <Row>
            <Col>
              <a href={category.link} target="_blank" rel="noreferrer">
                {category.defaultName}
              </a>
            </Col>
            {category.gymId === gymId && (
              <Col xs="auto">
                <Button type="link" onClick={handleClickEdit}>
                  Edit
                </Button>
              </Col>
            )}
          </Row>
        </Title>
        <Description>{category.defaultDescription}</Description>
        <PreviewContainer>
          {entries.map((entry) => (
            <EntryContainer key={entry.id} backgroundImage={entry.image?.smallest}></EntryContainer>
          ))}
        </PreviewContainer>
      </Container>
    );
  },
);

const styles = {
  image: {
    width: '100%',
    height: 300,
    objectFit: 'cover',
  },
} as any;
