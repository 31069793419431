/**
 * Created by neo on 25.02.19
 */

import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Delete from '@mui/icons-material/Delete';
import { LocalizedValue } from '../../Model/LocalizedValue';
import { CustomMarkdownEditor } from '../CustomMarkdownEditor';
import { runInAction } from 'mobx';
import { ImproveMode, TranslationService } from '../../Services/TranslationService';
import { ImproveTextButton } from './ImproveTextButton';

export type TranslationInputArrayEntryMaterialProps = {
  translations: Array<LocalizedValue>;
  translation: LocalizedValue;
  languages: string[];
  multiline?: boolean;
};

export const TranslationInputArrayEntryMaterial: React.FC<TranslationInputArrayEntryMaterialProps> = observer(
  (props) => {
    const { translation, translations, multiline = false, languages } = props;
    const { classes } = useStyles();

    const [processing, setProcessing] = useState(false);

    const handleRemove = React.useCallback(() => {
      const index = translations.findIndex((l) => l.lang === translation.lang);
      -1 !== index && translations.splice(index, 1);
    }, [translation, translations]);

    const handleImprove = React.useCallback(
      (mode: ImproveMode) => {
        setProcessing(true);
        TranslationService.improve({ text: translation.value, allowMarkdown: true, mode })
          .then((result) => runInAction(() => (translation.value = result ?? '')))
          .finally(() => setProcessing(false));
      },
      [translation],
    );

    return (
      <Grid container spacing={1} direction="column">
        <Grid item className={classes.firstItem}>
          <FormControl>
            <InputLabel id="label-outlined-lang">Value</InputLabel>
            <Select labelId="label-outlined-lang" label="Language" value={translation.lang} disabled={true}>
              <MenuItem value={translation.lang}>{translation.lang}</MenuItem>
            </Select>
          </FormControl>
          <Grid item>
            <ImproveTextButton onClick={handleImprove} disabled={processing} />
            <IconButton aria-label="Delete" onClick={handleRemove}>
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs>
          <CustomMarkdownEditor
            value={translation.value}
            initialValue={translation.value}
            onChangeValue={(value) => runInAction(() => (translation.value = value ?? ''))}
          />
        </Grid>
      </Grid>
    );
  },
);

const useStyles = makeStyles()((theme) => ({
  firstItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  lastItem: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
}));
