/**
 *
 * Created by neo on 25.02.17.
 */
import { observer, useLocalStore } from 'mobx-react';
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';
import { Form, Input } from 'antd';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { runInAction } from 'mobx';
import { useEffect } from 'react';
import { BodyPartJoint } from '../../../../Model/BodyPart/BodyPartJoint';

export type BodyPartBasicInfoTabProps = {
  joint: BodyPartJoint;
};

export const BodyPartJointBasicInfoTab: React.FC<BodyPartBasicInfoTabProps> = observer(
  ({ joint }: BodyPartBasicInfoTabProps) => {
    const debounce = React.useRef<any>(undefined);
    const store = useLocalStore(() => ({
      identifierUnique: true,
      checkIdentifier(identifier: string, bodyPart: BodyPartJoint) {
        BodyPartJoint.get(identifier).then((result) =>
          runInAction(() => {
            store.identifierUnique = !result || result.id === bodyPart.id;
          }),
        );
      },
    }));

    useEffect(() => {
      store.checkIdentifier(joint.identifier, joint);
    }, [joint]);

    const handleChangeIdentifier = React.useCallback(
      ({ target: { value } }: any) => {
        joint.identifier = value.trim().toLowerCase().replace(/ /g, '_');
        debounce.current && clearTimeout(debounce.current);
        debounce.current = setTimeout(() => {
          store.checkIdentifier(value, joint);
        }, 300);
      },
      [joint, store],
    );

    return (
      <SingleColRow>
        <Form layout="vertical">
          <Row>
            <Col md={6}>
              <Form.Item
                label="Identifier"
                validateStatus={store.identifierUnique ? 'success' : 'error'}
                help="Has to be unique"
              >
                <Input type="text" value={joint.identifier} onChange={handleChangeIdentifier} />
              </Form.Item>
            </Col>
            <Col md={6}>
              <TranslationInputArray entity={joint} field="name" label="Name" />
            </Col>
            <Col md="12">
              <TranslationInputArray entity={joint} field="description" label="Description" type="textarea" />
            </Col>
          </Row>
        </Form>
      </SingleColRow>
    );
  },
);
