/**
 *
 * Created by neo on 18.01.17.
 */

import {
  toJS,
  observable,
  action,
  computed,





} from 'mobx';
import { v4 as UUID } from 'uuid';
import { Media } from '../Media/Media';
import { BodyPartRegion } from '../BodyPart/BodyPartRegion';
import { ExerciseType } from './ExerciseType';
import { ExercisePosition } from './ExercisePosition';
import { BodyPartJoint } from '../BodyPart/BodyPartJoint';
import { TagCondition } from '../Coach/SuperMacro/TagCondition';
import { BaseTrackingKey } from '../ProgramPortfolio/TrackingKeys';

export type ExerciseForce = 'PUSH' | 'PULL' | 'PUSH_PULL';
export type ExerciseMechanics = 'ISOLATED' | 'COMPOUND';
export type ExerciseUtility = 'AUXILIARY' | 'BASIC';
export type ExerciseExecutionType = 'ONE_SIDED' | 'BOTH_SIDED' | 'ALTERNATING';
export type SpineForceType = 'stretch' | 'contract';

const bodyPartSizes = ['huge', 'large', 'medium', 'small', 'tiny'];

export class AbstractExercise {
  @observable
  id = UUID();
  @observable
  archived = false;
  @observable
  type: ExerciseType = 'STRENGTH';
  @observable
  met: number = 0;
  @observable
  force?: ExerciseForce = undefined;
  @observable
  mechanics?: ExerciseMechanics = undefined;
  @observable
  utility?: ExerciseUtility = undefined;
  @observable
  initialPosition?: ExercisePosition = undefined;
  @observable
  executionType?: ExerciseExecutionType = undefined;
  @observable
  joints: Array<BodyPartJoint> = [];
  @observable
  medias: Array<Media> = [];
  @observable
  previewMedias: Array<Media> = [];
  @observable
  trackingKeys: BaseTrackingKey[] = [];
  @observable
  optionalTrackingKeys: Array<string> = [];
  @observable
  sourceType?: string = undefined;
  @observable
  sourceId?: string = undefined;
  @observable
  tags: Array<string> = [];
  @observable
  tagConditions: TagCondition[] = [];
  @observable
  hipFlexing: boolean = false;
  @observable
  spineFlexing: boolean = false;
  @observable
  spineForceType?: SpineForceType = undefined;
  @observable
  easierAlternativeIds: string[] = [];
  @observable
  alternativeIds: string[] = [];
  @observable
  harderAlternativeIds: string[] = [];

  constructor(json?: any) {
    if (json) {
      this.id = json.id || UUID();
      this.type = json.type || 'STRENGTH';
      this.met = json.met || 5.5;
      this.force = json.force;
      this.mechanics = json.mechanics;
      this.utility = json.utility;
      this.initialPosition = json.initialPosition ? new ExercisePosition(json.initialPosition) : undefined;
      this.executionType = json.executionType;

      this.joints = (json.joints || []).map((b) => new BodyPartJoint(b));
      this.medias = (json.medias || []).map((m) => new Media(m));
      this.previewMedias = (json.previewMedias || []).map((m) => new Media(m));
      this.trackingKeys = json.trackingKeys || [];
      this.optionalTrackingKeys = json.optionalTrackingKeys || [];
      this.sourceType = json.sourceType ?? 'gym';
      this.sourceId = json.sourceId ?? 'default';
      this.tags = json.tags || [];
      this.tagConditions = (json.tagConditions ?? []).map((t) => new TagCondition(t));
      this.archived = json.archived ?? false;

      this.hipFlexing = !!json.hipFlexing;
      this.spineFlexing = !!json.spineFlexing;
      this.spineForceType = json.spineForceType;

      this.easierAlternativeIds = json.easierAlternativeIds ?? [];
      this.alternativeIds = json.alternativeIds ?? [];
      this.harderAlternativeIds = json.harderAlternativeIds ?? [];
    }
  }

  async flattenBodyParts(bodyParts: Array<BodyPartRegion>) {
    let results: Array<BodyPartRegion> = [];
    for (const bodyPart of bodyParts) {
      results = results.concat(await bodyPart.flatten());
    }
    return results;
  }

  toJS(newId?: boolean): any {
    return {
      id: newId ? UUID() : this.id,
      type: this.type,
      met: this.met,
      force: this.force,
      mechanics: this.mechanics,
      utility: this.utility,
      initialPosition: this.initialPosition?.toJS(),
      executionType: this.executionType,
      joints: this.joints.map((s) => s.toJS()),
      medias: this.medias.map((m) => m.toJS()),
      previewMedias: this.previewMedias.map((m) => m.toJS()),
      trackingKeys: toJS(this.trackingKeys),
      optionalTrackingKeys: toJS(this.optionalTrackingKeys),
      sourceType: this.sourceType,
      sourceId: this.sourceId,
      tags: toJS(this.tags),
      tagConditions: this.tagConditions.map((t) => t.toJS()),
      hipFlexing: this.hipFlexing,
      spineFlexing: this.spineFlexing,
      spineForceType: this.spineForceType,
      easierAlternativeIds: this.easierAlternativeIds,
      alternativeIds: this.alternativeIds,
      harderAlternativeIds: this.harderAlternativeIds,
    };
  }

  equals(other: AbstractExercise): boolean {
    if (other) {
      return other.id === this.id;
    }
    return false;
  }

  @action
  setMainMedia(media: Media, index: number) {
    this.medias.splice(index, 1);
    this.medias.unshift(media);
  }

  @action
  removeMedia(index: number) {
    this.medias.splice(index, 1);
  }

  @computed
  get uniqueId(): string {
    return this.id;
  }

  @computed
  get videoMedia(): Media | undefined {
    if (this.medias.length > 0) {
      const index = this.medias.findIndex((m: Media) => m.mediaType.startsWith('video'));
      if (index !== -1) {
        return this.medias[index];
      }
    }
    return undefined;
  }

  @computed
  get imageMedia(): Media | undefined {
    if (this.medias.length > 0) {
      const index = this.medias.findIndex((m) => m.mediaType.startsWith('image'));
      if (index !== -1) {
        return this.medias[index];
      }
    }
    return undefined;
  }

  @computed
  get firstSmallestMediaUrl(): string | undefined {
    const { imageMedia } = this;
    if (imageMedia) {
      return imageMedia.smallest;
    }
    return undefined;
  }

  @computed
  get defaultTrackingKeys(): Array<string> {
    switch (this.type.toLowerCase()) {
      case 'strength':
        return ['REPETITIONS', 'WEIGHT', 'DURATION', 'BREAK'];
      case 'endurance':
      case 'flexibility':
      case 'balance':
      case 'relax':
      default:
        return ['DURATION', 'BREAK'];
    }
  }

  @computed
  get trackingParameters(): Array<string> {
    if (this.trackingKeys.length > 0) {
      const hasBreak = this.trackingKeys.indexOf('BREAK') !== -1;
      const hasDuration = this.trackingKeys.indexOf('DURATION') !== -1;
      if (!hasBreak) {
        if (!hasDuration) {
          return this.trackingKeys
            .slice()
            .concat(['DURATION', 'BREAK'])
            .sort((a, b) => a.localeCompare(b));
        }
        return this.trackingKeys
          .slice()
          .concat(['BREAK'])
          .sort((a, b) => a.localeCompare(b));
      } else if (!hasDuration) {
        if (!hasBreak) {
          return this.trackingKeys
            .slice()
            .concat(['DURATION', 'BREAK'])
            .sort((a, b) => a.localeCompare(b));
        }
        return this.trackingKeys
          .slice()
          .concat(['DURATION'])
          .sort((a, b) => a.localeCompare(b));
      }
      return this.trackingKeys.slice().sort((a, b) => a.localeCompare(b));
    }
    return this.defaultTrackingKeys.sort((a, b) => a.localeCompare(b));
  }

  @computed
  get tracksDuration(): boolean {
    return this.trackingParameters.indexOf('DURATION') !== -1;
  }

  @computed
  get tracksWeightOrReps(): boolean {
    return this.trackingParameters.indexOf('WEIGHT') !== -1 || this.trackingParameters.indexOf('REPETITIONS') !== -1;
  }
}
