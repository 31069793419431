/**
 * Created by neo on 02.06.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Media } from '../../../../../../Model/Media/Media';
import { availableLanguages } from '../../../../../Coach/TextToSpeech/CoachTtsEditModal/CoachTtsEditModal';
import { TranslationService } from '../../../../../../Services/TranslationService';
import { v4 as UUID } from 'uuid';
import MediaService from '../../../../../../Services/MediaService';
import { runInAction } from 'mobx';
import { MediaTextTrack } from '../../../../../../Model/Media/MediaTextTrack';
import { Button } from 'antd';
import webvtt from 'node-webvtt';

export type MediaTextTrackTranslationComponentProps = {
  media: Media;
};

export const MediaTextTrackTranslationComponent: React.FC<MediaTextTrackTranslationComponentProps> = observer(
  ({ media }) => {
    const [translating, setTranslating] = useState(false);

    const handleTranslate = React.useCallback(() => {
      const missingLanguages = availableLanguages.filter((l) => !media.textTracks.some((e) => e.language === l.value));
      const source =
        media.textTracks.find((t) => t.language === 'de') ??
        media.textTracks.find((t) => t.language === 'en') ??
        media.textTracks[0];
      if (missingLanguages.length > 0 && source) {
        fetch(source.url)
          .then((res) => res.text())
          .then((content) => webvtt.parse(content))
          .then((parsed) => {
            if (parsed.valid) {
              Promise.all(
                missingLanguages.flatMap((lang) => {
                  const clone = Object.assign({}, parsed);
                  return Promise.all(
                    parsed.cues.map((cue) =>
                      TranslationService.openai({
                        sourceLanguage: source.language,
                        targetLanguage: lang.value,
                        text: cue.text,
                      }).then((text) => [cue, text ?? '']),
                    ),
                  )
                    .then((translated) => {
                      translated.forEach(([cue, text]) => {
                        const newCue = clone.cues.find((c) => c.identifier === cue.identifier);
                        if (newCue) {
                          newCue.text = text ?? '';
                        }
                      });
                      return webvtt.compile(clone);
                    })
                    .then((vttText) => {
                      const blob = new Blob([vttText], { type: 'text/vtt' });
                      const newFile = new File([blob], `${UUID()}.vtt`, { type: 'text/vtt' });
                      MediaService.uploadMedia(newFile).then((newMedia) => {
                        runInAction(() =>
                          media.textTracks.push(
                            new MediaTextTrack({
                              url: newMedia.url,
                              index: newMedia.textTracks.length,
                              language: lang.value,
                              mimeType: 'text/vtt',
                              title: lang.label,
                            }),
                          ),
                        );
                      });
                    });
                }),
              ).then(() => media.save());
            }
          });
      }
    }, [media]);

    return (
      <Button onClick={handleTranslate} disabled={translating}>
        Translate Missing
      </Button>
    );
  },
);
