import { GptResponseService } from '../../../../Services/GptResponseService';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { TranslationKey } from '../../../../Model/Translation/TranslationKey';

// Optimized generateExploreFilterTags function
export const translateExploreFilterTags = (entry: ExploreEntry) =>
  Promise.all(
    entry.filterTags
      .map((tag) => `explore.filter_tag.${tag}`)
      .map((tag) => {
        const languageOrder = ['en', 'de', 'fr', 'it'];
        return TranslationKey.findOne(tag, 'coach-app').then((res) => {
          if (!res) {
            // Prepare the prompt for translation
            const translationPrompt = `Translate the following tag into English, German, French, and Italian, in exactly that order. The translation should be as short as possible, as fitting as possible, in an app context. An emoji at the beginning is very much welcome in that translation.

Tag: "${tag.replace('explore.filter_tag.', '')}"

Provide the translations as a JSON array containing only the translated strings in the specified order. No other text should be included in the response.`;

            // Send the prompt to ChatGPT
            return new GptResponseService([
              {
                role: 'system',
                content:
                  'Provide all responses as a JSON array containing only strings. The response must start with "[" and end with "]". It MUST be a JSON array only—no other characters or text before or after the JSON array.',
              },
              {
                role: 'user',
                content: translationPrompt,
              },
            ])
              .generate()
              .then((result) => {
                // Parse the JSON array of translations
                const translations = JSON.parse(result);

                // Map translations to the correct languages
                return Promise.all(
                  languageOrder.map((languageCode, index) => {
                    const translation = translations[index];
                    if (translation) {
                      return new TranslationKey({
                        key: tag,
                        language: languageCode,
                        namespace: 'coach-app',
                        value: translation,
                      }).save();
                    }
                    return Promise.resolve();
                  }),
                );
              })
              .catch((error) => {
                console.error('Error translating tag:', tag, error);
              });
          }
          return Promise.resolve();
        });
      }),
  ).catch((error) => {
    console.error('Error generating filter tags:', error);
  });
