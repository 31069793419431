/**
 * Created by neo on 24.08.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { FitnessProgramTemplate } from '../../../Model/Coach/Program/Template/FitnessProgramTemplate';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space, Tag } from 'antd';
import dayjs from 'dayjs';
import { ViewMediaItem } from '../../../Components/ViewMedia/ViewMediaItem';
import { ProgramStatisticsUsage } from '../../../Model/Coach/Program/Statistics/ProgramStatisticsUsage';

export type CoachProgramTemplateTableRowProps = {
  template: FitnessProgramTemplate;
  onCopy?: (superMacro: FitnessProgramTemplate) => void;
  onRemove?: (superMacro: FitnessProgramTemplate) => void;
};

export const CoachProgramTemplateTableRow: React.FC<CoachProgramTemplateTableRowProps> = observer(
  ({ template, onCopy, onRemove }) => {
    const [stats, setStats] = useState<ProgramStatisticsUsage | undefined>();

    useEffect(() => {
      ProgramStatisticsUsage.findOne(template.id).then((res) => setStats(res));
    }, [template]);

    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(template);
    }, [template, onRemove]);

    const handleCopy = React.useCallback(() => {
      onCopy && onCopy(template);
    }, [onCopy, template]);

    return (
      <tr>
        <td>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {template.medias
              .filter((m) => m.mediaType.startsWith('image'))
              .map((media) => (
                <ViewMediaItem key={media.id} media={media} size={64} />
              ))}
          </div>
        </td>
        <td>
          <Link to={`/coach/program-template/${template.id}`}>{template.templateName || '<no name>'}</Link>
        </td>
        <td>
          {template.name.map((name) => (
            <React.Fragment key={name.lang}>
              {`${name.value} (${name.lang})`}
              <br />
            </React.Fragment>
          ))}
        </td>
        <td style={{ fontSize: 12 }}>{template.includedTags.sort((a, b) => a.localeCompare(b)).join(', ')}</td>
        <td>
          {template.minWorkoutDurationInMinutes
            ? dayjs.utc(template.minWorkoutDurationInMinutes * 60000).format('HH:mm')
            : '-'}
        </td>
        <td>
          {template.maxWorkoutDurationInMinutes
            ? dayjs.utc(template.maxWorkoutDurationInMinutes * 60000).format('HH:mm')
            : '-'}
        </td>
        <td>
          {template.params?.equipmentConfiguration?.equipmentTypes.join(', ')}
          <br />
          {template.params?.equipmentLocked ? <Tag color="red">locked</Tag> : <Tag color="green">unlocked</Tag>}
        </td>
        <td>{stats?.count}</td>
        <td>{stats?.athletes}</td>
        <td>
          <Space>
            <Popconfirm title={`Delete Template ${template.templateName}?`} onConfirm={handleRemove}>
              <Button type="link" danger>
                Delete
              </Button>
            </Popconfirm>
            <Button type="dashed" onClick={handleCopy}>
              Copy
            </Button>
          </Space>
        </td>
      </tr>
    );
  },
);
