/**
 * Created by neo on 17.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Gym } from '../../../Model/Gym/Gym';
import { Form } from 'antd';
import { SignUpHourDistribution } from './SignUpHourDistribution';
import { SingleColRow } from '../../../Components/SingleColRow';
import { SignupOnboardingDaily } from './SignupOnboardingDaily';
import { SignUpDayDistribution } from './SignUpDayDistribution';

export type OnboardingAnalyticsTabProps = {
  gym?: Gym;
};

export const OnboardingAnalyticsTab: React.FC<OnboardingAnalyticsTabProps> = observer(({ gym }) => {
  return (
    <Form layout="vertical">
      <SingleColRow>
        <SignupOnboardingDaily gym={gym} />
      </SingleColRow>
      <SingleColRow>
        <SignUpHourDistribution gym={gym} />
      </SingleColRow>
      <SingleColRow>
        <SignUpDayDistribution gym={gym} />
      </SingleColRow>
    </Form>
  );
});
