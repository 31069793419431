/**
 * Created by andreaskarantzas on 09.04.2021.
 */
import { observable, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { ActivityWorkoutEntry } from './ActivityWorkoutEntry';
import { SubscribableEntry, SubscribableEntryJson } from './SubscribableEntry';
import { Media, MediaJson } from '../Media/Media';
import { WebinarFaqEntry, WebinarFaqEntryJson } from './WebinarFaqEntry';

export type WebinarEntryJson = SubscribableEntryJson & {
  language: string;
  previewVideos: MediaJson[];
  frequentlyAskedQuestions: WebinarFaqEntryJson[];
};

export class WebinarEntry extends SubscribableEntry {
    @observable
  language = 'de';
  @observable
  previewVideos: Media[] = [];
  @observable
  frequentlyAskedQuestions: WebinarFaqEntry[] = [];

  constructor(json?: Partial<WebinarEntryJson>) {
    super(
      Object.assign(
        json ?? {},
        { type: 'webinar' },
        { version: { minVersionIos: '1.66.0', minVersionAndroid: '1.66.0' } },
      ),
    );
    if (json) {
      this.type = 'webinar';
      this.language = json.language ?? 'de';
      this.previewVideos = (json.previewVideos ?? []).map((v) => new Media(v));
    }
  }

  toJS(): WebinarEntryJson {
    return Object.assign(super.toJS(), {
      language: this.language,
      previewVideos: this.previewVideos.map((v) => v.toJS()),
      frequentlyAskedQuestions: this.frequentlyAskedQuestions.map((f) => f.toJS()),
    });
  }

  static async find(request: Omit<ExploreEntryQueryRequest, 'type'>): Promise<WebinarEntry[]> {
    return HttpBackend.get(`/coach/explore/admin`, Object.assign(toJS(request), { type: 'webinar' })).then((res) =>
      (res ?? []).map((r) => new ActivityWorkoutEntry(r)),
    );
  }

  static async findOne(id: string): Promise<WebinarEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new WebinarEntry(res));
  }
}
