/**
 *
 * Created by neo on 25.02.17.
 */
import { observer } from 'mobx-react';
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import { Input, Form, Select, Card, InputNumber } from 'antd';
import { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { runInAction } from 'mobx';

export type PersonCardProps = {
  athlete: Athlete;
};

export const PersonCard: React.FC<PersonCardProps> = observer(({ athlete }: PersonCardProps) => {
  const debounce = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const [validatingNickname, setValidatingNickname] = useState(false);
  const [nicknameUnique, setNicknameUnique] = useState(true);

  const handleChangeNickname = React.useCallback(
    (e) => {
      athlete.nickname = e.target.value;
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => {
        if (athlete.nickname) {
          setValidatingNickname(true);
          Athlete.findByNickname(athlete.nickname)
            .then((result) => setNicknameUnique(!result || result.id === athlete.id))
            .finally(() => setValidatingNickname(false));
        }
      }, 300);
    },
    [athlete, debounce],
  );

  const handleChangeFirstname = React.useCallback((e) => (athlete.firstname = e.target.value), [athlete]);

  const handleChangeLastname = React.useCallback((e) => (athlete.lastname = e.target.value), [athlete]);

  const handleChangeGender = React.useCallback((e) => (athlete.gender = e), [athlete]);

  const handleChangeBirthdate = React.useCallback(
    (value: number | string | undefined | null) => {
      if (value) {
        runInAction(() => (athlete.birthYear = Number(value)));
      }
    },
    [athlete],
  );

  const handleChange = React.useCallback(
    (tags: { label: string; value: string }[]) => {
      runInAction(() => {
        athlete.tags = tags.map((t) => t.value);
      });
    },
    [athlete],
  );

  return (
    <Card title="Personal Details">
      <Form layout="vertical">
        <Row>
          <Col md={4}>
            <Form.Item label="Name">
              <Input type="text" value={athlete.firstname} onChange={handleChangeFirstname} />
            </Form.Item>
          </Col>
          <Col md={4}>
            <Form.Item
              label="Nickname"
              validateStatus={validatingNickname ? 'validating' : nicknameUnique ? 'success' : 'error'}
              help="Has to be unique"
            >
              <Input type="text" value={athlete.nickname} onChange={handleChangeNickname} />
            </Form.Item>
          </Col>
          <Col md={2}>
            <Form.Item label="Year of Birth">
              <InputNumber value={athlete.birthYear} onChange={handleChangeBirthdate} />
            </Form.Item>
          </Col>
          <Col md={2}>
            <Form.Item label="Gender">
              <Select value={athlete.gender} onChange={handleChangeGender}>
                <Select.Option value="MALE">Male</Select.Option>
                <Select.Option value="FEMALE">Female</Select.Option>
                <Select.Option value="UNKNOWN">Unknown</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <CreatableSelect
              isClearable
              isMulti
              onChange={handleChange as any}
              value={athlete.tags.map((t) => ({ label: t, value: t }))}
            />
          </Col>
        </Row>
      </Form>
    </Card>
  );
});
