/**
 * Created by neo on 17.01.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { RouteChallengeTeam } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeTeam';
import { Button, Tag } from 'antd';
import { RouteChallengeTeamsTableRowMembersTable } from './RouteChallengeTeamsTableRowMembersTable';
import Dayjs from '../../../../../Utils/dayjs';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';

export type RouteChallengeTeamsTableRowProps = {
  challenge: RouteChallenge;
  team: RouteChallengeTeam;
};

export const RouteChallengeTeamsTableRow: React.FC<RouteChallengeTeamsTableRowProps> = observer(
  ({ challenge, team }) => {
    const [open, setOpen] = useState(false);

    const toggleOpen = () => setOpen((p) => !p);

    return (
      <React.Fragment>
        <tr>
          <td>
            <Button type="link" onClick={toggleOpen}>
              {team.id}
            </Button>
          </td>
          <td>{team.gym?.name}</td>
          <td>{team.name}</td>
          <td>
            <Tag color={team.isPrivate ? 'red' : 'green'}>{team.isPrivate ? 'Private' : 'Public'}</Tag>
          </td>
          <td>{team.totalCollectedPoints}</td>
          <td>
            {team.memberCount} / {team.maxAllowedMembers}
          </td>
          <td>{Dayjs(team.lastReachedLocationTimestamp).format('YYYY-MM-DD HH:mm:ss')}</td>
        </tr>
        {open && <RouteChallengeTeamsTableRowMembersTable challenge={challenge} team={team} />}
      </React.Fragment>
    );
  },
);
