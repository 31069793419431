import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Button, AutoComplete, Table, Pagination, Modal, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { PageResult } from '../../../Model/PageResult';
import { Activity } from '../../../Model/Activity/Activity';
import { TableRowMedia } from '../../../Components/TableRowMedia';

export type ActivityListScreenProps = {};

export const ActivityListScreen: React.FC<ActivityListScreenProps> = () => {
  const history = useNavigate();
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  const [activities, setActivities] = useState<Activity[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<Activity[]>([]);
  const [sorter, setSorter] = useState<{ field: string; order: 'ASC' | 'DESC' }>({
    field: 'identifier',
    order: 'ASC',
  });
  const [lightboxVisible, setLightboxVisible] = useState(false);
  const [lightboxImage, setLightboxImage] = useState('');

  const loadActivities = useCallback(() => {
    setLoading(true);
    PageResult.execute(
      Activity.find({ query, page: page - 1, sort: `${sorter.field},${sorter.order}`, size: pageSize }),
      Activity.count({ query }),
      page - 1,
      pageSize,
    )
      .then((data) => {
        setActivities(data.content);
        setTotal(data.totalElements);
      })
      .finally(() => setLoading(false));
  }, [page, pageSize, query, sorter]);

  useEffect(() => {
    loadActivities();
  }, [loadActivities]);

  const fetchSuggestions = useCallback((searchText: string) => {
    if (!searchText) {
      setSuggestions([]);
      return;
    }
    Activity.find({ query: searchText, page: 0, size: 5 })
      .then((result) => {
        setSuggestions(result);
      })
      .catch(() => setSuggestions([]));
  }, []);

  const handleSearch = (value: string) => {
    setQuery(value);
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      fetchSuggestions(value);
      setPage(1);
      loadActivities();
    }, 300);
  };

  const handleSelect = (value: string) => {
    history(`/metadata/activity/${value}`);
  };

  const handlePaginationChange = (pageNumber: number, newPageSize: number) => {
    if (newPageSize !== pageSize) {
      setPage(1);
      setPageSize(newPageSize);
    } else {
      setPage(pageNumber);
    }
  };

  const handleTableChange = (_: any, __: any, tableSorter: any) => {
    if (tableSorter.field) {
      setSorter({ field: tableSorter.field, order: tableSorter.order === 'descend' ? 'DESC' : 'ASC' });
    }
  };

  // Updated delete handler using Modal.confirm from antd
  const handleDelete = (activity: Activity) => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this activity? This action cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await activity.delete();
          message.success('Activity deleted successfully');
          loadActivities();
        } catch {
          message.error('Failed to delete activity');
        }
      },
    });
  };

  const columns = [
    {
      title: 'Media',
      dataIndex: 'medias',
      key: 'medias',
      render: (medias: any) => {
        const handleClick = () => {
          if (medias && medias.length > 0) {
            setLightboxImage(medias[0]?.url);
            setLightboxVisible(true);
          }
        };
        return (
          <div onClick={handleClick} style={{ cursor: 'pointer' }}>
            <TableRowMedia medias={[...medias]} />
          </div>
        );
      },
    },
    {
      title: 'Identifier',
      dataIndex: 'identifier',
      key: 'identifier',
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'defaultName',
      key: 'defaultName',
      sorter: true,
      render: (_: any, record: Activity) => (
        <Link to={`/metadata/activity/${record.id}`}>{record.defaultName || '<no name>'}</Link>
      ),
    },
    {
      title: 'Tracking Keys',
      dataIndex: 'trackingKeys',
      key: 'trackingKeys',
      render: (trackingKeys: string[]) =>
        trackingKeys.map((tag: string) => (
          <span
            key={tag}
            style={{ marginRight: 4, padding: '2px 6px', backgroundColor: '#1890ff', color: '#fff', borderRadius: 4 }}
          >
            {tag}
          </span>
        )),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: Activity) => (
        <Button danger onClick={() => handleDelete(record)}>
          Delete
        </Button>
      ),
    },
  ];

  const handleCreate = () => {
    history('/metadata/activity/new');
  };

  return (
    <Container>
      <Row className="mb-3">
        <Col xs={12} md={8}>
          <AutoComplete
            placeholder="Search activities"
            style={{ width: '100%' }}
            value={query}
            onSearch={handleSearch}
            onSelect={handleSelect}
            options={suggestions.map((activity) => ({
              value: String(activity.id),
              label: (
                <div>
                  {activity.defaultName} ({activity.identifier})
                </div>
              ),
            }))}
            allowClear
          />
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={handleCreate}>
            Create
          </Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Pagination
            current={page}
            pageSize={pageSize}
            total={total}
            showSizeChanger
            onChange={handlePaginationChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            dataSource={activities}
            columns={columns}
            rowKey="identifier"
            loading={loading}
            pagination={false}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Pagination
            current={page}
            pageSize={pageSize}
            total={total}
            showSizeChanger
            onChange={handlePaginationChange}
          />
        </Col>
      </Row>
      <Modal open={lightboxVisible} footer={null} onCancel={() => setLightboxVisible(false)}>
        <img alt="Lightbox" style={{ width: '100%' }} src={lightboxImage} />
      </Modal>
    </Container>
  );
};
