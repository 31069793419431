/**
 * Created by neo on 02.10.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Exercise } from '../../../../../Model/Exercise/Exercise';
import { Link } from 'react-router-dom';

export type ExerciseBlockExerciseVariationLinkProps = {
  exercise: Exercise;
};

export const ExerciseBlockExerciseVariationLink: React.FC<ExerciseBlockExerciseVariationLinkProps> = observer(
  ({ exercise }: ExerciseBlockExerciseVariationLinkProps) => {
    return (
      <Link to={`/metadata/exercise/${exercise.id}?sourceType=${exercise.sourceType}&sourceId=${exercise.sourceId}`}>
        <div style={{ fontSize: 12 }}>{exercise.name}</div>
      </Link>
    );
  },
);
