/**
 * Created by neo on 18.06.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import CreatableSelect from 'react-select/creatable';
import { reaction, runInAction } from 'mobx';
import { FitnessProgramTemplate } from '../../../../Model/Coach/Program/Template/FitnessProgramTemplate';
import { useEffect } from 'react';

const allowedTags = [
  'level:beginner',
  'level:intermediate',
  'level:advanced',
  'goal:muscle_growth',
  'goal:stay_fit',
  'goal:weight_loss',
  'goal:cardio',
  'equipment:no',
  'equipment:custom',
  'equipment:gym',
  'focus:whole_body',
  'focus:upper_body',
  'focus:lower_body',
  'focus:abs',
  'focus:back',
  'focus:arm',
  'focus:legs',
  'focus:shoulder',
  'focus:butt',
  'age:junior',
  'age:adult',
  'age:senior',
  'age:elder',
  'gender:male',
  'gender:female',
  'activity:sitting',
  'activity:standing',
  'activity:physical',
].sort((a, b) => a.localeCompare(b));

export type CoachProgramTagsInputProps = {
  template: FitnessProgramTemplate;
};

export const CoachProgramTagsInput: React.FC<CoachProgramTagsInputProps> = observer(({ template }) => {
  const tags = template.includedTags.map((value) => ({ label: value, value }));
  const allowedIncludedTags = allowedTags
    .filter((t) => template.includedTags.indexOf(t) === -1)
    .map((value) => ({ label: value, value }));

  useEffect(
    () =>
      reaction(
        () => template.params?.equipmentConfiguration?.equipmentTypes.map((t) => t),
        (equipment) => {
          if (equipment) {
            if (template.params?.equipmentConfiguration.isFree) {
              runInAction(() => {
                template.includedTags = template.includedTags.filter(
                  (t) => t !== 'equipment:gym' && t !== 'equipment:custom',
                );
                template.includedTags = [...new Set(template.includedTags.concat(['equipment:no']))];
              });
            } else if (template.params?.equipmentConfiguration.isGym) {
              runInAction(() => {
                template.includedTags = template.includedTags.filter(
                  (t) => t !== 'equipment:no' && t !== 'equipment:custom',
                );
                template.includedTags = [...new Set(template.includedTags.concat(['equipment:gym']))];
              });
            } else {
              runInAction(() => {
                template.includedTags = template.includedTags.filter((t) => t !== 'equipment:no');
                template.includedTags = [
                  ...new Set(template.includedTags.concat(['equipment:custom', 'equipment:gym'])),
                ];
              });
            }
          } else {
            runInAction(() => {
              template.includedTags = template.includedTags.filter((t) => t !== 'equipment:no');
              template.includedTags = [...new Set(template.includedTags.concat(['equipment:gym']))];
            });
          }
        },
        { fireImmediately: true },
      ),
    [template],
  );

  const handleChange = React.useCallback(
    (tags?: any[]) => {
      runInAction(() => {
        template.includedTags.splice(0, template.includedTags.length);
        (tags ?? []).map((t) => t.value).forEach((t) => template.includedTags.push(t));
      });
    },
    [template],
  );

  return (
    <CreatableSelect isClearable isMulti onChange={handleChange as any} value={tags} options={allowedIncludedTags} />
  );
});
