/**
 * Created by neo on 17.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';

export type UserActivityDistributionChartProps = {
  data: AnalyticsRow[];
};

export const UserActivityDistributionChart: React.FC<UserActivityDistributionChartProps> = observer(({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart height={250} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="activities_count" />
        <YAxis />
        <Tooltip />
        <Legend />
        {/*<Bar dataKey="total_users" stackId="a" fill="#CE7537" />*/}
        <Bar dataKey="total_users_sum" stackId="a" fill="#D2A45F" name="# Total Users" />
      </ComposedChart>
    </ResponsiveContainer>
  );
});
