import { ExerciseFilter } from '../../Exercise/ExerciseFilter';
import { observable, toJS } from 'mobx';
import { PipelineContext } from '../PipelineContext';
import { BodyPartRegion } from '../../BodyPart/BodyPartRegion';

export class FetchStageConfig extends ExerciseFilter {
  @observable
  contextVariables = new Array<string>();
  @observable
  random?: boolean = false;
  @observable
  size?: number = undefined;
  @observable
  distinctKey?: string = undefined;
  @observable
  distinctValue?: string = undefined;

  constructor(json?: any) {
    super(json);

    if (json) {
      this.contextVariables = json.contextVariables || [];
    }
  }

  toJson(context?: PipelineContext): any {
    return {
      type: this.getValue('type', context, this.type),
      force: this.getValue('force', context, this.force),
      mechanics: this.getValue('mechanics', context, this.mechanics),
      utilities: this.getValue('utilities', context, this.utilities),
      primaryPositions: this.getValue('primaryPositions', context, this.primaryPositions),
      secondaryPositions: this.getValue('secondaryPositions', context, this.secondaryPositions),
      excludedPrimaryPositions: this.getValue('excludedPrimaryPositions', context, this.excludedPrimaryPositions),
      excludedSecondaryPositions: this.getValue('excludedSecondaryPositions', context, this.excludedSecondaryPositions),
      requiredEquipmentTypes: this.getValue('requiredEquipmentTypes', context, this.requiredEquipmentTypes),
      equipmentTypes: this.getValue('equipmentTypes', context, this.equipmentTypes),
      excludedEquipmentTypes: this.getValue('excludedEquipmentTypes', context, this.excludedEquipmentTypes),
      trackingKeys: this.getValue('trackingKeys', context, this.trackingKeys),
      excludedTrackingKeys: this.getValue('excludedTrackingKeys', context, this.excludedTrackingKeys),
      tags: this.getTags(context),
      excludedTags: this.getValue('excludedTags', context, this.excludedTags),
      bodyPartIds: this.getValue('bodyPartIds', context, this.bodyPartIds),
      excludedBodyPartIds: this.getValue('excludedBodyPartIds', context, this.excludedBodyPartIds),
      synergistIds: this.getValue('synergistIds', context, this.synergistIds),
      stabilizerIds: this.getValue('stabilizerIds', context, this.stabilizerIds),
      joints: this.getValue('joints', context, this.joints),
      excludedJoints: this.getValue('excludedJoints', context, this.excludedJoints),
      executionTypes: this.getValue('executionTypes', context, this.executionTypes),
      excludedExecutionTypes: this.getValue('excludedExecutionTypes', context, this.excludedExecutionTypes),
      hipFlexing: this.getValue('hipFlexing', context, this.hipFlexing),
      spineFlexing: this.getValue('spineFlexing', context, this.spineFlexing),
      spineForceType: this.getValue('spineForceType', context, this.spineForceType),
      excludeEmptyEquipmentTypes: true,
    };
  }

  toJS(): any {
    return Object.assign(super.toJS(), {
      contextVariables: toJS(this.contextVariables),
      random: this.random,
      size: this.size,
      distinctKey: this.distinctKey,
      distinctValue: this.distinctValue,
    });
  }

  toSaveJS(): Promise<any> {
    return Promise.all([
      this.bodyPartIds.length > 0
        ? Promise.all(this.bodyPartIds.map((b) => BodyPartRegion.flatten(b)))
        : Promise.resolve(undefined),
      this.synergistIds.length > 0
        ? Promise.all(this.synergistIds.map((b) => BodyPartRegion.flatten(b)))
        : Promise.resolve(undefined),
      this.stabilizerIds.length > 0
        ? Promise.all(this.stabilizerIds.map((b) => BodyPartRegion.flatten(b)))
        : Promise.resolve(undefined),
    ]).then(([bodyPartRoots, synergistRoots, stabilizerRoots]) =>
      Object.assign(this.toJS(), {
        bodyPartRoots,
        synergistRoots,
        stabilizerRoots,
      }),
    );
  }

  private getTags(context?: PipelineContext) {
    const contextTags = this.getValue('tags', context, []).filter((t) => t.startsWith('level:'));
    return this.tags.concat(contextTags);
  }

  private getValue(key: string, context?: PipelineContext, defaultValue?: any) {
    if (context && this.contextVariables.find((k) => k === key)) {
      return toJS(context[key] ?? defaultValue);
    }
    return toJS(defaultValue);
  }
}
