/**
 * Created by neo on 24.08.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { FitnessProgramTemplate } from '../../../Model/Coach/Program/Template/FitnessProgramTemplate';
import { Table } from 'reactstrap';
import { CoachProgramTemplateTableRow } from './CoachProgramTemplateTableRow';

export type CoachProgramTemplateTableProps = {
  templates: FitnessProgramTemplate[];
  onCopy?: (superMacro: FitnessProgramTemplate) => void;
  onRemove?: (superMacro: FitnessProgramTemplate) => void;
};

export const CoachProgramTemplateTable: React.FC<CoachProgramTemplateTableProps> = observer(
  ({ templates, onRemove, onCopy }) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Template Name</th>
            <th>Display Names</th>
            <th>Tags</th>
            <th>Min Duration</th>
            <th>Max Duration</th>
            <th>Equipment</th>
            <th># Started</th>
            <th># Users</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {templates.map((template) => (
            <CoachProgramTemplateTableRow key={template.id} template={template} onRemove={onRemove} onCopy={onCopy} />
          ))}
        </tbody>
      </Table>
    );
  },
);
