/**
 * Created by neo on 23.04.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Gym } from '../../../Model/Gym/Gym';
import { Retry } from '../../../Utils/Retry';
import { AnalyticsData, AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { gymRetention, Resolution } from './Queries/queryUsersActive';
import { Col, Row } from 'reactstrap';
import { DatePicker, Form, InputNumber, Space, Typography } from 'antd';
import { GymRetentionNewChart } from './GymRetentionNewChart';

export type GymRetentionProps = {
  gym?: Gym;
  title: string;
  resolution: Resolution;
};

export const GymRetentionNew: React.FC<GymRetentionProps> = observer(({ gym, title, resolution }) => {
  const [endDate, setEndDate] = useState(dayjs());
  const [minActive, setMinActive] = useState(1);
  const [data, setData] = useState<AnalyticsRow[]>([]);

  // const totalUsers = data?.reduce((max, curr) => Math.max(max, Number(curr.total_users_cumulative)), 0) ?? 0;

  const fetchGymData = React.useRef((gym?: Gym, res: Resolution = 'WEEK', excludedGymIds: string[] = []) => {
    return Retry.tryTimes(() =>
      AnalyticsData.query({
        query: gymRetention(gym, res, excludedGymIds),
        parameters: {
          start_date: {
            value: '20210601',
          },
          start_date_iso: {
            value: '2021-06-01',
          },
          end_date: {
            value: endDate.format('YYYYMMDD'),
          },
          end_date_iso: {
            value: endDate.format('YYYY-MM-DD'),
          },
          min_active: {
            value: minActive.toString(),
            type: 'number',
          },
        },
      }),
    ).then((data) =>
      data.map((row, index) =>
        Array.from(Object.entries(row))
          .map(([key, value]) => [key, key === 'minWeek' ? value : Number(value)])
          .reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {}),
      ),
    );
  }).current;

  useEffect(() => {
    setData([]);
    Gym.find({ size: 200 })
      .then((result) =>
        result.filter((g) => !!g.configuration.configuration.get('excludeInAnalytics')).map((g) => g.id),
      )
      .then((excludedGymIds) => fetchGymData(gym, resolution, excludedGymIds))
      .then((gymData) => setData(gymData));
  }, [gym, fetchGymData, minActive, resolution]);

  const handleChangeValidUntil = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

  const handleChangeMinActive = React.useCallback((e) => {
    setMinActive(Number(e) ?? 1);
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h5>{title}</h5>
        </Col>
        <Col xs={12}>
          <Space>
            <Form.Item label="End Date">
              <DatePicker value={endDate} onChange={handleChangeValidUntil} />
            </Form.Item>
            <Form.Item label="Min. Activities">
              <InputNumber min={1} max={30} step={1} value={minActive} onChange={handleChangeMinActive} />
            </Form.Item>
          </Space>
        </Col>
        <Col xs={12} style={{ paddingBottom: 16 }}>
          <Typography.Text type="secondary">
            Similar to User Retention it compares how many users are active relative to the start of the company. The
            red line shows the average over all companies.
            <br />
            It answers the question &quot;How does the usage in % per company looks like after n
            weeks/months/quarter&quot;
            <br />
            The values are higher compared to User Retention because it does not take into account that some users join
            in a later stage.
          </Typography.Text>
        </Col>
        <Col xs={12}>
          <GymRetentionNewChart data={data} />
        </Col>
      </Row>
    </React.Fragment>
  );
});
