/**
 *
 * Created by neo on 25.02.17.
 */
import * as React from 'react';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import { PersonCard } from './PersonCard';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { observer } from 'mobx-react';

export type AthleteBasicTabProps = {
  athlete: Athlete;
};

export const AthleteBasicTab: React.FC<AthleteBasicTabProps> = observer(({ athlete }) => {
  return (
    <React.Fragment>
      <SingleColRow>
        <PersonCard athlete={athlete} />
      </SingleColRow>
    </React.Fragment>
  );
});
