import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'reactstrap';
import { CoachWorkoutTemplateTableRow } from './CoachWorkoutTemplateTableRow';
import { WorkoutTemplate } from '../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';

export type CoachWorkoutTemplateTableProps = {
  templates: WorkoutTemplate[];
  onRemove?: (template: WorkoutTemplate) => any;
  onCopy?: (template: WorkoutTemplate) => any;
};

export const CoachWorkoutTemplateTable: React.FC<CoachWorkoutTemplateTableProps> = observer(
  ({ templates, onRemove, onCopy }: CoachWorkoutTemplateTableProps) => {
    return (
      <Table size="sm" hover striped>
        <thead>
          <tr>
            <th>Image</th>
            <th>Template Name</th>
            {/*<th>Tags</th>*/}
            <th>Name</th>
            <th>Type</th>
            <th>~ Min Duration</th>
            <th>~ Max Duration</th>
            <th>Intensity</th>
            <th>Created</th>
            <th>{''}</th>
          </tr>
        </thead>
        <tbody>
          {templates.map((workout) => (
            <CoachWorkoutTemplateTableRow key={workout.id} template={workout} onRemove={onRemove} onCopy={onCopy} />
          ))}
        </tbody>
      </Table>
    );
  },
);
