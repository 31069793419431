/**
 * Created by neo on 14.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Gym } from '../../../Model/Gym/Gym';
import { AnalyticsData } from '../../../Model/Analytics/AnalyticsData';
import { DatePicker, Form } from 'antd';
import {
  allAverageDailyEngagementTime,
  queryAverageDailyEngagementTime,
} from '../Queries/queryAverageDailyEngagementTime';
import dayjs from 'dayjs';
import { UserDailyEngagementTimeChart } from './UserDailyEngagementTimeChart';
import { SingleColRow } from '../../../Components/SingleColRow';
import { Col, Container, Row } from 'reactstrap';

export type UserDailyEngagementTimeProps = {
  gym?: Gym;
};

export const UserDailyEngagementTime: React.FC<UserDailyEngagementTimeProps> = observer(({ gym }) => {
  const [data, setData] = useState<any[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(dayjs());

  useEffect(() => {
    setData(undefined);
    setLoading(true);
    AnalyticsData.query({
      query: gym ? queryAverageDailyEngagementTime : allAverageDailyEngagementTime,
      parameters: {
        endDate: {
          value: endDate.format('YYYYMMDD'),
        },
        startDate: {
          value: startDate.format('YYYYMMDD'),
        },
        gym_id: {
          value: gym?.id ?? '',
        },
      },
    })
      .then((result) =>
        setData(
          result.map((entry) => ({
            name: entry.event_date,
            value: Math.round(Number(entry.avg_daily_engagement_time) * 100) / 100,
            usersCount: Number(entry.unique_users_count),
          })),
        ),
      )
      .finally(() => setLoading(false));
  }, [endDate, gym, startDate]);

  const handleChangeValidFrom = React.useCallback(
    (date) => setStartDate(date ?? dayjs().subtract(1, 'month')),
    [],
  );

  const handleChangeValidUntil = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

  return (
    <Container>
      <SingleColRow>
        <h5>Daily Engagement Time (Minutes)</h5>
      </SingleColRow>
      <Row>
        <Col xs={6}>
          <Form.Item label="Start Date">
            <DatePicker value={startDate} onChange={handleChangeValidFrom} />
          </Form.Item>
        </Col>
        <Col xs={6}>
          <Form.Item label="End Date">
            <DatePicker value={endDate} onChange={handleChangeValidUntil} />
          </Form.Item>
        </Col>
        <Col xs={12}>{data && <UserDailyEngagementTimeChart data={data} />}</Col>
      </Row>
    </Container>
  );
});
