/**
<<<<<<< Updated upstream
 * Created by andreaskarantzas on 29.04.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ActivityWorkoutEntryContent } from '../ActivityWorkoutEntryContent';
import { YogaInstructorEdit } from './YogaInstructorEdit';
import { YogaEntry } from '../../../../../Model/Explore/YogaEntry';
import { Col, Row } from 'reactstrap';

export type YogaEntryContentProps = {
  entry: YogaEntry;
};

export const YogaEntryContent: React.FC<YogaEntryContentProps> = observer(({ entry }) => {
  return (
    <React.Fragment>
      <ActivityWorkoutEntryContent entry={entry} />
      <Row style={{ marginBottom: 16 }}>
        <Col xs={6}>
          <YogaInstructorEdit entry={entry} field="narrator" />
        </Col>
      </Row>
    </React.Fragment>
  );
});
