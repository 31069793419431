import { IReactionDisposer, observable, onBecomeObserved, onBecomeUnobserved, reaction, runInAction, toJS } from 'mobx';
import { RecipeEquipment } from './RecipeEquipment';
import { LocalizedValue, LocalizedValueJson } from '../../LocalizedValue';
import { Ingredient } from '../Ingredient/Ingredient';
import { RecipeEquipmentHeader, RecipeEquipmentHeaderJson } from './RecipeEquipmentHeader';
import { DietEquipment } from '../Equipment/DietEquipment';

/**
 * Created by neo on 19.12.20.
 */

export type RecipeInstructionStepJson = {
  number: number;
  step: LocalizedValueJson[];
  ingredients: string[];
  equipment: RecipeEquipmentHeaderJson[];
};

export class RecipeInstructionStep {
  @observable
  number: number = 0;
  @observable
  step: LocalizedValue[] = [];
  @observable
  ingredients: string[] = [];
  @observable
  equipment: RecipeEquipmentHeader[] = [];
  @observable
  ingredientData?: Ingredient[];
  @observable
  fullEquipment?: RecipeEquipment[];
  fetchDisposer?: IReactionDisposer;
  fetchEquipmentDisposer?: IReactionDisposer;

  constructor(json?: Partial<RecipeInstructionStepJson>) {
    if (json) {
      this.number = json.number ?? 0;
      this.step = (json.step ?? []).map((l) => new LocalizedValue(l));
      this.ingredients = json.ingredients ?? [];
      this.equipment = (json.equipment ?? []).map((e) => new RecipeEquipmentHeader(e));
    }
    onBecomeObserved(this, 'ingredientData', this.startFetchIngredients);
    onBecomeUnobserved(this, 'ingredientData', this.stopFetchIngredients);
    onBecomeObserved(this, 'fullEquipment', this.startEquipment);
    onBecomeUnobserved(this, 'fullEquipment', this.stopEquipment);
  }

  startFetchIngredients = () => {
    this.fetchDisposer && this.fetchDisposer();
    this.fetchDisposer = reaction(
      () => this.ingredients.map((i) => i),
      (ingredients) => {
        if (ingredients.length > 0) {
          Ingredient.getAll(ingredients).then((result) => runInAction(() => (this.ingredientData = result)));
        } else {
          runInAction(() => (this.ingredientData = []));
        }
      },
      { fireImmediately: true },
    );
  };

  stopFetchIngredients = () => {
    this.fetchDisposer && this.fetchDisposer();
    this.fetchDisposer = undefined;
  };

  startEquipment = () => {
    this.fetchEquipmentDisposer && this.fetchEquipmentDisposer();
    this.fetchEquipmentDisposer = reaction(
      () => this.equipment.map((i) => i),
      (equipments) => {
        if (equipments.length > 0) {
          DietEquipment.getAll(equipments.map((e) => e.id))
            .then((result) =>
              result.map(
                (r) =>
                  new RecipeEquipment(Object.assign(this.equipment.find((e) => e.id === r.id)?.toJS() ?? {}, r.toJS())),
              ),
            )
            .then((result) => runInAction(() => (this.fullEquipment = result)));
        } else {
          runInAction(() => (this.ingredientData = []));
        }
      },
      { fireImmediately: true },
    );
  };

  stopEquipment = () => {
    this.fetchEquipmentDisposer && this.fetchEquipmentDisposer();
    this.fetchEquipmentDisposer = undefined;
  };

  toJS(): RecipeInstructionStepJson {
    return {
      number: this.number,
      step: this.step.map((s) => s.toJS()),
      ingredients: toJS(this.ingredients),
      equipment: this.equipment.map((e) => e.toJS()),
    };
  }
}
