/**
 * Created by neo on 18.06.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

export type CanvasContainerProps = {
  color?: string;
  background?: string;
  children: React.ReactNode;
};

const StyledContainer = styled.div<{ color: string; background: string }>`
  height: 100%;
  background-color: ${(p) => p.background};
  background-size: 50px 50px;
  display: flex;
  > * {
    height: 100%;
    min-height: 100%;
    width: 100%;
  }
  background-image: linear-gradient(
      0deg,
      transparent 24%,
      ${(p) => p.color} 25%,
      ${(p) => p.color} 26%,
      transparent 27%,
      transparent 74%,
      ${(p) => p.color} 75%,
      ${(p) => p.color} 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      ${(p) => p.color} 25%,
      ${(p) => p.color} 26%,
      transparent 27%,
      transparent 74%,
      ${(p) => p.color} 75%,
      ${(p) => p.color} 76%,
      transparent 77%,
      transparent
    );
`;

export const CanvasContainer: React.FC<CanvasContainerProps> = observer(
  ({ color, background, children }: CanvasContainerProps) => {
    return (
      <StyledContainer background={background || 'rgb(60, 60, 60)'} color={color || 'rgba(255,255,255, 0.05)'}>
        {children}
      </StyledContainer>
    );
  },
);
