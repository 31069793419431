/**
 * Created by neo on 27.07.23
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Button } from 'antd';
import { Recipe } from '../../../../Model/Diet/Recipe/Recipe';
import { Sleep } from '../../../../Utils/Sleep';
import OpenAI from 'openai';
import { Config } from '../../../../Config/Config';
import { Retry } from '../../../../Utils/Retry';

export type FixIngredientDescriptionComponentProps = {};

export const FixIngredientDescriptionComponent: React.FC<FixIngredientDescriptionComponentProps> = observer((props) => {
  const [translating, setTranslating] = useState(false);

  const fixRecipe = React.useRef(async (recipe: Recipe) => {
    for (const ingredient of recipe.ingredients) {
      const germanDescription = ingredient.getDescription('de');
      const englishDescription = ingredient.getDescription('en').toLowerCase();
      const frenchDescription = ingredient.getDescription('fr').toLowerCase();
      const italianDescription = ingredient.getDescription('it').toLowerCase();

      // console.log('before', ingredient.description.map((e) => `${e.lang} -> ${e.value}`).join('\n'));

      const prompt = `Fix the following texts so that it only contains the amount and unit or in case of any further information like diced, vaporized, sliced, or halved. The name of the ingredient/s has to be removed though so the text is as short as possible. Each line should be corrected invidiually and is in a different language. The output should be the same and in the same language as each line:`;

      const response = await Retry.tryTimes(
        () =>
          new OpenAI({ apiKey: Config.openai.gptApiKey, dangerouslyAllowBrowser: true }).responses.create({
            model: 'gpt-4.5-preview',
            instructions: prompt,
            input: `${germanDescription}\n${englishDescription}\n${frenchDescription}\n${italianDescription}`,
          }),
        3,
        2000,
      );

      await Sleep.sleepRandom(100, 200);

      const result = response.output_text;

      if (result.length === 4) {
        const [german, english, french, italian] = result;

        ingredient.changeDescription('de', german);
        ingredient.changeDescription('en', english);
        ingredient.changeDescription('fr', french);
        ingredient.changeDescription('it', italian);

        console.log('after', ingredient.description.map((e) => `${e.lang} -> ${e.value}`).join('\n'));
      }
    }
  }).current;

  const doWork = React.useRef(async () => {
    let page = 84;
    let results: Recipe[] | undefined;
    while (!results || results.length >= 10) {
      results = await Recipe.search({ size: 10, page, sort: 'createdAt,DESC' });

      for (const recipe of results) {
        await fixRecipe(recipe);
        await Sleep.sleepRandom(200, 500);
      }

      await Promise.all(results.map((e) => e.save()));
      page += 1;
    }
  }).current;

  const handlePress = React.useCallback(async () => {
    setTranslating(true);
    try {
      await doWork();
    } finally {
      setTranslating(false);
    }
  }, [doWork]);

  return (
    <Button onClick={handlePress} disabled={translating}>
      Fix All
    </Button>
  );
});
