/**
 * Created by neo on 12.04.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Ingredient } from '../../../../Model/Diet/Ingredient/Ingredient';
import { Card, Descriptions } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export type IngredientCardProps = {
  ingredient: Ingredient;
};

export const IngredientCard: React.FC<IngredientCardProps> = observer(({ ingredient }) => {
  const history = useNavigate();
  const [activeTab, setActiveTab] = useState('nutrients');

  const handleEdit = React.useCallback(() => {
    history(ingredient.id);
  }, [history, ingredient]);

  const handleChangeTab = React.useCallback((key: string) => {
    setActiveTab(key);
  }, []);

  return (
    <Card
      cover={<img src={ingredient.image?.medium} style={{ width: '100%', objectFit: 'cover' }} />}
      title={`${ingredient.getName('de').substr(0, 64)}${ingredient.getName('de').length > 64 ? '...' : ''}`}
      actions={[
        <EditOutlined key="edit" onClick={handleEdit} />,
        // <DeleteOutlined key="delete" onClick={handleArchive} />,
      ]}
      tabList={[
        { key: 'nutrients', tab: 'Nutrients per 100g' },
        // { key: 'ingredients', tab: 'Ingredients' },
      ]}
      activeTabKey={activeTab}
      onTabChange={handleChangeTab}
    >
      {/*{activeTab === 'ingredients' && (*/}
      {/*  <List*/}
      {/*    size="small"*/}
      {/*    bordered*/}
      {/*    dataSource={ingredient.ingredients}*/}
      {/*    renderItem={(item) => <List.Item>{item.getDescription('de') || item.getName('de')}</List.Item>}*/}
      {/*  />*/}
      {/*)}*/}
      {activeTab === 'nutrients' && (
        <Descriptions bordered size="small" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
          <Descriptions.Item
            label={'Calories'}
          >{`${ingredient.nutrition.nutrients['calories']?.amount}${ingredient.nutrition.nutrients['calories']?.unit}`}</Descriptions.Item>
          <Descriptions.Item
            label={'Carbs'}
          >{`${ingredient.nutrition.nutrients['carbohydrates']?.amount}${ingredient.nutrition.nutrients['carbohydrates']?.unit}`}</Descriptions.Item>
          <Descriptions.Item
            label={'Protein'}
          >{`${ingredient.nutrition.nutrients['protein']?.amount}${ingredient.nutrition.nutrients['protein']?.unit}`}</Descriptions.Item>
          <Descriptions.Item
            label={'Fat'}
          >{`${ingredient.nutrition.nutrients['fat']?.amount}${ingredient.nutrition.nutrients['fat']?.unit}`}</Descriptions.Item>
          <Descriptions.Item
            label={'Saturated Fat'}
          >{`${ingredient.nutrition.nutrients['saturated_fat']?.amount}${ingredient.nutrition.nutrients['saturated_fat']?.unit}`}</Descriptions.Item>
        </Descriptions>
      )}
    </Card>
  );
});
