/**
 * Created by neo on 12.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { YogaInstructorEdit } from '../../../../../Explore/Content/Yoga/YogaInstructorEdit';
import { Form } from 'antd';
import { MindfulnessProgramTemplateDayUnitAudio } from '../../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDayUnitAudio';
import { ExploreEntryModalMediaField } from '../../../../../Explore/ExploreEntryModalMediaField';
import { Media } from '../../../../../../../Model/Media/Media';
import { reaction, runInAction } from 'mobx';
import { useEffect, useState } from 'react';
import { MediaEditModal } from '../../../../../../MetaData/Media/MediaEditModal/MediaEditModal';
import { ViewMedia } from '../../../../../../../Components/ViewMedia/ViewMedia';
import { ConditionalMedia } from '../../../../../../../Model/Coach/SuperMacro/ConditionalMedia';

export type AudioEntryContentProps = {
  entry: MindfulnessProgramTemplateDayUnitAudio;
};

export const AudioEntryContent: React.FC<AudioEntryContentProps> = observer(({ entry }) => {
  const [selectedMedia, setSelectedMedia] = useState<Media | undefined>();

  useEffect(
    () =>
      reaction(
        () => entry.audios.map((a) => a),
        (medias) => {
          const durationSeconds = medias.reduce(
            (dur, media) => Math.min(dur, media.mediaInfo.duration ?? Number.MAX_SAFE_INTEGER),
            Number.MAX_SAFE_INTEGER,
          );
          console.log('media', medias);
          if (
            (!entry.durationInMinutes ||
              entry.durationInMinutes <= 0 ||
              Math.abs(entry.durationInMinutes * 60 - durationSeconds) > 120) &&
            durationSeconds > 0 &&
            durationSeconds !== Number.MAX_SAFE_INTEGER
          ) {
            runInAction(() => (entry.durationInMinutes = Math.max(1, Math.round(durationSeconds / 60))));
          }
        },
        { fireImmediately: true },
      ),
    [entry],
  );

  const handleClickMedia = React.useCallback((media: Media) => {
    setSelectedMedia(media);
  }, []);

  const handleCloseMedia = React.useCallback(() => setSelectedMedia(undefined), []);

  const handleMediaSave = React.useCallback(
    (media: Media) => {
      runInAction(() => {
        const index = entry.audios.findIndex((v) => v.id === media.id);
        if (index !== -1) {
          entry.audios[index] = media;
        }
      });
      setSelectedMedia(undefined);
    },
    [entry],
  );

  const handleMediaUploaded = React.useCallback(
    (media: Media[]) => {
      runInAction(() => (entry.audios = entry.audios.concat(media)));
    },
    [entry],
  );

  const handleRemoveMedia = React.useCallback(
    (media: Media) => {
      runInAction(() => (entry.audios = entry.audios.filter((v) => v.id !== media.id)));
    },
    [entry],
  );

  const handleSaveBackgroundImage = React.useCallback((media: Media) => new ConditionalMedia(media.toJS()), []);

  return (
    <React.Fragment>
      <Row style={{ marginBottom: 16 }}>
        <Col xs={6}>
          <Form.Item label="Background Images">
            <ViewMedia medias={entry.backgroundImages} disableCamera={true} onTransform={handleSaveBackgroundImage} />
          </Form.Item>
        </Col>
        <Col xs={6}>
          <YogaInstructorEdit entry={entry} />
        </Col>
        <Col xs={12}>
          <Form.Item label="Audio files">
            <div style={{ display: 'flex' }}>
              {entry.audios.concat([undefined] as any).map((video) => (
                <div key={video?.id} style={{ marginLeft: 8 }}>
                  <ExploreEntryModalMediaField
                    allowedTypes={['audio/mpeg', 'audio/mp3', 'audio/wav']}
                    onRemove={handleRemoveMedia}
                    onUploaded={handleMediaUploaded}
                    onClick={handleClickMedia}
                    media={video}
                  />
                </div>
              ))}
            </div>
          </Form.Item>
        </Col>
        {/*<Col>*/}
        {/*  <YogaInstructorEdit entry={entry} />*/}
        {/*</Col>*/}
      </Row>
      {selectedMedia && <MediaEditModal media={selectedMedia} onCancel={handleCloseMedia} onSave={handleMediaSave} />}
    </React.Fragment>
  );
});
