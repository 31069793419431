/**
 * Created by neo on 02.12.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CustomerGroup } from '../../../../../Model/Gym/CustomerGroup/CustomerGroup';
import { CustomerGroupMember } from '../../../../../Model/Gym/CustomerGroup/CustomerGroupMember';

export type CustomerGroupTableRowProps = {
  customerGroup: CustomerGroup;
};

export const CustomerGroupTableRow: React.FC<CustomerGroupTableRowProps> = observer(({ customerGroup }) => {
  const [membersCount, setMembersCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(
      () =>
        CustomerGroupMember.count(customerGroup.id, customerGroup.gymId).then((result) =>
          setMembersCount(result),
        ),
      5000,
    );
    return () => clearInterval(interval);
  }, [customerGroup]);

  return (
    <tr>
      <td>
        <Link to={`${customerGroup.id}`}>{customerGroup.id}</Link>
      </td>
      <td>{customerGroup.name}</td>
      <td>{membersCount}</td>
    </tr>
  );
});
