/**
 * Created by neo on 08.06.23
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Alert, Button, Checkbox, Form, Input, Space } from 'antd';
import { computed, observable, runInAction } from 'mobx';
import copy from 'copy-to-clipboard';
import { QRCodeCanvas } from 'qrcode.react';

class DynamicLinkBuilder {
  @observable
  link = '';
  @observable
  apn = 'app.flinq.athlete';
  /**
   * The link to open when the app isn't installed. Specify this to do something other than install
   * your app from the Play Store when the app isn't installed, such as open the mobile web version of the content, or display a promotional page for your app.
   */
  @observable
  afl = '';
  /**
   * The versionCode of the minimum version of your app that can open the link. If the installed app is an older version, the user is taken to the Play Store to upgrade the app.
   */
  @observable
  amv = '';
  @observable
  ibi = 'app.flinq.athlete';
  /**
   * The link to open when the app isn't installed. Specify this to do something other than install your app from the
   * App Store when the app isn't installed, such as open the mobile web version of the content, or display a promotional page for your app.
   */
  @observable
  ifl = '';
  @observable
  isi = '1465156373';
  /**
   * Your app's custom URL scheme, if defined to be something other than your app's bundle ID
   */
  @observable
  ius = '';
  /**
   * The link to open on iPads when the app isn't installed.
   * Specify this to do something other than install your app from the App Store when the app isn't installed, such as open the web version of the content, or display a promotional page for your app.
   */
  @observable
  ipfl = '';
  /**
   * The bundle ID of the iOS app to use on iPads to open the link. The app must be connected to your project from the Overview page of the Firebase console.
   */
  @observable
  ipbi = '';
  /**
   * The version number of the minimum version of your app that can open the link. This flag is passed to your app when it is opened, and your app must decide what to do with it.
   */
  @observable
  imv = '';
  /**
   * If set to '1', skip the app preview page when the Dynamic Link is opened, and instead redirect to the app or store.
   * The app preview page (enabled by default) can more reliably send users to the most appropriate destination when they open Dynamic Links in apps; however,
   * if you expect a Dynamic Link to be opened only in apps that can open Dynamic Links reliably without this page, you can disable it with this parameter.
   * This parameter will affect the behavior of the Dynamic Link only on iOS.
   */
  @observable
  efr = '';
  /**
   * The link to open on platforms beside Android and iOS. This is useful to specify a different behavior on desktop,
   * like displaying a full web page of the app content/payload (as specified by param link) with another dynamic link to install the app.
   */
  @observable
  ofl = '';
  /**
   * The title to use when the Dynamic Link is shared in a social post.
   */
  @observable
  st = '';
  /**
   * The description to use when the Dynamic Link is shared in a social post.
   */
  @observable
  sd = '';
  /**
   * The URL to an image related to this link. The image should be at least 300x200 px, and less than 300 KB.
   */
  @observable
  si = '';

  @observable
  utm_source = '';
  @observable
  utm_medium = '';
  @observable
  utm_campaign = '';
  @observable
  utm_term = '';
  @observable
  utm_content = '';

  @computed
  get fullLink(): string | undefined {
    if (this.link.trim()) {
      const params = {
        link: encodeURIComponent(this.link.trim()),
        apn: this.apn.trim(),
        afl: this.afl.trim() ? encodeURIComponent(this.afl.trim()) : undefined,
        amv: this.amv.trim(),
        ibi: this.ibi.trim(),
        ifl: this.ifl.trim() ? encodeURIComponent(this.ifl.trim()) : undefined,
        isi: this.isi.trim(),
        ius: this.ius.trim(),
        ipfl: this.ipfl.trim() ? encodeURIComponent(this.ipfl.trim()) : undefined,
        ipbi: this.ipbi.trim(),
        imv: this.imv.trim(),
        efr: this.efr.trim(),
        ofl: this.ofl.trim() ? encodeURIComponent(this.ofl.trim()) : undefined,
        st: this.st.trim(),
        sd: this.sd.trim(),
        si: this.si.trim(),
        utm_source: this.utm_source.trim(),
        utm_medium: this.utm_medium.trim(),
        utm_campaign: this.utm_campaign.trim(),
        utm_term: this.utm_term.trim(),
        utm_content: this.utm_content.trim(),
      };

      const queryString = Array.from(Object.entries(params))
        .filter(([key, value]) => !!value?.trim())
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      console.log('params', params, encodeURIComponent(this.link.trim()));

      return `https://open.kinastic.com?${queryString}`;
      // return `http://192.168.178.22:4000?${queryString}`;
    }
    return undefined;
  }

  @computed
  get hasError(): boolean {
    return !this.link.startsWith('https://coach.kinastic.com');
  }
}

export type DynamicLinkBuilderScreenProps = {};

export const DynamicLinkBuilderScreen: React.FC<DynamicLinkBuilderScreenProps> = observer((props) => {
  const [link] = useState(new DynamicLinkBuilder());

  return (
    <Container>
      <h1>Dynamic Link Builder</h1>
      {link.fullLink && (
        <Row>
          <Col xs={12}>
            <h5>Dynamic Link</h5>
          </Col>
          <Col xs={12}>
            {link.hasError && <Alert type="warning" message="Link has to start with https://coach.kinastic.com/..." />}
            <Alert
              type="success"
              message={link.fullLink}
              action={
                <Space direction="vertical">
                  <Button size="small" type="primary" onClick={() => copy(link.fullLink ?? '')}>
                    Copy Link
                  </Button>
                  <Button size="small" danger ghost onClick={() => window.open(link.fullLink)}>
                    Open Link
                  </Button>
                </Space>
              }
            />
          </Col>
          <Col xs={12}>
            <div style={{ textAlign: 'center' }}>
              {!link.hasError && (
                <QRCodeCanvas
                  value={link.fullLink}
                  includeMargin
                  size={720}
                  style={{ width: '100%', height: 'auto' }}
                  level="M"
                  // imageSettings={{
                  //   src: require('../../Assets/Images/logo.png'),
                  //   width: 120,
                  //   height: 120,
                  //   excavate: true,
                  // }}
                />
              )}
            </div>
          </Col>
        </Row>
      )}
      <Form layout="vertical" style={{ paddingTop: 16 }}>
        <Row>
          <Col sm={6}>
            <Form.Item
              label="Main Link (required)"
              extra="Usually the link that lands in the app and has to start with https://coach.kinastic.com/..."
            >
              <Input
                value={link.link}
                onChange={({ target: { value } }) => runInAction(() => (link.link = value))}
                placeholder="https://coach.kinastic.com/..."
              />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item
              label="Android - Link to open when app not installed (optional)"
              extra="The link to open when the app isn't installed. Specify this to do something other than install
your app from the Play Store when the app isn't installed, such as open the mobile web version of the content, or display a promotional page for your app."
            >
              <Input value={link.afl} onChange={({ target: { value } }) => runInAction(() => (link.afl = value))} />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item
              label="iOS - Link to open when app not installed (optional)"
              extra="The link to open when the app isn't installed. Specify this to do something other than install your app from the
App Store when the app isn't installed, such as open the mobile web version of the content, or display a promotional page for your app."
            >
              <Input value={link.ifl} onChange={({ target: { value } }) => runInAction(() => (link.ifl = value))} />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item
              label="Other Platform Link (like desktop - optional)"
              extra="The link to open on platforms beside Android and iOS. This is useful to specify a different behavior on desktop,
like displaying a full web page of the app content/payload (as specified by param link) with another dynamic link to install the app."
            >
              <Input value={link.ofl} onChange={({ target: { value } }) => runInAction(() => (link.ofl = value))} />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item
              label="Skip the preview page (iOS only - optional)"
              extra="If activated, skip the app preview page when the Dynamic Link is opened, and instead redirect to the app or store.
The app preview page (enabled by default) can more reliably send users to the most appropriate destination when they open Dynamic Links in apps; however,
if you expect a Dynamic Link to be opened only in apps that can open Dynamic Links reliably without this page, you can disable it with this parameter.
This parameter will affect the behavior of the Dynamic Link only on iOS."
            >
              <Checkbox
                checked={link.efr === '1'}
                onChange={({ target: { checked } }) => runInAction(() => (link.efr = checked ? '1' : ''))}
              >
                Skip the preview page?
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <h5>Social Params</h5>
          </Col>
          <Col sm={6}>
            <Form.Item
              label="Social Title (optional)"
              extra="The title to use when the Dynamic Link is shared in a social post."
            >
              <Input value={link.st} onChange={({ target: { value } }) => runInAction(() => (link.st = value))} />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item
              label="Social Description (optional)"
              extra="The description to use when the Dynamic Link is shared in a social post."
            >
              <Input value={link.sd} onChange={({ target: { value } }) => runInAction(() => (link.sd = value))} />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item
              label="Social Image URL (optional)"
              extra="The URL to an image related to this link. The image should be at least 300x200 px, and less than 300 KB."
            >
              <Input value={link.si} onChange={({ target: { value } }) => runInAction(() => (link.si = value))} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <h5>Tracking / UTM Params</h5>
          </Col>
          <Col sm={6}>
            <Form.Item label="UTM Source (optional)">
              <Input
                value={link.utm_source}
                onChange={({ target: { value } }) => runInAction(() => (link.utm_source = value))}
              />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item label="UTM Medium (optional)">
              <Input
                value={link.utm_medium}
                onChange={({ target: { value } }) => runInAction(() => (link.utm_medium = value))}
              />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item label="UTM Campaign (optional)">
              <Input
                value={link.utm_campaign}
                onChange={({ target: { value } }) => runInAction(() => (link.utm_campaign = value))}
              />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item label="UTM Term (optional)">
              <Input
                value={link.utm_term}
                onChange={({ target: { value } }) => runInAction(() => (link.utm_term = value))}
              />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item label="UTM Content (optional)">
              <Input
                value={link.utm_content}
                onChange={({ target: { value } }) => runInAction(() => (link.utm_content = value))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Container>
  );
});
