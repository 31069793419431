/**
 * Created by neo on 14.05.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import AsyncSelect from 'react-select/async';
import { BodyPartRegionAdmin } from '../../../../Model/BodyPart/BodyPartRegionAdmin';

export type BodyPartAntagonistsInputProps = {
  bodyPart: BodyPartRegionAdmin;
};

export const BodyPartAntagonistsInput: React.FC<BodyPartAntagonistsInputProps> = observer(
  ({ bodyPart }: BodyPartAntagonistsInputProps) => {
    const handleChange = React.useCallback(
      (antagonists: BodyPartRegionAdmin[] | undefined) => {
        bodyPart.antagonistIds = (antagonists || []).map((p) => p.id);
        bodyPart.antagonists = antagonists ?? [];
      },
      [bodyPart],
    );

    const fetch = React.useCallback(
      (query: string) => BodyPartRegionAdmin.find({ query }),
      [],
    );

    const getOptionLabel = React.useCallback(
      (option: BodyPartRegionAdmin) => `${option.defaultName} (${option.identifier})`,
      [],
    );

    const getOptionValue = React.useCallback((option: BodyPartRegionAdmin) => option, []);

    return (
      <Form.Item label="Antagonists">
        <AsyncSelect
          defaultOptions
          isClearable={true}
          loadOptions={fetch}
          isMulti={true}
          value={bodyPart.antagonists ?? []}
          hideSelectedOptions={false}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue as any}
          onChange={handleChange as any}
        />
      </Form.Item>
    );
  },
);
