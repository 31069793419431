/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { Input } from 'antd';
import { WorkoutTrayItem } from './WorkoutTrayItem';
import { WorkoutTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';

export type WorkoutSelectionContainerProps = {};

export const WorkoutSelectionContainer: React.FC<WorkoutSelectionContainerProps> = observer((props) => {
  const [query, setQuery] = useState('');
  const [result, setResult] = useState(() => new Array<WorkoutTemplate>());
  const debounce = React.useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    WorkoutTemplate.find({ size: 30, sort: 'templateName' }).then((templates) => setResult(templates));
  }, []);

  const handleChange = React.useCallback(({ target: { value } }) => {
    setQuery(value);
    debounce.current && clearTimeout(debounce.current);
    debounce.current = setTimeout(
      () => WorkoutTemplate.find({ name: value, size: 30 }).then((templates) => setResult(templates)),
      300,
    );
  }, []);

  return (
    <div>
      <SingleColRow>
        <Input value={query} onChange={handleChange} />
      </SingleColRow>
      <SingleColRow>
        {result.map((workoutTemplate) => (
          <WorkoutTrayItem key={workoutTemplate.id} workoutTemplate={workoutTemplate} />
        ))}
      </SingleColRow>
    </div>
  );
});
