/**
 *
 * Created by neo on 18.01.17.
 */

import { action, computed, observable } from 'mobx';
import { LocalizedValue, LocalizedValueJson } from './LocalizedValue';
import { v4 as UUID } from 'uuid';
import { Audited, AuditedJson } from './Audited';

export const languagePriority = ['de', 'en', 'fr', 'it'];

export type LocalizedNamedEntityJson = AuditedJson & {
  name: LocalizedValueJson[];
};

export class LocalizedNamedEntity extends Audited {
  @observable
  name: Array<LocalizedValue> = [];

  constructor(json?: Partial<LocalizedNamedEntityJson>) {
    super(json);
    if (json) {
      this.id = json.id ?? UUID();
      this.name = (json.name || []).map((l) => new LocalizedValue(l));
    }
  }

  toJS(newId: boolean = false): LocalizedNamedEntityJson {
    return Object.assign(super.toJS(newId), {
      name: this.name.map((v) => v.toJS()),
    });
  }

  /**
   * Filters out all languages except the one specified
   * @param lang
   */
  nameLanguageOnly(lang: string): LocalizedValue[] {
    return this.name.filter((n) => n.lang === lang.toLowerCase()).map((n) => new LocalizedValue(n.toJS()));
  }

  @action
  changeName(lang: string, value: string) {
    const existing = this.name.find((n) => n.lang === lang.toLowerCase());
    if (existing) {
      existing.value = value;
    } else {
      this.name.push(
        new LocalizedValue({
          lang: lang.toLowerCase(),
          value,
        }),
      );
    }
  }

  getName(lang: string) {
    return this.name.find((n) => n.lang === lang.toLowerCase())?.value ?? '';
  }

  @computed
  get defaultName(): string {
    for (const lang of languagePriority) {
      const entry = this.name.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.name[0];
    return first?.value ?? '';
  }
}
