/**
 * Created by neo on 19.12.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Card, Descriptions, List } from 'antd';
import { Recipe } from '../../../../Model/Diet/Recipe/Recipe';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ExploreEntryCoachUrl } from './RecipeCoachUrl';
import { GptResponseService } from '../../../../Services/GptResponseService';
import { runInAction } from 'mobx';
import { RecipeInstruction } from '../../../../Model/Diet/Recipe/RecipeInstruction';
import { LocalizedValueJson } from '../../../../Model/LocalizedValue';
import { RecipeInstructionStepJson } from '../../../../Model/Diet/Recipe/RecipeInstructionStep';

function parseTranslatedArray(array: any): LocalizedValueJson[] {
  console.log('array', Array.isArray(array), array);
  if (Array.isArray(array)) {
    if (array.length > 1) {
      return array;
      // return array.flatMap((item) =>
      //   Array.from(Object.entries(item)).map(([lang, value]) => ({ lang, value: value as string })),
      // );
    }
    return Array.from(Object.entries(array[0] ?? {}))?.map(([lang, value]) => ({ lang, value: value as string })) ?? [];
  } else if (typeof array === 'string') {
    return [{ lang: 'de', value: array }];
  }
  return Array.from(Object.entries(array ?? {}))?.map(([lang, value]) => ({ lang, value: value as string })) ?? [];
}

function createStepFromJson(step: any, number: number): RecipeInstructionStepJson {
  return {
    number,
    step: parseTranslatedArray(step.description),
    ingredients: [],
    equipment: [],
  };
}

export type RecipeCardProps = {
  recipe: Recipe;
};

export const RecipeCard: React.FC<RecipeCardProps> = observer(({ recipe }) => {
  const history = useNavigate();
  const [activeTab, setActiveTab] = useState('ingredients');
  const [processing, setProcessing] = useState(false);

  const handleEdit = React.useCallback(() => {
    history(recipe.id);
  }, [history, recipe]);

  const handleChangeTab = React.useCallback((key: string) => {
    setActiveTab(key);
  }, []);

  const handleFixInstructions = React.useCallback(() => {
    setProcessing(true);
    new GptResponseService([
      {
        role: 'system',
        content: `Take the role of a recipe database.
      The recipes should not be translated from American recipes and just transferred to metric scale but they need to be authentic so that Swiss people feel spoken for.
      Use informal language.
      Rinderstreifen should be Rindsstreifen
      Rinderfilet should be Rindsfilet
      Lasagneplatten should be Lasagneplätter
      When you refer to the vegetable paprika, please use the word peperoni for Swiss audience
      Instead of kirschtomaten use cherry-tomaten
      Randen instead of Rote Beeten
      Paniermehl instead of semmelbröt
      Hacktäschen instead of Frikadellen
      Never use "ẞ" , replace it always with "ss"
      
      The ingredients used in the recipes are: ${recipe.ingredients
        .map(
          (i) =>
            `${i.getName('en') ?? i.getName('de')} ${
              (i.measures.metric?.amount ?? 0) > 0
                ? `(${i.measures.metric?.amount} ${i.measures.metric?.unitShort})`
                : ''
            }`,
        )
        .join(', ')}

      The response has to be a JSON array containing only the required steps/tasks describing how someone can prepare and cook the recipe. 
      Make each step easy to understand and detailed. Each step should be only about one single task like "preheat the oven" or "cut the vegetables".
      Use 2 to 3 sentences per step whenever it makes sense. For each object in the array, the following properties are required:
      - description: an array of objects containing the description of the step in different languages
      - description.lang: two letter lowercase language code de | en | fr | it
      - description.value: the current step required to prepare or cook the recipe which are easy to understand and and should be around 2 to 3 sentences long.  Omit wordings like "step 1" or "1., 2." etc.`,
      },
      {
        role: 'user',
        content: recipe.getName('en'),
      },
    ])
      .generate()
      .then((res) => JSON.parse(res))
      .then((steps) => {
        console.log('steps', steps);
        runInAction(
          () =>
            (recipe.instructions = [
              new RecipeInstruction({
                steps: steps
                  .map((s, index) => createStepFromJson(s, index + 1))
                  .filter((s) => s.step.find((l) => l.lang === 'en')?.value !== recipe.getName('en')),
              }),
            ]),
        );
      })
      .then(() => recipe.save())
      .finally(() => setProcessing(false));
  }, [recipe]);

  return (
    <Card
      cover={<img src={recipe.image?.medium} style={{ width: '100%', objectFit: 'cover' }} />}
      title={
        <h6 style={{ color: recipe.isValid ? 'black' : 'red' }}>
          {recipe.getName('de').substring(0, 64)}
          {recipe.getName('de').length > 64 ? '...' : ''}
          <br />
          <small>{recipe.readyInMinutes} Minutes</small> - <small>{`${recipe.servings} servings`}</small>
        </h6>
      }
      actions={[<EditOutlined key="edit" onClick={handleEdit} />]}
      tabList={[
        { key: 'nutrients', tab: 'Nutrients' },
        { key: 'ingredients', tab: 'Ingredients' },
        { key: 'instructions', tab: 'Instructions' },
        { key: 'links', tab: 'Links' },
      ]}
      activeTabKey={activeTab}
      onTabChange={handleChangeTab}
    >
      {activeTab === 'ingredients' && (
        <List
          size="small"
          bordered
          dataSource={recipe.ingredients}
          renderItem={(item) => (
            <List.Item>
              {item.defaultName}
              {' / '}
              {item.getDescription('de') || item.getName('de')}
            </List.Item>
          )}
        />
      )}
      {activeTab === 'nutrients' && (
        <React.Fragment>
          <Descriptions bordered size="small" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
            <Descriptions.Item
              label={'Calories'}
            >{`${recipe.nutrition.nutrients['calories']?.amount}${recipe.nutrition.nutrients['calories']?.unit}`}</Descriptions.Item>
            <Descriptions.Item
              label={'Carbs'}
            >{`${recipe.nutrition.nutrients['carbohydrates']?.amount}${recipe.nutrition.nutrients['carbohydrates']?.unit} (${recipe.nutrition.caloricBreakdown.percentCarbs}%)`}</Descriptions.Item>
            <Descriptions.Item
              label={'Protein'}
            >{`${recipe.nutrition.nutrients['protein']?.amount}${recipe.nutrition.nutrients['protein']?.unit} (${recipe.nutrition.caloricBreakdown.percentProtein}%)`}</Descriptions.Item>
            <Descriptions.Item
              label={'Fat'}
            >{`${recipe.nutrition.nutrients['fat']?.amount}${recipe.nutrition.nutrients['fat']?.unit} (${recipe.nutrition.caloricBreakdown.percentFat}%)`}</Descriptions.Item>
            <Descriptions.Item
              label={'Saturated Fat'}
            >{`${recipe.nutrition.nutrients['saturated_fat']?.amount}${recipe.nutrition.nutrients['saturated_fat']?.unit}`}</Descriptions.Item>
          </Descriptions>
        </React.Fragment>
      )}
      {activeTab === 'instructions' && (
        <React.Fragment>
          <Descriptions bordered size="small" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
            {recipe.instructions
              .flatMap((i) => i.steps)
              .map((step, index) => (
                <Descriptions.Item label={`${index + 1}.`} key={index}>
                  {step.step.find((i) => i.lang === 'de')?.value ?? ''}
                </Descriptions.Item>
              ))}
          </Descriptions>
          <Button type="primary" block={true} onClick={handleFixInstructions} disabled={processing}>
            {processing ? 'Fixing...' : 'Fix Instructions'}
          </Button>
        </React.Fragment>
      )}
      {activeTab === 'links' && <ExploreEntryCoachUrl key="url" recipe={recipe} />}
    </Card>
  );
});
