/**
 * Created by neo on 21.03.2025
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Dropdown, MenuProps } from 'antd';
import { ImproveMode } from '../../Services/TranslationService';

export type ImproveTextButtonProps = {
  className?: string;
  onClick?: (mode: ImproveMode) => void;
  disabled?: boolean;
};

export const ImproveTextButton: React.FC<ImproveTextButtonProps> = observer(({ className, onClick, disabled }) => {
  const items: MenuProps['items'] = [
    {
      key: 'shorten',
      label: 'Shorten',
    },
    {
      key: 'normal',
      label: 'Normal',
    },
    {
      key: 'expand',
      label: 'Expand',
    },
  ];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    console.log('click', e.key);
    onClick?.(e.key as ImproveMode);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown className={className} menu={menuProps} disabled={disabled}>
      <Button disabled={disabled}>{disabled ? 'Processing...' : 'Improve Text'}</Button>
    </Dropdown>
  );
});
