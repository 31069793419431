/**
 * Created by neo on 26.04.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Form, Input, InputProps } from 'antd';

export type ExerciseListScreenSearchInputProps = {
  store: any;
  onClear?: () => any;
} & Partial<InputProps>;

export const ExerciseListScreenSearchInput: React.FC<ExerciseListScreenSearchInputProps> = observer(
  ({ store, onChange, onClear, disabled }: ExerciseListScreenSearchInputProps) => {
    return (
      <Form.Item>
        <Input.Search
          onChange={onChange}
          value={store.input}
          autoFocus={true}
          placeholder="Search for exercise..."
          enterButton="Clear"
          onSearch={onClear}
          loading={store.loading}
          disabled={disabled}
        />
      </Form.Item>
    );
  },
);
