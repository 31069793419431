/**
 *
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { Container } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { SingleColRow } from '../../../Components/SingleColRow';
import { BodyPartTabs } from './BodyPartTabs';
import { Route, Routes } from 'react-router';

export type BodyPartListScreenProps = {};

export const BodyPartListScreen: React.FC<BodyPartListScreenProps> = observer(() => {
  const history = useNavigate();

  const handleChangeTab = React.useCallback(
    (key) => {
      history(key);
    },
    [history],
  );

  return (
    <Container>
      <SingleColRow>
        <h1>{'Body Parts'}</h1>
      </SingleColRow>
      <SingleColRow>
        <Routes>
          <Route path=":tab?/*" element={<BodyPartTabs onTabChange={handleChangeTab} />} />
          <Route path="*" element={<BodyPartTabs onTabChange={handleChangeTab} />} />
        </Routes>
      </SingleColRow>
    </Container>
  );
});
