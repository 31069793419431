/**
 * Created by neo on 04.11.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Form, Input } from 'antd';
import { Col, Row } from 'reactstrap';
import { runInAction } from 'mobx';
import { CustomerGroup } from '../../../../../Model/Gym/CustomerGroup/CustomerGroup';

export type CustomerGroupModalBasicInfoTabProps = {
  customerGroup: CustomerGroup;
};

export const CustomerGroupModalBasicInfoTab: React.FC<CustomerGroupModalBasicInfoTabProps> = observer(
  ({ customerGroup }) => {
    const handleChangeName = React.useCallback(
      ({ target: { value } }) => runInAction(() => (customerGroup.name = value)),
      [customerGroup],
    );

    const handleChangeDescription = React.useCallback(
      ({ target: { value } }) => runInAction(() => (customerGroup.description = value)),
      [customerGroup],
    );

    return (
      <Form layout="vertical">
        <Row>
          <Col md={6}>
            <Form.Item label="Name">
              <Input type="text" value={customerGroup.name} onChange={handleChangeName} />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item label="Description">
              <Input type="text" value={customerGroup.description} onChange={handleChangeDescription} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  },
);
