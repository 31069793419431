import { Token } from './Token';

export class AppAuthTokenClass extends Token {
  static STORE_KEY = 'admin_user:auth_data';

  authData?: Token;

  async getAuthData(): Promise<Token | undefined> {
    return Promise.resolve(this.authData);
  }
}

const AppAuthToken = new AppAuthTokenClass();
export default AppAuthToken;
