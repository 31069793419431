import React, { useState } from 'react';
import { Steps, Button, Input, Alert, Form, Space, Card, Typography, Table } from 'antd';
import { RecipeGenerator } from './RecipeGenerator';
import dayjs from 'dayjs';
import openai from 'openai';
import { Config } from '../../../../Config/Config';

const { Step } = Steps;
const { TextArea } = Input;
const { Title } = Typography;

export type ResearchResult = {
  key: string;
  title: string;
  description: string;
  prompt: string;
};

const getSeasonPrompt = (): string => {
  const month = dayjs().month();
  let season = '';
  let trends = '';

  if (month < 2 || month === 11) {
    season = 'winter';
    trends = 'cozy, warm, and comforting';
  } else if (month < 5) {
    season = 'spring';
    trends = 'fresh, vibrant, and rejuvenating';
  } else if (month < 8) {
    season = 'summer';
    trends = 'light, refreshing, and cooling';
  } else {
    season = 'autumn';
    trends = 'hearty, rich, and flavorful';
  }

  return `Based on the ${season} season and the latest food trends, suggest some creative and trendy recipes perfect for a healthy lifestyle. The recipes should be ${trends}.`;
};

const generateHighResImage = async (recipeName: string): Promise<string> => {
  try {
    const response: any = await new openai({
      apiKey: Config.openai.gptApiKey,
      dangerouslyAllowBrowser: true,
    }).images.generate({
      prompt: recipeName,
      n: 1,
      size: '1024x1024',
    });
    return response.data[0]?.url;
  } catch (err) {
    console.error('Error generating image', err);
    return '';
  }
};

export const RecipeGeneratorAdvancedScreen: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [prompt, setPrompt] = useState(getSeasonPrompt());
  const [researchResults, setResearchResults] = useState<ResearchResult[]>([]);
  const [selectedResults, setSelectedResults] = useState<ResearchResult[]>([]);
  const [recipeInput, setRecipeInput] = useState('');
  const [generators, setGenerators] = useState<RecipeGenerator[]>([]);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [loadingImage, setLoadingImage] = useState(false);
  const [researchLoading, setResearchLoading] = useState(false);

  const handleNext = () => setCurrentStep((prev) => prev + 1);
  const handlePrev = () => setCurrentStep((prev) => prev - 1);

  const handleResearchAndPrepare = async () => {
    setResearchLoading(true);
    try {
      const response = await new openai({
        apiKey: Config.openai.gptApiKey,
        dangerouslyAllowBrowser: true,
      }).responses.create({
        model: 'o3-mini',
        instructions: `You are an assistant that provides creative and trendy recipe research results based on the latest food trends, the current time of the year, and the season. Return a JSON array where each entry has the properties: key, title, description, and prompt.`,
        input: prompt,
        max_output_tokens: 16000,
      });
      const resultText = response.output_text ?? '';
      const researchResults: ResearchResult[] = JSON.parse(resultText);
      setResearchResults(researchResults);
    } catch (err) {
      console.error('Error researching recipes', err);
    } finally {
      setResearchLoading(false);
    }
  };

  // When generating recipes we use the selected research results.
  // The generator prompt combines the recipe name and a short description.
  // Each generator will generate JSON following the JSON structure defined earlier.
  const handleGenerateRecipes = async () => {
    const gens = await Promise.all(
      selectedResults.map((result) => RecipeGenerator.create(`${result.title}\n${result.description}`)),
    );
    setGenerators(gens);
    // Trigger generation and allow live progress tracking for each generator.
    gens.forEach((generator) => {
      generator.generate().catch((err) => console.error(err));
    });
  };

  const handleGenerateImage = async () => {
    if (!recipeInput.trim()) return;
    setLoadingImage(true);
    const url = await generateHighResImage(recipeInput.trim());
    setImageUrl(url);
    setLoadingImage(false);
  };

  const researchColumns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  const rowSelection = {
    onChange: (_: React.Key[], selectedRows: ResearchResult[]) => {
      setSelectedResults(selectedRows);
    },
    getCheckboxProps: (record: ResearchResult) => ({
      name: record.title,
    }),
  };

  return (
    <div style={{ padding: 24 }}>
      <Steps current={currentStep} style={{ marginBottom: 32 }}>
        <Step title="Prepare Prompt & Research" description="Get seasonal ideas and research online" />
        <Step title="Generate Recipe" description="Generate recipe details and image" />
      </Steps>
      {currentStep === 0 && (
        <Card title="Step 1: Prepare Prompt & Research">
          <Form layout="vertical">
            <Form.Item label="Seasonal Prompt">
              <TextArea rows={4} value={prompt} onChange={(e) => setPrompt(e.target.value)} />
            </Form.Item>
            <Space style={{ marginBottom: 16 }}>
              <Button type="primary" onClick={handleResearchAndPrepare} disabled={researchLoading}>
                Research \&amp; Prepare
              </Button>
            </Space>
            {researchResults.length > 0 && (
              <>
                <Title level={5}>Research Results</Title>
                <Table
                  rowKey="key"
                  dataSource={researchResults}
                  columns={researchColumns}
                  rowSelection={rowSelection}
                  pagination={false}
                />
              </>
            )}
            <Space style={{ marginTop: 24 }}>
              <Button type="primary" onClick={handleNext} disabled={selectedResults.length === 0 || researchLoading}>
                Continue to Recipe Generation
              </Button>
            </Space>
          </Form>
        </Card>
      )}
      {currentStep === 1 && (
        <Card title="Step 2: Generate Recipe">
          <Form layout="vertical">
            <Form.Item label="Recipe prompt (can be edited)">
              <TextArea rows={6} value={recipeInput} onChange={(e) => setRecipeInput(e.target.value)} />
            </Form.Item>
            <Space style={{ marginBottom: 16 }}>
              <Button type="primary" onClick={handleGenerateRecipes}>
                Generate Recipe Details
              </Button>
              <Button onClick={handleGenerateImage} loading={loadingImage}>
                Generate High-Res Image
              </Button>
            </Space>
            {generators.length > 0 && (
              <>
                <Alert message={`Generating ${generators.length} recipes...`} type="success" showIcon />
                {generators.map((generator, index) => (
                  <Card key={index} type="inner" title={generator.recipeName} style={{ marginBottom: 12 }}>
                    <p>
                      <strong>Live progress:</strong> <span>{generator.tmpMessage}</span>
                    </p>
                  </Card>
                ))}
              </>
            )}
            {imageUrl && (
              <div style={{ marginTop: 16 }}>
                <Title level={5}>Generated Recipe Image</Title>
                <img src={imageUrl} alt="Recipe" style={{ width: '100%', maxWidth: 400 }} />
              </div>
            )}
            <Space style={{ marginTop: 24 }}>
              <Button onClick={handlePrev}>Back</Button>
            </Space>
          </Form>
        </Card>
      )}
    </div>
  );
};
