import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { ActivityLog } from '../../../../Model/Activity/ActivityLog';
import { AthleteLink } from '../../../../Components/AthleteLink';

export type ActivityLogTableRowProps = {
  activityLog: ActivityLog;
};

export const ActivityLogTableRow: React.FC<ActivityLogTableRowProps> = observer(({ activityLog }) => {
  return (
    <tr>
      <td>
        {activityLog.isWorkoutLog ? (
          <Link to={`/tracking/workout-log/${activityLog.source.sourceId}`}>{activityLog.type || '<no name>'}</Link>
        ) : (
          activityLog.type || '<no name>'
        )}
      </td>
      <td>
        <AthleteLink athlete={activityLog.athlete} />
      </td>
      <td>
        {activityLog.source.source} / {activityLog.source.sourceType}
      </td>
      <td>{dayjs(activityLog.startDate).format('DD.MM.YYYY')}</td>
      <td>{dayjs(activityLog.startDate).format('HH:mm')}</td>
      <td>{activityLog.endDate ? dayjs(activityLog.endDate).format('HH:mm') : 'running'}</td>
      {/*<td>/!*{Math.round(activityLog.data)} <small>({activityLog.totalCaloriesBurnt})</small>*!/</td>*/}
      <td>{dayjs.duration(activityLog.durationMs).format('HH:mm')}</td>
    </tr>
  );
});
