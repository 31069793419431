/**
 * Created by neo on 01.06.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Button } from 'antd';
import { WorkoutTemplate } from '../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';
import { TranslationService } from '../../../Services/TranslationService';

export type TranslateCoachWorkoutTemplateComponentProps = {};

export const TranslateCoachWorkoutTemplateComponent: React.FC<TranslateCoachWorkoutTemplateComponentProps> = observer(
  () => {
    const [translating, setTranslating] = useState(false);

    const handleTranslate = React.useCallback(async () => {
      setTranslating(true);
      try {
        let page = 0;
        let results: WorkoutTemplate[] | undefined;
        while (!results || results.length >= 10) {
          results = await WorkoutTemplate.find({ size: 10, page });
          await Promise.all(results.map((e) => TranslationService.translateName(e.name)));
          await Promise.all(results.map((e) => TranslationService.translateName(e.description)));

          await Promise.all(results.map((e) => e.save()));
          page += 1;
        }
      } finally {
        setTranslating(false);
      }
    }, []);

    return (
      <Button onClick={handleTranslate} disabled={translating}>
        Translate All
      </Button>
    );
  },
);
