/**
 * Created by neo on 10.07.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

export type NodeWidgetHeaderTitleProps = {
  title: string;
};

const StyledTitleName = styled.div`
  flex-grow: 1;
  flex: 1;
  padding: 5px 5px;
`;

export const NodeWidgetHeaderTitle: React.FC<NodeWidgetHeaderTitleProps> = observer(
  ({ title }: NodeWidgetHeaderTitleProps) => {
    return <StyledTitleName>{title}</StyledTitleName>;
  },
);
