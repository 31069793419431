/**
 * Created by neo on 02.08.22.
 */
import { observable } from 'mobx';
import { EarnModelRuleTimeConstraint, EarnModelRuleTimeConstraintJson } from './EarnModelRuleTimeConstraint';
import { EarnModelRuleActivationLogic, EarnModelRuleActivationLogicJson } from './EarnModelRuleActivationLogic';
import { EarnModelTemplateRuleAbstract, EarnModelTemplateRuleAbstractJson } from './EarnModelTemplateRuleAbstract';

export type EarnModelTemplateRuleInstantJson = EarnModelTemplateRuleAbstractJson & {
  timeConstraint: EarnModelRuleTimeConstraintJson;
  activationLogic: EarnModelRuleActivationLogicJson;
  rewardsUrl: string;
};

export class EarnModelTemplateRuleInstant extends EarnModelTemplateRuleAbstract {
  @observable
  timeConstraint = new EarnModelRuleTimeConstraint();
  @observable
  activationLogic = new EarnModelRuleActivationLogic();
  @observable
  rewardsUrl = '';

  constructor(json?: Partial<EarnModelTemplateRuleInstantJson>) {
    super(Object.assign({}, json ?? {}, { type: 'instant' }));
    if (json) {
      this.timeConstraint = new EarnModelRuleTimeConstraint(json.timeConstraint);
      this.activationLogic = new EarnModelRuleActivationLogic(json.activationLogic);
    }
  }

  toJS(newId: boolean = false): EarnModelTemplateRuleInstantJson {
    return Object.assign(super.toJS(newId), {
      timeConstraint: this.timeConstraint?.toJS(),
      activationLogic: this.activationLogic.toJS(),
      rewardsUrl: this.rewardsUrl,
    });
  }

  copy(): EarnModelTemplateRuleInstant {
    return new EarnModelTemplateRuleInstant(this.toJS(true));
  }
}
