/**
 * Created by Azat Fazlyev on 20.09.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, message, Modal, Space } from 'antd';
import { DailyTip } from '../../../Model/DailyTip/DailyTip';
import { TranslationInputArray } from '../../../Components/Translation/TranslationInputArray';

export type DailyTipsTableRowProps = {
  dailyTip: DailyTip;
  index: number;
};

export const DailyTipsTableRow: React.FC<DailyTipsTableRowProps> = observer(({ dailyTip, index }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const handleDeleteDailyTip = React.useCallback(() => {
    Modal.confirm({
      title: `Delete ${dailyTip.id}?`,
      onOk() {
        dailyTip.delete();
      },
    });
  }, [dailyTip]);

  const handleUpdateDailyTip = React.useCallback(() => {
    dailyTip.update();
  }, [dailyTip]);

  return (
    <tr>
      <td>{`${index + 1}.`}</td>
      <td>
        {contextHolder}
        <br />
        <Space>
          <Button danger size="small" onClick={handleDeleteDailyTip}>
            Delete Daily Tip
          </Button>
          <Button size="small" onClick={handleUpdateDailyTip}>
            Update Daily Tip
          </Button>
        </Space>
      </td>
      <td>
        <TranslationInputArray entity={dailyTip} field="value" type="textarea" />
      </td>
    </tr>
  );
});
