import * as React from 'react';
import { observer } from 'mobx-react';
import { Gym } from '../../../../../../Model/Gym/Gym';
import { Employee } from '../../../../../../Model/Gym/Employee';
import { Customer } from '../../../../../../Model/Customer/Customer';
import { SingleColRow } from '../../../../../../Components/SingleColRow';
import { useEffect, useState } from 'react';
import { Button, Input, message, Modal, Form, Select, Table } from 'antd';
import { useDebouncedEffect } from '../../../../../../Utils/useDebouncedEffect';

export type EmployeeCreateModalProps = {
  gym: Gym;
  isOpen?: boolean;
  onClose?: () => any;
};

export const EmployeeCreateModal: React.FC<EmployeeCreateModalProps> = observer(({ gym, isOpen, onClose }) => {
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>();
  const [customerResult, setCustomerResult] = useState<Customer[]>([]);
  const [employee, setEmployee] = useState<Employee | undefined>();
  const [query, setQuery] = useState('');

  useDebouncedEffect(() => {
    Customer.find({
      query,
      gymId: gym.id,
      size: 10,
    }).then((res) => setCustomerResult(res));
  }, [query, gym]);

  useEffect(() => {
    if (selectedCustomer) {
      setEmployee(
        new Employee({
          athlete: selectedCustomer.athlete.toJS(),
          gymId: gym.id,
          roles: [],
        }),
      );
    } else {
      setEmployee(undefined);
    }
  }, [selectedCustomer, gym]);

  const toggle = () => {
    onClose && onClose();
  };

  const dismiss = () => {
    onClose && onClose();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const createEmployee = async () => {
    try {
      if (employee) {
        await employee.save();
        message.success('Employee created');
        dismiss();
      }
    } catch (err) {
      message.error('Failed to create employee');
    }
  };

  // Updated table columns with render functions for nested values.
  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (_: any, record: Customer) => (record.athlete ? record.athlete.fullName : ''),
    },
    {
      title: 'Email',
      key: 'email',
      render: (_: any, record: Customer) => record.athlete.user.email,
    },
  ];

  // Antd Table rowSelection as radio buttons.
  const rowSelection = {
    type: 'radio' as const,
    selectedRowKeys: selectedCustomer ? [selectedCustomer.id] : [],
    onChange: (selectedRowKeys: React.Key[], selectedRows: Customer[]) => {
      setSelectedCustomer(selectedRows[0]);
    },
  };

  return (
    <Modal
      open={true}
      onCancel={toggle}
      title="Create Employee"
      footer={[
        <Button key="1" type="primary" onClick={createEmployee} disabled={!selectedCustomer}>
          Create Employee
        </Button>,
        <Button key="2" type="dashed" onClick={dismiss}>
          Cancel
        </Button>,
      ]}
      destroyOnClose
    >
      <SingleColRow>
        <Input type="text" placeholder="Search for customer" onChange={handleChange} />
      </SingleColRow>
      <SingleColRow>
        <Table
          dataSource={customerResult}
          columns={columns}
          rowKey="id"
          size="small"
          rowSelection={rowSelection}
          pagination={{ pageSize: 10 }}
        />
      </SingleColRow>
      {employee && (
        <SingleColRow>
          <Form layout="vertical" initialValues={{ roles: employee.roles || [] }}>
            <Form.Item label="Roles" name="roles">
              <Select
                mode="multiple"
                placeholder="Select roles"
                onChange={(value) => {
                  employee.roles = value;
                  setEmployee(new Employee({ ...employee.toJS(), roles: value, gymId: gym.id }));
                }}
                value={employee.roles}
              >
                {['ROLE_GYM_PUBLISHER', 'ROLE_GYM_EMPLOYEE', 'ROLE_GYM_ADMIN'].map((role) => (
                  <Select.Option key={role} value={role}>
                    {role}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </SingleColRow>
      )}
    </Modal>
  );
});
