/**
 *
 * Created by neo on 30.11.16.
 */

import { observable, action, computed } from 'mobx';
import AppAuthToken from '../Services/Security/AppAuthToken';
import { Athlete } from '../Model/Athlete/Athlete';
import { Token } from '../Services/Security/Token';

export class AuthenticationStore {
  @observable
  authenticated = false;
  @observable
  authTokenCheckInProgress = true;
  @observable
  isAuthenticating = false;
  @observable
  athlete?: Athlete;
  @observable
  wrongPasswordCount = 0;

  private readonly oneAboveAllUsers = [
    'jose@kinastic.com',
    'katarina.babic@kinastic.com',
    'katarina.milosevic@kinastic.com',
  ];

  @action
  storeAccessToken(accessToken: string) {
    console.log('storing access token', accessToken);
    AppAuthToken.authData = new Token({ access_token: accessToken });
    return this.checkAuthData();
  }

  @action
  async checkAuthData() {
    this.authTokenCheckInProgress = true;
    if (!this.athlete) {
      try {
        const user = await Athlete.me();
        return this.setUserData(user);
      } catch (err) {
        this.authTokenCheckInProgress = false;
        throw err;
      }
    }
    return this.athlete;
  }

  @action
  setUserData(athlete: Athlete): Athlete {
    this.athlete = athlete;
    this.authenticated = true;
    this.isAuthenticating = false;
    this.authTokenCheckInProgress = false;
    return this.athlete;
  }

  hasAnyRole(roles: string[]): boolean {
    return !!this.athlete?.user.roles.some((r) =>
      roles.map((r1) => (r1.startsWith('ROLE_') ? r1 : `ROLE_${r1}`)).some((r1) => r1 === r),
    );
  }

  @computed
  get isAdmin(): boolean {
    return !!this.athlete?.user.isAdmin;
  }

  @computed
  get isGodAdmin(): boolean {
    return !!this.athlete?.user.isGodAdmin;
  }

  @computed
  get isOneAboveAll(): boolean {
    return this.oneAboveAllUsers.includes(this.athlete?.user?.email ?? '');
  }
}
