/**
 * Created by neo on 01.05.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Form, Select } from 'antd';
import { ExerciseVariationAdmin } from '../../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { ExerciseMechanics } from '../../../../../Model/Exercise/AbstractExercise';

export type ExerciseMechanicsSelectProps = {
  exercise: ExerciseVariationAdmin;
  disabled?: boolean;
};

export const ExerciseMechanicsSelect: React.FC<ExerciseMechanicsSelectProps> = observer(
  ({ exercise, disabled }: ExerciseMechanicsSelectProps) => {
    const handleChange = React.useCallback((e) => (exercise.mechanics = e ? (e as ExerciseMechanics) : undefined), [
      exercise,
    ]);

    return (
      <Form.Item label="Mechanics">
        <Select value={exercise.mechanics} onChange={handleChange} disabled={disabled}>
          <Select.Option value="">{''}</Select.Option>
          <Select.Option value="ISOLATED">{'Isolated'}</Select.Option>
          <Select.Option value="COMPOUND">{'Compound'}</Select.Option>
        </Select>
      </Form.Item>
    );
  },
);
