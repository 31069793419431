import { observable } from 'mobx';
import { v4 as UUID } from 'uuid';
import { SuperMacroWorkoutDayConfiguration, WorkoutDayConfigurationJson } from './SuperMacroWorkoutDayConfiguration';

export type MicroCycleTemplateConfigurationJson = {
  id: string;
  workoutDays: WorkoutDayConfigurationJson[];
};

export class MicroCycleTemplateConfiguration {
  @observable
  id: string = UUID();
  @observable
  workoutDays: SuperMacroWorkoutDayConfiguration[] = [];

  constructor(json?: Partial<MicroCycleTemplateConfigurationJson>) {
    if (json) {
      this.id = json.id || UUID();
      this.workoutDays = (json.workoutDays ?? []).map((m) => new SuperMacroWorkoutDayConfiguration(m));
    }
  }

  toJS(replaceId: boolean = false): any {
    return {
      id: replaceId ? UUID() : this.id,
      workoutDays: this.workoutDays.map((m) => m.toJS()),
    };
  }
}
