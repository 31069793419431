/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { DefaultStageNodeWidget, StageNodeWidgetProps } from '../Default/DefaultStageNodeWidget';

export type FlattenNodeProps = {} & StageNodeWidgetProps;

export const FlattenNodeWidget: React.FC<FlattenNodeProps> = observer(({ node, engine, size }: FlattenNodeProps) => {
  return <DefaultStageNodeWidget engine={engine} node={node} size={size} disableEdit={true} />;
});
