/**
 * Created by neo on 15.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';

function formatDiff(delta?: number) {
  if (delta) {
    if (delta > 0) {
      return `(+${delta})`;
    } else if (delta < 0) {
      return `(${delta})`;
    }
  }
  return '';
}

function delta(value0?: string, value1?: string): number | undefined {
  if (value0 || value1) {
    const dec0 = value0 !== null && value0 !== undefined ? Number(value0) : 0;
    const dec1 = value1 !== null && value1 !== undefined ? Number(value1) : 0;
    return dec0 - dec1;
  }
  return undefined;
}

export type NumericColumnProps = {
  field: string;
  row: AnalyticsRow;
  previousRow?: AnalyticsRow;
};

export const NumericColumn: React.FC<NumericColumnProps> = observer(({ field, row, previousRow }) => {
  const value = row[field];
  const previousValue = previousRow ? previousRow[field] : undefined;

  const diff = delta(value, previousValue);

  const engagementTime = row.avg_engagement_seconds ? Number(row.avg_engagement_seconds) : 0;

  return (
    <td style={engagementTime < 10 ? { backgroundColor: '#dca1a1' } : undefined}>
      {value} {diff ? <span style={{ color: diff > 0 ? 'green' : 'red' }}>{formatDiff(diff)}</span> : null}
    </td>
  );
});
