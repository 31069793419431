import { computed, observable, toJS } from 'mobx';
import { SpineForceType } from './AbstractExercise';

export type ExerciseFilterJson = {
  type: string[];
  force: string[];
  mechanics: string[];
  utilities: string[];
  primaryPositions: string[];
  secondaryPositions: string[];
  excludedPrimaryPositions: string[];
  excludedSecondaryPositions: string[];
  requiredEquipmentTypes: string[];
  equipmentTypes: string[];
  excludedEquipmentTypes: string[];
  secondaryEquipmentTypes: string[];
  excludedSecondaryEquipmentTypes: string[];
  equipmentTypeGroups: string[];
  tags: string[];
  excludedTags: string[];
  trackingKeys: string[];
  excludedTrackingKeys: string[];
  bodyPartIds: string[];
  excludedBodyPartIds: string[];
  synergistIds: string[];
  stabilizerIds: string[];
  joints: string[];
  excludedJoints: string[];
  executionTypes: string[];
  excludedExecutionTypes: string[];
  hipFlexing?: boolean;
  spineFlexing?: boolean;
  spineForceType?: SpineForceType;
};

export class ExerciseFilter {
  @observable
  type: string[] = [];
  @observable
  force: string[] = [];
  @observable
  mechanics: string[] = [];
  @observable
  utilities: string[] = [];
  @observable
  primaryPositions: string[] = [];
  @observable
  secondaryPositions: string[] = [];
  @observable
  excludedPrimaryPositions: string[] = [];
  @observable
  excludedSecondaryPositions: string[] = [];
  @observable
  requiredEquipmentTypes: string[] = [];
  @observable
  equipmentTypes: string[] = [];
  @observable
  excludedEquipmentTypes: string[] = [];
  @observable
  secondaryEquipmentTypes: string[] = [];
  @observable
  excludedSecondaryEquipmentTypes: string[] = [];
  @observable
  equipmentTypeGroups: string[] = [];
  @observable
  tags: string[] = [];
  @observable
  excludedTags: string[] = [];
  @observable
  trackingKeys: string[] = [];
  @observable
  excludedTrackingKeys: string[] = [];
  @observable
  bodyPartIds: string[] = [];
  @observable
  excludedBodyPartIds: string[] = [];
  @observable
  synergistIds: string[] = [];
  @observable
  stabilizerIds: string[] = [];
  @observable
  joints: string[] = [];
  @observable
  excludedJoints: string[] = [];
  @observable
  executionTypes: string[] = [];
  @observable
  excludedExecutionTypes: string[] = [];
  @observable
  hipFlexing?: boolean = undefined;
  @observable
  spineFlexing?: boolean = undefined;
  @observable
  spineForceType?: SpineForceType = undefined;

  constructor(json?: Partial<ExerciseFilterJson>) {
    if (json) {
      this.type = json.type || [];
      this.force = json.force || [];
      this.mechanics = json.mechanics || [];
      this.utilities = json.utilities || [];
      this.primaryPositions = json.primaryPositions || [];
      this.secondaryPositions = json.secondaryPositions || [];
      this.excludedPrimaryPositions = json.excludedPrimaryPositions || [];
      this.excludedSecondaryPositions = json.excludedSecondaryPositions || [];
      this.requiredEquipmentTypes = json.requiredEquipmentTypes || [];
      this.equipmentTypes = json.equipmentTypes || [];
      this.excludedEquipmentTypes = json.excludedEquipmentTypes || [];
      this.secondaryEquipmentTypes = json.secondaryEquipmentTypes || [];
      this.excludedSecondaryEquipmentTypes = json.excludedSecondaryEquipmentTypes || [];
      this.equipmentTypeGroups = json.equipmentTypeGroups || [];
      this.trackingKeys = json.trackingKeys || [];
      this.excludedTrackingKeys = json.excludedTrackingKeys || [];
      this.tags = json.tags || [];
      this.excludedTags = json.excludedTags || [];
      this.bodyPartIds = json.bodyPartIds || [];
      this.excludedBodyPartIds = json.excludedBodyPartIds || [];
      this.synergistIds = json.synergistIds || [];
      this.stabilizerIds = json.stabilizerIds || [];
      this.joints = json.joints || [];
      this.excludedJoints = json.excludedJoints || [];
      this.executionTypes = json.executionTypes || [];
      this.excludedExecutionTypes = json.excludedExecutionTypes || [];
      this.hipFlexing = json.hipFlexing;
      this.spineFlexing = json.spineFlexing;
      this.spineForceType = json.spineForceType;
    }
  }

  toJS(): any {
    return {
      type: toJS(this.type),
      force: toJS(this.force),
      mechanics: toJS(this.mechanics),
      utilities: toJS(this.utilities),
      primaryPositions: toJS(this.primaryPositions),
      secondaryPositions: toJS(this.secondaryPositions),
      excludedPrimaryPositions: toJS(this.excludedPrimaryPositions),
      excludedSecondaryPositions: toJS(this.excludedSecondaryPositions),
      requiredEquipmentTypes: toJS(this.requiredEquipmentTypes),
      equipmentTypes: toJS(this.equipmentTypes),
      excludedEquipmentTypes: toJS(this.excludedEquipmentTypes),
      secondaryEquipmentTypes: toJS(this.secondaryEquipmentTypes),
      excludedSecondaryEquipmentTypes: toJS(this.excludedSecondaryEquipmentTypes),
      equipmentTypeGroups: toJS(this.equipmentTypeGroups),
      trackingKeys: toJS(this.trackingKeys),
      excludedTrackingKeys: toJS(this.excludedTrackingKeys),
      tags: toJS(this.tags),
      excludedTags: toJS(this.excludedTags),
      bodyPartIds: toJS(this.bodyPartIds),
      excludedBodyPartIds: toJS(this.excludedBodyPartIds),
      synergistIds: toJS(this.synergistIds),
      stabilizerIds: toJS(this.stabilizerIds),
      joints: toJS(this.joints),
      excludedJoints: toJS(this.excludedJoints),
      executionTypes: toJS(this.executionTypes),
      excludedExecutionTypes: toJS(this.excludedExecutionTypes),
      hipFlexing: this.hipFlexing,
      spineFlexing: this.spineFlexing,
      spineForceType: this.spineForceType,
    };
  }

  // equals(other: any) {
  //   if (other !== this) {
  //     if (other instanceof ExerciseFilter) {
  //       return (
  //         this.eqSet(other.type, this.type) &&
  //         this.eqSet(other.force, this.force) &&
  //         this.eqSet(other.mechanics, this.mechanics) &&
  //         this.eqSet(other.utilities, this.utilities) &&
  //         this.eqSet(other.primaryPositions, this.primaryPositions) &&
  //         this.eqSet(other.secondaryPositions, this.secondaryPositions) &&
  //         this.eqSet(other.requiredEquipmentTypes, this.requiredEquipmentTypes) &&
  //         this.eqSet(other.equipmentTypes, this.equipmentTypes) &&
  //         this.eqSet(other.excludedEquipmentTypes, this.excludedEquipmentTypes) &&
  //         this.eqSet(other.trackingKeys, this.trackingKeys) &&
  //         this.eqSet(other.excludedEquipmentTypes, this.excludedEquipmentTypes) &&
  //         this.eqSet(other.tags, this.tags) &&
  //         this.eqSet(other.excludedTags, this.excludedTags) &&
  //         this.eqSet(other.bodyPartIds, this.bodyPartIds) &&
  //         this.eqSet(other.excludedBodyPartIds, this.excludedBodyPartIds) &&
  //         this.eqSet(other.synergistIds, this.synergistIds) &&
  //         this.eqSet(other.stabilizerIds, this.stabilizerIds) &&
  //         this.eqSet(other.joints, this.joints) &&
  //         this.eqSet(other.excludedJoints, this.excludedJoints) &&
  //         this.eqSet(other.executionTypes, this.executionTypes)
  //       );
  //     }
  //     return false;
  //   }
  //   return true;
  // }

  // private eqSet(a?: string[], b?: string[]) {
  //   if (a !== b) {
  //     if (a && b) {
  //       const as = new Set(a);
  //       const bs = new Set(b);
  //       if (as.size !== bs.size) return false;
  //       for (const a of as) {
  //         if (!bs.has(a)) {
  //           return false;
  //         }
  //       }
  //     }
  //     return false;
  //   }
  //   return true;
  // }

  merge(other?: ExerciseFilter): ExerciseFilter {
    if (other) {
      const otherJson = other.toJS();
      const json = this.toJS();
      const result = Object.entries(json).reduce((result, [key, value]) => {
        if (Array.isArray(value) && Array.isArray(otherJson[key])) {
          result[key] = value.length === 0 ? otherJson[key] : value;
        } else {
          result[key] = value || otherJson[key];
        }
        return result;
      }, {});
      return new ExerciseFilter(result);
    }
    return this;
  }

  @computed
  get isEmpty(): boolean {
    return (
      (!this.type || this.type.length === 0) &&
      (!this.force || this.force.length === 0) &&
      (!this.mechanics || this.mechanics.length === 0) &&
      (!this.utilities || this.utilities.length === 0) &&
      (!this.primaryPositions || this.primaryPositions.length === 0) &&
      (!this.secondaryPositions || this.secondaryPositions.length === 0) &&
      (!this.excludedPrimaryPositions || this.excludedPrimaryPositions.length === 0) &&
      (!this.excludedSecondaryPositions || this.excludedSecondaryPositions.length === 0) &&
      (!this.requiredEquipmentTypes || this.requiredEquipmentTypes.length === 0) &&
      (!this.equipmentTypes || this.equipmentTypes.length === 0) &&
      (!this.excludedEquipmentTypes || this.excludedEquipmentTypes.length === 0) &&
      (!this.secondaryEquipmentTypes || this.secondaryEquipmentTypes.length === 0) &&
      (!this.excludedSecondaryEquipmentTypes || this.excludedSecondaryEquipmentTypes.length === 0) &&
      (!this.equipmentTypeGroups || this.equipmentTypeGroups.length === 0) &&
      (!this.trackingKeys || this.trackingKeys.length === 0) &&
      (!this.excludedTrackingKeys || this.excludedTrackingKeys.length === 0) &&
      (!this.tags || this.tags.length === 0) &&
      (!this.excludedTags || this.excludedTags.length === 0) &&
      (!this.bodyPartIds || this.bodyPartIds.length === 0) &&
      (!this.excludedBodyPartIds || this.excludedBodyPartIds.length === 0) &&
      (!this.synergistIds || this.synergistIds.length === 0) &&
      (!this.stabilizerIds || this.stabilizerIds.length === 0) &&
      (!this.joints || this.joints.length === 0) &&
      (!this.excludedJoints || this.excludedJoints.length === 0) &&
      (!this.executionTypes || this.executionTypes.length === 0) &&
      (!this.excludedExecutionTypes || this.excludedExecutionTypes.length === 0) &&
      this.hipFlexing === undefined &&
      this.spineFlexing === undefined &&
      !this.spineForceType
    );
  }

  @computed
  get json(): any {
    // little trick to properly observe arrays (use .map(...))
    return {
      type: this.type?.map((m) => m),
      force: this.force?.map((m) => m),
      mechanics: this.mechanics?.map((m) => m),
      utilities: this.utilities?.map((m) => m),
      primaryPositions: this.primaryPositions?.map((m) => m),
      secondaryPositions: this.secondaryPositions?.map((m) => m),
      excludedPrimaryPositions: this.excludedPrimaryPositions?.map((m) => m),
      excludedSecondaryPositions: this.excludedSecondaryPositions?.map((m) => m),
      requiredEquipmentTypes: this.requiredEquipmentTypes?.map((m) => m),
      equipmentTypes: this.equipmentTypes?.map((m) => m),
      excludedEquipmentTypes: this.excludedEquipmentTypes?.map((m) => m),
      secondaryEquipmentTypes: this.secondaryEquipmentTypes?.map((m) => m),
      excludedSecondaryEquipmentTypes: this.excludedSecondaryEquipmentTypes?.map((m) => m),
      equipmentTypeGroups: this.equipmentTypeGroups?.map((m) => m),
      trackingKeys: this.trackingKeys?.map((m) => m),
      excludedTrackingKeys: this.excludedTrackingKeys?.map((m) => m),
      tags: this.tags?.map((m) => m),
      excludedTags: this.excludedTags?.map((m) => m),
      bodyPartIds: this.bodyPartIds?.map((m) => m),
      excludedBodyPartIds: this.excludedBodyPartIds?.map((m) => m),
      synergistIds: this.synergistIds?.map((m) => m),
      stabilizerIds: this.stabilizerIds?.map((m) => m),
      joints: this.joints?.map((m) => m),
      excludedJoints: this.excludedJoints?.map((m) => m),
      executionTypes: this.executionTypes?.map((m) => m),
      excludedExecutionTypes: this.excludedExecutionTypes?.map((m) => m),
      hipFlexing: this.hipFlexing,
      spineFlexing: this.spineFlexing,
      spineForceType: this.spineForceType,
    };
  }
}
