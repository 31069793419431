/**
 * Created by neo on 08.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ExploreCategory } from '../../../Model/Explore/ExploreCategory';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import styled from '@emotion/styled';
import { Gym } from '../../../Model/Gym/Gym';

const Container = styled.div`
  flex: 1;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 18px;
  padding: 16px 0;
`;

const PreviewContainer = styled.div``;

const CategoryContainer = styled.div`
  padding: 16px;
  background-color: #e8e8e8;
  margin-bottom: 8px;
`;

export type ExploreCategoryRendererCategoryGroupProps = {
  category: ExploreCategory;
  gym?: Gym;
};

export const ExploreCategoryRendererCategoryGroup: React.FC<ExploreCategoryRendererCategoryGroupProps> = observer(
  ({ category, gym }) => {
    const history = useNavigate();
    const location = useLocation();

    const [categories, setCategories] = useState<ExploreCategory[]>([]);

    const gymId = new URLSearchParams(location.search).get('gymId') || undefined;

    useEffect(() => {
      ExploreCategory.find({ categories: category.identifier ? [category.identifier] : [] }).then((result) =>
        setCategories(result),
      );
    }, [category]);

    const handleClickEdit = React.useCallback(() => {
      history(`${category.id}/edit?gymId=${gymId ?? ''}`);
    }, [history, category, gymId]);

    return (
      <Container>
        <Title>
          <Row>
            <Col>
              <Link to={`/coach/explore/${category.id}?gymId=${gymId ?? ''}`}>{category.defaultName}</Link>
            </Col>
            {gymId === category.gymId && (
              <Col xs="auto">
                <Button type="link" onClick={handleClickEdit}>
                  Edit
                </Button>
              </Col>
            )}
          </Row>
        </Title>
        <Description>{category.defaultDescription}</Description>
        <PreviewContainer>
          {categories.map((subCategory) => (
            <CategoryContainer key={subCategory.id}>
              <Link to={`/coach/explore/${subCategory.id}?gymId=${gymId ?? ''}`}>{subCategory.defaultName}</Link>
            </CategoryContainer>
          ))}
        </PreviewContainer>
      </Container>
    );
  },
);
