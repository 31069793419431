/**
 * Created by neo on 07.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { WorkoutPhaseTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutPhaseTemplate';
import { PhaseTemplateParamCondition } from './PhaseTemplateParamCondition';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { Modal, Tabs } from 'antd';
import { ParamCondition } from '../../../../../Model/Coach/SuperMacro/ParamCondition';
import styled from '@emotion/styled';
import { PhaseConfiguration } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/PhaseConfiguration';
import { WorkoutTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';
import { useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { runInAction } from 'mobx';

export type PhaseTemplateParamConditionListProps = {
  phaseTemplate: WorkoutPhaseTemplate;
  phaseConfiguration?: PhaseConfiguration;
  workoutTemplate?: WorkoutTemplate;
};

const Container = styled.div`
  margin: 8px 0;
  padding-top: 8px;
  border-top: 1px solid lightgray;
`;

export const PhaseTemplateParamConditionList: React.FC<PhaseTemplateParamConditionListProps> = observer(
  ({ phaseTemplate, phaseConfiguration, workoutTemplate }) => {
    const hasParams = (phaseTemplate.pipeline?.params?.length ?? 0) > 0;
    const [activeTab, setActiveTab] = useState('0');

    const handleAdd = React.useCallback(() => {
      const last = phaseTemplate.params[phaseTemplate.params.length - 1];
      phaseTemplate.params.push(last?.copy() ?? new ParamCondition());
    }, [phaseTemplate]);

    const handleTabChange = React.useCallback((tab) => setActiveTab(tab), []);

    const handleEditTabs = React.useCallback(
      (targetKey, action) => {
        if (action === 'add') {
          const last = phaseTemplate.params[phaseTemplate.params.length - 1];
          phaseTemplate.params.push(last?.copy() ?? new ParamCondition());
          setActiveTab(`${phaseTemplate.params.length - 1}`);
        } else if (action === 'remove') {
          const index = Number(targetKey);
          const condition = phaseTemplate.params[index];
          Modal.confirm({
            title: `Delete Parameter Condition? ${condition.tagCondition.friendlyString}`,
            icon: <ExclamationCircleOutlined />,
            onOk() {
              runInAction(() => {
                phaseTemplate.params.splice(index, 1);
                setActiveTab(index > 0 ? `${index - 1}` : '0');
              });
            },
          });
        }
        console.log('action', action, targetKey);
      },
      [phaseTemplate],
    );

    return hasParams ? (
      <Container>
        <SingleColRow>
          <Tabs
            type="editable-card"
            activeKey={activeTab}
            animated={false}
            onChange={handleTabChange}
            onEdit={handleEditTabs}
          >
            {phaseTemplate.params.map((condition, index) => (
              <Tabs.TabPane tab={condition.tagCondition.friendlyString} key={index.toString()} closable>
                <PhaseTemplateParamCondition
                  key={index.toString()}
                  phaseTemplate={phaseTemplate}
                  condition={condition}
                  index={index}
                  workoutTemplate={workoutTemplate}
                  phaseConfiguration={phaseConfiguration}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
        </SingleColRow>
      </Container>
    ) : null;
  },
);
