/**
 * Created by katarinababic on 3.5.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartColors } from '../../../../Analytics/ChartColors';
import { Form } from 'antd';
import { AnalyticsRow } from '../../../../../Model/Analytics/AnalyticsData';

export type ExploreEntryModalAnalyticsSelectContentChartProps = {
  data: AnalyticsRow[];
};

export const ExploreEntryModalAnalyticsSelectContentChart: React.FC<ExploreEntryModalAnalyticsSelectContentChartProps> =
  observer(({ data }) => {
    return data.length ? (
      <Form.Item label="Number of clicks">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart height={300} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="entry_id" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar type="monotone" dataKey="select_count" fill={ChartColors[0]} />
            <Bar type="monotone" dataKey="user_count" fill={ChartColors[1]} />
          </BarChart>
        </ResponsiveContainer>
      </Form.Item>
    ) : (
      <p>No results for the selected time period</p>
    );
  });
