import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'reactstrap';
import { ExerciseBlock } from '../../../../../Model/ProgramPortfolio/ExerciseBlock';
import { SetsTableRow } from './SetsTableRow';

export type SetsTableProps = {
  exerciseBlock: ExerciseBlock;
};

export const SetsTable: React.FC<SetsTableProps> = observer(({ exerciseBlock }: SetsTableProps) => {
  return (
    <Table>
      <tbody>
        {exerciseBlock.sets.map((set, index) => (
          <SetsTableRow
            key={`${exerciseBlock.exerciseBlockId}_${index}`}
            exerciseBlock={exerciseBlock}
            set={set}
            index={index}
          />
        ))}
      </tbody>
    </Table>
  );
});
