/**
 * Created by neo on 16.07.2024
 */
import { observable, onBecomeObserved } from 'mobx';
import { Gym } from '../../../Model/Gym/Gym';

export type RegisteredUserPerGymAggregationEntryJson = {
  gymId: string;
  date: Date;
  usersJoined: number;
  usersLeft: number;
  deltaUsers: number;
  cumulative: number;
  percentageChange: number;
  onboardingRate: number;
  activitiesLogged: number;
  usersLoggedActivities: number;
  totalDurationInSecondsActivities: number;
  deltaLoggedActivities: number;
  percentageChangeActivities: number;
  deltaActivitiesUsers: number;
  percentageChangeActivitiesUsers: number;
  percentageActiveUsers: number;
};

export class RegisteredUserPerGymAggregationEntry {
  @observable
  gymId = '';
  date: Date = new Date();
  usersJoined = 0;
  usersLeft = 0;
  deltaUsers = 0;
  cumulative = 0;
  percentageChange = 0;
  onboardingRate = 0;
  activitiesLogged = 0;
  usersLoggedActivities = 0;
  totalDurationInSecondsActivities = 0;
  deltaLoggedActivities = 0;
  percentageChangeActivities = 0;
  deltaActivitiesUsers = 0;
  percentageChangeActivitiesUsers = 0;
  percentageActiveUsers = 0;

  @observable
  gym?: Gym;

  constructor(json?: Partial<RegisteredUserPerGymAggregationEntryJson>) {
    if (json) {
      this.gymId = json.gymId ?? '';
      this.date = json.date ? new Date(json.date) : new Date();
      this.usersJoined = json.usersJoined ?? 0;
      this.usersLeft = json.usersLeft ?? 0;
      this.deltaUsers = json.deltaUsers ?? 0;
      this.cumulative = json.cumulative ?? 0;
      this.percentageChange = json.percentageChange ?? 0;
      this.onboardingRate = json.onboardingRate ?? 0;
      this.activitiesLogged = json.activitiesLogged ?? 0;
      this.usersLoggedActivities = json.usersLoggedActivities ?? 0;
      this.totalDurationInSecondsActivities = json.totalDurationInSecondsActivities ?? 0;
      this.deltaLoggedActivities = json.deltaLoggedActivities ?? 0;
      this.percentageChangeActivities = json.percentageChangeActivities ?? 0;
      this.deltaActivitiesUsers = json.deltaActivitiesUsers ?? 0;
      this.percentageChangeActivitiesUsers = json.percentageChangeActivitiesUsers ?? 0;
      this.percentageActiveUsers = json.percentageActiveUsers ?? 0;
    }

    onBecomeObserved(this, 'gym', () => {
      if (!this.gym) {
        Gym.get(this.gymId).then((result) => (this.gym = result));
      }
    });
  }
}
