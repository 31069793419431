/**
 * Created by neo on 18.06.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Pipeline } from '../../../Model/Coach/Pipeline';
import { SingleColRow } from '../../../Components/SingleColRow';
import { PipelineTable } from './PipelineTable';
import { PageResult } from '../../../Model/PageResult';
import { Pager } from '../../../Components/Pager';
import { runInAction } from 'mobx';

export type PipelineListScreenProps = {};

export const PipelineListScreen: React.FC<PipelineListScreenProps> = observer((props: PipelineListScreenProps) => {
  const history = useNavigate();
  const [page, setPage] = useState(0);
  const [pipelines, setPipelines] = useState(new PageResult<Pipeline>());

  const handleCreatePipeline = React.useCallback(() => {
    history('/coach/workout-script/create');
  }, [history]);

  const handleRemove = React.useCallback(
    (pipelineToDelete: Pipeline) => {
      pipelineToDelete.delete().then(() => {
        pipelines.content.filter((p0) => p0.id !== pipelineToDelete.id);
        message.success('Pipeline deleted');
      });
    },
    [pipelines],
  );

  const handleCopy = React.useCallback(
    (pipeline: Pipeline) => {
      pipeline
        .copy()
        .save()
        .then((result) => runInAction(() => pipelines.content.unshift(result)));
    },
    [pipelines],
  );

  const handlePage = React.useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  useEffect(() => {
    PageResult.execute(Pipeline.find({ page, sort: 'name,ASC', size: 20 }), Pipeline.count(), page, 20).then((result) =>
      setPipelines(result),
    );
  }, [page]);

  return (
    <Container>
      <Row>
        <Col>
          <h1>Pipelines</h1>
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={handleCreatePipeline}>
            Create Pipeline
          </Button>
        </Col>
      </Row>
      <SingleColRow>
        <Pager page={pipelines} onPage={handlePage} />
      </SingleColRow>
      <SingleColRow>
        <PipelineTable pipelines={pipelines.content.slice()} onRemove={handleRemove} onCopy={handleCopy} />
      </SingleColRow>
      <SingleColRow>
        <Pager page={pipelines} onPage={handlePage} />
      </SingleColRow>
    </Container>
  );
});
