import { observable, toJS } from 'mobx';

export class GeoJsonPoint {
  @observable
  coordinates: Array<number> = [];
  @observable
  type = 'Point';

  constructor(json?: any) {
    if (json) {
      this.coordinates = json.coordinates || [];
    }
  }

  toJS() {
    return {
      coordinates: toJS(this.coordinates),
      type: this.type,
    };
  }
}
