/**
 * Created by neo on 10.07.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

export type NodeWidgetHeaderLinkProps = {
  label: string;
  onClick?: (e: any) => any;
};

const StyledTitleLink = styled.a`
  padding: 5px 5px;
`;

export const NodeWidgetHeaderLink: React.FC<NodeWidgetHeaderLinkProps> = observer(
  ({ label, onClick }: NodeWidgetHeaderLinkProps) => {
    return <StyledTitleLink onClick={onClick}>{label}</StyledTitleLink>;
  },
);
