/**
 * @flow
 * Created by neo on 13.03.17.
 */
import * as React from 'react';
import { IObservableArray, isObservableArray, runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import { FormGroup, Label } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { SingleColRow } from '../SingleColRow';
import { useEffect } from 'react';
import { BodyPartJoint } from '../../Model/BodyPart/BodyPartJoint';

export type ExerciseFilterJointsCardProps = {
  joints: IObservableArray<string> | Array<string>;
  header?: string;
  disabled?: boolean;
};

export const ExerciseFilterJointsCard: React.FC<ExerciseFilterJointsCardProps> = observer(
  ({ joints, header, disabled }) => {
    const store = useLocalStore(() => ({
      suggestions: new Array<BodyPartJoint>(),
      existing: new Array<BodyPartJoint>(),
      loadExisting(joints: string[]) {
        BodyPartJoint.getAll(joints).then((result) => runInAction(() => (store.existing = result)));
      },
    }));

    useEffect(() => {
      store.loadExisting(joints);
    }, [joints, store]);

    const fetch = React.useCallback(
      (name?: string) =>
        BodyPartJoint.find({
          name,
          page: 0,
          includeHidden: true,
          sort: 'defaultName,ASC',
        }).then((res) => res.filter((b) => !joints.find((b1) => b1 === b.id))),
      [joints],
    );

    const handleChange = React.useCallback(
      (bodyParts?: BodyPartJoint[]) => {
        if (isObservableArray(joints)) {
          joints.replace((bodyParts || []).map((b) => b.identifier));
        } else {
          joints.splice(0, joints.length);
          (bodyParts || []).forEach((b) => joints.push(b.identifier));
        }
        store.existing = bodyParts || [];
      },
      [store, joints],
    );

    const getOptionLabel = React.useCallback(
      (option: BodyPartJoint) => `${option.defaultName} (${option.identifier})`,
      [],
    );

    const getOptionValue = React.useCallback((option: BodyPartJoint) => option, []);

    return (
      <SingleColRow>
        <FormGroup>
          {header ? <Label>{header ? header : 'Joints'}</Label> : null}
          <AsyncSelect
            value={store.existing.slice()}
            cacheOptions
            defaultOptions
            isClearable={true}
            loadOptions={fetch}
            isMulti={true}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue as any}
            onChange={handleChange as any}
            isDisabled={disabled}
          />
        </FormGroup>
      </SingleColRow>
    );
  },
);
