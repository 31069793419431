/**
 * Created by neo on 09.11.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Media } from '../../../../../../Model/Media/Media';
import { Button, Space } from 'antd';
import styled from '@emotion/styled';
import { runInAction } from 'mobx';
import { MediaVisibleRect } from '../../../../../../Model/Media/MediaVisibleRect';

const CONTAINER_HEIGHT = 480;

const LANDSCAPE_RATIO = 16 / 9;

const dragImg = new Image(0, 0);
dragImg.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const DraggableAreaLandscape = styled.div<{ width: number; height: number; x: number; y: number }>`
  border: solid 4px red;
  cursor: pointer;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  position: absolute;
  top: ${({ y }) => `${y}px`};
  left: ${({ x }) => `${x}px`};
`;

export const DraggableAreaHorizontal = styled.div<{ width: number; height: number; x: number; y: number }>`
  border: solid 4px red;
  cursor: pointer;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  position: absolute;
  top: ${({ y }) => `${y}px`};
  left: ${({ x }) => `${x}px`};
`;

const ContentContainer = styled.div`
  overflow-y: scroll;
  max-height: 560px;
`;

export type MediaVisibleAreaTabProps = {
  media: Media;
};

export const MediaVisibleAreaTab: React.FC<MediaVisibleAreaTabProps> = observer(({ media }) => {
  const visibleRect = media.visibleRect;

  const [dimensions, setDimensions] = useState<{ width: number; height: number } | undefined>();
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [mode, setMode] = useState<'landscape' | 'horizontal'>('landscape');
  const [scale, setScale] = useState(0);
  const [boxDimension, setBoxDimension] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

  const initialPosition = React.useRef<{ x: number; y: number }>();
  const video = React.useRef<HTMLVideoElement>(null);
  const container = React.useRef<HTMLDivElement>(null);
  const dragElement = React.useRef<HTMLDivElement>(null);

  const containerWidth = (dimensions?.width ?? 0) / scale;

  useEffect(() => {
    if (visibleRect) {
      const x = (visibleRect.left / 100) * containerWidth;
      const y = (visibleRect.top / 100) * CONTAINER_HEIGHT;
      const width = (visibleRect.right / 100) * containerWidth - x;
      const height = (visibleRect.bottom / 100) * CONTAINER_HEIGHT - y;
      setPosition({ x, y });
      setBoxDimension({ width, height });
    }
  }, [visibleRect, containerWidth]);

  const handleLoad = React.useCallback((response) => {
    if (video.current?.videoWidth && video.current?.videoHeight) {
      setDimensions({ width: video.current.videoWidth, height: video.current.videoHeight });
      setScale(video.current.videoHeight / CONTAINER_HEIGHT);
    }
  }, []);

  const handleImageLoaded = React.useCallback((event) => {
    console.log('handleImageLoaded', event);
    setDimensions({ width: event.target.width, height: event.target.height });
    setScale(event.target.height / CONTAINER_HEIGHT);
  }, []);

  const handleDrag = React.useCallback(
    (event) => {
      event.preventDefault();
      if (event.clientX > 0 && event.clientY > 0 && initialPosition.current) {
        const deltaX = event.clientX - (initialPosition.current?.x ?? 0);
        const deltaY = event.clientY - (initialPosition.current?.y ?? 0);

        setPosition((prev) => {
          const newX = Math.max(0, prev.x + deltaX);
          const newY = Math.max(0, prev.y + deltaY);

          const right = newX + boxDimension.width;
          const bottom = newY + boxDimension.height;

          const x = right <= containerWidth || (right > containerWidth && newX < prev.x) ? newX : prev.x;
          const y = bottom <= CONTAINER_HEIGHT || (bottom > CONTAINER_HEIGHT && newY < prev.y) ? newY : prev.y;

          const xPercentage = (x / containerWidth) * 100;
          const yPercentage = (y / CONTAINER_HEIGHT) * 100;

          // console.log('dims', { x, y, xPercentage, yPercentage });

          return { x, y, xPercentage, yPercentage };
        });
      }

      initialPosition.current = { x: event.clientX, y: event.clientY };
    },
    [boxDimension, containerWidth],
  );

  const handleDragStart = React.useCallback((event) => {
    event.dataTransfer.setDragImage(dragImg, 0, 0);
  }, []);

  const handleDragEnd = (event) => {
    initialPosition.current = undefined;
    runInAction(() => {
      if (media.visibleRect) {
        media.visibleRect.left = (position.x / containerWidth) * 100;
        media.visibleRect.top = (position.y / CONTAINER_HEIGHT) * 100;
        media.visibleRect.right = ((position.x + boxDimension.width) / containerWidth) * 100;
        media.visibleRect.bottom = ((position.y + boxDimension.height) / CONTAINER_HEIGHT) * 100;
      }
    });
  };

  const handlePlusBox = React.useCallback(() => {
    const newBoxDimension = Object.assign({}, boxDimension, {
      width: Math.min(boxDimension.width * 1.1, containerWidth),
      height: Math.min(boxDimension.height * 1.1, CONTAINER_HEIGHT),
    });
    setBoxDimension(newBoxDimension);

    runInAction(() => {
      if (media.visibleRect) {
        media.visibleRect.left = (position.x / containerWidth) * 100;
        media.visibleRect.top = (position.y / CONTAINER_HEIGHT) * 100;
        media.visibleRect.right = ((position.x + newBoxDimension.width) / containerWidth) * 100;
        media.visibleRect.bottom = ((position.y + newBoxDimension.height) / CONTAINER_HEIGHT) * 100;
      }
    });
  }, [boxDimension, containerWidth, media, position]);

  const handleMinusBox = React.useCallback(() => {
    const newBoxDimension = Object.assign({}, boxDimension, {
      width: Math.min(boxDimension.width * 0.9, containerWidth),
      height: Math.min(boxDimension.height * 0.9, CONTAINER_HEIGHT),
    });
    setBoxDimension(newBoxDimension);

    runInAction(() => {
      if (media.visibleRect) {
        media.visibleRect.left = (position.x / containerWidth) * 100;
        media.visibleRect.top = (position.y / CONTAINER_HEIGHT) * 100;
        media.visibleRect.right = ((position.x + newBoxDimension.width) / containerWidth) * 100;
        media.visibleRect.bottom = ((position.y + newBoxDimension.height) / CONTAINER_HEIGHT) * 100;
      }
    });
  }, [boxDimension, containerWidth, media, position]);

  const handlePlusWidthBox = React.useCallback(() => {
    const newBoxDimension = Object.assign({}, boxDimension, {
      width: Math.min(boxDimension.width * 1.05, containerWidth),
      height: boxDimension.height,
    });
    setBoxDimension(newBoxDimension);

    runInAction(() => {
      if (media.visibleRect) {
        media.visibleRect.left = (position.x / containerWidth) * 100;
        media.visibleRect.top = (position.y / CONTAINER_HEIGHT) * 100;
        media.visibleRect.right = ((position.x + newBoxDimension.width) / containerWidth) * 100;
        media.visibleRect.bottom = ((position.y + newBoxDimension.height) / CONTAINER_HEIGHT) * 100;
      }
    });
  }, [containerWidth, boxDimension, position, media]);

  const handleMinusWidthBox = React.useCallback(() => {
    const newBoxDimension = Object.assign({}, boxDimension, {
      width: Math.min(boxDimension.width * 0.95, containerWidth),
      height: boxDimension.height,
    });
    setBoxDimension(newBoxDimension);

    runInAction(() => {
      if (media.visibleRect) {
        media.visibleRect.left = (position.x / containerWidth) * 100;
        media.visibleRect.top = (position.y / CONTAINER_HEIGHT) * 100;
        media.visibleRect.right = ((position.x + newBoxDimension.width) / containerWidth) * 100;
        media.visibleRect.bottom = ((position.y + newBoxDimension.height) / CONTAINER_HEIGHT) * 100;
      }
    });
  }, [boxDimension, containerWidth, media, position]);

  const handlePlusHeightBox = React.useCallback(() => {
    const newBoxDimension = Object.assign({}, boxDimension, {
      width: boxDimension.width,
      height: Math.min(boxDimension.height * 1.05, CONTAINER_HEIGHT),
    });
    setBoxDimension(newBoxDimension);

    runInAction(() => {
      if (media.visibleRect) {
        media.visibleRect.left = (position.x / containerWidth) * 100;
        media.visibleRect.top = (position.y / CONTAINER_HEIGHT) * 100;
        media.visibleRect.right = ((position.x + newBoxDimension.width) / containerWidth) * 100;
        media.visibleRect.bottom = ((position.y + newBoxDimension.height) / CONTAINER_HEIGHT) * 100;
      }
    });
  }, [boxDimension, containerWidth, media, position]);

  const handleMinusHeightBox = React.useCallback(() => {
    const newBoxDimension = Object.assign({}, boxDimension, {
      width: boxDimension.width,
      height: Math.min(boxDimension.height * 0.95, CONTAINER_HEIGHT),
    });
    setBoxDimension(newBoxDimension);

    runInAction(() => {
      if (media.visibleRect) {
        media.visibleRect.left = (position.x / containerWidth) * 100;
        media.visibleRect.top = (position.y / CONTAINER_HEIGHT) * 100;
        media.visibleRect.right = ((position.x + newBoxDimension.width) / containerWidth) * 100;
        media.visibleRect.bottom = ((position.y + newBoxDimension.height) / CONTAINER_HEIGHT) * 100;
      }
    });
  }, [boxDimension, containerWidth, media, position]);

  const handleSwitchMode = React.useCallback(() => {
    const newBoxDimension = Object.assign({}, boxDimension, {
      width: boxDimension.height,
      height: boxDimension.width,
    });

    setBoxDimension(newBoxDimension);
    setMode((prev) => (prev === 'landscape' ? 'horizontal' : 'landscape'));

    runInAction(() => {
      if (media.visibleRect) {
        media.visibleRect.left = (position.x / containerWidth) * 100;
        media.visibleRect.top = (position.y / CONTAINER_HEIGHT) * 100;
        media.visibleRect.right = ((position.x + newBoxDimension.width) / containerWidth) * 100;
        media.visibleRect.bottom = ((position.y + newBoxDimension.height) / CONTAINER_HEIGHT) * 100;
      }
    });
  }, [boxDimension, containerWidth, media, position]);

  const handleResetPosition = React.useCallback(() => {
    setPosition({ x: 0, y: 0 });
  }, []);

  const handleCenterX = React.useCallback(() => {
    setPosition((prev) => {
      const newX = containerWidth / 2 - boxDimension.width / 2;
      return {
        x: newX,
        y: prev.y,
      };
    });
  }, [boxDimension, containerWidth]);

  const handleAddRect = React.useCallback(() => {
    if (containerWidth) {
      const width = mode === 'landscape' ? CONTAINER_HEIGHT / LANDSCAPE_RATIO : containerWidth;
      const height = mode === 'landscape' ? width * LANDSCAPE_RATIO : width / LANDSCAPE_RATIO;

      setBoxDimension({ width, height });

      const left = (position.x / containerWidth) * 100;
      const top = (position.y / CONTAINER_HEIGHT) * 100;

      console.log('addRect', left, top, ((left + width) / containerWidth) * 100);

      runInAction(
        () =>
          (media.visibleRect = new MediaVisibleRect({
            left,
            top,
            right: ((left + width) / containerWidth) * 100,
            bottom: ((top + height) / CONTAINER_HEIGHT) * 100,
          })),
      );
    }
  }, [containerWidth, media, mode, position]);

  const handleRemoveRect = React.useCallback(() => {
    setPosition({ x: 0, y: 0 });
    runInAction(() => (media.visibleRect = undefined));
  }, [media]);

  return (
    <div>
      <Space>
        {visibleRect ? (
          <React.Fragment>
            <Button onClick={handlePlusBox}>+ Box</Button>
            <Button onClick={handleMinusBox}>- Box</Button>
            <Button onClick={handlePlusWidthBox}>+ Box Width</Button>
            <Button onClick={handleMinusWidthBox}>- Box Width</Button>
            <Button onClick={handlePlusHeightBox}>+ Box Height</Button>
            <Button onClick={handleMinusHeightBox}>- Box Height</Button>
            <Button onClick={handleSwitchMode}>{mode === 'landscape' ? 'Horizontal' : 'Landscape'}</Button>
            <Button onClick={handleResetPosition}>Reset Position</Button>
            <Button onClick={handleCenterX}>Center X Axis</Button>
            <Button onClick={handleRemoveRect} danger type="primary">
              Remove Rectangle
            </Button>
          </React.Fragment>
        ) : (
          <Button onClick={handleAddRect} type="primary">
            Add Rectangle
          </Button>
        )}
      </Space>
      <div ref={container} style={styles.container as any}>
        {media.mediaType.startsWith('video/') ? (
          <video ref={video} style={styles.video} onLoadedMetadata={handleLoad} loop={true} controls>
            <source src={media.medium} type={media.mediaType} />
          </video>
        ) : (
          <img src={media.url} alt="media" style={styles.video} onLoad={handleImageLoaded} />
        )}
        {visibleRect && (
          <React.Fragment>
            {mode === 'landscape' ? (
              <DraggableAreaLandscape
                ref={dragElement}
                width={boxDimension.width}
                height={boxDimension.height}
                x={position?.x ?? 0}
                y={position?.y ?? 0}
                draggable={true}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDrag={handleDrag}
              />
            ) : (
              <DraggableAreaHorizontal
                ref={dragElement}
                width={boxDimension.width}
                height={boxDimension.height}
                x={position?.x ?? 0}
                y={position?.y ?? 0}
                draggable={true}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDrag={handleDrag}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
});

const styles = {
  container: {
    height: `${CONTAINER_HEIGHT}px`,
    position: 'relative',
  },
  video: {
    height: '100%',
    width: 'auto',
  },
};
