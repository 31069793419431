/**
 * Created by neo on 01.05.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExerciseVariationAdmin } from '../../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { Form, Select } from 'antd';
import { ExerciseUtility } from '../../../../../Model/Exercise/AbstractExercise';

export type ExerciseUtilitySelectProps = {
  exercise: ExerciseVariationAdmin;
  disabled?: boolean;
};

export const ExerciseUtilitySelect: React.FC<ExerciseUtilitySelectProps> = observer(
  ({ exercise, disabled }: ExerciseUtilitySelectProps) => {
    const handleChange = React.useCallback(
      (value) => (exercise.utility = value ? (value as ExerciseUtility) : undefined),
      [exercise],
    );

    return (
      <Form.Item label="Utility">
        <Select value={exercise.utility} onChange={handleChange} disabled={disabled}>
          <Select.Option value="">{''}</Select.Option>
          <Select.Option value="BASIC">{'Basic'}</Select.Option>
          <Select.Option value="AUXILIARY">{'Auxiliary'}</Select.Option>
        </Select>
      </Form.Item>
    );
  },
);
