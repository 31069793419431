/**
 * Created by katarinababic on 26.1.22..
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import { Gym } from '../../../Model/Gym/Gym';
import { DatePicker, Form } from 'antd';
import dayjs from 'dayjs';
import { AnalyticsData, AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { allWorkoutsFinishedAggregated, gymWorkoutsFinishedAggregated } from './Queries/queryWorkoutsAggregated';
import { allWorkplaceFinishedAggregated, gymWorkplaceFinishedAggregated } from './Queries/queryWorkplaceAggregated';
import { allYogaFinishedAggregated, gymYogaFinishedAggregated } from './Queries/queryYogaAggregated';
import {
  allMeditationsFinishedAggregated,
  gymMeditationsFinishedAggregated,
} from './Queries/queryMeditationsAggregated';
import { allBreathingFinishedAggregated, gymBreathingFinishedAggregated } from './Queries/queryBreathingAggregated';
import { MeditationEntry } from '../../../Model/Explore/MeditationEntry';
import { BreathingEntry } from '../../../Model/Explore/BreathingEntry';
import { YogaEntry } from '../../../Model/Explore/YogaEntry';

export type ExploreEntryAnalyticsTabProps = {
  gym?: Gym;
};

export const ExploreEntryAnalyticsTab: React.FC<ExploreEntryAnalyticsTabProps> = observer(({ gym }) => {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(dayjs());
  const [data, setData] = useState<AnalyticsRow[] | undefined>();

  const getPromiseFromEntryType = React.useCallback((row: AnalyticsRow) => {
    switch (row.entry_type) {
      case 'meditation':
        return MeditationEntry.findOne(row.entry_id);
      case 'breathing':
        return BreathingEntry.findOne(row.entry_id);
      case 'yoga':
        return YogaEntry.findOne(row.entry_id);
      default:
        return undefined;
    }
  }, []);

  const transformEntry = React.useCallback((row: AnalyticsRow) => {
    const promise = getPromiseFromEntryType(row);
    if (promise) {
      return promise
        .then((entry) => {
          row.entry_name = entry.defaultName !== '' ? entry.defaultName : row.entry_id;
          return row;
        })
        .catch(() => {
          row.entry_name = row.entry_id;
          return row;
        });
    }
    return row;
  }, []);

  const fetchAndTransformData = React.useCallback(async () => {
    setData(undefined);
    const parameters = {
      start_date: {
        value: startDate.format('YYYYMMDD'),
      },
      end_date: {
        value: endDate.format('YYYYMMDD'),
      },
      gym_id: {
        value: gym?.id ?? '',
      },
    };

    const fetchedData = await Promise.all([
      AnalyticsData.query({
        query: gym ? gymWorkoutsFinishedAggregated : allWorkoutsFinishedAggregated,
        parameters,
      }),
      AnalyticsData.query({
        query: gym ? gymWorkplaceFinishedAggregated : allWorkplaceFinishedAggregated,
        parameters,
      }),
      AnalyticsData.query({
        query: gym ? gymYogaFinishedAggregated : allYogaFinishedAggregated,
        parameters,
      }),
      AnalyticsData.query({
        query: gym ? gymMeditationsFinishedAggregated : allMeditationsFinishedAggregated,
        parameters,
      }),
      AnalyticsData.query({
        query: gym ? gymBreathingFinishedAggregated : allBreathingFinishedAggregated,
        parameters,
      }),
    ]).then((result) => {
      let dataArr: AnalyticsRow[] = [];
      const newResult = result.map((arr) => {
        return arr.map((item) => {
          if (!item.entry_type) {
            item.entry_type = item.event_name.substring(0, item.event_name.length - 16);
          }
          return item;
        });
      });
      newResult.forEach((arr) => (dataArr = dataArr.concat(arr)));
      return dataArr;
    });

    const transformedFetchedData = await Promise.all(fetchedData.map((row) => transformEntry(row))).then(
      (transformedEntries) => transformedEntries,
    );

    setData(transformedFetchedData);
  }, [gym, startDate, endDate]);

  useEffect(() => {
    fetchAndTransformData();
  }, [gym, startDate, endDate]);

  const handleChangeValidFrom = React.useCallback((date) => setStartDate(date ?? dayjs().subtract(1, 'month')), []);

  const handleChangeValidUntil = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

  return (
    <Form layout="vertical">
      <Container>
        <Row>
          <Col xs={6}>
            <Form.Item label="Start Date">
              <DatePicker value={startDate} onChange={handleChangeValidFrom} />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item label="End Date">
              <DatePicker value={endDate} onChange={handleChangeValidUntil} />
            </Form.Item>
          </Col>
        </Row>
      </Container>

      <Row>
        <Col xs={12}>
          <div style={styles.tableContainer}>
            <Table striped={true} size="sm" bordered style={styles.table as any}>
              <thead style={styles.sticky as any}>
                <tr>
                  <th style={styles.sticky as any}>#</th>
                  <th style={styles.sticky as any}>Entry type</th>
                  <th style={styles.sticky as any}>Entry name</th>
                  <th style={styles.sticky as any}>No of finished sessions</th>
                  <th style={styles.sticky as any}>No of unique users</th>
                  <th style={styles.sticky as any}>Avg % completed</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((row, index) => (
                  <tr key={index}>
                    <th scope="row">{index}</th>
                    <td>{row.entry_type}</td>
                    <td>{row.entry_name}</td>
                    <td>{row.finished_count}</td>
                    <td>{row.user_count}</td>
                    <td>{row.avg_percentage_completed}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Form>
  );
});

const styles = {
  sticky: {
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
  },
  tableContainer: {
    overflow: 'auto',
    height: 800,
  },
  table: {
    position: 'relative',
  },
};
