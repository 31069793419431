/**
 * Created by neo on 23.04.21.
 */
import { DietRequirements, DietRequirementsJson } from './DietRequirements';
import { MealRequirementCalculation, MealRequirementCalculationJson } from './MealRequirementCalculation';
import { observable } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type MealRecommendationJson = {
  dietRequirements: DietRequirementsJson;
  mealRecommendations: MealRequirementCalculationJson[];
};

export class MealRecommendation {
  @observable
  dietRequirements = new DietRequirements();
  @observable
  mealRecommendations: MealRequirementCalculation[] = [];

  constructor(json?: Partial<MealRecommendationJson>) {
    if (json) {
      this.dietRequirements = new DietRequirements(json.dietRequirements);
      this.mealRecommendations = (json.mealRecommendations ?? []).map((m) => new MealRequirementCalculation(m));
    }
  }

  static async get(athleteId?: string): Promise<MealRecommendation> {
    return HttpBackend.get(`/coach/diet/recommendations`, { athleteId }).then((res) => new MealRecommendation(res));
  }
}
