// application/src/App/View/Coach/Explore/ExploreIndexScreen.tsx
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState, useCallback } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Alert, Button, Form, Modal, Space } from 'antd';
import { useParams, useSearchParams, Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import { runInAction } from 'mobx';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { ExploreCategory } from '../../../Model/Explore/ExploreCategory';
import { ExploreContentBuilder } from '../../../Model/Explore/ExploreContentBuilder';
import { ExploreContent } from '../../../Model/Explore/ExploreContent';
import { ExploreEntry } from '../../../Model/Explore/ExploreEntry';
import { ExploreCategoryCard } from './ExploreCategoryCard';
import { ExploreContentEditModal } from './ExploreContentEditModal';
import { ExploreIndexScreenCategoryPath } from './ExploreIndexScreenCategoryPath';
import { SingleColRow } from '../../../Components/SingleColRow';
import { PageResult } from '../../../Model/PageResult';
import { Pager } from '../../../Components/Pager';
import { ExploreStatistics } from './ExploreStatistics';
import { allowedTags } from '../SuperMacro/View/TagConditionInput';
import { Gym } from '../../../Model/Gym/Gym';

const defaultTagOptions = allowedTags.map((value) => ({ value, label: value }));

const CategoryContainer = styled.div`
  width: 600px;
  display: flex;
  flex-wrap: wrap;
  align-items: space-between;
  justify-content: space-between;
`;

const CategorySuperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExploreIndexScreen: React.FC = observer(() => {
  const history = useNavigate();
  const { categoryId } = useParams<{ categoryId?: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const gymId = searchParams.get('gymId') || undefined;
  const page = Number(searchParams.get('page') || '0');

  const [contents, setContents] = useState<PageResult<ExploreContent>>(new PageResult());
  const [parentCategory, setParentCategory] = useState<ExploreCategory | undefined>();
  const [loadingParent, setLoadingParent] = useState(false);
  const [dragIndex, setDragIndex] = useState<number | undefined>();
  const [dragEnabled, setDragEnabled] = useState(false);
  const [selectedGym, setSelectedGym] = useState<Gym | undefined>();
  const [testTags, setTestTags] = useState<string[]>([]);
  const testTagsTags = testTags.map((t) => ({ value: t, label: t }));

  // Helper to load gym
  useEffect(() => {
    if (gymId) {
      Gym.get(gymId).then((result) => setSelectedGym(result));
    } else {
      setSelectedGym(undefined);
    }
  }, [gymId]);

  // Load parent category if categoryId is set
  useEffect(() => {
    setParentCategory(undefined);
    setLoadingParent(true);
    if (categoryId) {
      ExploreCategory.findOne(categoryId, { gymId })
        .then((result) => setParentCategory(result))
        .finally(() => setLoadingParent(false));
    } else {
      setLoadingParent(false);
    }
  }, [categoryId, gymId]);

  // Helper to load contents using ExploreContentBuilder helper
  const loadContents = useCallback((parent?: ExploreCategory, gym?: string, currentPage?: number, tags?: string[]) => {
    PageResult.execute(
      ExploreContentBuilder.find(
        parent
          ? { categories: parent.identifier ? [parent.identifier] : [], tags, gymId: gym, page: currentPage, size: 20 }
          : { root: true, tags, gymId: gym, page: currentPage, size: 20 },
      ),
      ExploreContentBuilder.count(
        parent
          ? { categories: parent.identifier ? [parent.identifier] : [], tags, gymId: gym }
          : { root: true, tags, gymId: gym },
      ),
      currentPage,
      20,
    ).then(setContents);
  }, []);

  // Load contents when parent and other dependencies change
  useEffect(() => {
    if (!loadingParent) {
      setContents(new PageResult());
      loadContents(parentCategory, gymId, page, testTags);
    } else {
      setContents(new PageResult());
    }
  }, [parentCategory, loadingParent, gymId, page, testTags, loadContents]);

  const handleCreateCategory = useCallback(() => {
    if (parentCategory) {
      history(`new/edit?contentType=category&parentId=${parentCategory.identifier}&gymId=${gymId ?? ''}`);
    } else {
      history(`root/new/edit?contentType=category&gymId=${gymId ?? ''}`);
    }
  }, [history, parentCategory, gymId]);

  const handleDrop = useCallback(
    (categoryId: string, newIndex: number) => {
      const index = contents.content.findIndex((c) => c.id === categoryId);
      if (index !== -1) {
        const category = contents.content[index];
        runInAction(() => (category.order = newIndex));
        contents.content.splice(newIndex, 0, contents.content.splice(index, 1)[0]);
        runInAction(() => contents.content.forEach((c, i) => (c.order = i)));
        Promise.all(
          contents.content.map((content) =>
            content instanceof ExploreCategory || content instanceof ExploreEntry ? content.save() : Promise.resolve(),
          ),
        );
      }
    },
    [contents],
  );

  const handleDragStart = useCallback((category: any, index: number) => {
    setDragIndex(index);
  }, []);

  const handleDragEnd = useCallback(() => {
    setDragIndex(undefined);
  }, []);

  const handleToggleDrag = useCallback((checked: boolean) => {
    setDragEnabled(checked);
  }, []);

  const handleEditCategory = useCallback(() => {
    if (parentCategory) {
      history(`${parentCategory.id}/edit?gymId=${gymId ?? ''}`);
    }
  }, [history, parentCategory, gymId]);

  const handleCreateEntry = useCallback(() => {
    if (parentCategory) {
      history(`new/edit?contentType=entry&parentId=${parentCategory.identifier}&gymId=${gymId ?? ''}`);
    } else {
      history(`new/edit?contentType=entry&gymId=${gymId ?? ''}`);
    }
  }, [history, parentCategory, gymId]);

  const handleDeleteCategory = useCallback(() => {
    if (parentCategory) {
      Modal.confirm({
        title: `Delete ${parentCategory.defaultName}??`,
        onOk: () =>
          parentCategory.delete().then(() => history(`/coach/explore?gymId=${gymId ?? ''}`, { replace: true })),
      });
    }
  }, [parentCategory, history, gymId]);

  const fetchGym = useCallback((query: string) => Gym.find({ query, size: 100 }), []);

  const handleChange = useCallback(
    (gym?: Gym | null) => {
      setSelectedGym(gym ?? undefined);
      setSearchParams((prev) => new URLSearchParams({ ...Object.fromEntries(prev), gymId: gym?.id ?? '' }));
    },
    [setSearchParams],
  );

  const getOptionLabel = useCallback((option: Gym) => option.name, []);

  const getOptionValue = useCallback((option: Gym) => option, []);

  const handleContentSaved = useCallback(
    (entry: ExploreContent) => {
      loadContents(parentCategory, gymId, page, testTags);
    },
    [gymId, loadContents, page, parentCategory, testTags],
  );

  const handleContentDeleted = useCallback(
    (entry: ExploreContent) => {
      const index = contents.content.findIndex((c) => c.id === entry.id);
      if (index !== -1) {
        runInAction(() => contents.content.splice(index, 1));
      }
    },
    [contents],
  );

  const handleExcludedChange = useCallback((tags?: any[]) => {
    setTestTags(tags?.map((t) => t.value) ?? []);
  }, []);

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <h1>{parentCategory ? parentCategory.defaultName : 'Explore Tab'}</h1>
        </Col>
        <Col xs="auto">
          <Space>
            {parentCategory && (
              <Button type="primary" onClick={handleCreateEntry}>
                Create Entry
              </Button>
            )}
            <Button type="primary" onClick={handleCreateCategory}>
              {parentCategory ? 'Create Sub Category' : 'Create Root Category'}
            </Button>
            {parentCategory && parentCategory.gymId === gymId && (
              <Button onClick={handleEditCategory}>Edit {parentCategory.defaultName}</Button>
            )}
            {parentCategory && parentCategory.gymId === gymId && (
              <Button type="primary" danger onClick={handleDeleteCategory}>
                Delete {parentCategory.defaultName}
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      <SingleColRow>
        {parentCategory && (
          <h5>
            <ExploreIndexScreenCategoryPath category={parentCategory} gymId={gymId} />
          </h5>
        )}
      </SingleColRow>
      {selectedGym && (
        <SingleColRow>
          <Alert type="warning" message={`You are in \`${selectedGym.name}\``} />
        </SingleColRow>
      )}
      <Form layout="vertical">
        <Row className="mb-3">
          <Col>
            <Form.Item label="Select a gym to view gym specific explore tabs">
              <AsyncSelect
                value={selectedGym}
                cacheOptions
                defaultOptions
                isClearable
                loadOptions={fetchGym}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue as any}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="View with tags" extra="Enter the tags to test the explore view">
              <CreatableSelect
                isClearable
                isMulti
                onChange={handleExcludedChange as any}
                options={defaultTagOptions}
                value={testTagsTags}
                placeholder="Test Tags"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col xs={4} md={5}>
          <Pager page={contents} />
          <CategorySuperContainer>
            <CategoryContainer>
              {contents.content
                .sort((a, b) => a.order - b.order)
                .map((content, index) => (
                  <ExploreCategoryCard
                    key={content.id}
                    content={content}
                    index={index}
                    draggable={dragEnabled}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    dragIndex={dragIndex}
                    onDrop={handleDrop}
                    gym={selectedGym}
                  />
                ))}
            </CategoryContainer>
          </CategorySuperContainer>
          <Pager page={contents} />
        </Col>
        <Col xs={8} md={7}>
          <ExploreStatistics contents={contents} categoryId={categoryId} />
        </Col>
      </Row>
      <Routes>
        <Route
          path=":contentId/edit"
          element={<ExploreContentEditModal onSaved={handleContentSaved} onDeleted={handleContentDeleted} />}
        />
      </Routes>
    </Container>
  );
});
