/**
 * @flow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { ExerciseVariationFilterMultipleSelection, ValueEntry } from './ExerciseVariationFilterMultipleSelection';

export type Props = {
  filter: ExerciseFilter;
};

const Values = [
  { value: 'PUSH', label: 'Push' },
  { value: 'PULL', label: 'Pull' },
  { value: 'PUSH_PULL', label: 'Push & Pull' },
];

@observer
export class ExerciseVariationFilterForce extends React.Component<Props> {
  handleChange = (values: ValueEntry[]) => {
    this.props.filter.force = (values ?? []).map((e) => e.value);
  };

  render() {
    const {
      props: { filter },
    } = this;
    return (
      <ExerciseVariationFilterMultipleSelection
        label="Force"
        container={filter.force || []}
        values={Values}
        onChange={this.handleChange}
      />
    );
  }
}
