/**
 * Created by neo on 18.01.17.
 */

import { action, observable, computed } from 'mobx';
import dayjs from 'dayjs';
import { AbstractSet } from '../../ProgramPortfolio/AbstractSet';
import { WorkoutResponseExerciseBlock } from './WorkoutResponseExerciseBlock';
import { BaseTrackingKey, TrackingKey, TrackingKeysList } from '../../ProgramPortfolio/TrackingKeys';

export class WorkoutResponseSet extends AbstractSet {
  @observable
  exerciseBlock?: WorkoutResponseExerciseBlock = undefined;

  constructor(exerciseBlock?: WorkoutResponseExerciseBlock, source?: any) {
    super(source);
    this.exerciseBlock = exerciseBlock;
    if (source) {
      this.setData(source);
    }
  }

  calculateCalories(bmr24: number): number {
    if (this.plannedCalories > 0) {
      return this.plannedCalories;
    }
    const met = this.exerciseBlock ? this.exerciseBlock.exercise.met || 5.5 : 5.5;
    const timeInHours = Math.max(1, this.plannedDurationMs / 1000.0) / 3600.0;
    return Math.max(1, Math.round(bmr24 * (met || 5.5) * timeInHours)) * 1.2;
  }

  @action
  setData(json: any) {
    this.values = observable.map(json.values || {});
  }

  @action
  updateData(json: any) {
    this.setData(json);
  }

  @action
  setKey(key: TrackingKey, value?: number) {
    if (value) {
      this.values.set(key, value);
    } else {
      this.values.delete(key);
    }
  }

  @computed
  get plannedCalories(): number {
    return this.values.get('CALORIES') || this.values.get('MIN_CALORIES') || this.values.get('MAX_CALORIES') || 0;
  }

  @computed
  get calories(): number {
    return this.plannedCalories;
  }

  @computed
  get duration(): number {
    return this.plannedDurationMs;
  }

  @computed
  get plannedRepetitionBasedDuration(): number {
    return (
      (this.values.get('MIN_REPETITIONS') ||
        this.values.get('MAX_REPETITIONS') ||
        this.values.get('REPETITIONS') ||
        0) * 4
    );
  }

  @computed
  get durationFormatted(): string {
    const { duration } = this;
    if (duration > 0) {
      if (duration > 3599999) {
        return dayjs.utc(duration).format('HH:mm:ss');
      }
      return dayjs.utc(duration).format('mm:ss');
    }
    return '00:00';
  }

  @computed
  get durationSeconds(): number {
    return this.plannedDuration;
  }

  @computed
  get breakTime(): number {
    return this.plannedBreakTime;
  }

  @computed
  get breakTimeMs(): number {
    return this.plannedBreakTime * 1000;
  }

  /**
   * Value is in seconds
   */
  @computed
  get plannedBreakTime(): number {
    return this.values.get('MIN_BREAK') || this.values.get('MAX_BREAK') || this.values.get('BREAK') || 60;
  }

  @computed
  get plannedBreakTimeMs(): number {
    return this.plannedBreakTime * 1000;
  }

  /**
   * Value is in seconds
   */
  @computed
  get plannedDuration(): number {
    return (
      this.values.get('MIN_DURATION') ||
      this.values.get('MAX_DURATION') ||
      this.values.get('DURATION') ||
      this.plannedRepetitionBasedDuration
    );
  }

  /**
   * Includes breakTime if there is a next set
   */
  @computed
  get totalDuration(): number {
    return this.plannedDurationMs + (this.nextSet ? this.breakTimeMs : 0);
  }

  @computed
  get plannedDurationMs(): number {
    return this.plannedDuration * 1000;
  }

  @computed
  get tons(): number {
    const weight = this.values.get('MIN_WEIGHT') || this.values.get('MAX_WEIGHT') || this.values.get('WEIGHT') || 0;
    const reps =
      this.values.get('MIN_REPETITIONS') || this.values.get('MAX_REPETITIONS') || this.values.get('REPETITIONS') || 0;
    return (reps * weight) / 1000;
  }

  @computed
  get index(): number {
    return this.exerciseBlock ? this.exerciseBlock.sets.findIndex((s) => s === this) : -1;
  }

  @computed
  get isLastSet(): boolean {
    if (this.exerciseBlock) {
      const { index } = this;
      return index + 1 === this.exerciseBlock.sets.length;
    }
    return false;
  }

  @computed
  get prevSet(): WorkoutResponseSet | undefined {
    if (this.exerciseBlock) {
      const { index } = this;
      if (index === 0) {
        return this.exerciseBlock.prevBlock?.prevSet;
      }
      return this.exerciseBlock.sets[index - 1];
    }
    return undefined;
  }

  @computed
  get nextSet(): WorkoutResponseSet | undefined {
    if (this.exerciseBlock) {
      const { index, isLastSet } = this;
      if (isLastSet) {
        return this.exerciseBlock.nextBlock?.followingSet;
      }
      const nextIndex = index + 1;
      return nextIndex < this.exerciseBlock.sets.length ? this.exerciseBlock.sets[nextIndex] : undefined;
    }
    return undefined;
  }

  @computed
  get assignedTrackingKeys(): BaseTrackingKey[] {
    return Array.from(this.values.keys()).reduce((result, item) => {
      if (item && (item.startsWith('MIN_') || item.startsWith('MAX_'))) {
        const index = result.findIndex((k) => k === item.substr(4));
        if (index === -1) {
          result.push(item.substr(4) as BaseTrackingKey);
        }
      } else {
        const index = result.findIndex((k) => k === item);
        if (index === -1) {
          result.push(item as BaseTrackingKey);
        }
      }
      return result;
    }, Array<BaseTrackingKey>());
  }

  /**
   * sorting for the next two function is based on the order
   * provided by the TrackingKeys.ts file
   */
  @computed
  get assignedTrackingKeysSorted(): BaseTrackingKey[] {
    return this.assignedTrackingKeys.sort((a, b) => {
      return TrackingKeysList.indexOf(a) - TrackingKeysList.indexOf(b);
    });
  }

  @computed
  get unassignedTrackingKeys(): BaseTrackingKey[] {
    const { exerciseBlock, assignedTrackingKeys } = this;
    if (exerciseBlock) {
      return [...new Set(exerciseBlock.trackingParameters.filter((tk) => assignedTrackingKeys.indexOf(tk) < 0))];
    }
    return [];
  }

  /**
   * sorting for the next two function is based on the order
   * provided by the TrackingKeys.ts file
   */
  @computed
  get unassignedTrackingKeysSorted(): BaseTrackingKey[] {
    return this.unassignedTrackingKeys.sort((a, b) => TrackingKeysList.indexOf(a) - TrackingKeysList.indexOf(b));
  }

  @computed
  get allTrackingKeysSorted(): BaseTrackingKey[] {
    return this.assignedTrackingKeysSorted.concat(this.unassignedTrackingKeysSorted);
  }

  @computed
  get estimatedRepsDuration(): number {
    switch (this.exerciseBlock?.exercise.type) {
      case 'MOBILISATION':
        return 1.5;
      case 'STRENGTH':
        return this.exerciseBlock?.phase?.type === 'interval'
          ? 1.5
          : this.exerciseBlock?.exercise.equipmentTypes.includes('FREE')
          ? 2
          : 3;
      default:
        return 3;
    }
  }

  @computed
  get minRepetitionDuration(): number {
    const reps = this.values.get('MIN_REPETITIONS') || this.values.get('MAX_REPETITIONS') || 0;
    return reps * this.estimatedRepsDuration;
  }

  @computed
  get maxRepetitionDuration(): number {
    const reps = this.values.get('MAX_REPETITIONS') || this.values.get('MIN_REPETITIONS') || 0;
    return reps * this.estimatedRepsDuration;
  }
}
