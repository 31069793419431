/**
 * Created by neo on 13.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { WeekResult } from './UserStatisticsTables';
import { Alert, Col, Row, Table } from 'reactstrap';
import { UserStatisticsWeekTableRow, UserStatisticsWeekTableRowProps } from './UserStatisticsWeekTableRow';
import dayjs from 'dayjs';
import { Button } from 'antd';
import { Gym } from '../../../Model/Gym/Gym';

export type UserStatisticsWeekTableProps = Pick<UserStatisticsWeekTableRowProps, 'onUserSelect'> & {
  gym?: Gym;
  selectedWeekIndex: number;
  weekResults: WeekResult[];
  onLoadWeek?: (weekIndex: number) => void;
};

export const UserStatisticsWeekTable: React.FC<UserStatisticsWeekTableProps> = observer(
  ({ weekResults, selectedWeekIndex, onUserSelect, onLoadWeek, gym }) => {
    const weekResult = weekResults[selectedWeekIndex];
    const previousWeekResult = weekResults[selectedWeekIndex + 1];

    const showNextWeekButton = weekResult && gym ? dayjs(weekResult?.startDate).isAfter(dayjs(gym.createdAt)) : false;
    const usersEngLess10 =
      weekResult?.data.filter((row) => (row.avg_engagement_seconds ? Number(row.avg_engagement_seconds) : 0) < 10) ??
      [];
    const usersEngMore10 =
      weekResult?.data.filter((row) => (row.avg_engagement_seconds ? Number(row.avg_engagement_seconds) : 0) >= 10) ??
      [];
    const avgEngagementTime =
      (weekResult?.data.reduce(
        (agg, row) => agg + (row.avg_engagement_seconds ? Number(row.avg_engagement_seconds) : 0),
        0,
      ) ?? 0) / (weekResult?.data.length ?? 1);

    const avgEngagementTimeActive =
      (usersEngMore10?.reduce(
        (agg, row) => agg + (row.avg_engagement_seconds ? Number(row.avg_engagement_seconds) : 0),
        0,
      ) ?? 0) / (usersEngMore10?.length ?? 1);

    const handlePreviousWeek = React.useCallback(() => {
      if (selectedWeekIndex > 0) {
        onLoadWeek && onLoadWeek(selectedWeekIndex - 1);
      }
    }, [onLoadWeek, selectedWeekIndex]);

    const handleNextWeek = React.useCallback(() => {
      onLoadWeek && onLoadWeek(selectedWeekIndex + 1);
    }, [onLoadWeek, selectedWeekIndex]);

    if (!weekResult) {
      return <Alert color="warning">No Data available</Alert>;
    }

    return (
      <Row>
        <Col xs={12}>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              {selectedWeekIndex > 0 && (
                <Button type="primary" onClick={handlePreviousWeek}>
                  Newer Week
                </Button>
              )}
            </Col>
            <Col>
              <h5 style={{ textAlign: 'center' }}>{`Week ${selectedWeekIndex + 1}`}</h5>
              <h6 style={{ textAlign: 'center' }}>
                {`${weekResult.data.length} users`}
                {' / '}
                {`${usersEngLess10.length} users < 10sec`}
                {' / '}
                {`${weekResult.data.length - usersEngLess10.length} users >= 10sec`}
                {' / '}
                {`${dayjs.utc(avgEngagementTime * 1000).format('HH:mm:ss')} avg eng. time`}
                {' / '}
                {`${dayjs.utc(avgEngagementTimeActive * 1000).format('HH:mm:ss')} avg active eng. time`}
              </h6>
              <h4 style={{ textAlign: 'center' }}>
                {dayjs(weekResult.startDate).format('DD.MM.YYYY')} - {dayjs(weekResult.endDate).format('DD.MM.YYYY')}
              </h4>
            </Col>
            <Col style={{ textAlign: 'center' }}>
              {showNextWeekButton && (
                <Button type="primary" onClick={handleNextWeek}>
                  Older Week
                </Button>
              )}
            </Col>
          </Row>
        </Col>
        {weekResult.data.length <= 0 ? (
          <Col xs={12}>
            <Alert color="warning">No Data available</Alert>
          </Col>
        ) : (
          <Col xs={12}>
            <div style={{ overflow: 'auto', height: 800 }}>
              <Table striped={true} size="sm" bordered style={{ position: 'relative' }}>
                <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
                  <tr>
                    <th style={styles.sticky as any}>#</th>
                    <th style={styles.sticky as any}>User ID</th>
                    <th style={styles.sticky as any}>Date</th>
                    <th style={styles.sticky as any}>Article Cnt</th>
                    <th style={styles.sticky as any}>Article Avg Dur</th>
                    <th style={styles.sticky as any}>Recipe Cnt</th>
                    <th style={styles.sticky as any}>Breathe Cnt</th>
                    <th style={styles.sticky as any}>Breathe Avg %</th>
                    <th style={styles.sticky as any}>Breathe Avg Dur</th>
                    <th style={styles.sticky as any}>Medit. Cnt</th>
                    <th style={styles.sticky as any}>Medit. Avg %</th>
                    <th style={styles.sticky as any}>Medit. Avg Dur</th>
                    <th style={styles.sticky as any}>Yoga Cnt</th>
                    <th style={styles.sticky as any}>Yoga Avg %</th>
                    <th style={styles.sticky as any}>Yoga Avg Dur</th>
                    <th style={styles.sticky as any}>Workout Cnt</th>
                    <th style={styles.sticky as any}>Workout Avg %</th>
                    <th style={styles.sticky as any}>Workout Avg Dur</th>
                    <th style={styles.sticky as any}>Workout Avg Exer.</th>
                    <th style={styles.sticky as any}>Workplace Cnt</th>
                    <th style={styles.sticky as any}>Workplace Avg %</th>
                    <th style={styles.sticky as any}>Workplace Avg Dur</th>
                    <th style={styles.sticky as any}>Workplace Avg Exer.</th>
                    <th style={styles.sticky as any}>Eng Min</th>
                  </tr>
                </thead>
                <tbody>
                  {weekResult.data.map((row, index) => (
                    <UserStatisticsWeekTableRow
                      key={row.user_id}
                      index={index}
                      row={row}
                      previousRow={previousWeekResult?.data.find((d) => d.user_id === row.user_id)}
                      onUserSelect={onUserSelect}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        )}
      </Row>
    );
  },
);

const styles = {
  sticky: {
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
  },
};
