/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { observer } from 'mobx-react';
import { AthleteDeviceInfo } from '../../../../Model/Athlete/AthleteDeviceInfo';
import { useState } from 'react';
import dayjs from 'dayjs';
import { DeviceInfoTableRowValueRenderer } from './DeviceInfoTableRowValueRenderer';

export type DeviceInfoProps = {
  deviceInfo: AthleteDeviceInfo;
};

export const DeviceInfoTableRow: React.FC<DeviceInfoProps> = observer(({ deviceInfo }) => {
  const [open, setOpen] = useState(false);

  const handleSelect = React.useCallback(() => setOpen((o) => !o), []);

  const keys = Array.from(deviceInfo.data.keys())
    .filter((k) => k !== 'appInstanceId')
    .sort();

  return (
    <tbody>
      <tr onClick={handleSelect}>
        <td>{deviceInfo.appName}</td>
        <td>{deviceInfo.data.get('manufacturer')}</td>
        <td>{deviceInfo.data.get('model')}</td>
        <td>
          {deviceInfo.data.get('systemName')} {deviceInfo.data.get('systemVersion')}
        </td>
        <td>{deviceInfo.data.get('appVersion')}</td>
        <td>{deviceInfo.data.get('bundleId')}</td>
        <td>{dayjs(deviceInfo.updatedAt).format('LLLL')}</td>
      </tr>
      {open ? (
        <tr>
          <td colSpan={8}>
            <Row>
              {keys.map((key) => (
                <Col key={key} sm={4}>
                  <b>{key}:</b> <DeviceInfoTableRowValueRenderer deviceInfo={deviceInfo} infoKey={key} />
                </Col>
              ))}
            </Row>
          </td>
        </tr>
      ) : null}
    </tbody>
  );
});
