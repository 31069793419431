/**
 * Created by neo on 29.09.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';

export type EntityMediaListScreenProps = {};

export const EntityMediaListScreen: React.FC<EntityMediaListScreenProps> = observer(
  (props: EntityMediaListScreenProps) => {
    return null;
  },
);
