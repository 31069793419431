/**
 * Created by katarinababic on 28.4.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Gym } from '../../../Model/Gym/Gym';
import { AnalyticsData, AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import dayjs from 'dayjs';
import { activitiesLogged, gymActivitiesLogged } from './Queries/queryActivitiesLoggedAggregated';
import { Col, Container, Row } from 'reactstrap';
import { SingleColRow } from '../../../Components/SingleColRow';
import { DatePicker, Form } from 'antd';
import { ActivitiesLoggedAggregatedChart } from './ActivitiesLoggedAggregatedChart';
import { ActivityLog } from '../../../Model/Activity/ActivityLog';

export type ActivitiesLoggedAggregatedProps = {
  gym?: Gym;
};

export const ActivitiesLoggedAggregated: React.FC<ActivitiesLoggedAggregatedProps> = observer(({ gym }) => {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(dayjs());
  const [data, setData] = useState<AnalyticsRow[] | undefined>();

  const transformRow = React.useCallback((row: AnalyticsRow) => {
    const activityId = row.key === 'activityId' ? row.type : undefined;
    if (activityId) {
      return ActivityLog.get(activityId)
        .then((activityLog) => {
          row.type = activityLog?.type ?? activityId;
          return row;
        })
        .catch(() => {
          row.type = activityId;
          return row;
        });
    }
    return row;
  }, []);

  const fetchAndTransformData = React.useCallback(async () => {
    setData(undefined);

    const fetchedData = await AnalyticsData.query({
      query: gym ? gymActivitiesLogged : activitiesLogged,
      parameters: Object.assign(
        {
          start_date: {
            value: startDate.format('YYYYMMDD'),
          },
          end_date: {
            value: endDate.format('YYYYMMDD'),
          },
        },
        gym
          ? {
              gym_id: {
                value: gym?.id,
              },
            }
          : {},
      ) as any,
    });

    const transformedFetchedData = await Promise.all(fetchedData.map((row) => transformRow(row))).then(
      (transformedRows) => transformedRows,
    );

    setData(
      transformedFetchedData
        .map((d) =>
          Array.from(Object.entries(d))
            .map(([key, value]) => [key, key === 'key' || key === 'type' ? value : Number(value)])
            .reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {} as any),
        )
        .sort((b, a) => a.num_of_logged - b.num_of_logged),
    );
  }, []);

  useEffect(() => {
    setStartDate(dayjs(gym?.createdAt ?? dayjs('2020-05-01', 'YYYY-MM-DD').toDate()));
  }, [gym]);

  useEffect(() => {
    fetchAndTransformData();
  }, [startDate, endDate, gym, fetchAndTransformData]);

  const handleChangeValidFrom = React.useCallback(
    (date) => setStartDate(date ?? dayjs().subtract(1, 'month')),
    [],
  );

  const handleChangeValidUntil = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

  return (
    <Container>
      <SingleColRow>
        <h5>Activities logged (aggregated)</h5>
      </SingleColRow>
      <Row>
        <Col xs={6}>
          <Form.Item label="Start Date">
            <DatePicker value={startDate} onChange={handleChangeValidFrom} />
          </Form.Item>
        </Col>
        <Col xs={6}>
          <Form.Item label="End Date">
            <DatePicker value={endDate} onChange={handleChangeValidUntil} />
          </Form.Item>
        </Col>
        <Col xs={12}>{data && <ActivitiesLoggedAggregatedChart data={data} />}</Col>
      </Row>
    </Container>
  );
});
