/**
 * Created by neo on 05.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { Button, Checkbox, Form, Input, InputNumber, Select, Space } from 'antd';
import { TranslationInputArray } from '../../../../../Components/Translation/TranslationInputArray';
import { EarnModelTemplate } from '../../../../../Model/Engagement/EarnModelTemplate';
import { EarnModelTemplateCategory } from '../../../../../Model/Engagement/EarnModelTemplateCategory';
import { runInAction } from 'mobx';
import { EarnModelTemplateRuleAbstract } from '../../../../../Model/Engagement/EarnModelTemplateRuleAbstract';
import { EarnModelTemplateRuleStepped } from '../../../../../Model/Engagement/EarnModelTemplateRuleStepped';
import { EarnModelTemplateRuleSteppedValueStep } from '../../../../../Model/Engagement/EarnModelTemplateRuleSteppedValueStep';
import { PointsStepEditor } from './PointsStepEditor';

export type BasicTabProps = {
  template: EarnModelTemplate;
  category: EarnModelTemplateCategory;
  rule: EarnModelTemplateRuleAbstract;
};

export const BasicTab: React.FC<BasicTabProps> = observer(({ template, category, rule }) => {
  const handleChangeIdentifier = React.useCallback(
    ({ target: { value } }) => {
      if (rule) {
        runInAction(() => (rule.ruleIdentifier = value.trim().replace(/\s/, '').toLowerCase()));
      }
    },
    [rule],
  );

  const handleChangeTemplateName = React.useCallback(
    ({ target: { value } }) => {
      if (rule) {
        runInAction(() => (rule.templateName = value));
      }
    },
    [rule],
  );

  const handleChangePointsPerActivation = React.useCallback(
    (e) => {
      runInAction(() => (rule.pointsPerActivation = Number(e) ?? 1));
    },
    [rule],
  );

  const handleChangeMaxActivations = React.useCallback(
    (e) => {
      runInAction(() => (rule.maxActivations = Number(e) ?? 1));
    },
    [rule],
  );

  const handleAddStep = React.useCallback(() => {
    if (rule instanceof EarnModelTemplateRuleStepped) {
      const lastStep = rule.pointsSteps[rule.pointsSteps.length - 1];
      runInAction(() =>
        rule.pointsSteps.push(
          new EarnModelTemplateRuleSteppedValueStep({
            minValue: lastStep?.maxValue + 1,
          }),
        ),
      );
    }
  }, [rule]);

  const handleGenerateWorkout = () => {
    if (rule instanceof EarnModelTemplateRuleStepped) {
      rule.pointsSteps = [];
      for (let i = 1; i <= 100; i++) {
        runInAction(() =>
          rule.pointsSteps.push(
            new EarnModelTemplateRuleSteppedValueStep({
              minValue: i * 60,
              maxValue: i * 60 + 59,
              points: 2,
            }),
          ),
        );
      }
    }
  };

  const handleGenerateMindful = () => {
    if (rule instanceof EarnModelTemplateRuleStepped) {
      rule.pointsSteps = [];
      for (let i = 1; i <= 15; i++) {
        runInAction(() =>
          rule.pointsSteps.push(
            new EarnModelTemplateRuleSteppedValueStep({
              minValue: i * 60,
              maxValue: i * 60 + 59,
              points: 2,
            }),
          ),
        );
      }
    }
  };

  const handleChangeValueKey = React.useCallback(
    (valueKey: string) => {
      if (rule instanceof EarnModelTemplateRuleStepped) {
        runInAction(() => (rule.valueKey = valueKey));
      }
    },
    [rule],
  );

  const handleChangeAppActionLink = React.useCallback(
    ({ target: { value } }) => runInAction(() => (rule.appActionLink = value)),
    [rule],
  );

  const handleChangeAllowInChallenges = React.useCallback(
    ({ target: { checked } }: any) => {
      runInAction(() => (rule.allowInChallenges = checked));
    },
    [rule],
  );

  const handleChangeSingleResults = React.useCallback(
    ({ target: { checked } }: any) => {
      if (rule instanceof EarnModelTemplateRuleStepped) {
        runInAction(() => (rule.singleResults = checked));
      }
    },
    [rule],
  );

  return (
    <Row>
      <Col md={6}>
        <Form.Item label="Identifier">
          <Input onChange={handleChangeIdentifier} value={rule.ruleIdentifier} />
        </Form.Item>
      </Col>
      <Col md={6}>
        <Form.Item label="Template Name">
          <Input onChange={handleChangeTemplateName} value={rule.templateName} />
        </Form.Item>
      </Col>
      <Col xs={6}>
        <Form.Item
          label="Optional App Action Link"
          extra="This link is used to navigate to the location within the app where you can collect the points. Not applicable for all rules"
        >
          <Input
            onChange={handleChangeAppActionLink}
            value={rule.appActionLink}
            placeholder="https://coach.kinastic.com/..."
          />
        </Form.Item>
      </Col>
      <Col xs={3}>
        <Form.Item label="Allow in Challenge" extra="These points can be used in a Challenge">
          <Checkbox checked={rule.allowInChallenges} onChange={handleChangeAllowInChallenges}>
            Allow in Challenge
          </Checkbox>
        </Form.Item>
      </Col>
      {rule instanceof EarnModelTemplateRuleStepped && (
        <Col xs={3}>
          <Form.Item
            label="Single Results?"
            extra="Create a result entry per collected points when selected. If not it's merged under one result (like steps count)"
          >
            <Checkbox checked={rule.singleResults} onChange={handleChangeSingleResults}>
              Single Results?
            </Checkbox>
          </Form.Item>
        </Col>
      )}
      <Col md={6}>
        <Form.Item label="Name">
          <TranslationInputArray entity={rule} field="name" />
        </Form.Item>
      </Col>
      <Col md={6}>
        <Form.Item label="Description">
          <TranslationInputArray type="textarea" entity={rule} field="description" />
        </Form.Item>
      </Col>
      <Col md={6}>
        <Form.Item label="Instant messages" extra="Optional messages to show in app when points collected">
          <TranslationInputArray type="textarea" entity={rule} field="instantMessage" />
        </Form.Item>
      </Col>
      {rule instanceof EarnModelTemplateRuleStepped ? (
        <Col md={6}>
          <Form.Item label="Points Steps">
            <Row>
              <Col />
              <Col xs="auto">
                <Space>
                  <Button onClick={handleAddStep} type="primary">
                    Add points step
                  </Button>
                  <Button onClick={handleGenerateWorkout} type="primary">
                    Generate (workout)
                  </Button>
                  <Button onClick={handleGenerateMindful} type="primary">
                    Generate (Mindful)
                  </Button>
                </Space>
              </Col>
            </Row>
            {rule.pointsSteps.map((pointStep, index) => (
              <PointsStepEditor key={`${rule.id}:${index}`} rule={rule} pointStep={pointStep} />
            ))}
          </Form.Item>
        </Col>
      ) : (
        <Col md={3}>
          <Form.Item label="Points Per Activation">
            <InputNumber
              min={1}
              max={10000}
              onChange={handleChangePointsPerActivation}
              value={rule.pointsPerActivation}
            />
          </Form.Item>
        </Col>
      )}
      {rule instanceof EarnModelTemplateRuleStepped && (
        <Col md={3}>
          <Form.Item label="Value Key">
            <Select value={rule.valueKey} onChange={handleChangeValueKey}>
              <Select.Option value="stepCount">Step Count</Select.Option>
              <Select.Option value="activeEnergyBurned">Active Energy Burned</Select.Option>
              <Select.Option value="dietaryWater">Water drank</Select.Option>
              <Select.Option value="durationInSeconds">Duration in Seconds</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      )}

      <Col md={3}>
        <Form.Item label="Max Activations" extra="-1 = unlimited">
          <InputNumber min={-1} onChange={handleChangeMaxActivations} value={rule.maxActivations} />
        </Form.Item>
      </Col>
    </Row>
  );
});
