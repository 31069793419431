/**
 * Created by neo on 18.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export type TopicDistributionChartProps = {
  data: { name: string; value: number }[];
};

export const TopicDistributionChart: React.FC<TopicDistributionChartProps> = observer(({ data }) => {
  return (
    <Form.Item label="Topics">
      <ResponsiveContainer width="100%" height={400}>
        <BarChart height={250} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar type="monotone" dataKey="value" fill="#913CA4" />
        </BarChart>
      </ResponsiveContainer>
    </Form.Item>
  );
});
