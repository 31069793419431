import { Stage } from './Stage';

interface Sort {
  [key: string]: number;
}

export class GraphSortingStage extends Stage<any> {
  process() {
    // const exercises: Array<ExerciseVariation> = input.filter((e) => e instanceof ExerciseVariation);
    // const targets = Object.entries(
    //   exercises.reduce((prev: Sort, exercise) => {
    //     prev[exercise.id] = prev[exercise.id] ?? 100000;
    //     exercise.edges
    //       .filter((edge) => exercises.find((e) => e.id === edge.exerciseId))
    //       .filter((edge) => edge.plannedCount > 5)
    //       .forEach((edge) => {
    //         prev[edge.exerciseId] =
    //           !prev[edge.exerciseId] || prev[edge.exerciseId] === 100000 ? 1 : prev[edge.exerciseId] + 1;
    //       });
    //     return prev;
    //   }, {} as Sort),
    // ).sort(([_, a], [__, b]) => a - b);
    // const result = new Array<ExerciseVariation>();
    // targets.forEach((entry) => {
    //   if (!result.find((e) => e.id === entry[0])) {
    //     let next = exercises.find((e) => e.id === entry[0]);
    //     while (next) {
    //       result.push(next);
    //       next = exercises.find(
    //         (e) =>
    //           next!.edges.sort((a, b) => b.plannedCount - a.plannedCount).find((edge) => edge.exerciseId === e.id) &&
    //           !result.find((r) => r.id === e.id),
    //       );
    //     }
    //   }
    // });
    // console.log('graphSort', exercises, targets, result);
    // return this.processNext(result, context);
    this.dataSignals.forEach((signal) => this.processNext(signal));
  }

  get type(): string {
    return 'graphSort';
  }
}
