/**
 * Created by neo on 24.09.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Modal } from 'antd';
import { TranslationInputArray } from '../../../../../Components/Translation/TranslationInputArray';
import { MesoCycleTemplate } from '../../../../../Model/Coach/Program/Template/MesoCycleTemplate';
import { Col, Row } from 'reactstrap';
import { reaction } from 'mobx';
import { LocalizedValue } from '../../../../../Model/LocalizedValue';

const typeNamesDe = {
  hypertrophy_1: 'Grundlagen Muskelaufbau',
  hypertrophy_2: 'Muskelaufbau',
  low_intensity_muscle_endurance: 'Deload',
  low_intensity_hypertrophy: 'Grundlagen Muskelaufbau',
  muscle_endurance: 'Stärkung Grundlagenausdauer',
  max_strength: 'Kraftzuwachs',
};

const typeNamesEn = {
  hypertrophy_1: 'Hypertrophy',
  hypertrophy_2: 'Hypertrophy 2',
  low_intensity_muscle_endurance: 'Deload',
  low_intensity_hypertrophy: 'Low Intensity Hypertrophy',
  muscle_endurance: 'Muscle Endurance',
  max_strength: 'Max Strength',
};

export type MesoCycleEditModalProps = {
  mesoCycle: MesoCycleTemplate;
  index: number;
  onClose?: () => any;
};

export const MesoCycleEditModal: React.FC<MesoCycleEditModalProps> = observer(
  ({ mesoCycle, index, onClose }: MesoCycleEditModalProps) => {
    useEffect(
      () =>
        reaction(
          () => mesoCycle.type,
          (type) => {
            if (!mesoCycle.name.find((n) => n.lang === 'de')?.value) {
              mesoCycle.name.push(new LocalizedValue({ lang: 'de', value: typeNamesDe[type] }));
            }
            if (!mesoCycle.name.find((n) => n.lang === 'en')?.value) {
              mesoCycle.name.push(new LocalizedValue({ lang: 'en', value: typeNamesEn[type] }));
            }
          },
          { fireImmediately: true },
        ),
      [mesoCycle],
    );

    return (
      <Modal
        width={1920}
        style={{ top: 20 }}
        open={true}
        title={`Edit ${mesoCycle.type} Cycle ${index + 1}`}
        onOk={onClose}
        onCancel={onClose}
      >
        <Row>
          <Col>
            <TranslationInputArray entity={mesoCycle} field="name" label="Name" />
          </Col>
          <Col>
            <TranslationInputArray type="textarea" entity={mesoCycle} field="description" label="Description" />
          </Col>
        </Row>
      </Modal>
    );
  },
);
