import { computed, observable, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';

export type EquipmentConfigurationJson = {
  id: string;
  equipmentTypes: string[];
  excludedEquipmentTypes: string[];
};

export class EquipmentConfiguration {
  @observable
  id = UUID();
  @observable
  equipmentTypes: Array<string> = [];
  @observable
  excludedEquipmentTypes: Array<string> = [];

  constructor(json?: Partial<EquipmentConfigurationJson>) {
    if (json) {
      this.id = json.id ?? UUID();
      this.equipmentTypes = json.equipmentTypes || [];
      this.excludedEquipmentTypes = json.excludedEquipmentTypes || [];
    }
  }

  merge(other: EquipmentConfiguration) {
    return new EquipmentConfiguration({
      id: this.id,
      equipmentTypes: (this.equipmentTypes ?? []).concat(other.equipmentTypes ?? []),
      excludedEquipmentTypes: (this.excludedEquipmentTypes ?? []).concat(other.excludedEquipmentTypes ?? []),
    });
  }

  toJS(newId: boolean = false): any {
    return {
      id: newId ? UUID() : this.id,
      equipmentTypes: toJS(this.equipmentTypes),
      excludedEquipmentTypes: toJS(this.excludedEquipmentTypes),
    };
  }

  @computed
  get isFree(): boolean {
    const hasFree = this.equipmentTypes.includes('FREE');
    const hasFreeOutdoor = this.equipmentTypes.includes('FREE_OUTDOOR');
    return (
      (this.equipmentTypes.length === 1 && (hasFree || hasFreeOutdoor)) ||
      (this.equipmentTypes.length === 2 && hasFree && hasFreeOutdoor)
    );
  }

  @computed
  get isGym(): boolean {
    return this.equipmentTypes.length === 0;
  }
}
