/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { DefaultStageNodeWidget, StageNodeWidgetProps } from '../Default/DefaultStageNodeWidget';
import styled from '@emotion/styled';
import { ConfigAttributeSelect } from '../ConfigAttributeSelect';
import { SingleColRow } from '../../../../../../../Components/SingleColRow';
import { Button, Form } from 'antd';
import { GroupStage } from '../../../../../../../Model/Coach/Stages/GroupStage';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { StyledInput } from '../StyledInput';

export type GroupNodeWidgetProps = {} & StageNodeWidgetProps;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledRow = styled.div`
  display: flex;
  flex: 1;
  padding: 2px;
`;

export const GroupNodeWidget: React.FC<GroupNodeWidgetProps> = observer(
  ({ node, engine, size }: GroupNodeWidgetProps) => {
    const stage = node.stage as GroupStage;

    const handleAddBodyPartGroup = React.useCallback(() => {
      if (!stage.config.bodyPartGroups) {
        stage.config.bodyPartGroups = [];
      }
      stage.config.bodyPartGroups?.push([]);
    }, [stage]);

    const handleRemoveGroup = React.useCallback(
      (index: number) => {
        stage.config.bodyPartGroups?.splice(index, 1);
      },
      [stage],
    );

    const handleChangeGroup = React.useCallback(
      (index: number, value: string) => {
        if (stage.config.bodyPartGroups) {
          stage.config.bodyPartGroups[index] = value.split(',').map((v) => v.trim());
        }
      },
      [stage],
    );

    return (
      <DefaultStageNodeWidget
        engine={engine}
        node={node}
        size={size}
        disableEdit={true}
        content={
          <React.Fragment>
            <StyledRow>
              <ConfigAttributeSelect node={node} />
            </StyledRow>
            <StyledRow>
              {stage.config.attribute === 'bodyParts' ||
              stage.config.attribute === 'synergists' ||
              stage.config.attribute === 'stabilizers' ? (
                <SingleColRow>
                  {stage.config.bodyPartGroups?.map((field, index) => (
                    <Form.Item required={false} key={`${index}`}>
                      <Form.Item noStyle>
                        <StyledInput
                          node={node}
                          value={field.join(', ')}
                          onChange={({ target: { value } }) => {
                            handleChangeGroup(index, value);
                          }}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        style={{ margin: '0 8px' }}
                        onClick={() => {
                          handleRemoveGroup(index);
                        }}
                      />
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={handleAddBodyPartGroup}>
                      <PlusOutlined /> Add field
                    </Button>
                  </Form.Item>
                </SingleColRow>
              ) : null}
            </StyledRow>
          </React.Fragment>
        }
      />
    );
  },
);
