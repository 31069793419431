/**
 * Created by neo on 03.11.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import Setting from '@mui/icons-material/Settings';
import Delete from '@mui/icons-material/Delete';
import { ElementContent } from './ElementContent';
import { ThemeConfig } from '../../../../../Config/Theme';
import FeedEntryViewStore from '../../../FeedEntryViewStore';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';
import { ArticleEntry } from '../../../../../Model/Explore/ArticleEntry';

export type ElementRendererProps = {
  component: NativeFeedComponent;
  entry: ArticleEntry;
  store: FeedEntryViewStore;
  index?: number;
  isHover?: boolean;
  onDelete: () => void;
  lang?: string;
};

export const ElementRenderer: React.FC<ElementRendererProps> = observer(
  ({ component, onDelete, isHover, store, entry, index, lang }) => {
    const { classes } = useStyles();

    const canBeEdited =
      component.type === 'Paragraph' ||
      component.type === 'Image' ||
      component.type === 'Video' ||
      component.type === 'Button' ||
      component.type === 'SecondaryButton';

    const handleSettingsClick = React.useCallback(() => {
      store.setSelectedComponentToEdit(component);
    }, [store, component]);

    const handleDeleteClick = React.useCallback(() => {
      store.deleteComponent(entry, component.key);
      onDelete();
    }, [store, entry, onDelete, component]);

    return (
      <React.Fragment>
        <ElementContent component={component} isHover={isHover} lang={lang} />
        {isHover && (
          <div className={classes.actionsButtons}>
            {canBeEdited && (
              <IconButton aria-label="setting" onClick={handleSettingsClick} id="editComponentBtn">
                <Setting className={classes.settingsIcon} />
              </IconButton>
            )}
            <IconButton aria-label="delete" onClick={handleDeleteClick} id="deleteComponentBtn">
              <Delete color="secondary" className={classes.deleteIcon} />
            </IconButton>
          </div>
        )}
      </React.Fragment>
    );
  },
);

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: '8px 0px',
    position: 'relative',
  },
  hoverContainer: {
    borderRadius: 8,
    border: `1px solid ${ThemeConfig.Colors.primaryColor}`,
    padding: '8px 0',
    marginTop: 4,
    position: 'relative',
  },
  actionsButtons: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: ThemeConfig.Colors.primaryColor,
    border: `1px solid ${ThemeConfig.Colors.primaryColor}`,
    position: 'absolute',
    top: 0,
    right: 0,
    maxWidth: 200,
  },
  deleteIcon: {
    fontSize: 16,
  },
  settingsIcon: {
    color: ThemeConfig.Colors.white,
    fontSize: 16,
  },
}));
