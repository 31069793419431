/**
 * Created by neo on 23.03.21.
 */
import { observable, ObservableMap } from 'mobx';

export enum DurationBefore {
  ONE_HOUR_BEFORE = 'PT1H',
  DAY_BEFORE = 'PT24H',
  HALF_HOUR_BEFORE = 'PT30M',
  AT_EVENT = 'PT0S',
}

export const durationOrder = [
  DurationBefore.AT_EVENT,
  DurationBefore.HALF_HOUR_BEFORE,
  DurationBefore.ONE_HOUR_BEFORE,
  DurationBefore.DAY_BEFORE,
];

export type CampaignNotificationIntervalJson = {
  title: string;
  message: string;
  durationBefore: DurationBefore;
  customData: any;
  sentAt?: string;
  requestId?: string;
};

export class CampaignNotificationInterval {
  @observable
  title: string = '';
  @observable
  message: string = '';
  @observable
  durationBefore: DurationBefore = DurationBefore.ONE_HOUR_BEFORE;
  @observable
  customData: ObservableMap<string, string> = observable.map({});
  @observable
  sentAt?: string;
  @observable
  requestId?: string;

  constructor(json?: Partial<CampaignNotificationIntervalJson>) {
    if (json) {
      this.title = json.title ?? '';
      this.message = json.message ?? '';
      this.durationBefore = json.durationBefore ?? DurationBefore.ONE_HOUR_BEFORE;
      this.customData = observable.map(json.customData ?? {});
      this.sentAt = json.sentAt;
      this.requestId = json.requestId;
    }
  }

  toJS(): CampaignNotificationIntervalJson {
    return {
      title: this.title,
      message: this.message,
      durationBefore: this.durationBefore,
      customData: this.customData.toJSON(),
      sentAt: this.sentAt,
      requestId: this.requestId,
    };
  }
}
