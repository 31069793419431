/**
 * Created by neo on 03.09.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { PhaseConfiguration } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/PhaseConfiguration';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { Col, Row } from 'reactstrap';
import { Affix, Form } from 'antd';
import { PhaseParamEditor } from './PhaseParamEditor';
import { ModuleSelectionContainer } from './ModuleSelectionContainer';
import { WorkoutTemplateContainer } from './WorkoutTemplateContainer';
import { TagConditionsInput } from '../../../SuperMacro/View/TagConditionsInput';
import { WorkoutTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';
import styled from '@emotion/styled';

const ConditionsContainer = styled.div`
  padding-bottom: 16px;
`;

const DefaultParamsContainer = styled.div`
  border-bottom: 1px solid black;
  margin-bottom: 16px;
`;

export type PhaseConfigurationViewProps = {
  phaseConfiguration: PhaseConfiguration;
  workoutTemplate?: WorkoutTemplate;
};

export const PhaseConfigurationView: React.FC<PhaseConfigurationViewProps> = observer(
  ({ phaseConfiguration, workoutTemplate }) => {
    return (
      <Form layout="vertical">
        <ConditionsContainer>
          <TagConditionsInput tagConditions={phaseConfiguration.tagConditions} />
        </ConditionsContainer>
        <DefaultParamsContainer>
          <SingleColRow>
            <h4>Phases Default Params</h4>
          </SingleColRow>
          <Row>
            {phaseConfiguration.allParams.map((param) => (
              <Col key={param.name} xs={6} md={3}>
                <PhaseParamEditor phaseTemplate={phaseConfiguration} workoutTemplate={workoutTemplate} param={param} />
              </Col>
            ))}
          </Row>
        </DefaultParamsContainer>
        <Row>
          <Col md={4} lg={3}>
            <Affix offsetTop={36}>
              <ModuleSelectionContainer />
            </Affix>
          </Col>
          <Col md={8} lg={9}>
            <WorkoutTemplateContainer phaseConfiguration={phaseConfiguration} workoutTemplate={workoutTemplate} />
          </Col>
        </Row>
      </Form>
    );
  },
);
