/**
 * Created by neo on 18.06.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Pipeline } from '../../../Model/Coach/Pipeline';
import { Table } from 'reactstrap';
import { PipelineTableRow } from './PipelineTableRow';

export type PipelineTableProps = {
  pipelines: Pipeline[];
  onCopy?: (pipeline: Pipeline) => any;
  onRemove?: (pipeline: Pipeline) => any;
};

export const PipelineTable: React.FC<PipelineTableProps> = observer(
  ({ pipelines, onRemove, onCopy }: PipelineTableProps) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Tags</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {pipelines.map((pipeline) => (
            <PipelineTableRow key={pipeline.id} pipeline={pipeline} onRemove={onRemove} onCopy={onCopy} />
          ))}
        </tbody>
      </Table>
    );
  },
);
