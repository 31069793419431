/**
 * Created by neo on 12.04.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Ingredient } from '../../../../Model/Diet/Ingredient/Ingredient';
import styled from '@emotion/styled';
import { IngredientCard } from './IngredientCard';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Child = styled.div`
  flex: 1 0 31%;
  margin: 5px;
`;

export type IngredientTableProps = {
  ingredients: Ingredient[];
};

export const IngredientTable: React.FC<IngredientTableProps> = observer(({ ingredients }) => {
  return (
    <Container>
      {ingredients.map((ingredient) => (
        <Child key={ingredient.id}>
          <IngredientCard ingredient={ingredient} />
        </Child>
      ))}
    </Container>
  );
});
