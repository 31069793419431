/**
 * @fow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Container, Col, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { BodyPartBasicInfoTab } from './BodyPartBasicInfoTab';
import { ViewMedia } from '../../../../Components/ViewMedia/ViewMedia';
import { BodyPartRegionAdmin } from '../../../../Model/BodyPart/BodyPartRegionAdmin';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { runInAction } from 'mobx';
import { BodyPartExercisesTab } from './BodyPartExercisesTab';
import { Button, Space } from 'antd';

export const BodyPartViewScreen: React.FC<unknown> = observer(() => {
  const history = useNavigate();
  const { bodyPartId } = useParams<any>();
  const store = useLocalStore(() => ({
    bodyPart: new BodyPartRegionAdmin(),
    activeTab: 'basic',
    processing: false,
    fetch(bodyPartId?: string) {
      if (bodyPartId && 'new' !== bodyPartId) {
        store.get(bodyPartId);
      } else {
        store.bodyPart = new BodyPartRegionAdmin();
      }
    },
    get(id: string) {
      store.processing = true;
      BodyPartRegionAdmin.get(id)
        .then((result) => runInAction(() => (store.bodyPart = result ?? new BodyPartRegionAdmin())))
        .finally(() => runInAction(() => (store.processing = false)));
    },
  }));

  useEffect(() => {
    store.fetch(bodyPartId);
  }, [bodyPartId]);

  const toggle = React.useCallback(
    (tab) => {
      store.activeTab = tab;
    },
    [store],
  );

  const handleSave = React.useCallback(async () => {
    store.processing = true;
    try {
      await store.bodyPart.save();
      history(`/metadata/bodypart/view/${store.bodyPart.id}`, { replace: true });
    } finally {
      store.processing = false;
    }
  }, [store]);

  const createNew = React.useCallback(async () => {
    history(`/metadata/bodypart/view/new`, { replace: true });
  }, [history]);

  const isNew = React.useMemo(() => !bodyPartId || bodyPartId === 'new', [bodyPartId]);

  return (
    <Container>
      <Row>
        <Col>
          <h1>{!isNew ? `${store.bodyPart.defaultName}` : 'New Body Part'}</h1>
        </Col>
        <Col xs="auto">
          <Space>
            <Button type="dashed" onClick={createNew} disabled={store.processing}>
              {'Create Another'}
            </Button>
            <Button type="primary" onClick={handleSave} disabled={store.processing}>
              {'Save'}
            </Button>
          </Space>
        </Col>
      </Row>
      <SingleColRow>
        <Nav tabs>
          <NavItem>
            <NavLink className={classnames({ active: store.activeTab === 'basic' })} onClick={() => toggle('basic')}>
              {'Basic Info'}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: store.activeTab === 'media' })} onClick={() => toggle('media')}>
              {'Medias'}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: store.activeTab === 'exercises' })}
              onClick={() => toggle('exercises')}
            >
              {'Exercises'}
            </NavLink>
          </NavItem>
          {/*{!isNew ? (*/}
          {/*  <NavItem>*/}
          {/*    <NavLink*/}
          {/*      className={classnames({ active: store.activeTab === 'children' })}*/}
          {/*      onClick={() => toggle('children')}*/}
          {/*    >*/}
          {/*      {'Children'}*/}
          {/*    </NavLink>*/}
          {/*  </NavItem>*/}
          {/*) : null}*/}
        </Nav>
      </SingleColRow>
      <SingleColRow style={{ paddingTop: 8 }}>
        <TabContent activeTab={store.activeTab}>
          <TabPane tabId="basic">
            <BodyPartBasicInfoTab bodyPart={store.bodyPart} />
          </TabPane>
          <TabPane tabId="media">
            <ViewMedia medias={store.bodyPart.medias} />
          </TabPane>
          <TabPane tabId="exercises">
            <BodyPartExercisesTab bodyPart={store.bodyPart} />
          </TabPane>
          {/*{!isNew ? (*/}
          {/*  <TabPane tabId="children">*/}
          {/*    <BodyPartChildrenTab bodyPart={store.bodyPart} />*/}
          {/*  </TabPane>*/}
          {/*) : null}*/}
        </TabContent>
      </SingleColRow>
    </Container>
  );
});
