/**
 * Created by neo on 12.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { useLocation } from 'react-router-dom';
import { Tag } from 'antd';
import { Col } from 'reactstrap';

const Container = styled(Col)`
  display: flex;
  border-radius: 16px;
  flex: 1 0 49%;
  height: 160px;
  background-position: center center;
  background-size: cover;
  flex-direction: column;
`;

const Overlay = styled.div<{ pointer?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 16px;
  flex-direction: column;
  border-radius: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  &:hover {
    ${({ pointer }) => (pointer ? 'background: rgba(0, 0, 0, 0.75);' : '')}
    ${({ pointer }) => (pointer ? 'cursor: pointer;' : '')}
  }
`;

const Title = styled.div`
  font-size: 20px;
  color: white;
  text-align: center;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const TagContainer = styled.div`
  display: flex;
  color: white;
  font-size: 12px;
  align-items: flex-end;
`;

const SubscribedCount = styled.div`
  color: white;
  text-align: center;
`;

export type ExploreEntryCardProps = {
  entry: ExploreEntry;
  onSelect?: (entry: ExploreEntry) => void;
};

export const ExploreEntryCard: React.FC<ExploreEntryCardProps> = observer(({ entry, onSelect }) => {
  const location = useLocation();

  const gymId = new URLSearchParams(location.search).get('gymId') || undefined;

  const handleSelect = React.useCallback(() => {
    if (gymId === entry.gymId) {
      onSelect && onSelect(entry);
    }
  }, [entry, gymId, onSelect]);

  return (
    <Container style={{ backgroundImage: `url('${entry.image?.medium}')` }} onClick={handleSelect} xs={12} lg={6}>
      <Overlay pointer={gymId === entry.gymId}>
        <Tag color="blue">{entry.type}</Tag>
        <Title>{entry.defaultName}</Title>
        <TagContainer>{entry.tagString}</TagContainer>
        <SubscribedCount>
          Subscribed:
          <br />
          {entry.subscriberCount}
        </SubscribedCount>
      </Overlay>
    </Container>
  );
});
