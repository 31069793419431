/**
 * Created by neo on 29.09.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Media } from '../../../Model/Media/Media';
import { Image } from 'antd';
import { MediaListItemContentVideo } from './MediaListItemContentVideo';
import ReactAudioPlayer from 'react-audio-player';

export type MediaListItemContentProps = {
  media: Media;
  onClick?: (media: Media) => void;
};

export const MediaListItemContent: React.FC<MediaListItemContentProps> = observer(({ media, onClick }) => {
  if (media.mediaType.startsWith('image')) {
    return <Image src={media.smallest} />;
  } else if (media.mediaType.startsWith('video')) {
    return <MediaListItemContentVideo media={media} onClick={onClick} />;
  } else if (media.mediaType.startsWith('audio')) {
    return <ReactAudioPlayer src={media.url} controls={true} />;
  }
  return null;
});
