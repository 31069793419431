/**
 * Created by neo on 29.04.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { TableRowMedia } from '../../../Components/TableRowMedia';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { BodyPartJoint } from '../../../Model/BodyPart/BodyPartJoint';

export type BodyPartJointTableRowProps = {
  joint: BodyPartJoint;
  onRemove?: (joint: BodyPartJoint) => any;
};

export const BodyPartJointTableRow: React.FC<BodyPartJointTableRowProps> = observer(
  ({ joint, onRemove }: BodyPartJointTableRowProps) => {
    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(joint);
    }, [joint, onRemove]);

    return (
      <tr>
        <td>
          <TableRowMedia medias={joint.medias} />
        </td>
        <td>{joint.identifier}</td>
        <td>
          <Link to={`/metadata/bodypart/joint/view/${joint.id}`}>{joint.defaultName}</Link>
        </td>
        <td>{joint.exerciseCount}</td>
        <td>
          <Button type="link" danger onClick={handleRemove}>
            {'Remove'}
          </Button>
        </td>
      </tr>
    );
  },
);
