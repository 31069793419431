/**
 * Created by neo on 04.12.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { RouteChallengeTeam } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeTeam';
import { AdvancedMarker } from '@vis.gl/react-google-maps';

export type RouteChallengeTeamPositionProps = {
  challenge: RouteChallenge;
  team: RouteChallengeTeam;
};

export const RouteChallengeTeamPosition: React.FC<RouteChallengeTeamPositionProps> = observer(({ challenge, team }) => {
  return (
    <AdvancedMarker
      draggable={false}
      position={{
        lng: team.currentPosition.yorLng,
        lat: team.currentPosition.xorLat,
      }}
    >
      {/*<Pin background={'green'} glyphColor={'black'} borderColor={'green'} />*/}
      <img
        src={team.image?.smallest ?? ''}
        style={{
          width: 60,
          height: 60,
          borderWidth: 1,
          borderColor: 'green',
          borderRadius: 30,
          backgroundImage: `url(${team.image?.smallest}')`,
        }}
      ></img>
      <div style={{ backgroundColor: 'white', padding: 4, textAlign: 'center' }}>{team.name}</div>
    </AdvancedMarker>
  );
});
