import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space, Tag } from 'antd';
import { WorkoutTemplate } from '../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';
import dayjs from 'dayjs';
import { ViewMediaItem } from '../../../../Components/ViewMedia/ViewMediaItem';

export type CoachWorkoutTemplateTableRowProps = {
  template: WorkoutTemplate;
  onRemove?: (template: WorkoutTemplate) => any;
  onCopy?: (template: WorkoutTemplate) => any;
};

export const CoachWorkoutTemplateTableRow: React.FC<CoachWorkoutTemplateTableRowProps> = observer(
  ({ template, onRemove, onCopy }: CoachWorkoutTemplateTableRowProps) => {
    const handleRemove = () => {
      onRemove && onRemove(template);
    };

    const handleCopy = () => {
      onCopy && onCopy(template);
    };

    const getColor = (category: string) => {
      switch (category) {
        case 'level':
          return 'danger';
        case 'focus':
          return 'success';
        case 'goal':
          return 'warning';
        case 'duration':
          return 'info';
        case 'equipment':
          return 'secondary';
        default:
          return 'primary';
      }
    };

    return (
      <tr>
        <td>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {template.medias
              .filter((m) => m.mediaType.startsWith('image'))
              .map((media) => (
                <ViewMediaItem key={media.id} media={media} size={64} />
              ))}
          </div>
        </td>
        <td>
          <Link to={`/coach/workout-template/${template.id}`}>{template.templateName}</Link>
        </td>
        {/*<td>*/}
        {/*  {Object.entries(template.tagMap)*/}
        {/*    .sort((a, b) => a[0].localeCompare(b[0]))*/}
        {/*    // .filter(*/}
        {/*    //   t =>*/}
        {/*    //     !t[0].startsWith('goal') &&*/}
        {/*    //     !t[0].startsWith('duration') &&*/}
        {/*    //     !t[0].startsWith('equipment') &&*/}
        {/*    //     !t[0].startsWith('level')*/}
        {/*    // )*/}
        {/*    .map((entry: any[], index: number) => (*/}
        {/*      <React.Fragment key={entry[0]}>*/}
        {/*        {entry[1].map((tag) => (*/}
        {/*          <Badge key={tag} color={getColor(entry[0])} pill style={{ marginRight: 4 }}>*/}
        {/*            {tag}*/}
        {/*          </Badge>*/}
        {/*        ))}*/}
        {/*      </React.Fragment>*/}
        {/*    ))}*/}
        {/*</td>*/}
        <td>
          <Link to={`/coach/workout-template/${template.id}`}>
            {template.name.map((name) => (
              <React.Fragment key={name.lang}>
                {`${name.value} (${name.lang})`}
                <br />
              </React.Fragment>
            ))}
          </Link>
        </td>
        <td>{template.type}</td>
        <td>
          {template.minDurationInMinutes ? dayjs.utc(template.minDurationInMinutes * 60000).format('HH:mm') : '-'}
        </td>
        <td>
          {template.maxDurationInMinutes ? dayjs.utc(template.maxDurationInMinutes * 60000).format('HH:mm') : '-'}
        </td>
        <td>
          <Tag color={template.intensity === 'low' ? 'success' : template.intensity === 'medium' ? 'warning' : 'error'}>
            {template.intensity}
          </Tag>
        </td>
        <td>{dayjs.utc(template.createdAt).format('DD.MM.YYYY HH:mm')}</td>
        <td>
          <Space>
            {onRemove ? (
              <Popconfirm
                title={`Remove ${template.defaultName || template.templateName}?`}
                okText="Delete"
                onConfirm={handleRemove}
              >
                <Button danger type="link">
                  Remove
                </Button>
              </Popconfirm>
            ) : null}
            {onCopy ? (
              <Button type="dashed" onClick={handleCopy}>
                Copy
              </Button>
            ) : null}
          </Space>
        </td>
      </tr>
    );
  },
);
