/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Button, Space, Popconfirm, Select } from 'antd';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { MesoCycleTypeSelect } from './MesoCycleTypeSelect';
import { useState } from 'react';
import { MesoCycleEditModal } from './MesoCycleEditModal';
import { MesoCycleTemplate } from '../../../../../Model/Coach/Program/Template/MesoCycleTemplate';
import { WorkoutDayEditor } from './WorkoutDayEditor';
import { runInAction } from 'mobx';
import { WorkoutDayConfiguration } from '../../../../../Model/Coach/Program/Template/WorkoutDayConfiguration';
import { FitnessProgramTemplate } from '../../../../../Model/Coach/Program/Template/FitnessProgramTemplate';

export type MesoCycleEditorProps = {
  template: FitnessProgramTemplate;
  mesoCycle: MesoCycleTemplate;
  index: number;
  headerColor?: string;
  onRemove?: (mesoCycle: MesoCycleTemplate) => any;
};

const Container = styled.div`
  min-width: 400px;
  width: 49%;
  margin: 4px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
`;

const Row = styled.div`
  display: flex;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.color || '#8fd14f'};
  padding: 4px;
  padding-top: 16px;
`;

const numbers = new Array(16).fill(0);

export const MesoCycleEditor: React.FC<MesoCycleEditorProps> = observer(
  ({ template, mesoCycle, headerColor, onRemove, index }: MesoCycleEditorProps) => {
    const [modalOpen, setModalOpen] = useState(false);

    const handleChangeName = React.useCallback(
      ({ target: { value } }) => {
        mesoCycle.templateName = value;
      },
      [mesoCycle],
    );

    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(mesoCycle);
    }, [mesoCycle, onRemove]);

    const handleChangeCycles = React.useCallback(
      (value) => {
        mesoCycle.cycles = Number(value);
      },
      [mesoCycle],
    );

    const handleEdit = React.useCallback(() => {
      setModalOpen(true);
    }, []);

    const handleAddDay = React.useCallback(() => {
      runInAction(() => mesoCycle.workoutDays.push(new WorkoutDayConfiguration()));
    }, [mesoCycle]);

    const handleCloseModal = React.useCallback(() => {
      setModalOpen(false);
    }, []);

    const handleRemoveDay = React.useCallback(
      (workoutDay: WorkoutDayConfiguration, index: number) => {
        runInAction(() => mesoCycle.workoutDays.splice(index, 1));
      },
      [mesoCycle],
    );

    return (
      <Container>
        <Header color={headerColor}>
          <SingleColRow>
            <h6 style={{ textAlign: 'center' }}>{`Cycle ${index + 1}`}</h6>
          </SingleColRow>
          <Row>
            <div style={{ display: 'flex', flex: 1, paddingRight: 4 }}>
              <MesoCycleTypeSelect mesoCycle={mesoCycle} />
            </div>
            <div style={{ display: 'flex', flex: 1, paddingLeft: 4, paddingRight: 4 }}>
              <Select style={{ flex: 1 }} value={mesoCycle.cycles.toString()} onChange={handleChangeCycles}>
                {numbers.map((n, index) => (
                  <Select.Option key={index.toString()} value={`${index + 1}`}>{`${index + 1} Weeks`}</Select.Option>
                ))}
              </Select>
            </div>
            <div style={{ display: 'flex', flex: 0 }}>
              <Space>
                <Button type="link" onClick={handleEdit}>
                  Edit
                </Button>
                <Button type="link" onClick={handleAddDay}>
                  Add Day
                </Button>
                <Popconfirm title={`Remove Meso Cycle ${mesoCycle.templateName}?`} onConfirm={handleRemove}>
                  <Button type="link" danger>
                    Remove
                  </Button>
                </Popconfirm>
              </Space>
            </div>
          </Row>
        </Header>
        <Body>
          {mesoCycle.workoutDays.map((workoutDay, workoutDayIndex) => (
            <WorkoutDayEditor
              key={workoutDay.id}
              template={template}
              workoutDay={workoutDay}
              index={workoutDayIndex}
              onRemove={handleRemoveDay}
            />
          ))}
        </Body>
        {modalOpen ? <MesoCycleEditModal onClose={handleCloseModal} mesoCycle={mesoCycle} index={index} /> : null}
      </Container>
    );
  },
);
