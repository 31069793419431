/**
 * Created by neo on 12.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Form, InputNumber } from 'antd';
import AsyncSelect from 'react-select/async';
import { PipelineContextEditor } from './PipelineContextEditor';
import { Col, Row } from 'reactstrap';
import { runInAction } from 'mobx';
import {
  MindfulnessProgramTemplateDayUnitWorkoutTemplate
} from '../../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDayUnitWorkoutTemplate';
import { CoachWorkout } from '../../../../../../../Model/Coach/Program/Workout/CoachWorkout';
import { WorkoutTemplate } from '../../../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';

export type WorkoutTemplateEntryContentProps = {
  entry: MindfulnessProgramTemplateDayUnitWorkoutTemplate;
};

export const WorkoutTemplateEntryContent: React.FC<WorkoutTemplateEntryContentProps> = observer(({ entry }) => {
  const [generatedWorkout, setGeneratedWorkout] = useState<CoachWorkout | undefined>();
  const recalculate = React.useCallback(() => {
    entry.generate().then((res) => setGeneratedWorkout(res));
  }, [entry]);

  const handleChange = React.useCallback(
    (template: WorkoutTemplate | undefined) => {
      entry.workoutTemplateId = template?.id ?? '';
      recalculate();
    },
    [entry, recalculate],
  );

  const fetch = React.useCallback((name: string) => WorkoutTemplate.find({ name }), []);

  const getOptionLabel = React.useCallback(
    (option: WorkoutTemplate) => `${option.defaultName} (${option.templateName})`,
    [],
  );

  const getOptionValue = React.useCallback((option: WorkoutTemplate) => option, []);

  const handleChangeDuration = React.useCallback(
    (value: number | string | undefined | null) => {
      if (value) {
        runInAction(() => (entry.durationInMinutes = Number(value)));
      }
    },
    [entry],
  );

  const handleChangePipeline = React.useCallback(() => {
    recalculate();
  }, [recalculate]);

  useEffect(() => {
    recalculate();
  }, [recalculate]);

  useEffect(() => {
    if (
      (!entry.durationInMinutes ||
        Math.abs((generatedWorkout?.durationMinutes ?? 0) - entry.durationInMinutes) >= 10) &&
      generatedWorkout
    ) {
      runInAction(
        () =>
          (entry.durationInMinutes =
            generatedWorkout.durationMinutes < 10
              ? Math.max(generatedWorkout.durationMinutes, 5)
              : Math.round(generatedWorkout.durationMinutes / 5) * 5),
      );
    }
  }, [entry, generatedWorkout]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Form.Item label="Workout Template">
            <AsyncSelect
              defaultOptions
              isClearable={true}
              loadOptions={fetch}
              value={entry.workoutTemplate}
              hideSelectedOptions={false}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue as any}
              onChange={handleChange as any}
            />
          </Form.Item>
          {!!entry.workoutTemplate && (
            <a href={`/workout-template/${entry.workoutTemplateId}`}>{entry.workoutTemplate?.templateName}</a>
          )}
        </Col>
        <Col>
          <Form.Item
            label="Duration in Minutes"
            extra={generatedWorkout ? `${generatedWorkout?.durationMinutes}min` : 'Calculating duration...'}
          >
            <InputNumber value={entry.durationInMinutes} onChange={handleChangeDuration} />
          </Form.Item>
        </Col>
      </Row>
      <PipelineContextEditor entry={entry} onChange={handleChangePipeline} />
    </React.Fragment>
  );
});
