import { Stage } from './Stage';
import { DataSignal } from '../Signal/DataSignal';

export class ReverseStage extends Stage<any> {
  signals: DataSignal[] = [];

  process() {
    this.dataSignals.reverse().forEach((signal) => this.processNext(signal));
  }

  get type(): string {
    return 'reverse';
  }
}
