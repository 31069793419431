/**
 * Created by neo on 29.09.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Container, Row } from 'reactstrap';
import { Input } from 'antd';
import { Media } from '../../../Model/Media/Media';
import { observable, runInAction } from 'mobx';
import { useEffect, useState } from 'react';
import { MediaListItem } from './MediaListItem';
import { MediaEditModal } from './MediaEditModal/MediaEditModal';
import { SingleColRow } from '../../../Components/SingleColRow';
import MediaService from '../../../Services/MediaService';
import { Pager } from '../../../Components/Pager';
import { PageResult } from '../../../Model/PageResult';

export type MediaListScreenProps = {};

export const MediaListScreen: React.FC<MediaListScreenProps> = observer((_: MediaListScreenProps) => {
  const timeout = React.useRef<any>();
  const [selectedMedia, setSelectedMedia] = useState<Media | undefined>(undefined);
  const [uploading, setUploading] = useState(false);

  const [store] = useState(() =>
    observable({
      query: 'video',
      loading: false,
      hasMore: false,
      page: 0,
      result: new PageResult<Media>(),
    }),
  );

  const load = React.useCallback(() => {
    PageResult.execute(
      Media.find({ query: store.query, size: 50, sort: 'createdAt,DESC', page: store.page }),
      Media.count({ query: store.query }),
      store.page,
      50,
    )
      .then((result) => runInAction(() => (store.result = result)))
      .finally(() => runInAction(() => (store.loading = false)));
  }, [store]);

  const loadClear = React.useCallback(() => {
    runInAction(() => (store.page = 0));
    load();
  }, [store, load]);

  const handlePage = React.useCallback(
    (page: number) => {
      runInAction(() => (store.page = page));
      load();
    },
    [store, load],
  );

  useEffect(() => {
    load();
  }, [load]);

  const handleQueryChange = React.useCallback(
    ({ target: { value } }) => {
      store.query = value;
      timeout.current && clearTimeout(timeout.current);
      timeout.current = setTimeout(() => loadClear(), 300);
    },
    [store, loadClear],
  );

  const handleClick = React.useCallback((media: Media) => {
    setSelectedMedia(media);
  }, []);

  const handleOk = React.useCallback(() => {
    setSelectedMedia(undefined);
  }, []);

  const handleCancel = React.useCallback(() => {
    setSelectedMedia(undefined);
  }, []);

  const handleUpload = React.useCallback(
    ({ target: { files } }) => {
      console.log('file', files);
      setUploading(true);
      Promise.all(Array.from(files).map((file: any) => MediaService.uploadMedia(file)))
        .then((medias) => runInAction(() => medias.forEach((media) => store.result.content.unshift(media))))
        .finally(() => setUploading(false));
    },
    [store],
  );

  return (
    <Container>
      <SingleColRow>
        <h1>Medias</h1>
      </SingleColRow>
      <Row>
        <Col>
          <Input value={store.query} onChange={handleQueryChange} placeholder="Search for mediaType, url, name etc." />
        </Col>
        <Col>
          <Input type="file" onChange={handleUpload} disabled={uploading} />
        </Col>
      </Row>
      <SingleColRow>
        <Pager page={store.result} onPage={handlePage} />
      </SingleColRow>
      <Row>
        {store.result.content.map((media) => (
          <MediaListItem key={media.id} media={media} onClick={handleClick} />
        ))}
      </Row>
      <SingleColRow>
        <Pager page={store.result} onPage={handlePage} />
      </SingleColRow>
      {selectedMedia ? <MediaEditModal media={selectedMedia} onOk={handleOk} onCancel={handleCancel} /> : null}
    </Container>
  );
});
