import React from 'react';
import { Modal, Form, Input, Select, Button, message } from 'antd';
import { Employee } from '../../../../../Model/Gym/Employee';

export type EmployeeEditModalProps = {
  employee: Employee;
  isOpen: boolean;
  onClose: () => void;
};

export const EmployeeEditModal: React.FC<EmployeeEditModalProps> = ({ employee, isOpen, onClose }) => {
  const [form] = Form.useForm();

  // Initialize form fields with current employee data.
  React.useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        email: employee.athlete.user.email,
        roles: employee.roles || [],
      });
    }
  }, [form, isOpen, employee]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      // Update employee roles.
      employee.roles = values.roles;
      await employee.save();
      message.success('Employee updated successfully');
      onClose();
    } catch (error) {
      // Handle validation or save error.
    }
  };

  return (
    <Modal
      visible={isOpen}
      title={`Edit ${employee.athlete.fullName}`}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Email" name="email">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Roles" name="roles" rules={[{ required: true, message: 'Please select at least one role' }]}>
          <Select mode="multiple" placeholder="Select roles">
            {['ROLE_GYM_PUBLISHER', 'ROLE_GYM_EMPLOYEE', 'ROLE_GYM_ADMIN'].map((role) => (
              <Select.Option key={role} value={role}>
                {role}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
