/**
 * Created by neo on 12.04.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Ingredient } from '../../../../Model/Diet/Ingredient/Ingredient';
import { Col, Row } from 'reactstrap';
import { Form } from 'antd';
import { IngredientEditModalNutrientItem } from './IngredientEditModalNutrientItem';

export type IngredientEditModalNutrientsProps = {
  ingredient: Ingredient;
};

export type NutrientData = {
  key: string;
  name: string;
  unit: string;
};

export const nutrients: NutrientData[] = [
  {
    key: 'calories',
    name: 'Calories',
    unit: 'kcal',
  },
  {
    key: 'carbohydrates',
    name: 'Carbohydrates',
    unit: 'g',
  },
  {
    key: 'fat',
    name: 'Fat',
    unit: 'g',
  },
  {
    key: 'protein',
    name: 'Protein',
    unit: 'g',
  },
  {
    key: 'fiber',
    name: 'Fiber',
    unit: 'g',
  },
  {
    key: 'sugar',
    name: 'Sugar',
    unit: 'g',
  },
  {
    key: 'saturated_fat',
    name: 'Saturated fat',
    unit: 'g',
  },
  {
    key: 'cholesterol',
    name: 'Cholesterol',
    unit: 'mg',
  },
  {
    key: 'sodium',
    name: 'Sodium',
    unit: 'mg',
  },
  {
    key: 'potassium',
    name: 'Potassium',
    unit: 'mg',
  },
  {
    key: 'calcium',
    name: 'Calcium',
    unit: 'mg',
  },
  {
    key: 'iron',
    name: 'Iron',
    unit: 'mg',
  },
  {
    key: 'magnesium',
    name: 'Magnesium',
    unit: 'mg',
  },
  {
    key: 'phosphorus',
    name: 'Phosphorus',
    unit: 'mg',
  },
  {
    key: 'copper',
    name: 'Copper',
    unit: 'mg',
  },
  {
    key: 'manganese',
    name: 'Manganese',
    unit: 'mg',
  },
  {
    key: 'selenium',
    name: 'Selenium',
    unit: 'µg',
  },
  {
    key: 'zinc',
    name: 'Zinc',
    unit: 'mg',
  },
  {
    key: 'vitamin_a',
    name: 'Vitamin A',
    unit: 'IU',
  },
  {
    key: 'vitamin_b1',
    name: 'Vitamin B1',
    unit: 'mg',
  },
  {
    key: 'vitamin_b2',
    name: 'Vitamin B2',
    unit: 'mg',
  },
  {
    key: 'vitamin_b3',
    name: 'Vitamin B3',
    unit: 'mg',
  },
  {
    key: 'vitamin_b5',
    name: 'Vitamin B5',
    unit: 'mg',
  },
  {
    key: 'vitamin_b6',
    name: 'Vitamin B6',
    unit: 'mg',
  },
  {
    key: 'vitamin_b12',
    name: 'Vitamin B12',
    unit: 'µg',
  },
  {
    key: 'vitamin_c',
    name: 'Vitamin C',
    unit: 'mg',
  },
  {
    key: 'vitamin_d',
    name: 'Vitamin D',
    unit: 'IU',
  },
  {
    key: 'vitamin_e',
    name: 'Vitamin E',
    unit: 'mg',
  },
  {
    key: 'vitamin_k',
    name: 'Vitamin K',
    unit: 'µg',
  },
  {
    key: 'folate',
    name: 'Folate',
    unit: 'µg',
  },
  {
    key: 'net_carbohydrates',
    name: 'Net carbohydrates',
    unit: 'g',
  },
];

export const IngredientEditModalNutrients: React.FC<IngredientEditModalNutrientsProps> = observer(({ ingredient }) => {
  return (
    <Form layout="vertical">
      <Col xs={'auto'}>
        <Row>
          <Col xs={12}>
            <h6>{'Per 100g'}</h6>
          </Col>
        </Row>
        {nutrients.map((nutrient) => (
          <IngredientEditModalNutrientItem
            key={nutrient.key}
            nutrient={nutrient}
            nutrientMap={ingredient.nutrition.nutrients}
          />
        ))}
      </Col>
    </Form>
  );
});
