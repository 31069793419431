/**
 * Created by neo on 01.05.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExerciseVariationAdmin } from '../../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { Form, Select } from 'antd';
import { ExerciseForce } from '../../../../../Model/Exercise/AbstractExercise';

export type ExerciseForceSelectProps = {
  exercise: ExerciseVariationAdmin;
  disabled?: boolean;
};

export const ExerciseForceSelect: React.FC<ExerciseForceSelectProps> = observer(
  ({ exercise, disabled }: ExerciseForceSelectProps) => {
    const handleChange = React.useCallback((e) => (exercise.force = e ? (e as ExerciseForce) : undefined), [exercise]);

    return (
      <Form.Item label="Force">
        <Select value={exercise.force} onChange={handleChange} disabled={disabled}>
          <Select.Option value="">{''}</Select.Option>
          <Select.Option value="PUSH">{'Push'}</Select.Option>
          <Select.Option value="PULL">{'Pull'}</Select.Option>
          <Select.Option value="PUSH_PULL">{'Pull & Push'}</Select.Option>
        </Select>
      </Form.Item>
    );
  },
);
