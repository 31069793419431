/**
 * Created by neo on 02.05.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Form, Select } from 'antd';
import { ExerciseVariationAdmin } from '../../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { SpineForceType } from '../../../../../Model/Exercise/AbstractExercise';

export type ExerciseSpineForceTypeSelectProps = {
  exercise: ExerciseVariationAdmin;
  disabled?: boolean;
};

export const ExerciseSpineForceTypeSelect: React.FC<ExerciseSpineForceTypeSelectProps> = observer(
  ({ exercise, disabled }: ExerciseSpineForceTypeSelectProps) => {
    const handleChange = React.useCallback((e) => (exercise.spineForceType = e ? (e as SpineForceType) : undefined), [
      exercise,
    ]);

    return (
      <Form.Item label="Spine Force">
        <Select value={exercise.spineForceType} onChange={handleChange} disabled={disabled}>
          <Select.Option value="">{''}</Select.Option>
          <Select.Option value="stretch">{'Stretch / Zug'}</Select.Option>
          <Select.Option value="contract">{'Contract / Druck'}</Select.Option>
        </Select>
      </Form.Item>
    );
  },
);
