/**
 * Created by neo on 02.10.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExerciseBlockExerciseVariationLink } from '../../../../Planning/Workout/View/PhasesTable/ExerciseBlockExerciseVariationLink';
import { CoachExerciseBlock } from '../../../../../Model/Coach/Program/Workout/CoachExerciseBlock';
import { ExerciseBlockMedia } from '../../../../Planning/Workout/View/PhasesTable/ExerciseBlockMedia';
import { CoachExerciseBlockSuggestionsModal } from './CoachExerciseBlockSuggestionsModal/CoachExerciseBlockSuggestionsModal';
import { useState } from 'react';
import styled from '@emotion/styled';
import { CoachExerciseSet } from './CoachExerciseSet';
import { WorkoutResponseExerciseBlock } from '../../../../../Model/Coach/Script/WorkoutResponseExerciseBlock';
import { Exercise } from '../../../../../Model/Exercise/Exercise';

export type CoachExerciseBlockCardProps = {
  exerciseBlock: CoachExerciseBlock | WorkoutResponseExerciseBlock;
};

const Container = styled.div`
  flex: 0;
  padding-right: 4px;
  &:last-child {
    padding-right: 0;
  }
`;

const SetsContainer = styled.div`
  width: 100%;
  padding-top: 8px;
  padding: 8px 4px;
`;

export const CoachExerciseBlockCard: React.FC<CoachExerciseBlockCardProps> = observer((props) => {
  const [suggestions, setSuggestions] = useState<CoachExerciseBlock[] | undefined>();

  const { exerciseBlock } = props;

  const handleReplace = React.useCallback(() => {
    if (exerciseBlock instanceof CoachExerciseBlock) {
      exerciseBlock.suggestNew().then((results) => {
        setSuggestions(results);
      });
    }
  }, [exerciseBlock]);

  const handleSelect = React.useCallback(
    (newBlock: CoachExerciseBlock) => {
      if (exerciseBlock instanceof CoachExerciseBlock) {
        exerciseBlock.replace(newBlock).then(() => setSuggestions(undefined));
      }
    },
    [exerciseBlock],
  );

  const handleSuggestionModalClose = React.useCallback(() => {
    setSuggestions(undefined);
  }, []);

  const exercise =
    exerciseBlock instanceof WorkoutResponseExerciseBlock
      ? exerciseBlock.exercise?.exercise ?? new Exercise()
      : exerciseBlock.exercise;

  return (
    <React.Fragment>
      <Container>
        <div style={{ textAlign: 'center', height: 36 }}>
          <ExerciseBlockExerciseVariationLink exercise={exercise} />
        </div>
        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <ExerciseBlockMedia exercise={exercise} preferImage={true} />
          <SetsContainer>
            {exerciseBlock instanceof CoachExerciseBlock
              ? exerciseBlock.sets.map((set, index) => (
                  <CoachExerciseSet key={set.index.toString()} exercise={exercise} set={set} index={index} />
                ))
              : exerciseBlock.sets.map((set, index) => (
                  <CoachExerciseSet key={set.index.toString()} exercise={exercise} set={set} index={index} />
                ))}
          </SetsContainer>
        </div>
      </Container>
      {suggestions ? (
        <CoachExerciseBlockSuggestionsModal
          suggestions={suggestions}
          onSelect={handleSelect}
          onCancel={handleSuggestionModalClose}
        />
      ) : null}
    </React.Fragment>
  );
});
