/**
 * @flow
 * Created by neo on 13.03.17.
 */
import * as React from 'react';
import { IObservableArray, isObservableArray, runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import { FormGroup, Label } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { BodyPartRegion } from '../../Model/BodyPart/BodyPartRegion';
import { SingleColRow } from '../SingleColRow';
import { useEffect } from 'react';

type Props = {
  bodyPartIds: IObservableArray<string> | Array<string>;
  header?: string;
  disabled?: boolean;
};

export const ExerciseFilterBodyPartCard: React.FC<Props> = observer(({ bodyPartIds, header, disabled }: Props) => {
  const store = useLocalStore(() => ({
    suggestions: new Array<BodyPartRegion>(),
    existing: new Array<BodyPartRegion>(),
    loadExisting(bodyPartIds: string[]) {
      BodyPartRegion.getAll(bodyPartIds).then((result) => runInAction(() => (store.existing = result)));
    },
  }));

  useEffect(() => {
    store.loadExisting(bodyPartIds);
  }, [bodyPartIds]);

  const fetch = React.useCallback(
    (name?: string) =>
      BodyPartRegion.find({
        name,
        page: 0,
        includeHidden: true,
        sort: 'defaultName,ASC',
      }).then((res) => res.filter((b) => !bodyPartIds.find((b1) => b1 === b.id))),
    [bodyPartIds],
  );

  const handleChange = React.useCallback(
    (bodyParts?: BodyPartRegion[]) => {
      if (isObservableArray(bodyPartIds)) {
        bodyPartIds.replace((bodyParts || []).map((b) => b.id));
      } else {
        bodyPartIds.splice(0, bodyPartIds.length);
        (bodyParts || []).forEach((b) => bodyPartIds.push(b.id));
      }
      store.existing = bodyParts || [];
    },
    [bodyPartIds, store],
  );

  const getOptionLabel = React.useCallback((option: BodyPartRegion) => `${option.name} (${option.identifier})`, []);

  const getOptionValue = React.useCallback((option: BodyPartRegion) => option, []);

  return (
    <SingleColRow>
      <FormGroup>
        {header ? <Label>{header ? header : 'Body Parts'}</Label> : null}
        <AsyncSelect
          value={store.existing.slice()}
          cacheOptions
          defaultOptions
          isClearable={true}
          loadOptions={fetch}
          isMulti={true}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue as any}
          onChange={handleChange as any}
          isDisabled={disabled}
        />
      </FormGroup>
    </SingleColRow>
  );
});
