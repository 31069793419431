/**
 *
 * Created by neo on 25.02.17.
 */
import * as React from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import AsyncSelect from 'react-select/async';
import { EquipmentType } from '../Model/Equipment/EquipmentType';
import { Form } from 'antd';

export type EquipmentTypeInputProps = {
  container: string[];
  label?: React.ReactNode;
  extra?: React.ReactNode;
  allowIncludes?: boolean;
  onRemove?: (index: number) => any;
  disabled?: boolean;
  onChange?: (equipmentTypes: string[]) => any;
};

export const EquipmentTypeInput: React.FC<EquipmentTypeInputProps> = observer(
  ({ label, extra, disabled, container, onChange }) => {
    const fetch = React.useCallback(
      (query: string) =>
        EquipmentType.find({ query, sort: 'defaultName,ASC' }).then((results) =>
          results.map((value) => ({ value: value.id, label: value.defaultName })),
        ),
      [],
    );

    const resolveType = React.useCallback(
      async (type: string): Promise<string[]> =>
        EquipmentType.get(type).then((equipmentType) => {
          if (equipmentType) {
            const types = [equipmentType.id];
            return Promise.all(equipmentType.includes.map((r) => resolveType(r))).then((includes) =>
              types.concat(includes.flatMap((r) => r)),
            );
          }
          return [];
        }),
      [],
    );

    const addEquipmentType = React.useCallback(
      async (equipmentType: EquipmentType | string | any) => {
        if (equipmentType instanceof EquipmentType) {
          container.push(equipmentType.id);
          (await Promise.all(equipmentType.includes.map((r) => resolveType(r))))
            .flatMap((r) => r)
            .filter((r, pos, self) => self.indexOf(r) === pos)
            .filter((r) => !container.find((r1) => r1 === r))
            .forEach((e) => container.push(e));
        } else if (typeof equipmentType === 'string') {
          container.push(equipmentType);
        } else if (typeof equipmentType === 'object' && equipmentType.value) {
          container.push(equipmentType.value);
        }
      },
      [container, resolveType],
    );

    const handleChange = React.useCallback(
      async (tags?: Array<EquipmentType | string>) => {
        console.log('handleChange', tags);
        container.splice(0, container.length);
        if (tags && tags.length > 0) {
          await Promise.all(tags.map((e) => addEquipmentType(e)));
          console.log('container2', toJS(container));
        }
        onChange && onChange(container);
      },
      [onChange, container, addEquipmentType],
    );

    const optionLabel = React.useCallback(({ label }: { value: string; label: string }) => {
      // if (option.defaultName && option.id) {
      //   return `${option.defaultName} (${option.id})`;
      // } else if (option.defaultName || option.id) {
      //   return option.defaultName || option.id;
      // }
      // return option;
      return label;
    }, []);

    const optionValue = React.useCallback(({ value }: { value: string; label: string }) => value, []);

    console.log(
      'container render',
      container.slice(),
      container.slice().map((t) => ({ value: t, label: t })),
    );

    return (
      <Form.Item label={label} extra={extra}>
        <AsyncSelect
          value={container.slice().map((t) => ({ value: t, label: t }))}
          cacheOptions
          defaultOptions
          isClearable={true}
          loadOptions={fetch}
          isMulti={true}
          getOptionLabel={optionLabel}
          getOptionValue={optionValue as any}
          onChange={handleChange as any}
          isDisabled={disabled}
        />
      </Form.Item>
    );
  },
);
