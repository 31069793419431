/**
 * Created by neo on 24.08.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, message, Space } from 'antd';
import { Col, Container, Row } from 'reactstrap';
import { SingleColRow } from '../../../Components/SingleColRow';
import { FitnessProgramTemplate } from '../../../Model/Coach/Program/Template/FitnessProgramTemplate';
import { CoachProgramTemplateTable } from './CoachProgramTemplateTable';
import { PageResult } from '../../../Model/PageResult';
import { Pager } from '../../../Components/Pager';
import { toJS } from 'mobx';

export type CoachProgramTemplateListScreenProps = {};

export const CoachProgramTemplateListScreen: React.FC<CoachProgramTemplateListScreenProps> = observer((props) => {
  const history = useNavigate();
  const [templates, setTemplates] = useState<PageResult<FitnessProgramTemplate>>(() => new PageResult());
  const [query, setQuery] = useState('');
  const page = React.useRef(0);
  const debounce = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  useEffect(() => {
    PageResult.execute(FitnessProgramTemplate.find(), FitnessProgramTemplate.count()).then((result) =>
      setTemplates(result),
    );
  }, []);

  useEffect(() => {
    return () => {
      debounce.current && clearTimeout(debounce.current);
    };
  }, []);

  const handleCreate = React.useCallback(() => {
    history('/coach/program-template/create');
  }, [history]);

  const handleRemove = React.useCallback((template: FitnessProgramTemplate) => {
    template.delete().then(() => {
      setTemplates((p) => {
        p.content = p.content.filter((p0) => p0.id !== template.id);
        return new PageResult(toJS(p));
      });
      message.success('Super Macro deleted');
    });
  }, []);

  const handleCopy = React.useCallback((pipeline: FitnessProgramTemplate) => {
    pipeline
      .copy()
      .save()
      .then((result) =>
        setTemplates((p) => new PageResult(Object.assign(toJS(p), { content: [result].concat(p.content) }))),
      );
  }, []);

  const handlePage = React.useCallback(
    (newPage: number) => {
      PageResult.execute(
        FitnessProgramTemplate.find({ page: newPage, query }),
        FitnessProgramTemplate.count(),
        newPage,
        10,
      ).then((result) => setTemplates(result));
    },
    [query],
  );

  const handleChangeQuery = React.useCallback(({ target: { value } }) => {
    setQuery(value);
    debounce.current && clearTimeout(debounce.current);
    debounce.current = setTimeout(
      () =>
        PageResult.execute(
          FitnessProgramTemplate.find({ page: 0, query: value }),
          FitnessProgramTemplate.count(),
          0,
          10,
        ).then((result) => setTemplates(result)),
      300,
    );
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <h1>Program Templates</h1>
        </Col>
        <Col xs="auto">
          <Space>
            <Button type="primary" onClick={handleCreate}>
              Create Template
            </Button>
          </Space>
        </Col>
      </Row>
      <SingleColRow>
        <Input value={query} onChange={handleChangeQuery} />
      </SingleColRow>
      <SingleColRow>
        <Pager page={templates} onPage={handlePage} />
      </SingleColRow>
      <SingleColRow>
        <CoachProgramTemplateTable templates={templates.content} onRemove={handleRemove} onCopy={handleCopy} />
      </SingleColRow>
      <SingleColRow>
        <Pager page={templates} onPage={handlePage} />
      </SingleColRow>
    </Container>
  );
});
