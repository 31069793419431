/**
 * Created by neo on 02.12.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button } from 'antd';
import { RouteChallenge } from '../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { RouteChallengeTeam } from '../../../Model/Engagement/RouteChallenge/RouteChallengeTeam';
import { LocalizedValue } from '../../../Model/LocalizedValue';

export type RouteChallengeTableRowProps = {
  challenge: RouteChallenge;
  onRefresh?: () => void;
};

export const RouteChallengeTableRow: React.FC<RouteChallengeTableRowProps> = observer(({ challenge, onRefresh }) => {
  const [teamsCount, setTeamsCount] = useState(0);

  useEffect(() => {
    RouteChallengeTeam.count({ challengeId: challenge.id }).then((result) => setTeamsCount(result));
  }, [challenge]);

  const handleCopy = React.useCallback(() => {
    const copy = challenge.copy();
    copy.name = copy.name.map(({ lang, value }) => new LocalizedValue({ lang, value: `${value} (Copy)` }));
    copy.displayStartDateTime = dayjs().add(1, 'year').startOf('day').toDate();
    copy.displayEndDateTime = dayjs().add(1, 'year').add(1, 'month').endOf('day').toDate();
    copy.startDateTime = dayjs().add(1, 'year').startOf('day').startOf('day').toDate();
    copy.endDateTime = dayjs().add(1, 'year').add(1, 'month').endOf('day').toDate();
    copy.save().then(() => onRefresh?.());
  }, [challenge, onRefresh]);

  return (
    <tr>
      <td>
        <Link to={`${challenge.id}`}>{challenge.id}</Link>
      </td>
      <td>
        {challenge.gymId ? <Link to={`/infrastructure/gym/${challenge.gymId}`}>{challenge.gym?.name}</Link> : 'Global'}
      </td>
      <td>{challenge.defaultName}</td>
      <td>
        {challenge.started
          ? challenge.ended
            ? 'Ended'
            : 'Running'
          : dayjs(challenge.startDateTime).format('DD.MM.YYYY HH:mm')}
      </td>
      <td>{dayjs(challenge.endDateTime).format('DD.MM.YYYY HH:mm')}</td>
      {/*<td>*/}
      {/*  <Tag color={tagColor(challenge.status)}>{challenge.status}</Tag>*/}
      {/*</td>*/}
      <td>{challenge.totalCollectedPoints}</td>
      <td>{teamsCount}</td>
      <td>
        <Button size="small" onClick={handleCopy}>
          Copy
        </Button>
      </td>
    </tr>
  );
});
