/**
 * Created by neo on 20.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, Bar, YAxis, Tooltip, Legend } from 'recharts';
import { ChartColors } from '../ChartColors';

export type UserPropertiesDataEntry = { name: string; user_count: number }[];

export type UserPropertiesStatsAgeChartProps = {
  property: string;
  categories: UserPropertiesDataEntry;
  dataKeys: string[];
  stackId?: string;
};

export const UserPropertiesStatsAgeChart: React.FC<UserPropertiesStatsAgeChartProps> = observer(
  ({ property, categories, dataKeys, stackId }) => {
    return (
      <Form.Item label={property}>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart height={250} data={categories} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {dataKeys.map((dataKey, index) => (
              <Bar
                key={dataKey}
                type="monotone"
                dataKey={dataKey}
                fill={ChartColors[index % ChartColors.length]}
                stackId={stackId}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </Form.Item>
    );
  },
);
