/**
 * Created by neo on 23.03.21.
 */
import { RecurringPattern, RecurringPatternJson } from './RecurringPattern';
import { DurationBefore, NotificationInterval, NotificationIntervalJson } from './NotificationInterval';
import { computed, observable, onBecomeObserved, runInAction } from 'mobx';
import { v4 as UUID } from 'uuid';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { Pageable } from '../../Pageable';
import { Athlete } from '../../Athlete/Athlete';
import { ExploreEntry } from '../../Explore/ExploreEntry';
import { Recipe } from '../../Diet/Recipe/Recipe';
import { ExploreEntryBuilder } from '../../Explore/ExploreEntryBuilder';
import dayjs, { Dayjs } from 'dayjs';
import { EMPTY_ARRAY } from '../../../Utils/Constants';

export type EventEntrySearchParams = Pageable & {
  athleteId?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  objectType?: string;
  objectId?: string;
  parent?: boolean;
};

export type EventEntryJson = {
  id: string;
  athleteId: string;
  parentId?: string;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
  timezone: string;
  fullDay: boolean;
  objectType?: string;
  objectId?: string;
  recurringPattern?: RecurringPatternJson;
  deletable: boolean;
  hidden: boolean;
  completedAt?: string;
  linkedData: Record<string, any>;
  notificationIntervals: NotificationIntervalJson[];
  notificationEnabled: boolean;
  aiPlanned: boolean;
  groupId?: string;
};

export class EventEntry {
  @observable
  id = UUID();
  @observable
  athleteId: string = '';
  @observable
  parentId?: string;
  @observable
  startDate: string = '';
  @observable
  endDate: string = '';
  @observable
  startTime?: string;
  @observable
  endTime?: string;
  @observable
  timezone: string = 'Europe/Zurich';
  @observable
  fullDay: boolean = false;
  @observable
  objectType?: string;
  @observable
  objectId?: string;
  @observable
  recurringPattern?: RecurringPattern;
  @observable
  deletable: boolean = true;
  @observable
  hidden: boolean = false;
  @observable
  completedAt?: Date;
  @observable
  linkedData: Record<string, any> = {};
  @observable
  notificationEnabled: boolean = true;
  @observable
  notificationIntervals: NotificationInterval[] = [
    new NotificationInterval({
      durationBefore: DurationBefore.AT_EVENT,
    }),
  ];
  @observable
  aiPlanned: boolean = false;
  @observable
  groupId?: string;
  @observable
  athlete?: Athlete;
  @observable
  object?: ExploreEntry | Recipe;

  constructor(json?: Partial<EventEntryJson>) {
    if (json) {
      this.id = json.id ?? UUID();
      this.athleteId = json.athleteId ?? '';
      this.parentId = json.parentId;
      this.startDate = json.startDate ?? dayjs().format('YYYY-MM-DD');
      this.startTime = json.startTime;
      this.endDate = json.endDate ?? dayjs().add(1, 'year').format('YYYY-MM-DD');
      this.endTime = json.endTime;
      this.timezone = json.timezone ?? 'Europe/Zurich';
      this.fullDay = json.fullDay ?? false;
      this.objectType = json.objectType;
      this.objectId = json.objectId;
      this.recurringPattern = json.recurringPattern ? new RecurringPattern(json.recurringPattern) : undefined;
      this.deletable = json.deletable ?? true;
      this.hidden = json.hidden ?? false;
      this.completedAt = json.completedAt ? new Date(json.completedAt) : undefined;
      this.linkedData = json.linkedData ?? {};
      this.aiPlanned = json.aiPlanned ?? false;
      this.groupId = json.groupId;
      this.notificationEnabled = json.notificationEnabled ?? true;
      this.notificationIntervals =
        json.notificationIntervals && json.notificationIntervals.length > 0
          ? json.notificationIntervals.map((i) => new NotificationInterval(i))
          : [
              new NotificationInterval({
                durationBefore: DurationBefore.ONE_HOUR_BEFORE,
              }),
              new NotificationInterval({
                durationBefore: DurationBefore.AT_EVENT,
              }),
            ];
    }

    onBecomeObserved(this, 'athlete', this.fetchAthlete);
    onBecomeObserved(this, 'object', this.fetchObject);
  }

  fetchAthlete = () => {
    if (!this.athlete) {
      Athlete.get(this.athleteId).then((athlete) => runInAction(() => (this.athlete = athlete)));
    }
  };

  fetchObject = (): Promise<ExploreEntry | Recipe | undefined> => {
    if (!this.object && this.objectId) {
      if (this.objectType === 'recipe') {
        return Recipe.get(this.objectId).then((res) => {
          runInAction(() => (this.object = res));
          return res;
        });
      } else {
        return ExploreEntryBuilder.findOne(this.objectId).then((res) => {
          runInAction(() => (this.object = res));
          return res;
        });
      }
    }
    return Promise.resolve(undefined);
  };

  toJS(newId?: boolean): EventEntryJson {
    return {
      id: newId ? UUID() : this.id,
      athleteId: this.athleteId,
      parentId: this.parentId,
      startDate: this.startDate,
      startTime: this.startTime,
      endDate: this.endDate,
      endTime: this.endTime,
      timezone: this.timezone,
      fullDay: this.fullDay,
      objectType: this.objectType,
      objectId: this.objectId,
      recurringPattern: this.recurringPattern?.toJS(),
      deletable: this.deletable,
      hidden: this.hidden,
      completedAt: this.completedAt?.toISOString(),
      linkedData: this.linkedData,
      notificationEnabled: this.notificationEnabled,
      notificationIntervals: this.notificationIntervals.map((n) => n.toJS()),
      aiPlanned: this.aiPlanned,
      groupId: this.groupId,
    };
  }

  @computed
  get title(): string | undefined {
    if (this.notificationIntervals.length > 0) {
      return this.notificationIntervals[0].title;
    }
    return undefined;
  }

  @computed
  get message(): string | undefined {
    if (this.notificationIntervals.length > 0) {
      return this.notificationIntervals[0].message;
    }
    return undefined;
  }

  @computed
  get name(): string | undefined {
    if (this.notificationIntervals.length > 0) {
      return this.notificationIntervals.find((n) => n.customData.get('name'))?.customData.get('name');
    }
    return undefined;
  }

  @computed
  get image(): string | undefined {
    if (this.notificationIntervals.length > 0) {
      return this.notificationIntervals.find((n) => n.customData.get('imageUrl'))?.customData.get('imageUrl');
    }
    return undefined;
  }

  @computed
  get link(): string | undefined {
    if (this.notificationIntervals.length > 0) {
      return this.notificationIntervals.find((n) => n.customData.get('link'))?.customData.get('link');
    }
    return undefined;
  }

  @computed
  get startTimeFixed(): string | undefined {
    if (this.startTime) {
      if (this.startTime?.length === 5) {
        return `${this.startTime}:00`;
      } else if ((this.startTime?.length ?? 0) >= 8) {
        return this.startTime.substr(0, 8);
      }
    }
    return undefined;
  }

  @computed
  get startDateTime(): Dayjs | undefined {
    if (this.startDate) {
      const time = this.startTimeFixed ?? '00:00';
      return dayjs.tz(`${this.startDate} ${time}`, this.timezone);
    }
    return undefined;
  }

  @computed
  get localStartDateTime(): Dayjs | undefined {
    if (this.startDateTime) {
      return dayjs(this.startDateTime.toDate());
    }
    return undefined;
  }

  @computed
  get possibleDates(): Dayjs[] {
    const { localStartDateTime: startDateTime } = this;
    if (startDateTime) {
      return this.recurringPattern?.calculatePossibleDates(startDateTime) ?? EMPTY_ARRAY;
    }
    return EMPTY_ARRAY;
  }

  save(): Promise<EventEntry> {
    return HttpBackend.post('/coach/schedule/event/admin', this.toJS()).then(() => this);
  }

  delete(): Promise<EventEntry> {
    return HttpBackend.delete(`/coach/schedule/event/admin/${this.id}`).then(() => this);
  }

  static find(params: EventEntrySearchParams): Promise<EventEntry[]> {
    return HttpBackend.get('/coach/schedule/event/admin', params).then((res) => res.map((r) => new EventEntry(r)));
  }

  static count(params: EventEntrySearchParams): Promise<number> {
    return HttpBackend.get('/coach/schedule/event/admin/count', params);
  }

  static findExisting(params: EventEntrySearchParams): Promise<EventEntry[]> {
    return HttpBackend.get('/coach/schedule/event/admin', Object.assign(params, { size: 1 })).then((res) =>
      res.map((r) => new EventEntry(r)),
    );
  }

  static findOne(id: string): Promise<EventEntry> {
    return HttpBackend.get(`/coach/schedule/event/admin/${id}`).then((res) => new EventEntry(res));
  }
}
