/**
 * Created by katarinababic on 17.8.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { makeStyles } from 'tss-react/mui';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';
import { TranslationArrayInputMaterial } from '../../../../Translation/TranslationArrayInputMaterial';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography/Typography';
import TextField from '@mui/material/TextField/TextField';

export type ComponentStylesInnerLinkTextProps = {
  component: NativeFeedComponent;
};

export const ComponentStylesInnerLinkText: React.FC<ComponentStylesInnerLinkTextProps> = observer(({ component }) => {
  const { classes } = useStyles();

  const handleChangeUrl = React.useCallback(
    (e) => {
      runInAction(() => {
        component.children[0].props.extraProps.set('link', e.target.value);
      });
    },
    [component],
  );

  return (
    <Grid className={classes.container} id="componentStylesInnerLinkText">
      <Grid item xs={12}>
        <Typography>Text before link text</Typography>
        {component.text ? <TranslationArrayInputMaterial multiline={true} translations={component.text} /> : null}
        <Typography>Link text</Typography>
        {component.children[0] ? (
          <React.Fragment>
            <TranslationArrayInputMaterial multiline={true} translations={component.children[0].text} />
            <TextField
              placeholder="https://..."
              label="url"
              onChange={handleChangeUrl}
              className={classes.link}
              value={component.children[0].props.extraProps.get('link') ?? ''}
            />
          </React.Fragment>
        ) : null}
        <Typography className={classes.label}>Text after link text</Typography>
        {component.children[1] ? (
          <TranslationArrayInputMaterial multiline={true} translations={component.children[1].text} />
        ) : null}
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: 16,
    width: '100%',
  },
  link: {
    width: '65ch',
    marginTop: 16,
  },
  label: {
    paddingTop: 16,
  },
}));
