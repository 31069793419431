/**
 * Created by neo on 12.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { Form, InputNumber } from 'antd';
import { runInAction } from 'mobx';
import { VideoEntry } from '../../../../../Model/Explore/VideoEntry';
import { YogaInstructorEdit } from '../Yoga/YogaInstructorEdit';

export type VideoEntryContentProps = {
  entry: VideoEntry;
};

export const VideoEntryContent: React.FC<VideoEntryContentProps> = observer(({ entry }) => {
  const handleChangeDuration = React.useCallback(
    (value: number | string | undefined | null) => {
      if (value) {
        runInAction(() => (entry.durationInMinutes = Number(value)));
      }
    },
    [entry],
  );

  return (
    <Row style={{ marginBottom: 16 }}>
      <Col>
        <YogaInstructorEdit entry={entry} />
      </Col>
      <Col>
        <Form.Item label="Duration in Minutes">
          <InputNumber value={entry.durationInMinutes} onChange={handleChangeDuration} />
        </Form.Item>
      </Col>
    </Row>
  );
});
