/**
 * Created by neo on 01.12.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Gym } from '../../../../../Model/Gym/Gym';
import { Table } from 'reactstrap';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { GymRouteChallengeTableRow } from './GymRouteChallengeTableRow';
import { useNavigate } from 'react-router';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';

export type GymRouteChallengesTabProps = {
  gym: Gym;
};

export const GymRouteChallengesTab: React.FC<GymRouteChallengesTabProps> = observer(({ gym }) => {
  const history = useNavigate();

  const [challenges, setChallenges] = useState<RouteChallenge[]>([]);

  useEffect(() => {
    RouteChallenge.find({ gymId: gym.id }).then((result) => setChallenges(result));
  }, [gym]);

  return (
    <React.Fragment>
      <SingleColRow>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Start</th>
              <th>End</th>
              <th>Total Points</th>
              <th>Participants</th>
            </tr>
          </thead>
          <tbody>
            {challenges.map((challenge) => (
              <GymRouteChallengeTableRow key={challenge.id} challenge={challenge} />
            ))}
          </tbody>
        </Table>
      </SingleColRow>
    </React.Fragment>
  );
});
