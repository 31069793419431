/**
 * Created by neo on 24.09.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';

export type TranslationInputArrayContainerProps = {
  label?: string;
  children: React.ReactNode;
};

export const TranslationInputArrayContainer: React.FC<TranslationInputArrayContainerProps> = observer(
  ({ label, children }) => {
    return label ? <Form.Item label={label}>{children}</Form.Item> : <React.Fragment>{children}</React.Fragment>;
  },
);
