/**
 * Created by neo on 14.12.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { Modal } from 'antd';
import { Routes, Route } from 'react-router';
import { AthleteCoachSubscriptionModal } from './AthleteCoachSubscriptionModal';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import { AthleteCoachSubscription } from '../../../../Model/Subscription/AthleteCoachSubscription';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { AthleteCoachSubscriptionTableRow } from './AthleteCoachSubscriptionTableRow';
import { useNavigate } from 'react-router-dom';

export type AthleteCoachSubscriptionTabProps = {
  athlete: Athlete;
};

export const AthleteCoachSubscriptionTab: React.FC<AthleteCoachSubscriptionTabProps> = observer(({ athlete }) => {
  const [subscriptions, setSubscriptions] = useState<Array<AthleteCoachSubscription>>([]);
  const history = useNavigate();

  useEffect(() => {
    AthleteCoachSubscription.find({ athleteId: athlete.id, sort: 'validFrom,DESC' }).then((result) =>
      setSubscriptions(result),
    );
  }, [athlete]);

  const handleCreate = React.useCallback(() => {
    history(`new`);
  }, [history]);

  const handleRemove = React.useCallback((subscription) => {
    Modal.confirm({
      title: 'Delete Subscription',
      onOk() {
        subscription.delete().then(() => setSubscriptions((s) => s.filter((s0) => s0.id !== subscription.id)));
      },
    });
  }, []);

  return (
    <React.Fragment>
      {/*<SingleColRow>*/}
      {/*  <div style={{ float: 'right' }}>*/}
      {/*    <Button type="primary" onClick={handleCreate}>*/}
      {/*      Create Subscription*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*</SingleColRow>*/}
      <SingleColRow>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Sponsor</th>
              <th>Plan</th>
              <th>Valid From</th>
              <th>Valid Until</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {subscriptions.map((subscription) => (
              <AthleteCoachSubscriptionTableRow
                key={subscription.id}
                subscription={subscription}
                onRemove={handleRemove}
              />
            ))}
          </tbody>
        </Table>
      </SingleColRow>
      <Routes>
        <Route path=":subscriptionId" element={<AthleteCoachSubscriptionModal />} />
      </Routes>
    </React.Fragment>
  );
});
