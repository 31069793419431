/**
 * Created by neo on 01.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExerciseVariationAdmin } from '../../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import AsyncSelect from 'react-select/async';
import { Exercise } from '../../../../../Model/Exercise/Exercise';

export type ExerciseEasierAlternativeInputProps = {
  exercise: ExerciseVariationAdmin;
};

export const ExerciseEasierAlternativeInput: React.FC<ExerciseEasierAlternativeInputProps> = observer(
  ({ exercise }) => {
    const optionLabel = React.useCallback(
      (option: Exercise) => `${option.name} (${option.equipmentTypes.join(', ')})`,
      [],
    );

    const optionValue = React.useCallback((option: Exercise) => option.id, []);

    return (
      <AsyncSelect
        value={exercise.easierAlternatives ?? []}
        cacheOptions
        defaultOptions
        isClearable={true}
        isMulti={true}
        getOptionLabel={optionLabel}
        getOptionValue={optionValue}
        isDisabled={true}
      />
    );
  },
);
