/**
 * Created by neo on 27.01.2025
 */
import { Audited, AuditedJson } from '../Audited';
import { CampaignCalendarTemplateEntry, CampaignCalendarTemplateEntryJson } from './CampaignCalendarTemplateEntry';
import { CampaignNotificationInterval, CampaignNotificationIntervalJson } from './CampaignNotificationInterval';
import { observable, onBecomeObserved } from 'mobx';
import { Pageable } from '../Pageable';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Gym } from '../Gym/Gym';

export type CampaignCalendarJson = AuditedJson & {
  gymId?: string;
  entry: CampaignCalendarTemplateEntryJson;
  startDateTime: string;
  endDateTime: string;
  notificationIntervals: CampaignNotificationIntervalJson[];
};

export class CampaignCalendar extends Audited {
  @observable
  gymId?: string;
  @observable
  entry = new CampaignCalendarTemplateEntry();
  @observable
  startDateTime = new Date();
  @observable
  endDateTime = new Date();
  @observable
  notificationIntervals: CampaignNotificationInterval[] = [];
  @observable
  gym?: Gym;

  constructor(json?: Partial<CampaignCalendarJson>) {
    super(json);
    if (json) {
      this.gymId = json.gymId;
      this.entry = new CampaignCalendarTemplateEntry(json.entry);
      this.startDateTime = json.startDateTime ? new Date(json.startDateTime) : new Date();
      this.endDateTime = json.endDateTime ? new Date(json.endDateTime) : new Date();
      this.notificationIntervals = json.notificationIntervals?.map((n) => new CampaignNotificationInterval(n)) ?? [];
    }

    onBecomeObserved(this, 'gym', () => this.fetchGym());
  }

  fetchGym() {
    if (this.gymId) {
      Gym.get(this.gymId).then((res) => {
        this.gym = res;
      });
    }
  }

  toJS(newId?: boolean): CampaignCalendarJson {
    return Object.assign(super.toJS(newId), {
      gymId: this.gymId,
      entry: this.entry.toJS(newId),
      startDateTime: this.startDateTime.toISOString(),
      endDateTime: this.endDateTime.toISOString(),
      notificationIntervals: this.notificationIntervals.map((n) => n.toJS()),
    });
  }

  static find(
    request: Pageable & { gymId?: string; startDateTime?: Date; endDateTime?: Date },
  ): Promise<CampaignCalendar[]> {
    return HttpBackend.get(`/gym/campaign/calendar/admin`, request).then((res) =>
      res.map((json) => new CampaignCalendar(json)),
    );
  }
}
