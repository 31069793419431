/**
 * Created by neo on 18.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RecipeInstruction } from '../../../../Model/Diet/Recipe/RecipeInstruction';
import { runInAction } from 'mobx';
import { Recipe } from '../../../../Model/Diet/Recipe/Recipe';
import { RecipeEditModalInstructionStepEntry } from './RecipeEditModalInstructionStepEntry';

export type RecipeEditModalInstructionEntryProps = {
  recipe: Recipe;
  instruction: RecipeInstruction;
  index: number;
};

export const RecipeEditModalInstructionEntry: React.FC<RecipeEditModalInstructionEntryProps> = observer(
  ({ instruction, recipe, index }) => {
    const handleRemove = React.useCallback(
      (index: number) => {
        runInAction(() => instruction.steps.splice(index, 1));
      },
      [instruction],
    );

    return (
      <React.Fragment>
        {instruction.steps.map((step, index) => (
          <RecipeEditModalInstructionStepEntry
            key={index.toString()}
            index={index}
            recipe={recipe}
            instruction={instruction}
            step={step}
            onRemove={handleRemove}
          />
        ))}
      </React.Fragment>
    );
  },
);
