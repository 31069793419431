import { Stage } from './Stage';
import { Exercise } from '../../Exercise/Exercise';
import { FetchStageConfig } from './FetchStageConfig';
import { toJS } from 'mobx';
import { DataSignal } from '../Signal/DataSignal';
import { Signal } from '../Signal/Signal';
import { Pipeline } from '../Pipeline';
import { EmptySignal } from '../Signal/EmptySignal';
import { StartSignal } from '../Signal/StartSignal';

export class FetchStage extends Stage<FetchStageConfig> {
  constructor(pipeline: Pipeline, json?: any) {
    super(pipeline, Object.assign(json, { config: new FetchStageConfig(json.config || {}) }));
  }

  process() {
    const signals = this.takeAll();
    if (signals.filter((s) => !(s instanceof EmptySignal) || s instanceof StartSignal).length > 0) {
      const params = toJS(
        Object.assign(this.config.toJson(this.pipeline.context)),
      );
      console.log('fetching', params);
      return (
        Exercise.find(params)
          .then((result) => result.map((entry, index) => new DataSignal(entry, index)) as Signal[])
          // .then((signals) => signals.concat(new EndOfStreamSignal()))
          .then((signals) => signals.forEach((signal) => this.processNext(signal)))
      );
    } else {
      console.warn('Skipping fetch since we didn`t get any Data- or StartSignal', toJS(signals));
    }
    return undefined;
  }

  toSaveJS(): Promise<any> {
    return Promise.all([super.toSaveJS(), this.config.toSaveJS()]).then(([json, config]) =>
      Object.assign(json, { config }),
    );
  }

  get type(): string {
    return 'fetch';
  }
}
