/**
 * Created by neo on 21.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Media } from '../../../../Model/Media/Media';
import { MediaEditVideoContent } from './MediaEditVideoContent/MediaEditVideoContent';
import { MediaImageContent } from './MediaImageContent/MediaImageContent';
import { MediaAudioContent } from './MediaEditAudioContent/MediaAudioContent';

export type MediaEditContentSwitchProps = {
  media: Media;
};

export const MediaEditContentSwitch: React.FC<MediaEditContentSwitchProps> = observer(({ media }) => {
  if (media.mediaType.startsWith('video')) {
    return <MediaEditVideoContent media={media} />;
  } else if (media.mediaType.startsWith('audio')) {
    return <MediaAudioContent media={media} />;
  } else if (media.mediaType.startsWith('image')) {
    return <MediaImageContent media={media} />;
  }
  return null;
});
