/**
 * Created by neo on 17.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Gym } from '../../../Model/Gym/Gym';
import dayjs from 'dayjs';
import { AnalyticsData, AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { dailySummary } from '../Queries/queryActivitiesHistoryDaily';
import { Col, Container, Row } from 'reactstrap';
import { SingleColRow } from '../../../Components/SingleColRow';
import { DatePicker, Form } from 'antd';
import { ActivityPopularityChart } from './ActivityPopularityChart';

export type ActivityPopularityDailyProps = {
  gym?: Gym;
};

export const ActivityPopularityDaily: React.FC<ActivityPopularityDailyProps> = observer(({ gym }) => {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(dayjs());
  const [data, setData] = useState<AnalyticsRow[] | undefined>();

  useEffect(() => {
    setData(undefined);
    AnalyticsData.query({
      query: dailySummary(!!gym),
      parameters: {
        gym_start_date: {
          value: gym ? dayjs(gym.createdAt).format('YYYYMMDD') : '',
        },
        start_date: {
          value: startDate.format('YYYYMMDD'),
        },
        end_date: {
          value: endDate.format('YYYYMMDD'),
        },
        gym_id: {
          value: gym?.id ?? '',
        },
      },
    }).then((data) =>
      setData(
        data.map((d) =>
          Array.from(Object.entries(d))
            .map(([key, value]) => [key, key === 'event_date' ? value : Number(value)])
            .reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {}),
        ),
      ),
    );
  }, [gym, startDate, endDate]);

  const handleChangeValidFrom = React.useCallback((date) => setStartDate(date ?? dayjs().subtract(1, 'month')), []);

  const handleChangeValidUntil = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

  return (
    <Container>
      <SingleColRow>
        <h5>Activity Popularity Daily</h5>
      </SingleColRow>
      <Row>
        <Col xs={6}>
          <Form.Item label="Start Date">
            <DatePicker value={startDate} onChange={handleChangeValidFrom} />
          </Form.Item>
        </Col>
        <Col xs={6}>
          <Form.Item label="End Date">
            <DatePicker value={endDate} onChange={handleChangeValidUntil} />
          </Form.Item>
        </Col>
        <Col xs={12}>{data && <ActivityPopularityChart data={data} stackId="a" />}</Col>
      </Row>
    </Container>
  );
});
