import React, { useCallback, useMemo, useEffect, useState, useRef } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Row, Col, Collapse, Form, Checkbox, Space, Table, Tag, Button, Modal } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { observable, toJS, runInAction } from 'mobx';
import { ExerciseVariationAdmin } from '../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { ExerciseFilter } from '../../../../Model/Exercise/ExerciseFilter';
import { PageResult } from '../../../../Model/PageResult';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { ExerciseVariationFilter } from '../../../../Components/ExerciseVariationFilter/ExerciseVariationFilter';
import { HttpBackend } from '../../../../Services/Http/HttpBackend';
import { ExerciseListScreenSearchInput } from './ExerciseListScreenSearchInput';
import { useRootStore } from '../../../../Store/useRootStore';
import { BodyPartRegionAdmin } from '../../../../Model/BodyPart/BodyPartRegionAdmin';

const VideoHover: React.FC<{ url: string }> = ({ url }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  return (
    <video
      ref={videoRef}
      src={url}
      width={150}
      muted
      onMouseEnter={() => videoRef.current?.play()}
      onMouseLeave={() => {
        if (videoRef.current) {
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
        }
      }}
      style={{ cursor: 'pointer' }}
    />
  );
};

export type ExerciseListScreenContentProps = {
  sourceType?: string;
  sourceId?: string;
  gym?: any;
};

export const ExerciseListScreenContent: React.FC<ExerciseListScreenContentProps> = observer((props) => {
  const { authentication } = useRootStore();
  const { sourceType, sourceId } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const debounce = useRef<any>(undefined);
  const [currentLanguage, setCurrentLanguage] = useState('de');

  const store = useLocalStore(
    (source) => ({
      loading: false,
      filterVisible: false,
      importing: false,
      filtering: false,
      filteringDone: false,
      deletingFiltered: false,
      filter: new ExerciseFilter(),
      result: new PageResult<ExerciseVariationAdmin>(),
      input: '',
      variables: observable.map<string, string | undefined>(),
      initVariables(params: URLSearchParams) {
        store.variables = observable.map({
          sort: 'nameMap.de,ASC',
          page: '0',
          size: '10',
          sourceType: source.sourceType ?? (source.sourceId ? 'gym' : ''),
          sourceId: source.sourceId,
        });
        params.forEach((value, key) => {
          if (value !== undefined) store.variables.set(key, value);
        });
        store.input = store.variables.get('query') ?? '';
      },
      removeVariable(key: string) {
        store.variables.set(key, '');
        store.buildUrl();
      },
      updateVariable(key: string, value: any) {
        store.updateVariables([[key, value]]);
      },
      updateVariables(values: Array<[string, any]>) {
        values.forEach(([key, value]) => {
          store.variables.set(key, value !== undefined && value !== null ? value : '');
        });
        store.buildUrl();
      },
      buildUrl() {
        const newParams = new URLSearchParams();
        store.variables.forEach((value, key) => {
          if (value) newParams.set(key, value);
        });
        navigate(
          {
            pathname: location.pathname,
            search: newParams.toString(),
          },
          { replace: true },
        );
      },
      loadExercises() {
        store.loading = true;
        const page = Number(store.variables.get('page') ?? '0');
        const size = Number(store.variables.get('size') ?? '10');
        const request = Object.assign({}, toJS(store.variables), store.filter.toJS());
        PageResult.execute(ExerciseVariationAdmin.find(request), ExerciseVariationAdmin.count(request), page, size)
          .then((result) => {
            runInAction(() => (store.result = result));
          })
          .finally(() => runInAction(() => (store.loading = false)));
      },
      loadFresh() {
        store.clear();
        store.loadExercises();
      },
      clear() {
        store.variables = observable.map({
          sourceType: source.sourceType ?? (source.sourceId ? 'gym' : ''),
          sourceId: source.sourceId,
          size: '10',
        });
        store.filter = new ExerciseFilter();
      },
      toggleFilter() {
        store.filterVisible = !store.filterVisible;
      },
      removeExercise(exerciseId: string) {
        store.result.content = store.result.content.filter((e) => e.id !== exerciseId);
      },
      async deleteFiltered() {
        store.deletingFiltered = true;
        await Promise.all(
          store.result.content.map((entry) =>
            entry.archived
              ? entry
                  .remove(true)
                  .then(() => store.removeExercise(entry.id))
                  .then(() => true)
              : entry
                  .remove()
                  .then(() => runInAction(() => (entry.archived = true)))
                  .then(() => true),
          ),
        ).finally(() => runInAction(() => (store.deletingFiltered = false)));
      },
    }),
    props,
  );

  useEffect(() => {
    store.initVariables(params);
    store.loadExercises();
  }, [store, sourceId]);

  const toggleArchived = useCallback(
    ({ target: { checked } }: any) => {
      store.updateVariable('archived', checked ? 'true' : 'false');
      store.loadExercises();
    },
    [store],
  );

  const toggleNoAlternatives = useCallback(
    ({ target: { checked } }: any) => {
      store.updateVariable('noAlternatives', checked ? 'true' : 'false');
      store.loadExercises();
    },
    [store],
  );

  const toggleNoHarderAlternatives = useCallback(
    ({ target: { checked } }: any) => {
      store.updateVariable('noHarderAlternatives', checked ? 'true' : 'false');
      store.loadExercises();
    },
    [store],
  );

  const handleCreate = useCallback(() => {
    navigate(`/new?sourceType=${sourceType ?? ''}&sourceId=${sourceId ?? ''}`);
  }, [sourceType, sourceId, navigate]);

  const handleRemove = useCallback(
    (exercise: ExerciseVariationAdmin, hard: boolean) => {
      Modal.confirm({
        title: hard ? `DELETE ${exercise.nameGermanOrNext} FOREVER???` : `Archive ${exercise.nameGermanOrNext}`,
        content: hard
          ? `Deleting ${exercise.nameGermanOrNext} forever. We CANNOT RECOVER it later.`
          : `Archiving ${exercise.nameGermanOrNext} won't delete it. We can restore it later.`,
        onOk() {
          exercise
            .remove(hard)
            .then(() => (hard ? store.removeExercise(exercise.id) : runInAction(() => (exercise.archived = true))));
        },
      });
    },
    [store],
  );

  const handleQueryChange = useCallback(
    ({ target: { value } }: any) => {
      if (store.input !== value) {
        store.input = value;
        if (debounce.current) clearTimeout(debounce.current);
        debounce.current = setTimeout(() => {
          if (store.variables.get('query') !== value.trim()) {
            store.updateVariables([
              ['query', value.trim()],
              ['page', 0],
            ]);
            store.loadExercises();
          }
        }, 300);
      }
    },
    [store],
  );

  // Remote sorter: update the sort variable based on the selected column.
  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any) => {
      let sortField = sorter.field || '';
      const sortOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC';
      if (sortField === 'nameGermanOrNext') {
        sortField = `nameMap.${currentLanguage}`;
      }
      // For the Type column, no mapping is needed.
      // Pass remote sorter as the sort argument.
      store.updateVariables([
        ['sort', sortField ? `${sortField},${sortOrder}` : ''],
        ['page', 0],
      ]);
      store.loadExercises();
    },
    [store, currentLanguage],
  );

  const handleClear = useCallback(() => {
    store.clear();
    store.buildUrl();
  }, [store]);

  // Simple language switcher.
  const LanguageSwitcher: React.FC = () => {
    return (
      <div style={{ marginBottom: 16 }}>
        {['de', 'en', 'fr', 'it'].map((lang) => (
          <Tag
            key={lang}
            color={lang === currentLanguage ? 'blue' : 'default'}
            style={{ cursor: 'pointer', marginRight: 4 }}
            onClick={() => setCurrentLanguage(lang)}
          >
            {lang.toUpperCase()}
          </Tag>
        ))}
      </div>
    );
  };

  // Columns updated with remote sorter for Type.
  const columns = useMemo(
    () => [
      {
        title: 'Video Preview',
        key: 'videoPreview',
        render: (_: any, record: ExerciseVariationAdmin) => {
          const videoMedia = (record.previewMedias || []).find(
            (m: any) => m.mediaType && m.mediaType.startsWith('video/'),
          );
          return videoMedia ? <VideoHover url={videoMedia.url} /> : null;
        },
      },
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
      },
      {
        title: 'Name',
        dataIndex: 'nameGermanOrNext',
        sorter: true,
        render: (text: string, record: ExerciseVariationAdmin) => (
          <Link to={`/metadata/exercise/${record.id}`}>{record.getName(currentLanguage)}</Link>
        ),
      },
      {
        title: 'Type',
        dataIndex: 'type',
        sorter: true,
        render: (type: string) => {
          let color = 'default';
          if (type && type.toLowerCase() === 'strength') {
            color = 'red';
          } else if (type && type.toLowerCase() === 'cardio') {
            color = 'blue';
          }
          return <Tag color={color}>{type}</Tag>;
        },
      },
      {
        title: 'Body Parts',
        dataIndex: 'bodyParts',
        render: (parts: BodyPartRegionAdmin[]) =>
          parts ? parts.map((b) => <Tag key={b.id || b.defaultName}>{b.defaultName}</Tag>) : '',
      },
      {
        title: 'Equipment',
        dataIndex: 'equipment',
        render: (equip: string[]) => (equip ? equip.map((e, idx) => <Tag key={idx}>{e}</Tag>) : ''),
      },
      {
        title: 'Tags',
        dataIndex: 'tags',
        render: (tags: string[]) =>
          tags
            ? tags.map((t, idx) => (
                <Tag key={idx} color="magenta">
                  {t}
                </Tag>
              ))
            : '',
      },
      {
        title: 'Tracking Keys',
        dataIndex: 'trackingKeys',
        render: (keys: string[], record: ExerciseVariationAdmin) =>
          record.trackingParameters.map((key, idx) => (
            <Tag key={idx} color="purple">
              {key}
            </Tag>
          )),
      },
      {
        title: 'Attributes',
        dataIndex: 'furtherAttributes',
        render: (_: any, record: ExerciseVariationAdmin) => {
          return (
            <>
              {record.force && (
                <Tag color="blue" style={{ marginRight: 4 }}>
                  Force: {record.force}
                </Tag>
              )}
              {record.mechanics && (
                <Tag color="orange" style={{ marginRight: 4 }}>
                  Mechanics: {record.mechanics}
                </Tag>
              )}
              {record.utility && (
                <Tag color="purple" style={{ marginRight: 4 }}>
                  Utility: {record.utility}
                </Tag>
              )}
            </>
          );
        },
      },
      {
        title: 'Actions',
        render: (_: any, record: ExerciseVariationAdmin) => (
          <Space>
            <Button onClick={() => record.copy()} size="small">
              Copy
            </Button>
            <Button danger onClick={() => handleRemove(record, false)} size="small">
              Archive
            </Button>
          </Space>
        ),
      },
    ],
    [handleRemove, currentLanguage],
  );

  return (
    <React.Fragment>
      <LanguageSwitcher />
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col flex="auto">
          <ExerciseListScreenSearchInput
            store={store}
            onChange={handleQueryChange}
            onClear={handleClear}
            disabled={store.deletingFiltered || store.filtering}
          />
        </Col>
        <Col xs="auto">
          <Form.Item style={{ marginBottom: 0 }}>
            <Checkbox
              onChange={toggleArchived}
              checked={store.variables.get('archived') === 'true'}
              disabled={store.deletingFiltered || store.filtering}
            >
              Show archived
            </Checkbox>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Checkbox
              onChange={toggleNoAlternatives}
              checked={store.variables.get('noAlternatives') === 'true'}
              disabled={store.deletingFiltered || store.filtering}
            >
              Show no alternatives
            </Checkbox>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Checkbox
              onChange={toggleNoHarderAlternatives}
              checked={store.variables.get('noHarderAlternatives') === 'true'}
              disabled={store.deletingFiltered || store.filtering}
            >
              Show no harder alternatives
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xs="auto">
          <Space>
            <Button type="primary" onClick={handleCreate} disabled={store.deletingFiltered || store.filtering}>
              Create Exercise
            </Button>
          </Space>
        </Col>
      </Row>
      <SingleColRow>
        <Collapse activeKey={store.filterVisible ? 'filter' : undefined}>
          <Collapse.Panel key="filter" header={<h5>Filter</h5>}>
            <ExerciseVariationFilter filter={store.filter} onApply={() => store.loadExercises()} />
          </Collapse.Panel>
        </Collapse>
      </SingleColRow>
      <SingleColRow>
        <Table
          rowKey="id"
          dataSource={store.result.content.slice()}
          columns={columns}
          loading={store.loading}
          pagination={{
            current: Number(store.variables.get('page')) + 1,
            pageSize: Number(store.variables.get('size')),
            total: store.result.totalElements || 0,
            showSizeChanger: false,
          }}
          scroll={{ x: 1200 }} // set a horizontal scroll width
          onChange={handleTableChange}
        />
      </SingleColRow>
    </React.Fragment>
  );
});
