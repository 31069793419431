/**
 * Created by neo on 05.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { EarnModelTemplate } from '../../../../../Model/Engagement/EarnModelTemplate';
import { EarnModelTemplateCategory } from '../../../../../Model/Engagement/EarnModelTemplateCategory';
import { EarnModelTemplateRuleSimple } from '../../../../../Model/Engagement/EarnModelTemplateRuleSimple';
import { Col, Row } from 'reactstrap';
import { DatePicker, Form, InputNumber, Radio, Select, Space } from 'antd';
import { runInAction } from 'mobx';
import { RecurringPatternDaysOfWeek } from '../../../../Coach/Explore/Content/ZoomMeetingEntry/RecurringPatternDaysOfWeek';
import dayjs from 'dayjs';
import { DayOfWeekList } from '../../../../../Model/DayOfWeek';
import { EarnModelTemplateRuleInstant } from '../../../../../Model/Engagement/EarnModelTemplateRuleInstant';
import { EarnModelTemplateRuleGrouped } from '../../../../../Model/Engagement/EarnModelTemplateRuleGrouped';

export type TimeConstraintTabProps = {
  template: EarnModelTemplate;
  category: EarnModelTemplateCategory;
  rule: EarnModelTemplateRuleSimple | EarnModelTemplateRuleInstant | EarnModelTemplateRuleGrouped;
};

export const TimeConstraintTab: React.FC<TimeConstraintTabProps> = observer(({ template, category, rule }) => {
  const timeConstraint = rule.timeConstraint;
  const validFrom = timeConstraint.validFrom ? dayjs(timeConstraint.validFrom) : undefined;
  const validUntil = timeConstraint.validUntil ? dayjs(timeConstraint.validUntil) : undefined;

  // const handleChangeTriggerWindow =

  const handleChangeType = React.useCallback(
    (newValue) => runInAction(() => (rule.timeConstraint.type = newValue)),
    [rule],
  );

  const handleChangeSeparationCount = React.useCallback(
    (e) => {
      runInAction(() => (rule.pointsPerActivation = Number(e) ?? 1));
    },
    [rule],
  );

  const handleChangeCount = React.useCallback(
    (e) => {
      runInAction(() => (rule.pointsPerActivation = Number(e) ?? 1));
    },
    [rule],
  );

  const handleActivateDayOfMonth = React.useCallback(
    () =>
      runInAction(() => {
        timeConstraint.dayOfMonth = timeConstraint.dayOfMonth ? undefined : dayjs().date();
        timeConstraint.nthDayOfTheMonth = timeConstraint.dayOfMonth ? undefined : 'first';
        timeConstraint.weekDayOfMonth = timeConstraint.dayOfMonth ? undefined : DayOfWeekList[dayjs().isoWeekday() - 1];
      }),
    [timeConstraint],
  );

  const handleActivateNthOfMonth = React.useCallback(
    () =>
      runInAction(() => {
        timeConstraint.nthDayOfTheMonth = timeConstraint.nthDayOfTheMonth ? undefined : 'first';
        timeConstraint.dayOfMonth = timeConstraint.nthDayOfTheMonth ? undefined : dayjs().date();
        timeConstraint.weekDayOfMonth = timeConstraint.nthDayOfTheMonth
          ? DayOfWeekList[dayjs().isoWeekday() - 1]
          : undefined;
      }),
    [timeConstraint],
  );

  const handleChangeNthDayOfMonth = React.useCallback(
    (type) => runInAction(() => (timeConstraint.nthDayOfTheMonth = type)),
    [timeConstraint],
  );

  const handleChangeDayOfMonth = React.useCallback(
    (value: number | string | undefined | null) => {
      if (value) {
        const number = value ? Number(value) : 1;
        runInAction(() => (timeConstraint.dayOfMonth = number && number > 0 ? number : 1));
      }
    },
    [timeConstraint],
  );

  const handleChangeWeekDayOfMonth = React.useCallback(
    (type) => runInAction(() => (timeConstraint.weekDayOfMonth = type)),
    [timeConstraint],
  );

  const handleChangeMonthOfYear = React.useCallback(
    (type) => runInAction(() => (timeConstraint.monthOfYear = type)),
    [timeConstraint],
  );

  const handleChangeValidFrom = React.useCallback(
    (date) => (timeConstraint.validFrom = date?.toDate()),
    [timeConstraint],
  );

  const handleChangeValidUntil = React.useCallback(
    (date) => (timeConstraint.validUntil = date?.toDate()),
    [timeConstraint],
  );

  return (
    <React.Fragment>
      <Row>
        <Col md={4}>
          <Form.Item label="Type">
            <Select value={rule.timeConstraint.type} onChange={handleChangeType}>
              <Select.Option value="daily">Daily</Select.Option>
              <Select.Option value="weekly">Weekly</Select.Option>
              <Select.Option value="monthly">Monthly</Select.Option>
              <Select.Option value="yearly">Yearly</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item label="Separation Count" extra="e.g. every 2nd day/week">
            <InputNumber
              min={1}
              max={10000}
              onChange={handleChangeSeparationCount}
              value={rule.timeConstraint.separationCount}
            />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            label="Trigger Count"
            extra="how often can it trigger per day or within the selected days when weekly (-1 unlimited)"
          >
            <InputNumber min={-1} max={10000} onChange={handleChangeCount} value={rule.timeConstraint.count} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Item label="Valid From">
            <DatePicker value={validFrom} onChange={handleChangeValidFrom} allowClear />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label="Valid Until">
            <DatePicker value={validUntil} onChange={handleChangeValidUntil} allowClear />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          {timeConstraint.type === 'weekly' && <RecurringPatternDaysOfWeek recurringPattern={timeConstraint} />}
          {(timeConstraint.type === 'monthly' || timeConstraint.type === 'yearly') && (
            <React.Fragment>
              <Row>
                <Col>
                  <Form.Item label="Day of Month">
                    {timeConstraint.type === 'monthly' && (
                      <Radio checked={!!timeConstraint.dayOfMonth} onChange={handleActivateDayOfMonth} />
                    )}
                    <InputNumber
                      value={timeConstraint.dayOfMonth}
                      onChange={handleChangeDayOfMonth}
                      disabled={!timeConstraint.dayOfMonth && timeConstraint.type === 'monthly'}
                      min={1}
                      max={31}
                    />
                  </Form.Item>
                </Col>
                {timeConstraint.type === 'yearly' && (
                  <Col>
                    <Form.Item label="Month of Year">
                      <Select value={timeConstraint.monthOfYear} onChange={handleChangeMonthOfYear}>
                        <Select.Option value={1}>January</Select.Option>
                        <Select.Option value={2}>February</Select.Option>
                        <Select.Option value={3}>March</Select.Option>
                        <Select.Option value={4}>April</Select.Option>
                        <Select.Option value={5}>May</Select.Option>
                        <Select.Option value={6}>June</Select.Option>
                        <Select.Option value={7}>July</Select.Option>
                        <Select.Option value={8}>August</Select.Option>
                        <Select.Option value={9}>September</Select.Option>
                        <Select.Option value={10}>October</Select.Option>
                        <Select.Option value={11}>November</Select.Option>
                        <Select.Option value={12}>December</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {timeConstraint.type === 'monthly' && (
                  <React.Fragment>
                    <Col>
                      <Form.Item label="nth day of Month">
                        <Space>
                          <Radio checked={!!timeConstraint.nthDayOfTheMonth} onChange={handleActivateNthOfMonth} />
                          <Select
                            value={timeConstraint.nthDayOfTheMonth}
                            onChange={handleChangeNthDayOfMonth}
                            disabled={!timeConstraint.nthDayOfTheMonth}
                          >
                            <Select.Option value="first">First</Select.Option>
                            <Select.Option value="second">Second</Select.Option>
                            <Select.Option value="third">Third</Select.Option>
                            <Select.Option value="fourth">Fourth</Select.Option>
                            <Select.Option value="last">Last</Select.Option>
                          </Select>
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item label="Day of Week">
                        <Select
                          value={timeConstraint.weekDayOfMonth}
                          onChange={handleChangeWeekDayOfMonth}
                          disabled={!timeConstraint.weekDayOfMonth}
                        >
                          <Select.Option value="MONDAY">Monday</Select.Option>
                          <Select.Option value="TUESDAY">Tuesday</Select.Option>
                          <Select.Option value="WEDNESDAY">Wednesday</Select.Option>
                          <Select.Option value="THURSDAY">Thursday</Select.Option>
                          <Select.Option value="FRIDAY">Friday</Select.Option>
                          <Select.Option value="SATURDAY">Saturday</Select.Option>
                          <Select.Option value="SUNDAY">Sunday</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </React.Fragment>
                )}
              </Row>
            </React.Fragment>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
});
