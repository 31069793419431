/**
 * Created by neo on 01.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExerciseVariationAdmin } from '../../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { runInAction } from 'mobx';
import AsyncSelect from 'react-select/async';
import { Exercise } from '../../../../../Model/Exercise/Exercise';

export type ExerciseAlternativeInputProps = {
  exercise: ExerciseVariationAdmin;
};

export const ExerciseAlternativeInput: React.FC<ExerciseAlternativeInputProps> = observer(({ exercise }) => {
  const handleChange = React.useCallback(
    async (tags?: Array<Exercise>) => {
      console.log('handleChange', tags);
      runInAction(() => (exercise.alternativeIds = (tags ?? []).map((e) => e.id)));
    },
    [exercise],
  );

  const fetch = React.useCallback(
    (query?: string) =>
      Exercise.find({
        query,
        size: 30,
      }),
    [],
  );

  const optionLabel = React.useCallback(
    (option: Exercise) => `${option.name} (${option.equipmentTypes.join(', ')})`,
    [],
  );

  const optionValue = React.useCallback((option: Exercise) => option.id, []);

  return (
    <AsyncSelect
      value={exercise.alternatives ?? []}
      cacheOptions
      defaultOptions
      isClearable={true}
      loadOptions={fetch}
      isMulti={true}
      getOptionLabel={optionLabel}
      getOptionValue={optionValue}
      onChange={handleChange as any}
    />
  );
});
