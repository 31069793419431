/**
 * Created by neo on 24.09.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { SuperMacroMesoCycleTemplate } from '../../../../../Model/Coach/SuperMacro/SuperMacroMesoCycleTemplate';

export type MesoCycleTypeSelectProps = {
  mesoCycle: SuperMacroMesoCycleTemplate;
  onChange?: () => any;
};

export const MesoCycleTypeSelect: React.FC<MesoCycleTypeSelectProps> = observer(
  ({ mesoCycle, onChange }: MesoCycleTypeSelectProps) => {
    const handleChangeType = React.useCallback(
      (value) => {
        mesoCycle.type = value;
        onChange && onChange();
      },
      [mesoCycle],
    );

    return (
      <Select style={{ flex: 1 }} value={mesoCycle.type} onChange={handleChangeType}>
        <Select.Option value="hypertrophy_1">Hypertrophy / Grundlagen Muskelaufbau</Select.Option>
        <Select.Option value="hypertrophy_2">Hypertrophy 2 / Muskelaufbau</Select.Option>
        <Select.Option value="low_intensity_muscle_endurance">Low Intensity Muscle Endurance / Deload</Select.Option>
        <Select.Option value="low_intensity_hypertrophy">
          Low Intensity Hypertrophy / Grundlagen Muskelaufbau
        </Select.Option>
        <Select.Option value="muscle_endurance">Muscle Endurance/ Stärkung Grundlagenausdauer</Select.Option>
        <Select.Option value="max_strength">Max Strength/ Kraftzuwachs</Select.Option>
      </Select>
    );
  },
);
