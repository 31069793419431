/**
 * Created by neo on 02.08.22.
 */
import { observable } from 'mobx';
import { EarnModelRuleTimeConstraint, EarnModelRuleTimeConstraintJson } from './EarnModelRuleTimeConstraint';
import { EarnModelRuleActivationLogic, EarnModelRuleActivationLogicJson } from './EarnModelRuleActivationLogic';
import { EarnModelTemplateRuleAbstract, EarnModelTemplateRuleAbstractJson } from './EarnModelTemplateRuleAbstract';

export type EarnModelTemplateRuleSimpleJson = EarnModelTemplateRuleAbstractJson & {
  timeConstraint: EarnModelRuleTimeConstraintJson;
  activationLogic: EarnModelRuleActivationLogicJson;
};

export class EarnModelTemplateRuleSimple extends EarnModelTemplateRuleAbstract {
  @observable
  timeConstraint = new EarnModelRuleTimeConstraint();
  @observable
  activationLogic = new EarnModelRuleActivationLogic();

  constructor(json?: Partial<EarnModelTemplateRuleSimpleJson>) {
    super(Object.assign({}, json ?? {}, { type: 'simple' }));
    if (json) {
      this.timeConstraint = new EarnModelRuleTimeConstraint(json.timeConstraint);
      this.activationLogic = new EarnModelRuleActivationLogic(json.activationLogic);
    }
  }

  toJS(newId: boolean = false): EarnModelTemplateRuleSimpleJson {
    return Object.assign(super.toJS(newId), {
      timeConstraint: this.timeConstraint?.toJS(),
      activationLogic: this.activationLogic.toJS(),
    });
  }

  copy(): EarnModelTemplateRuleSimple {
    return new EarnModelTemplateRuleSimple(this.toJS(true));
  }
}
