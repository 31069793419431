/**
 *
 * Created by neo on 13.03.17.
 */
import { toJS, action, computed, observable } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { v4 as UUID } from 'uuid';
import { Media } from '../Media/Media';
import { LocalizedArrayEntity } from '../LocalizedArrayEntity';

export class BodyPartJoint extends LocalizedArrayEntity {
  @observable
  identifier = '';
  @observable
  medias: Array<Media> = [];
  @observable
  movedBy: Array<string> = [];
  exerciseCount?: number = 0;

  constructor(json?: any) {
    super(json);
    if (json) {
      this.id = json.id || UUID();
      this.identifier = json.identifier || '';
      this.medias = (json.medias || []).map((m) => new Media(m));
      this.movedBy = json.movedBy || [];
    }

    // onBecomeObserved(this, 'exerciseCount', this.fetchExerciseCount);
  }

  // fetchExerciseCount = async () => {
  //   if (this.exerciseCount === undefined) {
  //     this.exerciseCount = (await ExerciseVariationAdmin.find({ size: 1, joints: this.id })).totalElements;
  //   }
  //   return this.exerciseCount;
  // };

  toJS(): any {
    return Object.assign(super.toJS(), {
      id: this.id,
      identifier: this.identifier,
      medias: toJS(this.medias),
      movedBy: toJS(this.movedBy),
    });
  }

  @computed
  get _isNew(): boolean {
    return !this.id;
  }

  @action
  remove() {
    return HttpBackend.delete(`/activity/exercise/bodypart/joint/${this.id}`);
  }

  @action
  async save() {
    return HttpBackend.post('/activity/exercise/bodypart/joint', this.toJS()).then(() => this);
  }

  static async get(id: string): Promise<BodyPartJoint | undefined> {
    const res = await HttpBackend.get(`/activity/exercise/bodypart/joint/${id}`);
    if (res) {
      return new BodyPartJoint(res);
    }
    return undefined;
  }

  static async getAll(ids: Array<string>): Promise<Array<BodyPartJoint>> {
    if (ids.length > 0) {
      const data = await Promise.all(ids.map((id) => BodyPartJoint.get(id)));
      return data.filter((b) => !!b) as Array<BodyPartJoint>;
    }
    return [];
  }

  static async list(): Promise<Array<BodyPartJoint>> {
    return HttpBackend.get(`/activity/exercise/bodypart/joint/list`).then((result) =>
      (result || []).map((b) => new BodyPartJoint(b)),
    );
  }

  static async find(params: any = { page: 0, sort: 'name,ASC' }): Promise<BodyPartJoint[]> {
    return HttpBackend.get(`/activity/exercise/bodypart/joint`, params).then((res) => res.map((r) => new BodyPartJoint(r)));
  }
}
