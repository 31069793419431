/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { StageNodeWidgetProps, StyledRow } from '../Default/DefaultStageNodeWidget';
import AsyncSelect from 'react-select/async';
import { Link } from 'react-router-dom';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';
import { ActionMeta } from 'react-select';
import { PipelineStage } from '../../../../../../../Model/Coach/Stages/PipelineStage';
import { ExecutionModeSelect } from './ExecutionModeSelect';
import { PipelineNodeModel } from './PipelineNodeModel';
import { PipelineWidgetContainer } from './PipelineWidgetContainer';

export type PipelineNodeWidgetProps = {
  node: PipelineNodeModel;
} & StageNodeWidgetProps;

export const PipelineNodeWidget: React.FC<PipelineNodeWidgetProps> = observer(
  ({ node, engine, size }: PipelineNodeWidgetProps) => {
    const stage = node.stage as PipelineStage;

    const fetch = React.useCallback(
      (name?: string): Promise<Array<Pipeline>> =>
        Pipeline.find({
          name,
          page: 0,
          sort: 'name,ASC',
        }).then((result) => result.filter((b) => b.id !== stage.config.pipelineId)),
      [stage],
    );

    const handleChange = React.useCallback(
      (pipeline?: Pipeline, action?: ActionMeta<any>) => {
        stage.config.pipelineId = pipeline ? pipeline.id : undefined;
        stage.innerPipeline = pipeline;
      },
      [stage],
    );

    const getOptionLabel = (option: Pipeline) => option.name;

    const getOptionValue = (option: Pipeline) => option.id;

    const handleFocus = React.useCallback(() => {
      node.setLocked(true);
    }, [node]);

    const handleBlur = React.useCallback(() => {
      node.setLocked(false);
    }, [node]);

    return (
      <PipelineWidgetContainer
        engine={engine}
        node={node}
        size={size}
        content={
          <React.Fragment>
            <StyledRow>
              <div style={{ flex: 1, minWidth: 240 }}>
                <AsyncSelect
                  value={stage.innerPipeline}
                  cacheOptions
                  defaultOptions
                  isClearable={true}
                  loadOptions={fetch}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={getOptionValue as any}
                  onChange={handleChange as any}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <Link style={{ color: 'black' }} to={`/coach/workout-script/${stage.config.pipelineId}`}>
                  {stage.innerPipeline?.name}
                </Link>
              </div>
            </StyledRow>
            <StyledRow>
              <ExecutionModeSelect node={node} />
            </StyledRow>
          </React.Fragment>
        }
      />
    );
  },
);
