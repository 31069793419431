/**
 * Created by katarinababic on 8.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Container } from 'reactstrap';
import { StudioTotalEventsPerCompany } from './StudioTotalEventsPerCompany';
import { StudioTotalImageDownloadsPerCompany } from './StudioTotalImageDownloadsPerCompany';
import { StudioTotalImageDownloadsPerEntryPerCompany } from './StudioTotalImageDownloadsPerEntryPerCompany';
import { StudioTotalTextDownloadsPerCompany } from './StudioTotalTextDownloadsPerCompany';
import { StudioTotalTextDownloadsPerEntryPerCompany } from './StudioTotalTextDownloadsPerEntryPerCompany';
import { StudioTotalDownloadAllEventsPerCompany } from './StudioTotalDownloadAllEventsPerCompany';
import { StudioTotalDownloadAllEventsPerEntryPerCompany } from './StudioTotalDownloadAllEventsPerEntryPerCompany';
import { StudioTotalSearchEventsPerCompany } from './StudioTotalSearchEventsPerCompany';
import { StudioTotalSearchEventsPerSearchQueryPerCompany } from './StudioTotalSearchEventsPerSearchQueryPerCompany';
import { useEffect } from 'react';
import { Gym } from '../../../Model/Gym/Gym';
import { CampaignCalendarTemplateEntry } from '../../../Model/CampaignCalendar/CampaignCalendarTemplateEntry';
import { StudioTotalDownloadReportClicksPerCompany } from './StudioTotalDownloadReportClicksPerCompany';
import { StudioTotalGenerateReportClicksPerCompany } from './StudioTotalGenerateReportClicksPerCompany';
import { StudioTotalOpenFolderClicksPerCompany } from './StudioTotalOpenFolderClicksPerCompany';
import { StudioTotalCreateNotificationClicksPerCompany } from './StudioTotalCreateNotificationClicksPerCompany';
import { StudioTotalImageDownloadsPerEntry } from './StudioTotalImageDownloadsPerEntry';
import { StudioTotalTextDownloadsPerEntry } from './StudioTotalTextDownloadsPerEntry';
import { StudioTotalDownloadAllEventsPerEntry } from './StudioTotalDownloadAllEventsPerEntry';

export type Company = {
  id: string;
  name: string;
};

export type CalendarEntry = {
  id: string;
  name: string;
  date: string;
};

export type StudioAnalyticsScreenProps = {};

export const StudioAnalyticsScreen: React.FC<StudioAnalyticsScreenProps> = observer((props) => {
  const [companies, setCompanies] = React.useState<Company[]>([]);
  const [calendarEntries, setCalendarEntries] = React.useState<CalendarEntry[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [companiesPage, setCompaniesPage] = React.useState(0);
  const [entriesPage, setEntriesPage] = React.useState(0);

  useEffect(() => {
    setLoading(true);
    Gym.find({ page: companiesPage }).then((res) => {
      if (res.length > 0) {
        setCompanies((previous) => previous.concat(res.map((gym) => ({ id: gym.id, name: gym.name }))));
        setCompaniesPage((prev) => prev + 1);
      } else {
        setLoading(false);
      }
    });
  }, [companiesPage]);

  useEffect(() => {
    setLoading(true);
    CampaignCalendarTemplateEntry.find({ page: entriesPage }).then((res) => {
      if (res.length > 0) {
        setCalendarEntries((previous) =>
          previous.concat(res.map((entry) => ({ id: entry.id, name: entry.defaultName, date: entry.entryDate }))),
        );
        setEntriesPage((prev) => prev + 1);
      } else {
        setLoading(false);
      }
    });
  }, [entriesPage]);

  return loading ? null : (
    <Container>
      <Row>
        <Col>
          <h1>{'Studio Analytics'}</h1>
        </Col>
      </Row>
      <StudioTotalEventsPerCompany companies={companies} />
      {/*<StudioTotalPageViewsPerCompany companies={companies} />*/}
      <StudioTotalImageDownloadsPerCompany companies={companies} />
      <StudioTotalImageDownloadsPerEntry entries={calendarEntries} />
      <StudioTotalImageDownloadsPerEntryPerCompany companies={companies} entries={calendarEntries} />
      <StudioTotalTextDownloadsPerCompany companies={companies} />
      <StudioTotalTextDownloadsPerEntry entries={calendarEntries} />
      <StudioTotalTextDownloadsPerEntryPerCompany companies={companies} entries={calendarEntries} />
      <StudioTotalDownloadAllEventsPerCompany companies={companies} />
      <StudioTotalDownloadAllEventsPerEntry entries={calendarEntries} />
      <StudioTotalDownloadAllEventsPerEntryPerCompany companies={companies} entries={calendarEntries} />
      <StudioTotalSearchEventsPerCompany companies={companies} />
      <StudioTotalSearchEventsPerSearchQueryPerCompany companies={companies} />
      <StudioTotalDownloadReportClicksPerCompany companies={companies} />
      <StudioTotalGenerateReportClicksPerCompany companies={companies} />
      <StudioTotalOpenFolderClicksPerCompany companies={companies} />
      <StudioTotalCreateNotificationClicksPerCompany companies={companies} />
    </Container>
  );
});
