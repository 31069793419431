/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { DefaultStageNodeWidget, StageNodeWidgetProps, StyledRow } from '../Default/DefaultStageNodeWidget';
import { StyledInput } from '../StyledInput';

export type LimitNodeWidgetProps = {} & StageNodeWidgetProps;

export const LimitNodeWidget: React.FC<LimitNodeWidgetProps> = observer(
  ({ node, engine, size }: LimitNodeWidgetProps) => {
    const handleChange = React.useCallback(({ target: { value } }: any) => (node.stage.config.limit = value), [node]);

    return (
      <DefaultStageNodeWidget
        engine={engine}
        node={node}
        size={size}
        disableEdit={true}
        content={
          <StyledRow>
            <StyledInput node={node} value={node.stage.config.limit} onChange={handleChange} />
          </StyledRow>
        }
      />
    );
  },
);
