/**
 * Created by neo on 20.04.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import { Button, Tag } from 'antd';
import { Pager } from '../../../Components/Pager';
import { PageResult } from '../../../Model/PageResult';
import { OAuthClient } from '../../../Model/OAuthClient';
import { Link, useNavigate } from 'react-router-dom';

export type OAuthClientListScreenProps = {};

export const OAuthClientListScreen: React.FC<OAuthClientListScreenProps> = observer((props) => {
  const [result, setResult] = useState(new PageResult<OAuthClient>());

  const history = useNavigate();

  const page = React.useRef(0);

  const load = React.useRef((page: number) => {
    PageResult.execute(OAuthClient.find({ page, sort: 'name,ASC' }), OAuthClient.count(), page, 10).then((res) =>
      setResult(res),
    );
  }).current;

  useEffect(() => {
    load(0);
  }, [load]);

  const handleCreateOauth = () => {
    history('new');
  };

  const handlePage = (newPage: number): void => {
    if (page.current !== newPage) {
      page.current = newPage;
      load(newPage);
    }
  };

  const handleDelete = React.useCallback(
    (client: OAuthClient) => {
      if (window.confirm(`Delete ${client.name}?`)) {
        client.remove().then(() => load(page.current));
      }
    },
    [load],
  );

  return (
    <Container>
      <Row>
        <Col>
          <h1>{'OAuth Clients'}</h1>
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={handleCreateOauth}>
            {'Add Client'}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Pager page={result} onPage={handlePage} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Table className="table-striped table-hover table-sm">
            <thead className="thead-inverse">
              <tr>
                <th>{'Name'}</th>
                <th>{'Client ID'}</th>
                <th>{'Grant Types'}</th>
                <th>{'Scopes'}</th>
                <th>{''}</th>
              </tr>
            </thead>
            <tbody>
              {result.content.map((client) => (
                <tr key={client.id}>
                  <td>{client.name}</td>
                  <td>
                    <Link to={`/security/oauth/${client.clientId}`}>{client.clientId}</Link>
                  </td>
                  <td>
                    {client.authorizedGrantTypes.map((g) => (
                      <Tag key={g} color="cyan">
                        {g}
                      </Tag>
                    ))}
                  </td>
                  <td>
                    {client.scopes.map((scope, index) => (
                      <Tag key={scope} color="green">
                        {scope}
                      </Tag>
                    ))}
                  </td>
                  <td>
                    <Button danger onClick={() => handleDelete(client)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
});
