/**
 *
 * Created by neo on 30.03.17.
 */
import { toJS, observable, action, computed, runInAction } from 'mobx';
import { HttpBackend } from '../Services/Http/HttpBackend';
import { Audited } from './Audited';

export class OAuthClient extends Audited {
  @observable
  name: string = '';
  @observable
  description: string = '';
  @observable
  clientId: string = '';
  @observable
  clientSecret: string = '';
  @observable
  plainSecret?: string = undefined;
  @observable
  secretRequired: boolean = true;
  @observable
  resourceIds: Array<string> = [];
  @observable
  isScoped: boolean = false;
  @observable
  scopes: Array<string> = [];
  @observable
  authorizedGrantTypes: Array<string> = [];
  @observable
  redirectUris: Array<string> = [];
  @observable
  authorities: Array<string> = [];
  @observable
  accessTokenValiditySeconds: number = 600;
  @observable
  refreshTokenValiditySeconds: number = 8035200;
  @observable
  isAutoApprove: boolean = true;
  @observable
  additionalInformation = observable.map({});

  constructor(json?: any) {
    super(json);
    if (json) {
      this.name = json.name;
      this.description = json.description;
      this.clientId = json.clientId;
      this.clientSecret = json.clientSecret;
      this.plainSecret = json.plainSecret;
      this.secretRequired = Boolean(json.secretRequired);
      this.resourceIds = json.resourceIds || [];
      this.isScoped = Boolean(json.isScoped);
      this.scopes = json.scopes || [];
      this.authorizedGrantTypes = json.authorizedGrantTypes || [];
      this.redirectUris = json.redirectUris || [];
      this.authorities = json.authorities || [];
      this.accessTokenValiditySeconds = json.accessTokenValiditySeconds || 600;
      this.refreshTokenValiditySeconds = json.refreshTokenValiditySeconds || 8035200;
      this.isAutoApprove = Boolean(json.isAutoApprove);
      this.additionalInformation = observable.map(json.additionalInformation || {});
    }
  }

  @computed
  get _isNew(): boolean {
    return !this.id;
  }

  @action
  async save() {
    const res = await HttpBackend.post('/uaa/oauth/client', toJS(this));

    if (res) {
      runInAction(() => {
        this.clientId = res.clientId;
        this.plainSecret = res.plainSecret;
      });
    }

    return this;
  }

  remove() {
    return HttpBackend.delete(`/uaa/oauth/client/${this.id}`);
  }

  static find(data: any = { sort: 'name,ASC' }): Promise<OAuthClient[]> {
    return HttpBackend.get('/uaa/oauth/client', data).then((res) => (res || []).map((c) => new OAuthClient(c)));
  }

  static count(data: any = {}): Promise<number> {
    return HttpBackend.get('/uaa/oauth/client/count', data);
  }

  static async get(clientId: string): Promise<OAuthClient | undefined> {
    const res = await HttpBackend.get(`/uaa/oauth/client/${clientId}`);
    if (res) {
      return new OAuthClient(res);
    }
    return undefined;
  }
}
