/**
 * Created by neo on 15.12.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ExerciseVariationAdmin } from '../../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { message, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import { ExerciseEditCard } from './ExerciseEditCard';
import { useNavigate } from 'react-router';

export type ExerciseEditModalProps = {};

export const ExerciseEditModal: React.FC<ExerciseEditModalProps> = observer(() => {
  const [exercise, setExercise] = useState(new ExerciseVariationAdmin());
  const { exerciseId, gymId } = useParams<{ gymId?: string; exerciseId: string }>();
  const [saving, setSaving] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    if (exerciseId && exerciseId !== 'new') {
      ExerciseVariationAdmin.get(exerciseId, gymId ? 'gym' : undefined, gymId).then((result) => setExercise(result));
    } else {
      setExercise(new ExerciseVariationAdmin({ sourceType: gymId ? 'gym' : undefined, sourceId: gymId }));
    }
  }, [exerciseId, gymId]);

  const handleSave = React.useCallback(() => {
    setSaving(true);
    exercise
      .save()
      .then((result) => history(result.id, { replace: true }))
      .then(() => message.success(`${exercise.nameGermanOrNext} saved`))
      .catch(() => message.error(`ERROR saving ${exercise.nameGermanOrNext}`))
      .finally(() => setSaving(false));
  }, [exercise, history]);

  const handleClose = React.useCallback(() => {
    history(-1);
  }, [history]);

  return (
    <Modal
      title={exercise.nameGermanOrNext}
      open={true}
      width="80%"
      style={{ top: 20 }}
      onOk={handleSave}
      onCancel={handleClose}
      confirmLoading={saving}
    >
      <ExerciseEditCard exercise={exercise} onSaveMedia={handleSave} />
    </Modal>
  );
});
