import * as React from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'reactstrap';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { useParams, useNavigate } from 'react-router-dom';
import { message, Button, Space, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { PhaseEditorTab } from './PhaseEditorTab/PhaseEditorTab';
import { WorkoutTemplate } from '../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';
import { BasicInfoTab } from './BasicInfoTab/BasicInfoTab';
import { TestTab } from './TestTab/TestTab';

export type WorkoutTemplateViewScreenProps = {};

export const WorkoutTemplateViewScreen: React.FC<WorkoutTemplateViewScreenProps> = observer((_) => {
  const { templateId } = useParams<any>();
  const history = useNavigate();

  const [workout, setWorkout] = useState(new WorkoutTemplate());
  const [activeTab, setActiveTab] = useState('basicInfo');
  const [processing, setProcessing] = useState(false);


  useEffect(() => {
    if (templateId && templateId !== 'new' && templateId !== 'create') {
      WorkoutTemplate.get(templateId).then((result) => setWorkout(result ?? new WorkoutTemplate()));
    } else {
      setWorkout(new WorkoutTemplate());
    }
  }, [templateId]);

  const handleSave = React.useCallback(() => {
    setProcessing(true);
    workout
      .save()
      .then(() => {
        if ((!templateId || templateId === 'create' || templateId === 'new') && templateId !== workout.id) {
          history(`/coach/workout-template/${workout.id}`, { replace: true });
        }
      })
      .then(() => message.success('Workout Template saved'))
      .catch(() => message.error('Failed to save template'))
      .finally(() => setProcessing(false));
  }, [history, templateId, workout]);

  const handleTabChange = React.useCallback((tab) => setActiveTab(tab), []);

  return (
    <Container>
      <Row>
        <Col>
          <h1>{workout.templateName}</h1>
        </Col>
        <Col xs="auto">
          <Space>
            <Button type="primary" onClick={handleSave} disabled={processing}>
              {'Save'}
            </Button>
          </Space>
        </Col>
      </Row>
      <SingleColRow>
        <Tabs activeKey={activeTab} animated={false} onChange={handleTabChange}>
          <Tabs.TabPane key="basicInfo" tab="Basic Info">
            <BasicInfoTab workoutTemplate={workout} />
          </Tabs.TabPane>
          <Tabs.TabPane key="editor" tab="Phase Editor">
            <PhaseEditorTab workoutTemplate={workout} />
          </Tabs.TabPane>
          <Tabs.TabPane key="test" tab="Test">
            <TestTab workoutTemplate={workout} />
          </Tabs.TabPane>
        </Tabs>
      </SingleColRow>
    </Container>
  );
});
