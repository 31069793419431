/**
 * Created by neo on 01.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { ExerciseVariationAdmin } from '../../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { Form, Select } from 'antd';
import { ExercisePositionSecondary } from '../../../../../Model/Exercise/ExercisePosition';

export type ExercisePositionSecondarySelectProps = {
  exercise: ExerciseVariationAdmin;
  disabled?: boolean;
};

export const ExercisePositionSecondarySelect: React.FC<ExercisePositionSecondarySelectProps> = observer(
  (props: ExercisePositionSecondarySelectProps) => {
    const { exercise, disabled } = props;
    const store = useLocalStore(
      (source: ExercisePositionSecondarySelectProps) => ({
        get lying(): boolean {
          return source.exercise.initialPosition?.primary === 'lying';
        },
      }),
      props,
    );

    const handleChange = React.useCallback(
      (value) => {
        if (exercise.initialPosition) {
          exercise.initialPosition.secondary = value ? (value as ExercisePositionSecondary) : undefined;
        }
      },
      [exercise],
    );

    return (
      <Form.Item label="Exercise Position (secondary)">
        <Select
          value={exercise.initialPosition?.secondary}
          onChange={handleChange}
          disabled={disabled || !exercise.initialPosition}
        >
          <Select.Option value="">{''}</Select.Option>
          <Select.Option value="straight">{'Straight / Gerade'}</Select.Option>
          <Select.Option value="inclined_front">{'Inclined Front / Geneigt nach vorne'}</Select.Option>
          <Select.Option value="inclined_back">{'Inclined Back / Geneigt nach hinten'}</Select.Option>
          <Select.Option value="inclined_side">{'Inclined Side / Geneigt zur Seite'}</Select.Option>
          {store.lying ? (
            <>
              <Select.Option value="back">{'On the back / Auf dem Rücken'}</Select.Option>
              <Select.Option value="belly">{'On the belly / Auf dem Bauch'}</Select.Option>
              <Select.Option value="side">{'Side / Zur Seite'}</Select.Option>
            </>
          ) : null}
        </Select>
      </Form.Item>
    );
  },
);
