import { RecipeIngredient, RecipeIngredientJson } from './RecipeIngredient';
import { NutritionInformation, NutritionInformationJson } from '../Ingredient/NutritionInformation';
import { RecipeInstruction, RecipeInstructionJson } from './RecipeInstruction';
import { action, computed, observable, toJS } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { v4 as UUID } from 'uuid';
import { RecipeSearchParams, SeasonType } from './RecipeSearchParams';
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../../LocalizedArrayEntity';
import { Media, MediaJson } from '../../Media/Media';

/**
 * Created by neo on 18.12.20.
 */

export type RecipeJson = LocalizedArrayEntityJson & {
  archived?: boolean;
  veryHealthy?: boolean;
  sustainable?: boolean;
  ingredients: RecipeIngredientJson[];
  readyInMinutes: number;
  servings: number;
  image?: MediaJson;
  nutrition?: NutritionInformationJson;
  cuisines: string[];
  dishTypes: string[];
  diets: string[];
  occasions: string[];
  seasons: SeasonType[];
  instructions: RecipeInstructionJson[];
};

export class Recipe extends LocalizedArrayEntity {
  @observable
  id = UUID();
  @observable
  archived?: boolean;
  @observable
  veryHealthy?: boolean;
  @observable
  sustainable?: boolean;
  @observable
  ingredients: RecipeIngredient[] = [];
  @observable
  readyInMinutes: number = 0;
  @observable
  servings: number = 1;
  @observable
  image?: Media;
  @observable
  nutrition = new NutritionInformation();
  @observable
  cuisines: string[] = [];
  @observable
  dishTypes: string[] = [];
  @observable
  diets: string[] = [];
  @observable
  occasions: string[] = [];
  @observable
  seasons: SeasonType[] = [];
  @observable
  instructions: RecipeInstruction[] = [new RecipeInstruction()];

  constructor(json?: Partial<RecipeJson>) {
    super(json);
    if (json) {
      this.id = json.id ?? UUID();
      this.archived = json.archived ?? false;
      this.veryHealthy = json.veryHealthy;
      this.sustainable = json.sustainable;
      this.ingredients = (json.ingredients ?? []).map((i) => new RecipeIngredient(i));
      this.readyInMinutes = json.readyInMinutes ?? 0;
      this.servings = json.servings ?? 1;
      this.image = json.image ? new Media(json.image) : undefined;
      this.nutrition = new NutritionInformation(json.nutrition);
      this.cuisines = json.cuisines ?? [];
      this.dishTypes = json.dishTypes ?? [];
      this.diets = json.diets ?? [];
      this.occasions = json.occasions ?? [];
      this.seasons = json.seasons ?? [];
      this.instructions = json.instructions?.map((i) => new RecipeInstruction(i)) ?? [new RecipeInstruction()];
    }
  }

  englishOnly(): Recipe {
    const recipe = new Recipe(this.toJS());
    recipe.name = this.nameLanguageOnly('en');
    recipe.description = this.descriptionLanguageOnly('en');
    recipe.ingredients = this.ingredients.map((i) => {
      const ingredient = new RecipeIngredient(i.toJS());
      ingredient.name = i.nameLanguageOnly('en');
      ingredient.description = i.descriptionLanguageOnly('en');
      return ingredient;
    });
    return recipe;
  }

  toJS(): RecipeJson {
    return Object.assign(super.toJS(), {
      archived: this.archived,
      veryHealthy: this.veryHealthy,
      sustainable: this.sustainable,
      ingredients: this.ingredients.map((i) => i.toJS()),
      readyInMinutes: this.readyInMinutes,
      servings: this.servings,
      image: this.image?.toJS(),
      nutrition: this.nutrition.toJS(),
      cuisines: toJS(this.cuisines),
      dishTypes: toJS(this.dishTypes),
      diets: toJS(this.diets),
      occasions: toJS(this.occasions),
      seasons: toJS(this.seasons),
      instructions: this.instructions.map((i) => i.toJS()),
    });
  }

  @action
  update(json: Partial<RecipeJson>): Recipe {
    this.archived = json.archived ?? this.archived;
    this.veryHealthy = json.veryHealthy ?? this.veryHealthy;
    this.sustainable = json.sustainable ?? this.sustainable;
    this.readyInMinutes = json.readyInMinutes ?? this.readyInMinutes;
    this.servings = json.servings ?? this.servings;
    this.image = json.image ? new Media(json.image) : this.image;
    this.nutrition = json.nutrition ? new NutritionInformation(json.nutrition) : this.nutrition;
    this.cuisines = json.cuisines ?? this.cuisines;
    this.dishTypes = json.dishTypes ?? this.dishTypes;
    this.diets = json.diets ?? this.diets;
    this.occasions = json.occasions ?? this.occasions;
    this.seasons = json.seasons ?? this.seasons;

    if (json.ingredients) {
      this.ingredients = json.ingredients.map((i) => new RecipeIngredient(i));
    }
    if (json.instructions) {
      this.instructions = json.instructions.map((i) => new RecipeInstruction(i));
    }

    return this;
  }

  save(): Promise<Recipe> {
    return HttpBackend.post(`/coach/diet/admin/recipes`, this.toJS()).then(() => this);
  }

  archive(): Promise<Recipe> {
    return HttpBackend.delete(`/coach/diet/admin/recipes/${this.id}`).then(() => this);
  }

  @computed
  get isValid(): boolean {
    return (
      this.ingredients.length > 1 &&
      (this.instructions[0]?.steps.length ?? 0) > 1 &&
      this.ingredients.every((i) => i.description.length >= 4) &&
      this.readyInMinutes > 0
    );
  }

  static search(params?: RecipeSearchParams): Promise<Recipe[]> {
    return HttpBackend.get(`/coach/diet/admin/recipes`, params).then((result) => result.map((r) => new Recipe(r)));
  }

  static count(params?: RecipeSearchParams): Promise<number> {
    return HttpBackend.get(`/coach/diet/recipes/count`, params);
  }

  static get(id: string): Promise<Recipe> {
    return HttpBackend.get(`/coach/diet/admin/recipes/${id}`).then((result) => new Recipe(result));
  }
}
