/**
 * Created by neo on 30.09.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Media } from '../../../Model/Media/Media';
import ReactPlayer from 'react-player';

export type MediaListItemContentVideoProps = {
  onClick?: (media: Media) => void;
  media: Media;
};

export const MediaListItemContentVideo: React.FC<MediaListItemContentVideoProps> = observer(
  ({ media, onClick }: MediaListItemContentVideoProps) => {
    const [playing, setPlaying] = useState(false);

    const handleClick = React.useCallback(() => {
      onClick && onClick(media);
    }, [onClick, media]);

    const handleMouseEnter = React.useCallback(() => {
      setPlaying(true);
    }, []);

    const handleMouseLeave = React.useCallback(() => {
      setPlaying(false);
    }, []);

    return (
      <React.Fragment>
        <div
          style={{ width: '100%', cursor: 'pointer' }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
        >
          <ReactPlayer url={media.smallest} width="100%" height="100%" playing={playing}  />
        </div>
      </React.Fragment>
    );
  },
);
