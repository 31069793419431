/**
 * Created by neo on 04.06.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Button } from 'antd';
import { TranslationService } from '../../../Services/TranslationService';
import { MergedTranslation, TranslationQueryRequest } from '../../../Model/Translation/MergedTranslation';
import { availableLanguages } from '../../../Utils/availableLanguages';
import { TranslationKey } from '../../../Model/Translation/TranslationKey';

export type TranslateMissingTranslationsButtonProps = {
  request?: TranslationQueryRequest;
};

export const TranslateMissingTranslationsButton: React.FC<TranslateMissingTranslationsButtonProps> = observer(
  ({ request }) => {
    const [translating, setTranslating] = useState(false);

    const translate = React.useRef((translation: MergedTranslation) => {
      const missingLanguages = availableLanguages.filter(
        (lang) => !translation.keys.some((k) => k.language === lang.value),
      );
      const missingValues = translation.keys.filter((key) => !key.value.trim());

      const source =
        translation.keys.find((key) => key.language === 'de' && !!key.value.trim()) ??
        translation.keys.find((key) => key.language === 'en' && !!key.value.trim()) ??
        translation.keys.find((key) => !!key.value.trim());

      if (!!source?.value.trim() && (missingLanguages.length > 0 || missingValues.length > 0)) {
        console.log('source', source, missingLanguages, missingValues);
        return Promise.all([
          Promise.all(
            missingValues.map((entry) =>
              TranslationService.openai({
                sourceLanguage: source.language,
                targetLanguage: entry.language,
                text: source.value,
              }).then((result) => {
                entry.value = result ?? '';
                return entry.save();
              }),
            ),
          ),
          Promise.all(
            missingLanguages.map((entry) =>
              TranslationService.openai({
                sourceLanguage: source.language,
                targetLanguage: entry.value,
                text: source.value,
              }).then((result) =>
                new TranslationKey({
                  key: source.key,
                  namespace: translation.namespace,
                  language: entry.value,
                  value: result ?? '',
                }).save(),
              ),
            ),
          ),
        ]);
      }

      return Promise.resolve();
    }).current;

    const handleTranslateMissing = React.useCallback(async () => {
      setTranslating(true);
      try {
        let page = 0;
        let results: MergedTranslation[] | undefined;
        while (!results || results.length >= 10) {
          results = await MergedTranslation.list(
            Object.assign({}, request, { size: 10, page, sort: 'createdAt,DESC' }),
          );

          await Promise.all(results.map((res) => translate(res)));
          page += 1;
        }
      } finally {
        setTranslating(false);
      }
    }, [translate, request]);

    return (
      <Button onClick={handleTranslateMissing} disabled={translating}>
        {translating ? 'Translation in progress...' : 'Translate Missing (OpenAI)'}
      </Button>
    );
  },
);
