/**
 * Created by neo on 01.02.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, DatePicker, Form, Input, Select, Tag, TimePicker } from 'antd';
import { Col, Row } from 'reactstrap';
import { MultiRowMap } from '../../../../Security/OAuth/View/MultiRowMap';
import { Activity } from '../../../../../Model/Activity/Activity';
import AsyncSelect from 'react-select/async';
import { runInAction } from 'mobx';
import dayjs from 'dayjs';
import { InstructorEdit } from './InstructorEdit';
import { Instructor } from '../../../../../Model/Explore/Instructor';
import { RecurringPattern } from '../../../../../Model/Coach/Schedule/RecurringPattern';
import { RecurringPatternEditor } from './RecurringPatternEditor';
import { Timezones } from '../../../../../Utils/Timezones';
import { OnlineMeetingEntry } from '../../../../../Model/Explore/OnlineMeetingEntry';
import { OnlineMeetingEntryEmbedData } from '../../../../../Model/Explore/OnlineMeetingEntryEmbedData';
import { OnlineMeetingEntryEmbedDataEntry } from '../../../../../Model/Explore/OnlineMeetingEntryEmbedDataEntry';

export type OnlineMeetingEntryContentProps = {
  entry: OnlineMeetingEntry;
};

export const OnlineMeetingEntryContent: React.FC<OnlineMeetingEntryContentProps> = observer(({ entry }) => {
  const handleChangeActivity = React.useCallback(
    (activity: Activity | undefined) => {
      entry.activityIdentifier = activity?.identifier ?? '';
    },
    [entry],
  );

  const fetchActivity = React.useCallback((query: string) => Activity.find({ query }), []);

  const getOptionLabel = React.useCallback((option: Activity) => `${option.defaultName} (${option.identifier})`, []);

  const getOptionValue = React.useCallback((option: Activity) => option, []);

  const handleChangeMeetingLink = React.useCallback(
    ({ target: { value } }) => runInAction(() => (entry.meetingLink = value.trim().replace(/\s/g, ''))),
    [entry],
  );

  const handleChangeLanguage = React.useCallback(
    (lang) => {
      runInAction(() => (entry.language = lang));
    },
    [entry],
  );

  const handleChangeStartDate = React.useCallback(
    (date) =>
      runInAction(() => (entry.startDate = date?.format('YYYY-MM-DD') ?? dayjs().add(1, 'day').format('YYYY-MM-DD'))),
    [entry],
  );

  const handleChangeStartTime = React.useCallback(
    (date, dateString) => runInAction(() => (entry.startTime = date.format('HH:mm') ?? entry.startTime)),
    [entry],
  );

  const handleChangeTimezone = React.useCallback(
    (timezone: string) => runInAction(() => (entry.timezone = timezone)),
    [entry],
  );

  const handleChangeDuration = React.useCallback(
    (date, dateString) => {
      if (date) {
        runInAction(() => (entry.duration = dayjs.duration(dayjs(date.toDate()).diff(dayjs(0))).toISOString()));
      }
    },
    [entry],
  );

  const handleChangeRecurring = React.useCallback(
    ({ target: { checked } }) => {
      if (checked) {
        runInAction(() => (entry.recurringPattern = new RecurringPattern()));
      } else {
        runInAction(() => (entry.recurringPattern = undefined));
      }
    },
    [entry],
  );

  const handleChangeWebVideoEmbedCode = React.useCallback(
    ({ target: { value } }) => {
      const newValue = value.trim();
      runInAction(() => {
        if (newValue) {
          if (!entry.embedData) {
            entry.embedData = new OnlineMeetingEntryEmbedData();
          }
          if (!entry.embedData.web) {
            entry.embedData.web = new OnlineMeetingEntryEmbedDataEntry();
          }

          entry.embedData.web.videoEmbedCode = value;
        } else {
          if (entry.embedData && !entry.embedData.web?.interactionEmbedCode) {
            entry.embedData.web = undefined;
          }
          if (!entry.embedData?.app?.videoEmbedCode && !entry.embedData?.app?.videoEmbedCode) {
            entry.embedData = undefined;
          }
        }
      });
    },
    [entry],
  );

  const handleChangeWebInteractionEmbedCode = React.useCallback(
    ({ target: { value } }) => {
      const newValue = value.trim();
      runInAction(() => {
        if (newValue) {
          if (!entry.embedData) {
            entry.embedData = new OnlineMeetingEntryEmbedData();
          }
          if (!entry.embedData.web) {
            entry.embedData.web = new OnlineMeetingEntryEmbedDataEntry();
          }

          entry.embedData.web.interactionEmbedCode = value;
        } else {
          if (entry.embedData && !entry.embedData.web?.videoEmbedCode) {
            entry.embedData.web = undefined;
          }
          if (!entry.embedData?.app?.videoEmbedCode && !entry.embedData?.app?.videoEmbedCode) {
            entry.embedData = undefined;
          }
        }
      });
    },
    [entry],
  );

  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <Row>
            <Col xs={12}>
              <Form.Item label="Meeting Link (required)">
                <Input value={entry.meetingLink} onChange={handleChangeMeetingLink} />
              </Form.Item>
            </Col>
            <Col md={12} lg={6}>
              <Form.Item
                label="Video Embed Code (Web)"
                extra="The complete video embed code from vimeo or other platform"
              >
                <Input.TextArea value={entry.embedData?.web?.videoEmbedCode} onChange={handleChangeWebVideoEmbedCode} />
              </Form.Item>
            </Col>
            <Col md={12} lg={6}>
              <Form.Item label="Interaction Embed Code (Web)" extra="If we want to embed the vimeo chat for instance">
                <Input.TextArea
                  value={entry.embedData?.web?.interactionEmbedCode}
                  onChange={handleChangeWebInteractionEmbedCode}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={3}>
          <Form.Item label="Meeting Date">
            <DatePicker
              value={dayjs(entry.startDateTime?.toDate())}
              disabledDate={(date) => !date.isAfter(dayjs().startOf('day'))}
              onChange={handleChangeStartDate}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col xs={3}>
          <Form.Item label="Meeting Time" extra={entry.localStartDateTime?.format('DD.MM.YYYY HH:mm')}>
            <TimePicker
              value={dayjs(entry.startTime, 'HH:mm')}
              showSecond={false}
              format="HH:mm"
              onChange={handleChangeStartTime}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col xs={3}>
          <Form.Item label="Meeting Duration">
            <TimePicker
              value={dayjs.utc(entry.durationDayjs.toDate())}
              showSecond={false}
              format="HH:mm"
              onChange={handleChangeDuration}
              minuteStep={5}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col xs={3}>
          <Form.Item label="Timezone">
            <Select value={entry.timezone} onChange={handleChangeTimezone}>
              {Timezones.map((v) => (
                <Select.Option key={v} value={v}>
                  {v}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Checkbox checked={!!entry.recurringPattern} onChange={handleChangeRecurring}>
            Recurring Meeting?
          </Checkbox>
          {entry.recurringPattern && <RecurringPatternEditor recurringPattern={entry.recurringPattern} />}
          {entry.recurringPattern && (
            <React.Fragment>
              {entry.possibleDates.map((date) => (
                <Tag key={date.toISOString()}>{date.format('DD.MM.YYYY HH:mm')}</Tag>
              ))}
            </React.Fragment>
          )}
        </Col>
        <Col md={12}>
          <Form.Item label="Hosts">
            <Row>
              {entry.hosts.map((host, index) => (
                <Col md={3} key={index.toString()}>
                  <InstructorEdit
                    key={index}
                    index={index}
                    instructor={host}
                    onRemove={() => runInAction(() => entry.hosts.splice(index, 1))}
                  />
                </Col>
              ))}
            </Row>
            <Button type="primary" onClick={() => runInAction(() => entry.hosts.push(new Instructor()))}>
              Add Host
            </Button>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="Activity"
            extra="This is optional. If set we will log an activity after the user leaves the meeting"
          >
            <AsyncSelect
              defaultOptions
              isClearable={true}
              loadOptions={fetchActivity}
              value={entry.activity}
              hideSelectedOptions={false}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue as any}
              onChange={handleChangeActivity as any}
            />
          </Form.Item>
        </Col>
        <Col md={6}>
          <MultiRowMap label="Data" container={entry.data} />
        </Col>
      </Row>
    </React.Fragment>
  );
});
