/**
 *
 * Created by neo on 04.03.17.
 */

import { toJS, observable, computed, onBecomeObserved, runInAction } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Address } from '../Address';
import { CompanyInfo } from './CompanyInfo';
import { Media, MediaJson } from '../Media/Media';
import { Customer } from '../Customer/Customer';
import { Branch } from './Branch';
import { GymConfiguration } from './GymConfiguration';
import { Pageable } from '../Pageable';
import { Audited, AuditedJson } from '../Audited';
import { GymCoachSubscription } from '../Subscription/GymCoachSubscription';

export type GymQueryParams = Pageable & {
  type: GymType;
  query: string;
  location: number[];
  distance: number;
};

export type GymType = 'gym' | 'creator' | 'coach';

export type GymJson = AuditedJson & {
  type: GymType;
  customerLimit?: number;
  totalPotentialUsers?: number;
  name: string;
  address: Address;
  companyInfo: CompanyInfo;
  domains: string[];
  allowDomainSignup: boolean;
  logo?: MediaJson;
  configuration: GymConfiguration;
  axaCoBrandingEnabled: boolean;
};

export class Gym extends Audited {
  @observable
  type: GymType = 'coach';
  @observable
  customerLimit?: number;
  @observable
  totalPotentialUsers = 0;
  @observable
  name: string = '';
  @observable
  address: Address = new Address();
  @observable
  companyInfo: CompanyInfo = new CompanyInfo();
  @observable
  domains: string[] = [];
  @observable
  allowDomainSignup = false;
  @observable
  logo?: Media = undefined;
  @observable
  configuration: GymConfiguration = new GymConfiguration();
  @observable
  totalCustomers?: number = undefined;
  @observable
  totalBranches?: number = undefined;
  @observable
  branches?: Branch[] = undefined;
  @observable
  subscription?: GymCoachSubscription;
  @observable
  axaCoBrandingEnabled = false;

  constructor(json?: Partial<GymJson>) {
    super(json);
    if (json) {
      this.type = json.type || 'coach';
      this.customerLimit = json.customerLimit;
      this.name = json.name ?? '';
      this.address = new Address(json.address || {});
      this.companyInfo = new CompanyInfo(json.companyInfo || {});
      this.domains = json.domains ?? [];
      this.allowDomainSignup = json.allowDomainSignup ?? true;
      this.logo = json.logo ? new Media(json.logo) : undefined;
      this.configuration = new GymConfiguration(json.configuration);
      this.totalPotentialUsers = json.totalPotentialUsers ?? 0;
      this.axaCoBrandingEnabled = json.axaCoBrandingEnabled ?? false;
    }

    onBecomeObserved(this, 'totalCustomers', this.fetchCustomerCount);
    onBecomeObserved(this, 'totalBranches', this.fetchTotalBranches);
    onBecomeObserved(this, 'branches', this.fetchBranches);
    onBecomeObserved(this, 'subscription', this.fetchSubscription);
  }

  fetchSubscription = async () => {
    if (this.id && !this.subscription) {
      this.subscription = await GymCoachSubscription.current({ gymId: this.id });
    }
  };

  fetchCustomerCount = async () => {
    if (this.id && !this.totalCustomers) {
      Customer.count({ gymId: this.id }).then((count) => runInAction(() => (this.totalCustomers = count)));
    }
  };

  fetchTotalBranches = async () => {
    if (this.id && !this.totalBranches) {
      const data = await Branch.find({ gymId: this.id, size: 1 });
      this.totalBranches = data.totalElements;
    }
  };

  fetchBranches = async () => {
    if (this.id && !this.branches) {
      this.branches = await Branch.listByGym(this.id);
    }
  };

  @computed
  get _isNew(): boolean {
    return !this.id || this.id === 'new';
  }

  static get(id: string): Promise<Gym | undefined> {
    return HttpBackend.get(`/gym/admin/${id}`).then((res) => (res ? new Gym(res) : undefined));
  }

  static find(data: Partial<GymQueryParams> = {}): Promise<Gym[]> {
    return HttpBackend.get('/gym/admin', data).then((res) => res.map((g) => new Gym(g)));
  }

  static count(data: any = { page: 0, sort: 'name,ASC' }): Promise<number> {
    return HttpBackend.get('/gym/admin/count', data);
  }

  static listByAthleteId(athleteId: string): Promise<Gym[]> {
    return HttpBackend.get(`/gym/customer/list-gyms`, { athleteId }).then((res) => res.map((g) => new Gym(g)));
  }

  save(): Promise<Gym> {
    return HttpBackend.post('/gym/admin', toJS(this)).then(() => this);
  }

  delete() {
    return HttpBackend.delete(`/gym/admin/${this.id}`);
  }
}
