/**
 * Created by neo on 07.05.21.
 */
import { observable } from 'mobx';
import { v4 as UUID } from 'uuid';

export type AuditedJson = {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdBy?: string;
  updatedBy?: string;
};

export class Audited {
  @observable
  id = UUID();
  @observable
  createdAt = new Date();
  @observable
  updatedAt = new Date();
  @observable
  createdBy?: string;
  @observable
  updatedBy?: string;

  constructor(json?: Partial<AuditedJson>) {
    if (json) {
      this.id = json.id ?? UUID();
      this.createdAt = json.createdAt ? new Date(json.createdAt) : new Date();
      this.updatedAt = json.updatedAt ? new Date(json.updatedAt) : new Date();
      this.createdBy = json.createdBy;
      this.updatedBy = json.updatedBy;
    }
  }

  toJS(newId?: boolean): AuditedJson {
    return {
      id: newId ? UUID() : this.id,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
    };
  }
}
