/**
 * Created by neo on 12.04.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Button, Form, Input, Space } from 'antd';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { RecipeSearchParams } from '../../../../Model/Diet/Recipe/RecipeSearchParams';
import { Pager } from '../../../../Components/Pager';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { observable, runInAction } from 'mobx';
import { PageResult } from '../../../../Model/PageResult';
import { IngredientSearchParams } from '../../../../Model/Diet/Ingredient/IngredientSearchParams';
import { Ingredient } from '../../../../Model/Diet/Ingredient/Ingredient';
import { IngredientTable } from './IngredientTable';
import { IngredientEditModal } from './IngredientEditModal';
import { TranslateIngredientsComponent } from './TranslateIngredientsComponent';

export type IngredientListScreenProps = {};

export const IngredientListScreen: React.FC<IngredientListScreenProps> = observer((props) => {
  const history = useNavigate();
  const [queryData] = useState(() =>
    observable({
      query: '',
      page: 0,
      archived: false,
    } as IngredientSearchParams),
  );
  // const [query, setQuery] = useState('');
  // const [diet, setDiet] = useState<string[]>([]);
  // const [dishTypes, setDishTypes] = useState(string[])([]);
  const offset = React.useRef(0);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<PageResult<Ingredient>>(new PageResult());
  const debounce = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const search = React.useCallback(
    (params: RecipeSearchParams) => {
      setLoading(true);
      setResult(new PageResult());
      PageResult.execute(Ingredient.search(params), Ingredient.count(params), queryData.page)
        .then((result) => setResult(result))
        .finally(() => setLoading(false));
    },
    [queryData],
  );

  const handleQueryChange = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (queryData.query = value));
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => search(queryData), 300);
    },
    [search, queryData],
  );

  const handleQueryClear = React.useCallback(() => {
    runInAction(() => (queryData.query = ''));
    setResult(new PageResult());
  }, [queryData]);

  const handleCreate = React.useCallback(() => {
    history('new');
  }, [history]);

  const handlePage = React.useCallback(
    (page: number) => {
      if (queryData.page !== page) {
        runInAction(() => (queryData.page = page));
        debounce.current && clearTimeout(debounce.current);
        debounce.current = setTimeout(() => search(queryData), 300);
      }
    },
    [search, queryData],
  );

  useEffect(() => {
    search({});
  }, [search]);

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <h1>Ingredients</h1>
          </Col>
          <Col xs="auto">
            <Space>
              <Button type="primary" onClick={handleCreate}>
                Create Ingredient
              </Button>
              <TranslateIngredientsComponent />
            </Space>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item>
              <Input.Search
                onChange={handleQueryChange}
                value={queryData.query}
                autoFocus={true}
                placeholder="Search for ingredients..."
                enterButton="Clear"
                onSearch={handleQueryClear}
                loading={loading}
              />
            </Form.Item>
          </Col>
        </Row>
        <SingleColRow>
          <Pager page={result} onPage={handlePage} />
        </SingleColRow>
        <SingleColRow>
          <IngredientTable ingredients={result.content} />
        </SingleColRow>
        <SingleColRow>
          <Pager page={result} onPage={handlePage} />
        </SingleColRow>
      </Container>
      <Routes>
        <Route path=":ingredientId" element={<IngredientEditModal />} />
      </Routes>
    </React.Fragment>
  );
});
