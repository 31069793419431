/**
 * Created by neo on 12.04.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { Button, Modal, Tabs } from 'antd';
import { Ingredient } from '../../../../Model/Diet/Ingredient/Ingredient';
import { IngredientEditModalBasicInfo } from './IngredientEditModalBasicInfo';
import { IngredientEditModalNutrients } from './IngredientEditModalNutrients';

export type IngredientEditModalProps = {};

export const IngredientEditModal: React.FC<IngredientEditModalProps> = observer((props) => {
  const history = useNavigate();
  const { ingredientId } = useParams<{ ingredientId: string }>();
  const [processing, setProcessing] = useState(false);
  const [ingredient, setIngredient] = useState(new Ingredient());
  const [activeTab, setActiveTab] = useState('basic');
  const isNew = ingredientId === 'new';

  useEffect(() => {
    if (ingredientId && ingredientId !== 'new') {
      setProcessing(true);
      Ingredient.get(ingredientId)
        .then((result) => setIngredient(result))
        .finally(() => setProcessing(false));
    } else {
      setIngredient(new Ingredient());
    }
  }, [ingredientId]);

  const handleSave = React.useCallback(() => {
    setProcessing(true);
    ingredient.save().then(() => setProcessing(false));
  }, [ingredient]);

  const handleCancel = React.useCallback(() => {
    history(-1);
  }, [history]);

  // const handleArchive = React.useCallback(() => {
  //   setProcessing(true);
  //   ingredient
  //     .archive()
  //     .then(() => history(-1))
  //     .finally(() => setProcessing(false));
  // }, [history, ingredient]);

  // const handleRestore = React.useCallback(() => {
  //   setProcessing(true);
  //   ingredient.archived = false;
  //   ingredient
  //     .save()
  //     .then(() => history(-1))
  //     .finally(() => setProcessing(false));
  // }, [history, ingredient]);

  const handleChangeTab = React.useCallback((tab: string) => setActiveTab(tab), []);

  return (
    <Modal
      open={true}
      title={isNew ? 'New Ingredient' : ingredient.defaultName}
      confirmLoading={processing}
      footer={[
        <Button key="1" onClick={handleCancel}>
          Close
        </Button>,
        // <Button key="2" danger onClick={ingredient.archived ? handleRestore : handleArchive}>
        //   {ingredient.archived ? 'Restore' : 'Archive'}
        // </Button>,
        <Button key="3" type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
      width={1280}
    >
      <Tabs defaultActiveKey="1" onChange={handleChangeTab}>
        <Tabs.TabPane tab="Basic" key="basic">
          <IngredientEditModalBasicInfo ingredient={ingredient} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Nutrients" key="nutrients">
          <IngredientEditModalNutrients ingredient={ingredient} />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
});
