import { observable } from 'mobx';
import { LocalizedValueJson } from '../../LocalizedValue';
import { v4 as UUID } from 'uuid';
import { NutritionInformation, NutritionInformationJson } from './NutritionInformation';
import { IngredientSearchParams } from './IngredientSearchParams';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../../LocalizedArrayEntity';
import { Media, MediaJson } from '../../Media/Media';

/**
 * Created by neo on 18.12.20.
 */

export type IngredientJson = LocalizedArrayEntityJson & {
  id: string;
  name: LocalizedValueJson[];
  amount: number;
  unit: string;
  consistency?: string;
  image?: MediaJson;
  nutrition: NutritionInformationJson;
};

export const defaultPossibleUnits = {
  g: 1,
  milliliter: 1,
  oz: 28.3495,
  teaspoon: 4,
  pinch: 0.2,
  sachet: 1,
  tablespoon: 12,
  envelope: 14,
  packet: 14,
  lb: 453.592,
  kg: 1000,
  piece: 1,
  l: 1,
};

export class Ingredient extends LocalizedArrayEntity {
  @observable
  id = UUID();
  @observable
  amount: number = 100;
  @observable
  unit: string = 'g';
  @observable
  consistency?: string;
  @observable
  image?: Media;
  @observable
  nutrition = new NutritionInformation();

  constructor(json?: Partial<IngredientJson>) {
    super(json);
    if (json) {
      this.id = json.id ?? UUID();
      this.amount = json.amount ?? 0;
      this.unit = json.unit ?? '';
      this.consistency = json.consistency;
      this.image = json.image ? new Media(json.image) : undefined;
      this.nutrition = new NutritionInformation(json.nutrition);
    }
  }

  toJS(): IngredientJson {
    return Object.assign(super.toJS(), {
      id: this.id,
      amount: this.amount,
      unit: this.unit,
      consistency: this.consistency,
      image: this.image?.toJS(),
      nutrition: this.nutrition.toJS(),
    });
  }

  save(): Promise<Ingredient> {
    return HttpBackend.post(`/coach/diet/admin/ingredients`, this.toJS()).then(() => this);
  }

  static search(params?: IngredientSearchParams): Promise<Ingredient[]> {
    return HttpBackend.get(`/coach/diet/ingredients`, params).then((result) => result.map((r) => new Ingredient(r)));
  }

  static count(params?: IngredientSearchParams): Promise<number> {
    return HttpBackend.get(`/coach/diet/ingredients/count`, params);
  }

  static get(id: string): Promise<Ingredient> {
    return HttpBackend.get(`/coach/diet/ingredients/${id}`).then((result) => new Ingredient(result));
  }

  static getAll(ids: string[]): Promise<Ingredient[]> {
    return HttpBackend.get(`/coach/diet/ingredients/findAll/${ids.join(',')}`).then((result) =>
      (result ?? []).map((res) => new Ingredient(res)),
    );
  }
}
