/**
 * Created by andreaskarantzas on 04.05.20.
 */

import React from 'react';
import { observer } from 'mobx-react';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';
import { TranslationArrayInputMaterial } from '../../../../Translation/TranslationArrayInputMaterial';

export type ComponentStylesHeadingProps = {
  component: NativeFeedComponent;
};

export const ComponentStylesHeading: React.FC<ComponentStylesHeadingProps> = observer((props) => {
  const { classes } = useStyles();
  const { component } = props;

  return (
    <Grid className={classes.container} id="nativeFeedSideDrawerComponentStylesHeading">
      <Grid item xs={12}>
        {component.text ? <TranslationArrayInputMaterial multiline={true} translations={component.text} /> : null}
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: 16,
    width: '100%',
  },
  divider: {
    margin: theme.spacing(1, 0.5),
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '25ch',
  },
}));
